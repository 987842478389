import {
    Component,
    ViewChild,
    Inject,
    PLATFORM_ID,
    Input,
    OnInit, ElementRef,
} from '@angular/core';
import { BasketService } from '@core/basket.service';
import { ImpactCoreBasketsBasketLineServiceModel as basketLineModel } from '@shared/swagger/swagger.interface';
import { StickyService } from '@core/sticky.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { Subject, timer } from 'rxjs';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { getComputedTranslateY } from '@shared/utility';
import { TranslationService } from '@core/translation.service';
import { PageService } from '@core/page.service';
import { RaptorTrackingService } from '@core/tracking/raptor-tracking.service';
import { UserService } from '@core/user.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { BreakpointState, BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'app-basket',
    templateUrl: './basket.component.html',
    host: { class: 'column' },
})
export class BasketComponent implements OnInit {
    @ViewChild('sidebar', { static: true }) sidebar;
    @ViewChild('wrapper', { static: true }) wrapper;
    @Input() data: any;

    destroy = new Subject();
    last_known_scroll_position;
    ticking = false;
    sidebarTranslateY = 0;
    public mobileLayout: string;
    isMobileTablet: boolean;
    pageData: any;

    constructor(
        private stickyService: StickyService,
        private spinnerService: SpinnerService,
        public basketService: BasketService,
        public pageService: PageService,
        public translationService: TranslationService,
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private _document,
        private raptorTracking: RaptorTrackingService,
        private userService: UserService,
        private tracking: TrackingService,
        private breakpointObserver: BreakpointObserver,
    ) {}

    ngOnInit() {
        const user = this.userService.currentUser.getValue();
        this.spinnerService.show('basketSpinner');

        this.basketService.basket$.subscribe(data => {
            this.spinnerService.hide('basketSpinner');
        });

        this.stickyService.stickyEvent
            .pipe(takeUntil(this.destroy))
            .subscribe(event => {
                if (!this.ticking && isPlatformBrowser(this.platformId)) {
                    this.ticking = true;
                    this.last_known_scroll_position = event.yPos;
                    this.sticky();
                    this.ticking = false;
                }
            });
        this.basketService.basket$
            .pipe(filter(obj => Object.keys(obj).length > 0), first())
            .subscribe(basket => {
                this.tracking.sendCheckoutStep(0, basket, false);
                this.raptorTracking.trackBasketEvent(basket, user);
            });

        if (this.data.Row1) {
            for (let i = 0; i < this.data.Row1.length; i++) {
                if (this.data.Row1[i].TemplateName) {
                    this.mobileLayout = this.data.Row1[i].MobileLayout;
                }
            }
        }
        this.breakpointObserver
            .observe(['(max-width: 768px)'])
            .subscribe((state: BreakpointState) => {
                this.isMobileTablet = state.matches;
            });
    }

    /**
     * Final update basket event for children to call when changes are made
     */
    onUpdateBasket(basketLine: basketLineModel) {
        this.basketService.update(
            basketLine.Id,
            basketLine.SkuId,
            basketLine.Amount
        );
    }

    onUpdateGiftWrap(skuId: string) {
        this.basketService.updateGiftWrapping(skuId);
    }

    sticky() {
        const wrapperTop = this.wrapper.nativeElement.offsetTop;
        const wrapperHeight = this.wrapper.nativeElement.offsetHeight;
        const wrapperBottom = wrapperTop + wrapperHeight;

        const documentTop = document.documentElement.style.top.length
            ? parseInt(document.documentElement.style.top.replace('px', '')) *
              -1
            : 0;

        const lastKnownScrollPos =
            this.last_known_scroll_position + documentTop;

        const sidebarTranslateY = getComputedTranslateY(
            this.sidebar.nativeElement
        );

        if (sidebarTranslateY && this.sidebarTranslateY !== sidebarTranslateY) {
            this.sidebarTranslateY = sidebarTranslateY;
        }

        if (wrapperBottom < lastKnownScrollPos + window.innerHeight) {
            this._document.body.classList.add('checkout_sticky');
        } else {
            this._document.body.classList.remove('checkout_sticky');
        }

        if (
            wrapperBottom - this.sidebarTranslateY <
            lastKnownScrollPos + window.innerHeight
        ) {
            this._document.body.classList.add('checkout_sticky_mobile');
        } else {
            this._document.body.classList.remove('checkout_sticky_mobile');
        }
    }

    displayIcon(svg: string): boolean {
        return svg.indexOf('<svg ') > -1;
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }
}
