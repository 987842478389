<form class="form" novalidate name="form" [ngClass]="{'form_submitted': form.submitted}" (ngSubmit)="onComplete(form)" #form="ngForm">

    <div class="columns is-mobile is-multiline is-gapless-vertical">
        <div class="column is-6">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="text" class="input input_text input_bold" name="firstName" [observe]="true" appResetInput [(ngModel)]="user.FirstName"
                               #firstName="ngModel">
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.FirstNameInput}}</span>
                    </div>
                </label>
            </div>
        </div>
        <div class="column is-6">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="text" class="input input_text input_bold" name="lastName" appResetInput [(ngModel)]="user.LastName" [observe]="true"
                               #lastName="ngModel">
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.SurnameInput}}</span>
                    </div>
                </label>
            </div>
        </div>
        <div class="column is-6">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="text" class="input input_text input_bold" name="address" appResetInput [(ngModel)]="user.Address" #address="ngModel">
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.StreetNameInput}}</span>
                    </div>
                </label>
            </div>
        </div>
        <div class="column is-3">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="text" class="input input_text input_bold" name="houseNumber" appResetInput [(ngModel)]="user.HouseNumber" #houseNumber="ngModel">
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.StreetNumberInput}}</span>
                    </div>
                </label>
            </div>
        </div>
        <div class="column is-3">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="text" class="input input_text input_bold" name="floor" appResetInput [(ngModel)]="user.Floor" #floor="ngModel">
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.FloorInput}}</span>
                    </div>
                </label>
            </div>
        </div>
        <div class="column is-3">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="text" class="input input_text input_bold" name="zipCode" appResetInput (keyup)="setCity(zipCode.value)" [(ngModel)]="user.ZipCode"
                            #zipCode="ngModel">
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.ZipcodeInput}}</span>
                    </div>
                </label>
            </div>
        </div>
        <div class="column is-9">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="text" class="input input_text input_bold" name="city" appResetInput [observe]="true" [(ngModel)]="user.City" #city="ngModel">
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.CityInput}}</span>
                    </div>
                </label>
            </div>
        </div>
        <div class="column is-12">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <select class="input input_select input_bold" name="country" appResetInput [(ngModel)]="user.Country" #country="ngModel">
                            <option [ngValue]="" selected></option>
                            <option *ngFor="let country of countries.Countries" [ngValue]="country">{{country}}</option>
                        </select>
                        <span class="input-container__icon input-container__icon-expand validation-icon validation-icon_normal" [innerHTML]="icons.expand | safeHtml"></span>
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.CountryInput}}</span>
                    </div>
                </label>
            </div>
        </div>
        <div class="column is-6-desktop is-12-mobile">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="tel" class="input input_text input_bold" name="phoneNumber" appResetInput [(ngModel)]="user.PhoneNumber" #phoneNumber="ngModel"
                               [required]="user.IsSms" [pattern]="regexService.phone">
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.PhoneInput}}</span>
                    </div>
                </label>
                <div class="checkbox checkbox_no-overflow signup__newsletter-checkbox">
                    <input type="checkbox" id="newsletterSMS" name="isSms" [(ngModel)]="user.IsSms" #isSms="ngModel">
                    <label class="checkbox__label" for="newsletterSMS">
                        <span [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="checkbox__name">{{(translations | async)?.GeneralInput?.Content?.ReceiveNewsLettersBySMS}}</span>
                    </label>
                </div>
                <div class="form__validation" *ngIf="(phoneNumber.invalid && phoneNumber.touched) || form.submitted">
                    <span class="validation" *ngIf="phoneNumber.errors?.required">{{(translations | async)?.GeneralInput?.Content?.PhoneInputMissing}}</span>
                    <span class="validation" *ngIf="phoneNumber.errors?.pattern">{{(translations | async)?.GeneralInput?.Content?.PhoneInputInvalid}}</span>
                </div>
            </div>
        </div>
        <div class="column is-6 is-hidden-mobile"></div>
        <div class="column is-6">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <select class="input input_select input_bold" name="gender" appResetInput [(ngModel)]="user.Gender" #gender="ngModel">
                            <option [ngValue]="" selected></option>
                            <option [ngValue]="'Male'">{{(translations | async)?.GeneralInput?.Content?.GenderInputOptionMale}}</option>
                            <option [ngValue]="'Female'">{{(translations | async)?.GeneralInput?.Content?.GenderInputOptionFemale}}</option>
                        </select>
                        <span class="input-container__icon input-container__icon-expand validation-icon validation-icon_normal" [innerHTML]="icons.expand | safeHtml"></span>
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.GenderInput}}</span>
                    </div>
                </label>
            </div>
        </div>
        <div class="column is-6">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="text" class="input input_select input_bold" matInput appResetInput [matDatepicker]="myDatepicker" [min]="minDate" [max]="maxDate"
                               (click)="myDatepicker.open()" readonly name="birthday" [(ngModel)]="user.Birthday" #birthday="ngModel">
                        <mat-datepicker #myDatepicker startView="multi-year"></mat-datepicker>
                        <span class="input-container__icon input-container__icon-expand validation-icon validation-icon_normal" [innerHTML]="icons.expand | safeHtml"></span>
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.BirthdayInput}}</span>
                    </div>
                </label>
            </div>
        </div>
        <div class="column is-12">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <select class="input input_select input_bold" name="store" appResetInput [(ngModel)]="user.Store" #store="ngModel">
                            <option [ngValue]="" selected></option>
                            <option *ngFor="let store of stores" [ngValue]="store.StoreImportEasyposId">{{store.StoreImportDescription}}</option>
                        </select>
                        <span class="input-container__icon input-container__icon-expand validation-icon validation-icon_normal" [innerHTML]="icons.expand | safeHtml"></span>
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.FavoriteStoreInput}}</span>
                    </div>
                </label>
            </div>
        </div>
    </div>

    <div class="form__action">
        <app-alert class="form__response" *ngIf="response.text" [closeCallback]="closeAlert" [type]="response.type" [text]="response.text"></app-alert>

        <button type="submit" class="button button_primary button_full button_text-center">
            {{(translations | async)?.Authentication?.CreateUser?.FinishUserBotton}}
            <spinner name="signupSpinner" [size]="20" background="true" inverted="true"></spinner>
        </button>
    </div>

</form>
