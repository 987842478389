import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@features/find-store/models/store.model';
import { linkArrow } from '@shared/svg';
import { Router } from '@angular/router';
import { PaneService } from '@core/pane.service';

@Component({
    selector: 'app-find-store-list-item',
    templateUrl: './find-store-list-item.component.html',
})
export class FindStoreListItemComponent implements OnInit {
    @Input() store: Store;
    @Input() isPane: boolean;
    @Output() listClick = new EventEmitter<Store>();
    linkArrow = linkArrow;

    constructor(private router: Router, private paneService: PaneService) {}

    ngOnInit() {}

    onStoreSelected() {
        if (!this.isPane) {
            this.listClick.emit(this.store);
        } else {
            if (this.store.LinkToContentPage) {
                const route = '(dialog:' + this.store.LinkToContentPage + ')';
                this.router.navigate(
                    [
                        {
                            outlets: {
                                dialog: this.store.LinkToContentPage.replace(
                                    '/',
                                    ''
                                ),
                            },
                        },
                    ],
                    { queryParamsHandling: 'preserve' }
                );
                this.paneService.closeAll();
            }
        }
    }
}
