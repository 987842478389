import { tap } from 'rxjs/operators';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { LoginComponent } from '@features/authentication/login/login.component';
import { PaneService } from '@core/pane.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthenticationHeaderInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private paneService: PaneService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.indexOf('dawa.aws.dk') > -1) {
            return next.handle(req);
        }

        return next.handle(req).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                    }
                },
                (error: any) => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401) {
                            if (isPlatformBrowser(this.platformId)) {
                                localStorage.removeItem('clientToken');
                            }

                            this.router.navigate(['/']).then(() => {
                                this.paneService.open(
                                    LoginComponent,
                                    'right',
                                    'login'
                                );
                            });
                        }
                    }
                }
            )
        );
    }
}
