import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    animate,
    query,
    stagger,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { ImpactCoreBasketsBasketLineServiceModel as basketLineModel } from '@shared/swagger/swagger.interface';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-minibasket-products',
    templateUrl: './minibasket-products.component.html',

    animations: [
        trigger('loadAnimation', [
            transition(
                'inactive => active',
                query(
                    '.minibasket-products__item',
                    [
                        style({
                            opacity: 0,
                            transform: 'translateX(25%)',
                        }),
                        stagger(50, [
                            animate(
                                '400ms cubic-bezier(0.35, 0, 0.25, 1)',
                                style({
                                    opacity: 1,
                                    transform: 'translateX(0)',
                                })
                            ),
                        ]),
                    ],
                    { optional: true }
                )
            ),
        ]),
        trigger('removeAnimation', [
            transition(':leave', [
                style({
                    opacity: '1',
                    height: '*',
                    overflow: 'hidden',
                    transform: 'translateX(0)',
                }),
                animate(
                    '800ms cubic-bezier(0.35, 0, 0.25, 1)',
                    style({
                        opacity: '0',
                        height: '0px',
                        overflow: 'hidden',
                        transform: 'translateX(100%)',
                    })
                ),
            ]),
        ]),
    ],
})
export class MinibasketProductsComponent implements OnInit, OnDestroy {
    constructor(public translationService: TranslationService) {}

    ngOnInit() {
        setTimeout(() => {
            this.state = 'active';
        });
    }

    /**
     * Get products from parent component
     */
    @Input() basketLines: basketLineModel[];

    @Input() productImageBgColor: string;

    /**
     * Update basket event which parent should handle
     */
    @Output() updateBasket: EventEmitter<basketLineModel> = new EventEmitter();

    /**
     * Toggle state of minibasket to trigger product animation
     */
    state: string = 'inactive';

    /**
     * Emit child event upwards to parent component
     */
    onUpdateBasket(basketLine: basketLineModel): void {
        this.updateBasket.emit(basketLine);
    }

    trackByFn(index, item) {
        return item.Id;
    }

    ngOnDestroy() {
        this.state = 'inactive';
    }
}
