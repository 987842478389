import { Component, Input, ViewChild, ViewContainerRef, Compiler, Injector, NgModuleFactory } from '@angular/core';

@Component({
    selector: 'app-checkout-sublayout',
    templateUrl: './checkout-sublayout.component.html',
})
export class CheckoutSublayoutComponent {
    @Input() public data;
    @ViewChild('checkoutAnchor', { read: ViewContainerRef }) checkoutAnchor: ViewContainerRef;

    static ref = 'CheckoutSublayout';

    constructor(
        private compiler: Compiler,
        private injector: Injector
    ) {
        this.loadCheckout();
    }

    async loadCheckout() {
        const { CheckoutModule, CheckoutComponent } = await import('@features/checkout');
        const moduleFactory = CheckoutModule instanceof NgModuleFactory ? CheckoutModule
            : (await this.compiler.compileModuleAsync(CheckoutModule));
        const moduleRef = moduleFactory.create(this.injector);
        const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(CheckoutComponent);
        this.checkoutAnchor.clear();
        this.checkoutAnchor.createComponent(componentFactory);
    }
}
