import {
    Directive,
    HostBinding,
    HostListener,
    Input,
    isDevMode,
    OnChanges,
    OnDestroy,
} from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterEvent,
    UrlTree,
    QueryParamsHandling
} from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocationStrategy } from '@angular/common';
import { PaneService } from '@core/pane.service';

@Directive({
    selector: '[IRouterLink]',
})
export class IRouterLinkDirective implements OnChanges, OnDestroy {
    @HostBinding('attr.target')
    @Input()
    target: string;
    @Input() queryParams: { [k: string]: any };
    @Input() fragment: string;
    @Input() queryParamsHandling: QueryParamsHandling;
    @Input() preserveFragment: boolean;
    @Input() skipLocationChange: boolean;
    @Input() replaceUrl: boolean;
    @Input() closeDialog: boolean;
    private commands: any[] = [];
    private subscription: Subscription;
    private preserve: boolean;

    // the url displayed on the anchor element.
    @HostBinding() href: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private locationStrategy: LocationStrategy,
        private paneService: PaneService
    ) {
        this.subscription = router.events.subscribe((s: RouterEvent) => {
            if (s instanceof NavigationEnd) {
                this.updateTargetUrlAndHref();
            }
        });
    }

    @Input()
    set IRouterLink(commands: any[] | string) {
        if (commands != null) {
            this.commands = Array.isArray(commands) ? commands : [commands];
        } else {
            this.commands = [];
        }
    }

    @Input()
    set preserveQueryParams(value: boolean) {
        if (isDevMode() && <any>console && <any>console.warn) {
            console.warn(
                'preserveQueryParams is deprecated, use queryParamsHandling instead.'
            );
        }
        this.preserve = value;
    }

    ngOnChanges(changes: {}): any {
        this.updateTargetUrlAndHref();
    }
    ngOnDestroy(): any {
        this.subscription.unsubscribe();
    }

    @HostListener('click', [
        '$event.button',
        '$event.ctrlKey',
        '$event.metaKey',
        '$event.shiftKey',
    ])
    onClick(
        button: number,
        ctrlKey: boolean,
        metaKey: boolean,
        shiftKey: boolean
    ): boolean {
        if (button !== 0 || ctrlKey || metaKey || shiftKey) {
            return true;
        }

        if (typeof this.target === 'string' && this.target != '_self') {
            return true;
        }
        const isExternal = this.commands.filter(command => command.includes('http')).length > 0;
        if (isExternal) {
            this.router.navigate(this.commands);
            return false;
        }

        const extras = {
            skipLocationChange: attrBoolValue(this.skipLocationChange),
            replaceUrl: attrBoolValue(this.replaceUrl),
        };

        const urlTree = this.urlTree;

        if (urlTree.root.children.dialog && this.closeDialog) {
            this.router
                .navigate([{ outlets: { dialog: null } }], {
                    skipLocationChange: true,
                })
                .then(() => {
                    this.router.navigateByUrl(this.urlTree, extras);
                });

            return false;
        }

        this.paneService.closeAll();

        this.router.navigateByUrl(this.urlTree, extras);
        return false;
    }

    private updateTargetUrlAndHref(): void {
        this.href = this.locationStrategy.prepareExternalUrl(
            this.router.serializeUrl(this.urlTree)
        );
    }

    get urlTree(): UrlTree {

        return this.router.createUrlTree(this.commands, {
            relativeTo: this.route,
            queryParams: this.queryParams,
            fragment: this.fragment,
            preserveQueryParams: attrBoolValue(this.preserve),
            queryParamsHandling: this.queryParamsHandling,
            preserveFragment: attrBoolValue(this.preserveFragment),
        });
    }
}

function attrBoolValue(s: any): boolean {
    return s === '' || !!s;
}
