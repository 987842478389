<div class="container {{ templateName }} {{ mobileLayout }}"
     [ngClass]="{
        'container_to-edges': data.NoSidePadding,
        'sublayout_background': data.BackgroundColor,
        'collapse-content-container': data.CollapseContent
     }"
    [style.backgroundColor]="data.BackgroundColor"
>
    <div class="sublayout"
        [ngClass]="{
            'sublayout_shadow': data.HasShadow,
            'collapse-content': data.CollapseContent,
            'collapse-content-toggle': collapseContentToggle
        }"
        [style.height.px]="collapseContentToggle ? nonCollapsedContentHeight : data.CollapseHeight"
    >
        <article class="columns is-multiline" [ngClass]="{'sublayout_max-width': data.BackgroundColor}">
            <div class="column is-2-tablet">
                <div class="columns column is-equal-height">
                    <div *ngFor="let spot of data.Column1Spots" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName" [backgroundColor]="data.BackgroundColor"></div>
                </div>
            </div>
            <div class="column is-5-tablet">
                <div class="columns column is-equal-height">
                    <div *ngFor="let spot of data.Column2Spots" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName" [backgroundColor]="data.BackgroundColor"></div>
                </div>
            </div>
            <div class="column is-5-tablet">
                <div class="columns column is-equal-height">
                    <div *ngFor="let spot of data.Column3Spots" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName" [backgroundColor]="data.BackgroundColor"></div>
                </div>
            </div>
        </article>
    </div>
    <div class="collapse-content-button-container">
        <button
            type="button"
            class="button button_icon button_clean button__icon-go-arrow collapse-content__button"
            (click)="toggleCollapseContent()"
            *ngIf="data.CollapseContent"
        >
            <span style="display: inline-flex; margin-right: 8px;" [innerHTML]="icon.goArrow | safeHtml"></span>
            {{collapseContentToggle ? (translation.translations$ | async)?.CollapseContent?.General?.ShowLess : (translation.translations$ | async)?.CollapseContent?.General?.ShowMore}}
        </button>
    </div>
</div>
