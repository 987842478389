import {Injectable} from '@angular/core';
import {
    IProfitMetrics,
    IProfitMetricsAddToCart,
    IProfitMetricsPageViewCategory,
    IProfitMetricsPageViewProduct,
    IProfitMetricsService
} from '@core/tracking/profit-metrics.interface';

@Injectable({
    providedIn: 'root'
})
export class ProfitMetricsService implements IProfitMetricsService {

    private profitMetricsPromise: Promise<IProfitMetrics> | null;

    constructor() {
        this.profitMetricsPromise = null;
    }

    trackPageViewGeneric(): void {
        this.getProfitMetrics()
            .then(profitMetrics => {
                profitMetrics.pageViewGeneric();
            });
    }

    trackPageViewCategory(category: IProfitMetricsPageViewCategory): void {
        this.getProfitMetrics()
            .then(profitMetrics => {
                profitMetrics.pageViewCategory(category.name);
            });
    }

    trackPageViewProduct(product: IProfitMetricsPageViewProduct): void {
        this.getProfitMetrics()
            .then(profitMetrics => {
                profitMetrics.pageViewProduct(product.sku, product.name);
            });
    }

    trackAddToCart(product: any, amount: number): void {
        let activeColorGroupTracking;

        if (product.ColorGroups && product.ActiveColorGroupId) {
            activeColorGroupTracking = product.ColorGroups.find(group => {
                return product.ActiveColorGroupId === group.ColorId;
            }) ?? product.ColorGroups[0];
        } else if (product.ColorGroups && !product.ActiveColorGroupId) {
            activeColorGroupTracking = product.ColorGroups.find(group => {
                return group.Skus.find(sku => {
                    return product.SkuId === sku.Id;
                });
            });
        } else {
            activeColorGroupTracking = product;
        }

        const products: IProfitMetricsAddToCart = {
            id: product.SkuId,
            name: product.Name,
            price: product.UnitPrice
                ? product.UnitPrice
                : !!activeColorGroupTracking.SalePrice && activeColorGroupTracking.SalePrice > 0
                    ? activeColorGroupTracking.SalePrice : activeColorGroupTracking.Price,
            quantity: amount
        };

        this.getProfitMetrics()
            .then(profitMetrics => {
                profitMetrics.addToCart([products]);
            });
    }

    setEmail(email: string): void {
        this.getProfitMetrics()
            .then((profitMetrics) => {
                profitMetrics.setEmail(email);
            });
    }

    private getProfitMetrics(): Promise<IProfitMetrics> {
        if (this.profitMetricsPromise) {
            return (this.profitMetricsPromise);
        }

        if (window.profitMetrics.pageViewGeneric) {
            return (this.profitMetricsPromise = Promise.resolve(window.profitMetrics));
        }

        if (window.document.readyState === 'complete') {
            return (this.profitMetricsPromise = Promise.resolve(new NoProfitMetrics()));
        }

        this.profitMetricsPromise = new Promise<IProfitMetrics>((resolve) => {
            window.addEventListener('load', function handleWindowLoad() {
                resolve(window.profitMetrics.pageViewGeneric ? window.profitMetrics : new NoProfitMetrics());
            });
        });

        return (this.profitMetricsPromise);
    }
}

// tslint:disable-next-line:max-classes-per-file
class NoProfitMetrics implements IProfitMetrics {
    constructor() {
        console.warn('Tracking API for ProfitMetrics is not available.');
    }

    addToCart(products: IProfitMetricsAddToCart[]): void {
        console.log('ProfitMetrics API not available. Add to cart tracking not working.');
    }

    pageViewCategory(categoryName: string): void {
        console.log('ProfitMetrics API not available. View Category Page tracking not working.');
    }

    pageViewGeneric(): void {
        console.log('ProfitMetrics API not available. View Generic Page tracking not working.');
    }

    pageViewProduct(productName: string, sku: string): void {
        console.log('ProfitMetrics API not available. View Product Page tracking not working.');
    }

    setEmail(email: string): void {
        console.log('ProfitMetrics API not available. Setting the email is not possible.');
    }
}
