import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    Inject,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '@core/seo';
import { pageComponents } from '@features/pages/pages';
import { PageService } from '@core/page.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { UserService } from '@core/user.service';
import { BasketService } from '@core/basket.service';
import {
    personalizationBarType,
    PersonalizationBarService,
} from '@core/personalization-bar.service';
import { store } from '@shared/svg';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {ProfitMetricsService} from '@core/tracking/profit-metrics.service';
import {HeaderService} from '@core/header.service';
import {SiteContextService} from '@core/site-context.service';
import {BreakpointObserver} from '@angular/cdk/layout';

/**
 * Generic component in charge of rendering the correct page component
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <link
            *ngIf="canonical && !isFilterPage"
            rel="canonical"
            moveToHead
            [attr.href]="canonicalUrl()"
        />
        <link
            *ngFor="let langHref of hrefLangs"
            rel="alternate"
            moveToHead
            [hreflang]="langHref.LanguageCode === 'se' ? 'sv' : langHref.LanguageCode"
            [href]="sanitizeUrl(langHref.Url)"
        />
    `, // Template cannot be empty,
})
export class PageComponent implements OnInit, OnDestroy {
    canonical: string;
    hrefLangs: any[];
    isFilterPage: boolean;

    private destroy = new Subject();
    /**
     * Injects our dependencies
     * @param {ActivatedRoute} activatedRoute
     * @param {ComponentFactoryResolver} cfResolver
     * @param {ViewContainerRef} viewContainer
     * @param {SeoService} seoService
     * @param pageService
     * @param tracking
     * @param changeRef
     * @param sanitizer
     * @param userService
     * @param basketService
     * @param platformId
     * @param personalizationService
     * @param profitMetricsService
     * @param headerService
     * @param siteContextService
     * @param breakpointObserver
     */
    constructor(
        private activatedRoute: ActivatedRoute,
        private cfResolver: ComponentFactoryResolver,
        private viewContainer: ViewContainerRef,
        private seoService: SeoService,
        private pageService: PageService,
        private tracking: TrackingService,
        private changeRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private userService: UserService,
        private basketService: BasketService,
        @Inject(PLATFORM_ID) private platformId,
        private personalizationService: PersonalizationBarService,
        private profitMetricsService: ProfitMetricsService,
        private headerService: HeaderService,
        private siteContextService: SiteContextService,
        private breakpointObserver: BreakpointObserver,
    ) {}

    /**
     * Detects our page component, resolves and renders it.
     */
    ngOnInit() {
        // Read the content from the route snapshot ('content' is the name of the resolve)
        const data = this.activatedRoute.snapshot.data['content'];

        if (!data) {
            return;
        }

        if (isPlatformBrowser(this.platformId)) {
            this.activatedRoute.queryParams.subscribe(params => {
                if (
                    Object.keys(params).length &&
                    params.hasOwnProperty(
                        this.basketService.basketIdLocalstorageName()
                    )
                ) {
                    localStorage.setItem(
                        this.basketService.basketIdLocalstorageName(),
                        params['basketId']
                    );
                    this.basketService.fetchBasket();
                }

                if (
                    Object.keys(params).length &&
                    params.hasOwnProperty('login')
                ) {
                    console.log('show login dialog');
                }

                if (
                    Object.keys(params).length &&
                    params.hasOwnProperty('sl_h')
                ) {
                    const email = params['sl_h'];
                    const storedEmail = this.userService.userEmailLocalstorage;
                    if (email !== storedEmail) {
                        this.userService.getUserName(email).subscribe(user => {
                            // Personalization for soft login
                            this.personalizationService.getPersonalizationBars(
                                personalizationBarType.profile
                            );
                        });
                    }
                }
                if (
                    Object.keys(params).length &&
                    params.hasOwnProperty('segment')
                ) {
                    this.userService.userSegmentLocalstorage =
                        params['segment'];
                }
            });

            this.breakpointObserver.observe('(min-width: 1221px)').subscribe(value => {
                if (value.matches) {
                    if (data.pageData.TemplateName.toLowerCase() === 'homepage' && this.siteContextService.getContext().generalSiteSettings.TransparentHeader) {
                        (window as any).document.body.classList.add('transparent-header');
                    } else {
                        (window as any).document.body.classList.remove('transparent-header');
                    }
                } else {
                    (window as any).document.body.classList.remove('transparent-header');
                }
            });

            if (
                data.pageData.TemplateName.toLowerCase() !== 'filter page' ||
                data.pageData.TemplateName.toLowerCase() !== 'product page'
            ) {
                this.profitMetricsService.trackPageViewGeneric();
            }

            if (data.pageData.TemplateName.toLowerCase() === 'filter page') {
                this.profitMetricsService.trackPageViewCategory({
                    name: data.pageData.FilterPageTitle,
                });
            }

            // Tracking
            this.tracking.sendPromotionImpressions(data.content);

            // Tracking page views
            const basketId = this.basketService.getCurrentBasket().Id;
            if (basketId) {
                this.tracking.sendPageView(data.pageData, basketId);
            } else {
                this.basketService.basket$
                    .pipe(takeUntil(this.destroy))
                    .subscribe(basket => {
                        if (basket.Id) {
                            this.tracking.sendPageView(data.pageData, basket.Id);
                        }
                    });
            }
        }

        this.pageService.page = data;
        this.seoService.setTags(data.pageData);

        this.isFilterPage = data.pageData.TemplateName.toLowerCase() === 'filter page';

        // Find the ComponentClass of the desired pageComponent (based on template)
        const ComponentClass = pageComponents.find(
            component => component.ref === data.pageData['TemplateName']
        );

        if (!ComponentClass) {
            console.warn("Couldn't find a matching template for this route");
            return;
        }

        // Resolve the ComponentFactory
        const pageComponentFactory = this.cfResolver.resolveComponentFactory(
            ComponentClass
        );

        // Create the component, attach it to the viewContainer and bind the data
        const pageComponent = this.viewContainer.createComponent(
            pageComponentFactory
        );
        pageComponent.instance['data'] = data.content;
        pageComponent.instance['breadcrumbFloat'] =
            data.pageData.BreadcrumbFloat === false ||
            data.pageData.BreadcrumbFloat === true
                ? data.pageData.BreadcrumbFloat
                : true;

        if (data.pageData.CanonicalUrl) {
            this.canonical = data.pageData.CanonicalUrl;

            this.changeRef.detectChanges();
        }

        if (data.pageData.HreflangList) {
            this.hrefLangs = data.pageData.HreflangList;

            this.changeRef.detectChanges();
        }
    }

    canonicalUrl() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.canonical);
    }

    sanitizeUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    ngOnDestroy(): void {
        this.headerService.headerSolidState = false;
        this.destroy.next();
        this.destroy.complete();
    }
}
