import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { TrackingService } from '@core/tracking/tracking.service';
import { getFilename, isIe } from '@shared/utility';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { PlyrComponent } from '@features/plyr-video/plyr/plyr.component';
import { isPlatformBrowser } from '@angular/common';
import { arrowRight } from '@shared/svg';

@Component({
    selector: 'app-video-spot',
    templateUrl: './video-spot.component.html',
    host: {
        '[class]':
            '"column " + (data.HideOnMobile ? "is-hidden-mobile " : "") + (data.HideOnDesktopTablet ? "is-hidden-tablet " : "")',
    },
})
export class VideoSpotComponent implements OnInit, OnDestroy {
    static ref = 'Video Spot';
    @Input() public data;
    @Input() public templateType;

    @ViewChild(PlyrComponent) plyrComponent: PlyrComponent;
    noText: boolean;
    imagePathName = getFilename;
    sitecoreId: string;
    hideFromSearchEngines = false;
    inView = false;
    isTabletMobile: boolean;

    rightArrow = arrowRight;

    videoConfig: any;
    showPosterImage: boolean;
    videoSourcesMP4: any;
    videoSourcesYoutube: any;
    videoSourcesVimeo: any;
    moodVideo = false;
    hidePoster = false;

    startedPlay = false;
    played25 = false;
    played50 = false;
    played75 = false;
    played100 = false;
    spotName: string;

    constructor(
        private tracking: TrackingService,
        @Optional()
        @Inject('isBot')
        private isBot: boolean,
        private breakpointObserver: BreakpointObserver,
        @Inject(PLATFORM_ID) private platformId
    ) { }

    onInView(bool: boolean) {
        if (isPlatformBrowser(this.platformId)) {
            this.inView = isIe() || bool;
            if (!this.plyrComponent || !this.plyrComponent.player) {
                return;
            }

            if (
                this.inView &&
                this.data.Autoplay &&
                !this.plyrComponent.player.playing
            ) {
                this.plyrComponent.player.play();
            } else if (
                !this.inView &&
                !this.moodVideo &&
                this.plyrComponent.player.playing
            ) {
                this.plyrComponent.player.pause();
            }
        }

    }

    trackClick() {
        this.tracking.sendPromotionClick(this.data);
    }

    ngOnInit(): void {
        if (
            this.data.PosterImage === '' &&
            this.data.Autoplay &&
            this.data.HidePlayerControl &&
            this.data.Loop &&
            this.data.Mute
        ) {
            this.moodVideo = true;
        }

        this.spotName = this.data.Heading ? this.data.Heading : this.data.Id;

        this.breakpointObserver
            .observe(['(max-width: 768px)'])
            .subscribe((state: BreakpointState) => {
                this.isTabletMobile = state.matches;
            });

        this.noText =
            this.data.Heading.length > 0 && this.data.SubHeadingText.length > 0;

        if (this.isBot) {
            this.hideFromSearchEngines = this.data.HideFromSearchEngines;
        }

        this.videoSourcesYoutube = [
            {
                src: this.data.Youtube,
                provider: 'youtube',
            },
        ];

        this.videoSourcesMP4 = [
            {
                src: this.data.MP4,
                type: 'video/mp4',
            },
        ];

        this.videoSourcesVimeo = [
            {
                src: this.data.Vimeo,
                provider: 'vimeo',
            },
        ];

        this.videoConfig = {
            settings: ['speed', 'quality'],
            mute: this.data.Mute,
            muted: this.data.Mute,
            autoplay: this.data.Autoplay,
            loop: {
                active: this.data.Loop,
            },
            fullscreen: {
                enabled: !this.moodVideo,
            },
        };

        this.showPosterImage = this.data.PosterImage !== '';
    }


    played(event: Plyr.PlyrEvent) {
        this.plyrComponent.player.muted = this.data.Mute;
        this.showPosterImage = false;
        if (!this.data.Mute) {
            this.plyrComponent.player.volume = 0.4;
        }
    }

    seeking(event: Plyr.PlyrEvent) {
        if (event.detail.plyr.currentTime === 0) {
            this.resetDurationMarkers();
        }
    }

    ngOnDestroy() {
        if (isPlatformBrowser(this.platformId)) {
            this.breakpointObserver.ngOnDestroy();

            if (document.getElementsByTagName('video').length) {
                document.getElementsByTagName('video')[0].style.filter =
                    'opacity(0%)';
            }

            if (this.plyrComponent && this.plyrComponent.player) {
                this.plyrComponent.player.destroy();
            }
        }
    }

    playerEnded(event: Plyr.PlyrEvent) {
        if (this.data.PosterImage) {
            this.showPosterImage = true;
        }
        this.tracking.sendVideoDuration('100%', this.spotName);
        this.resetDurationMarkers();
    }

    timeUpdate(event: Plyr.PlyrEvent) {
        const eventPlyr = event.detail.plyr;
        if (event.detail.plyr.duration === 0 || event.detail.plyr.currentTime === 0) {
            return;
        }
        const roundedCurrentTime = Math.floor(
            eventPlyr.currentTime
        );

        if (!this.startedPlay) {
            // track: Play
            this.startedPlay = true;
            this.tracking.sendVideoDuration('Play', this.spotName);
        } else if (
            !this.played25 &&
            Math.floor(eventPlyr.duration * 0.25) === roundedCurrentTime
        ) {
            // track: 25%
            this.played25 = true;
            this.tracking.sendVideoDuration('25%', this.spotName);
        } else if (
            !this.played50 &&
            Math.floor(eventPlyr.duration * 0.50) === roundedCurrentTime
        ) {
            // track: 50%
            this.played50 = true;
            this.tracking.sendVideoDuration('50%', this.spotName);
        } else if (
            !this.played75 &&
            Math.floor(eventPlyr.duration * 0.75) === roundedCurrentTime
        ) {
            // track: 75%
            this.played75 = true;
            this.tracking.sendVideoDuration('75%', this.spotName);
        } else if (
            this.data.Loop &&
            !this.played100 &&
            eventPlyr.currentTime >= eventPlyr.duration * 0.95
        ) {
            // track: 100%
            this.played100 = true;
            this.tracking.sendVideoDuration('100%', this.spotName);
        }
    }
    private resetDurationMarkers() {
        this.startedPlay = false;
        this.played25 = false;
        this.played50 = false;
        this.played75 = false;
        this.played100 = false;
    }

    public videoLink() {
        if (isPlatformBrowser(this.platformId)) {
            if (this.moodVideo && this.data.Link) {
                window.location.href = this.data.Link;
            }
        }
    }
}
