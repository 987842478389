import { finalize } from 'rxjs/operators';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { cross, password } from '@shared/svg';
import { Component, Input } from '@angular/core';
import { AlertResponse, AlertType } from '@shared/alert/alert.interface';
import { BasketService } from '@core/basket.service';
import { ImpactCoreBasketsBasketServiceModel as basketModel } from '@shared/swagger/swagger.interface';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-minibasket-voucher',
    templateUrl: './minibasket-voucher.component.html',

    animations: [
        trigger('slideInOutAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateX(100%)',
                }),
                animate(
                    '800ms cubic-bezier(0.35, 0, 0.25, 1)',
                    style({
                        opacity: 1,
                        transform: 'translateX(0)',
                    })
                ),
            ]),
        ]),
    ],
})
export class MinibasketVoucherComponent {
    constructor(
        private basketService: BasketService,
        private spinnerService: SpinnerService,
        public translationService: TranslationService
    ) {}

    /**
     * Basket input from service
     */
    @Input()
    basket: basketModel;

    /**
     * Set submitted to prevent multiple form triggers
     */
    submitted: boolean = false;

    /**
     * Set initial model for submit
     */
    voucher = {
        code: '',
    };

    /**
     * Prep icons for voucher
     */
    icons = {
        password,
        cross,
    };

    /**
     * Setup alert response for service success or error
     */
    response: AlertResponse = {};

    /**
     * Add voucher on enter
     */
    onVoucher(event: Event): void {
        if (this.voucher.code.length > 0 && !this.submitted) {
            this.submitted = true;
            // this.spinnerService.show('minibasketVoucherSpinner');

            this.basketService
                .addVoucher(this.voucher.code)
                .pipe(
                    finalize(() => {
                        this.submitted = false;
                        // this.spinnerService.hide('minibasketVoucherSpinner');
                    })
                )
                .subscribe(
                    () => {
                        this.voucher.code = '';
                        this.response = {};
                        (event.target as HTMLInputElement).blur();
                    },
                    ({ error }) => {
                        this.response = {
                            type: AlertType.error,
                            text: error.ErrorMessage,
                        };
                    }
                );
        }
    }

    /**
     * Remove voucher on delete
     */
    removeVoucher(): void {
        this.basketService.removeVoucher();
    }

    closeAlert = () => {
        this.response = {};
    };
}
