import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImpactCoreModelsProductsDtoStyleDto as ProductStyle } from '@shared/swagger/swagger.interface';
import { SiteContextService } from '@core/site-context.service';
import { flatMap } from 'rxjs/operators';
import { BasketService } from './basket.service';

@Injectable({
    providedIn: 'root',
})
export class UpsellingService {
    constructor(
        private http: HttpClient,
        private basketService: BasketService,
        private siteContextService: SiteContextService
    ) {}
    getUpsellingProducts() {
        return this.basketService.basket$.pipe(
            flatMap(basket =>
                this.http.post<ProductStyle[]>(
                    `${this.siteContextService.getRootUrl()}/webapi/MiniBasket/UpsaleProducts${
                        basket.Id ? `?basketId=${basket.Id}` : ''
                    }`,
                    {}
                )
            )
        );
    }
}
