import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    backAnimation,
    flyOutFlyInAnimation,
} from './flyout-flyin-menu.animation';
import { AnimationEvent, trigger } from '@angular/animations';
import { NavigationService } from '@core/navigation.service';
import { arrowRight90, close, heart, linkArrow, plus, store, user } from '@shared/svg';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { Subject } from 'rxjs';
import { TrackingService } from '@core/tracking/tracking.service';
import {PaneService} from '@core/pane.service';
import { ImpactCoreModelsDtoMetaMenuMetaMenuItemDto, ImpactCoreModelsDtoNavigationsMainMenuItemDto, ImpactCoreNavigationModelNavigationViewModel } from '@shared/swagger/swagger.interface';
import { BusinessDimensions, SiteContextService } from '@core/site-context.service';
import { ISiteContext } from '@core/site-context.interface';
import {getVwoId} from '@shared/utility';

/**
 * Renders our mobile menu component.
 */
@Component({
    selector: 'mobile-menu',
    templateUrl: './mobile-menu.component.html',
    animations: [
        trigger('listAnimation', flyOutFlyInAnimation),
        trigger('backAnimation', backAnimation),
    ],
})
export class MobileMenuComponent implements OnInit {

    /**
     * Injects our dependencies
     * @param {ImpactOverlayRef} overlayRef
     * @param {Router} router
     * @param {NavigationService} navService
     * @param {TrackingService} tracking
     * @param paneService
     * @param siteContextService
     */
    constructor(
        private overlayRef: ImpactOverlayRef,
        private router: Router,
        private navService: NavigationService,
        private tracking: TrackingService,
        private paneService: PaneService,
        private siteContextService: SiteContextService,
    ) {

        this.context = this.siteContextService.getContext();
        this.setupBusinessDomainLogic(this.context) ;
        this.businessDimension  = this.context.businessDimension.toLowerCase();
    }
    // Trigger animation
    public animationStateChanged = new EventEmitter<AnimationEvent>();
    public startExitTrigger = new Subject();

    iconSettingsOuterAccordion: IconSettings;
    iconSettingsInnerAccordion: IconSettings;

    plusIcon = plus;

    currentTabOuter = -1;
    currentTabInner = -1;
    context: ISiteContext;
    businessDimension: string;

    /**
     * Holds the menu items
     * @type {ImpactCoreModelsDtoNavigationsMainMenuItemDto[]}
     */
    menuItems: ImpactCoreModelsDtoNavigationsMainMenuItemDto[] = [];


    /**
     * Holds the secondary menu items (The Journal, Kaufmann Business etc.)
     * @type {ImpactCoreModelsDtoNavigationsMainMenuItemDto[]}
     */
     secondaryMainMenu: ImpactCoreModelsDtoNavigationsMainMenuItemDto[];


    /**
     * Holds the current levels menu items
     * @type {any[]}
     */
    levels = [];

    /**
     * Icons used in the meta menu
     *
     * @TODO: Should come from a service rather than dummy content
     */
    icons = {
        close,
        store,
        heart,
        user,
        arrow: linkArrow,
    };

    /**
     * Placeholder meta menu items
     */
    metaMenu: ImpactCoreModelsDtoMetaMenuMetaMenuItemDto[];

    vwoId = getVwoId;

    outerTab = 'mobile-menu-outer-tab';
    innerTab = 'mobile-menu-inner-tab';

    public animationStateChange(event): void {
        this.animationStateChanged.emit(event);

    }

    public startExitAnimation(): void {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }

    /**
     * Sets up our mobile menu content, and subscribes to the close event of the overlay for animating out.
     */
    ngOnInit() {

        this.context = this.siteContextService.getContext();
        this.setupBusinessDomainLogic(this.context);
        this.loadLastTabLocations();

        this.navService.getMainNav().subscribe( (menu: ImpactCoreNavigationModelNavigationViewModel) => {
            this.menuItems = menu.MainMenuViewModel.MainMenu;
            this.secondaryMainMenu = menu.SecondaryMainMenuViewModel.MainMenu;
            this.metaMenu = menu.MetaMenuViewModel.MetaMenu;
            this.levels = [this.menuItems];
        });
    }


    setupBusinessDomainLogic(context: ISiteContext) {
        this.iconSettingsOuterAccordion = {
            icon: plus,
            iconLocation: 'right'
        };

        switch (context.businessDimension) {
            case BusinessDimensions.AXEL:
                this.iconSettingsInnerAccordion = {
                    icon: plus,
                    iconLocation: 'right'
                };
                break;
            case BusinessDimensions.KAUFMANN:
            case BusinessDimensions.QUINT:
                this.iconSettingsInnerAccordion = {
                    icon: arrowRight90,
                    iconLocation: 'left'
                };
                break;
            default:
                console.warn(
                    'Setup mobile menu settings: Product does not match any business dimension'
                );
                break;
        }
    }

    /**
     * Handles clicks on the meta menu
     */
    metaClick() {
        this.overlayRef.close();

        setTimeout(() => {
            this.levels = [this.menuItems];
        }, 200);
    }

    /**
     * app-find-store-content
     * Handles clicks on a menu item
     * @param event
     * @param itemapp-find-store-content
     * @returns {boolean}
     */
    menuClick(event, item) {
        const linkEle = event.target;

        // Handle children
        if (item && item.Subitems && item.Subitems.length) {
            this.levels.push(item.Subitems);
            return false;
        }

        const url = new URL(
            item.NavigationLink,
            location.protocol + '//' + location.host
        );

        this.tracking.sendNavigationEvent('Main menu', item.NavigationLink);

        // Eject if the linkElement has a download-attribute
        if (linkEle.getAttribute('download') !== null) {
            return;
        }

        // Eject if link has a target
        if (item.target === '_blank') {
            return;
        }

        // Eject if link points to another domain
        if (url.host !== location.host) {
            return;
        }

        this.overlayRef.close();

        // Changing menu items back after its closed
        setTimeout(() => {
            this.levels = [this.menuItems];
        }, 200);


        // If you are still here, the router will take over and do the navigation
        this.router.navigateByUrl(url.pathname);
        return false;
    }

    /**
     * Goes back one level
     */
    goBack() {
        if (this.levels.length > 1) {
            this.levels.pop();
        }
    }

    /**
     * Closes the menu
     */
    close() {
        this.overlayRef.close();
    }

    // Keep track of active accordions
    accordionOuterChange(tabId: number) {
        this.currentTabInner = -1;
        this.currentTabOuter = tabId === this.currentTabOuter ? -1 : tabId;
        // TODO: Make this work to omptimize the sidebar position when an accordion is toggled
        // this.setSidebarHeight();
        this.saveCurrentInnerAndOuterLocation();
    }

    // Keep track of active accordions
    accordionInnerChange(tabId: number) {
        this.currentTabInner = tabId === this.currentTabInner ? -1 : tabId;
        // TODO: Make this work to omptimize the sidebar position when an accordion is toggled
        // this.setSidebarHeight();
        this.saveCurrentInnerAndOuterLocation();
    }

    saveCurrentInnerAndOuterLocation() {
        localStorage.setItem(this.outerTab, String(this.currentTabOuter));
        localStorage.setItem(this.innerTab, String(this.currentTabInner));
    }

    loadLastTabLocations() {
        this.currentTabInner = Number(localStorage.getItem(this.innerTab) ?? -1);
        this.currentTabOuter = Number(localStorage.getItem(this.outerTab) ?? -1);
    }
}

export interface IconSettings {
    icon: string;
    iconLocation: string;
}
