import {
    Component,
    EventEmitter,
    Inject,
    InjectionToken,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
} from '@angular/core';
import {PaginationFacet} from '@features/filter/models/facet.model';
import {SpinnerService} from '@shared/spinner/spinner.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslationService} from '@core/translation.service';
import {platformBrowser} from '@angular/platform-browser';

@Component({
    selector: 'app-pagination',
    template: `
        <div class="more-products">
            <div class="progress">
                <div class="progress__figures" [innerHtml]="ofTotalTranslation(translations?.Filter?.Content?.NumberOfProductsOfTotalProductsFound) | safeHtml"></div>
                <div class="progress__bar">
                    <div [style.width]="progressBarPercentage" class="progress__bar-indicator"></div>
                </div>
                <button
                    type="button"
                    class="button button_outline button_text-center"
                    (click)="nextPage($event)"><span *ngIf="!isSpinning">{{translations?.Filter?.Content?.GetMoreProducts}}</span><spinner (showChange)="showChange($event)" name="paginationSpinner" [size]="20"></spinner></button>
            </div>
        </div>
    `,
})
export class PaginationComponent implements OnInit, OnDestroy {
    @Input()
    pagination: PaginationFacet;
    @Input()
    totalResults: number;
    @Input()
    productsOnPage: number;
    @Input()
    progressBarPercentage: string;

    @Output()
    onNextPage = new EventEmitter<PaginationFacet>();

    public translations: any;
    private unSubscribe = new Subject();
    public isSpinning = false;

    constructor(
        public spinnerService: SpinnerService,
        private translationService: TranslationService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {}

    ngOnInit() {
        this.translationService.translations$
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(translations => {
                this.translations = translations;
            });
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }

    nextPage(event) {
        // Fix to prevent chrome from doing scroll anchoring
        // Issue: https://jira.impact.dk/browse/KFM-2133
        // Solution: https://stackoverflow.com/questions/63396493/chrome-auto-scrolling-page-when-content-adds-via-js
        if (platformBrowser(this.platformId)) {
            if (event.target.type === 'button') {
                event.target.blur();
            } else if (event.target.offsetParent.type === 'button') {
                event.target.offsetParent.blur();
            }
        }
        /*******/

        this.spinnerService.show('paginationSpinner');
        this.pagination.pageIndex = this.pagination.pageIndex + 1;
        this.onNextPage.emit(this.pagination);
    }

    ofTotalTranslation(translation: string) {
        const replacedTranslation = translation
            ? translation
                  .replace(
                      '#CURRENTPRODUCTS#',
                      '<b>' + this.productsOnPage + '</b>'
                  )
                  .replace(
                      '#TOTALPRODUCTS#',
                      '<b>' + this.totalResults + '</b>'
                  )
            : '';

        return replacedTranslation;
    }

    showChange(event) {
        this.isSpinning = event;
    }
}
