// import { Component, Input, ViewChild, ViewContainerRef, NgModuleFactory, Compiler, Injector } from '@angular/core';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-product-filter-spot',
    templateUrl: './product-filter-spot.component.html',
})
export class ProductFilterSpotComponent {
    static ref = 'Filter spot';
    // @ViewChild('productListAnchor', { read: ViewContainerRef }) productListAnchor: ViewContainerRef;
    // @ViewChild('productFilterAnchor', { read: ViewContainerRef }) productFilterAnchor: ViewContainerRef;

    // TODO: All commented code in this component is related to lazy loading this feature.

    @Input()
    public data;

    constructor(
        // private compiler: Compiler,
        // private injector: Injector
    ) {
        // this.loadProductFilter();
        // this.loadProductList();
    }

    // async loadProductFilter() {
    //     const { ProductFilterModule, ProductFilterComponent } = await import('@features/product-filter');
    //     const moduleFactory = ProductFilterModule instanceof NgModuleFactory ? ProductFilterModule
    //         : (await this.compiler.compileModuleAsync(ProductFilterModule));
    //     const moduleRef = moduleFactory.create(this.injector);
    //     const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(ProductFilterComponent);
    //     this.productFilterAnchor.clear();
    //     this.productFilterAnchor.createComponent(componentFactory);
    // }

    // async loadProductList() {
    //     const { ProductListModule, ProductListComponent } = await import('@features/product-list');
    //     const moduleFactory = ProductListModule instanceof NgModuleFactory ? ProductListModule
    //         : (await this.compiler.compileModuleAsync(ProductListModule));
    //     const moduleRef = moduleFactory.create(this.injector);
    //     const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(ProductListComponent);
    //     this.productListAnchor.clear();
    //     this.productListAnchor.createComponent(componentFactory);
    // }
}
