import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrustPilotComponent } from '@features/trust-pilot/trust-pilot.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [TrustPilotComponent],
    entryComponents: [TrustPilotComponent],
    exports: [TrustPilotComponent],
})
export class TrustPilotModule {}
