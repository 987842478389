import { Component, Input, OnInit } from '@angular/core';
import { PageService } from '@core/page.service';
import { take } from 'rxjs/operators';

/**
 * Template for a subpage
 */
@Component({
    selector: 'brand-filter-page',
    template: `
        <app-breadcrumb [floated]="breadcrumbFloat"></app-breadcrumb>

        <div class="filter">
            <div class="filter__header filter__header_spacing">
                <div class="container container_padded">
                    <div class="filter-header">
                        <h1 class="headline filter-header__heading">{{pageContent?.pageData?.FilterPageTitle}}</h1>
                    </div>
                </div>
            </div>

            <div class="filter__image" *ngIf="pageContent?.pageData?.BrandImageUrl">
                <div class="container">
                    <div class="lazy-load" [style.paddingBottom.%]="(pageContent?.pageData?.ImageHeight / pageContent?.pageData?.ImageWidth) * 100">
                        <picture>
                            <source media="(max-width: 480px)" srcset="{{pageContent?.pageData?.BrandImageUrl}}?w=480">
                            <source media="(min-width: 481px) and (max-width: 768px)" srcset="{{pageContent?.pageData?.BrandImageUrl}}?w=768">
                            <source media="(min-width: 769px)" srcset="{{pageContent?.pageData?.BrandImageUrl}}?w=1680">
                            <img alt="{{altTagText}}" src="{{pageContent?.pageData?.BrandImageUrl}}?w=1680">
                        </picture>
                    </div>
                </div>
            </div>
        </div>

        <spots [spots]="data"></spots>
        <app-footer *ngIf="showFooter"></app-footer>
    `,
})
export class BrandFilterPageComponent implements OnInit {
    altTagText: string;

    constructor(private pageService: PageService) {}

    ngOnInit() {
        this.pageService.page.pipe(take(1)).subscribe(data => {
            this.pageContent = data;

            this.altTagText = data.pageData.FilterPageTitle
                ? data.pageData.FilterPageTitle
                : this.getFilename(data.pageData.BrandImageUrl);
        });
    }

    getFilename(path) {
        const filePathShards = path.split('/');

        return filePathShards[filePathShards.length - 1];
    }

    /**
     * Page content
     */
    @Input() public data;

    @Input() public showFooter = true;

    @Input() public breadcrumbFloat = true;

    pageContent: any = {};

    /**
     * Reference to resolve the correct component
     * @type {string}
     */
    static ref = 'Brand filter page';
}
