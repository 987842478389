<a
    *ngFor="let item of metaMenu | metaMenuItemsPipe: isLoggedIn"
    (click)="openRightPane(item)"
    [title]="item.Title"
    class="meta-menu__item {{item.AngularKey}}"
    [class.active]="item.AngularKey === (paneService.activeKey$ | async)"
    [class.is-hidden-touch]="(item.HideOnMobile && !isMobile) || (!item.HideOnMobile && isMobile)">
    <span class="meta-menu__link-text" *ngIf="!isMobile">{{item.Title}}</span>
    <app-svg
        [src]="item.Image"
        size="'normal'"
        class="meta-menu__icon"
        [class.wishlist-icon_active]="item.AngularKey === 'wishlist' && !!totalFavorites"
    ></app-svg>
    <span class="badge minibasket-badge" *ngIf="item.AngularKey === 'wishlist' && !!totalFavorites">{{totalFavorites}}</span>
    <span class="badge minibasket-badge" app-minibasket-badge *ngIf="item.AngularKey === 'basket'"></span>
</a>
