<section class="product-spot" #spot role="region">
    <h1 class="sr-only" id="product-name">{{productData?.Name}}</h1>
    <div class="product-spot__content-wrapper">
        <div class="product-spot__content">
            <div class="product-spot__hero">
                <!--  PRODUCT DETAILS HERO SPOT -->
                <div class="product-spot__hero-content" aria-describedby="product-name" #productSpotContent>
                    <div class="product-spot__image-gallery">

                        <!-- PRODUCT DETAILS IMAGE SLIDER -->
                        <product-details-image-slider
                            [activeImageIndex]="activeProductImageIndex"
                            [productImages]="activeProduct?.activeProductVariant?.Images"
                            [productImageDescription]="productImageAltText"
                            [product]="activeProduct">
                        </product-details-image-slider>

                        <!-- PRODUCT SPOT SIDEBAR -->
                        <div class="product-spot__sidebar" #sidebar aria-label="Product information, primary">
                            <div #sidebarContentWrapper>
                                <div class="product-spot-sidebar__top">
                                    <app-favorite-picker [styleId]="styleId" [colorId]="colorId"></app-favorite-picker>
                                    <h2 class="product-spot-sidebar__header">
                                        <ng-container
                                            [ngSwitch]="activeProduct.productData?.ProductBrandPageLinks.length">
                                            <a *ngSwitchCase="true"
                                               [IRouterLink]="activeProduct.productData?.ProductBrandPageLinks[0]?.Link">
                                                {{productData.BrandName}}
                                            </a>
                                            <div *ngSwitchCase="false">{{productData?.BrandName}}</div>
                                        </ng-container>
                                    </h2>
                                    <span class="product-spot-sidebar__header_sub"
                                          [innerHtml]="metaTags() | safeHtml"></span>

                                    <!-- Product price -->
                                    <app-product-price [product]="activeProduct"></app-product-price>
                                    <div class="product-spot__details__additional-text"
                                         *ngIf="!!pageData.pageData.ProductData.ProductHeading.length">{{pageData.pageData.ProductData.ProductHeading}}</div>

                                    <!-- Product color variant -->
                                    <app-product-variants-list
                                        *ngIf="activeProduct?.productData?.ColorGroups.length > 1"
                                        [productData]="activeProduct.productData"
                                        [productVariants]="activeProduct.productData.ColorGroups"
                                        [currentProductVariantId]="activeProduct.productData.ActiveColorGroupId">
                                    </app-product-variants-list>
                                </div>

                                <div class="product-spot-sidebar__middle">

                                    <!-- Size guide -->
                                    <div class="product-spot-sidebar__sizeguide"
                                         *ngIf="!activeProduct?.productData?.IsShowProduct">
                                        <button type="button" class="product-spot-sidebar__sizeguide-button"
                                                (click)="openSizeguide()">
                                            <span>{{translations?.ProductDetails?.SideBarSizePicker?.HelpPickingTheRightSizeText}}</span>
                                            <span>{{translations?.ProductDetails?.SideBarSizePicker?.ShowSizeGuideLinkText}}</span>
                                        </button>
                                    </div>

                                    <!-- Product size picker button -->
                                    <app-product-size-picker-button *ngIf="!activeProduct?.productData?.IsShowProduct"
                                                                    (callbackMethod)="openProductSizePicker()"></app-product-size-picker-button>

                                    <div class="product-spot__buttons-wrapper"
                                         *ngIf="!activeProduct?.productData?.IsShowProduct"
                                         [ngSwitch]="activeProduct?.activeProductVariant?.isSoldOut">
                                        <ng-container *ngSwitchCase="true">
                                            <div
                                                class="button button_outline button_full button_text-center product-spot__sold-out-button">
                                                <span>{{translations?.ProductDetails?.SideBarBasketSection?.ProductSoldOutButtonText}}</span>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="false">
                                            <!-- Product add-to-basket button -->
                                            <app-product-add-to-basket
                                                (callbackMethod)="addActiveProductToBasket()"></app-product-add-to-basket>
                                            <!-- Product favorites button -->
                                            <app-product-store-stock-button
                                                [productSizeStock]="productSizeStock"
                                                [onSale]="!!activeProduct?.activeProductVariant?.SalePrice"
                                            ></app-product-store-stock-button>
                                            <!-- <app-product-favorite-button></app-product-favorite-button> -->
                                        </ng-container>
                                    </div>
                                    <app-delivery-text
                                        *ngIf="!activeProduct?.productData?.IsShowProduct && !activeProduct?.activeProductVariant?.isSoldOut && !isGiftCardDigital"></app-delivery-text>
                                </div>

                                <div
                                    class="product-spot-sidebar__color-picker product-spot-sidebar__color-picker-filler"
                                    *ngIf="productData.ColorGroups.length <= 1"></div>


                                <div class="product-spot__details__info__description">
                                    <app-info-description [activeProduct]="activeProduct"
                                                          [openFirstTab]='accordionProductInfoOpenAsDefault'
                                                          [translations]="translations"></app-info-description>
                                </div>

                                <app-usp *ngIf='!isGiftCardDigital'></app-usp>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


            <!-- PRODUCT INFORMATION -->
            <div class="product-spot__details-wrapper" #productSpotInformation>
                <div class="product-spot__details__info-wrapper">

                    <!-- Product details info -->
                    <div class="product-spot__details__info" [class.product_soldout]="activeProduct?.activeProductVariant?.isSoldOut">
                        <h2 class="product-spot__details-header">
                            <app-favorite-picker [styleId]="styleId" [colorId]="colorId"></app-favorite-picker>
                            <ng-container [ngSwitch]="activeProduct.productData?.ProductBrandPageLinks.length">
                                <a *ngSwitchCase="true"
                                   [IRouterLink]="activeProduct.productData?.ProductBrandPageLinks[0]?.Link">
                                    {{productData.BrandName}}
                                </a>
                                <div *ngSwitchCase="false">{{productData?.BrandName}}</div>
                            </ng-container>
                        </h2>
                        <span class="product-spot__details-header_sub" [innerHtml]="metaTags() | safeHtml"></span>

                        <!-- Product price-->
                        <app-product-price [product]="activeProduct"></app-product-price>

                        <div class="product-spot__details__additional-text"
                             *ngIf="!!pageData.pageData.ProductData.ProductHeading.length">{{pageData.pageData.ProductData.ProductHeading}}</div>

                        <!-- Product color variant -->
                        <app-product-variants-list *ngIf="activeProduct?.productData?.ColorGroups.length > 1"
                                                   [productData]="activeProduct.productData"
                                                   [productVariants]="activeProduct.productData.ColorGroups"
                                                   [currentProductVariantId]="activeProduct.productData.ActiveColorGroupId">
                        </app-product-variants-list>

                        <!-- Product actions mobile -->
                        <div #mobileButtonsWrapper class="product-spot__details__actions-wrapper">
                            <div class="product-spot-sidebar__sizeguide"
                                 *ngIf="!activeProduct?.productData?.IsShowProduct">
                                <button type="button" class="product-spot-sidebar__sizeguide-button"
                                        (click)="openSizeguide()">
                                    {{translations?.ProductDetails?.SideBarSizePicker?.ShowSizeGuideLinkText}}
                                </button>
                            </div>

                            <app-product-size-picker-button (callbackMethod)="openProductSizePicker()"
                                                            *ngIf="!activeProduct?.productData?.IsShowProduct"></app-product-size-picker-button>

                            <div class="product-spot__details__actions"
                                 *ngIf="!activeProduct?.productData?.IsShowProduct"
                                 [ngClass]="{'is-sold-out': activeProduct?.activeProductVariant?.isSoldOut}">
                                <app-product-size-picker-button
                                    (callbackMethod)="openProductSizePicker()"></app-product-size-picker-button>

                                <ng-container [ngSwitch]="activeProduct?.activeProductVariant?.isSoldOut">
                                    <ng-container *ngSwitchCase="true">
                                        <div
                                            class="button button_outline button_full button_text-center product-spot__sold-out-button">
                                            <span>{{translations?.ProductDetails?.SideBarBasketSection?.ProductSoldOutButtonText}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="false">
                                        <!-- Product add-to-basket button -->
                                        <app-product-add-to-basket
                                            (callbackMethod)="addActiveProductToBasket()"></app-product-add-to-basket>
                                        <!-- Product favorites button -->
                                        <app-product-store-stock-button
                                            [productSizeStock]="productSizeStock"
                                            [onSale]="!!activeProduct?.activeProductVariant?.SalePrice"
                                        ></app-product-store-stock-button>
                                    </ng-container>
                                </ng-container>
                                <app-delivery-text class="delivery-text_mobile"
                                                   *ngIf="!activeProduct?.productData?.IsShowProduct && !activeProduct?.activeProductVariant?.isSoldOut && !isGiftCardDigital"></app-delivery-text>
                            </div>
                            <app-delivery-text class="delivery-text_tabletAndLarger"
                                               *ngIf="!activeProduct?.productData?.IsShowProduct && !activeProduct?.activeProductVariant?.isSoldOut && !isGiftCardDigital"></app-delivery-text>


                            <div class="product-spot__floating-actions" aria-hidden="true" [style.transform]="isGiftCardDigital ? 'translate3d(0, 46%, 0)':''"
                                 *ngIf="!activeProduct.productData.IsShowProduct">

                                <div class="product-spot__floating-actions-wrapper">
                                    <div>
                                        <app-delivery-text *ngIf="!activeProduct?.productData?.IsShowProduct && !activeProduct?.activeProductVariant?.isSoldOut && !isGiftCardDigital"></app-delivery-text>
                                    </div>
                                    <div>
                                        <app-product-size-picker-button (callbackMethod)="openProductSizePicker()"></app-product-size-picker-button>
                                        <ng-container [ngSwitch]="activeProduct?.activeProductVariant?.isSoldOut">
                                            <ng-container *ngSwitchCase="true">
                                                <div
                                                    class="button button_outline button_full button_text-center product-spot__sold-out-button">
                                                    <span>{{translations?.ProductDetails?.SideBarBasketSection?.ProductSoldOutButtonText}}</span>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="false">
                                                <!-- Product add-to-basket button -->
                                                <app-product-add-to-basket (callbackMethod)="addActiveProductToBasket()"></app-product-add-to-basket>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div>
                                        <app-product-store-stock-button
                                            [productSizeStock]="productSizeStock"
                                            [onSale]="!!activeProduct?.activeProductVariant?.SalePrice"
                                        ></app-product-store-stock-button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <!-- Product info description and specifications -->
                    <div
                        class="product-spot__details__info__description product-spot__details__info__description_small_screen">
                        <app-info-description [activeProduct]="activeProduct"
                                              [openFirstTab]='accordionProductInfoOpenAsDefault'
                                              [translations]="translations"></app-info-description>
                    </div>

                    <app-usp *ngIf='!isGiftCardDigital' class="product-details__usp"></app-usp>


                    <!-- Product details related content -->
                    <div class="product-spot__details__info__related">

                        <div class="product-spot__related-categories"
                             *ngIf="productData?.ProductCategoryLinks?.length || productData?.ProductBrandPageLinks?.length">
                            <!-- <h4>{{translations?.ProductDetails?.ProductInfoSectionInformation?.SeeMoreBrandAndCategoriesLabel}}</h4> -->
                            <div class="product-spot__related-categories__header">{{translations?.ProductDetails?.ProductExploreCategories?.ExploreCategories}}</div>
                            <ul>
                                <li *ngFor="let item of productData?.ProductCategoryLinks">
                                    <a [routerLink]="item?.Link" class="product-spot__related-categories__link">
                                        {{item?.Name}}
                                    </a>
                                </li>
                                <ng-container *ngIf="productData?.ProductBrandPageLinks?.length">
                                    <li *ngFor="let item of productData?.ProductBrandPageLinks">
                                        <a [routerLink]="item?.Link" class="product-spot__related-categories__link">
                                            {{item?.Name}}
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>


                        <app-brand-info [activeProduct]="activeProduct"></app-brand-info>


                    </div>

                </div>
            </div>

        </div>
    </div>


</section>

<app-json-ld [json]="productLd"></app-json-ld>
<app-json-ld [json]="brandLd"></app-json-ld>
