<app-rightpane (animationStateChanged)="doSomething($event)" [startExit]="startExitTrigger">
    <div class="rightpane-container">
        <header class="rightpane-container__header">
            <h2 class="rightpane-container__heading">{{(translations | async)?.Store?.StoreFinderPane?.FindStore}}</h2>
        </header>
        <div class="rightpane-container__content" appScrollbarHide>
            <app-find-store-content [mapHeight]="300" [isPane]="true" (selectedStoreObject)="onStoreSelected($event)">
            </app-find-store-content>
        </div>
    </div>
</app-rightpane>