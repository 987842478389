import {Component, Input, OnInit} from '@angular/core';
import {SiteContextService} from '@core/site-context.service';
import {PriceGroup} from '@features/product-page/product-price/price-group.interface';
import {ImpactCoreModelsDtoGeneralSiteSettingsGeneralSiteSettingsDto} from '@shared/swagger/swagger.interface';

export interface ISplashVisibility {
    hideNewArrivals: boolean;
    hideSales: boolean;
    hideOutlet: boolean;
    hideExtra: boolean;
}

interface ISplashSettings {
    SplashDiscountBackgroundColor: string;
    SplashDiscountTextColor: string;
    SplashSaleBackgroundColor: string;
    SplashSaleTextColor: string;
    SplashSaleTextLabel: string;
    SplashExtraBackgroundColor: string;
    SplashExtraTextColor: string;
    SplashExtraTextLabel: string;
    SplashOutletBackgroundColor: string;
    SplashOutletTextColor: string;
    SplashOutletTextLabel: string;
    SplashNewArrivalsBackgroundColor: string;
    SplashNewArrivalsTextColor: string;
    SplashNewArrivalsTextLabel: string;
}

@Component({
    selector: 'app-product-splash',
    template: `
        <ng-container
             *ngIf="productPriceGroup !== priceGroup[priceGroup.Normal]"
        >
            <div class="discount-badge-container">
                <div
                    *ngIf="productPriceGroup === priceGroup[priceGroup.Normal] && !!discountDescription"
                    class="label label_full" [style.color]="splash.SplashExtraTextColor"
                    [style.background-color]="splash.SplashExtraBackgroundColor">{{discountDescription}}</div>
                <div *ngIf="!splashVisibility.hideExtra && productPriceGroup === priceGroup[priceGroup.Extra]"
                     class="label label_full" [style.color]="splash.SplashExtraTextColor"
                     [style.background-color]="splash.SplashExtraBackgroundColor">{{splash.SplashExtraTextLabel}}</div>
                <div class="label label_splash"
                     *ngIf="!splashVisibility.hideSales && (productPriceGroup === priceGroup[priceGroup.Sale] || productPriceGroup === priceGroup[priceGroup.Extra])"
                     [style.color]="splash.SplashSaleTextColor"
                     [style.background-color]="splash.SplashSaleBackgroundColor">{{splash.SplashSaleTextLabel}}</div>
                <div class="label label_splash"
                     *ngIf="!splashVisibility.hideOutlet && productPriceGroup === priceGroup[priceGroup.Outlet]"
                     [style.color]="splash.SplashOutletTextColor"
                     [style.background-color]="splash.SplashOutletBackgroundColor">{{splash.SplashOutletTextLabel}}</div>
                <div class="label percentage" [style.color]="splash.SplashDiscountTextColor"
                     [style.background-color]="splash.SplashDiscountBackgroundColor">
                    -{{percentageSaving}}%
                </div>
            </div>
        </ng-container>
        <ng-container
             *ngIf="productPriceGroup === priceGroup[priceGroup.Normal] && !!discountDescription"
        >
            <div class="discount-badge-container">
                <div class="label label_full" [style.color]="splash.SplashExtraTextColor"
                     [style.background-color]="splash.SplashExtraBackgroundColor">{{discountDescription}}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="isNew && !splashVisibility.hideNewArrivals">
            <div class="discount-badge-container">
                <div class="label label_full" [style.color]="splash.SplashNewArrivalsTextColor"
                     [style.background-color]="splash.SplashNewArrivalsBackgroundColor">{{splash.SplashNewArrivalsTextLabel}}</div>
            </div>
        </ng-container>
    `
})
export class ProductSplashComponent implements OnInit {
    @Input() productPriceGroup: string;
    @Input() percentageSaving: string;
    @Input() discountDescription: string;
    @Input() isNew = false;
    @Input() splashVisibility: ISplashVisibility = {
        hideNewArrivals: false,
        hideSales: false,
        hideOutlet: false,
        hideExtra: false
    };

    splash: ISplashSettings = {
        SplashNewArrivalsBackgroundColor: '',
        SplashNewArrivalsTextColor: '',
        SplashNewArrivalsTextLabel: '',
        SplashExtraTextLabel: '',
        SplashOutletTextLabel: '',
        SplashSaleTextLabel: '',
        SplashDiscountBackgroundColor: '',
        SplashDiscountTextColor: '',
        SplashExtraBackgroundColor: '',
        SplashExtraTextColor: '',
        SplashOutletBackgroundColor: '',
        SplashOutletTextColor: '',
        SplashSaleBackgroundColor: '',
        SplashSaleTextColor: ''
    };

    private contextGeneralSettings: ImpactCoreModelsDtoGeneralSiteSettingsGeneralSiteSettingsDto;

    priceGroup = PriceGroup;

    constructor(
        private siteContextService: SiteContextService,
    ) {
        this.contextGeneralSettings = this.siteContextService.getContext().generalSiteSettings;
        this.splash.SplashDiscountBackgroundColor = !!this.contextGeneralSettings.SplashDiscountBackgroundColor ?
            `#${this.contextGeneralSettings.SplashDiscountBackgroundColor}` : '#9f9f9f';
        this.splash.SplashDiscountTextColor = !!this.contextGeneralSettings.SplashDiscountTextColor ?
            `#${this.contextGeneralSettings.SplashDiscountTextColor}` : '#fff';
        this.splash.SplashSaleBackgroundColor = !!this.contextGeneralSettings.SplashSaleBackgroundColor ?
            `#${this.contextGeneralSettings.SplashSaleBackgroundColor}` : '#fff700';
        this.splash.SplashSaleTextColor = !!this.contextGeneralSettings.SplashSaleTextColor ?
            `#${this.contextGeneralSettings.SplashSaleTextColor}` : '#17191c';
        this.splash.SplashExtraBackgroundColor = !!this.contextGeneralSettings.SplashExtraBackgroundColor ?
            `#${this.contextGeneralSettings.SplashExtraBackgroundColor}` : '#17191c';
        this.splash.SplashExtraTextColor = !!this.contextGeneralSettings.SplashExtraTextColor ?
            `#${this.contextGeneralSettings.SplashExtraTextColor}` : '#fff';
        this.splash.SplashOutletBackgroundColor = !!this.contextGeneralSettings.SplashOutletBackgroundColor ?
            `#${this.contextGeneralSettings.SplashOutletBackgroundColor}` : '#861910';
        this.splash.SplashOutletTextColor = !!this.contextGeneralSettings.SplashOutletTextColor ?
            `#${this.contextGeneralSettings.SplashOutletTextColor}` : '#fff';
        this.splash.SplashNewArrivalsBackgroundColor = !!this.contextGeneralSettings.SplashNewArrivalsBackgroundColor ?
            `#${this.contextGeneralSettings.SplashNewArrivalsBackgroundColor}` : '#17191c';
        this.splash.SplashNewArrivalsTextColor = !!this.contextGeneralSettings.SplashNewArrivalsTextColor ?
            `#${this.contextGeneralSettings.SplashNewArrivalsTextColor}` : '#fff';
        this.splash.SplashSaleTextLabel = this.contextGeneralSettings.SplashSaleTextLabel;
        this.splash.SplashOutletTextLabel = this.contextGeneralSettings.SplashOutletTextLabel;
        this.splash.SplashExtraTextLabel = this.contextGeneralSettings.SplashExtraTextLabel;
        this.splash.SplashExtraTextLabel = this.contextGeneralSettings.SplashExtraTextLabel;
        this.splash.SplashNewArrivalsTextLabel = this.contextGeneralSettings.SplashNewArrivalsTextLabel;
    }

    ngOnInit(): void {}

}
