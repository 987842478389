import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-article-header-spot',
    templateUrl: './article-header-spot.component.html',

    host: { class: 'column is-12-mobile is-12-tablet is-8-desktop' },
})
export class ArticleHeaderSpotComponent {
    static ref = 'Article Header Spot';

    @Input()
    public data;
}
