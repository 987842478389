<div class="filter" #filterWrapper>
    <div class="filter__header" [class.filter__header_breadcrumb-pad]="pageData.BreadcrumbFloat" *ngIf="pageData.TemplateName !== 'Brand filter page'">
        <div class="container container_padded">
            <div class="filter-header">
                <ng-container *ngIf="searchString">
                    <h1 class="headline filter-header__heading">{{pageData?.FilterPageTitle}} {{searchString}}</h1>
                </ng-container>
                <ng-container *ngIf="!searchString">
                    <h1 class="headline filter-header__heading">{{pageData?.FilterPageTitle}}</h1>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="filterAnchor" #filterContainer style="height: 0;"></div>

    <app-product-filter-smart #smartFilterWrapper [facets]="facets | popularFacets" [displayOrder]="smartFilterDisplayOrder" [paginationFacet]="paginationFacet"></app-product-filter-smart>

    <div class="filter__sticky" #sticky>
        <div class="filter__nav">
            <div class="is-hidden-tablet container filter-buttons">
                <button class="button button_primary filter-button" (click)="openMobileFilter('price')">
                    <app-svg [src]='icons.filter2' [size]="'small'"></app-svg>{{translations?.Filter?.Content?.ShowAllFacetsButtonText}}
                </button>
                <div class="filter-button">
                    <div class="input-container input-container_icon input-container_icon-clean">
                        <select class="input input_select input_clean filter-misc__sorting" (change)="sortChange($event)">
                            <option value="">{{translations?.Filter?.Sorting?.ChooseSorting}}</option>
                            <option *ngFor="let sort of sortFacet?.children" [value]="sort.key" [selected]="sort.isActive && !isDefaultSorting">{{translations?.Filter?.Sorting[sort.name]}}</option>
                        </select>
                        <span class="input-container__icon input-container__icon-expand" [innerHTML]="expand | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div class="container container_padded filter-nav__wrapper is-hidden-mobile">
                <ul class="filter-nav__list">
                    <li class="filter-nav__item" *ngFor="let facet of facets | visualFacets" (click)="toggleDrawer(facet)" [ngClass]="{'filter-nav__link_active': (currentFacet !== undefined && facet.key === currentFacet.key && drawerOpen)}">
                        <button type="button" class="filter-nav__link">
                            {{translations?.Filter?.Facets[facet.name]}}
                            <svg #expand class="filter-nav__arrow svg-expand" viewBox="0 0 14 9" aria-hidden="true">
                                <path d="M1 .57l6 7.5 6-7.5" stroke="#979797" fill="none" fill-rule="evenodd" />
                            </svg>
                        </button>
                    </li>
                </ul>
                <div class="input-container input-container_icon input-container_icon-clean">
                    <select class="input input_select input_clean filter-misc__sorting filter-misc__sorting-no-padding" (change)="sortChange($event)">
                        <option value="">{{translations?.Filter?.Sorting?.ChooseSorting}}</option>
                        <option *ngFor="let sort of sortFacet?.children" [value]="sort.key" [selected]="sort.isActive && !isDefaultSorting">{{translations?.Filter?.Sorting[sort.name]}}</option>
                    </select>
                    <span class="input-container__icon input-container__icon-expand" [innerHTML]="expand | safeHtml"></span>
                </div>
            </div>

        </div>

        <app-product-filter-facets [open]="drawerOpen" [facet]="currentFacet" [allFacets]="facets" (pixelScroll)="pixelScroll()"></app-product-filter-facets>


        <div class="filter__misc">
            <div class="container container_padded">
                <div class="columns is-multiline">
                    <div class="column is-12-tablet is-7-desktop filter__misc-first" [ngClass]="{'is-hidden-tablet-only': activeFacets.length < 1}">

                        <div class="filter-misc">
                            <ul class="filter-misc__list">
                                <li class="filter-picked-facets__item" [@activeFilters]="activeFacets" *ngFor="let activeFacet of activeFacets; trackBy: trackByFn"
                                    (click)="removeFacet(activeFacet.facet, activeFacet.child)">
                                    <button class="button button_icon button_tiny button_faded">
                                        <ng-container *ngIf="activeFacet.facet.key === 'PriceGroup'; else activeFacetNormal">{{translations?.Filter.PriceGroup[activeFacet.child.key]}}</ng-container>
                                        <ng-template #activeFacetNormal>
                                            {{activeFacet.child.name | xlReformat}}
                                        </ng-template>
                                        <span class="filter-picked-facets__remove" [innerHTML]="icons.cross | safeHtml"></span>
                                    </button>
                                </li>
                                <li class="filter-picked-facets__item" (click)="resetAllFilters()" *ngIf="activeFacets.length > 1">
                                    <button class="button button_icon button_tiny button_faded-inverted">
                                        {{translations?.Filter?.Content?.RemoveAllFilters}}
                                        <span class="filter-picked-facets__remove" [innerHTML]="icons.cross | safeHtml"></span>
                                    </button>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div class="column is-12-tablet is-5-desktop filter__misc-last">
                        <div class="filter-misc">
                            <ul class="filter-misc__list">
                                <li class="filter-misc__item">
                                    <strong>{{productsFound}}</strong> {{(productsFound === 1 ? translations?.Filter?.Content?.FoundProduct
                                    : translations?.Filter?.Content?.FoundProducts)}}</li>
                                <li class="filter-misc__item filter-misc__item_flex filter-misc__item_more-space">
                                    <button type="button" class="button button_clean button__icon-product-grid" [ngClass]="{'button__icon-product-grid_active': selectedgrid.gridState === 'large'}"
                                        [innerHTML]="productGridLargeIcon | safeHtml" (click)="changeGridLayout('large')">
                                    </button>
                                    <button type="button" class="button button_clean button__icon-product-grid" [ngClass]="{'button__icon-product-grid_active': selectedgrid.gridState === 'small'}"
                                        [innerHTML]="productGridSmallIcon | safeHtml" (click)="changeGridLayout('small')">
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
