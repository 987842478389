<div class="columns find-store-page is-multiline">
    <div class="column is-12-tablet is-6-desktop">
        <div class="find-store-page__map">
            <app-find-store-content [mapHeight]="374" [isPane]="false" [storeToShow]="selectedStore" (selectedStoreObject)="onSelectedStore($event)">
            </app-find-store-content>
        </div>
    </div>
    <div class="column is-12-tablet is-6-desktop">
        <div class="find-store-page__info">
            <app-store-info [selectedStore]="selectedStore"></app-store-info>
        </div>
    </div>
</div>