import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SiteContextService } from '@core/site-context.service';
import { Observable } from 'rxjs';
import {
    ImpactWebsiteCodeWebApiControllersGetGiftcardSaldoArgs as giftCardBalanceRequestModel,
    ImpactWebsiteCodeWebApiControllersGetGiftcardSaldoResponse as giftCardBalanceResponse,
} from '@shared/swagger/swagger.interface';

@Injectable({
    providedIn: 'root',
})
export class GiftCardBalanceService {
    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService
    ) {}

    checkGiftCardBalance(
        payload: giftCardBalanceRequestModel
    ): Observable<giftCardBalanceResponse> {
        return this.http.post<giftCardBalanceResponse>(
            `${this.siteContextService.getRootUrl()}/webapi/GiftcardSaldo/RetrieveSaldo`,
            payload
        );
    }
}
