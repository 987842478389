import { Component, Input, OnInit } from '@angular/core';
import { AlertType } from '@shared/alert/alert.interface';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
})
export class AlertComponent {
    @Input()
    public closeCallback: Function;
    @Input()
    public text: string;
    @Input()
    public type: AlertType;
    @Input()
    public hideCloseButton: boolean;
}
