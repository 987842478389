import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-article-layout100',
    templateUrl: './article-layout100.component.html',
})
export class ArticleLayout100Component {
    static ref = 'ArticleLayout100';

    @Input()
    data;
}
