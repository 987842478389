import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    ImpactCoreModelsProductsDtoColorGroupDto as ColorGroup,
    ImpactCoreModelsProductsDtoStyleDto as ProductStyle
} from '@shared/swagger/swagger.interface';
import {NgModel} from '@angular/forms';
import {
    expand,
    basket
} from '@shared/svg';
import {SpinnerService} from '@shared/spinner/spinner.service';
import { SiteContextService } from '@core/site-context.service';

@Component({
    selector: 'app-minibasket-upselling-product',
    templateUrl: './minibasket-upselling-product.component.html',
    styles: []
})
export class MinibasketUpsellingProductComponent implements OnInit {
    @Output() updateBasket = new EventEmitter<any>();
    @Input() product: ProductStyle;
    @Input() translate: any;
    @Input() productImageBgColor: string;
    @ViewChild('size') size;

    image: string;
    activeColorGroup: ColorGroup;
    selectedSize;
    icons = {
        expand,
        basket
    };
    basketSpinnerName = 'addToBasketSpinner';
    businessDimension: string;

    static productImagePath(colorGroup: ColorGroup): string {
        if (colorGroup.Images.length) {
            return colorGroup.Images[0].Path;
        }
        return '';
    }

    static setActiveColor(colorGroups: ColorGroup[], activeColorGroupId: string): ColorGroup {
        const activeColor = colorGroups.filter(colorGroup => colorGroup.ColorId === activeColorGroupId);
        if (activeColor.length > 0) {
            return activeColor[0];
        }
        console.warn(`Found no active color group ${activeColorGroupId} on this product ${colorGroups[0].ColorGroupPath}.\nDefaulting to first available color group`);
        return colorGroups[0];
    }

    constructor(
        private spinnerService: SpinnerService,
        private siteContextService: SiteContextService
    ) {
        this.businessDimension = this.siteContextService.getContext().businessDimension.toLowerCase();
    }

    ngOnInit(): void {
        this.activeColorGroup = MinibasketUpsellingProductComponent.setActiveColor(this.product.ColorGroups, this.product.ActiveColorGroupId);
        this.image = MinibasketUpsellingProductComponent.productImagePath(this.activeColorGroup);
        this.activeColorGroup.Skus.length > 1 ? this.selectedSize = '': this.selectedSize = this.activeColorGroup.Skus[0];
    }

    getProductTags(): string {
        const tags = [];
        if (this.activeColorGroup) {

            if (this.product.Fit) {
                tags.push(this.product.Fit);
            }

            if (this.product.Name) {
                tags.push(this.product.Name);
            } else {
                tags.push(this.product.Category);
            }

            if (this.activeColorGroup.FilterColorName) {
                tags.push(this.activeColorGroup.FilterColorName);
            }

        }
        return tags.join(' | ');
    }

    addToBasket() {
        if (this.size.value !== '') {
            this.updateBasket.emit(
                {
                selectedSize: this.selectedSize,
                product: this.product
            });
        }
    }
}
