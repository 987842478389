import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RightpaneModule } from '@features/rightpane/rightpane.module';
import { NgxLazyloadModule } from '@features/ngx-lazyload/ngx-lazyload.module';
import { SharedModule } from '@shared/shared.module';
import { MinibasketBadgeComponent } from './minibasket-badge/minibasket-badge.component';
import { MinibasketOverviewComponent } from './minibasket-overview/minibasket-overview.component';
import { MinibasketProductComponent } from './minibasket-product/minibasket-product.component';
import { MinibasketProductsComponent } from './minibasket-products/minibasket-products.component';
import { MinibasketComponent } from './minibasket.component';
import { MinibasketUpsellingComponent } from './minibasket-upselling/minibasket-upselling.component';
import { MinibasketUpsellingProductComponent } from './minibasket-upselling-product/minibasket-upselling-product.component';
import { ProductFilterModule } from '@features/product-filter';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RightpaneModule,
        NgxLazyloadModule,
        RouterModule,
        ProductFilterModule,
    ],
    declarations: [
        MinibasketComponent,
        MinibasketBadgeComponent,
        MinibasketOverviewComponent,
        MinibasketProductsComponent,
        MinibasketProductComponent,
        MinibasketUpsellingComponent,
        MinibasketUpsellingProductComponent,
    ],
    exports: [MinibasketBadgeComponent],
    entryComponents: [MinibasketComponent],
})
export class MinibasketModule {}
