<app-leftpane (animationStateChanged)="animationStateChange($event)" [startExit]="startExitTrigger">
    <div class="mobile-menu__top-bar">
        <!-- <meta-menu [metaMenu]="metaMenu" [isMobile]="true" (onClick)="metaClick()"></meta-menu> -->
        <div class="menu-title">Menu</div>
    </div>
    <div class="leftpane__content">

        <span class="mobile-menu__back" (click)="goBack()" *ngIf="levels.length > 1" [@backAnimation]="levels.length">
            <span class="mobile-menu__back-arrow" [innerHtml]="icons.arrow | safeHtml"></span>
        </span>


        <!-- Primary menu -->
        <ul class="mobile-menu__items" [@listAnimation]="levels.length">
            <li class="mobile-menu__item" *ngFor="let menuItem of levels[levels.length - 1]; let i1 = index;">
                <app-mobile-navigation-accordion
                    [id]="i1" [title]="menuItem.NavigationTitle" [activeId]="currentTabOuter"
                    (onToggle)="accordionOuterChange($event)" [icon]="iconSettingsOuterAccordion.icon" [iconLocation]="iconSettingsOuterAccordion.iconLocation"
                    [menuItem]="menuItem" [actAsLink]="menuItem.MainMenuItems.length == 0" [link]="menuItem.NavigationLink"
                    class="mobile-menu__outer-accordion">

                        <a *ngIf="menuItem.SeeAllLinkText && menuItem.SeeAllLinkText != '' && menuItem.NavigationLink != ''" [IRouterLink]="menuItem.NavigationLink" class="mobile-menu__all-product-link"> {{ menuItem.SeeAllLinkText }} </a>

                        <ng-container *ngFor="let mainMenuItem of menuItem.MainMenuItems; let i2 = index">
                            <app-mobile-navigation-accordion
                                [id]="i1*100 + i2" [title]="mainMenuItem.NavigationTitle" [activeId]="currentTabInner"
                                (onToggle)="accordionInnerChange($event)" [icon]="iconSettingsInnerAccordion.icon" [iconLocation]="iconSettingsInnerAccordion.iconLocation"
                                [hideTitle]="mainMenuItem.HideTitle" [actAsLink]="mainMenuItem.Subitems.length == 0" [link]="mainMenuItem.NavigationLink"
                                class="mobile-menu__inner-accordion">

                                <a *ngIf="!mainMenuItem.HideTitle" [IRouterLink]="mainMenuItem.NavigationLink" class="mobile-menu__inner-accordion__all-product-link">{{ mainMenuItem.SeeAllLinkText }} </a>
                                <ul class="mobile-menu__sub-items">
                                    <li *ngFor="let subItem of mainMenuItem.Subitems">
                                        <a [IRouterLink]="subItem.NavigationLink" class="mobile-menu__sub-item-link" [attr.vwo-id]="vwoId(subItem.NavigationLink)">
                                            {{ subItem.NavigationTitle }}
                                        </a>
                                    </li>
                                    <li *ngIf="!mainMenuItem.HideTitle" class="mobile-menu__sub-item-link__all-product-link">
                                        <a [IRouterLink]="mainMenuItem.NavigationLink" class="mobile-menu__sub-item-link">
                                            {{ mainMenuItem.SeeAllLinkText }}
                                        </a>
                                    </li>
                                </ul>
                            </app-mobile-navigation-accordion>
                        </ng-container>
                </app-mobile-navigation-accordion>
            </li>
        </ul>


        <!-- Secondary menu -->
        <ul class="mobile-secondary-menu__items" [@listAnimation]="secondaryMainMenu.length">
            <li class="mobile-secondary-menu__item" *ngFor="let menuItem of secondaryMainMenu; let i1 = index;">

                <app-mobile-navigation-accordion
                    [id]="10000 + i1" [title]="menuItem.NavigationTitle" [activeId]="currentTabOuter"
                    (onToggle)="accordionOuterChange($event)" [icon]="iconSettingsOuterAccordion.icon" [iconLocation]="iconSettingsOuterAccordion.iconLocation"
                    [menuItem]="menuItem" [actAsLink]="menuItem.MainMenuItems.length == 0" [link]="menuItem.NavigationLink"
                    class="mobile-menu__outer-accordion mobile-menu__secondary-menu-accordion">

                        <a *ngIf="menuItem.SeeAllLinkText && menuItem.SeeAllLinkText != '' && menuItem.NavigationLink != ''" [IRouterLink]="menuItem.NavigationLink" class="mobile-menu__all-product-link"> {{ menuItem.SeeAllLinkText }} </a>

                        <ng-container *ngFor="let mainMenuItem of menuItem.MainMenuItems; let i2 = index">
                            <app-mobile-navigation-accordion
                                [id]="10000 + i1*100 + i2" [title]="mainMenuItem.NavigationTitle" [activeId]="currentTabInner"
                                (onToggle)="accordionInnerChange($event)" [icon]="iconSettingsInnerAccordion.icon" [iconLocation]="iconSettingsInnerAccordion.iconLocation"
                                [hideTitle]="mainMenuItem.HideTitle" [actAsLink]="mainMenuItem.Subitems.length == 0" [link]="mainMenuItem.NavigationLink"
                                class="mobile-menu__inner-accordion">

                                <a *ngIf="!mainMenuItem.HideTitle" [IRouterLink]="mainMenuItem.NavigationLink" class="mobile-menu__inner-accordion__all-product-link">{{ mainMenuItem.SeeAllLinkText }} </a>
                                <ul class="mobile-menu__sub-items">
                                    <li *ngFor="let subItem of mainMenuItem.Subitems">
                                        <a [IRouterLink]="subItem.NavigationLink" class="mobile-menu__sub-item-link">
                                            {{ subItem.NavigationTitle }}
                                        </a>
                                    </li>
                                    <li *ngIf="!mainMenuItem.HideTitle" class="mobile-menu__sub-item-link__all-product-link">
                                        <a [IRouterLink]="mainMenuItem.NavigationLink" class="mobile-menu__sub-item-link">
                                            {{ mainMenuItem.SeeAllLinkText }}
                                        </a>
                                    </li>
                                </ul>
                            </app-mobile-navigation-accordion>
                        </ng-container>
                </app-mobile-navigation-accordion>

                <!-- <a [IRouterLink]="menuItem.NavigationLink" class="mobile-secondary-menu__item-link">
                    {{ menuItem.NavigationTitle }}
                </a> -->

            </li>
        </ul>

        <div class="mobile-menu__meta-menu">
            <app-language-picker [useCustomLanguagePicker]="businessDimension === 'axel'"></app-language-picker>
            <meta-menu [isMobile]="true" [metaMenu]="metaMenu"></meta-menu>
        </div>

    </div>
</app-leftpane>
