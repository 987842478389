<app-rightpane (animationStateChanged)="doSomething($event)" [startExit]="startExitTrigger" *noSSR>

    <div class="rightpane-container">
        <header class="rightpane-container__header">
            <h2 class="rightpane-container__heading">{{pageData?.StockInStoreHeadline}}</h2>
        </header>

        <div class="rightpane-container__content" appScrollbarHide>
            <div class="store-stock">
                <div class="store-stock__intro-text" [innerHTML]="pageData?.StockInStoresText | safeHtml"></div>
                <div class="store-stock__product">
                    <img [src]="activeColorGroup.Images[0].Path + '&w=40&h=40&bgcolor=eaebe8'" [srcset]="activeColorGroup.Images[0].Path + '&w=40&h=40&bgcolor=eaebe8 1x,' + activeColorGroup.Images[0].Path + '&w=80&h=80&bgcolor=eaebe8 2x'"
                        alt="">
                    <div class="store-stock__product-name">
                        <b>{{data.product.BrandName}}</b><br> {{data.product.Category}} {{activeColorGroup.FilterColorName}}
                    </div>
                </div>
                <div class="store-stock__size">
                    <div class="input-container input-container_icon input-container_background">
                        <select class="input input_select input_filled" name="size" id="size" [(ngModel)]="selectedSize" (change)="changeSize()"
                            #size="ngModel">
                            <option [ngValue]="''" selected>{{(translations | async)?.StockInStores?.RightPane?.DropDownSelectSize}}</option>
                            <option *ngFor="let size of activeColorGroup.Skus" [ngValue]="size.Id">{{size.SizeLabel}}</option>
                        </select>
                        <span class="input-container__icon input-container__icon_expand" [innerHTML]="expandIcon | safeHtml"></span>
                    </div>
                </div>
                <div class="store-stock__indicators">
                    <div class="store-stock__indicator stock-display stock-display_high">{{(translations | async)?.StockInStores?.RightPane?.StockHigh}}</div>
                    <div class="store-stock__indicator stock-display stock-display_low">{{(translations | async)?.StockInStores?.RightPane?.StockLow}}</div>
                </div>
                <div class="store-stock__list">
                    <ul *ngIf="!justOpened">
                        <li *ngFor="let store of storeList">
                            <a [routerLink]="['', { outlets: { dialog: store.StorePageUrl } }]" class="stock-display" [ngClass]="{'stock-display_high': isStockCountHigh(store.StockCount), 'stock-display_low': !isStockCountHigh(store.StockCount)}">
                                {{store.StoreName}} <span [innerHTML]="arrowRightIcon | safeHtml"></span>
                            </a>
                        </li>
                        <li *ngIf="isStoreListEmpty && isInStockOnline">{{(translations | async)?.StockInStores?.RightPane?.NoStockInStores}}</li>
                        <li *ngIf="isStoreListEmpty && !isInStockOnline">{{(translations | async)?.StockInStores?.RightPane?.NoStockInStoresAndOnline}}</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</app-rightpane>
