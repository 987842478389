<ng-container *ngIf="facet.kind === 'Multicheck'">
    <ng-container *ngIf="facet.key === 'brand'">
        <div class="filter-facet-container filter-facets__item filter-facets__item_option column">
            <div class="filter-pane__facet-search" *ngIf="facet.key === 'brand'">
                <app-svg class="facet-search__icon" [src]='icons.search' [size]="'unset'"></app-svg>
                <button (click)="clearFilterFacetValue()" class="facet-search__icon facet-search__icon_pos-right"><app-svg [src]='icons.cross' [size]="'unset'"></app-svg></button>
                <input
                    type='text'
                    class="facet-search__input"
                    [placeholder]="translations?.Filter?.MobileFilterPane?.FilterFacetsPlaceholderText | stringFormat: facet.name.toLowerCase()"
                    (keyup)="filterFacets($event)"
                    #filterFacetInput
                />
            </div>
        </div>
    </ng-container>
    <app-product-filter-facet-item
        class='filter-facets__item filter-facets__item_option column'
        [class.filter-facets__item_sub]="option.isSubFacet"
        *ngFor='let option of filteredFacets | async | filterFacets: [filterFacetValue, facet.key]'
        [option]='option'
        (onChange)='onItemChange($event)'>
    </app-product-filter-facet-item>
    <app-product-filter-show-more *ngIf='(filteredFacets | async | filterFacets: [filterFacetValue, facet.key]).length >= 18'
                                  class='filter-facets__show-more column'
                                  [ngClass]='getShowMoreClasses()'
                                  (click)='showMore()'>
    </app-product-filter-show-more>
</ng-container>

<ng-container *ngIf="facet.kind === 'Slider'">
    <app-product-filter-slider [facet]='facet' (onChange)='sliderChange($event)'></app-product-filter-slider>
</ng-container>


