export const isStockCountHigh = (stockCount: number): boolean => {
    return stockCount >= 3;
};

export const lowStockCount = 3;

// Make keycodes globally accessible
export enum KEYCODES {
    TAB = 9,
    LEFT_ARROW = 37,
    UP_ARROW = 38,
    RIGHT_ARROW = 39,
    DOWN_ARROW = 40,
}

/**
 * Finds the index of the number that in the array
 * that is closest to the number provided
 * @param a - Array of numbers
 * @param n - Number to find the closest value to
 * @returns {number}
 */
export const getNearestNumberIndex = (a, n): number => {
    if ((l = a.length) < 2) return l - 1;
    for (var l, p = Math.abs(a[--l] - n); l--; )
        if (p < (p = Math.abs(a[l] - n))) break;
    return l + 1;
};

/**
 * Figures the computed TranslateY value
 * of a element.
 * @param obj
 * @returns {any}
 */
export const getComputedTranslateY = obj => {
    if (!window.getComputedStyle) { return; }
    const style = getComputedStyle(obj),
        transform = style.transform || style.webkitTransform;
    let mat = transform.match(/^matrix3d\((.+)\)$/);
    if (mat) { return parseFloat(mat[1].split(', ')[13]); }
    mat = transform.match(/^matrix\((.+)\)$/);
    return mat ? parseFloat(mat[1].split(', ')[5]) : 0;
};

export const isIe = () => {
    if (/MSIE 10/i.test(navigator.userAgent)) {
        return true;
    }

    if (
        /MSIE 9/i.test(navigator.userAgent) ||
        /rv:11.0/i.test(navigator.userAgent)
    ) {
        return true;
    }

    if (/Edge\/\d./i.test(navigator.userAgent)) {
        return true;
    }

    return false;
};

export const productBulletTags = (productData, colorId?: string) => {
    const tags = [];

    if (productData.Fit) {
        tags.push(productData.Fit);
    }

    if (productData.Name) {
        tags.push(productData.Name);
    } else {
        tags.push(productData.Category);
    }

    if (colorId) {
        const colorGroup = productData.ColorGroups.find(group => {
            return colorId === group.ColorId;
        });

        if (colorGroup && colorGroup.FilterColorName) {
            const colorName = colorGroup.FilterColorName.toLowerCase();
            tags.push(colorName.charAt(0).toUpperCase() + colorName.slice(1));
        }
    }

    return tags.join(' | ');
};

export const sublayoutImageScaleDivider = (templateType: string): number => {
    const sublayoutNum = +templateType.match(/\d/g).join('');
    if (sublayoutNum === 25) {
        return 4;
    }
    if (sublayoutNum === 33) {
        return 3;
    }
    if (sublayoutNum === 50) {
        return 2;
    }
    if (sublayoutNum === 100) {
        return 1;
    }
    return 1;
};

export const removeIndexFromArray = (arr: any[], index: number) => {
    return arr.filter((item, idx) => {
        return index !== idx;
    });
};

export const getFilename = (path: string) => {
    const filePathShards = path.split('/');
    return filePathShards[filePathShards.length - 1];
};

// Extract query parameters from string
export const getStringParameters = (urlString: string): any => {
    const params: any = {};
    const query = urlString.split('?').pop();
    const vars = query ? query.split('&') : [];

    if (vars) {
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
    }

    return params;
};

export const getContainerElement = (elm: HTMLElement, className: string): HTMLElement => {
    let parentElm = elm.parentElement;
    while (!parentElm.classList.value.includes(className)) {
        parentElm = parentElm.parentElement;
    }
    return parentElm;
};

export const getVwoId = (str: string): string => {
    return str.toLowerCase().replace(new RegExp(/\W/, 'g'), '');
};

/**
 * Calculate brightness value by RGB or HEX color.
 * @param color (String) The color value in RGB or HEX (for example: #000000 || #000
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
export const brightnessByColor = (color: string): number => {
    const hasFullSpec = color.length === 7;
    const m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
    const r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16);
    const g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16);
    const b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
    try {
        return ((r * 299) + (g * 587) + (b * 114)) / 1000;
    } catch (e) {
        throw new Error(`One or more of the RGB values was not valid`);
    }
};

export const imageAltTextFormat = (brand: string, category: string, model: string, color: string): string => {
    return `${brand} ${category} ${model} ${color}`;
};
