import { ModuleWithProviders, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
    RouteReuseStrategy,
    RouterModule,
    Routes,
} from '@angular/router';
import { PageComponent } from '@features/pages/pages.module';
import { PageResolve } from '@features/pages/page.resolve';
import { DialogOutletComponent } from '@features/dialog/dialog-outlet/dialog-outlet.component';
import { DialogPageResolve } from '@features/pages/dialog.resolve';

/**
 * Our app routes only have a single route, a catch all.
 * The resolver is in charge of using the correct template.
 * @type {{path: string; resolve: {content: PageResolve}; component: PageComponent}[]}
 */
const appRoutes: Routes = [
    {
        path: '',
        resolve: {
            content: PageResolve,
        },
        component: PageComponent,
    },
    {
        path: '**',
        resolve: {
            content: PageResolve,
        },
        component: PageComponent,
    },
    {
        path: '**',
        outlet: 'dialog',
        resolve: {
            content: DialogPageResolve,
        },
        component: DialogOutletComponent,
    },
];

/**
 * Exports our router module for usage
 * @type {ModuleWithProviders}
 */
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, {
    enableTracing: false,
    initialNavigation: 'enabled',
    anchorScrolling: 'enabled',
});

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        // should never be called, since we are not reusing routes
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return null;
        // should never be called, since we are not reusing routes
    }

    shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot
    ): boolean {
        // Due to our page resolving, we need to reuse routes based on url, and the router sends two events, one for root and one for child
        const futureUrl = (future.firstChild ? future.firstChild.url : future.url).toString();
        const currUrl = (curr.firstChild ? curr.firstChild.url : curr.url).toString();
        return futureUrl === currUrl;
    }
}
