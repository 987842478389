import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { BasketService } from '@core/basket.service';
import { TranslationService } from '@core/translation.service';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { basket } from '@shared/svg';
import { Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

export enum ProductAddToBasketButtonStates {
    IDLE = 'idle',
    BUSY = 'busy',
    DONE = 'done',
    ERROR = 'error',
    DISABLED = 'disabled'
}

@Component({
    selector: 'app-product-add-to-basket',
    template: `
        <button class="button button_primary button_full add-to-basket-button"
                (click)="buttonClicked()">
            <div>{{translations?.ProductDetails?.SideBarBasketSection?.AddToCartButtonText}}</div>
            <spinner [name]="basketSpinnerName" [size]="'20'" background="true" inverted="true"></spinner>
        </button>
        <app-notification
            type="error"
            [message]="giftCardLimitMessage"
            (closeNotification)="onCloseNotification()"
            *ngIf="showGiftCardLimitMessage"
        ></app-notification>
    `,
})
export class ProductAddToBasketComponent implements OnDestroy {
    @Output() callbackMethod: EventEmitter<any> = new EventEmitter();

    translations: any;
    basketSpinnerName = 'addToBasketSpinner';
    basketIcon = basket;
    destroy = new Subject();

    showGiftCardLimitMessage = false;
    giftCardLimitMessage: string;

    constructor(
        private translationService: TranslationService,
        public basketService: BasketService,
        private spinnerService: SpinnerService,
    ) {

        // Get translations
        this.translationService.translations$
            .pipe(first())
            .subscribe(translations => {
                this.translations = translations;
            });

        // Control state of the add-to-basket-spinner
        this.basketService.basketUpdating$
            .pipe(
                takeUntil(this.destroy),
            )
            .subscribe((isUpdating: boolean) => {
                if (isUpdating) {
                    this.spinnerService.show(this.basketSpinnerName);
                } else {
                    this.spinnerService.hide(this.basketSpinnerName);
                }
            });

        this.basketService.giftCardLimitMessage$
            .pipe(
                takeUntil(this.destroy),
            )
            .subscribe(data => {
                if (data.hasOwnProperty('Success') && !data.Success) {
                    this.showGiftCardLimitMessage = true;
                    this.giftCardLimitMessage = data.Message;
                }
            });

    }

    buttonClicked() {
        this.callbackMethod.next();
    }

    onCloseNotification() {
        this.showGiftCardLimitMessage = false;
    }

    ngOnDestroy(): void {
        this.basketService.giftCardLimitMessage$.next({Success: true});
        this.destroy.next();
        this.destroy.complete();
    }
}
