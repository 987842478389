import {
    AfterContentInit,
    Directive,
    ElementRef,
    Inject,
    Input,
    PLATFORM_ID,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

/**
 * Directive that automatically replaces image tags with inline SVG's
 *
 */
@Directive({
    selector: '[InlineSvg]',
})
export class InlineSvgDirective implements AfterContentInit {
    /**
     * The [src] tag of the image
     */
    @Input('src') defaultImg;

    /**
     * Constructs and injects services
     * @param {ElementRef} elementRef
     * @param {HttpClient} http
     * @param {object} platformId
     */
    constructor(
        private elementRef: ElementRef,
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    /**
     * If we are in the browser platform,
     * we inline the SVG rather than using the image tag.
     *
     * We do this so that we can style the SVG's.
     */
    ngAfterContentInit() {
        if (isPlatformBrowser(this.platformId)) {
            const element = this.elementRef.nativeElement;
            const src = this.defaultImg;

            if (src.indexOf('.svg') >= 0) {
                this.http.get(src, { responseType: 'text' }).subscribe(res => {
                    element.outerHTML = res;
                });
            }
            if (src.indexOf('<svg') >= 0) {
                element.outerHTML = src;
            } else {
                element.src = this.defaultImg;
            }
        }
    }
}
