import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FavoritePickerComponent} from './favorite-picker.component';
import {SharedModule} from '../../@shared/shared.module';


@NgModule({
    declarations: [FavoritePickerComponent],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [FavoritePickerComponent]
})
export class FavoritePickerModule {
}
