import {Component, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {Subject, Observable, throwError} from 'rxjs';
import {TranslationService} from '@core/translation.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {WishlistService} from '@core/wishlist.service';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {Wish} from '@features/wishlist/wish.model';
import {arrowRight, arrowUp, expand} from '@shared/svg';
import {SiteContextService} from '@core/site-context.service';
import {FavoriteService} from '@core/favorite.service';
import {ImpactCoreModelsProductsDtoStyleDto} from '@shared/swagger/swagger.interface';
import {IChangeSizeProps} from '../wishlist/wishlist-item/wishlist-item.component';
import {IFavoriteProductStore} from '@core/favorite.interface';

@Component({
    selector: 'app-wishlist-pane',
    templateUrl: './wishlist-pane.component.html',
    animations: [
        trigger('slideUpDown', [
            transition(':enter', [
                style({transform: 'translateY(100%)', opacity: '0'}),
                animate(
                    '600ms cubic-bezier(0.35, 0, 0.25, 1)',
                    style({transform: 'translateY(0%)', opacity: '1'})
                ),
            ]),
            transition(':leave', [
                style({transform: 'translateY(0%)', opacity: '1'}),
                animate(
                    '600ms cubic-bezier(0.35, 0, 0.25, 1)',
                    style({transform: 'translateY(100%)', opacity: '0'})
                ),
            ]),
        ]),
        trigger('fadeOut', [
            transition(':enter', [
                style({opacity: '0'}),
                animate(
                    '600ms cubic-bezier(0.35, 0, 0.25, 1)',
                    style({opacity: '1'})
                ),
            ]),
            transition(':leave', [
                style({opacity: '1'}),
                animate(
                    '600ms cubic-bezier(0.35, 0, 0.25, 1)',
                    style({opacity: '0'})
                ),
            ]),
        ]),
    ],
})
export class WishlistPaneComponent implements OnInit, OnDestroy {
    constructor(
        private translationService: TranslationService,
        private wishlistService: WishlistService,
        private contextService: SiteContextService,
        private favoriteService: FavoriteService,
    ) {
        this.currentBusinessDimension = this.contextService.getContext().businessDimension.toLowerCase();
    }

    @ViewChild('productListContainer') productListContainer: ElementRef;

    wishlist: Wish[];
    translations: Observable<any>;
    animationStateChanged = new EventEmitter<AnimationEvent>();
    startExitTrigger = new Subject();
    destroy = new Subject();
    isFormulaOpen = false;
    expand = expand;
    currentBusinessDimension: string;
    validToSendFavorites: boolean;
    submitTouched = false;

    // List of products
    products: ImpactCoreModelsProductsDtoStyleDto[];
    favorites: IFavoriteProductStore[];


    ngOnInit() {
        this.translations = this.translationService.translations$;
        this.favoriteService.favoriteProductStoreList$
            .pipe(takeUntil(this.destroy))
            .subscribe(favorites => {
                this.favorites = favorites;
                this.validToSendFavorites = favorites.filter(favorite => favorite['sku'] === undefined || favorite['sku'] === '').length === 0;
            });
        this.favoriteService.getFavoriteProductsData()
            .subscribe(products => this.products = products);
    }

    doSomething(event) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    onDelete(product: ImpactCoreModelsProductsDtoStyleDto) {
        this.favoriteService.removeFavorite(product.StyleId, product.ActiveColorGroupId);
        this.products = this.products
            .filter(prod => {
                if (prod.StyleId === product.StyleId) {
                    return prod.ActiveColorGroupId !== product.ActiveColorGroupId;
                }
                return true;
            });
    }

    toggleFormula() {
        this.isFormulaOpen = !this.isFormulaOpen;
    }

    onChangeSize(product: IChangeSizeProps) {
        this.favoriteService.updateFavorite(product.style, product.color, product.sku);
        setTimeout(() => {
            if (this.getInvalidSizePickerElement()) {
                this.scrollToInvalidSizePickerElement();
            }
        }, 0);
    }

    getSelectedSizeFromFavorites(styleId: string, activeColorGroupId: string): string | undefined {
        return this.favorites.filter(fav => fav.styleId === styleId && fav.colorId === activeColorGroupId).shift().sku ?? undefined;
    }

    showValidationMessage() {
        this.submitTouched = true;
        setTimeout(() => {
            if (this.getInvalidSizePickerElement()) {
                this.scrollToInvalidSizePickerElement();
            }
        }, 0);
    }

    showSizeMeassage(styleId: string, activeColorGroupId: string) {
        return this.submitTouched && (this.getSelectedSizeFromFavorites(styleId, activeColorGroupId) === '' ||
            this.getSelectedSizeFromFavorites(styleId, activeColorGroupId) === undefined);
    }

    private getInvalidSizePickerElement(): HTMLElement | null {
        return this.productListContainer.nativeElement.querySelector('.invalid');
    }

    private scrollToInvalidSizePickerElement(): void {
        this.productListContainer.nativeElement.scrollTo({
            // @ts-ignore
            top: this.getInvalidSizePickerElement()?.offsetParent?.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    }
}
