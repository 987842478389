import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    selector: '[appDynamicLinks]',
})
export class DynamicLinksDirective {
    constructor(private router: Router) {}

    @HostListener('click', ['$event'])
    public onClick($event: Event) {
        const linkElement = $event.target as Element;

        if (linkElement && linkElement.tagName === 'A') {
            $event.preventDefault();
            this.router.navigateByUrl(linkElement.getAttribute('href'));
        }
    }
}
