<div *ngIf="isPane; then paneMap else pageMap"></div>
<ng-template #paneMap>
    <div class="find-store__map-container">
        <agm-map #map [ngStyle]="{ 'height': mapHeight+'px' }" [latitude]="lat" [longitude]="lng" [zoom]="zoom" [styles]="mapStyling"
            [disableDefaultUI]="true" [streetViewControl]="false" [zoomControl]="false" [fitBounds]="bounds" [usePanning]="true"
            [scrollwheel]="null" (mapReady)="storeMapReady($event)">
            <div *ngIf="storesToDisplay.length > 0">
                <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                    <agm-marker *ngFor="let store of storesToDisplay; let i = index" [latitude]="store?.Latitude" [longitude]="store?.Longitude"
                        (markerClick)="closeInfoWindows()">
                        <agm-info-window #InfoWindow [latitude]="store?.Latitude" [longitude]="store?.Longitude">
                            <strong>
                                <a (click)="storeSelected(store)">{{store?.StoreImportDescription}}</a>
                            </strong>
                            <p>{{ store?.Address + ' ' + store?.Streetnumber + ', ' + store?.Zip + ' ' + store?.City}}</p>
                        </agm-info-window>
                    </agm-marker>
                </agm-marker-cluster>
            </div>
        </agm-map>
    </div>
</ng-template>
<ng-template #pageMap>
    <div *ngIf="bounds; else panningMap">
        <agm-map [ngStyle]="{ 'height': mapHeight+'px' }" [latitude]="lat" [longitude]="lng" [zoom]="zoom" [styles]="mapStyling"
            [disableDefaultUI]="true" [streetViewControl]="false" [zoomControl]="false" [fitBounds]="bounds" [usePanning]="true"
            [scrollwheel]="null" (mapReady)="storeMapReady($event)">
            <div *ngIf="storesToDisplay.length > 0">
                <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                    <agm-marker *ngFor="let store of stores" [latitude]="store?.Latitude" [longitude]="store?.Longitude" (markerClick)="closeInfoWindows()">
                        <agm-info-window #InfoWindow [latitude]="store?.Latitude" [longitude]="store?.Longitude">
                            <strong>
                                <a (click)="storeSelected(store)">{{store?.StoreImportDescription}}</a>
                            </strong>
                            <p>{{ store?.Address + ' ' + store?.Streetnumber + ', ' + store?.Zip + ' ' + store?.City}}</p>
                        </agm-info-window>
                    </agm-marker>
                </agm-marker-cluster>
            </div>
        </agm-map>
    </div>
    <!-- If bounds it set, the panning won't update the map -->
    <ng-template #panningMap>
        <agm-map [ngStyle]="{ 'height': mapHeight+'px' }" [latitude]="lat" [longitude]="lng" [zoom]="zoom" [styles]="mapStyling"
            [disableDefaultUI]="true" [streetViewControl]="false" [zoomControl]="false" [usePanning]="true" [scrollwheel]="null"
            (mapReady)="storeMapReady($event)">
            <div *ngIf="storesToDisplay.length > 0">
                <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                    <agm-marker *ngFor="let store of stores" [latitude]="store?.Latitude" [longitude]="store?.Longitude" (markerClick)="closeInfoWindows()">
                        <agm-info-window #InfoWindow [latitude]="store?.Latitude" [longitude]="store?.Longitude">
                            <strong>
                                <a (click)="storeSelected(store)">{{store?.StoreImportDescription}}</a>
                            </strong>
                            <p>{{ store?.Address + ' ' + store?.Streetnumber + ', ' + store?.Zip + ' ' + store?.City}}</p>
                        </agm-info-window>
                    </agm-marker>
                </agm-marker-cluster>
            </div>
        </agm-map>
    </ng-template>
</ng-template>