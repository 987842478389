import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    OnDestroy,
    Output,
    PLATFORM_ID,
} from '@angular/core';

@Directive({
    selector: '[inView]',
})
export class InViewDirective implements AfterViewInit, OnDestroy {
    @Output() public inView: EventEmitter<any> = new EventEmitter<any>();

    private _intersectionObserver?: IntersectionObserver;

    constructor(
        private _element: ElementRef,
        @Inject(PLATFORM_ID) private platformId
    ) {}

    ngAfterViewInit(): void {
        if (
            isPlatformBrowser(this.platformId) &&
            (window as any).IntersectionObserver !== undefined
        ) {
            this._intersectionObserver = new IntersectionObserver(
                entries => {
                    this.checkForIntersection(entries);
                },
                {
                    threshold: [0.5],
                }
            );
            this._intersectionObserver.observe(this._element.nativeElement);
        } else {
            this.inView.emit(true);
        }
    }

    private checkForIntersection(entries: IntersectionObserverEntry[]) {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (this.checkIfIntersecting(entry)) {
                this.inView.emit(true);
            } else {
                this.inView.emit(false);
            }
        });
    }

    private checkIfIntersecting(entry: IntersectionObserverEntry) {
        return (
            (entry as any).isIntersecting &&
            entry.target === this._element.nativeElement
        );
    }

    ngOnDestroy(): void {
        if (
            isPlatformBrowser(this.platformId) &&
            (window as any).IntersectionObserver !== undefined
        ) {
            this._intersectionObserver.unobserve(this._element.nativeElement);
            this._intersectionObserver.disconnect();
        }
    }
}
