import {
    animate,
    group,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

const ANIMATION_TIMINGS_ENTER = '400ms cubic-bezier(0, 0, 0, 1)';
const ANIMATION_TIMINGS_LEAVE = '200ms cubic-bezier(0.25, 0, 0.5, 1)';

export const infoPageAnimations = [
    trigger('slideContent', [
        state(
            'void',
            style({ /*transform: 'translateY(-100%)',*/ opacity: 0 })
        ),
        state('enter', style({ transform: 'translateY(0)', opacity: 1 })),
        state(
            'leave',
            style({ /*transform: 'translateY(-100%)',*/ opacity: 0 })
        ),
        transition('* => enter', [group([animate(ANIMATION_TIMINGS_ENTER)])]),
        transition('* => leave', [
            group([group([animate(ANIMATION_TIMINGS_LEAVE)])]),
        ]),
    ]),
];
