import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
    ImpactWebsiteCodeWebApiControllersPaymentProvider as IPaymentProvider,
    ImpactWebsiteCodeWebApiControllersPaymentTypeServiceModel as IPaymentTypes,
} from '@shared/swagger/swagger.interface';
import { map, tap } from 'rxjs/operators';
import { ReplaySubject, Observable } from 'rxjs';
import { SiteContextService } from '@core/site-context.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService
    ) {}
    private _selectedPayment: ReplaySubject<
        IPaymentProvider
    > = new ReplaySubject<IPaymentProvider>(1);

    set selectedPayment(provider: any) {
        this._selectedPayment.next(provider);
    }
    get selectedPayment() {
        return this._selectedPayment.asObservable();
    }
    getPaymentTypes(): Observable<IPaymentTypes[]> {
        return this.http
            .get<IPaymentTypes[]>(
                `${this.siteContextService.getRootUrl()}/webapi/PaymentType/GetPaymentTypes`
            )
            .pipe(
                map(data => {
                    data.forEach(type =>
                        type.PaymentProviders.forEach(provider => {
                            provider[
                                'PaymentTypes'
                            ] = provider.DibsPaymentTypes.toLowerCase().split(
                                ', '
                            );
                        })
                    );
                    return data;
                })
            );
    }
}
