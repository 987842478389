<div class="product-spot__details__brand" *ngIf="activeProduct?.productData?.BrandImage">


    <div class="product-spot__details__brand__container">


        <div class="first-column">

            <div class="brand-image-container">
                <ng-container [ngSwitch]="activeProduct?.productData?.ProductBrandPageLinks.length">
                    <a *ngSwitchCase="true"
                        [IRouterLink]="activeProduct?.productData?.ProductBrandPageLinks[0]?.Link">
                        <img src="{{activeProduct?.productData.BrandImage}}"
                            class="product-spot__details__brand-image"
                            alt="{{activeProduct?.productData.BrandName}}" />
                    </a>
                    <ng-container *ngSwitchCase="false">
                        <img src="{{activeProduct?.productData.BrandImage}}"
                            class="product-spot__details__brand-image"
                            alt="{{activeProduct?.productData.BrandName}}" />
                    </ng-container>
                </ng-container>
            </div>

            <div class="brand-info">
                {{ activeProduct?.productData?.PDPBrandText }}
            </div>

            <div class="brand-link">
                <a [IRouterLink]="activeProduct?.productData?.PDPBrandLink">
                    {{ activeProduct?.productData?.PDPBrandLinkText }}
                </a>
            </div>

        </div>


        <div class="second-column">
            <div class="brand-big-image">
                <img [src]="activeProduct?.productData?.PDPBrandImage" alt="">
            </div>
        </div>

    </div>




   
</div>