import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {TranslationService} from '@core/translation.service';
import {goArrow} from '@shared/svg';

@Component({
    selector: 'app-sublayout50LargeSmall',
    templateUrl: './sublayout50LargeSmall.component.html',
})
export class Sublayout50LargeSmallComponent implements OnInit {
    constructor(
        private elm: ElementRef,
        public translation: TranslationService
        ) {
    }
    static ref = 'Sublayout50LargeSmall';

    @Input() public data;

    public templateName: string;
    public mobileLayout: string;
    public icon = {
        goArrow
    };

    ngOnInit() {
        for (let i = 0; i < this.data.Column1Spots.length; i++) {
            if (this.data.Column1Spots[i].TemplateName) {
                this.templateName = this.data.Column1Spots[i].TemplateName;
                this.mobileLayout = this.data.Column1Spots[i].MobileLayout;
            }
        }
    }

}
