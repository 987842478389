import {Pipe, PipeTransform} from '@angular/core';
/**
 * Pipe to transform character return and new line characters to list items or string with <br> tags.
 * @param {string} value - The value should be a string containing character returns \r and new line \n.
 * @param {boolean} useBrTag - This controls if the pipe replace the \r\n with a <br> tag.
 * @returns {string | string[]} If the argument useBrTag is not used a string array is returned. Otherwise a string is returned.
 *
 * @example
 * If useBrTag is false use the pipe like this:
 * <ul>
 *     <li *ngFor="let item of items | transformToLineBreaks" [innerHTML]="item"></li>
 * </ul>
 * @example
 * If useBrTag is true use the pipe like this:
 * <p [innerHTML]="textString | transformToLineBreaks: true"></p>
 */

@Pipe({
    name: 'transformToLineBreaks'
})
export class TransformToLineBreaksPipe implements PipeTransform {
    transform(value: string, useBrTag?: boolean): string | string[] {
        if (useBrTag) {
            return value
                .replace(new RegExp(/\r\n(?!\<)/, 'g'), '<br>')
                .replace(new RegExp(/\r\n/, 'g'), '');
        }
        return value.split('\r\n');
    }

}
