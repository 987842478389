<div class="article__sublayout50 columns is-mobile is-centered is-multiline">
    <div class="column is-12-mobile is-12-tablet is-6-desktop article__column-first">
        <div class="columns is-mobile is-centered is-multiline">
            <div *ngFor="let spot of data.ArticleColumn1" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName"></div>
        </div>
    </div>
    <div class="column is-12-mobile is-12-tablet is-6-desktop article__column-second">
        <div class="columns is-mobile is-centered is-multiline">
            <div *ngFor="let spot of data.ArticleColumn2" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName"></div>
        </div>
    </div>
</div>
