import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    PLATFORM_ID,
    Renderer2,
    ViewChild,
    ViewChildDecorator,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-made-2-measure',
    template: `
        <article id='made2measure'>
            <iframe
                    style='border:none;width:100%;height:500px;'
                    sandbox='allow-popups allow-same-origin allow-popups-to-escape-sandbox allow-scripts allow-forms'
                    scrolling='no' data-no-scroll-into-view='true' allow='autoplay; fullscreen'
                    #contact
            ></iframe>
        </article>
    `
})
export class Made2MeasureComponent implements AfterViewInit {
    static ref = 'Made2Measure';
    @Input() public data;
    @ViewChild('contact') contactElm: ElementRef;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private render: Renderer2,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            const scriptsInHead = document.head.getElementsByTagName('script');
            let isScriptPressent = false;
            for (let arrLen = scriptsInHead.length, x = 0; x < arrLen; x++) {
                if (scriptsInHead.item(x).src.includes('utilities.responsive_iframe.min.js')) {
                    isScriptPressent = true;
                    break;
                }
            }
            if (!isScriptPressent) {
                const scripty = this.render.createElement('script');
                this.render.setAttribute(scripty, 'src', 'https://files.cdn.leadfamly.com/campaign/sdk/utilities.responsive_iframe.min.js');
                this.render.appendChild(document.head, scripty);
            }
        }
    }


    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.contactElm.nativeElement.src = 'https://www.spil.axel.dk/axel-made-to-measure-kontaktformular?iframe=dontbreakout';
        }
    }

}
