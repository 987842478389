import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-store-sublayout',
    templateUrl: './store-sublayout.component.html',
})
export class StoreSublayoutComponent {
    constructor() {}

    static ref = 'StoreSublayout';
    @Input()
    public data;
}
