<article class="spot-text"
         [ngClass]="{'is-hidden-mobile': data.HideOnMobile, 'is-hidden-tablet': data.HideOnDesktopTablet}"
         *ngIf="!hideFromSearchEngines" [style.opacity]="displayPersonalizedContent && !isSpotDisplayingPersonalizedContent ? '0' : '1'">
    <div class="spot-text__content">
        <app-spot-heading-seo
            [headingClass]="'spot-text__heading'"
            [hTag]="data.HeadingSeoImportance"
            [headingText]="data.Heading"
            *ngIf="data.Heading"
        ></app-spot-heading-seo>
        <div class="spot-text__subheading" *ngIf="data.Subheading">{{data.Subheading}}</div>
        <div class="spot-text__body" intercept-links [innerHTML]="data.Body | safeHtml" *ngIf="data.Body"></div>
    </div>
</article>
