import { Component, Inject, OnInit } from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { FilterService } from '@features/filter/services/filter.service';
import { IProduct } from '@features/product-filter/models/product.model';
import { IMPACT_OVERLAY_DATA, ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { close } from '@shared/svg';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-product-filter-show-more-dialog',
    templateUrl: './product-filter-show-more-dialog.component.html',
})
export class ProductFilterShowMoreDialogComponent implements OnInit {
    closeSvg = close;
    translations: any;
    facetName: string;
    private destroy = new Subject();

    constructor(
        public overlayRef: ImpactOverlayRef,
        @Inject(IMPACT_OVERLAY_DATA) public data: any,
        private filterService: FilterService<IProduct>,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.translationService.translations$
            .pipe(takeUntil(this.destroy))
            .subscribe(data => {
                this.translations = data;
                this.facetName = this.translations.BrandOverview.Dialog[
                    this.data.name
                ];
            });
    }

    public onItemChange(event): void {
        const newChildren = this.data.children.map(child => {
            const newIsActive =
                child.key === event.option.key
                    ? event.isChecked
                    : child.isActive;

            return Object.assign(child, { isActive: newIsActive });
        });

        this.filterService.updateFilter(
            Object.assign(this.data, { children: newChildren })
        );
    }

    itemsToShow() {
        return this.data.children;
    }

    close() {
        this.overlayRef.close();
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }
}
