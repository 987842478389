import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { Facet, MultiCheckBoxFacetChild, TwoSidedSliderFacet, VisualFacet } from '@features/filter/models/facet.model';
import { FilterService } from '@features/filter/services/filter.service';
import { IProduct } from '@features/product-filter/models/product.model';
import { IMPACT_OVERLAY_DATA, ImpactOverlayRef, OverlayService } from '@impactdk/ngx-overlay';
import { slideInOutAnimation } from '@shared/animations/slide-in-out.animation';
import { cross, search } from '@shared/svg';
import { Subject } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { isArray } from 'rxjs/internal-compatibility';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { SiteContextService } from '@core/site-context.service';
import { MobileFilterStateService } from '@core/mobile-filter-state.service';

@Component({
    selector: 'app-product-filter-mobile',
    templateUrl: './product-filter-mobile.component.html',
    animations: [
        slideInOutAnimation,
        trigger('activeFilters', [
            transition(':enter', [
                style({
                    transform: 'scale(0)',
                }),
                animate(
                    '400ms cubic-bezier(0.35, 0, 0.25, 1)',
                    style({
                        transform: 'scale(1)',
                    })
                ),
            ]),
            transition(':leave', [
                animate(
                    '400ms cubic-bezier(0.35, 0, 0.25, 1)',
                    style({
                        transform: 'scale(0)',
                    })
                ),
            ]),
        ]),
    ],
})
export class ProductFilterMobileComponent implements OnInit, OnDestroy {

    constructor(
        public overlayRef: ImpactOverlayRef,
        private overlayService: OverlayService,
        private filterService: FilterService<IProduct>,
        private translationService: TranslationService,
        @Optional() @Inject(IMPACT_OVERLAY_DATA) public initalFacetKey = '',
        private siteContextService: SiteContextService,
        private mobileFilterStateService: MobileFilterStateService,
    ) {
        this.currency = this.siteContextService.getContext().currency;
    }
    @ViewChild('filterFacetInput') filterFacetInputElm: ElementRef<HTMLInputElement>;
    public filterFacetValue = '';

    currency = '';
    public facets: Facet[];
    public activeCollection: boolean[] = [];
    public productsFound: number;
    public activeFacets: Array<{facet: VisualFacet, child: MultiCheckBoxFacetChild}> = [];
    public translations: any;

    public firstFilter = true;


    private destroy = new Subject();

    icons = {
        cross,
        search
    };

    // Trigger animation
    public animationStateChanged = new EventEmitter<AnimationEvent>();
    public startExitTrigger = new Subject();

    protected readonly isArray = isArray;
    minValue = 0;
    maxValue: number;
    currentMax: number;
    options: Options = {
        floor: 0,
        ceil: 8000,
        step: 10,
        translate: (value: number) => {
            return `${value} ${this.currency}`;
        }
    };

    sliderEventSubject = new Subject<{facet: Facet, sliderEvent: ChangeContext, paginationFacet: Facet}>();

    protected readonly Array = Array;

    public toggleFacets(index: number): void {
        this.activeCollection[index] = !this.activeCollection[index];
    }
    public openFacetsPane(key: string) {
        /*const facetPaneElm = document.getElementById(key).querySelector('.mobile-facet-overlay');
        facetPaneElm.classList.add('mobile-facet-overlay__open');*/

        this.mobileFilterStateService.backButtonState$.next({
            key,
            show: true
        });
    }

    public animationStateChange(event): void {
        this.animationStateChanged.emit(event);
    }

    public startExitAnimation(): void {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }

    public closeFilterPane() {
        this.overlayRef.close();
    }

    public filterFacets(event: KeyboardEvent) {
        this.filterFacetValue = (event.target as HTMLInputElement).value;
    }

    public clearFilterFacetValue() {
        this.filterFacetValue = '';
        this.filterFacetInputElm.nativeElement.value = '';
    }

    private initialOpenFacet(facets: Facet[]) {
        if (this.firstFilter) {
            this.firstFilter = false;
            const indexIntialFacet = facets.findIndex((facet) => facet.key === this.initalFacetKey);
            if (indexIntialFacet > -1) {
                this.toggleFacets(indexIntialFacet);
            }
        }
    }

    ngOnInit() {
        // update filter with price facet
        this.sliderEventSubject
            .pipe(
                debounceTime(750),
                takeUntil(this.destroy)
                )
            .subscribe(event => {
                this.filterService.updateFilter([
                    {...event.facet, ...{currentMax: event.sliderEvent.highValue, currentMin: event.sliderEvent.value, isActive: true}},
                    event.paginationFacet,
                ]);
            });

        this.translationService.translations$
            .pipe(first())
            .subscribe(translations => {
                this.translations = translations;
            });

        this.filterService
            .getFilter()
            .pipe(takeUntil(this.destroy))
            .subscribe(data => {
                this.facets = this.updateFacetKeys(data.facets);
                this.initialOpenFacet(this.facets);
                this.productsFound = data.totalEntityCount;

                const {max, currentMax, currentMin} = this.getMaxPrice(this.facets);
                this.maxValue = currentMax;
                this.minValue = currentMin;
                this.options.ceil = max;

                if (data.facets.length) {
                    const activeFacets = data.facets.filter((facet: any) => {
                        if (facet.kind === 'Slider') {
                            return facet.isActive;
                        }

                        return (
                            facet.kind !== 'pagination' &&
                            facet.kind !== 'sort' &&
                            facet.kind !== 'search'
                        );
                    });

                    if (activeFacets && activeFacets.length) {
                        this.activeFacets = activeFacets
                            .map((facet: VisualFacet) => {
                                return facet.kind === 'Multicheck'
                                    ? facet.children
                                          .filter(
                                              facetItem => facetItem.isActive
                                          )
                                          .map(child => {
                                              return {
                                                  facet,
                                                  child,
                                              };
                                          })
                                    : [];
                            })
                            .reduce((previousValue, currentValue) => {
                                return [...previousValue, ...currentValue];
                            });
                    } else {
                        this.activeFacets = [];
                    }
                }
            });
    }

    ngOnDestroy() {
        this.filterFacetValue = undefined;
        this.destroy.next();
        this.destroy.complete();
    }

    onItemChange(facet, option, checked) {
        const newChildren = facet.children.map(child => {
            const newIsActive =
                child.key === option.key ? checked : child.isActive;

            return {...child,  isActive: newIsActive};
        });

        const currentFacet: any = this.facets.find(obj => {
            return obj.key === facet.key;
        });

        currentFacet.children = newChildren;

        const paginationFacet: any = this.facets.find(facet => {
            return facet.kind === 'pagination';
        });

        paginationFacet.pageIndex = 0;

        this.filterService.updateFilter(this.facets);
    }

    // Update facet keys to unique idetifiers
    updateFacetKeys(facets: Facet[]): Facet[] {
        const updatedFacets = facets;

        for (let i = 0; i < updatedFacets.length; i++) {
            const facet = updatedFacets[i];

            if (facet.kind === 'Multicheck' && facet.children.length) {
                for (let i = 0; i < facet.children.length; i++) {
                    const facetChildren = facet.children[i];
                    facetChildren.id = facetChildren.key.replace(' ', '-');
                    facetChildren.id = this.uniqueId(facetChildren.key);
                }
            }
        }

        return updatedFacets;
    }

    // Create unique key for facet checkboxes
    uniqueId(facetName: string): string {
        return `${facetName.toLowerCase()}-${Math.random().toString().substring(2, 8)}`;
    }

    removeFacet(facet, item) {
        const newChildren = facet.children.map(child => {
            const newIsActive = child.key === item.key ? false : child.isActive;

            return {...child,  isActive: newIsActive};
        });

        this.filterService.updateFilter(
            {...facet,  children: newChildren}
        );
    }

    resetAllFilters() {
        this.filterService.resetFilter(this.facets);
    }
    onSliderChange(facet, event) {
        const paginationFacet: any = this.facets.find(f => {
            return f.kind === 'pagination';
        });
        paginationFacet.pageIndex = 0;

        this.sliderEventSubject.next({facet, sliderEvent: event, paginationFacet});
    }

    trackByFn(index, item) {
        return item.child ? item.child.name : null; // or item.id
    }

    trackByFacet(index, item) {
        return item.key ? item.key : null; // or item.id
    }
    private getMaxPrice(facets: Facet[]): PriceFacetValues {
        const priceSlider = facets.filter(facet => facet.kind === 'Slider' && facet.key === 'price');
        if (!priceSlider.length) {
            return;
        }
        const priceFacet = priceSlider.shift() as TwoSidedSliderFacet;
        const priceValues = {
            max: priceFacet.max,
            currentMax: priceFacet.currentMax,
            currentMin: priceFacet.currentMin
        };
        return priceValues;
    }
}
interface PriceFacetValues {
    max: number;
    currentMax: number;
    currentMin: number;
}
