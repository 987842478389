import { AnimationEvent } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { IMPACT_OVERLAY_DATA } from '@impactdk/ngx-overlay';
import { Subject } from 'rxjs';
import { SiteContextService } from '@core/site-context.service';
import { goArrow } from '@shared/svg';
import { TranslationService } from '@core/translation.service';
import { TrackingService } from '@core/tracking/tracking.service';

@Component({
    selector: 'app-shop-by-image',
    templateUrl: 'shop-by-image.html',
})
export class ShopByImageComponent implements OnInit {
    static ref = 'ShopByImageComponent';

    mainImage: string;
    imageBgColor = 'eaebe8';
    productName: string;
    productMeta: string;
    currency: string;
    icons = {
        goArrow,
    };

    // Trigger animation
    animationStateChanged = new EventEmitter<AnimationEvent>();
    startExitTrigger = new Subject();

    constructor(
        @Inject(IMPACT_OVERLAY_DATA) public data: any,
        private contextSettings: SiteContextService,
        public translations: TranslationService,
        private tracking: TrackingService
    ) {}

    ngOnInit() {
        this.currency = this.contextSettings.getContext().currency;
        this.mainImage = this.data.marker.Images[0].Path;
        this.productName =
            this.data.marker.Name !== ''
                ? this.data.marker.Name
                : [
                      this.data.marker.Fit,
                      this.data.marker.Model,
                      this.data.marker.Category,
                  ].join(' ');
        this.productMeta = this.generateProductMeta(this.data.marker);
    }

    private generateProductMeta(markerProdData: any): string {
        const tags = [];
        if (markerProdData.Fit) {
            tags.push(markerProdData.Fit);
        }
        if (markerProdData.Name) {
            tags.push(markerProdData.Name);
        } else {
            tags.push(markerProdData.Category);
        }
        tags.push(markerProdData.Color);
        return tags.join(' | ');
    }

    public animationStateChange(event): void {
        this.animationStateChanged.emit(event);
    }

    public startExitAnimation(): void {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }

    doSomething(event) {
        this.animationStateChanged.emit(event);
    }

    trackShopByImageDetailClick(marker) {
        this.tracking.clickShopByImage(marker, 'Detail', this.data.sitecoreId);
    }
}
