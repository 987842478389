<div class="container">
    <div class="sublayout">
        <div class="columns">
            <div class="column">
                <article class="columns is-multiline">
                    <div class="gift-card-balance">
                        <form class="form"
                              novalidate
                              name="form"
                              [ngClass]="{'form_submitted': form.submitted}"
                              (ngSubmit)="getSaldo()"
                              #form="ngForm">
                            <div class="form__field">
                                <label class="label label_animated">
                                    <div class="input-container input-container_icon">
                                        <input type="text"
                                               pattern="[0-9]*"
                                               class="input input_text input_bold"
                                               name="GiftcardNumber"
                                               appResetInput
                                               minlength="16"
                                               maxlength="30"
                                               required
                                               [(ngModel)]="model.GiftcardNumber"
                                               #GiftcardNumber="ngModel">
                                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                                        <span class="label__text">{{(translations.translations$ | async).GiftcardBalance?.Form?.GiftcardNumberLabel}}</span>
                                    </div>
                                </label>
                                <div class="form__validation" *ngIf="(GiftcardNumber.invalid && GiftcardNumber.touched) || form.submitted">
                                    <span class="validation" *ngIf="GiftcardNumber.errors?.required">{{(translations.translations$ | async).GiftcardBalance?.Error?.GiftcardEmptyField}}</span>
                                    <span class="validation" *ngIf="GiftcardNumber.errors?.minlength">{{(translations.translations$ | async).GiftcardBalance?.Error?.GiftcardNumberErrorLengthFailed}}</span>
                                </div>
                            </div>
                            <div class="form__field">
                                <label class="label label_animated">
                                    <div class="input-container input-container_icon">
                                        <input type="password"
                                               class="input input_text input_bold"
                                               name="GiftcardPassword"
                                               appResetInput
                                               required
                                               [(ngModel)]="model.GiftcardPassword"
                                               #GiftcardPassword="ngModel">
                                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                                        <span class="label__text">{{(translations.translations$ | async).GiftcardBalance?.Form?.GiftcardAccessCodeLabel}}</span>
                                    </div>
                                </label>
                                <div class="form__validation" *ngIf="(GiftcardPassword.invalid && GiftcardPassword.touched) || form.submitted">
                                    <span class="validation" *ngIf="GiftcardPassword.errors?.required">{{(translations.translations$ | async).GiftcardBalance?.Error?.GiftcardEmptyField}}</span>
                                </div>
                            </div>
                            <button type="submit" class="button button_primary button_full button_text-center">
                                {{(translations.translations$ | async).GiftcardBalance?.Form?.GiftcardSubmitButton}}
                                <spinner name="checkGiftcardBalance" [size]="20" background="true"></spinner>
                            </button>
                        </form>
                    </div>
                    <div class="gift-card-balance-result" *ngIf="showBalanceCard">
                        <div *ngIf="cardNotRecognized">{{(translations.translations$ | async).GiftcardBalance?.Error?.GiftcardSubmitFailed}}</div>
                        <div class="gift-card-balance-result__label" *ngIf="!cardNotRecognized">Saldo<br>
                            <div class="gift-card-balance-result__amount">{{cardBalance | kfmCurrency: currency}}</div>
                        </div>
                        <div><span>Giftcard number</span><br>{{GiftcardNumber.value}}&nbsp;</div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div>
