
    <div class="product-card" NgxAnimateIn [NgxAnimateInAnimation]="animateInAnimation">
        <a [routerLink]="product.ProductPath" (mouseenter)="slideImages()" (mouseleave)="slideImagesOff()" [queryParams]="{id: product.ColorGroupId}" class="product-card__link" (click)="setYPosition(); sentGTMData()">
            <div class="product-card__content">
                <app-product-splash
                    [productPriceGroup]="product.PriceGroup"
                    [percentageSaving]="product.PercentageSavings"
                    [discountDescription]="product.DiscountDescription"
                    [isNew]="product.IsNew"
                    [splashVisibility]="splashVisibility"
                ></app-product-splash>
                <div class="product-card__image" [ngStyle]="{'background-color': '#' + imageBgColor}">
                    <app-favorite-picker
                        class="inside-white-circle"
                        [styleId]="product.ProductId"
                        [colorId]="product.ColorGroupId.substr(product.ColorGroupId.indexOf('-')+1)"
                    ></app-favorite-picker>
                    <div *ngIf="!isExposedProduct" class="lazy-load" [ngStyle]="{'padding-bottom': isExposedProduct ? calcBottomPadding() : ((imageSize.width / imageSize.height) * 100) + '%'}">
                        <img
                            loading="lazy"
                            [src]="product.ImageUrl[0] + '&w='+ imageSize.width +'&h='+ imageSize.height +'&bgcolor=' + imageBgColor"
                            [alt]="getImageAltTag()"
                            class="product-card__image"
                            [ngStyle]="{'background-color': '#' + imageBgColor}"
                            width="360"
                            height="360"
                        >
                        <img #secondaryImage [alt]='getImageAltTag()'
                             *ngIf="product.FlipImagesOnProductList"
                             class="product-card__image" [ngClass]="!imageLoaded ? 'product-card__image_not-loaded' : 'product-card__image_second'">
                        <ng-container #slideImagesContainer></ng-container>
                    </div>
                    <div *ngIf="isExposedProduct" class="lazy-load" [ngStyle]="{'padding-bottom': isExposedProduct ? calcBottomPadding() : ((imageSize.width / imageSize.height) * 100) + '%'}">
                        <img loading="lazy" [src]="exposedImage[0] + '&w='+ imageSize.width +'&h='+ imageSize.height +'&bgcolor=' + imageBgColor" [alt]="getImageAltTag()" class="product-card__image" [ngStyle]="{'background-color': '#' + imageBgColor}">
                        <img #secondaryImage [alt]='getImageAltTag()'
                             *ngIf="product.FlipImagesOnProductList"
                             class="product-card__image" [ngClass]="!imageLoaded ? 'product-card__image_not-loaded' : 'product-card__image_second'">
                        <ng-container #slideImagesContainer></ng-container>
                    </div>
                </div>
                <div class="product-card__details">
                    <div class="product-card__name">{{product.Brand}}</div>
                    <div class="product-card__detail">
                        <span>{{getProductTags()}}</span>
                    </div>
                    <div class="product-card__prices-splash" *ngIf="!isGiftcard">
                        <div class="product-card__price" [ngClass]="product.IsOnSale ? 'product-card__price_line-through' : ''">{{product.OriginalPrice | kfmCurrency: product.Currency }}</div>
                        <div class="product-card__price product-card__price_discount" *ngIf="product.IsOnSale">{{product.Price | kfmCurrency: product.Currency}}</div>
                        <!--<div class="product-card__price product-card__price_discount" *ngIf="product.DiscountDescription">{{product.DiscountDescription}}</div>-->
                    </div>
                    <app-color-group-variations [colorGroupList]="product.ColorHexCodes"></app-color-group-variations>
                    <div class="product-card__giftcard-splash" *ngIf="isGiftcard">{{translations?.ProductList?.Block?.ChooseAmountLabel}}</div>
                    <div class="product-card__sizes" *ngIf="!isGiftcard"
                        [innerHTML]="product.ActiveSizesDisplayNames | showSizes: sizeLabels?.SizeLabel:sizeLabels?.SizesLabel">
                    </div>
                </div>
            </div>
        </a>
    </div>
