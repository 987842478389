import { AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { MegaMenuContentSpot } from '@features/mobile-menu/menu.interface';
import { arrowRight } from '@shared/svg';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-mega-menu-spot',
    template: `
        <section
            class='mega-menu-spot'
            [ngClass]="{'mega-menu-spot_double': data.Size === 'Double'}"
            [ngStyle]="{'color': '#' + data.TextColor}"
            [style.--spot-bg-image-url]="getImageUrl()"
        >
            <div class='mega-menu-spot__headline'>{{data.Headline}}</div>
            <div (click)='hideMenu()' class='mega-menu-spot__content' intercept-links
                 [innerHTML]='data.Content | safeHtml' #content></div>
        </section>
    `,
})
export class MegaMenuSpotComponent implements AfterViewInit {
    constructor(
        @Inject(PLATFORM_ID) private platformId,
    ) {

    }

    @Input() data: MegaMenuContentSpot;
    @Input() listItem: any;

    @ViewChild('content') spotContentElm: ElementRef;
    arrowIcon = arrowRight;

    ngAfterViewInit(): void {
        this.setStrokeColorInSvg();
        if (isPlatformBrowser(this.platformId)) {
            this.spotContentElm.nativeElement.querySelectorAll('a').forEach(elm => {
                elm.innerHTML = `${this.arrowIcon}${elm.innerHTML}`;
            });
        }
    }

    setStrokeColorInSvg(): void {
        this.arrowIcon = this.arrowIcon.replace('stroke="#fff"', `stroke="#${this.data.TextColor}"`);
    }

    hideMenu() {
        this.listItem.classList.add('hideMegaMenu');

        setTimeout(() => {
            this.listItem.classList.remove('hideMegaMenu');
        }, 3000);
    }

    public getImageUrl(): string {
        const url = this.data.BackgroundImageUrl;
        const imgSize = this.data.Size === 'Normal' ? 600 : 1200;
        return `url(${url}?w=${imgSize})`;
    }
}
