<div class="mega-menu">
    <div class="mega-menu__columns">
        <div class="mega-menu__column mega-menu__navigation-column" *ngIf="showMainMenuNavigation" >
            <ul class="mega-menu__main-menu-items" [appMegaMenuAxelClasses]="item" [idx]="0">
                <li *ngFor="let menuItem of item.MainMenuItems; index as i;" (mouseenter)="selectedMenuItem = menuItem">
                        <a
                            [IRouterLink]="menuItem.NavigationLink"
                            [closeDialog]="true"
                            class="mega-menu__link main-menu-items"
                            (click)="handleClick(subItem.NavigationLink)">
                            {{menuItem.NavigationTitle}}
                            <span *ngIf="menuItem == selectedMenuItem" class="right-arrow"> > </span>
                        </a>
                </li>
            </ul>
        </div>
        <div class="mega-menu__column" *ngIf="selectedMenuItem">
            <ul class="mega-menu__sub-items" [appMegaMenuAxelClasses]="selectedMenuItem" [idx]="showMainMenuNavigation ? 1 : 0">
                <li *ngFor="let subItem of selectedMenuItem.Subitems"
                [ngClass]="subItem.IsSeparator ? 'is-seperator' : ''">
                    <a
                        [IRouterLink]="subItem.NavigationLink"
                        [closeDialog]="true"
                        class="mega-menu__link"

                        (click)="handleClick(subItem.NavigationLink)">{{subItem.NavigationTitle}}</a>
                </li>
            </ul>
        </div>
        <div class="mega-menu__column" [ngStyle]="{'grid-column': getColumnSpanValue(i, n)}" *ngFor="let spotItem of item.MegaMenuSpots; index as i; count as n;">
            <app-mega-menu-spot [listItem]="listItem" [data]="spotItem"></app-mega-menu-spot>
        </div>
    </div>
    <a [IRouterLink]="item.NavigationLink" (click)="handleClick(item.NavigationLink)"  *ngIf="item.SeeAllLinkText" class="button button_as-text-link">{{item.SeeAllLinkText}}</a>
</div>
