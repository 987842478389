<div
    class="breadcrumb"
    [ngClass]="{ 'breadcrumb_floated': isMobile && productPage ? false : floated, 'breadcrumb_init-hidden': (breadcrumbService.breadcrumbHideBackButton$ | async) }">
    <div class="container">
        <nav role="navigation">
            <button type="button" class="breadcrumb__back-button" *ngIf="productPage && !(breadcrumbService.breadcrumbHideBackButton$ | async)" (click)="goBack()">
                <app-svg [src]="icons.arrowRight90" [size]="'unset'" [inline]="true"></app-svg>
            </button>
            <ul class="breadcrumb__list" *ngIf="isMobile && productPage && !(breadcrumbService.breadcrumbHideBackButton$ | async)">
                <li class="breadcrumb__item">
                    <button type="button" class="button button_clean" (click)="goBack()">{{(transitions.translations$ | async)?.ProductDetails?.MobileBackButtonBreadcrumb?.ButtonText}}</button>
                </li>
            </ul>
            <ul class="breadcrumb__list" *ngIf="isMobile && !productPage || !isMobile">
                <li class="breadcrumb__item" *ngFor="let crumb of pageData?.BreadCrumb; last as isLast;">
                    <a *ngIf="!isLast" [routerLink]="crumb.Url" class="breadcrumb__link">{{crumb.Title}}</a>
                    <span *ngIf="isLast" class="breadcrumb__link">{{crumb.Title}}</span>
                    <span class="breadcrumb__arrow" [innerHTML]="icons.arrowRight | safeHtml"></span>
                </li>
            </ul>
        </nav>
    </div>
</div>
<app-json-ld [json]="breadcrumbLd"></app-json-ld>
