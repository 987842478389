import { animate, group, query, style, transition, trigger } from '@angular/animations';
import {
    DOCUMENT,
    isPlatformBrowser,
    isPlatformServer,
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Injector, isDevMode, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HeaderService } from '@core/header.service';
import { ScrollBackService } from '@core/scrollBack.service';
import { ISiteContextBgColor } from '@core/site-context.interface';
import { SiteContextService } from '@core/site-context.service';
import { TranslationService } from '@core/translation.service';
import { UserService } from '@core/user.service';
import { isIe } from '@shared/utility';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

import { dns, IDnsLinks } from './dns-links';
import { facebookDomainVerificationKeys } from './facebook-domain-verification-keys';
import { BUILDNUMBER, CLOUDFLAREURL } from './tokens';

export const routerTransition = trigger('routerTransition', [
    transition('* <=> *', [
        /* order */
        /* 1 */
        query(
            ':enter',
            style({ position: 'fixed', height: '100vh', width: '100%', opacity: 0 }),
            { optional: true },
        ),
        query(':enter', style({ transform: 'translateY(40px)', opacity: 0 }), {
            optional: true,
        }),
        query(
            ':leave',
            [
                style({ transform: 'translateY(0px)' }),
                animate(
                    '300ms cubic-bezier(0.25,0, 0.5, 1)',
                    style({ transform: 'translateY(15px)', opacity: 0 }),
                ),
            ],
            { optional: true },
        ),
        query(
            ':leave',
            style({ position: 'fixed', height: '100vh', width: '100%' }),
            { optional: true },
        ),
        query(
            ':enter',
            [
                animate(
                    '600ms cubic-bezier(0.2, 0, 0, 1)',
                    style({ transform: 'translateY(0px)', opacity: 1 }),
                ),
            ],
            { optional: true, delay: '0ms' },
        ),
        group([]),
    ]),
]);

/**
 * Our root component in charge of bootstrapping our app
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routerTransition],
    providers: [
        Location,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
    ],
})
export class AppComponent implements OnInit {
    /**
     *
     * @param {HttpClient} http
     * @param {Renderer2} render
     * @param _document
     * @param {TransferState} statetransferService
     * @param {object} platformId
     * @param buildNumber
     * @param cloudFlareUrl
     * @param {SiteContextService} siteContextService
     * @param {Injector} injector
     * @param {TranslationService} translations
     * @param {ScrollBackService} scrollBackService
     * @param userService
     * @param scrollBack
     * @param headerService
     */
    constructor(
        private http: HttpClient,
        private render: Renderer2,
        @Inject(DOCUMENT) private _document,
        private statetransferService: TransferState,
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(BUILDNUMBER) private buildNumber: string,
        @Inject(CLOUDFLAREURL) private cloudFlareUrl: string,
        private injector: Injector,
        private siteContextService: SiteContextService,
        private translations: TranslationService,
        private scrollBackService: ScrollBackService,
        private userService: UserService,
        private scrollBack: ScrollBackService,
        private headerService: HeaderService,
    ) {
        if (isPlatformServer(this.platformId)) {
            this.localHost = this.injector.get('ForwardedOrigin');
        }
    }

    /**
     * Subscribe holder
     */
    appData$: any;

    /**
     * Business Dimension ID
     * Can be: `kaufmann` | `quint` | `qnts` | `axel`
     */
    businessDimension: string;

    host: string;
    forwardOrigin: string;
    origin: string;
    localHost: string;
    language: string;
    script: any;

    appReady = false;
    showApp = false;

    /**
     * Helper to detech if we are in dev or prod mode
     * @type {boolean}
     */
    prodMode: boolean = !isDevMode();

    // Cookie Information script
    static mapLanguageToCookieLang(lang: string): string {
        let language;
        switch (lang) {
            case 'da':
                language = 'DA';
                break;
            case 'de':
                language = 'DE';
                break;
            case 'se':
                language = 'SV';
                break;
            case 'en':
                language = 'EN';
                break;
            default:
                language = 'DA';
        }
        return language;
    }

    /**
     * Fetches our site settings and injects the correct CSS bundle
     * and font if needed.
     *
     * Injects as a javascript bundle if in development mode as it inlines the css for hotmodule reloading.
     */
    ngOnInit() {
        this.cookieInformation();
        this.tracedockInit();
        this.initGtm();
        this.initRaptor();
        this.initProfitMetrics();
        this.initDnsLinks();
        this.initFacebookDomainVerification();

        if (isPlatformBrowser(this.platformId)) {
            this.setUserDataForPersonalization();
            this.removeSSRLinkTags();

            if (isIe()) {
                console.log(
                    'this._document.body.addClass',
                    this._document.body.classList.add('ie'),
                );
            }

            // Add development breakpoints indicator
            if (environment.local) {
                this._document.body.classList.add('local-dev');
            }
        }

        const context = this.siteContextService.getContext();

        // if the context does not have all properties the context is derived from domain and further setup is required.
        if (!context?.checkoutSettings) {
            this.translations.loadTranslations();
            this.appData$ = this.http
                .get(
                    this.siteContextService.getRootUrl() +
                    '/webapi/contextsettings',
                )
                .pipe(
                    map((res: any) => {
                        let contextObj: any = {};

                        Object.keys(res).map(key => {
                            const formattedKey =
                                key.charAt(0).toLowerCase() + key.slice(1);
                            contextObj[formattedKey] = res[key];
                        });

                        const businessDimensionLowercase = contextObj.businessDimension.toLowerCase();

                        this.addFontBundle();

                        this.addStyleBundle(businessDimensionLowercase);

                        contextObj.bgColor = this.getBgColor(
                            businessDimensionLowercase,
                        );

                        contextObj.language = res.LanguageCode;
                        delete contextObj.languageCode;
                        this.siteContextService.setContext(contextObj);

                        this.businessDimension = contextObj.businessDimension;

                        this.addFavicon();

                        this.initZendesk(contextObj.businessDimension);
                        this._document.documentElement.lang = contextObj.language === 'se' ? 'sv' : contextObj.language;
                        // Set the transparent state of the header and the solid state if the header is transparent
                        this.headerService.transparentHeader = contextObj.generalSiteSettings.TransparentHeader;
                        if (contextObj.generalSiteSettings.TransparentHeader) {
                            this.headerService.headerSolidState = !contextObj.generalSiteSettings.TransparentHeader;
                        }


                        return res;
                    }),
                )
                .subscribe(
                    () => {
                        this.appReady = true;
                    },
                    err => {
                        console.log('An error happened');
                        console.log(err);
                    },
                );
        } else {
            this.businessDimension = context.businessDimension.toLowerCase();

            this.addFontBundle();

            this.addStyleBundle(this.businessDimension);

            context.bgColor = this.getBgColor(
                this.businessDimension.toLowerCase(),
            );

            this.addFavicon();

            this.initZendesk(this.businessDimension);

            this.translations.loadTranslations();

            this._document.documentElement.lang = context.language === 'se' ? 'sv' : context.language;

            // Set the transparent state of the header and the solid state if the header is transparent
            this.headerService.transparentHeader = context.generalSiteSettings.TransparentHeader;
            if (context.generalSiteSettings.TransparentHeader) {
                this.headerService.headerSolidState = !context.generalSiteSettings.TransparentHeader;
            }

            this.appReady = true;
        }

        this.initPinterest();
    }

    private getBgColor(businessDimension: string): ISiteContextBgColor {
        switch (businessDimension) {
            case 'kaufmann':
                return {
                    primary: 'f1f2f0',
                    secondary: 'ffffff',
                    productListItem: 'f4f5f2',
                    productImageBgColor: 'eaebe8',
                };
            case 'axel':
                return {
                    primary: 'ffffff',
                    secondary: 'f1f2f0',
                    productListItem: 'ffffff',
                    productImageBgColor: 'E7E7E7',
                };
            case 'quint':
                return {
                    primary: 'ffffff',
                    secondary: 'f1f1f1',
                    productListItem: 'ffffff',
                    productImageBgColor: 'eaebe8',
                };
            default:
                return { primary: '', secondary: '', productListItem: '', productImageBgColor: '' };
        }
    }

    initGtm() {
        if (isPlatformBrowser(this.platformId)) {
            const script = this.render.createElement('script');
            script.async = true;

            script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TB7J7TQ');`;

            this.render.appendChild(this._document.head, script);
        }
    }

    initRaptor() {
        if (isPlatformBrowser(this.platformId)) {
            const script = this.render.createElement('script');
            script.async = true;
            script.defer = true;

            script.text = `(function (d, t) {
                var g = d.createElement(t),
                    s = d.getElementsByTagName(t)[0];
                g.src = '//az19942.vo.msecnd.net/script/raptor-2.1.3.js';
                s.parentNode.insertBefore(g, s);
            }(document, 'script'));`;

            this.render.appendChild(this._document.head, script);
        }
    }

    initProfitMetrics() {
        if (isPlatformBrowser(this.platformId)) {
            const domain = this.stripSubdomains(location.hostname);
            let publicId = '';

            switch (domain) {
                case 'axel':
                case 'axel.dk':
                    publicId = '9E267F47D839B598';
                    break;
                case 'axel-store':
                case 'axel-store.com': // SE
                    publicId = '218BFA7390D4244F';
                    break;
                case 'kaufmann':
                case 'kaufmann.dk':
                    publicId = '9072ACF89C537E9A';
                    break;
                case 'kaufmann-store':
                case 'kaufmann-store.com': // SE
                    publicId = '1B4D4077630C63F5';
                    break;
                case 'quint':
                case 'quint.dk':
                    publicId = 'DAC429DB40172225';
                    break;
            }

            const scriptConfig = this.render.createElement('script');
            scriptConfig.text = `
                window.profitMetrics = {
                pid: "${publicId}", // The ProfitMetrics website ID
                emailInputSelector: '.input_text[name="email"]', // finds email input fields
                debugMode: ${environment.dev}
            }`;

            this.render.appendChild(this._document.head, scriptConfig);

            const script = this.render.createElement('script');
            script.async = true;
            script.defer = true;

            // New script
            script.src = `https://cdn1.profitmetrics.io/${publicId}/bundle.js`;

            // Old script
            // script.src = `https://my.profitmetrics.io/temp/hybrid-scripts/without-hashing/modern/generic.min.js`;


            this.render.appendChild(this._document.head, script);
        }
    }

    initZendesk(businessDimension) {
        if (
            isPlatformBrowser(this.platformId) &&
            businessDimension.toLowerCase() !== 'axel'
        ) {
            const context = this.siteContextService.getContext();

            setTimeout(() => {
                if ((window as any).zE) {
                    (window as any).zE(function() {
                        (window as any).zE.setLocale(context.language);
                    });
                }
            }, 2000);
        }
    }

    /**
     * Adds our style CSS bundle
     * @param bundleName
     */
    addStyleBundle(bundleName) {
        const cloudFlare =
            this.cloudFlareUrl === '--CLOUDFLAREURL--'
                ? ''
                : this.cloudFlareUrl;
        const stateKey = makeStateKey<string>('addStyleBundle');

        if (!this.statetransferService.hasKey(stateKey)) {
            const link = this.render.createElement('link');

            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.href = cloudFlare + bundleName + '.css?v=' + this.buildNumber;

            this.render.appendChild(this._document.head, link);
            this.statetransferService.set(stateKey, 'isSet');
        }
    }

    /**
     * Adds our fonts if needed
     * @param bundleName
     */
    addFontBundle(): void {
        const stateKey = makeStateKey<string>('addFontBundle');

        if (!this.statetransferService.hasKey(stateKey)) {
            const fontUrl = {
                kaufmann: null, // Kaufmann font is self hosted
                axel:
                    'https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i,900,900i',
                quint: null, // Quint font is a Helvetica self-hosted from fonts.com
            };

            if (fontUrl[this.businessDimension]) {
                const link = this.render.createElement('link');

                link.rel = 'stylesheet';
                link.href = fontUrl[this.businessDimension];
                this.render.appendChild(this._document.head, link);
                this.statetransferService.set(stateKey, 'isSet');
            }
        }
    }

    addFavicon(): void {
        const favicon: HTMLLinkElement = this.render.createElement('link');

        favicon.rel = 'icon';
        favicon.type = 'image/x-icon';
        favicon.href = `/assets/favicon/${this.businessDimension.toLowerCase()}.ico`;
        this.render.appendChild(this._document.head, favicon);
    }

    getState(outlet) {
        return outlet.activatedRouteData.content
            ? outlet.activatedRouteData.content.pageData.Id +
            outlet.activatedRouteData.content.pageData.CanonicalUrl
            : 'home';
    }

    animationDone(event) {
        this.scrollBackService.triggerAnimationDone();
    }

    animationStart(event) {
        this.showApp = true;
        this.scrollBackService.triggerAnimationStart();
    }

    private initDnsLinks() {
        if (isPlatformServer(this.platformId)) {
            dns.forEach((entry: IDnsLinks) => {
                if (entry.rel === 'preconnect|dns-prefetch') {
                    const linkElm1 = this.render.createElement('link');
                    linkElm1.rel = 'preconnect';
                    linkElm1.href = entry.href;
                    this.render.setAttribute(linkElm1, 'crossorigin', '');

                    const linkElm2 = this.render.createElement('link');
                    linkElm2.rel = 'dns-prefetch';
                    linkElm2.href = entry.href;

                    this.render.appendChild(this._document.head, linkElm1);
                    this.render.appendChild(this._document.head, linkElm2);
                }
            });
        }
    }

    private initFacebookDomainVerification() {
        if (isPlatformServer(this.platformId)) {
            const domainRaw = this.localHost;

            const domain = domainRaw
                .replace('https://', '')
                .replace('www.', '')
                .replace('.dk', '')
                .replace('.com', '')
                .replace('.localhost', '')
                .replace('.local', '')
                .replace('.gennemse2', '')
                .replace('.godkend', '');

            const metaElm = this.render.createElement('meta');
            metaElm.name = 'facebook-domain-verification';
            metaElm.content = facebookDomainVerificationKeys[domain];
            this.render.appendChild(this._document.head, metaElm);
        }
    }

    private cookieInformation() {
        if (isPlatformBrowser(this.platformId)) {
            const lang = AppComponent.mapLanguageToCookieLang(
                this.siteContextService.getContext().language,
            ) ?? 'da';
            const scriptElm = this.render.createElement('script');
            scriptElm.id = 'CookieConsent';
            scriptElm.type = 'text/javascript';
            scriptElm.src = 'https://policy.app.cookieinformation.com/uc.js';
            scriptElm.setAttribute('data-culture', lang);

            this.render.insertBefore(
                this._document.head,
                scriptElm,
                this._document.head.firstChild,
            );
        }
    }

    private setUserDataForPersonalization(): void {
        const userEmail = this.userService.userEmailLocalstorage;
        if (userEmail) {
            this.userService.getUserName(userEmail).subscribe();
        }
    }

    private tracedockInit() {
        if (isPlatformBrowser(this.platformId)) {
            const domain = this.stripSubdomains(location.hostname);
            let tracedockDomain;
            let tracedockUrl;

            switch (domain) {
                case 'quint-shop.com':
                case 'quint-shop':
                    tracedockDomain = 'quint-shop.com';
                    tracedockUrl = 'https://ds.quint-shop.com/hauh.js';
                    break;
                case 'quint.dk':
                case 'quint':
                    tracedockDomain = 'quint.dk';
                    tracedockUrl = 'https://ds.quint.dk/zij.js';
                    break;
                case 'kaufmann-store.com':
                case 'kaufmann-store':
                    tracedockDomain = 'kaufmann-store.com';
                    tracedockUrl = 'https://ds.kaufmann-store.com/naig.js';
                    break;
                case 'kaufmann.dk':
                case 'kaufmann':
                    tracedockDomain = 'kaufmann.dk';
                    tracedockUrl = 'https://ds.kaufmann.dk/lez.js';
                    break;
                case 'axel-store.com':
                case 'axel-store':
                    tracedockDomain = 'axel-store.com';
                    tracedockUrl = 'https://ds.axel-store.com/xuaw.js';
                    break;
                case 'axel.dk':
                case 'axel':
                    tracedockDomain = 'axel.dk';
                    tracedockUrl = 'https://ds.axel.dk/vuoz.js';
                    break;
            }

            const scriptContent = `
                !function(e,t,n,r,o,i,u,c,a,l){a=n.getElementsByTagName("head")[0],(l=n.createElement("script")).async=1,l.src=t,a.appendChild(l),r=n.cookie;try{if(i=(" "+r).match(new RegExp("[; ]_tdbu=([^\\\\s;]*)")))for(u in o=decodeURI(i[1]).split("||"))(c=o[u].split("~~"))[1]&&(r.indexOf(c[0]+"=")>-1||(n.cookie=c[0]+"="+c[1]+";path=/;max-age=604800;domain=."+e,n.cookie="_1=1"))}catch(e){} }("${tracedockDomain}","${tracedockUrl}",document)
            `;

            const scriptElm = this.render.createElement('script');
            scriptElm.text = scriptContent;
            this.render.insertBefore(
                this._document.head,
                scriptElm,
                this._document.head.firstChild,
            );
        }
    }

    private initPinterest() {
        if (isPlatformServer(this.platformId)) {
            const language = this.siteContextService.getContext().language;
            const domain = this.stripSubdomains(this.localHost);

            const metaElm = this.render.createElement('meta');
            metaElm.name = 'p:domain_verify';

            if (domain.includes('quint')) {
                metaElm.content = '65398b2cbe419f537587d0af8f57e392';
            }

            if (domain.includes('kaufmann')) {
                if (!!language && language === 'se') {
                    metaElm.content = '593ccef3c1c6bd1073e3205b2989a044';
                } else {
                    metaElm.content = '593ccef3c1c6bd1073e3205b2989a044';
                }
            }

            if (domain.includes('axel')) {
                metaElm.content = '2fbb383c33c68ffd94d7f40e23f3b3e7';
            }

            this.render.appendChild(
                this._document.head,
                metaElm,
            );

        }
    }

    /**
     * Strip subdomains
     */
    private stripSubdomains(domain: string): string {
        if (!domain) {
            return;
        }

        return domain
            .replace('https://', '')
            .replace('www.', '')
            .replace('.dk', '')
            .replace('.com', '')
            .replace('.localhost', '')
            .replace('.local', '')
            .replace('.gennemse2', '')
            .replace('.godkend', '');
    }

    private removeSSRLinkTags() {
        const headerLinkTags = this._document.head.querySelectorAll('link[rel="alternate"], link[rel="canonical"]');
        headerLinkTags.forEach((item) => this.render.removeChild(this._document.head, item));
    }
}
