import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { arrowRight90 } from '@shared/svg';

import { MobileFilterStateService } from '@core/mobile-filter-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-mobile-facet-left-pane',
    template: `
        <div class="mobile-facet-overlay">
            <ng-content></ng-content>
        </div>
    `,
})
export class MobileFacetLeftPaneComponent implements AfterViewInit, OnDestroy {

    @ViewChild('facetOverlay') overlayElm: ElementRef<HTMLDivElement>;

    constructor(
        private mobileFilterStateService: MobileFilterStateService,
    ) {
    }

    destroy: Subject<boolean> = new Subject();

    ngAfterViewInit(): void {
        this.mobileFilterStateService.backButtonState$.pipe(takeUntil(this.destroy)).subscribe(state => {
            const elm = document.querySelector(`#${state.key} .mobile-facet-overlay`);
            if (state.key != null && !!elm) {
                if (state.show) {
                    elm.classList.add('mobile-facet-overlay__open');
                } else {
                    elm.classList.remove('mobile-facet-overlay__open');
                }
            }
        });
    }

    ngOnDestroy(): void {
        console.log('destroy', this.mobileFilterStateService.backButtonState$.getValue());
        this.mobileFilterStateService.backButtonState$.next({...this.mobileFilterStateService.backButtonState$.getValue(), ...{show: false}});
        this.destroy.next();
        this.destroy.complete();
    }
}
