<div class="customer">
    <div class="columns is-multiline">
        <div class="column is-5-tablet is-5-desktop is-4-widescreen">
            <div class="costumer__profile">
                <app-customer-profile></app-customer-profile>
            </div>

            <div class="customer__change-password">
                <app-customer-change-password></app-customer-change-password>
            </div>
        </div>
        <div class="column is-offset-1-tablet is-6-tablet is-offset-1-desktop is-6-desktop is-offset-1-widescreen is-7-widescreen">
            <app-customer-receipts></app-customer-receipts>
        </div>
    </div>
</div>