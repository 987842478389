<div class="container container_to-edges {{ templateName }} {{ mobileLayout }}"
     [ngClass]="{'sublayout_mirror': data.Sublayout50LargeSmallMirrorLayout}"
>
    <div class="sublayout"
        [ngClass]="{'sublayout_background': data.BackgroundColor}"
        [style.backgroundColor]="data.BackgroundColor"
    >
        <div class="column-large">
            <div *ngFor="let spot of data.Column1Spots"
                 generic-spot
                 [spotType]="spot.TemplateName"
                 [spotData]="spot"
                 [templateType]="data.TemplateName"
                 [backgroundColor]="data.BackgroundColor"
            ></div>
        </div>
        <div class="column-small">
            <div *ngFor="let spot of data.Column2Spots"
                 generic-spot
                 [spotType]="spot.TemplateName"
                 [spotData]="spot"
                 [templateType]="data.TemplateName"
                 [backgroundColor]="data.BackgroundColor"
            ></div>
        </div>
    </div>
    <div class="brand-anim" [showOnSite]="['axel']">
        <app-axel-brand-tile-anim></app-axel-brand-tile-anim>
    </div>
</div>
