import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AxelBrandTileAnimComponent } from './axel-brand-tile-anim.component';
import {SharedModule} from '@shared/shared.module';



@NgModule({
  declarations: [AxelBrandTileAnimComponent],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [AxelBrandTileAnimComponent]
})
export class AxelBrandTileAnimModule { }
