import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { FormattedProductData } from '@features/product-filter/models/product.model';

@Component({
    selector: 'app-product-price',
    template: `
        <div class="product-price__price-wrapper" *ngIf="!isGiftcard" [ngSwitch]="product?.activeProductVariant?.SalePrice > 1">

            <!-- Normal price -->
            <div class="product-price__price" *ngSwitchCase="false">
                {{product?.activeProductVariant?.Price | kfmCurrency : product?.productData?.Currency}}
            </div>

            <!-- Discount price -->
            <ng-container *ngSwitchCase="true">
                <div class="price-flex-wrapper product-price__discounted-price">
                    <div class="price-flex-container">
                        <div class="product-price__price_normal">
                             {{product?.activeProductVariant?.Price | kfmCurrency : product?.productData?.Currency}}
                        </div>
                        <div class="product-price__price_discount">
                             {{product?.activeProductVariant?.SalePrice | kfmCurrency : product?.productData?.Currency}}
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="product-price__discount-description" *ngIf="product?.productData?.DiscountDescription">
                {{product?.productData?.DiscountDescription}}
            </div>

        </div>
        <div class="product-price__price-wrapper" *ngIf="isGiftcard"></div>
    `,
})
export class ProductPriceComponent implements OnInit {
    @Input() product: FormattedProductData;

    public translations: any;
    public isGiftcard: boolean;

    constructor(private translationService: TranslationService) {
        // Get translations
        this.translationService.translations$.subscribe(translations => {
            this.translations = translations;
        });
    }

    ngOnInit(): void {
        this.isGiftcard = this.product.productData.BrandId === 'GK';
    }
}
