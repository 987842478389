import { TranslationService } from '@core/translation.service';
import { SiteContextService } from '@core/site-context.service';
import { AlertResponse, AlertType } from '@shared/alert/alert.interface';
import { Component, OnDestroy, OnInit, Injectable } from '@angular/core';
import { StoreService } from '@core/store.service';
import {
    ImpactCoreStoresStoreServiceModel as storeDto,
    ImpactCoreUsersModelsCreateUserRequest as createUserRequest,
    ImpactWebsiteCodeWebApiControllersGetInvoiceCountriesResponse as invoiceCountriesResponse,
} from '@shared/swagger/swagger.interface';
import { Subject } from 'rxjs';
import {
    DateAdapter,
    MAT_DATE_LOCALE,
    NativeDateAdapter,
} from '@angular/material/core';
import { ZipcodeService } from '@core/zipcode.service';
import { take, takeUntil } from 'rxjs/operators';
import { UserService } from '@core/user.service';
import { checkmark, expand, home, mail, store, user } from '@shared/svg';
import { BasketService } from '@core/basket.service';
import { RegexService } from '@core/regex.service';
import {
    personalizationBarType,
    PersonalizationBarService,
} from '@core/personalization-bar.service';

// Set date format for picker
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }
}

// tslint:disable-next-line:max-classes-per-file
@Component({
    selector: 'app-customer-profile',
    templateUrl: './customer-profile.component.html',
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_LOCALE, useValue: 'en' },
    ],
})
export class CustomerProfileComponent implements OnInit, OnDestroy {
    constructor(
        private storeService: StoreService,
        private userService: UserService,
        private zipcodeService: ZipcodeService,
        private siteContextService: SiteContextService,
        private dateAdapter: DateAdapter<NativeDateAdapter>,
        private translationService: TranslationService,
        private basketService: BasketService,
        private personalizationService: PersonalizationBarService,
        public regexService: RegexService
    ) {}

    private destroy = new Subject();

    translations: any;
    response: AlertResponse = {};
    stores: storeDto[] = [];
    user: createUserRequest = {};
    icons = {
        expand,
        home,
        user,
        store,
        mail,
        checkmark,
    };
    minDate: Date = new Date(
        new Date().setFullYear(new Date().getFullYear() - 100)
    );
    maxDate: Date = new Date();
    countries: invoiceCountriesResponse = {};

    ngOnInit() {
        // Set date locale based on service response
        const dateLanguage = this.siteContextService.getContext();
        this.dateAdapter.setLocale(dateLanguage.language);

        this.storeService
            .getAllStores()
            .pipe(takeUntil(this.destroy))
            .subscribe(data => (this.stores = data));

        this.userService.currentUser$.pipe(takeUntil(this.destroy)).subscribe(data => {
            this.user = data;

            // Get personalization bars
            this.personalizationService.getPersonalizationBars(
                personalizationBarType.profile
            );
        });

        this.translationService.translations$
            .pipe(takeUntil(this.destroy))
            .subscribe(data => (this.translations = data));

        this.basketService
            .retrieveInvoiceCountries()
            .pipe(takeUntil(this.destroy))
            .subscribe(countries => (this.countries = countries));
    }

    onChange(obj?: { [key: string]: string }) {
        if (obj) {
            const userKey = Object.keys(obj)[0];
            this.user[userKey] = obj[userKey];
        }

        this.userService.setUser(this.user).subscribe(
            data =>
                (this.response = {
                    type: AlertType.success,
                    text: this.translations.Profile.Content
                        .YourProfileWasUpdated,
                }),
            err => {
                this.response = {
                    type: AlertType.error,
                    text: err.error.ErrorMessage,
                };
            }
        );
    }

    closeAlert = () => {
        this.response = {};
    }

    setCity(zipcode: string) {
        if (
            zipcode &&
            zipcode.length === 4 &&
            this.siteContextService.context.language === 'da'
        ) {
            this.zipcodeService
                .getCity(zipcode)
                .subscribe(data => (this.user.City = data.navn));
        }
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }
}
