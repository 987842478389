<a class="favorite-brand-spot-brand__details" [routerLink]="slide.BrandPageUrl" (click)="onClick($event)" (dragstart)="disableDefault($event)">
    <div class="favorite-brand-spot-brand__picture">
        <div class="favorite-brand-spot-brand__picture-wrap">
            <img
                #image
                loading="lazy"
                class="favorite-brand-spot-brand__image"
                [src]="slide.BrandImage"
                [srcset]="slide.BrandImage + ' 1x, ' + slide.BrandImage + ' 2x'"
                [alt]="slide.BrandName"
                draggable="false"
                width="840"
                height="960"
            />

            <h4 class="favorite-brand-spot-brand__title">{{slide.BrandName}}</h4>
            <div class="favorite-brand-spot-brand__right-arrow" role="button">
              <span [innerHtml]="arrowRight | safeHtml"></span>
            </div>

        </div>

    </div>

</a>
