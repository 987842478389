import { Injectable } from '@angular/core';
import { InfoPageComponent } from '@features/info/info-page.component';
import { PageService } from '@core/page.service';
import { ImpactOverlayRef, OverlayService } from '@impactdk/ngx-overlay';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { SiteContextService } from '@core/site-context.service';

@Injectable()
export class InfoService {
    overlayRef: ImpactOverlayRef;
    constructor(
        private overlay: OverlayService,
        private pageService: PageService,
        private http: HttpClient,
        private router: Router,
        private siteContextService: SiteContextService
    ) {}

    openInfo(url, cls?: string) {
        const urlTree = this.router.createUrlTree([url], {
            queryParams: {
                getasjson: 1,
            },
        });

        const requestUrl =
            this.siteContextService.getRootUrl() +
            environment.proxyPageApiRoot +
            urlTree.toString();

        this.http.get(requestUrl).subscribe((res: any) => {
            this.pageService.page = res.content;

            this.overlayRef = this.overlay.open(InfoPageComponent, {
                fullHeight: true,
                hasBackdrop: true,
                blockScroll: false,
                panelClass: cls ? cls : null,
                positionVertical: {
                    placement: 'center',
                },
                positionHorizontal: {
                    placement: 'center',
                },
                data: {
                    content: res,
                },
            });
        });
    }
}
