import { NgModule } from '@angular/core';
import { ArticleService } from '@features/article/article.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [ArticleService],
})
export class ArticleModule {}
