<div class="authentication">
    <div class="authentication__form">
        <div class="authentication__content" intercept-links *ngIf="topContent" [innerHTML]="topContent | safeHtml"></div>
        <form class="form" novalidate name="form" [ngClass]="{'form_submitted': form.submitted}"
              (ngSubmit)="onLogin(form)" #form="ngForm">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="email" class="input input_text" name="email" appResetInput required
                               [pattern]="regexService.email"
                               [(ngModel)]="user.Email" #email="ngModel" appLowerCaseInput>
                        <span
                            class="input-container__icon input-container__icon-mail validation-icon validation-icon_normal">
                            <app-svg src="envelope" size="small"></app-svg>
                        </span>
                        <span
                            class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid"
                            [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.EmailInput}}</span>
                    </div>
                </label>
                <div class="form__validation" *ngIf="(email.invalid && email.touched) || form.submitted">
                    <span class="validation"
                          *ngIf="email.errors?.required">{{(translations | async)?.GeneralInput?.Content?.EmailInputMissing}}</span>
                    <span class="validation"
                          *ngIf="email.errors?.pattern">{{(translations | async)?.GeneralInput?.Content?.EmailInputInvalid}}</span>
                </div>
            </div>

            <div class="form__field form__field_last">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="password" class="input input_text" name="password" appResetInput
                               required minlength="6" [(ngModel)]="user.Password"
                               #password="ngModel">
                        <span
                            class="input-container__icon input-container__icon-password validation-icon validation-icon_normal">
                            <app-svg src="lock-open" size="small"></app-svg>
                        </span>
                        <span
                            class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid"
                            [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span
                            class="label__text">{{(translations | async)?.GeneralInput?.Content?.PasswordInput}}</span>
                    </div>
                </label>
                <div class="form__validation" *ngIf="(password.invalid && password.touched) || form.submitted">
                    <span class="validation"
                          *ngIf="password.errors?.required">{{(translations | async)?.GeneralInput?.Content?.PasswordInputMissing}}</span>
                    <span class="validation"
                          *ngIf="password.errors?.minlength">{{(translations | async)?.GeneralInput?.Content?.PasswordInputLength}}</span>
                </div>
                <div class="form__description">
                    <a href="javascript:void(0)" (click)="openResetPassword()"
                       class="authentication__forgot-password">{{(translations | async)?.Authentication?.Login?.ForgotPassword}}</a>
                </div>
            </div>

            <div class="form__action authentication__action">
                <div class="checkbox checkbox_no-overflow authentication__remember-me">
                    <input type="checkbox" id="rememberMe" name="rememberMe" [(ngModel)]="user.RememberMe"
                           #rememberMe="ngModel">
                    <label class="checkbox__label" for="rememberMe">
                        <span [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span
                            class="checkbox__name">{{(translations | async)?.Authentication?.Login?.RememberMe}}</span>
                    </label>
                </div>

                <app-alert class="form__response" *ngIf="response.text" [closeCallback]="closeAlert"
                           [type]="response.type" [text]="response.text"></app-alert>

                <button type="submit"
                        class="button button_primary button_full button_icon authentication__button">
                    <app-svg src="arrow-right" [inline]="true"></app-svg>
                    {{(translations | async)?.Authentication?.Login?.LoginBotton}}
                    <spinner name="loginSpinner" [size]="'20'" background="true" inverted="true"></spinner>
                </button>

                <a [IRouterLink]="createUserUrl" [closeDialog]="true"
                   class="button button_outline button_full button_icon">
                    <app-svg src="arrow-right-black" [inline]="true"></app-svg>
                    {{(translations | async)?.Authentication?.Login?.LoginCreateUserBotton}}</a>
            </div>
        </form>
        <div class="authentication__content" intercept-links *ngIf="bottomContent" [innerHTML]="bottomContent | safeHtml"></div>
    </div>
</div>
