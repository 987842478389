import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerComponent } from './customer.component';
import { CustomerProfileComponent } from './customer-profile/customer-profile.component';
import { CustomerChangePasswordComponent } from './customer-change-password/customer-change-password.component';
import { SharedModule } from '@shared/shared.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomerReceiptsComponent } from './customer-receipts/customer-receipts.component';
import { CustomerService } from './customer.service';
import { ReceiptModule } from '@features/receipt/receipt.module';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReceiptModule,
        MatInputModule,
    ],
    declarations: [
        CustomerComponent,
        CustomerProfileComponent,
        CustomerChangePasswordComponent,
        CustomerReceiptsComponent,
    ],
    exports: [CustomerComponent],
    providers: [CustomerService],
})
export class CustomerModule {}
