import { isPlatformServer } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    Optional,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { PageService } from '@core/page.service';
import { ProductUtilityService } from '@core/productUtility.service';
import { ScrollBackService } from '@core/scrollBack.service';
import { SiteContextService } from '@core/site-context.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { TranslationService } from '@core/translation.service';
import { FilterConfigs } from '@features/filter/configs/filter.config';
import { Facet, PaginationFacet } from '@features/filter/models/facet.model';
import { IFilter, IFilterConfig } from '@features/filter/models/filter.model';
import {
    FilterService,
    IProductGridState,
} from '@features/filter/services/filter.service';
import { ExposedProductRow } from '@features/product-list/exposed-product-row';
import { PaginationFactPipe } from '@features/product-list/pipes/pagination-fact.pipe';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { FilterOutputViewModelListViewProductModelSearchProductFacetOutputViewModel as IFilterProduct } from '@shared/swagger/solrsearcher.interface';
import { Subject } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import {ProfitMetricsService} from '@core/tracking/profit-metrics.service';
import {ISplashVisibility} from '@shared/product-splash/product-splash.component';
import { SeoService } from '@core/seo';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
})
export class ProductListComponent implements OnInit, OnDestroy {
    filterResults: IFilter<IFilterProduct>;
    products: any[] = [];
    selectedGrid: IProductGridState;
    totalResults: number;
    productsOnPage: number;
    progressBarWidth: string;
    showPagination: boolean;
    translation: any;
    listName: string;
    paginationPages;
    pageData: any;
    initialLoad: boolean;
    pageIndex: number;

    exposedProductsResult: ExposedProductRow[];
    destroy = new Subject();
    splashVisibility: ISplashVisibility;

    private paginationObj: PaginationFacet;
    private filterConfig: any;


    constructor(
        private filterService: FilterService<IFilterProduct>,
        private paginationPipe: PaginationFactPipe,
        private spinnerService: SpinnerService,
        private translationService: TranslationService,
        private scrollBackService: ScrollBackService,
        private tracking: TrackingService,
        private pageService: PageService,
        private productUtilService: ProductUtilityService,
        @Inject(PLATFORM_ID) private platformId,
        @Optional()
        @Inject('isBot')
        private isBot,
        private profitMetricsService: ProfitMetricsService,
        private seoService: SeoService,
    ) {}

    ngOnInit() {
        this.pageService.page.pipe(takeUntil(this.destroy)).subscribe(page => {
            this.pageData = page.pageData;
            this.splashVisibility = {
                hideNewArrivals: page.pageData.HideNewArrivalsSplash,
                hideSales: page.pageData.HideSalesSplash,
                hideOutlet: page.pageData.HideOutletSplash,
                hideExtra: page.pageData.HideExtraSplash
            };
        });

        this.translationService.translations$
            .pipe(takeUntil(this.destroy))
            .subscribe(x => (this.translation = x));

        this.filterConfig = this.filterService.getFilterConfig(FilterConfigs);

        this.filterService
            .getFilter()
            .pipe(takeUntil(this.destroy))
            .subscribe(data => {
                if (data.facets.length > 0) {
                    this.filterResults = data;
                    this.initialLoad = data.initialLoad;

                    const roof = Math.ceil(
                        data.totalEntityCount / this.filterConfig.pageSize
                    );

                    const pages = [];

                    // Pagination for search engines and bots
                    for (let i = 0; i < roof; i++) {
                        const page = {
                            i,
                            pageUrl:
                                '/' +
                                this.pageData.CanonicalUrl.replace(
                                    /^(?:\/\/|[^\/]+)*\//,
                                    ''
                                ) + (i > 0 ? '?pageIndex=' + i : '')
                        };

                        pages.push(page);
                    }

                    if (isPlatformServer(this.platformId) && this.isBot) {
                        this.paginationPages = pages;
                    }

                    if (
                        this.clearProductList(data.facets) ||
                        data.initialLoad
                    ) {
                        this.exposedProductsResult = null;
                        if (data.initialLoad) {
                            this.exposedProductsResult = this.productUtilService.mapExposedProduct(
                                data
                            );
                        }
                        this.products = [].concat(
                            [],
                            this.filterResults.entities
                        );
                    } else {
                        this.products = [].concat(
                            this.products,
                            this.filterResults.entities
                        );
                    }

                    const searchFacet = data.facets.find(facet => {
                        return facet.kind === 'search';
                    });

                    const listName =
                        searchFacet && (searchFacet as any).value
                            ? (searchFacet as any).value
                            : 'Filter';

                    this.listName = listName;

                    this.tracking.sendProductsImpressions(
                        this.products,
                        listName,
                        true
                    );

                    this.spinnerService.hide('paginationSpinner');

                    this.paginationObj = this.paginationPipe.transform(
                        this.filterResults.facets
                    );

                    // Set appropriate canonicals for filter pagination
                    this.seoService.setCanonicalsForPagination(this.pageData, this.paginationObj.pageIndex, pages.length - 1);

                    this.showPagination = this.paginationObj.hasNextPage;
                    this.productsOnPage = this.calcProductsOnPage();
                    this.progressBarWidth = this.calcProgressBarWidth();
                }
            });

        this.filterService
            .getProductGridSize()
            .pipe(takeUntil(this.destroy))
            .subscribe(data => {
                this.selectedGrid = data;
            });

        if (this.scrollBackService.shouldScrollBack) {
            this.scrollBackService.animationDone.pipe(first()).subscribe(() => {
                setTimeout(() => {
                    this.scrollBackService.scrollBack();
                    this.scrollBackService.setScrollBack(false);
                }, 0);
            });
        }
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    onNextPage() {
        this.filterService.updateFilter(this.filterResults.facets);
    }

    private calcProgressBarWidth(): string {
        const progressPercentage =
            (this.productsOnPage / this.filterResults.totalEntityCount) * 100;
        return progressPercentage + '%';
    }

    private calcProductsOnPage(): number {
        if (this.paginationObj.pageSize > this.filterConfig.pageSize) {
            return this.paginationObj.pageSize;
        }
        /*if (this.totalResults <= this.paginationObj.pageSize) {
            return this.totalResults;
        }*/
        return this.paginationObj.pageSize * (this.paginationObj.pageIndex + 1);
    }

    private clearProductList(facets: Facet[]): boolean {
        const pagination = this.paginationPipe.transform(facets);
        return pagination.pageIndex === 0;
    }
}
