import {
    Component,
    Inject,
    Input,
    OnInit,
    Optional,
    PLATFORM_ID,
} from '@angular/core';
import { UserService } from '@core/user.service';
import { PersonalizeContentService } from '@core/personalize-content.service';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-text-spot',
    templateUrl: './text-spot.component.html',
})
export class TextSpotComponent implements OnInit {
    static ref = 'Text spot';

    @Input()
    public data;

    hideFromSearchEngines = false;
    isSpotDisplayingPersonalizedContent = false;
    displayPersonalizedContent = false;
    destroy = new Subject();

    constructor(
        @Optional()
        @Inject('isBot')
        private isBot: boolean,
        @Inject(PLATFORM_ID) private platformId,
        private userService: UserService,
        private personalizeContent: PersonalizeContentService
    ) {}

    ngOnInit(): void {
        const userSegmentFromLocalstorage = this.userService
            .userSegmentLocalstorage;

        if (this.isBot) {
            this.hideFromSearchEngines = this.data.HideFromSearchEngines;
        }

        if (
            !this.isBot &&
            isPlatformBrowser(this.platformId) &&
            this.data.IsPersonalized
        ) {
            this.userService.currentUser.subscribe(user => {
                if (!!user.Segment) {
                    this.fetchPersonalizedSpotContent(
                        this.userService.userSegmentLocalstorage
                            ? this.userService.userSegmentLocalstorage
                            : user.Segment
                    );
                }
            });
        }
    }
    private fetchPersonalizedSpotContent(segment: string) {
        const isUserSegmentInSpotSegments =
            this.data.Segments.filter(
                seg => seg.toLowerCase() === segment.toLowerCase()
            ).length > 0;

        if (isUserSegmentInSpotSegments) {
            this.displayPersonalizedContent = true;

            this.personalizeContent
                .userContent({
                    Id: this.data.Id,
                    TemplateName: this.data.TemplateName,
                    Segment: segment,
                })
                .pipe(takeUntil(this.destroy))
                .subscribe(content => {
                    this.data = { ...content };
                    this.isSpotDisplayingPersonalizedContent = true;
                });
        }
    }
}
