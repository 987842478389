<div class="filter__products">

    <div class="container container_padded mobile-no-padding">
        <div class="filter-products">
            <ul
                class="filter-products__list"
                [ngClass]="{'empty-list' : exposedProductsResult?.length === 0, 'filter-products__list_large': selectedGrid.gridState === 'large', 'filter-products__list_small': selectedGrid.gridState === 'small'}"
            >
                <li class="exposed-products">
                    <div appExposedProductsTemplate
                         [data]="exposedProduct"
                         [splashVisibility]="splashVisibility"
                         *ngFor="let exposedProduct of exposedProductsResult; let i = index;"></div>
                </li>
            </ul>
            <ul
                class="filter-products__list"
                [ngClass]="
                {
                    'filter-products__list_large': selectedGrid.gridState === 'large',
                    'filter-products__list_small': selectedGrid.gridState === 'small'
                }"
            >
                <li
                    class="filter-products__item"
                    *ngFor="let product of products; let i = index;"
                >
                    <app-product-item
                        [product]="product"
                        [index]="i"
                        [listName]="listName"
                        [sizeLabels]="translation?.ProductList?.Block"
                        [initialLoad]="initialLoad"
                        [splashVisibility]="splashVisibility"
                    ></app-product-item>
                </li>
            </ul>
            <app-pagination
                [pagination]="filterResults.facets | paginationFact"
                [totalResults]="filterResults.totalEntityCount"
                [productsOnPage]="productsOnPage"
                [progressBarPercentage]="progressBarWidth"
                (onNextPage)="onNextPage()"
                *ngIf="showPagination"
            >
            </app-pagination>
            <ul *ngIf="paginationPages">
                <li *ngFor="let paginationPage of paginationPages">
                    <a [href]="paginationPage.pageUrl">{{paginationPage.pageNumber}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

