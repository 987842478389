import {AuthenticationService} from '@core/authentication.service';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, EMPTY, Observable, ObservableInput, throwError} from 'rxjs';
import {
    ImpactCoreUsersModelsCreateUserRequest as createUserRequest,
    ImpactWebsiteCodeWebApiControllersB2BUserControllerChangePasswordRequest as changePasswordRequest,
    ImpactWebsiteCodeWebApiControllersUserControllerGetUserNameArgs as GetUserNameArgs,
    ImpactWebsiteCodeWebApiControllersUserControllerSubscribeRequest as subscribeRequest,
    ImpactWebsiteCodeWebApiControllersUserControllerUnsubscribeEmailRequest as unsubscribeEmail,
    ImpactWebsiteCodeWebApiControllersUserControllerUnsubscribeSmsRequest as unsubscribeSms,
    ImpactWebsiteCodeWebApiControllersUserControllerUpdateUserDataRequest as updateUserDataRequest,
} from '@shared/swagger/swagger.interface';
import {catchError, first, map, tap} from 'rxjs/operators';
import {SiteContextService} from '@core/site-context.service';
import {CookieService} from 'ngx-cookie';
import {RaptorTrackingService} from '@core/tracking/raptor-tracking.service';
import {LoggingService} from '@core/logging.service';
import {isPlatformBrowser} from '@angular/common';

const LOCALSTORAGE_USERID_KEY = 'uid';
const LOCALSTORAGE_USER_MAIL_KEY = 'umail';
const LOCALSTORAGE_USER_SEGMENT_KEY = 'usegment';
export const COOKIE_KEY = 'kfm_uid';

@Injectable()
export class UserService {
    constructor(
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService,
        private siteContextService: SiteContextService,
        private cookieService: CookieService,
        private raptorTrackingService: RaptorTrackingService,
        private logging: LoggingService,
        @Inject(PLATFORM_ID) private platformId: object,
    ) {
        this.authenticationService.isAuthorized$.subscribe(isLoggedIn => {
            if (isLoggedIn) {
                this.fetchUser();
            } else {
                this.resetUser();
            }
        });
    }

    private env: string = this.siteContextService.getRootUrl();

    public currentUser: BehaviorSubject<createUserRequest> = new BehaviorSubject<createUserRequest>({});

    currentUser$ = this.currentUser.asObservable();

    static storeUserName(userName: string): void {
        localStorage.setItem(LOCALSTORAGE_USERID_KEY, userName);
    }

    static storeIsNewsletterSubscriber(isNewsletterSubscriber: boolean): void {
        localStorage.setItem(
            'uNewsSub',
            JSON.stringify(isNewsletterSubscriber)
        );
    }

    public get userFromLocalStorage(): string | null {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem(LOCALSTORAGE_USERID_KEY);
        }
        return null;
    }

    public get userEmailLocalstorage(): string | null {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem(LOCALSTORAGE_USER_MAIL_KEY);
        }
        return null;
    }

    public set userEmailLocalstorage(email: string) {
        localStorage.setItem(LOCALSTORAGE_USER_MAIL_KEY, email);
    }

    public get userSegmentLocalstorage(): string | null {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem(LOCALSTORAGE_USER_SEGMENT_KEY);
        }
    }

    public set userSegmentLocalstorage(segment: string) {
        localStorage.setItem(LOCALSTORAGE_USER_SEGMENT_KEY, segment);
    }

    /**
     * Fetch user for the first time via http service
     */
    private fetchUser(): void {
        this.httpClient
            .get<createUserRequest>(`${this.env}/webapi/User/GetUserData`)
            .pipe(
                tap(data =>
                    this.setPersonalizationSegmentCookie(data.UserName)
                ),
                catchError(
                    (err): ObservableInput<any> => {
                        this.logging.exception(
                            `Error happened in /webapi/User/GetUserData. Error was: ${err}`
                        );
                        return throwError(err);
                    }
                )
            )
            .subscribe(data => {
                this.currentUser.next(data);
            });
    }

    /**
     * Put users RFM value in a cookie so BE are able read it
     */
    private setPersonalizationSegmentCookie(userId: string) {
        this.cookieService.put(COOKIE_KEY, userId, {
            storeUnencoded: true,
            expires: new Date(
                new Date().setFullYear(new Date().getFullYear() + 1)
            ),
        });
    }

    /**
     * Set data for authorized user
     */
    setUser(user: updateUserDataRequest): Observable<createUserRequest> {
        return this.httpClient
            .post<createUserRequest>(`${this.env}/webapi/User/UpdateUser`, user)
            .pipe(
                tap(data => {
                    this.raptorTrackingService.trackAddUser(data.Email);
                    this.currentUser.next(data);
                })
            );
    }

    /**
     * Reset user data for logout etc
     */
    resetUser(): void {
        this.currentUser.next({});
    }

    /**
     * Create user
     */
    createUser(user: createUserRequest): Observable<any> {
        return this.httpClient
            .post<createUserRequest>(
                `${this.env}/webapi/User/CreateUser`,
                user,
                {observe: 'response'}
            )
            .pipe(
                tap(data => {
                    localStorage.setItem(
                        'clientToken',
                        data.headers.get('clienttoken')
                    );
                    this.currentUser.next(data.body);
                    // this.authenticationService.isAuthorized.next(true);
                }),
                catchError(
                    (err): ObservableInput<any> => {
                        this.logging.exception(
                            `Error happened in /webapi/User/CreateUser. Error was: ${err}`,
                            user
                        );
                        return throwError(err);
                    }
                )
            );
    }

    subscribeToEmailNewsletter(user: subscribeRequest): Observable<any> {
        return this.httpClient
            .post<any>(
                `${this.env}/webapi/User/subscribeToEmailNewsletter`,
                user
            )
            .pipe(
                tap(data => {
                    this.currentUser.next(data);
                    // this.authenticationService.isAuthorized.next(true);
                })
            );
    }

    /**
     * Change password for authorized user
     */
    changePassword(user: changePasswordRequest): Observable<any> {
        return this.httpClient.post(
            `${this.env}/webapi/User/UpdatePassword`,
            user
        );
    }

    /**
     * Retrieve possible delivery countries
     */
    retrieveInvoiceCountries(): Observable<any> {
        return this.httpClient.post<any>(
            `${this.env}/webapi/Basket/RetrieveInvoiceCountries`,
            null
        );
    }

    /**
     * Unsubscribe email newsletters
     * @param unsubscribePayload
     */
    unsubscribeEmail(unsubscribePayload: unsubscribeEmail): Observable<any> {
        return this.httpClient
            .post(
                `${this.env}/webapi/User/UnsubscribeEmail`,
                unsubscribePayload
            )
            .pipe(
                catchError(
                    (err): ObservableInput<any> => {
                        this.logging.exception(
                            `Error happened in /webapi/User/UnsubscribeEmail. Error was: ${err}`,
                            unsubscribePayload
                        );
                        return throwError(err);
                    }
                )
            );
    }

    /**
     * Unsubscribe Sms messages
     * @param unsubscribePayload
     */
    unsubscribeSms(unsubscribePayload: unsubscribeSms): Observable<any> {
        return this.httpClient
            .post(`${this.env}/webapi/User/UnsubscribeSms`, unsubscribePayload)
            .pipe(
                catchError(
                    (err): ObservableInput<any> => {
                        this.logging.exception(
                            `Error happened in /webapi/User/UnsubscribeSms. Error was: ${err}`,
                            unsubscribePayload
                        );
                        return throwError(err);
                    }
                )
            );
    }

    getUserName(email: string): Observable<any> {
        try {
            try {
                if (email.includes('@')) {
                    throw new Error('Wrong email format!');
                }
            } catch (e) {
                console.error('Email string is not in the right format', e);
                localStorage.removeItem(LOCALSTORAGE_USER_MAIL_KEY);
                return EMPTY;
            }
            const requestPayload: GetUserNameArgs = {};
            requestPayload['email'] = email;

            this.userEmailLocalstorage = email;

            return this.httpClient
                .post(`${this.env}/webapi/User/GetUserName`, requestPayload)
                .pipe(
                    first(),
                    map((user: createUserRequest) => {
                        return !!user.Segment
                            ? user
                            : {...user, ...{Segment: 'empty'}};
                    }),
                    tap((userData: createUserRequest) => {
                        this.setPersonalizationSegmentCookie(
                            (userData as any).UserName
                        );
                        this.currentUser.next(userData);
                        UserService.storeUserName(userData?.UserName);
                        UserService.storeIsNewsletterSubscriber(
                            userData['IsNewsletterSubscriber']
                        );
                    }),
                    catchError(
                        (err): ObservableInput<any> => {
                            this.logging.exception(
                                `Error happened in /webapi/User/GetUserName. Error was: ${err}`,
                                requestPayload
                            );
                            return throwError(err);
                        }
                    )
                );
        } catch (e) {
            console.error('GetUserName failed with error', e);
        }
    }
}
