import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MobileFilterStateService {

    backButtonState$: BehaviorSubject<IMobileFilterState> = new BehaviorSubject<IMobileFilterState>({show: false});

    constructor() {
    }
}
export interface IMobileFilterState {
    key?: string;
    show?: boolean;
}
