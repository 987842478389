import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasketService } from '@core/basket.service';
import {
    NnMarkedsDataViewModel,
    RequestModel,
} from '@shared/swagger/nnmarkedsdata.interface';
import {
    ImpactCoreAddressB2CAddress as invoiceAddress,
    ImpactCoreBasketsBasketServiceModel as orderModel,
    ImpactWebsiteCodeWebApiControllersDeliveryTypeServiceModel as deliveryTypeModel,
    ImpactWebsiteCodeWebApiControllersGetInvoiceCountriesResponse as invoiceCountriesResponse,
} from '@shared/swagger/swagger.interface';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { SiteContextService } from '@core/site-context.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(
        private basketService: BasketService,
        private httpClient: HttpClient,
        private siteContextService: SiteContextService
    ) {
        this.basketService.basket$.subscribe(data => {
            this.order.next(data);
            this.basketId = data.Id;
        });
    }

    private env: string = this.siteContextService.getRootUrl();

    private order: ReplaySubject<orderModel> = new ReplaySubject<orderModel>(1);

    /**
     * Get current order state as obserable
     */
    order$: Observable<orderModel> = this.order.asObservable();

    private basketId: string = '';

    setInvoiceAddress(invoiceAddress: invoiceAddress): Observable<orderModel> {
        return this.httpClient
            .post<orderModel>(`${this.env}/webapi/Basket/SetInvoiceAddress`, {
                BasketId: this.basketId,
                BasketAddress: invoiceAddress,
            })
            .pipe(tap(data => this.order.next(data)));
    }

    retrieveInvoiceCountries(): Observable<invoiceCountriesResponse> {
        return this.httpClient.post<invoiceCountriesResponse>(
            `${this.env}/webapi/Basket/RetrieveInvoiceCountries`,
            null
        );
    }

    retrieveUserFromMarkedsData(
        user: RequestModel
    ): Observable<NnMarkedsDataViewModel> {
        return this.httpClient.post<NnMarkedsDataViewModel>(
            `${environment.nnMarkedsDataRoot}/api/nnmarkedsdata`,
            user
        );
    }

    retrieveDeliveryTypes(): Observable<deliveryTypeModel> {
        return new Observable(observer => {
            this.order$.pipe(take(1)).subscribe(data => {
                this.httpClient
                    .get<deliveryTypeModel>(
                        `${this.env}/webapi/DeliveryType/GetDeliveryTypes`,
                        {
                            params: {
                                basketId: data.Id,
                            },
                        }
                    )
                    .subscribe(data => {
                        observer.next(data);
                        observer.complete();
                    });
            });
        });
    }
}
