import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { SiteContextService } from '@core/site-context.service';

@Pipe({
    name: 'kfmCurrency',
})
export class KfmCurrencyPipe implements PipeTransform {
    private readonly lang: string;

    /**
     * Kaufmanns currency pipe
     * @param {number} amount - ex. 100
     * @param {string} currency - currency symbol ex. DKK
     * @param {boolean} negativeValue - displays the price as a negative number ex. -100
     * @returns {string} returns the formatted price ex. DKK 100,- or EUR 23,-
     */
    transform(
        amount: number | string,
        currency?: string,
        negativeValue?: boolean
    ): string {
        if (typeof amount !== 'number') {
            amount = +amount;
        }
        if (!Number.isInteger(amount)) {
            return `${currency} ${this.decimal.transform(amount, '1.2-2', this.lang)}`;
        }
        if (negativeValue) {
            return `${currency} -${this.decimal.transform(amount, '1.0-2', this.lang)}`;
        }
        return `${currency} ${this.decimal.transform(amount, '1.0-2', this.lang)}`;
    }

    constructor(
        private decimal: DecimalPipe,
        private contextService: SiteContextService
    ) {
        this.lang = this.contextService.context.language;
        this.lang = this.lang !== 'se' ? this.lang : 'sv';
    }
}
