import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SiteContextService } from '@core/site-context.service';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RaptorTrackingService {
    private raptorEventSubject: Subject<
        RaptorEvent | RaptorClickEvent
    > = new Subject();
    private raptorEventQueue: any[] = [];
    private isRaptorLoaded = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private siteContextService: SiteContextService,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            (window as any).raptorLoaded = () => {
                this.initializeRaptor();
            };
        }
    }

    initializeRaptor(): void {
        const raptorCustomerId =
            environment.raptorCustomerId[
                this.siteContextService.getContext().businessDimension
            ];
        raptor.initialize({
            customerID: raptorCustomerId,
            productIdParamIndex: 2,
            eventTypeParamIndex: 1,
        });

        this.isRaptorLoaded = true;

        if (this.raptorEventQueue.length > 0) {
            this.raptorEventQueue.forEach(queueEvent => queueEvent.sendEvent());
            this.raptorEventQueue = [];
        }

        this.raptorEventSubject.subscribe(data => {
            if (typeof data === 'object') {
                data.sendEvent();
            }
        });
    }

    trackClickEvent(
        raptorFeedType: string,
        raptorEventType: RaptorEventType,
        styleId: string,
        colorGroupId: string
    ): void {
        if (raptorFeedType !== '') {
            this.raptorEventSubject.next(
                new RaptorClickEvent(
                    raptorFeedType,
                    raptorEventType,
                    styleId,
                    colorGroupId
                )
            );
        }
    }

    trackContentEvent(
        eventType: RaptorEventType,
        contentId: string,
        contentTag: string[],
        user
    ): void {
        this.raptorEventSubject.next(
            new RaptorEvent(
                this.siteContextService.getContext().businessDimension,
                null,
                null,
                null,
                null,
                null,
                eventType,
                null,
                null,
                null,
                null,
                user,
                contentId,
                contentTag
            )
        );
    }

    trackProductEvent(
        eventType: RaptorEventType,
        styleId: string,
        productName: string,
        categoryPath: string,
        price: string,
        currency: string,
        colorId: string,
        brandId: string,
        colorGroupId: string,
        user
    ): void {
        this.raptorEventSubject.next(
            new RaptorEvent(
                this.siteContextService.getContext().businessDimension,
                brandId,
                categoryPath,
                colorGroupId,
                colorId,
                currency,
                eventType,
                price,
                styleId,
                productName,
                '',
                user,
                null,
                null
            )
        );
    }

    trackBasketEvent(basket: any, user: any): void {
        if (isPlatformBrowser(this.platformId) && basket.hasOwnProperty('Id')) {
            basket.Lines.forEach(line => {
                const basketEvent = new RaptorEvent(
                    this.siteContextService.getContext().businessDimension,
                    line.BrandId,
                    ''.concat(line.CategoryId, '#', line.CategoryName),
                    ''.concat(line.StyleId, '_', line.ColorId),
                    line.ColorId,
                    line.Currency,
                    RaptorEventType.Basket,
                    line.UnitPrice,
                    line.StyleId,
                    line.ProductName,
                    line.SizeId,
                    user,
                    null,
                    null
                );
                if (this.isRaptorLoaded) {
                    this.raptorEventSubject.next(basketEvent);
                } else {
                    this.raptorEventQueue.push(basketEvent);
                }
            });
        }
    }

    trackBuyEvent(basket: any, user: any): void {
        if (isPlatformBrowser(this.platformId)) {
            basket.OrderLines.forEach(line => {
                const buyEvent = new RaptorEvent(
                    this.siteContextService.getContext().businessDimension,
                    line.BrandId,
                    ''.concat(line.CategoryId, '#', line.CategoryName),
                    ''.concat(line.StyleId, '_', line.ColorId),
                    line.ColorId,
                    line.Currency,
                    RaptorEventType.Buy,
                    line.UnitPrice,
                    line.StyleId,
                    line.ProductName,
                    line.SizeId,
                    user,
                    null,
                    null
                );
                if (this.isRaptorLoaded) {
                    this.raptorEventSubject.next(buyEvent);
                } else {
                    this.raptorEventQueue.push(buyEvent);
                }
            });
        }
    }

    trackAddUser(email: string): void {
        if (this.isRaptorLoaded) {
            if (email === undefined) {
                return;
            }
            raptor.addUser(email);
        }
    }
x
}

declare interface IRaptorEvent {
    eventType: RaptorEventType;
    productId: string;
    productName: string;
    categoryPath: string;
    price: string;
    currency: string;
    domain?: string;
    userId?: string;
    isSubscriber?: string;
    colorId: string;
    brandId: string;
    sizeId: string;
    colorGroupId: string;
    user: any;
    businessDimension: string;
    contentId: string;
    contentTag: string[];
}

declare interface IRaptorClickEvent {
    raptorFeedType: string;
    eventType: RaptorEventType;
    styleId: string;
    colorGroupId: string;
}

export enum RaptorEventType {
    Visit = 'visit',
    Basket = 'basket',
    Buy = 'buy',
    ItemClick = 'itemClick',
}

// tslint:disable-next-line:max-classes-per-file
class RaptorEvent implements IRaptorEvent {
    brandId: string;
    categoryPath: string;
    colorGroupId: string;
    colorId: string;
    currency: string;
    domain: string;
    eventType: RaptorEventType;
    price: string;
    productId: string;
    productName: string;
    sizeId: string;
    user: any;
    isSubscriber: string;
    userId: string;
    businessDimension: string;
    contentId: string;
    contentTag: string[];

    constructor(
        businessDimension: string,
        brandId: string,
        categoryPath: string,
        colorGroupId: string,
        colorId: string,
        currency: string,
        eventType: RaptorEventType,
        price: string,
        productId: string,
        productName: string,
        sizeId: string,
        user: any | string,
        contentId: string,
        contentTag: string[]
    ) {
        this.brandId = brandId;
        this.categoryPath = categoryPath;
        this.colorGroupId = colorGroupId;
        this.colorId = colorId;
        this.currency = currency;
        this.eventType = eventType;
        this.price = price;
        this.productId = productId;
        this.productName = productName;
        this.sizeId = sizeId;
        this.user = user;
        this.businessDimension = businessDimension;
        this.contentId = contentId;
        this.contentTag = contentTag;
    }

    sendEvent(): void {

        if ((window as any).CookieInformationScriptLoaded && !(window as any).CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
            console.log('Abort sending data to Raptor! Consent not fulfilled.');
            return;
        }
        const anonymousUser = ''.concat(this.businessDimension, 'anonymous');

        if (this.user.hasOwnProperty('UserName')) {
            this.userId = this.user.hasOwnProperty('UserName')
                ? this.user.UserName
                : anonymousUser;

            this.isSubscriber = this.user.hasOwnProperty(
                'IsNewsletterSubscriber'
            )
                ? this.user.IsNewsletterSubscriber.toString()
                : 'false';
        } else {
            const userName = localStorage.getItem('uid');
            const isNewsletterSubscriber = JSON.parse(
                localStorage.getItem('uNewsSub')
            );
            if (userName && isNewsletterSubscriber) {
                this.userId = userName;
                this.isSubscriber = isNewsletterSubscriber;
            }
        }

        this.domain = this.businessDimension;

        raptor.trackEvent(
            this.eventType,
            this.productId,
            this.productName,
            this.categoryPath,
            this.price,
            this.currency,
            this.domain,
            this.userId,
            this.isSubscriber,
            this.colorId,
            this.brandId,
            this.sizeId,
            this.colorGroupId,
            null,
            this.contentId, // it looks like contentId is parameter 15 and cotentTag is parameter 16. Parameter 14 is not shown on admin.raptorsmartadvisor.com.
            this.contentTag
        );
    }
}

// tslint:disable-next-line:max-classes-per-file
class RaptorClickEvent implements IRaptorClickEvent {
    styleId: string;
    colorGroupId: string;
    raptorFeedType: string;
    eventType: RaptorEventType;

    constructor(
        raptorFeedType: string,
        eventType: RaptorEventType,
        styleId: string,
        colorGroupId: string
    ) {
        this.styleId = styleId;
        this.colorGroupId = colorGroupId;
        this.raptorFeedType = raptorFeedType;
        this.eventType = eventType;
    }

    sendEvent(): void {
        if ((window as any).CookieInformationScriptLoaded && !(window as any).CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
            console.log('Aborting sending data to Raptor! Consent not fulfilled.');
            return;
        }

        raptor.trackClickEvent(
            {
                methodName: this.raptorFeedType,
            },
            [
                this.eventType,
                this.styleId,
                ,
                ,
                ,
                ,
                ,
                ,
                ,
                ,
                ,
                ,
                this.colorGroupId,
            ]
        );
    }
}
