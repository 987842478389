import {Component} from '@angular/core';
import {ProductUtilityService} from '@core/productUtility.service';
import {SiteContextService} from '@core/site-context.service';
import {TranslationService} from '@core/translation.service';

@Component({
    selector: 'app-delivery-text',
    template: `
        {{(translationService.translations$ | async)?.ProductDetails?.ProductDeliveryDate?.DeliveryText}} <b>{{deliveryFromDate | date: dateFormat:'+0100':language}} - {{deliveryToDate | date: dateFormat:'+0100':language}}</b>
    `
})
export class DeliveryTextComponent {
    language: string;
    dateFormat: string;
    deliveryFromDate: string;
    deliveryToDate: string;

    constructor(
        public translationService: TranslationService,
        private productUtilityService: ProductUtilityService,
        private siteContextService: SiteContextService,
    ) {
        this.language = this.siteContextService.getContext().language;
        this.language = this.language !== 'se' ? this.language : 'sv';
        this.dateFormat = this.language !== 'en' ? 'd. MMM' : 'MMM d';

        // Get delivery dates
        this.productUtilityService.getDeliveryTime()
            .subscribe(dates => {
                this.deliveryFromDate = dates.FromDate;
                this.deliveryToDate = dates.ToDate;
            });
    }

}
