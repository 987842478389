import {Component, Input, OnInit} from '@angular/core';
import {brightnessByColor} from '@shared/utility';

@Component({
    selector: 'app-color-group-variations',
    template: `
        <ng-container *ngFor="let hexColor of colorsToDisplay">
            <div
                *ngIf="!hexColor.includes('|')"
                class="color-dot"
                [style.background-color]="hexColor"
                [style.border-color]="addBorderColor(hexColor) ? '#333333' : 'transparent'"
            ></div>
            <div
                *ngIf="hexColor.includes('|')"
                class="color-dot color-dot_no-border"
                [style.background]="gradientMaker(hexColor)"
            ></div>
        </ng-container>
        <div *ngIf="colorGroupList?.length > 4">+{{colorGroupList.length - 4}}</div>
    `
})
export class ColorGroupVariationsComponent implements OnInit {

    @Input() colorGroupList: string[];
    colorsToDisplay: string[];

    constructor() {}

    ngOnInit(): void {
        this.colorsToDisplay =
            this.colorGroupList?.length > 4 ?
                this.colorGroupList.slice(0, 4) :
                this.colorGroupList;
    }

    addBorderColor(color: string): boolean {
        return brightnessByColor(color) > 225;
    }

    gradientMaker(hexColor: string): string {
        const gradientColors = hexColor.split('|');
        const numOfSteps = gradientColors.length;
        let gradientSteps = '';
        gradientColors.forEach((color, index) => {
            gradientSteps += `${color} ${(index * 100) / numOfSteps}%${index < numOfSteps - 1 ? ', ' : ''}`;

            /* Use this code if the gradient colors shall have hard steps */
            /*if (index < numOfSteps - 1) {
                gradientSteps += `${color} ${((index + 1) * 100) / numOfSteps}%,`;
            }*/
        });
        return `linear-gradient(135deg, ${gradientSteps})`;
    }
}
