<article class="spot-text">
    <div class="spot-text__content">
        <div class="spot-text__body">
            <form
                novalidate
                name="optOutEmailForm"
                #optOutEmailForm="ngForm"
                (submit)="optOutEmailSubmit()"
            >
                <div class="form__field">
                    <label class="label label_animated">
                        <div class="input-container input-container_icon">
                            <input type="text"
                                   class="input input_text input_bold"
                                   name="userEmail"
                                   [pattern]="regexService.email"
                                   appLowerCaseInput
                                   appResetInput
                                   required
                                   [(ngModel)]="optOutEmail.Email"
                                   #userEmail="ngModel"
                                   autocorrect="off"
                                   autocapitalize="none"
                            >
                            <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                            <span class="label__text">{{(translations.translations$ | async)?.UserService?.Unsubscribes?.EmailLabel}}</span>
                        </div>
                    </label>
                    <div class="form__validation" *ngIf="(userEmail.invalid && userEmail.touched) || optOutEmailForm.submitted">
                        <span class="validation" *ngIf="userEmail.errors?.required">{{(translations.translations$ | async)?.GeneralInput?.Content?.EmailInputMissing}}</span>
                        <span class="validation" *ngIf="userEmail.errors?.pattern">{{(translations.translations$ | async)?.GeneralInput?.Content?.EmailInputInvalid}}</span>
                    </div>
                </div>
                <button type="submit" class="button button_primary button_full button_text-center">
                    {{(translations.translations$ | async)?.UserService?.Unsubscribes?.UnsubscribeEmailButtonText}}
                    <spinner name="unsubscribeEmailSpinner" [size]="20" background="true"></spinner>
                </button>
                <div class="form__action">
                    <app-alert class="form__response" *ngIf="optOutEmailResponseMessageShow" [closeCallback]="closeEmailSuccessNotification" [type]="alertType.success" [text]="optOutEmailResponseMessage"></app-alert>
                </div>
            </form>
            <form
                novalidate
                name="optOutSmsForm"
                #optOutSmsForm="ngForm"
                (submit)="optOutSmsSubmit()"
            >
                <div class="form__field">
                    <label class="label label_animated">
                        <div class="input-container input-container_icon">
                            <input type="text"
                                   class="input input_text input_bold"
                                   name="userSms"
                                   [pattern]="regexService.phone"
                                   appResetInput
                                   required
                                   [(ngModel)]="optOutSms.PhoneNumber"
                                   #userSms="ngModel"
                            >
                            <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                            <span class="label__text">{{(translations.translations$ | async)?.UserService?.Unsubscribes?.MobileNumberLabel}}</span>
                        </div>
                    </label>
                    <div class="form__validation" *ngIf="(userSms.invalid && userSms.touched) || optOutSmsForm.submitted">
                        <span class="validation" *ngIf="userSms.errors?.required">{{(translations.translations$ | async)?.GeneralInput?.Content?.PhoneInputMissing}}</span>
                        <span class="validation" *ngIf="userSms.errors?.pattern">{{(translations.translations$ | async)?.GeneralInput?.Content?.PhoneInputInvalid}}</span>
                    </div>
                </div>
                <button type="submit" class="button button_primary button_full button_text-center">
                    {{(translations.translations$ | async)?.UserService?.Unsubscribes?.UnsubscribeSmsButtonText}}
                    <spinner name="unsubscribeSmsSpinner" [size]="20" background="true"></spinner>
                </button>
                <div class="form__action">
                    <app-alert class="form__response" *ngIf="optOutSmsResponseMessageShow" [closeCallback]="closeSmsSuccessNotification" [type]="alertType.success" [text]="optOutSmsResponseMessage"></app-alert>
                </div>
            </form>
        </div>
    </div>
</article>
