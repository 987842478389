<div class="container {{ templateName }} {{ mobileLayout }}">
    <div class="sublayout"
        [ngClass]="{
            'sublayout_background': data.BackgroundColor,
            'sublayout_shadow': data.HasShadow,
            'collapse-content': data.CollapseContent,
            'collapse-content-toggle': collapseContentToggle
        }"
        [style.backgroundColor]="data.BackgroundColor"
        [style.height.px]="collapseContentToggle ? nonCollapsedContentHeight : data.CollapseHeight"
    >
        <article style="display:flex">
            <section >

                <div>
                    <div *ngFor="let spot of data.Column1Spots" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName" [backgroundColor]="data.BackgroundColor"></div>
                </div>

            </section>
            <section>

                <div>
                    <div *ngFor="let spot of data.Column2Spots" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName" [backgroundColor]="data.BackgroundColor"></div>
                </div>

            </section>
        </article>
    </div>
    <div class="collapse-content-button-container">
        <button
            type="button"
            class="button button_icon button_clean button__icon-go-arrow collapse-content__button"
            (click)="toggleCollapseContent()"
            *ngIf="data.CollapseContent"
        >
            <span style="display: inline-flex; margin-right: 8px;" [innerHTML]="icon.goArrow | safeHtml"></span>
            {{collapseContentToggle ? (translation.translations$ | async)?.CollapseContent?.General?.ShowLess : (translation.translations$ | async)?.CollapseContent?.General?.ShowMore}}
        </button>
    </div>
</div>
