import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnInit,
    Optional,
    TemplateRef, ViewContainerRef,
} from '@angular/core';

@Component({
    selector: 'app-sublayout100',
    templateUrl: './sublayout100.component.html',
})
export class Sublayout100Component implements OnInit {
    constructor(
        private changeRef: ChangeDetectorRef,
        private elm: ElementRef,
        @Optional()
        @Inject('isBot')
        private isBot: boolean,
    ) {

    }

    static ref = 'Sublayout100';

    @Input() public data;

    @HostBinding('class.is-hidden-mobile') get isHiddenMobile() {
        return !this.data.ShowOnMobile && this.data.ShowOnTabletAndDesktop;
    }
    @HostBinding('class.is-hidden-tablet') get isHiddenTabletDesktop() {
        return !this.data.ShowOnTabletAndDesktop && this.data.ShowOnMobile;
    }

    show = true;

    public templateName: string;
    public mobileLayout: string;

    ngOnInit() {

        this.boundHide = this.hide.bind(this);

        for (let i = 0; i < this.data.Column1Spots.length; i++) {
            if (this.data.Column1Spots[i].TemplateName) {
                this.templateName = this.data.Column1Spots[i].TemplateName;
                this.mobileLayout = this.data.Column1Spots[i].MobileLayout;
            }
        }
    }

    boundHide;
    hide() {
        this.show = false;
        this.changeRef.detectChanges();
    }
    private hasSublayoutContent(): boolean {
        return !!this.data.Column1Spots.length;
    }
}
