import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-svg',
    template: `
        <span *ngIf="isSourceInlineSvg && showSvg" [innerHTML]="src | safeHtml"></span>
        <img *ngIf="!isSourceInlineSvg && showSvg && !isLocalFile" [src]="src" loading="lazy" class="svg"/>
        <img *ngIf="isLocalFile && !inline && showSvg" [src]="src" loading="lazy" class="svg" [ngClass]="'svg_' + size"/>
        <img *ngIf="showSvg && isLocalFile && inline" [src]="src" InlineSvg/>
    `
})

export class SvgComponent implements OnInit {

    constructor() {
    }

    @Input() size: 'small' | 'normal' | 'large' | 'unset' = 'normal';
    @Input() src: string;
    @Input() inline: boolean;

    isLocalFile = false;
    isSourceInlineSvg = false;
    showSvg = false;

    @HostBinding('class.inline-svg') inlineSvg = false;
    @HostBinding('class.svg_small') get isSmall() {
        return this.size === 'small';
    }
    @HostBinding('class.svg_normal') get isNormal() {
        return this.size === 'normal';
    }
    @HostBinding('class.svg_large') get isLarge() {
        return this.size === 'large';
    }

    @HostBinding('class.svg_unset') get isUnset() {
        return this.size === 'unset';
    }
    ngOnInit(): void {
        this.showSvg = this.src !== '';
        this.isSourceInlineSvg = this.src.includes('<svg');
        this.isLocalFile = !this.isSourceInlineSvg && !this.src.includes('https');
        if (this.isLocalFile) {
            this.src = `/assets/svg/${this.src}.svg`;
        }
        if (this.inline) {
            this.inlineSvg = true;
        }
    }
}
