import { SiteContextService } from './site-context.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    ImpactWebsiteCodeWebApiControllersAuthReturnModel as authReturnModel,
    ImpactWebsiteCodeWebApiControllersLoginArguments as loginArguments,
    ImpactWebsiteCodeWebApiControllersResetArguments as resetArguments,
} from '@shared/swagger/swagger.interface';
import { tap } from 'rxjs/operators';
import { RaptorTrackingService } from '@core/tracking/raptor-tracking.service';

@Injectable()
export class AuthenticationService {
    constructor(
        private httpClient: HttpClient,
        private siteContextService: SiteContextService,
        private raptorTrackingService: RaptorTrackingService,
    ) {
        this.isAuthorized.next(this.siteContextService.context.isLoggedIn);
    }

    private env: string = this.siteContextService.getRootUrl();

    isAuthorized = new BehaviorSubject<boolean>(false); // Public and can be altered from everywhere

    isAuthorized$ = this.isAuthorized.asObservable();

    /**
     * Login for user
     */
    login(user: loginArguments): Observable<authReturnModel> {
        return this.httpClient
            .post<authReturnModel>(`${this.env}/webapi/Auth/Login`, user)
            .pipe(
                tap(authData => {
                    localStorage.setItem('clientToken', authData.AuthToken);
                    this.raptorTrackingService.trackAddUser(user.Email);
                    this.isAuthorized.next(true);
                })
            );
    }

    /**
     * Logout for user
     */
    logout(): Observable<authReturnModel> {
        return this.httpClient
            .post<authReturnModel>(`${this.env}/webapi/Auth/Logout`, null)
            .pipe(
                tap(() => {
                    localStorage.removeItem('clientToken');
                    this.isAuthorized.next(false);
                })
            );
    }

    /**
     * Reset password for user
     */
    resetPassword(user: resetArguments): Observable<authReturnModel> {
        return this.httpClient.post<authReturnModel>(
            `${this.env}/webapi/Auth/ResetPassword`,
            user
        );
    }

}
