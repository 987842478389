import {
    Component,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-json-ld',
    template: '',
})
export class JsonLdComponent implements OnInit, OnChanges {
    constructor(private sanitizer: DomSanitizer) {}

    @Input() readonly json: Object = {};

    @HostBinding('innerHTML') jsonLD: SafeHtml = '';

    ngOnInit() {
        this.jsonLD = this.getSafeHTML(this.json);
    }

    private getSafeHTML(value: Object): SafeHtml {
        const html = `<script type="application/ld+json">${JSON.stringify(
            value
        )}</script>`;
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.json.currentValue) {
            this.jsonLD = this.getSafeHTML(changes.json.currentValue);
        }
    }
}
