import { Component, EventEmitter, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslationService } from '@core/translation.service';
import { Store } from '@features/find-store/models/store.model';
import { Router } from '@angular/router';
import { PaneService } from '@core/pane.service';

@Component({
    selector: 'app-find-store-pane',
    templateUrl: './find-store-pane.component.html',
})

/**
 *  Find Store Page Component is the root component in the right pane 'find-store' view
 */
export class FindStorePaneComponent implements OnInit {
    public translations: any;

    constructor(
        private translationService: TranslationService,
        private router: Router,
        private paneService: PaneService
    ) {}

    ngOnInit() {
        this.translations = this.translationService.translations$;
    }

    onStoreSelected(store: Store) {
        if (store.LinkToContentPage) {
            const route = '(dialog:' + store.LinkToContentPage + ')';
            this.router.navigate(
                [
                    {
                        outlets: {
                            dialog: store.LinkToContentPage.replace('/', ''),
                        },
                    },
                ],
                { queryParamsHandling: 'preserve' }
            );
            this.paneService.closeAll();
        }
    }

    // Trigger animation
    animationStateChanged = new EventEmitter<AnimationEvent>();
    startExitTrigger = new Subject();

    doSomething(event) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }
}
