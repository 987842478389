export enum AlertType {
    error = 'error',
    success = 'success',
    info = 'info',
    warning = 'warning',
}

export interface AlertResponse {
    text?: string;
    type?: AlertType;
}
