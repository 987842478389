import {Component, EventEmitter, HostBinding, OnDestroy, OnInit} from '@angular/core';
import { BasketService } from '@core/basket.service';
import { SiteContextService } from '@core/site-context.service';
import { TranslationService } from '@core/translation.service';
import { goArrow } from '@shared/svg';
import { ImpactCoreBasketsBasketLineServiceModel as basketLineModel } from '@shared/swagger/swagger.interface';
import { Subject } from 'rxjs';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {takeUntil} from 'rxjs/operators';
import {
    IPersonalizationBarFreeFreight,
    IPersonalizationBarItem,
    PersonalizationBarService
} from '@core/personalization-bar.service';

@Component({
    selector: 'app-minibasket',
    templateUrl: './minibasket.component.html',
})
export class MinibasketComponent implements OnInit, OnDestroy {
    animationStateChanged = new EventEmitter<AnimationEvent>();
    startExitTrigger = new Subject();
    isMobile: boolean;

    imageBgColor: string;
    showUpsellingProducts = false;
    private destroy: Subject<any> = new Subject<any>();

    @HostBinding('style.width.px') minibasketWidth: number;

    constructor(
        public basketService: BasketService,
        public siteContextService: SiteContextService,
        public translationService: TranslationService,
        private breakpointObserver: BreakpointObserver,
        private personalizationBarService: PersonalizationBarService,
    ) {
        this.imageBgColor = this.siteContextService.getContext().bgColor.productImageBgColor;
        this.basketService.basket$.pipe(takeUntil(this.destroy)).subscribe(data => {
            this.showUpsellingProducts = this.siteContextService.getContext().checkoutSettings.MinibasketUseUpsellingProducts && data.TotalItemCount > 0;
            this.minibasketWidth = this.showUpsellingProducts ? 768 : 400;
        });
    }

    /**
     * Prep icons for minibasket
     */
    icons = {
        goArrow,
    };


    freeFreightMessage = '';
    amountBeforefreeFreightValue = 0;
    currency: string;
    freeFreightAvailable = false;


    /**
     * Final update basket event for children to call when changes are made
     */
    onUpdateBasket(basketLine: basketLineModel) {
        this.basketService.update(
            basketLine.Id,
            basketLine.SkuId,
            basketLine.Amount
        );
    }

    doSomething(event) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }

    ngOnInit(): void {
        this.breakpointObserver
            .observe(['(max-width: 440px)'])
            .pipe(takeUntil(this.destroy))
            .subscribe((state: BreakpointState) => {
                this.isMobile = state.matches;
            });
        this.personalizationBarService.freeFreightMessage$
            .pipe(takeUntil(this.destroy))
            .subscribe((value: IPersonalizationBarItem) => {
                this.freeFreightMessage = value.barContent.text ?? '';
                this.amountBeforefreeFreightValue = (value.barContent as IPersonalizationBarFreeFreight).freeDeliveryDifferenceAmount ?? 0;
                this.currency = value.currency;
                this.freeFreightAvailable = (value.barContent as IPersonalizationBarFreeFreight).freeShippingAvailable;
            });
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }
}
