import {
    Component,
    ComponentFactoryResolver,
    Input,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import { pageComponents } from '@features/pages/pages';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '@core/seo';

@Component({
    selector: 'app-dialog-page-content',
    template: ' ',
})
export class DialogPageContentComponent implements OnInit {
    @Input() private data: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private cfResolver: ComponentFactoryResolver,
        private viewContainer: ViewContainerRef,
        private seoService: SeoService
    ) {}

    ngOnInit() {
        const data = this.data['content'];

        if (!data) {
            return;
        }

        this.seoService.setTags(data.pageData);

        // Find the ComponentClass of the desired pageComponent (based on template)
        const ComponentClass = pageComponents.find(
            component => component.ref === data.pageData['TemplateName']
        );

        if (!ComponentClass) {
            console.warn(
                "Couldn't find a matching component for this template"
            );
            return;
        }

        // Resolve the ComponentFactory
        const pageComponentFactory = this.cfResolver.resolveComponentFactory(
            ComponentClass
        );

        // Create the component, attach it to the viewContainer and bind the data
        const pageComponent = this.viewContainer.createComponent(
            pageComponentFactory
        );
        pageComponent.instance['data'] = data.content;
    }
}
