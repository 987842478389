import {
    Component,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

@Component({
    selector: 'app-spot-heading-seo',
    template: ``,
})
export class SpotHeadingSeoComponent implements OnInit, OnChanges {
    constructor(
        private viewConainer: ViewContainerRef,
        private renderer: Renderer2
    ) {}

    tagType: string;
    hElement: any;

    @Input()
    headingClass: string;
    @Input()
    set hTag(tag: string) {
        if (tag) {
            this.tagType = tag.toLowerCase();
        }
    }
    @Input()
    headingText: string;

    ngOnInit() {
        this.hElement = this.renderer.createElement(this.tagType);
        const hElementText = this.renderer.createText(this.headingText);
        this.renderer.addClass(this.hElement, this.headingClass);
        this.renderer.appendChild(this.hElement, hElementText);
        this.renderer.appendChild(
            this.viewConainer.element.nativeElement,
            this.hElement
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.headingText.previousValue !== undefined) {
            this.hElement.innerHTML = changes.headingText.currentValue;
        }
    }
}
