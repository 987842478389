<div class="alert" [ngClass]="{
    'alert_error': type === 'error',
    'alert_success': type === 'success',
    'alert_info': type === 'info',
    'alert_warning': type === 'warning'
}">
    {{text}}
    <button type="button" class="alert__button" (click)="closeCallback()" *ngIf="!hideCloseButton">
        <svg viewBox="0 0 28.2 28.2">
            <path fill="#979797" d="M28.2.7l-.7-.7-13.4 13.4L.7 0 0 .7l13.4 13.4L0 27.5l.7.7 13.4-13.4 13.4 13.4.7-.7-13.4-13.4z" />
        </svg>
    </button>
</div>

