import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
    selector: '[appLowerCaseInput]',
})
export class LowerCaseInputDirective implements OnInit, OnDestroy {
    private subscription: Subscription;

    constructor(private ngControl: NgControl) {}

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        const ctrl = this.ngControl;

        this.subscription = ctrl.valueChanges
            .pipe(
                map(val => {
                    if (val) {
                        return val.toLowerCase();
                    }
                })
            )
            .subscribe(v => ctrl.control.setValue(v, { emitEvent: false }));
    }
}
