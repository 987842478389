import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appShopByImageHoverClass]',
})
export class ShopByImageHoverClassDirective implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit(): void {
        const elm = this.el.nativeElement as HTMLElement;

        elm.addEventListener(
            'mouseover',
            (e: MouseEvent) => {
                (this.el.nativeElement as HTMLElement).classList.remove('out');
                (this.el.nativeElement as HTMLElement).classList.add('over');
            },
            { passive: true }
        );
        elm.addEventListener(
            'mouseout',
            (e: MouseEvent) => {
                (this.el.nativeElement as HTMLElement).classList.add('out');
                (this.el.nativeElement as HTMLElement).classList.remove('over');
                setTimeout(() => {
                    (this.el.nativeElement as HTMLElement).classList.remove(
                        'out'
                    );
                }, 400);
            },
            { passive: true }
        );
    }
}
