<app-rightpane (animationStateChanged)="doSomething($event)" [startExit]="startExitTrigger" *noSSR>

    <div class="rightpane-container">
        <header class="rightpane-container__header">
            <h2 class="rightpane-container__heading">{{translations?.Authentication?.ResetPassword?.ResetPassword}}</h2>
        </header>

        <div class="rightpane-container__content" appScrollbarHide>
            <div class="authentication">
                <div class="authentication__body">
                    <p>{{translations?.Authentication?.ResetPassword?.ResetPasswordDescription}}</p>
                </div>

                <div class="authentication__form">
                    <form class="form" novalidate name="form" [ngClass]="{'form_submitted': form.submitted}" (ngSubmit)="onResetPassword(form)"
                        #form="ngForm">
                        <div class="form__field">
                            <label class="label label_animated">
                                <div class="input-container input-container_icon">
                                    <input type="email" class="input input_text input_bold" name="email" appResetInput required [pattern]="regexService.email"
                                        [(ngModel)]="user.Email" #email="ngModel">
                                    <span class="input-container__icon input-container__icon-mail validation-icon validation-icon_normal" [innerHTML]="icons.mail | safeHtml"></span>
                                    <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                                    <span class="label__text">{{translations?.GeneralInput?.Content?.EmailInput}}</span>
                                </div>
                            </label>
                            <div class="form__validation" *ngIf="(email.invalid && email.touched) || form.submitted">
                                <span class="validation" *ngIf="email.errors?.required">{{translations?.GeneralInput?.Content?.EmailInputMissing}}</span>
                                <span class="validation" *ngIf="email.errors?.pattern">{{translations?.GeneralInput?.Content?.EmailInputInvalid}}</span>
                            </div>
                        </div>

                        <div class="form__action authentication__action">
                            <app-alert class="form__response" *ngIf="response.text" [closeCallback]="closeAlert" [type]="response.type" [text]="response.text"></app-alert>

                            <button type="submit" class="button button_primary button_full button_text-center authentication__button">
                                {{translations?.Authentication?.ResetPassword?.ResetPasswordBotton}}
                                <spinner name="resetPasswordSpinner" [size]="20" background="true" inverted="true"></spinner>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</app-rightpane>