import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { isPlatformBrowser } from '@angular/common';
import { ExposedProductRow } from '@features/product-list/exposed-product-row';
import { ISplashVisibility } from '@shared/product-splash/product-splash.component';

@Component({
    selector: 'app-row-template2',
    template: `
        <div class="exposed-products__row columns">
            <div class="column is-one-quarter is-paddingless">
                <ul class="exposed-products__list columns is-multiline">
                    <li *ngFor="let product of data.products | slice:0:2"
                        class="exposed-products__item column is-full is-half-mobile is-paddingless"
                    >
                        <app-product-item [product]="product"
                                          [sizeLabels]="(translation.translations$ | async)?.ProductList?.Block"
                                          [splashVisibility]='splashVisibility'
                        ></app-product-item>
                    </li>
                </ul>
            </div>
            <div class="column is-half is-paddingless exposed-product exposed-products__item" (inView)="onInView($event)">
                <ul class="exposed-products__list columns is-multiline">
                    <li class="column is-paddingless exposed-item">
                        <app-product-item [product]="data.exposedProduct.Product"
                                          [isExposedProduct]="true"
                                          [exposedBgColorImage]="data.exposedProduct.BgColor"
                                          [exposedImage]="data.exposedProduct.Product.ImageUrl"
                                          [rowTemplate]="2"
                                          [rowNumber]="data.exposedProduct.RowNumber"
                                          [sizeLabels]="(translation.translations$ | async)?.ProductList?.Block"
                                          [splashVisibility]='splashVisibility'
                        ></app-product-item>
                    </li>
                </ul>
            </div>
            <div class="column is-one-quarter is-paddingless">
                <ul class="exposed-products__list columns is-multiline">
                    <li *ngFor="let product of data.products | slice:2:4"
                        class="exposed-products__item column is-full is-half-mobile is-paddingless"
                    >
                        <app-product-item [product]="product"
                                          [sizeLabels]="(translation.translations$ | async)?.ProductList?.Block"
                                          [splashVisibility]='splashVisibility'
                        ></app-product-item>
                    </li>
                </ul>
            </div>
        </div>
    `,
})
export class RowTemplate2Component implements OnInit {
    static readonly ref = 'exposedProductTemplate2';

    @Input() data: ExposedProductRow;
    @Input() splashVisibility: ISplashVisibility;

    inView = false;
    hasBeenExposed = false;

    constructor(
        public translation: TranslationService,
        private trackingService: TrackingService,
        @Inject(PLATFORM_ID) private platformId
    ) {}

    ngOnInit() {}

    onInView(bool: boolean) {
        if (
            isPlatformBrowser(this.platformId) &&
            !this.hasBeenExposed &&
            bool
        ) {
            this.trackingService.sendExposedProductImpressions(this.data);
            this.hasBeenExposed = true;
        }
    }
}
