import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Directive, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';

import { ObserverService } from './observer.service';

@Directive({
    selector: 'img[LazyLoad]'
})
export class LazyloadDirective implements OnInit, OnDestroy {
    @Input() LazyLoadSrc: string;
    @Input() LazyLoadSrcSet: string;

    constructor(
        private _observer: ObserverService,
        private el: ElementRef,
        @Inject(PLATFORM_ID) private platformId: Object,
        private cd: ChangeDetectorRef
    ) {}

    /**
     * Initiates the observer watcher if Observers is supported
     * if not we simply just load the sources.
     */
    ngOnInit() {
        if (this._observer.isBot) {
            this.addSources(true);
        } else if (this._observer.supported) {
            this._observer.addTarget(this.el.nativeElement, this.addSources.bind(this));
        } else if (isPlatformBrowser(this.platformId)) {
            this.addSources(true);
        }
    }

    /**
     * Adds the sources to the img tag if in viewport
     */
    addSources(inViewport: boolean) {
        if (inViewport) {
            // Sets the src attribute of the img tag
            if (this.LazyLoadSrc) {
                this.el.nativeElement.src = this.LazyLoadSrc;
            }

            // Sets the srcset attribute of the img tag
            if (this.LazyLoadSrcSet) {
                this.el.nativeElement.srcset = this.LazyLoadSrcSet;
            }

            this.cd.markForCheck();
        }
    }

    ngOnDestroy() {
        this._observer.removeTarget(this.el.nativeElement);
    }
}
