
<div class="title-div container">
  <h5 class="brandspot-title"> {{ data.FirstHeadline }} </h5>
  <h4 class="brandspot-subtitle"> {{ data.SecondHeadline }} </h4>
</div>


<div class="brand-slider" *ngIf="(slides$ | async)?.length">
    <div class="brand-slider__content">
      <!-- Slider main container -->
      <div id="slider" class="swiper-container" #slider [id]="id">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper" #sliderList>
          <!-- Slides -->
          <div class="swiper-slide" #sliderItem *ngFor="let slide of (slides$ | async); let i = index;" [style.width]="itemWidth.width + '%'" >
            <favorite-brand-spot-brand [slide]="slide" [index]="i" [backgroundColor]="backgroundColor" [imageBgColor]="data.ImageBackgroundColor" [class.small]="itemWidthInPx < 250"></favorite-brand-spot-brand>
          </div>
        </div>
      </div>
      <!-- If we need navigation buttons -->
      <div class="slider-button-prev" [ngClass]="{'slider-button-prev--hide': sliderStart}" [id]="'prev_' + id" (click)="prevClickTrigger()" [@prevClick]="prevClick" tabindex="0" role="button" aria-label="Previous" [attr.aria-disabled]="sliderEnd ? true : false">
        <span [innerHtml]="paginationArrow | safeHtml"></span>
      </div>
      <div class="slider-button-next" [ngClass]="{'slider-button-next--hide': sliderEnd}" [id]="'next_' + id" (click)="nextClickTrigger()" [@nextClick]="nextClick" tabindex="0" role="button" aria-label="Next" [attr.aria-disabled]="sliderStart ? true : false">
        <span [innerHtml]="paginationArrow | safeHtml"></span>
      </div>

    </div>
  </div>

<div class="brandspot-see-all-brands-btn-div" >
    <button type="button" class="smart-filter__facets__button"><span class="smart-filter__facets__button-name" [IRouterLink]="data.LinkToBrandPage">{{data.ButtonText}}</span> </button>
</div>

