import { LanguagePickerComponent } from './../language-picker/language-picker.component';
import { WishlistPaneComponent } from '@features/wishlist/wishlist-pane/wishlist-pane.component';
import { MinibasketComponent } from '@features/minibasket/minibasket.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '@core/authentication.service';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ImpactOverlayRef, OverlayService } from '@impactdk/ngx-overlay';
import { LoginComponent } from '@features/authentication/login/login.component';
import { BasketService } from '@core/basket.service';
import { SearchComponent } from '@features/search/search.component';
import { PaneService } from '@core/pane.service';
import { FindStorePaneComponent } from '@features/find-store/find-store-pane/find-store-pane.component';
import { SiteContextService } from '@core/site-context.service';
import { TrackingService } from '@core/tracking/tracking.service';
import {HeaderService} from '@core/header.service';
import {ImpactCoreModelsDtoMetaMenuMetaMenuItemDto as IMetaMenuItem} from '@shared/swagger/swagger.interface';
import { FavoriteService } from '@core/favorite.service';

/**
 * Renders a meta menu (list of icons)
 */
@Component({
    selector: 'meta-menu',
    templateUrl: './meta-menu.component.html',
})
export class MetaMenuComponent implements OnInit, OnDestroy {
    /**
     * Icons that needs to be rendered
     */
    @Input() metaMenu: IMetaMenuItem[];
    @Input() isMobile = false;

    searchKaufmannMobilePaneClass = 'search-axel-mobile';
    /**
     * If you want to handle the click events outside of our normal link handler, simply trigger on this event
     * @type {EventEmitter<any>}
     */
        // tslint:disable-next-line:no-output-on-prefix
    @Output() onClick = new EventEmitter();

    totalFavorites = 0;

    /**
     * Injects dependencies
     * @param {Router} router
     * @param overlay
     * @param authenticationService
     * @param basketService
     * @param siteContextService
     * @param paneService
     * @param tracking
     * @param headerService
     * @param favoriteService
     */
    constructor(
        private router: Router,
        private overlay: OverlayService,
        private authenticationService: AuthenticationService,
        private basketService: BasketService,
        private siteContextService: SiteContextService,
        public paneService: PaneService,
        private tracking: TrackingService,
        private headerService: HeaderService,
        public favoriteService: FavoriteService,
    ) {
        this.businessDimension = this.siteContextService.getContext().businessDimension.toLowerCase();
        this.favoriteService.favoriteProductStoreList$
            .pipe(takeUntil(this.destroy))
            .subscribe(favItems => this.totalFavorites = favItems.length);
    }

    /**
     * Set is logged in boolean on load
     *
     * @public
     * @type {boolean}
     * @memberof MetaMenuComponent
     */
    isLoggedIn = false;

    private destroy = new Subject();

    public language: any;

    businessDimension: string;

    /**
     * Store overlay reference for toggle of rightpane
     *
     * @type {ImpactOverlayRef}
     * @memberof MetaMenuComponent
     */
    public overlayRef: ImpactOverlayRef;

    public ngOnInit(): void {
        this.authenticationService.isAuthorized$
            .pipe(takeUntil(this.destroy))
            .subscribe(data => {
                this.isLoggedIn = data;
            });

        this.language = this.siteContextService
            .getContext()
            .countrySelectorList.filter(x => x.IsCurrent)[0];
    }

    /**
     * Makes sure to use the router if on same domain and not target blank etc
     * @param event
     * @param item
     * @returns {boolean}
     */
    handleClick(event, item) {
        this.onClick.emit(item);
        const linkEle = event.currentTarget;

        const url = new URL(item.url, location.protocol + '//' + location.host);

        // Eject if the linkElement has a download-attribute
        if (linkEle.getAttribute('download') !== null) {
            return;
        }

        // Eject if link has a target
        if (item.target === '_blank') {
            return;
        }

        // Eject if link points to another domain
        if (url.host !== location.host) {
            return;
        }

        // If you are still here, the router will take over and do the navigation
        this.router.navigateByUrl(url.pathname);
        return false;
    }

    /**
     * Open/toggle rightpane or set router redirect
     *
     * @param {MetaMenuItem} item
     * @returns
     * @memberof MetaMenuComponent
     */
    openRightPane(item: IMetaMenuItem) {
        this.tracking.sendMetaMenuMenuClick(item.AngularKey);
        let component;
        let paneClass;

        switch (item.AngularKey) {
            case 'basket':
                component = MinibasketComponent;
                paneClass = 'minibasket';
                break;
            case 'login':
                if (this.isLoggedIn) {
                    this.openLogin(item);
                    return;
                }
                component = LoginComponent;
                break;
            case 'profile':
                this.openLogin(item);
                break;
            case 'search':
                this.headerService.headerSolidState = true;
                this.businessDimension !== 'axel' ?
                    // Kaufmann, Quint
                    this.paneService.open(SearchComponent, 'center', item.AngularKey, 'search-panel', 'top', true, 'search-panel-backdrop') :
                    // Axel
                    this.paneService.open(SearchComponent, 'center', item.AngularKey, this.searchKaufmannMobilePaneClass, 'center', true);
                return;
            case 'wishlist':
                component = WishlistPaneComponent;
                break;
            case 'findStore':
                component = FindStorePaneComponent;
                break;
            case 'languagePicker':
                component = LanguagePickerComponent;
                break;
            default:
                if (item.Link) {
                    this.router.navigateByUrl(item.Link);
                    return;
                }
                return;
        }

        this.paneService.open(component, 'right', item.AngularKey, paneClass, null, true);
    }

    openLogin(item: IMetaMenuItem) {
        // If user is logged in navigate to router
        const loginUrl: string = item.Link.replace('/', ''); // Angular removing slash in url

        if (this.router.url !== `/(dialog:${loginUrl})`) {
            this.router.navigate([{ outlets: { dialog: loginUrl } }], {
                queryParamsHandling: 'preserve',
            });
            this.paneService.setActiveKey('login');
        } else {
            this.router.navigate([{ outlets: { dialog: null } }], {
                queryParamsHandling: 'preserve',
            });
            this.paneService.setActiveKey(null);
        }
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }
}
