import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CommonModule } from '@angular/common';
import { RightpaneModule } from '@features/rightpane/rightpane.module';
import { WishlistComponent } from './wishlist/wishlist.component';
import { WishlistPaneComponent } from './wishlist-pane/wishlist-pane.component';
import { WishlistItemComponent } from './wishlist/wishlist-item/wishlist-item.component';
import { WishlistService } from '@core/wishlist.service';
import { WishlistFormulaComponent } from './wishlist-formula/wishlist-formula.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxLazyloadModule } from '@features/ngx-lazyload/ngx-lazyload.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { WishlistBadgeComponent } from './wishlist-badge/wishlist-badge.component';
import { RouterModule } from '@angular/router';
import { FavoriteService } from '@core/favorite.service';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RightpaneModule,
        ReactiveFormsModule,
        NgxLazyloadModule,
        NgxCaptchaModule,
        RouterModule,
    ],
    declarations: [
        WishlistComponent,
        WishlistPaneComponent,
        WishlistItemComponent,
        WishlistFormulaComponent,
        WishlistBadgeComponent,
    ],
    entryComponents: [WishlistPaneComponent],
    providers: [WishlistService, FavoriteService],
    exports: [WishlistBadgeComponent],
})
export class FavoritesModule {}
