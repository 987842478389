import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    animate,
    animateChild,
    group,
    query,
    stagger,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Facet, MultiCheckBoxFacet } from '@features/filter/models/facet.model';
import { FilterService } from '@features/filter/services/filter.service';
import { IProduct } from '@features/product-filter/models/product.model';
import { TranslationService } from '@core/translation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TrackingService } from '@core/tracking/tracking.service';
import { StickyService } from '@core/sticky.service';
import { MultiCheckBoxSelection } from '../models/selection.model';

@Component({
    selector: 'app-product-filter-facets',
    templateUrl: './product-filter-facets.component.html',
    animations: [
        trigger('openState', [
            state(
                'false',
                style({
                    height: '0',
                })
            ),
            state(
                'true',
                style({
                    height: '264px',
                })
            ),
            transition('false => true', [
                group([
                    animate('400ms cubic-bezier(0.25, 0, 0, 1)'),
                    query('@checkboxAnimation', [animateChild()]),
                ]),
            ]),
            transition(
                'true => false',
                group([animate('400ms cubic-bezier(0.25, 0, 0, 1)')])
            ),
        ]),
        trigger('checkboxAnimation', [
            transition(':enter', [
                group([
                    query(
                        '.filter-facet-container, app-product-filter-facet-item, app-product-filter-show-more, app-product-filter-slider, .filter-facets__heading, .filter-facets__separator',
                        [
                            style({
                                opacity: '0',
                                transform: 'translateX(-20px)',
                            }),
                            stagger(
                                '20ms',
                                animate(
                                    '400ms cubic-bezier(0.25, 0, 0, 1)',
                                    style({
                                        opacity: '1',
                                        transform: 'translateX(0)',
                                    })
                                )
                            ),
                        ],
                        {
                            optional: true,
                        }
                    ),
                ]),
            ]),
            transition(':leave', [
                group([
                    query(
                        '.filter-facet-container, app-product-filter-facet-item, app-product-filter-slider, .filter-facets__heading, .filter-facets__separator',
                        [
                            stagger(
                                '20ms',
                                animate(
                                    '300ms cubic-bezier(0.25, 0, 0, 1)',
                                    style({
                                        opacity: '0',
                                        transform: 'translateX(20px)',
                                    })
                                )
                            ),
                        ],
                        {
                            optional: true,
                        }
                    ),
                    query(
                        'app-product-filter-show-more',
                        [
                            animate(
                                '300ms cubic-bezier(0.25, 0, 0, 1)',
                                style({
                                    opacity: '0',
                                    transform: 'translateX(20px)',
                                })
                            ),
                        ],
                        {
                            optional: true,
                        }
                    ),
                ]),
            ]),
        ]),
    ],
})
export class ProductFilterFacetsComponent
    implements OnChanges, OnInit, OnDestroy {
    @Input() open = false;
    @Input() facet: MultiCheckBoxFacet;
    @Input() allFacets: Facet[];
    @Output() pixelScroll: EventEmitter<void> = new EventEmitter();
    disableAnimation = false;
    translations: any;
    unSubscribe = new Subject();

    constructor(
        private filterService: FilterService<IProduct>,
        private translationService: TranslationService,
        private tracking: TrackingService,
        private stickyService: StickyService
    ) {}

    ngOnChanges(changes) {
        if (
            changes.facet &&
            (!changes.facet.firstChange || !changes.open.firstChange)
        ) {
            this.disableAnimation =
                !changes.facet.currentValue ||
                changes.facet.currentValue.key ===
                changes.facet.previousValue.key;
        }
    }

    ngOnInit() {
        this.translationService.translations$
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(translations => {
                this.translations = translations;
            });
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }

    public onItemChange(event: MultiCheckBoxSelection): void {
        if (event.isChecked) {
            this.tracking.sendFacetCheck(this.facet.key, event.option.key);
        }
        const newChildren = this.facet.children.map(child => {
            const newIsActive =
                child.key === event.option.key
                    ? event.isChecked
                    : child.isActive;

            return Object.assign(child, { isActive: newIsActive });
        });

        const paginationFacet = this.allFacets.find(facet => {
            return facet.kind === 'pagination';
        });

        const searchFacet = this.allFacets.find(facet => {
            return facet.kind === 'search';
        });

        this.filterService.updateFilter([
            {
                ...this.facet,
                children: newChildren,
            },
            {
                ...paginationFacet,
                pageIndex: 0,
            },
            {
                ...searchFacet,
            },
        ]);
        this.scrollToTop();
    }

    public onSliderChange(event): void {
        this.tracking.sendSliderChange(this.facet.key, event.currentMax);
        const paginationFacet = this.allFacets.find(facet => {
            return facet.kind === 'pagination';
        });

        this.filterService.updateFilter([
            Object.assign(this.facet, event),
            {
                ...paginationFacet,
                pageIndex: 0,
            },
        ]);

        this.scrollToTop();
    }

    trackByFn(index: any, item: any) {
        return item !== undefined ? item.key : null;
    }
    private scrollToTop(): void {
        this.pixelScroll.emit();
    }
}
