import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Router, Params } from '@angular/router';
import { PageService } from '@core/page.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as hash from 'object-hash';

import { FilterConfigs } from '../configs/filter.config';
import {
    assignToArrayProperty,
    assignToRangeProperty,
    assignToValueProperty,
} from '../functions/param.function';
import {
    Facet,
    MultiCheckBoxFacet,
    MultiCheckBoxFacetChild,
    PaginationFacet,
    SearchFacet,
    SortFacet,
    TwoSidedSliderFacet,
    VisualFacet,
} from '../models/facet.model';
import { IFilter, IFilterConfig } from '../models/filter.model';
import {SiteContextService} from '@core/site-context.service';

@Injectable()
export class FilterService<T> {
    private bs: BehaviorSubject<IFilter<T>>;
    private filterPageId: string;
    private selectedGridState: BehaviorSubject<IProductGridState>;
    private hashedQueryParams: string;
    private businessDimension: string;
    private language: string;
    private mandatoryParams: Params;
    public initialLoad = true;

    private config = FilterConfigs[0];

    constructor(
        private http: HttpClient,
        private router: Router,
        private pageService: PageService,
        private tracking: TrackingService,
        private siteContextService: SiteContextService,
        @Optional()
        @Inject('isBot')
        private isBot
    ) {
        this.bs = new BehaviorSubject(this.getEmptyFilter());
        this.selectedGridState = new BehaviorSubject<IProductGridState>({
            gridState: 'large',
        });
        this.businessDimension = this.siteContextService.getContext().businessDimension;
        this.language = this.siteContextService.getContext().language;
        this.mandatoryParams = {
            businessDimension: this.businessDimension,
            languageCode: this.language
        };
    }

    private queryParams: Params;

    public initFilter(queryParams: Params, defaultParams: Params = {}) {

        this.initialLoad = !this.isBot;

        const config = this.getFilterConfig(FilterConfigs);

        this.pageService.page.pipe(take(1)).subscribe(data => {
            if (data.pageData.Id !== this.filterPageId) {
                this.bs.next(this.getEmptyFilter());
            }
            this.filterPageId = data.pageData.Id;
        });

        queryParams = {
            ...queryParams,
            initialLoad: this.initialLoad,
            ...defaultParams,
        };

        const initialHashedValue = hash({
            url: config.url,
            queryParams,
            filterPageId: this.filterPageId,
        });

        if (initialHashedValue !== this.hashedQueryParams) {
            this.hashedQueryParams = initialHashedValue;

            this.pushParamsToFilterState(
                config.url,
                queryParams,
                this.initialLoad
            );
        }
        this.initialLoad = false;

        this.queryParams = queryParams;
    }

    public paramsUpdated(updatedQueryParams: Params) {
        const config = this.getFilterConfig(FilterConfigs);
        this.hashedQueryParams = hash({
            url: config.url,
            queryParams: updatedQueryParams,
            filterPageId: this.filterPageId,
        });
        this.pushParamsToFilterState(config.url, updatedQueryParams);
    }

    public getFilter(): Observable<IFilter<T>> {
        return this.bs.asObservable();
    }

    public updateFilter(
        newFacet: Facet | Facet[] | any,
        keepInHistory = false
    ): void {
        const queryParams = this.bs.value.facets
            .map(facet => {
                if (Array.isArray(newFacet)) {
                    const updateFacet = newFacet.find(updatedFacet => {
                        return facet.key === updatedFacet.key;
                    });

                    return typeof updateFacet !== 'undefined'
                        ? updateFacet
                        : facet;
                } else {
                    return newFacet.key === facet.key ? newFacet : facet;
                }
            })
            .map(facet => this.getFacetParams(facet))
            .reduce((target, source) => ({ ...target, ...source }));

        this.router.navigate([], { replaceUrl: !keepInHistory, queryParams });
    }

    public resetFilter(facets: Facet[]): void {
        const sorting = facets.find(facet => {
            return facet.kind === 'sort';
        });

        const search = facets.find(facet => {
            return facet.kind === 'search';
        });

        const queryParams = {
            ...this.getFacetParams(sorting),
            ...this.getFacetParams(search),
        };

        this.router.navigate([], { replaceUrl: true, queryParams });
    }

    public setProductGridSize(gridState: IProductGridState): void {
        this.selectedGridState.next(gridState);
    }

    public getProductGridSize(): Observable<IProductGridState> {
        return this.selectedGridState.asObservable();
    }

    private getFacetParams(facet: Facet): Params {
        if (facet === undefined) {
            return {};
        }

        switch (facet.kind) {
            case 'Multicheck':
                return this.getMultiCheckBoxFacetParams(facet);
            case 'search':
                return this.getSearchFacetParams(facet);
            case 'sort':
                return this.getSortFacetParams(facet);
            case 'Slider':
                return this.getTwoSidedFacetParams(facet);
            case 'pagination':
                return this.getPageIndexParams(facet);
            default:
                return {};
        }
    }

    private getPageIndexParams(facet: PaginationFacet): Params {
        return assignToValueProperty(
            {},
            {
                key: facet.key,
                value: facet.pageIndex,
                isActive: facet.pageIndex > 0,
            }
        );
    }
    private getMultiCheckBoxFacetParams(facet: MultiCheckBoxFacet): Params {
        return facet.children
            .map(child => {
                return {
                    key: facet.key,
                    value: child.key,
                    isActive: child.isActive,
                };
            })
            .reduce<Params>(assignToArrayProperty, {});
    }

    private getSearchFacetParams(facet: SearchFacet): Params {
        return assignToValueProperty({}, facet);
    }

    private getSortFacetParams(facet: SortFacet): Params {
        return facet.children
            .map(child => {
                return {
                    key: facet.key,
                    value: child.key,
                    isActive: child.isActive,
                };
            })
            .reduce<Params>(assignToValueProperty, {});
    }

    private getTwoSidedFacetParams(facet: TwoSidedSliderFacet): Params {
        return assignToRangeProperty(
            {},
            {
                key: facet.key,
                from: facet.currentMin,
                to: facet.currentMax,
                isActive: facet.isActive,
            }
        );
    }

    private pushParamsToFilterState(
        url: string,
        params: Params,
        initialLoad = false
    ): void {
        const newParams = new HttpParams({ fromObject: {...params, ...this.mandatoryParams} }).set(
            'id',
            this.filterPageId
        );
        this.http.get(url, { params: newParams }).subscribe((data: any) => {
            this.bs.next(this.toFilterModel(data, params, initialLoad));

            const searchFacet = data.Facets.find(facet => {
                return facet.kind === 'search';
            });

            if (initialLoad && searchFacet && searchFacet.value) {
                const totalUnfilteredResults =
                    data.UnFilteredTotalDocumentsFound;

                this.tracking.sendSearchResults(
                    searchFacet.value,
                    totalUnfilteredResults,
                    false
                );
            }
        });
    }

    private toFilterModel(
        data: any,
        params,
        isInitialLoad = false
    ): IFilter<T> {
        data.Facets = data.Facets.map(facet => {
            facet.FacetResults = facet.FacetResults.map(result => {
                result.IsSelected =
                    result.IsSelected !== undefined ? result.IsSelected : false;
                return result;
            });
            return facet;
        });

        const sorting: SortFacet = {
            kind: 'sort',
            key: 'SortBy',
            children: data.SortBy
                ? data.SortBy.map(sort => {
                      return {
                          key: sort,
                          isActive: params.SortBy === sort,
                          name: sort,
                      };
                  })
                : [],
        };

        const pagination: PaginationFacet = {
            kind: 'pagination',
            key: 'pageIndex',
            isActive: data.PageIndex > 0, // Need to find a way to set this from elsewhere
            pageSize: data.PageSize,
            pageIndex: data.PageIndex,
            hasNextPage: data.HasNextPage,
        };

        const search: SearchFacet = {
            kind: 'search',
            key: 'search',
            isActive: this.queryParams.hasOwnProperty('search'),
            value: this.queryParams.hasOwnProperty('search')
                ? this.queryParams.search
                : null,
        };

        const facets: Facet[] = [pagination, sorting];

        if (this.queryParams.hasOwnProperty('search')) {
            facets.push(search);
        }

        const visualFacets: VisualFacet[] = data.Facets.map(facet => {
            switch (facet.Control) {
                case 'Multicheck':
                    const children = (facet.FacetResults as any[]).map(
                        facetResult => {
                            const child: MultiCheckBoxFacetChild = {
                                count: facetResult.Count,
                                isActive: facetResult.IsSelected,
                                key: facetResult.Query.Value,
                                name: facetResult.Query.Name,
                                hexColor: facetResult.ColorHexCodes,
                                isSubFacet: facetResult.IsSubFacet
                            };
                            return child;
                        }
                    );

                    const popularChildren = ((facet.PopularFacetResults ||
                        []) as any[]).map(facetResult => {
                        const popChild: MultiCheckBoxFacetChild = {
                            count: facetResult.Count,
                            isActive: facetResult.IsSelected,
                            key: facetResult.Query.Value,
                            name: facetResult.Query.Name,
                            hexColor: facetResult.ColorHexCodes,
                            isSubFacet: facetResult.IsSubFacet
                        };
                        return popChild;
                    });

                    return {
                        kind: facet.Control,
                        key: facet.Key,
                        name: facet.Name,
                        isActive: facet.IsActive,
                        children,
                        popularKey: facet.PopularName,
                        popularChildren,
                    };
                case 'Slider':
                    return {
                        kind: facet.Control,
                        key: facet.Key,
                        name: facet.Name,
                        isActive: facet.IsActive,
                        min: facet.Min,
                        max: facet.Max,
                        currentMin: facet.CurrentMin,
                        currentMax: facet.CurrentMax,
                    };
            }
        });

        const result: IFilter<T> = {
            exposedProducts: isInitialLoad ? data.ExposedProducts : [],
            entities: data.Products,
            initialLoad: isInitialLoad,
            pageIndex: data.PageIndex,
            facets: [...facets, ...visualFacets],
            totalEntityCount: data.TotalDocumentsFound,
            smartFilterDisplayOrder: data.FilterDisplayOrder,
        };
        return result;
    }

    // TODO: fix!!
    private asHttpParams(params: Params): HttpParams {
        const returnParams = params as HttpParams;
        return returnParams;
    }

    // TODO: fix!!
    public getFilterConfig(filterConfigs: IFilterConfig[]): any {
        return filterConfigs[0];
    }

    private getEmptyFilter(): IFilter<T> {
        return {
            facets: [],
            entities: [],
            initialLoad: true,
            pageIndex: 0,
            totalEntityCount: 0,
            exposedProducts: [],
        };
    }
}
export interface IProductGridState {
    gridState: string;
}
