import { finalize, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { GetOrdersForUserArgs as getOrdersForUserArgs } from '@shared/swagger/easypos.interface';
import { UserService } from '@core/user.service';
import { AuthenticationService } from '@core/authentication.service';
import { TranslationService } from '@core/translation.service';
import { SpinnerService } from '@shared/spinner/spinner.service';

@Injectable()
export class CustomerService {
    constructor(
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private translationService: TranslationService,
        private spinnerService: SpinnerService
    ) {}

    private env: string = environment.easyposRoot;

    private receipts = new BehaviorSubject<any>(null); // TODO implement swagger types for receipts

    receipts$ = this.receipts.asObservable();

    /**
     * Fetch receipts for user when logged in
     */
    fetchReceipts(user: getOrdersForUserArgs) {
        this.spinnerService.show('receiptsSpinner');

        this.httpClient
            .post(`${this.env}/api/Order/RetriveOrdersForUser`, user)
            .pipe(
                map((receipts: any[]) => receipts.slice(0, 10)),
                map((receipts: any[]) => {
                    receipts.map((data: any) => {
                        data.Created = this.dateFormat(data.Created);
                        data.Completed = this.dateFormat(data.Completed);
                    });
                    return receipts;
                }),
                finalize(() => this.spinnerService.hide('receiptsSpinner'))
            )
            .subscribe(data => this.receipts.next(data));
    }

    private dateFormat(date: string) {
        return new Date(date).toLocaleDateString();
    }
}
