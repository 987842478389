import { Directive, Input } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    NgModel,
    ValidationErrors,
    Validator,
} from '@angular/forms';

@Directive({
    selector: '[appMatchInputs]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MatchInputsDirective,
            multi: true,
        },
    ],
})
export class MatchInputsDirective implements Validator {
    @Input('appMatchInputs') inputToMatch: NgModel;

    validate(control: AbstractControl): ValidationErrors | null {
        return this.inputToMatch.model !== control.value
            ? { emailsNotMatched: true }
            : null;
    }
}
