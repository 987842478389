import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from '@core/translation.service';
import {
    ImpactCoreStoresPeriod,
    ImpactCoreStoresStoreSpecialOpeningHour,
} from '@shared/swagger/swagger.interface';
import { SiteContextService } from '@core/site-context.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-opening-hours',
    templateUrl: './opening-hours.component.html',
})
export class OpeningHoursComponent implements OnInit, OnDestroy {
    constructor(
        private translationService: TranslationService,
        private siteContextService: SiteContextService,
    ) {
        this.translationService.translations$
            .pipe(takeUntil(this.destroy))
            .subscribe(translations => this.translations = translations.Store.StorePage);
    }

    private destroy = new Subject();

    @Input() openingPeriods: ImpactCoreStoresPeriod;
    @Input() specialOpenings: ImpactCoreStoresStoreSpecialOpeningHour[];

    translations: any;
    dayNames: {[p: number]: string};
    toDay: number = new Date(Date.now()).getDay();

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    ngOnInit() {
        this.dayNames = {
            0: this.translations.DayOfWeekSunday,
            1: this.translations.DayOfWeekMonday,
            2: this.translations.DayOfWeekTuesday,
            3: this.translations.DayOfWeekWednesday,
            4: this.translations.DayOfWeekThursday,
            5: this.translations.DayOfWeekFriday,
            6: this.translations.DayOfWeekSaturday,
        };
    }
}
