<div class="filter-facets__slider">
    <label for="min" class="filter-facets__slider__label">{{(translationService.translations$ | async)?.Filter?.PriceFacet?.MinimumPriceRangeLabel}}</label>
    <input type="number"
           placeholder="0"
           id="min"
           #min
           class="filter-facets__slider__input"
           (input)="updateSlider()"
           [(ngModel)]="slider.min">
    <div class="filter-facets__slider__separator">-</div>
    <label for="max" class="filter-facets__slider__label">{{(translationService.translations$ | async)?.Filter?.PriceFacet?.MaximumPriceRangeLabel}}</label>
    <input type="number"
           [placeholder]="facet.max"
           id="max"
           class="filter-facets__slider__input"
           #max
           [(ngModel)]="slider.max"
           (input)="updateSlider()">
    <div class="filter-facets__slider__currency">
        {{currency}}
    </div>
</div>
