import { BasketService } from '@core/basket.service';
import { Component } from '@angular/core';

@Component({
    selector: '[app-minibasket-badge]',
    template: `{{(basketService.basket$ | async)?.TotalItemCount}}`,
})
export class MinibasketBadgeComponent {
    constructor(public basketService: BasketService) {}
}
