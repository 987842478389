import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-trust-pilot-spot',
    templateUrl: './trust-pilot-spot.component.html',
})
export class TrustPilotSpotComponent {
    static ref = 'Trust Pilot spot';

    @Input()
    data: any;
}
