import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { checkmark, expand, mail } from '@shared/svg';
import {
    ImpactCoreStoresStoreServiceModel as StoreModel,
    ImpactCoreModelsProductsDtoColorGroupDto as ColorGroupModel, ImpactCoreStoresStoreServiceModel,
} from '@shared/swagger/swagger.interface';
import { AnimationEvent } from '@angular/animations';
import { IMPACT_OVERLAY_DATA, OverlayService } from '@impactdk/ngx-overlay';
import { TranslationService } from '@core/translation.service';
import { PageService } from '@core/page.service';
import { ProductUtilityService } from '@core/productUtility.service';
import { StoreService } from '@core/store.service';
import {
    IWatchProductArgs,
    WatchProductService,
} from '@features/product-page/watch-product/watch-product.service';
import { take } from 'rxjs/operators';
import { RegexService } from '@core/regex.service';
import { TrackingService } from '@core/tracking/tracking.service';

interface IStore extends Omit<IWatchProductArgs, 'store'> {
    store: ImpactCoreStoresStoreServiceModel;
}

@Component({
    selector: 'app-watch-product',
    templateUrl: './watch-product.component.html',
})
export class WatchProductComponent implements OnInit {
    stores: StoreModel[];
    expandIcon = expand;
    activeColorGroup: ColorGroupModel;
    selectedSize: string;
    translations: any;
    pageData: any;
    selectedColorId: string;
    model: IStore;
    productWatchRequestModel: IWatchProductArgs;
    icons: any = {
        mail,
        checkmark,
    };
    isProductOnSales: boolean;
    introText: string;

    @ViewChild('form') form: any;

    // Trigger animation
    public animationStateChanged = new EventEmitter<AnimationEvent>();
    public startExitTrigger = new Subject();
    public responseSuccess = false;
    public responseFailed = false;

    constructor(
        private overlay: OverlayService,
        @Inject(IMPACT_OVERLAY_DATA) public data: any,
        private translationService: TranslationService,
        private pageDataService: PageService,
        private productUtilityService: ProductUtilityService,
        private storeService: StoreService,
        private watchProductService: WatchProductService,
        public regexService: RegexService,
        private tracking: TrackingService
    ) {}

    ngOnInit() {
        // Gets the page data
        this.pageDataService.page.pipe(take(1)).subscribe(data => {
            this.pageData = data.pageData;
        });

        // Initial forms model
        this.model = {
            skuNumber: this.data.selectedSize ? this.data.selectedSize.Id : '',
            store: {},
            receiverEmail: '',
        };

        const activeColorGroup = this.data.product.ColorGroups.filter(colorGroup => colorGroup.ColorId === this.data.product.ActiveColorGroupId).pop();
        this.isProductOnSales = activeColorGroup.SalePrice > 0;

        this.selectedColorId = this.data.selectedColorId;

        this.introText = this.isProductOnSales ? this.pageData?.StockAgentTextOnSale : this.pageData?.StockAgentText;


        this.translations = this.translationService.translations$;

        this.activeColorGroup = this.productUtilityService.getActiveColorGroup(
            this.data.product.ColorGroups,
            this.selectedColorId
        );

        this.storeService
            .getAllStores()
            .pipe(take(1))
            .subscribe(stores => {
                const onlineStoreDefaultOption = stores.filter(store => store.IsWebShop);

                if (!!onlineStoreDefaultOption) {
                    this.model.store = onlineStoreDefaultOption[0];
                }

                this.stores = stores;
            });

        if (this.selectedSize !== '') {
            this.changeSize();
        }
    }

    changeSize(): void {
        this.responseFailed = false;
    }

    onSubmit(): void {
        if (this.form.valid) {
            const reqestModel: IWatchProductArgs = {
                store: this.model.store.StoreImportEasyposId,
                skuNumber: this.model.skuNumber,
                receiverEmail: this.model.receiverEmail
            };
            this.watchProductService
                .submitProductWatch(reqestModel)
                .subscribe(data => {
                    this.responseSuccess = true;

                    this.tracking.sendKeepAnEyeOpenSend(
                        this.data.product.Name,
                        this.model.store.Name
                    );
                });
        } else {
            this.responseFailed = true;
        }
    }

    public animationStateChange(event): void {
        this.animationStateChanged.emit(event);
    }

    public startExitAnimation(): void {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }

    doSomething(event) {
        this.animationStateChanged.emit(event);
    }

    closeSuccessNotification = () => {
        this.responseSuccess = false;
    };
    closeFailedNotification = () => {
        this.responseFailed = false;
    };
}
