import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SiteContextService } from '@core/site-context.service';

@Injectable()
export class ArticleService {
    private env: string = this.siteContextService.getRootUrl();

    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService
    ) {}

    getArticlesBySpotId(
        id: string,
        raptorCookieValue: string,
        productId: string,
        pageId: string
    ): Observable<any> {
        return this.http.post(`${this.env}/webapi/Article/ArticlesBySpotId`, {
            Id: id,
            RaptorCookieValue: raptorCookieValue,
            ProductId: productId,
            PageId: pageId,
        });
    }
}
