import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {MinibasketVoucherComponent} from '@features/minibasket/minibasket-voucher/minibasket-voucher.component';
import {MoveToHeadDirective} from '@shared/move-to-head.directive';
import {SafeStylePipe} from '@shared/safe-style.pipe';
import {ScrollbarModule} from '@shared/scrollbar/scrollbar.module';
import {SpinnerModule} from '@shared/spinner/spinner.module';
import {TrackingDirective} from '@shared/tracking.directive';
import {AccordionComponent} from './accordion/accordion.component';
import {AlertComponent} from './alert/alert.component';
import {AutofocusDirective} from './autofocus.directive';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {InViewDirective} from './directives/in-view.directive';
import {LowerCaseInputDirective} from './directives/lower-case-input.directive';
import {DynamicLinksDirective} from './dynamic-links.directive';
import {MultiCheckBoxFacetComponent} from './filter/facets/multi-check-box-facet/multi-check-box-facet.component';
import {HorizontalMouseScrollDirective} from './horizontal-mousescroll.directive';
import {IRouterLinkDirective} from './i-router-link.directive';
import {InlineSvgDirective} from './inline-svg.directive';
import {InterceptLinksDirective} from './intercept-links.directive';
import {JsonLdComponent} from './json-ld/json-ld.component';
import {NoSSRDirective} from './no-ssr.directive';
import {OpenLinkInOverlayDirective} from './open-link-in-overlay.directive';
import {BrandUrlFormatPipe} from './pipes/brand-url-format/brand-url-format.pipe';
import {KfmCurrencyPipe} from './pipes/currency/kfm-currency.pipe';
import {FirstDayOfWeekPipe} from './pipes/first-day-of-week/first-day-of-week.pipe';
import {HighlightPipe} from './pipes/highlight/highlight.pipe';
import {ShowSizesPipe} from './pipes/show-sizes/show-sizes.pipe';
import {SplitFormatPipe} from './pipes/split-format/split-format.pipe';
import {StringFormatUrlInOverlayPipe} from './pipes/string-format/string-format-url.pipe';
import {StringFormatPipe} from './pipes/string-format/string-format.pipe';
import {TimeSeperatorPipe} from './pipes/time-seperator/time-seperator.pipe';
import {ResetInputDirective} from './reset-input/reset-input.directive';
import {SafeHtmlPipe} from './safe-html.pipe';
import {SpotHeadingSeoComponent} from './spot-heading-seo/spot-heading-seo.component';
import {TooltipComponent} from './tooltip/tooltip.component';
import {LinkTargetDirective} from './link-target.directive';
import {SkuInStockPipe} from './pipes/sku-in-stock/sku-in-stock.pipe';
import {ConsoleLogPipe} from './debug/console-log.pipe';
import {XLargeReformatPipe} from './pipes/x-large-reformat/x-large-reformat.pipe';
import {PreventPasteDirective} from './directives/prevent-paste.directive';
import {MatchInputsDirective} from './directives/match-inputs.directive';
import {SvgComponent} from './svg/svg.component';
import {ShowOnSiteDirective} from './directives/show-on-site.directive';
import {DeliveryTextComponent} from './delivery-text/delivery-text.component';
import {TransformToLineBreaksPipe} from './pipes/transform-to-line-breaks/transform-to-line-breaks.pipe';
import {ProductSplashComponent} from './product-splash/product-splash.component';
import {ColorGroupVariationsComponent} from './color-group-variations/color-group-variations.component';
import {ProductSizePickerComponent} from './product-size-picker/product-size-picker.component';
import {AddToBasketStandaloneComponent} from './add-to-basket-standalone/add-to-basket-standalone.component';
import { FilterFacetsPipe } from './pipes/filter-facets/filter-facets.pipe';
import { NotificationComponent } from './notification/notification.component';

@NgModule({
    declarations: [
        NoSSRDirective,
        InterceptLinksDirective,
        SafeHtmlPipe,
        SafeStylePipe,
        InlineSvgDirective,
        MultiCheckBoxFacetComponent,
        AccordionComponent,
        AlertComponent,
        KfmCurrencyPipe,
        ShowSizesPipe,
        ResetInputDirective,
        BreadcrumbComponent,
        HighlightPipe,
        MinibasketVoucherComponent,
        SplitFormatPipe,
        StringFormatPipe,
        StringFormatUrlInOverlayPipe,
        FirstDayOfWeekPipe,
        TimeSeperatorPipe,
        IRouterLinkDirective,
        SpotHeadingSeoComponent,
        TrackingDirective,
        DynamicLinksDirective,
        JsonLdComponent,
        MoveToHeadDirective,
        TooltipComponent,
        LowerCaseInputDirective,
        BrandUrlFormatPipe,
        InViewDirective,
        OpenLinkInOverlayDirective,
        AutofocusDirective,
        HorizontalMouseScrollDirective,
        LinkTargetDirective,
        SkuInStockPipe,
        ConsoleLogPipe,
        XLargeReformatPipe,
        PreventPasteDirective,
        MatchInputsDirective,
        SvgComponent,
        ShowOnSiteDirective,
        DeliveryTextComponent,
        TransformToLineBreaksPipe,
        ProductSplashComponent,
        ColorGroupVariationsComponent,
        ProductSizePickerComponent,
        AddToBasketStandaloneComponent,
        FilterFacetsPipe,
        NotificationComponent,
    ],
    imports: [
        CommonModule,
        MatSidenavModule,
        MatTooltipModule,
        FormsModule,
        SpinnerModule,
        RouterModule,
        ScrollbarModule,
    ],
    providers: [DecimalPipe],
    exports: [
        CommonModule,
        NoSSRDirective,
        InterceptLinksDirective,
        InlineSvgDirective,
        SafeHtmlPipe,
        AccordionComponent,
        MultiCheckBoxFacetComponent,
        MatSidenavModule,
        FormsModule,
        AlertComponent,
        KfmCurrencyPipe,
        ShowSizesPipe,
        SpinnerModule,
        ResetInputDirective,
        BreadcrumbComponent,
        ScrollbarModule,
        HighlightPipe,
        MinibasketVoucherComponent,
        SafeStylePipe,
        SplitFormatPipe,
        StringFormatPipe,
        StringFormatUrlInOverlayPipe,
        FirstDayOfWeekPipe,
        TimeSeperatorPipe,
        IRouterLinkDirective,
        SpotHeadingSeoComponent,
        TrackingDirective,
        DynamicLinksDirective,
        JsonLdComponent,
        MoveToHeadDirective,
        TooltipComponent,
        LowerCaseInputDirective,
        BrandUrlFormatPipe,
        OpenLinkInOverlayDirective,
        InViewDirective,
        AutofocusDirective,
        HorizontalMouseScrollDirective,
        LinkTargetDirective,
        SkuInStockPipe,
        ConsoleLogPipe,
        XLargeReformatPipe,
        PreventPasteDirective,
        MatchInputsDirective,
        SvgComponent,
        ShowOnSiteDirective,
        DeliveryTextComponent,
        TransformToLineBreaksPipe,
        ProductSplashComponent,
        ColorGroupVariationsComponent,
        ProductSizePickerComponent,
        AddToBasketStandaloneComponent,
        FilterFacetsPipe,
        NotificationComponent,
    ],
})

export class SharedModule {}
