import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appResetInput]',
    host: {
        '(blur)': 'onBlur($event)',
        '(change)': 'onChange($event)',
    },
})
export class ResetInputDirective implements OnInit, OnDestroy {
    constructor(
        private formControl: NgControl,
        private elementRef: ElementRef
    ) {}

    @Input() observe: boolean = false;

    private destroy: Subject<any> = new Subject();

    ngOnInit() {
        if (this.observe) {
            this.formControl.valueChanges
                .pipe(takeUntil(this.destroy))
                .subscribe(data => {
                    if (data) {
                        // this.formControl.control.markAsTouched();
                        this.formControl.control.markAsDirty();
                    }
                });
        }
    }

    onBlur($event: Event) {
        const element = $event.currentTarget as
            | HTMLInputElement
            | HTMLSelectElement;

        if (element.value.length === 0) {
            this.formControl.control.markAsPristine();
        }
    }

    onChange($event: Event) {
        const element = $event.currentTarget as
            | HTMLInputElement
            | HTMLSelectElement;

        if (element.value.length === 0) {
            this.formControl.control.markAsPristine();
        } else {
            this.formControl.control.markAsTouched();
            this.formControl.control.markAsDirty();
        }
    }

    ngOnDestroy() {
        if (this.observe) {
            this.destroy.next();
            this.destroy.complete();
        }
    }
}
