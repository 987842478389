import { AnimationEvent } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { StickyService } from '@core/sticky.service';
import { rightpaneAnimations } from '@features/rightpane/rightpane.animation';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { arrowRight90, close } from '@shared/svg';
import { Subject, Subscribable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { IMobileFilterState, MobileFilterStateService } from '@core/mobile-filter-state.service';

@Component({
    selector: 'app-rightpane',
    animations: rightpaneAnimations,
    template: `
        <aside
            class="rightpane"
            [@rightpaneAnimation]="animationState"
            (@rightpaneAnimation.start)="onAnimationStart($event)"
            (@rightpaneAnimation.done)="onAnimationDone($event)"
            #pane
        >
            <button *ngIf='showFacetBackButton.show' class='button button_clean facet-back-button' (click)="closeOverlay()"><app-svg [src]='backArrow' [size]="'small'"></app-svg></button>
            <button
                *ngIf="showCloseMobile && !showFacetBackButton.show"
                class="button button_clean rightpane_close"
                (click)="closeDialog()"
            >
                ${close}
            </button>
            <ng-content></ng-content>
        </aside>
    `,
})
export class RightpaneComponent implements OnInit, OnDestroy {
    animationState: 'void' | 'enter' | 'leave' = 'enter';
    @Output() animationStateChanged = new EventEmitter<AnimationEvent>();
    @Input() startExit: Subscribable<any>;
    @Input() showCloseMobile = true;

    @ViewChild('pane', { static: true }) paneElement;
    private destroy;

    showFacetBackButton: IMobileFilterState;
    backArrow = arrowRight90;

    constructor(
        public overlayRef: ImpactOverlayRef,
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private _document,
        private stickyService: StickyService,
        private breakpointObserver: BreakpointObserver,
    private mobileFilterStateService: MobileFilterStateService
    ) {
        this.mobileFilterStateService.backButtonState$.subscribe(state => this.showFacetBackButton = state);
    }

    closeOverlay() {
        this.mobileFilterStateService.backButtonState$.next({...this.showFacetBackButton, show: false});
    }

    onAnimationStart(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    onAnimationDone(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.animationState = 'leave';
    }

    closeDialog() {
        this.overlayRef.close();
    }

    ngOnInit() {
        this.destroy = new Subject();
        this.startExit.subscribe(() => {
            this.startExitAnimation();
        });

        this.breakpointObserver
            .observe(Breakpoints.Handset)
            .subscribe(result => {
                if (!result.matches) {
                    this.showCloseMobile = true;
                }
            });
        /*this.stickyService.stickyEvent
            .pipe(takeUntil(this.destroy))
            .subscribe(event => {
                this.setPosition(event.headerBottom);
            });*/
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    setPosition(headerBottom) {
        const headerHeight = headerBottom;
        let paneMargin = headerHeight + 'px';

        this.paneElement.nativeElement.style.marginTop = paneMargin;
        this.paneElement.nativeElement.style.height = `calc(100% - ${paneMargin}`;
    }

    protected readonly arrowRight90 = arrowRight90;
}
