import { Component, Input, OnInit } from '@angular/core';
import { arrowRight } from '@shared/svg';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-brandlist',
    templateUrl: './brandlist.component.html',
})
export class BrandlistComponent implements OnInit {
    arrow = arrowRight;

    @Input() data: any;

    static ref: string = 'Brand list spot';

    constructor(public translation: TranslationService) {}

    ngOnInit() {}
}
