import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SiteContextService } from '@core/site-context.service';

@Injectable()
export class WatchProductService {
    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService
    ) {}

    submitProductWatch(watchProduct: IWatchProductArgs) {
        return this.http.post<any>(
            `${this.siteContextService.getRootUrl()}/webapi/stockagentservice/addsku`,
            watchProduct
        );
    }
}
export interface IWatchProductArgs {
    receiverEmail: string;
    skuNumber: string;
    store: string;
}
