import {
    Directive,
    Inject,
    PLATFORM_ID,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

/**
 * Disables rendering of DOM element on the server.
 *
 * Usage:
 *
 *  ```<div *noSSR>
 *      This content will not be rendered by the server!
 *  </div>```
 */
@Directive({ selector: '[noSSR]' })
export class NoSSRDirective {
    /**
     * Constructs and injacts services
     * @param templateRef
     * @param viewContainer
     * @param platformId  Platform ID used to see if we are on server, provided by Angular
     */
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        if (isPlatformServer(this.platformId)) {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
