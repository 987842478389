import { HomePageComponent } from '@features/pages/home-page.component';
import { StandardPageComponent } from '@features/pages/standard-page.component';
import { SubpageSidebarComponent } from '@features/pages/subpage-sidebar.component';
import { FilterPageComponent } from '@features/pages/filter-page.component';
import { CustomerPageComponent } from '@features/pages/customer-page.component';
import { SignupPageComponent } from '@features/pages/signup-page.component';
import { StorePageComponent } from '@features/pages/store-page.component';
import { ProductPageComponent } from '@features/pages/product-page.component';
import { CheckoutPageComponent } from '@features/pages/checkout-page.component';
import { BasketPageComponent } from '@features/pages/basket-page.component';
import { BrandFilterPageComponent } from '@features/pages/brand-filter-page.component';

/**
 * Possible page components
 */
export const pageComponents = [
    HomePageComponent,
    StandardPageComponent,
    SubpageSidebarComponent,
    FilterPageComponent,
    CustomerPageComponent,
    SignupPageComponent,
    StorePageComponent,
    ProductPageComponent,
    CheckoutPageComponent,
    BasketPageComponent,
    BrandFilterPageComponent,
];
