<div class="basket-overview">
    <header class="basket-overview__header">
        {{(translationService.translations$ | async)?.Minibasket?.Overview?.YourOrder}}
    </header>

    <div class="basket-overview__overview">
        <div class="minibasket-overview">
            <div class="minibasket-overview-info">
                <span class="minibasket-overview-info__label">
                    {{(translationService.translations$ | async)?.Minibasket?.Overview?.PriceTotal}} ({{basket.TotalItemCount}}
                    <ng-container *ngIf="basket.TotalItemCount > 1">{{(translationService.translations$ | async)?.Minibasket?.Overview?.TotalProducts}}</ng-container>
                    <ng-container *ngIf="basket.TotalItemCount === 1">{{(translationService.translations$ | async)?.Minibasket?.Overview?.TotalProduct}}</ng-container>)
                </span>
                <span class="minibasket-overview-info__price">{{(basket.LinesTotalBeforePrice || basket.LinesTotalPrice)
                    | kfmCurrency: basket.Currency}}
                </span>
            </div>

            <ng-container *ngIf="basket.LinesTotalBeforePrice">
                <div class="minibasket-overview-info minibasket-overview-info_discount">
                    <span class="minibasket-overview-info__label">{{(translationService.translations$ | async)?.Minibasket?.Overview?.YouSave}}</span>
                    <span class="minibasket-overview-info__price minibasket-overview-info_discount">{{basket.LinesTotalRebate | kfmCurrency: basket.Currency:true}}
                    </span>
                </div>
            </ng-container>

            <ng-container *ngIf="basket.GiftCards?.length > 0">
                <div class="minibasket-overview-info minibasket-overview-info_discount" *ngFor="let giftCard of basket.GiftCards">
                    <span class="minibasket-overview-info__label">{{(translationService.translations$ | async)?.Minibasket?.Overview?.GiftCard}}</span>
                    <span class="minibasket-overview-info__price">{{giftCard.Amount | kfmCurrency: basket.Currency: true}}
                    </span>
                </div>
            </ng-container>

            <div class="minibasket-overview-info minibasket-overview-info_discount" *ngIf="basket.VoucherCode">
                <span class="minibasket-overview-info__label">{{(translationService.translations$ | async)?.Minibasket?.Overview?.Voucher}}:
                    {{basket.VoucherCode}}
                </span>
                <span class="minibasket-overview-info__price">{{basket.VoucherBalance.replace(' ', '') | kfmCurrency: basket.Currency}}
                </span>
            </div>
            <div class="minibasket-overview-info">
                <span class="minibasket-overview-info__label">{{(translationService.translations$ | async)?.Minibasket?.Overview?.Delivery}}</span>
                <span class="minibasket-overview-info__price">{{basket.DeliveryPrice | kfmCurrency: basket.Currency}}</span>
            </div>

            <ng-container *ngIf="basket.ShowPaymentFee">
                <div class="minibasket-overview-info">
                    <span class="minibasket-overview-info__label">{{(translationService.translations$ | async)?.Minibasket?.Overview?.PaymentFee}}</span>
                    <span class="minibasket-overview-info__price">{{basket.PaymentFee | kfmCurrency: basket.Currency}}</span>
                </div>
            </ng-container>

            <div class="minibasket-overview__total">
                <div class="minibasket-overview-info">
                    <span class="minibasket-overview-info__label minibasket-overview-info__label_large">
                        {{(translationService.translations$ | async)?.Minibasket?.Overview?.PriceTotal}}
                        <span class="minibasket-overview-info__vat">{{(translationService.translations$ | async)?.Minibasket?.Overview?.InclVat}}</span>
                    </span>
                    <span class="minibasket-overview-info__price">{{basket.TotalPrice | kfmCurrency: basket.Currency}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="basket-overview__action">
        <a [routerLink]="siteContextService.context?.checkoutSettings?.GoToCheckoutLink" class="button button_primary button_full button_icon">
            <app-svg class="" src="arrow-right" [inline]="true"></app-svg>
            {{siteContextService.context?.checkoutSettings?.GoToCheckoutLinkText}}
        </a>
    </div>

    <app-minibasket-voucher [basket]="basket"></app-minibasket-voucher>
</div>
