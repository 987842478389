import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-order-receipt-spot',
    templateUrl: './order-receipt-spot.component.html',
})
export class OrderReceiptSpotComponent {
    @Input()
    data: any;

    static ref: string = 'Order receipt spot';
}
