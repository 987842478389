import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPageComponent } from '@features/info/info-page.component';
import { InfoPageContentComponent } from '@features/info/info-page-content.component';

@NgModule({
    imports: [CommonModule],
    declarations: [InfoPageComponent, InfoPageContentComponent],
    exports: [InfoPageComponent, InfoPageContentComponent],
    entryComponents: [InfoPageComponent, InfoPageContentComponent],
})
export class InfoModule {}
