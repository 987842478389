import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeSeperator',
})
export class TimeSeperatorPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        const timeHours = value.substring(0, 2);
        const timeMinutes = value.substring(2, 4);
        const timeFormatted = ''.concat(timeHours, ':', timeMinutes);
        return timeFormatted;
    }
}
