import {
    animate,
    group,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

const ANIMATION_TIMINGS_ENTER = '400ms cubic-bezier(0, 0, 0, 1)';
const ANIMATION_TIMINGS_LEAVE = '200ms cubic-bezier(0.25, 0, 0.5, 1)';

export const rightpaneAnimations = [
    trigger('rightpaneAnimation', [
        state('void', style({ transform: 'translateX(100%)' })),
        state('enter', style({ transform: 'translateX(0)' })),
        state('leave', style({ transform: 'translateX(100%)' })),
        transition('* => enter', [animate(ANIMATION_TIMINGS_ENTER)]),
        transition('* => leave', [
            group([group([animate(ANIMATION_TIMINGS_LEAVE)])]),
        ]),
    ]),
];
