import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import { BasketService } from '@core/basket.service';
import { ProductService } from '@core/product.service';
import { TranslationService } from '@core/translation.service';
import { ProductSizeVariant } from '@features/product-filter/models/product.model';
import { arrowRight, arrowRight90 } from '@shared/svg';
import { ResponsiveService } from '@core/responsive.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export enum ProductAddToBasketButtonStates {
    IDLE = 'idle',
    BUSY = 'busy',
    DONE = 'done',
    ERROR = 'error',
    DISABLED = 'disabled'
}
@Component({
    selector: 'app-product-size-picker-button',
    template: `
    <button class="product-size-picker-button button button_clean button_full" type="button"
        (click)="buttonClicked()"
        [ngSwitch]="selectedSize !== null">
        <span *ngSwitchCase="true">{{ selectedSize?.SizeLabel }}</span>
        <span *ngSwitchCase="false">{{ isMobileSmall ? translations?.ProductDetails?.SideBarSizePicker?.SelectSizeMobileLabel : translations?.ProductDetails?.SideBarSizePicker?.SelectSizeLabel }}</span>

        <div class="svg-icon" aria-hidden="true" [innerHtml]="arrowRight90 | safeHtml"></div>

    </button>
    `,
})
export class ProductSizePickerButtonComponent implements OnDestroy {
    @Output() callbackMethod: EventEmitter<any> = new EventEmitter();

    private destroy: Subject<any> = new Subject<any>();
    translations: any;
    disableSizePickerButton: boolean = false;
    selectedSize: ProductSizeVariant = null;

    arrowRight90 = arrowRight90;
    isMobileSmall: boolean;

    constructor(
        private translationService: TranslationService,
        private basketService: BasketService,
        private productService: ProductService,
        private responsiveService: ResponsiveService,
        ) {

        // Listen for small mobile devices
        this.responsiveService.mediaSmallMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe(breakpoints => this.isMobileSmall = breakpoints.matches);

        // Get translations
        this.translationService.translations$.subscribe(translations => {
            this.translations = translations;
        });

        // Listen for product size changes
        this.productService.currentlySelectedProductSizeVariant$.subscribe((selectedSizeVariant: ProductSizeVariant) => {
            this.selectedSize = selectedSizeVariant;
        });

        // Listen for basket update status
        this.basketService.basketUpdating$.subscribe((isUpdating: boolean) => {
            this.disableSizePickerButton = isUpdating;
        });

        // Listen for when product is added to basket
        this.productService.productAddedToBasket$.subscribe((isAdded: boolean) => {
            if (isAdded) {
                this.disableSizePickerButton = false;
                // this.selectedSize = null;
            }
        });
    }

    ngOnInit(): void {
        // this.disableSizePickerButton = this.productService.activeProduct.activeProductVariant.isSoldOut;
    }

    buttonClicked() {
        if (!this.disableSizePickerButton) {
            this.callbackMethod.next();
        }

    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }
}





