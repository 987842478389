<div class="brandlist">
    <div class="brandlist__subtitle">
        <div class="container container_padded">
            <h1 class="headline brandlist__heading">
                {{(translation.translations$ | async)?.BrandList?.List?.Title}}
            </h1>
        </div>
    </div>
    
    <ul class="brandlist__group" *ngFor="let brandList of data.BrandList"> 
        <li class="brandlist__alphabet"><span>{{brandList.Alphabet}}</span></li>
        <li class="brandlist__name" *ngFor="let brand of brandList.Brands"><a [routerLink]="brand.BrandPageUrl">{{brand.BrandName}}
                <span class="brandlist__name__arrow" [innerHtml]="arrow | safeHtml"></span></a></li>
    </ul>
</div>