import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {arrowRight, checkmark, mail} from '@shared/svg';
import {TranslationService} from '@core/translation.service';
import {NgForm} from '@angular/forms';
import {RegexService} from '@core/regex.service';
import {WishlistService} from '@core/wishlist.service';
import {
    ImpactCoreModelsProductsDtoStyleDto,
    ImpactWebsiteCodeWebApiControllersEmailControllerSendWishListArgs as SendWishListEmailRequestModel
} from '@shared/swagger/swagger.interface';
import {SpinnerService} from '@shared/spinner/spinner.service';
import {TrackingService} from '@core/tracking/tracking.service';
import { IFavoriteProductStore } from '@core/favorite.interface';

@Component({
    selector: 'app-wishlist-formula',
    templateUrl: './wishlist-formula.component.html',
})
export class WishlistFormulaComponent implements OnInit {
    mailIcon = mail;
    checkmarkIcon = checkmark;
    arrowRight = arrowRight;

    submitted = false;

    translations: any;
    values = {
        name: '',
        email: '',
        receiver_email: '',
    };
    captchaValid = false;
    captchaResponseKey = '';
    skuIds: string[] = [];
    emailSendSuccess = false;
    emailSendFailed = false;
    sending = false;

    @Input() private wishlist: IFavoriteProductStore[];

    constructor(
        private translationService: TranslationService,
        private cdRef: ChangeDetectorRef,
        public regexService: RegexService,
        private wishListService: WishlistService,
        private spinnerService: SpinnerService,
        private tracking: TrackingService
    ) {}

    ngOnInit() {
        this.translations = this.translationService.translations$;
        this.skuIds = this.wishlist.map(item => item.sku);
        // this.wishlist.forEach(prod => this.skuIds.push(`${prod.sku}`));
    }

    captchaResolved(captchaResponse: string) {
        this.captchaResponseKey = captchaResponse;
        this.captchaValid = true;
        this.cdRef.detectChanges();
    }

    captchaExpire() {
        this.captchaValid = false;
        this.captchaResponseKey = '';
        this.cdRef.detectChanges();
    }

    onSubmit(form: NgForm) {
        if (
            form.valid &&
            !this.submitted &&
            this.captchaValid &&
            !this.sending
        ) {
            this.emailSendFailed = false;
            this.emailSendSuccess = false;
            this.sending = true;

            const requestBody = new SendWishlistEmailModel(
                form.value.receiver_email,
                form.value.email,
                form.value.name,
                this.skuIds,
                this.captchaResponseKey
            );

            this.tracking.sendFavoriteListSend(this.skuIds.length);
            this.spinnerService.show('sendEmailSpinner');

            this.wishListService.sendWishlist(requestBody).subscribe(
                resp => {
                    this.submitted = true;
                    this.spinnerService.hide('sendEmailSpinner');
                    this.emailSendSuccess = true;
                    this.sending = false;
                },
                (error): any => {
                    this.spinnerService.hide('sendEmailSpinner');
                    this.emailSendFailed = true;
                    this.sending = false;
                }
            );
        }
    }

    closeAlertSuccess = () => {
        this.emailSendSuccess = false;
    }

    closeAlertFailed = () => {
        this.emailSendFailed = false;
    }
}

// tslint:disable-next-line:max-classes-per-file
class SendWishlistEmailModel implements SendWishListEmailRequestModel {
    ReceiverEmail: string;
    SenderEmail: string;
    SenderName: string;
    SkuIds: string[];
    UserId?: string;
    GoogleRecaptchaResponse: string;

    constructor(
        ReceiverEmail: string,
        SenderEmail: string,
        SenderName: string,
        SkuIds: string[],
        GoogleRecaptchaResponse: string
    ) {
        this.ReceiverEmail = ReceiverEmail;
        this.SenderEmail = SenderEmail;
        this.SenderName = SenderName;
        this.SkuIds = SkuIds;
        this.GoogleRecaptchaResponse = GoogleRecaptchaResponse;
    }
}
