import { Component, Input, OnInit } from '@angular/core';
import {
    MultiCheckBoxFacet,
    MultiCheckBoxFacetChild,
} from '@features/filter/models/facet.model';
import { FilterService } from '@features/filter/services/filter.service';

@Component({
    selector: 'app-multi-check-box-facet',
    templateUrl: './multi-check-box-facet.component.html',
})
export class MultiCheckBoxFacetComponent<T> implements OnInit {
    @Input()
    facet: MultiCheckBoxFacet;

    constructor(private filterService: FilterService<T>) {}

    ngOnInit() {}

    public onItemChange(
        item: MultiCheckBoxFacetChild,
        isChecked: boolean
    ): void {
        const newChildren = this.facet.children.map(child => {
            const newIsActive =
                child.key === item.key ? isChecked : child.isActive;

            return Object.assign(child, { isActive: newIsActive });
        });

        this.filterService.updateFilter(
            Object.assign(this.facet, { children: newChildren })
        );
    }
}
