<ng-container *ngIf="facet">
    <h2 class="smart-filter__headline">{{translations?.Filter?.Content?.SmartFilterChoose}} {{translations?.Filter?.Facets[facet.name]}}</h2>

    <ul class="smart-filter__facets-list">
        <li class="smart-filter__facets-list__item" *ngFor="let popular of childrenToShow(facet)">
            <button class="smart-filter__facets__button" type="button" (click)="selectItem(popular)">
                <span class="smart-filter__facets__button-name">{{popular.name}}</span> ({{popular.count}})
            </button>
        </li>
        <li class="smart-filter__facets-list__item show-more"
            *ngIf="facet.popularChildren.length < facet.children.length" [ngSwitch]="isDesktop">
            <button class="smart-filter__facets__show-more-button" *ngSwitchCase="true" (click)="showMore.emit(facet)">
                {{translations?.Filter?.Content?.ShowMoreFacets}}
            </button>
            <button class="smart-filter__facets__show-more-button" *ngSwitchCase="false" (click)="showMoreMobile.emit(facet)">
                {{translations?.Filter?.Content?.ShowMoreFacets}}
            </button>
        </li>
    </ul>
</ng-container>
