import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TwoSidedSliderFacet } from '@features/filter/models/facet.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SiteContextService } from '@core/site-context.service';
import {TranslationService} from '@core/translation.service';

@Component({
    selector: 'app-product-filter-slider',
    templateUrl: './product-filter-slider.component.html',
})
export class ProductFilterSliderComponent implements OnInit {
    @Input() facet: any;
    @Output() onChange = new EventEmitter();
    slider: Slider;

    update$ = new Subject();

    currency: string;

    constructor(
        private siteContextService: SiteContextService,
        public translationService: TranslationService
    ) {}

    ngOnInit() {
        this.currency = this.siteContextService.getContext().currency;
        this.slider = new Slider(this.facet);

        this.update$
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(slider => {
                this.onChange.emit(slider);
            });
    }

    updateSlider() {
        this.update$.next({
            currentMin: this.slider.min ? this.slider.min : 0,
            currentMax: this.slider.max ? this.slider.max : this.facet.max,
            isActive: true,
        });
    }
}

// tslint:disable-next-line: max-classes-per-file
class Slider {
    public min: number;
    public max: number;
    constructor(facet: TwoSidedSliderFacet) {
        if (facet.isActive) {
            if (facet.currentMin || facet.currentMin === 0) {
                this.min = facet.currentMin;
            }

            if (facet.currentMax) {
                this.max = facet.currentMax;
            }
        }
    }
}
