import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguagePickerComponent } from './language-picker.component';
import { SharedModule } from '@shared/shared.module';
import { RightpaneModule } from '@features/rightpane/rightpane.module';
import { LanguagePickerPaneComponent } from './language-picker-pane/language-picker-pane.component';

@NgModule({
    imports: [CommonModule, SharedModule, RightpaneModule],
    declarations: [LanguagePickerComponent, LanguagePickerPaneComponent],
    entryComponents: [LanguagePickerComponent],
    exports: [LanguagePickerComponent]
})
export class LanguagePickerModule {}
