import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    constructor() {
    }

    private isHeaderTransparent = false;
    headerSolid$ = new BehaviorSubject<boolean>(true);

    get headerSolidState() {
        return this.headerSolid$.getValue();
    }

    set headerSolidState(state) {
        if (this.isHeaderTransparent && this.headerSolid$.getValue() !== state) {
            this.headerSolid$.next(state);
        }
    }

    set transparentHeader(state: boolean) {
        this.isHeaderTransparent = state;
    }
}
