import {Directive, HostBinding, Inject, Input, OnInit, PLATFORM_ID, ViewContainerRef} from '@angular/core';
import {SiteContextService} from '@core/site-context.service';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {isPlatformBrowser} from '@angular/common';

export type InputBusinessUnits =
    'kaufmann'
    | 'kaufmannMobileOnly'
    | 'kaufmannDesktopOnly'
    | 'quint'
    | 'quintMobileOnly'
    | 'quintDesktopOnly'
    | 'axel'
    | 'axelMobileOnly'
    | 'axelDesktopOnly';

@Directive({
    selector: '[showOnSite]',
})
export class ShowOnSiteDirective implements OnInit {
    @Input('showOnSite') set inputBusiness(arr: InputBusinessUnits[]) {
        this._inputBusiness = arr.map(v => v.toLowerCase()) as InputBusinessUnits[];
    }

    get inputBusiness(): InputBusinessUnits[] {
        return this._inputBusiness;
    }

    @HostBinding('attr.showOnSite') showOnSite = true;

    private _inputBusiness: InputBusinessUnits[];

    private readonly contextBusinessDimension: string;
    private isMobile = false;

    constructor(
        private _containerRef: ViewContainerRef,
        private siteContextService: SiteContextService,
        private breakpointObserver: BreakpointObserver,
        @Inject(PLATFORM_ID) private platformId: object,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.contextBusinessDimension = this.siteContextService.getContext().businessDimension.toLowerCase();
            this.breakpointObserver.observe(['(max-width: 1220px)', '(min-width: 1221px)']).subscribe((breakpointState: BreakpointState) => {
                this.isMobile = breakpointState.matches && breakpointState.breakpoints['(max-width: 1220px)'];
            });
        }
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            let hideElement = true;

            if (this.inputBusiness.find(businessDimension => businessDimension.indexOf(this.contextBusinessDimension.toLowerCase()) >= 0)) {
                this.inputBusiness.filter(businessDimension => businessDimension.includes(this.contextBusinessDimension.toLowerCase())).forEach((input: InputBusinessUnits) => {
                    hideElement = false;
                    if (input.includes('mobile') && !this.isMobile) {
                        hideElement = true;
                    }
                    if (input.includes('desktop') && this.isMobile) {
                        hideElement = true;
                    }
                });
            }

            if (hideElement) {
                this._containerRef.element.nativeElement.outerHTML = '';
            } else {
                this._containerRef.element.nativeElement.classList.add('show-on-site');
            }
        }
    }

}
