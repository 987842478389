import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { MetaMenuModule } from '../meta-menu/meta-menu.module';
import { HeaderComponent } from './header.component';
import { MegaMenuModule } from '../mega-menu/mega-menu.module';
import { RouterModule } from '@angular/router';
import { LanguagePickerModule } from '@features/language-picker/language-picker.module';
import { SearchModule } from '@features/search/search.module';
import { MegaMenuAxelModule } from '@features/mega-menu-axel/mega-menu-axel.module';

@NgModule({
    declarations: [HeaderComponent],
    imports: [SharedModule, MetaMenuModule, MegaMenuModule, MegaMenuAxelModule, RouterModule, LanguagePickerModule, SearchModule],
    exports: [HeaderComponent],
})
export class HeaderModule {} 
