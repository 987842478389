<div role="region" class="product-details-image-slider__wrapper" style="display:flex; flex-direction: column-reverse"
     [attr.aria-label]="'Product image gallery/carousel for ' + productImageDescription">

    <!-- PRODUCT IMAGE SLIDER THUMBNAILS LIST -->
    <div class="product-details-image-slider__thumbnails" style="width: 300px; overflow: visible;"
         [ngClass]="{
            'images-list': desktopDevice,
            'indicators-list': !desktopDevice
        }">

        <div *ngIf="desktopDevice" class="product-details-image-slider__thumbnails-navigation div_left"
             aria-label="Product image gallery/carousel thumbnail navigation">
            <button type="button" class="product-details-image-slider__thumbnails-button button_up"
                    [ngClass]="{'visible': showThumbNavUp}"
                    aria-label="Scroll up"
                    (click)="thumbsUp()">
                <div class="svg-icon" aria-hidden="true" [innerHtml]="icons.productSliderArrowRight | safeHtml"></div>
            </button>
        </div>

        <!-- [ngClass]="{'active': i === activeSlide}" -->
        <div class="product-details-image-slider__thumbnails-list-wrapper"
             [ngSwitch]="desktopDevice"
             #thumbnailsList
             (scroll)="thumbnailsScroll($event)">
            <ul class="product-details-image-slider__thumbnails-list" style="flex-direction: row;"
                *ngSwitchCase="true"
                aria-label="Product image slider thumbnails list">
                <li class="product-details-image-slider__thumbnail-list-item" style="min-width: 60px; max-width: 60px;"
                    *ngFor="let image of productImages; let i = index">
                    <button type="button" class="product-details-image-slider__thumbnail-list-item__button"
                            (click)="gotoSlide(i)">
                        <img [ngStyle]=" { 'border': (i === activeSlide) ? 'solid 1px black' : null }"
                             [src]="image?.Path + '&w=100&h=100&bgcolor=' + thumbnailImageBgColor"
                             [alt]="productImageDescription">
                    </button>
                </li>
            </ul>
            <ul class="product-details-image-slider__indicator-list"
                *ngSwitchCase="false">
                <li class="product-details-image-slider__indicator-list-item"
                    [ngClass]="{'active': i === activeSlide}"
                    *ngFor="let image of productImages; let i = index">
                </li>
            </ul>
        </div>


        <div *ngIf="desktopDevice" class="product-details-image-slider__thumbnails-navigation div_right"
             aria-label="Product image gallery/carousel thumbnail navigation">
            <button type="button" class="product-details-image-slider__thumbnails-button button_down"
                    [ngClass]="{'visible': showThumbNavDown}"
                    aria-label="Scroll down"
                    (click)="thumbsDown()">
                <div class="svg-icon" aria-hidden="true" [innerHtml]="icons.productSliderArrowRight | safeHtml"></div>
            </button>
        </div>


    </div>

    <!-- PRODUCT IMAGE SLIDER -->
    <div class="product-details-image-slider">
        <button type="button" class="button button_clean image-zoom-icon" (click)="imageZoom()">
            <app-svg [src]="icons.plus" [size]="'unset'"></app-svg>
        </button>
        <ul class="slider-controls"
            *ngIf="desktopDevice"
            aria-label="Product images gallery carousel, controls">
            <li>
                <button type="button" class="slider-button-prev"
                        [ngClass]="{'slider-button-prev--hide': sliderStart}"
                        [id]="'prev_' + id"
                        (click)="prevClickTrigger()"
                        tabindex="0"
                        aria-label="Previous"
                        [attr.aria-disabled]="sliderEnd ? true : false">
                    <div class="svg-icon slider-button__icon" aria-hidden="true"
                         [innerHtml]="icons.productSliderArrowRight | safeHtml"></div>
                </button>
            </li>
            <!-- <li>
                <button type="button" class="image-zoom"
                    (click)="imageZoom()">
                    <div class="svg-icon" aria-hidden="true" [innerHtml]="icons.fullscreen | safeHtml"></div>
                </button>
            </li> -->
            <li>
                <button type="button" class="slider-button-next"
                        [ngClass]="{'slider-button-next--hide': sliderEnd}"
                        [id]="'next_' + id" (click)="nextClickTrigger()"
                        tabindex="0"
                        aria-label="Next"
                        [attr.aria-disabled]="sliderStart ? true : false">
                    <div class="svg-icon slider-button__icon" aria-hidden="true"
                         [innerHtml]="icons.productSliderArrowRight | safeHtml"></div>
                </button>
            </li>
        </ul>

        <!--<button type="button" class="image-zoom"
            (click)="imageZoom()">
            <div class="svg-icon" aria-hidden="true" [innerHtml]="icons.zoomIn | safeHtml"></div>
        </button>-->

        <!-- PRODUCT IMAGE SLIDER CONTENT -->
        <div class="product-details-image-slider__content"
             [attr.aria-label]="'Product image gallery list, images of ' + productImageDescription">
            <!-- Slider main container -->
            <div class="swiper-container" #slider [id]="id">

                <!-- Additional required wrapper -->
                <ul class="swiper-wrapper" #sliderList>
                    <!-- Slides -->
                    <li class="swiper-slide" #sliderItem *ngFor="let image of productImages; let i = index;"
                        (dragstart)="disableDefault($event)">

                        <!-- CONSIDER CHANING IMG SIZE TO w=550 h=630 -->
                        <div class="content">
                            <button type="button" class="product-details-image-slider__image-button"
                                    (click)="imageZoom()">
                                <img class="product-details-image-slider__image"
                                     [style.background]="'#'+sliderImageBgColor"
                                     [src]="image?.Path + '&w=850&h=850&bgcolor=' + sliderImageBgColor"
                                     [alt]="productImageDescription"
                                     (load)="sliderImageLoaded()"
                                     draggable="false"/>
                            </button>
                        </div>
                    </li>
                </ul>
                <app-product-splash
                    [productPriceGroup]="product.activeProductVariant.PriceGroup"
                    [percentageSaving]="product?.activeProductVariant?.PercentageSaving"
                    [discountDescription]="product?.productData?.DiscountDescription"
                ></app-product-splash>
                <!--<div class="product-price__discount-badge"
                     *ngIf="product && product.activeProductVariant.PriceGroup !== priceGroup[priceGroup.Normal]"
                >
                    <div class="discount-badge-container">
                        <div *ngIf="product.activeProductVariant.PriceGroup === priceGroup[priceGroup.Normal] && !!product?.productData?.DiscountDescription" class="label label_full" [style.color]="splash.SplashExtraTextColor" [style.background-color]="splash.SplashExtraBackgroundColor">{{product?.productData?.DiscountDescription}}</div>
                        <div *ngIf="product.activeProductVariant.PriceGroup === priceGroup[priceGroup.Extra]" class="label label_full" [style.color]="splash.SplashExtraTextColor" [style.background-color]="splash.SplashExtraBackgroundColor">{{splash.SplashExtraTextLabel}}</div>
                        <div class="label label_splash" *ngIf="product.activeProductVariant.PriceGroup === priceGroup[priceGroup.Sale] || product.activeProductVariant.PriceGroup === priceGroup[priceGroup.Extra]" [style.color]="splash.SplashSaleTextColor" [style.background-color]="splash.SplashSaleBackgroundColor">{{splash.SplashSaleTextLabel}}</div>
                        <div class="label label_splash" *ngIf="product.activeProductVariant.PriceGroup === priceGroup[priceGroup.Outlet]" [style.color]="splash.SplashOutletTextColor" [style.background-color]="splash.SplashOutletBackgroundColor">{{splash.SplashOutletTextLabel}}</div>
                        <div class="label percentage" [style.color]="splash.SplashDiscountTextColor" [style.background-color]="splash.SplashDiscountBackgroundColor">-{{product?.activeProductVariant?.PercentageSaving}}%</div>
                    </div>
                </div>
                <div class="product-price__discount-badge"
                     *ngIf="product && product.activeProductVariant.PriceGroup === priceGroup[priceGroup.Normal] && !!product?.productData?.DiscountDescription"
                >
                    <div class="discount-badge-container">
                        <div class="label label_full" [style.color]="splash.SplashExtraTextColor" [style.background-color]="splash.SplashExtraBackgroundColor">{{product?.productData?.DiscountDescription}}</div>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</div>
