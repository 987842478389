import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { BasketComponent } from './basket.component';
import { BasketProductsComponent } from './basket-products/basket-products.component';
import { BasketProductComponent } from './basket-product/basket-product.component';
import { NgxLazyloadModule } from '@features/ngx-lazyload/ngx-lazyload.module';
import { BasketOverviewComponent } from './basket-overview/basket-overview.component';
import { RouterModule } from '@angular/router';
import { GenericSpotBasketDirective } from './generic-spot-basket.directive';
import { ProductFilterModule } from '@features/product-filter';

@NgModule({
    imports: [
        SharedModule,
        NgxLazyloadModule,
        RouterModule,
        ProductFilterModule,
    ],
    declarations: [
        BasketComponent,
        BasketProductsComponent,
        BasketProductComponent,
        BasketOverviewComponent,
        GenericSpotBasketDirective,
    ],
    exports: [BasketComponent],
})
export class BasketModule {}
