import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { MultiCheckBoxFacet, MultiCheckBoxFacetChild } from '@features/filter/models/facet.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-product-filter-smart-facet',
    templateUrl: './product-filter-smart-facet.component.html',
})
export class ProductFilterSmartFilterFacetComponent {
    @Input() facet: MultiCheckBoxFacet;
    @Output() selection = new EventEmitter<MultiCheckBoxFacet>();
    @Output() showMore = new EventEmitter<MultiCheckBoxFacet>();
    @Output() showMoreMobile = new EventEmitter<MultiCheckBoxFacet>();
    @HostBinding('class.container') hostClass = true;

    public translations: any;
    public isDesktop = false;
    private unsubscribe = new Subject();


    constructor(
        private breakpointObserver: BreakpointObserver,
        private translationService: TranslationService
    ) {
        this.breakpointObserver
            .observe(['(min-width: 1024px)'])
            .subscribe((state: BreakpointState) => {
                this.isDesktop = state.matches;
            });

        this.translationService.translations$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(translations => {
                this.translations = translations;
            });
    }

    selectItem(item: MultiCheckBoxFacetChild) {
        if (item.count <= 0 ) {
            return;
        }

        const updatedChildren = this.facet.children.map(child => {
            child.isActive = child.key === item.key ? true : child.isActive;
            return child;
        });

        this.selection.emit({
            ...this.facet,
            children: updatedChildren,
        });
    }

    childrenToShow(facet: MultiCheckBoxFacet) {
        return facet.popularChildren.filter(child => child.count > 0);
    }
}
