<div class="store-info__container" *ngIf="selectedStore">
    <h2 class="store-info__header">{{ selectedStore?.StoreImportDescription }}</h2>
    <p class="store-info__sub-info">
        {{ selectedStore?.Address + ' ' + selectedStore?.Streetnumber }}
        <br>
        {{ selectedStore?.Zip + ' ' + selectedStore?.City }}
        <br>
        <br>
        {{ selectedStore?.Phone}}
    </p>
    <app-opening-hours *ngIf="selectedStore?.OpeningPeriods !== null || selectedStore?.StoreSpecialOpeningHours !== null" [openingPeriods]="selectedStore?.OpeningPeriods" [specialOpenings]="selectedStore?.StoreSpecialOpeningHours"></app-opening-hours>
    <div class="store-info__store-description" *ngIf="selectedStore?.Body" [innerHTML]="selectedStore?.Body | safeHtml"></div>
    <img class="store-info__image" *ngIf="selectedStore?.PresentationimageUrl" [src]="selectedStore?.PresentationimageUrl">
</div>
