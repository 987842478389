<div class="scrollbar-hide">
    <div class="scrollbar-hide__content" appScrollbarHide>
        <section class="dialog-overlay">

            <div class="dialog-overlay__content">
                <span class="dialog-overlay__close" (click)="onExit()" [innerHTML]="icons.cross | safeHtml"></span>

                <div class="checkout">
                    <div class="checkout-step checkout-step_active">
                        <div class="checkout-step__inset">
                            <div class="columns is-gapless-vertical">
                                <div class="column is-12-tablet">
                                    <div class="checkout-step__inner">
                                        <h2 class="checkout-step__heading">
                                            {{(translationService.translations$ | async)?.Receipt?.Page?.Headline}}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="checkout-step__inset checkout-step__inset_top">
                            <div class="columns is-gapless-vertical is-multiline">
                                <div class="column is-6-tablet is-8-desktop">

                                    <div class="receipt-container__order-details">
                                        <div class="receipt-container__order-details__details-properties">
                                            <span>{{(translationService.translations$ | async)?.Receipt?.Page?.OrderNumberLabel}}</span>
                                            <span>{{(translationService.translations$ | async)?.Receipt?.Page?.DateLabel}}</span>
                                            <span>{{(translationService.translations$ | async)?.Receipt?.Page?.StoreLabel}}</span>
                                            <span>{{(translationService.translations$ | async)?.Receipt?.Page?.PaymentMethodLabel}}</span>
                                            <span>{{(translationService.translations$ | async)?.Receipt?.Page?.GiftCardLabel}}</span>
                                        </div>
                                        <div class="receipt-container__order-details__details-values">
                                            <span>{{ data?.OrderNumber }}</span>
                                            <span>{{ date?.getDate() }} {{ date?.getMonth()+1 }} {{ date?.getFullYear() }}</span>
                                            <span>{{ data?.StoreName }}</span>
                                            <span>{{ data?.PaymentType }}</span>
                                            <div *ngIf="isGiftCard(); else nogiftcards">
                                                <span *ngFor="let giftcard of data.GiftCards">{{ giftcard }}</span>
                                            </div>
                                            <ng-template #nogiftcards>
                                                <span>{{(translationService.translations$ | async)?.Receipt?.Page?.GiftCardNoneStatusText}}</span>
                                            </ng-template>
                                        </div>
                                    </div>

                                    <div class="columns is-gapless-vertical">
                                        <div class="column is-6">
                                            <div class="receipt-container__contact-information">
                                                <span class="receipt-container__contact-information__header">{{(translationService.translations$
                                                    | async)?.Receipt?.Page?.InvoiceTitle}}</span>

                                                <div class="receipt-container__contact-information__content">
                                                    <span>{{ data?.InvoiceAddress?.FullName }}</span>
                                                    <span>{{ data?.InvoiceAddress?.Line1 }}</span>
                                                    <span>{{ data?.InvoiceAddress?.Zipcode }} {{ data?.InvoiceAddress?.City
                                                        }}
                                                    </span>
                                                    <span>{{ data?.InvoiceAddress?.Email }}</span>
                                                    <span>{{ data?.InvoiceAddress?.Phone }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-6">
                                            <div class="receipt-container__contact-information">
                                                <span class="receipt-container__contact-information__header">{{(translationService.translations$
                                                    | async)?.Receipt?.Page?.DeliveryTitle}} - {{ data?.DeliveryMethodText
                                                    }}
                                                </span>

                                                <div class="receipt-container__contact-information__content">
                                                    <span>{{ data?.DeliveryAddress?.Company }}</span>
                                                    <span>{{ data?.DeliveryAddress?.Line1 }}</span>
                                                    <span>{{ data?.DeliveryAddress?.Zipcode }} {{ data?.DeliveryAddress?.City
                                                        }}
                                                    </span>
                                                    <span>{{ data?.Email }}</span>
                                                    <span>{{ data?.DeliveryAddress?.Phone }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="receipt-container__contact-information" *ngIf="data.Comment !== ''">
                                        <span class="receipt-container__contact-information__header">{{(translationService.translations$
                                            | async)?.Receipt?.Page?.CommentTitle}}</span>

                                        <div class="receipt-container__contact-information__content receipt-container__contact-information__content_comment">
                                            <span>{{ data?.Comment }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-6-tablet is-4-desktop">

                                    <div class="receipt-container__sales-slip">
                                        <span class="receipt-container__sales-slip__header">{{(translationService.translations$
                                            | async)?.Receipt?.RightPane?.Headline}}</span>

                                        <div class="receipt-item-list">
                                            <div class="receipt-item-list__item" *ngFor="let order of data.OrderLines">
                                                <div class="receipt-item-list__item__picture">
                                                    <img LazyLoad [LazyLoadSrc]="order.ImageUrl + '&w=50&h=50'" [LazyLoadSrcSet]="order.ImageUrl + '&w=50&h=50 1x,' + order.ImageUrl + '&w=50&h=50 2x'"
                                                        alt="no image" />
                                                </div>
                                                <div class="receipt-item-list__item__info">
                                                    <span>{{ order.BrandText }}</span>
                                                    <span>{{ order.CategoryText }}, {{ order.Color }}</span>
                                                    <span>{{(translationService.translations$ | async)?.Receipt?.Page?.SizeLabel}}
                                                        {{ order.Size }}</span>
                                                </div>
                                                <div class="receipt-item-list__item__price">
                                                    <br>
                                                    <div *ngIf="order.Count === 1; else plural">
                                                        <span>{{ order.Count }} {{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryProductLabel}}</span>
                                                    </div>
                                                    <ng-template #plural>
                                                        <span>{{ order.Count }} {{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryProductsLabel}}</span>
                                                    </ng-template>
                                                    <span>{{ order.TotalLinePrice | kfmCurrency: data.Currency}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="receipt-container__sales-slip__footer">

                                            <div class="receipt-container__sales-slip__footer__price-details-container">
                                                <div class="receipt-container__sales-slip__footer__price-details">
                                                    <div class="receipt-container__sales-slip__footer__price-details__category" *ngIf="data.OrderLines?.length === 1; else orderlinesPlural">
                                                        {{ data.OrderLines?.length }} {{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryProductLabel}}
                                                    </div>
                                                    <ng-template #orderlinesPlural>
                                                        <div class="receipt-container__sales-slip__footer__price-details__category">{{
                                                            data.OrderLines?.length }} {{(translationService.translations$
                                                            | async)?.Receipt?.RightPane?.SummaryProductsLabel}}</div>
                                                    </ng-template>
                                                    <div class="receipt-container__sales-slip__footer__price-details__price">{{data.SubTotal
                                                        |kfmCurrency: data.Currency}}</div>
                                                </div>
                                                <div class="receipt-container__sales-slip__footer__price-details" *ngIf="data.NormalPrice">
                                                    <div class="receipt-container__sales-slip__footer__price-details__category">{{(translationService.translations$
                                                        | async)?.Receipt?.Page?.RetailPriceLabel}}</div>
                                                    <div class="receipt-container__sales-slip__footer__price-details__price">{{
                                                        data.NormalPrice | kfmCurrency: data.Currency}}</div>
                                                </div>
                                                <div class="receipt-container__sales-slip__footer__price-details" *ngIf="data.DiscountValue">
                                                    <div class="receipt-container__sales-slip__footer__price-details__category receipt-container__sales-slip__footer__price-details_discount">{{(translationService.translations$
                                                        | async)?.Receipt?.RightPane?.SummaryYouDiscountLabel}}</div>
                                                    <div class="receipt-container__sales-slip__footer__price-details__price receipt-container__sales-slip__footer__price-details_discount">{{
                                                        data.DiscountValue | kfmCurrency: data.Currency}}</div>
                                                </div>

                                                <div class="receipt-container__sales-slip__footer__price-details__breaker"></div>

                                                <div class="receipt-container__sales-slip__footer__price-details" *ngIf="data.DeliveryFee">
                                                    <div class="receipt-container__sales-slip__footer__price-details__category">{{(translationService.translations$
                                                        | async)?.Receipt?.RightPane?.SummaryDeliveryLabel}}</div>
                                                    <div class="receipt-container__sales-slip__footer__price-details__price">{{
                                                        data.DeliveryFee | kfmCurrency: data.Currency }}</div>
                                                </div>
                                                <div class="receipt-container__sales-slip__footer__price-details" *ngIf="data.PaymentTypeFee && data.PaymentTypeFee !== '0'">
                                                    <div class="receipt-container__sales-slip__footer__price-details__category">{{(translationService.translations$
                                                        | async)?.Receipt?.RightPane?.SummaryPaymentFeeLabel}}</div>
                                                    <div class="receipt-container__sales-slip__footer__price-details__price">{{
                                                        data.PaymentTypeFee | kfmCurrency: data.Currency}}</div>
                                                </div>

                                                <div class="receipt-container__sales-slip__footer__price-details__breaker receipt-container__sales-slip__footer__price-details__breaker_large"></div>

                                                <div class="receipt-container__sales-slip__footer__price-details">
                                                    <div class="receipt-container__sales-slip__footer__price-details__category receipt-container__sales-slip__footer__price-details_total">{{(translationService.translations$
                                                        | async)?.Receipt?.RightPane?.SummaryTotalLabel}}</div>
                                                    <div class="receipt-container__sales-slip__footer__price-details__price receipt-container__sales-slip__footer__price-details_total">{{
                                                        data.Total | kfmCurrency: data.Currency }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>
</div>