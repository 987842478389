import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { leftpaneAnimations } from '@features/leftpane/leftpane.animation';
import { AnimationEvent } from '@angular/animations';
import { arrowRight90, close } from '@shared/svg';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { StickyService } from '@core/sticky.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MobileFilterStateService } from '@core/mobile-filter-state.service';

@Component({
    selector: 'app-leftpane',
    animations: leftpaneAnimations,
    template: `<aside class="leftpane" [@leftpaneAnimation]="animationState" (@leftpaneAnimation.start)="onAnimationStart($event)" (@leftpaneAnimation.done)="onAnimationDone($event)" #pane>
        <div class="leftpane_close" (click)="closeDialog()">
            ${close}
        </div>
        <ng-content></ng-content>
    </aside>
    `,
})
export class LeftpaneComponent implements OnInit, OnDestroy {
    animationState: 'void' | 'enter' | 'leave' = 'enter';
    @Output() animationStateChanged = new EventEmitter<AnimationEvent>();
    @Input() startExit: any;

    @ViewChild('pane', { static: true }) paneElement;
    private destroy = new Subject();

    constructor(
        public overlayRef: ImpactOverlayRef,
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private _document,
        private stickyService: StickyService,
    ) {}

    onAnimationStart(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    onAnimationDone(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.animationState = 'leave';
    }

    closeDialog() {
        this.overlayRef.close();
    }

    ngOnInit() {
        this.startExit.subscribe(() => {
            this.startExitAnimation();
        });

        /*this.stickyService.stickyEvent
            .pipe(takeUntil(this.destroy))
            .subscribe(event => {
                this.setPosition(event.headerBottom);
            });*/
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.complete();
    }

    setPosition(headerBottom) {
        const headerHeight = headerBottom;
        let paneMargin = headerHeight + 'px';

        this.paneElement.nativeElement.style.marginTop = paneMargin;
        this.paneElement.nativeElement.style.height = `calc(100% - ${paneMargin}`;
    }

    protected readonly arrowRight90 = arrowRight90;
}
