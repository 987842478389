import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LanguagePickerModule } from '@features/language-picker/language-picker.module';
import { NewsletterModule } from '@features/newsletter/newsletter.module';
import { SharedModule } from '@shared/shared.module';

import { FooterColumnComponent } from './footer-column/footer-column.component';
import { FooterSectionComponent } from './footer-section/footer-section.component';
import { FooterComponent } from './footer.component';

/**
 * Module in charge of providing the apps footer
 */
@NgModule({
    imports: [CommonModule, SharedModule, LanguagePickerModule, RouterModule, NewsletterModule],
    declarations: [FooterComponent, FooterColumnComponent, FooterSectionComponent],
    exports: [FooterComponent],
})
export class FooterModule {}
