import { UserService } from '@core/user.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ImpactCoreStoresStoreServiceModel as storeDto,
    ImpactCoreUsersModelsCreateUserRequest as CreateUserRequest,
    ImpactWebsiteCodeWebApiControllersGetInvoiceCountriesResponse as invoiceCountriesResponse,
} from '@shared/swagger/swagger.interface';
import { AlertResponse } from '@shared/alert/alert.interface';
import { StoreService } from '@core/store.service';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ZipcodeService } from '@core/zipcode.service';
import { checkmark, expand } from '@shared/svg';
import { TranslationService } from '@core/translation.service';
import { SiteContextService } from '@core/site-context.service';
import { BasketService } from '@core/basket.service';
import {RegexService} from '@core/regex.service';

@Component({
    selector: 'app-signup-step-2',
    templateUrl: './signup-step-2.component.html',
})
export class SignupStep2Component implements OnInit, OnDestroy {
    constructor(
        private userService: UserService,
        private storeService: StoreService,
        private zipcodeService: ZipcodeService,
        private translationService: TranslationService,
        private basketService: BasketService,
        private siteContextService: SiteContextService,
        public regexService: RegexService,
    ) {}

    private destroy = new Subject();

    @Input() onSubmit: (user: CreateUserRequest) => void;
    @Input() response: AlertResponse = {};
    @Input() isSignupSplash: boolean;

    translations: any;
    user: CreateUserRequest = {
        IsSms: true
    };
    stores: storeDto[] = [];

    icons = {
        expand,
        checkmark,
    };

    countries: invoiceCountriesResponse = {};

    minDate: Date = new Date(
        new Date().setFullYear(new Date().getFullYear() - 100)
    );

    maxDate: Date = new Date();

    ngOnInit() {
        this.storeService
            .getAllStores()
            .pipe(take(1))
            .subscribe(data => (this.stores = data));

        this.userService.currentUser$
            .pipe(takeUntil(this.destroy))
            .subscribe(data => this.user = {...data, ...this.user});

        this.translations = this.translationService.translations$;

        this.basketService
            .retrieveInvoiceCountries()
            .pipe(takeUntil(this.destroy))
            .subscribe(countries => (this.countries = countries));
    }

    closeAlert = () => {
        this.response = {};
    }

    onComplete(form: NgForm) {
        this.response = {};

        if (form.valid) {
            console.log('phonenr. ', this.user.PhoneNumber);
            this.onSubmit(this.user);
        }
    }

    setCity(zipcode: string) {
        if (
            zipcode &&
            zipcode.length === 4 &&
            this.siteContextService.context.language === 'da'
        ) {
            this.zipcodeService
                .getCity(zipcode)
                .subscribe(data => (this.user.City = data.navn));
        }
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }
}
