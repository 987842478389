import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'showSizes',
})
export class ShowSizesPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    XRegexp = new RegExp(/^X+/i);

    reformat(sizes: string[]): string[] {
        return sizes.map(size => {
            if (size.includes('XXXL')) {
                const Xs = size.match(this.XRegexp);
                const lastOfSizeLabel = size.substring(
                    Xs[0].length,
                    size.length
                );
                return `${Xs[0].length}X${lastOfSizeLabel}`;
            }
            return size;
        });
    }

    transform(value: string[], sizeLabel?: string, sizesLabel?: string): any {
        const availableSizes: number = value.length;
        const maxSizesToShow = 5;
        const sizesToShow: string = this.reformat(value)
            .slice(0, maxSizesToShow)
            .join('  ');
        const showTextNotSizes = availableSizes > maxSizesToShow;

        const template = showTextNotSizes
            ? `<div class="product-card__possible-sizes">${sizesLabel}</div>`
            : `<div class="product-card__possible-sizes">${sizesToShow}</div>`;

        return this.sanitized.bypassSecurityTrustHtml(template);
    }
}
