import {Component, OnInit} from '@angular/core';
import {PageService} from '@core/page.service';
import {Observable} from 'rxjs';
import {first} from 'rxjs/internal/operators';
import {checkmark} from '@shared/svg';
import {SafeStylePipe} from '@shared/safe-style.pipe';

@Component({
    selector: 'app-usp',
    templateUrl: './usp.component.html',
})
export class UspComponent implements OnInit {
    page: Observable<any>;

    constructor(
        private pageService: PageService,
    ) {
    }

    ngOnInit() {
        this.page = this.pageService.page.pipe(first());
    }
}
