import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitFormat',
})
export class SplitFormatPipe implements PipeTransform {
    /**
     * Format string with an assigned character
     * @param {string} value
     * @param {number} splitIndex
     * @param {string} separatorCharacter
     * @returns {string}
     */
    transform(
        value: string,
        splitIndex: number = 4,
        separatorCharacter: string = '-'
    ): string {
        if (typeof value !== 'undefined' && value.length > splitIndex) {
            let formatted = [];
            for (
                let i = 0, arrLen = value.length;
                i < arrLen;
                i = i + splitIndex
            ) {
                formatted.push(value.substring(i, i + splitIndex));
            }

            return formatted.join(separatorCharacter);
        } else {
            return value;
        }
    }
}
