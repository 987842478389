import {
    ComponentFactoryResolver,
    Directive,
    Input,
    OnChanges,
    SimpleChanges,
    ViewContainerRef,
} from '@angular/core';
import { ExposedProductRow } from '@features/product-list/exposed-product-row';
import { RowTemplate1Component } from '@features/product-list/exposed-products/product-row-templates/row-template1.component';
import { RowTemplate2Component } from '@features/product-list/exposed-products/product-row-templates/row-template2.component';
import { RowTemplate3Component } from '@features/product-list/exposed-products/product-row-templates/row-template3.component';
import { RowTemplate4Component } from '@features/product-list/exposed-products/product-row-templates/row-template4.component';
import { ISplashVisibility } from '@shared/product-splash/product-splash.component';

@Directive({
    selector: '[appExposedProductsTemplate]',
})
export class ExposedProductsTemplateDirective implements OnChanges {
    @Input() data: ExposedProductRow;
    @Input() splashVisibility: ISplashVisibility;
    private rowTemplateComponents = [
        RowTemplate1Component,
        RowTemplate2Component,
        RowTemplate3Component,
        RowTemplate4Component,
    ];

    constructor(
        private viewContainer: ViewContainerRef,
        private cfResolver: ComponentFactoryResolver
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('data')) {
            const rowData: ExposedProductRow = changes.data?.currentValue;

            this.viewContainer.clear();

            const componentClass = this.rowTemplateComponents.find(
                component => component.ref === rowData.templateName
            );

            if (!componentClass) {
                console.warn(
                    `Couldn't find a matching compnent of the name ${
                        rowData.templateName
                    }`
                );
                return;
            }
            if (typeof componentClass !== 'undefined') {
                const spotComponentFactory = this.cfResolver.resolveComponentFactory(
                    componentClass
                );

                const spotComponent = this.viewContainer.createComponent(
                    spotComponentFactory
                );

                spotComponent.instance['data'] = this.data;
                spotComponent.instance['splashVisibility'] = this.splashVisibility;
            }
        }
    }
}
