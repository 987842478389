import {
    Component, ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MultiCheckBoxFacet, MultiCheckBoxFacetChild, VisualFacet } from '@features/filter/models/facet.model';
import {
    ProductFilterShowMoreDialogComponent,
} from '@features/product-filter/product-filter-show-more-dialog/product-filter-show-more-dialog.component';
import { OverlayService } from '@impactdk/ngx-overlay';

import { MultiCheckBoxSelection } from '../models/selection.model';
import { TranslationService } from '@core/translation.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cross, search, search2 } from '@shared/svg';

@Component({
    selector: 'app-product-filter-facet-list',
    templateUrl: './product-filter-facet-list.component.html',
})
export class ProductFilterFacetListComponent implements OnInit, OnDestroy, OnDestroy, OnChanges {
    @Input() facet: VisualFacet;
    @Output() onChange = new EventEmitter<MultiCheckBoxSelection>();
    @Output() onSliderChange = new EventEmitter();

    @ViewChild('filterFacetInput') filterFacetInputElm: ElementRef<HTMLInputElement>;
    public filterFacetValue = '';

    public filteredFacets = new ReplaySubject<MultiCheckBoxFacetChild[]>();

    translations;
    private destroy: Subject<any> = new Subject<any>();

    icons = {
        search,
        cross
    };

    constructor(
        private overlay: OverlayService,
        private translationService: TranslationService
    ) {
        this.translationService.translations$
            .pipe(takeUntil(this.destroy))
            .subscribe(translations => this.translations = translations);
    }

    ngOnInit() {
        if (this.facet.kind === 'Multicheck') {
            this.filteredFacets.next(this.facet.children);
        }
    }

    onItemChange(event: MultiCheckBoxSelection) {
        this.onChange.emit(event);
    }

    itemsToShow(): MultiCheckBoxFacetChild[] {
        return this.facet.kind === 'Multicheck' ? this.facet.children.slice(0, 18) : [];
    }

    getShowMoreClasses() {
        return this.facet.kind === 'Multicheck' ? {
            'is-hidden-touch': (this.facet.children.length < 8),
            'is-hidden-desktop': (this.facet.children.length < 18)
        } : {};
    }

    showMore() {
        this.overlay.open(ProductFilterShowMoreDialogComponent, {
            data: this.facet,
            backdropClass: 'cdk-overlay-white-backdrop',
        });
    }

    sliderChange(event) {
        this.onSliderChange.emit(event);
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('facet') && changes.facet.currentValue.key === 'PriceGroup') {
            (this.facet as MultiCheckBoxFacet).children =
                (this.facet as MultiCheckBoxFacet).children.map(facet => facet = {...facet, name: this.translations.Filter.PriceGroup[facet.key]});
        }
    }
    public filterFacets(event: KeyboardEvent) {
        this.filterFacetValue = (event.target as HTMLInputElement).value;
    }

    public clearFilterFacetValue() {
        this.filterFacetValue = '';
        this.filterFacetInputElm.nativeElement.value = '';
    }
}
