import { filter, finalize, mergeMap, take, tap } from 'rxjs/operators';
import { UserService } from '@core/user.service';
import { Component, OnInit } from '@angular/core';
import { ImpactCoreUsersModelsCreateUserRequest as createUserRequest } from '@shared/swagger/swagger.interface';
import { AlertResponse, AlertType } from '@shared/alert/alert.interface';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { PageService } from '@core/page.service';
import { TranslationService } from '@core/translation.service';
import { SiteContextService } from '@core/site-context.service';
import { ActivatedRoute } from '@angular/router';
import { user } from '@shared/svg';
import { AuthenticationService } from '@core/authentication.service';
import { TrackingService } from '@core/tracking/tracking.service';
import {RaptorTrackingService} from '@core/tracking/raptor-tracking.service';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
})
export class SignupComponent implements OnInit {
    constructor(
        private userService: UserService,
        private authService: AuthenticationService,
        private spinnerService: SpinnerService,
        private pageService: PageService,
        private translationService: TranslationService,
        private siteContextService: SiteContextService,
        private route: ActivatedRoute,
        private tracking: TrackingService,
        private raptorTrackingService: RaptorTrackingService
    ) {}

    pageContent: any = {};
    createUserTermsLink = '';
    translations: any;
    submitted = false;
    currentStep = 1;
    response: AlertResponse = {};
    isSignupSplash = false;
    user: createUserRequest;

    ngOnInit() {
        this.route.queryParams
            .pipe(filter(params => params.splash))
            .subscribe(paramValue => {
                this.isSignupSplash = paramValue.splash;
                this.currentStep = 2;
            });

        this.pageService.page.pipe(take(1)).subscribe(data => {
            this.pageContent = data;
        });

        this.userService.currentUser$.subscribe(
            userData => (this.user = userData)
        );

        this.translations = this.translationService.translations$;

        this.createUserTermsLink = this.siteContextService.getContext().createUserTermsUrl;
    }

    raptorTrackUser(email: string): void {
        this.raptorTrackingService.trackAddUser(email);
    }

    signup = (user: createUserRequest) => {
        if (user.IsNewsletterSubscriber) {
            this.tracking.sendNewsletterSignup('create-user-flow');
        }

        this.response = {};

        if (!this.submitted) {
            this.spinnerService.show('signupSpinner');
            this.submitted = true;
            this.raptorTrackUser(user.Email);

            this.userService
                .createUser(user)
                .pipe(
                    finalize(() => {
                        this.spinnerService.hide('signupSpinner');
                        this.submitted = false;
                    })
                )
                .subscribe(
                    () => {
                        this.currentStep++;
                    },
                    ({ error }) =>
                        (this.response = {
                            type: AlertType.error,
                            text: error.ErrorMessage,
                        })
                );
        }
    }

    complete = (user: createUserRequest) => {
        this.response = {};

        if (!this.submitted) {
            this.spinnerService.show('signupSpinner');
            this.submitted = true;

            this.userService
                .setUser(user)
                .pipe(
                    finalize(() => {
                        this.spinnerService.hide('signupSpinner');
                        this.submitted = false;
                    })
                )
                .subscribe(
                    () => {
                        this.currentStep++;
                    },
                    ({ error }) =>
                        (this.response = {
                            type: AlertType.error,
                            text: error.ErrorMessage,
                        })
                );
        }
    }

    update = (user: createUserRequest) => {
        this.raptorTrackUser(user.Email);
        this.userService
            .setUser(user)
            .pipe(
                finalize(() => {
                    this.spinnerService.hide('signupSpinner');
                    this.submitted = false;
                })
            )
            .subscribe(
                () => {
                    this.currentStep++;
                },
                ({ error }) =>
                    (this.response = {
                        type: AlertType.error,
                        text: error.ErrorMessage,
                    })
            );
    }

    getFilename(path) {
        const filePathShards = path.split('/');

        return filePathShards[filePathShards.length - 1];
    }
}
