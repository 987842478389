import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SiteContextService } from '@core/site-context.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie';
import { UserService } from '@core/user.service';
import { RaptorService } from '@core/raptor.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class BrandService {
    private env: string = this.siteContextService.getRootUrl();

    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService,
        private userService: UserService,
        private raptorService: RaptorService,
        @Inject(PLATFORM_ID) private platform_id
    ) {}

    getFavoriteBrands(
        maxNumberOfBrands: string,
        typeName: string
    ): Observable<any> {
        let raptorCookieValue = '';
        if (isPlatformBrowser(this.platform_id)) {
            raptorCookieValue = this.raptorService.getRaptorCookieValue();
        }
        const user = this.userService.currentUser.getValue();

        const request = {
            MaxNumberOfBrands: maxNumberOfBrands,
            UserNameIncludingDomain: user ? user.UserName : null,
            RaptorCookieValue: raptorCookieValue,
            TypeName: typeName,
        };

        return this.http.post(
            `${this.env}/webapi/Brand/FavoriteBrands`,
            request
        );
    }
}
