import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
    transform(text: string, search: string): string {
        // https://gist.github.com/adamrecsko/0f28f474eca63e0279455476cc11eca7
        if (search && text) {
            let pattern = search.replace(
                /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
                '\\$&'
            );
            pattern = pattern
                .split(' ')
                .filter(t => {
                    return t.length > 0;
                })
                .join('|');
            const regex = new RegExp(pattern, 'gi');

            return text.replace(
                regex,
                match => `<span class="highlight">${match}</span>`
            );
        } else {
            return text;
        }
    }
}
