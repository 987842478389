import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationService } from '@core/navigation.service';
import { SiteContextService } from '@core/site-context.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { globe, goArrow } from '@shared/svg';

import { FooterSettings } from './interfaces/footer-settings.interface';
import { first } from 'rxjs/operators';
import { ScrollBackService } from '@core/scrollBack.service';
import { TrustpilotService } from '@core/trustpilot.service';
import { PageService } from '@core/page.service';

enum SiteContextNames {
    AXEL = 'Axel',
    KAUFMANN = 'Kaufmann',
    QUINT = 'Quint'
}

/**
 * Renders a footer for the application
 */
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit, OnDestroy, AfterViewInit {
    /**
     * Layout settings for the footer
     */
    footerSettings: FooterSettings;

    organisationLd: any[] = [];
    organisationLdSameAs: string[] = [];

    pageData: any;
    brandLogo: { image: string; url: string };
    icons = {
        globe,
        goArrow,
    };

    countrySelectorData: any;

    trustpilotId: string;
    trustpilotTheme: string;
    loadAPI: Promise<any>;

    /**
     * Subscribe holder
     */
    settingsSub$;
    siteContextName: string;

    siteContextNames = SiteContextNames;

    script: any;

    /**
     * Constructs and injects services
     * @param platformId
     * @param {NavigationService} navigation
     * @param tracking
     * @param siteContextService
     * @param pageService
     * @param _document
     * @param render
     * @param scrollBack
     * @param trustpilotService
     */
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private navigation: NavigationService,
        private tracking: TrackingService,
        private siteContextService: SiteContextService,
        private pageService: PageService,
        @Inject(DOCUMENT) private _document,
        private render: Renderer2,
        private scrollBack: ScrollBackService,
        private trustpilotService: TrustpilotService,
    ) {
        this.siteContextName = this.siteContextService.getContext().businessDimension;
        this.trustpilotTheme = this.siteContextName === this.siteContextNames.AXEL ? 'light' : 'dark';
        this.trustpilotId = this.siteContextService.getContext().generalSiteSettings?.TrustPilotBusinessId ?? '';

        this.countrySelectorData = this.siteContextService.getContext().countrySelectorList;
    }

    /**
     * Subscribes to our footer settings that control our layout
     */
    ngOnInit() {
        this.brandLogo = {
            image: this.siteContextService.getContext().logoImageUrl,
            url: this.siteContextService.getContext().logoImageRedirectUrl,
        };

        this.settingsSub$ = this.navigation
            .getFooterSettings()
            .subscribe(res => {
                this.footerSettings = res;
                this.organisationLdSameAs = this.sameAsLinks(
                    this.footerSettings.SocialColumn.SocialItems,
                );

                this.organisationLd = [
                    {
                        '@context': 'http://schema.org/',
                        '@type': 'Organization',
                        legalName: this.footerSettings.FooterStructuredData
                            .LegalName,
                        url: this.footerSettings.FooterStructuredData.Url,
                        logo: this.footerSettings.FooterStructuredData
                            .LogoImage,
                        contactPoint: [
                            {
                                '@type': 'ContactPoint',
                                telephone: this.footerSettings
                                    .FooterStructuredData.Telephone,
                                email: this.footerSettings.FooterStructuredData
                                    .Email,
                                contactType: this.footerSettings
                                    .FooterStructuredData.ContactType,
                            },
                        ],
                        sameAs: this.organisationLdSameAs,
                    },
                ];
            });
    }

    countrySelect(selectedCountry: string): void {
        if (!isPlatformBrowser(this.platformId) || !this.countrySelectorData) {
            return;
        }

        for (const item of this.countrySelectorData) {
            if (selectedCountry === item.CountryName) {
                window.location.href = item.LinkToHomePage;
                return;
            }
        }
    }

    sameAsLinks(socialItem: any[]): string[] {
        const socialLinksArray = [];

        socialItem.forEach(item => socialLinksArray.push(item.ImageLink));

        return socialLinksArray;
    }

    scrollToTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
    }

    /**
     * Unsubscribes on destroy
     */
    ngOnDestroy() {
        this.settingsSub$.unsubscribe();
    }

    trackSocialIcon(network) {
        this.tracking.sendSocialClick(network);
    }

    trackNavigationClick(link) {
        this.tracking.sendNavigationEvent('Footer menu', link);
    }

    ngAfterViewInit(): void {
        /*this.pageService.page.subscribe(page => {
            if (
                isPlatformBrowser(this.platformId) &&
                this.trustpilotId && !this.trustpilotService.trustpilotLoaded$.getValue() &&
                (page.pageData.TemplateName !== 'CheckoutPage' &&  page.pageData.TemplateName !== 'BasketPage')
            ) {
                setTimeout(() => {
                    /!*const dynamicScripts = [
                        'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
                    ];*!/

                    // for (let i = 0; i < dynamicScripts.length; i++) {
                        this.script = this.render.createElement('script');
                        this.script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
                        this.script.type = 'text/javascript';
                        this.script.async = false;
                        this.script.charset = 'utf-8';
                        this.render.appendChild(this._document.head, this.script);
                    // }
                    this.trustpilotService.trustpilotLoaded$.next(true);
                }, 1000);
            } else {
                if ((window as any).Trustpilot) {
                    this.scrollBack.animationDone.pipe(first()).subscribe(() => {
                        setTimeout(() => {
                            (window as any).Trustpilot.Modules.WidgetManagement.findAndApplyWidgets();
                        }, 100);
                    });
                }
            }
        });*/
    }
}
