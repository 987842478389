import { AnimationEvent } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageService } from '@core/page.service';
import { ProductUtilityService } from '@core/productUtility.service';
import { TranslationService } from '@core/translation.service';
import { StockInStoreService } from '@features/product-page/stock-in-store/stock-in-store.service';
import { IMPACT_OVERLAY_DATA, OverlayService } from '@impactdk/ngx-overlay';
import { arrowRight, expand } from '@shared/svg';
import {
    ImpactCoreModelsProductsDtoColorGroupDto as ColorGroupModel,
    ImpactCoreModelsProductsDtoStoreStockDto as StoreStockModel,
} from '@shared/swagger/swagger.interface';
import { isStockCountHigh } from '@shared/utility';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-stock-in-store',
    templateUrl: './stock-in-store.component.html',
})
export class StockInStoreComponent implements OnInit {
    expandIcon = expand;
    arrowRightIcon = arrowRight;

    activeColorGroup: ColorGroupModel;
    selectedSize: string;

    storeList: StoreStockModel[];
    isStoreListEmpty = true;
    isInStockOnline = true;

    isStockCountHigh = isStockCountHigh;

    selectedColorId = '';

    translations: any;
    pageData: any;

    justOpened = true;

    // Trigger animation
    public animationStateChanged = new EventEmitter<AnimationEvent>();
    public startExitTrigger = new Subject();

    constructor(
        private overlay: OverlayService,
        @Inject(IMPACT_OVERLAY_DATA) public data: any,
        private stockService: StockInStoreService,
        private router: Router,
        private translationService: TranslationService,
        private pageDataService: PageService,
        private productUtilityService: ProductUtilityService
    ) {}

    ngOnInit() {
        // Gets the page data
        this.pageDataService.page.pipe(take(1)).subscribe(data => {
            this.pageData = data.pageData;
        });
        this.translations = this.translationService.translations$;
        this.selectedColorId = this.data.selectedColorId;
        this.activeColorGroup = this.productUtilityService.getActiveColorGroup(
            this.data.product.ColorGroups,
            this.selectedColorId
        );
        this.selectedSize = this.data.selectedSize
            ? this.data.selectedSize.Id
            : '';
        if (this.selectedSize !== '') {
            this.changeSize();
        }
    }

    public animationStateChange(event): void {
        this.animationStateChanged.emit(event);
    }

    public startExitAnimation(): void {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }

    doSomething(event) {
        this.animationStateChanged.emit(event);
    }

    changeSize(): void {
        if (this.selectedSize === '') {
            this.storeList = [];
        } else {
            this.stockService
                .getStockInStores(this.selectedSize)
                .subscribe(stores => {
                    this.justOpened = false;
                    this.storeList = [].concat(stores);
                    this.isStoreListEmpty = this.storeList.length === 0;
                    this.isInStockOnline = this.getOnlineStockCount();
                });
        }
    }
    private getOnlineStockCount(): boolean {
        const skuSelected = this.activeColorGroup.Skus.filter(sku => sku.Id === this.selectedSize);
        return !!skuSelected.length ? skuSelected[0].hasOwnProperty('StockCount') : false;
    }
}
