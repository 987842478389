import {isPlatformBrowser} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef, HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {ProductService} from '@core/product.service';
import {StickyService} from '@core/sticky.service';
import {ProductVariant} from '@features/product-filter/models/product.model';
import {arrowRight90} from '@shared/svg';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-product-variants-list',
    template: `
        <div class="variant-picker" *noSSR>
            <div>{{(translation.translations$ | async)?.ProductDetails.ProductInfoSectionInformation.ColorLabel}}: <b>{{ selectedVariantName }}</b></div>
            <div class="variant-picker__track" #track *ngIf="isReady">
                <div class="variant-picker__outer-circle border-gradient-purple border-gradient-purple"
                     *ngFor="let variant of productVariants"
                     (click)="changeVariant(variant)" #thumbnails
                     [ngClass]="{'variant-picker__thumbnail_active': variant?.ColorId === selectedVariantId,
                                'white-variant': isVariantWhite(variant)}"
                     [ngStyle]=" { 'background': variant?.ColorId === selectedVariantId ? getColorFromHexList(variant.ColorHexCodes) : 'none' } ">
                    <div class="inner-circle" [style.background]="getColorFromHexList(variant.ColorHexCodes)"></div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="showArrows">
            <button class="variant-picker__button variant-picker__next-btn"
                    *ngIf="moreNext"
                    (click)="moveTrack(true)"
                    [innerHtml]="arrow | safeHtml">
            </button>

            <button class="variant-picker__button variant-picker__prev-btn"
                    *ngIf="morePrev"
                    (click)="moveTrack(false)"
                    [innerHtml]="arrow | safeHtml">
            </button>
        </ng-container>
    `,
})
export class ProductVariantsListComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() productVariants: any[];
    @Input() currentProductVariantId: any;
    @Input() productData: any;

    @ViewChild('track') track: ElementRef<HTMLDivElement>;
    @ViewChildren('thumbnails') thumbnails;

    @HostBinding('style.display') get variantPickerVisibility(): string {
        return !!this.productVariants.length ? 'block' : 'none';
    }


    morePrev = true;
    moreNext = true;
    showArrows = false;
    imageBackgroundColor = '';

    last_known_scroll_position;
    ticking = false;

    arrow = arrowRight90;
    private unsubscribe: Subject<void> = new Subject();

    selectedVariantId = '';
    selectedVariantName = '';
    isReady = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private productService: ProductService,
        private stickyService: StickyService,
        private cdr: ChangeDetectorRef,
        public translation: TranslationService
    ) {

        this.productService.currentlySelectedProduct$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((product: any) => {
                if (product) {
                    this.selectedVariantId = product.activeProductVariant.ColorId;
                }
            });
    }

    ngOnInit(): void {
        const brandColors = this.productService.getBrandColors();
        this.imageBackgroundColor = brandColors.variantImageBgColor;
        this.selectedVariantId = this.currentProductVariantId;

        // Filter out any products that are sold out, because we don't want to show them in the variants list
        const onlyAvailableVariants = this.productVariants.filter((variant: any) => {
            return !this.productService.isVariantSoldOut(variant.Skus);
        });


        this.productVariants = onlyAvailableVariants;

        this.selectedVariantName = this.productVariants.find(variant => variant.ColorId === this.selectedVariantId)?.FilterColorName;
        this.isReady = true;
    }

    isVariantWhite(variant) {
        return variant.ColorHexCodes &&
            variant.ColorHexCodes.length === 1 &&
            variant.ColorHexCodes[0].startsWith('#fff');
    }


    changeVariant(variant: ProductVariant): void {
        this.productService.setActiveProductByColorId(variant.ColorId);
        this.selectedVariantId = variant.ColorId;
        this.selectedVariantName = variant.FilterColorName;
        window.scroll({top: 0, left: 0, behavior: 'smooth'});

        const currentThumbnailIndex = this.productVariants.findIndex(colorGroup => {
            return colorGroup === variant;
        });

        const clickedThumbnail = this.thumbnails.find((thumbnail, index) => {
            return currentThumbnailIndex === index;
        });

        this.track.nativeElement.scroll({
            top: 0,
            left: clickedThumbnail.nativeElement.offsetLeft - 60,
            behavior: 'smooth',
        });
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.checkArrows();

            this.initScrollListen();

            const currentThumbnailIndex = this.productVariants.findIndex(
                colorGroup => {
                    return colorGroup.ColorId === this.currentProductVariantId;
                }
            );

            const currentThumbnail = this.thumbnails.find(
                (thumbnail, index) => {
                    return currentThumbnailIndex === index;
                }
            );

            if (currentThumbnail) {
                const offsetLeft = currentThumbnail.nativeElement.offsetLeft;

                setTimeout(() => {
                    if (this.track.nativeElement.scrollTo) {
                        this.track.nativeElement.scrollTo({
                            top: 0,
                            left: offsetLeft - 60,
                        });
                    } else {
                        this.track.nativeElement.scrollLeft = offsetLeft - 60;
                    }
                }, 100);
            }

            this.stickyService.stickyEvent$
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.stickyListener();
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        if (isPlatformBrowser(this.platformId)) {
            this.track.nativeElement.removeEventListener(
                'scroll',
                this.stickyListenerBound
            );
        }
    }

    checkArrows() {
        const trackWidth = this.track.nativeElement.clientWidth;
        const trackScrollWidth = this.track.nativeElement.scrollWidth;
        const shouldShowArrows = trackScrollWidth > trackWidth;

        if (this.showArrows !== shouldShowArrows) {
            this.showArrows = shouldShowArrows;

            this.cdr.detectChanges();
        }
    }

    moveTrack(forward = true) {
        const trackWidth = this.track.nativeElement.offsetWidth;
        const currentScrollOffset = this.track.nativeElement.scrollLeft;

        const newLeftOffset = forward
            ? currentScrollOffset + trackWidth - 40
            : currentScrollOffset - trackWidth + 40;

        if (this.track.nativeElement.scrollTo) {
            this.track.nativeElement.scrollTo({
                top: 0,
                left: newLeftOffset,
                behavior: 'smooth',
            });
        } else {
            this.track.nativeElement.scrollLeft = newLeftOffset;
        }
    }

    initScrollListen() {
        if (isPlatformBrowser(this.platformId)) {
            this.stickyListenerBound = this.stickyListener.bind(this);
            this.track.nativeElement.addEventListener(
                'scroll',
                this.stickyListenerBound
            );
        }
    }

    stickyListenerBound;

    stickyListener() {
        if (!this.ticking && isPlatformBrowser(this.platformId)) {
            window.requestAnimationFrame(() => {
                this.last_known_scroll_position = this.track.nativeElement.scrollLeft;

                const trackWidth = this.track.nativeElement.offsetWidth;

                const lastThumbnail = this.thumbnails.find(
                    (thumbnail, index) => {
                        return this.thumbnails.length - 1 === index;
                    }
                );

                if (lastThumbnail) {
                    const lastThumbnailOffset =
                        lastThumbnail.nativeElement.offsetLeft;
                    const lastThumbnailWidth =
                        lastThumbnail.nativeElement.offsetWidth;

                    const endOfTrack = lastThumbnailOffset + lastThumbnailWidth;

                    this.moreNext =
                        this.last_known_scroll_position + trackWidth <
                        endOfTrack;
                    this.morePrev = this.last_known_scroll_position > 0;
                }

                this.ticking = false;
            });

            this.ticking = true;
        }
    }

    getImageAltTag(currentColorGroup) {
        const model = this.productData.Model ? this.productData.Model : '';
        const name = this.productData.Name ? this.productData.Name : '';
        const category = this.productData.Category
            ? this.productData.Category
            : '';
        const fit = this.productData.Fit ? this.productData.Fit : '';

        const colorGroup = this.productVariants.find(group => {
            return group.ColorId === currentColorGroup.ColorId;
        });

        const colorName = colorGroup ? colorGroup.FilterColorName : '';

        const tags = [];

        if (model) {
            tags.push(model);
        }
        if (name) {
            tags.push(name);
        }
        if (category) {
            tags.push(category);
        }
        if (fit) {
            tags.push(fit);
        }
        if (colorName) {
            tags.push(colorName);
        }

        return tags.join(' - ');
    }

    getColorFromHexList(hexList: string[]) {
        if (hexList && hexList.length === 1) {
            return hexList[0];
        }
        if (hexList && hexList.length === 0) {
            return 'grey';
        }
        return `linear-gradient(135deg, ${hexList.join(', ')})`;
    }
}
