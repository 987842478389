<div class="product-zoom-wrapper" *noSSR>
    <div class="product-zoom__close-icon" (click)="closeOverlay()" [innerHtml]="closeIcon | safeHtml"></div>

    <div class="product-zoom" #imageTrack>
        <div class="product-zoom__image-track">
            <div class="product-zoom__image-container" *ngFor="let image of data.images">
                <button class="product-zoom__image"
                        type="button"
                        (click)="closeOverlay()"
                        #productImage
                        [style.paddingBottom.%]="imageHeight(image)"
                        [style.backgroundImage]="'url(' +image.Path + '&w=1200&bgcolor=f1f2f0)' | safeStyle">
                </button>
            </div>
        </div>
    </div>

    <div class="product-zoom__thumbnails">
        <img *ngFor="let image of data.images; let i = index "
            class="product-zoom__thumbnail"
            [ngClass]="{'product-zoom__thumbnail--active': i === currentThumbnail}"
            (click)="scrollToImage(i)"
            [src]="image.Path + '&w=40&h=40&bgcolor=f1f2f0'" />
    </div>

</div>
