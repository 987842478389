import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { ISplashVisibility } from '@shared/product-splash/product-splash.component';

@Component({
    selector: 'app-row-template4',
    template: `
        <div class="exposed-products__row columns">
            <div class="column is-paddingless">
                <ul class="exposed-products__list columns is-multiline">
                    <li *ngFor="let product of data.products"
                        class="exposed-products__item column is-one-quarter is-half-mobile is-paddingless">
                        <app-product-item [product]="product"
                                          [sizeLabels]="(translation.translations$ | async)?.ProductList?.Block"
                                          [splashVisibility]='splashVisibility'
                        ></app-product-item>
                    </li>
                </ul>
            </div>
        </div>
    `,
    styles: [],
})
export class RowTemplate4Component implements OnInit {
    static readonly ref = 'exposedProductTemplate4';

    @Input() data: any;
    @Input() splashVisibility: ISplashVisibility;

    constructor(public translation: TranslationService) {}

    ngOnInit() {}
}
