import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import {TranslationService} from '@core/translation.service';
import {goArrow} from '@shared/svg';

@Component({
    selector: 'app-sublayout50',
    templateUrl: './sublayout50.component.html',
})
export class Sublayout50Component implements OnInit, AfterViewInit {
    constructor(
        private elm: ElementRef,
        public translation: TranslationService
        ) {
    }
    static ref = 'Sublayout50';

    @Input() public data;

    @HostBinding('class.is-hidden-mobile') get isHiddenMobile() {
        return !this.data.ShowOnMobile && this.data.ShowOnTabletAndDesktop;
    }
    @HostBinding('class.is-hidden-tablet') get isHiddenTabletDesktop() {
        return !this.data.ShowOnTabletAndDesktop && this.data.ShowOnMobile;
    }

    public templateName: string;
    public mobileLayout: string;
    public collapseContentToggle: boolean;
    public nonCollapsedContentHeight: string;
    public icon = {
        goArrow
    };

    ngOnInit() {
        for (let i = 0; i < this.data.Column1Spots.length; i++) {
            if (this.data.Column1Spots[i].TemplateName) {
                this.templateName = this.data.Column1Spots[i].TemplateName;
                this.mobileLayout = this.data.Column1Spots[i].MobileLayout;
            }
        }
    }

    toggleCollapseContent() {
        this.nonCollapsedContentHeight = this.elm.nativeElement.querySelector('.columns').scrollHeight + (!!this.data.BackgroundColor ? 60 : 0);
        this.collapseContentToggle = !this.collapseContentToggle;
    }

    ngAfterViewInit(): void {
        if (!!this.data.BackgroundColor && this.data.CollapseContent) {
            this.elm.nativeElement.querySelector('.collapse-content').style.setProperty('--bg-color', this.data.BackgroundColor);
        }
    }
}
