import {DOCUMENT, isPlatformBrowser, Location} from '@angular/common';
import {Component, HostListener, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BasketService} from '@core/basket.service';
import {PageService} from '@core/page.service';
import {PaneService} from '@core/pane.service';
import {PersonalizationBarService} from '@core/personalization-bar.service';
import {ProductService} from '@core/product.service';
import {ProductUtilityService} from '@core/productUtility.service';
import {ScrollBackService} from '@core/scrollBack.service';
import {ISiteContextBgColor} from '@core/site-context.interface';
import {SiteContextService} from '@core/site-context.service';
import {StickyService} from '@core/sticky.service';
import {RaptorEventType, RaptorTrackingService,} from '@core/tracking/raptor-tracking.service';
import {TrackingService} from '@core/tracking/tracking.service';
import {XdbTrackingService} from '@core/tracking/xdb-tracking.service';
import {TranslationService} from '@core/translation.service';
import {UserService} from '@core/user.service';
import {WishlistService} from '@core/wishlist.service';
import {MinibasketComponent} from '@features/minibasket/minibasket.component';
import {
    FormattedProductData,
    ProductSizePickerData,
    ProductSizeVariant,
} from '@features/product-filter/models/product.model';
import {StockForSkuModel} from '@features/product-page/product-spot/stock-for-sku-model';
import {ProductZoomComponent} from '@features/product-page/product-zoom/product-zoom.component';
import {OverlayService} from '@impactdk/ngx-overlay';
import {arrowRight, basket, close, favorittehover, info, mail, store,} from '@shared/svg';
import {
    ImpactCoreBasketsBasketServiceModel as basketModel,
    ImpactCoreModelsProductsDtoStoreStockDto,
} from '@shared/swagger/swagger.interface';
import {imageAltTextFormat, productBulletTags} from '@shared/utility';
import {Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';

import {ProductSizePickerComponent} from '../product-size-picker/product-size-picker.component';
import {ProfitMetricsService} from '@core/tracking/profit-metrics.service';
import {LazyLoadSource} from '@features/ngx-lazyload/lazyload.interface';

//
// PRODUCT SPOT
//
// Terminology explanation:
// ColorGroups = Product variants (different variatons of same product, based on color etc)
// Skus = Product sizes
//

@Component({
    selector: 'app-product-spot',
    templateUrl: './product-spot.component.html',
})
export class ProductSpotComponent implements OnInit, OnDestroy {
    @ViewChild('spot', { static: true }) spot;
    @ViewChild('productSpotContent', { static: true }) productSpotContent;
    @ViewChild('productSpotInformation', { static: true }) productSpotInformation;
    @ViewChild('sidebar', { static: true }) sidebar;
    @ViewChild('sidebarContentWrapper', { static: true }) sidebarContentWrapper;
    @ViewChild('mobileButtonsWrapper', { static: true }) mobileButtonsWrapper;

    currentTab: string | number = 'productInformation';
    ticking = false;
    @Input() productData: any;

    private basketData: basketModel;
    public pageData: any;

    // Product properties
    activeProduct: FormattedProductData; // Current product
    isGiftCardDigital = false;

    // TODO: REPLACE THIS
    selectedColorId: string; // Current product ID
    selectedColorGroup: any;

    // Favorite picker
    styleId: string;
    colorId: string;

    productInfoListItems: any[] = [];
    productColor: string;
    availableSizes: any[] = [];
    availableSizesInStock: any[] = [];
    productSizeStock: number = null;
    disableSizePickerButton = false;

    isShowProduct = false;
    productImageAltText: string;
    getImageAltTagBound;
    selectedSize: ProductSizeVariant = null;
    activeProductImageIndex = 0;
    stockForSku: StockForSkuModel = {
        stock: null,
        sku: '',
    };
    // isColorGroupSoldOut: boolean;
    bgColor: ISiteContextBgColor;

    // Sidebar properties
    productColorVariants: any[] = [];
    sizeNotSelectedMessage: boolean = false;
    isSizeSelected: boolean = true;

    // Schema properties
    productLd = {};
    brandLd = {};

    destroy = new Subject();
    private unsubscribe: Subject<void> = new Subject();

    // Icons
    closeIcon = close;
    favoriteIcon = favorittehover;
    locationIcon = store;
    mailIcon = mail;
    infoIcon = info;
    basketIcon = basket;
    arrowIcon = arrowRight;

    translations: any;

    // Layout properties
    isPreHeaderVisible: boolean;
    isPersonalizationBarVisible: boolean;
    appHeaderHeight: number = 0;
    personalizationBarHeight: number = 0;
    sidebarIsSticky: boolean = false;
    mobileCTAWrapperIsFull: boolean = false;
    accordionProductInfoOpenAsDefault: boolean;


    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        this.scrollBackService.setScrollBack(true);
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private _document,
        private productService: ProductService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private basketService: BasketService,
        private paneService: PaneService,
        private overlay: OverlayService,
        private translationService: TranslationService,
        private stickyService: StickyService,
        private scrollBackService: ScrollBackService,
        private siteContextService: SiteContextService,
        private tracking: TrackingService,
        private xdbTracking: XdbTrackingService,
        private pageService: PageService,
        private productUtil: ProductUtilityService,
        private raptorService: RaptorTrackingService,
        private userService: UserService,
        private wishlistService: WishlistService,
        private personalizationService: PersonalizationBarService,
        private profitMetricsService: ProfitMetricsService
    ) {
        // Get translations
        this.translationService.translations$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(translations => {
                this.translations = translations;
            });

        // Listen for when a product size variant has been selected
        this.productService.currentlySelectedProductSizeVariant$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((productSizeVariant: ProductSizeVariant) => {
                this.selectedSize = productSizeVariant;

                if (productSizeVariant) {
                    this.profitMetricsService.trackPageViewProduct({
                        sku: productSizeVariant.Id,
                        name: this.productData.Name ?
                            this.productData.Name :
                            this.tracking.fallBackProductName(
                                this.productData.Fit,
                                this.productData.Model,
                                this.productData.Category,
                                this.productData.ColorName
                            )
                    });
                }
            });

        // Listen for when product is added successfully to basket
        this.productService.productAddedToBasket$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isAdded: boolean) => {
                if (isAdded) {
                    this.paneService.open(
                        MinibasketComponent,
                        'right',
                        'basket',
                        'minibasket',
                        null,
                        true
                    );
                    // this.productService.setProductSizeVariant(null);
                }
            });

        // Listen for basket state
        this.basketService.basketUpdating$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isUpdating: boolean) => {
                // Disable size picker button
                this.disableSizePickerButton = isUpdating;
            });

        // Listen for size picker trigger events
        this.productService.showProductSizePicker$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((values: any) => {
                if (!values) {
                    return;
                }

                this.openProductSizePicker(
                    values.addToBasket,
                    values.addToFavorites
                );
            });

        // Listen for product size store stock status
        this.productService.productSizeStockStatus$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((stores: ImpactCoreModelsProductsDtoStoreStockDto[]) => {
                if (stores) {
                    this.productSizeStock = stores.length;
                }
            });
        this.productService.currentlySelectedProduct$.subscribe(product => {
            this.styleId = product.productData.StyleId;
            this.colorId = product.activeProductColorId;
        });
    }

    ngOnInit(): void {
        this.scrollToTop();

        this.pageService.page
            .pipe(first())
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                this.pageData = data;
                this.productService.setActiveProductPageData(data);
                this.accordionProductInfoOpenAsDefault = this.pageData.pageData.OpenProductInformationAccordionAsDefault;
            });

        // Update product data properties
        this.setProductProperties();

        // SIDEBAR DATA
        this.productColorVariants = this.colorGroupsInStock();

        // Schemas
        this.generateSchemas();

        // Get available stock
        this.basketService.basket$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(basket => {
                this.basketData = basket;
                if (this.selectedSize !== null) {
                    this.availableStockForSku(this.selectedSize);
                }
            });

        // Product view tracking
        this.productUtil.trackProductViewed();

        // Raptor Tracking
        this.raptorProductTracking();

        // GTM tracking
        this.tracking.sendProductPageView(this.productData);

        if (isPlatformBrowser(this.platformId)) {
            this.stickyService.stickyEvent
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(event => {
                    this.checkMobileButtonsWrapperSticky(event.yPos);
                });
        }
    }

    //
    // PRODUCT PAGE LOGIC
    //

    // Set product related variables and properties
    setProductProperties() {
        let preselectedSizeSku = '';

        // Set active product, will replace all the other functionality
        // This sets up all product related properties, and returns the object back to the component
        this.activeProduct = this.productService.setActiveProduct(
            this.productData
        );

        this.isGiftCardDigital = this.activeProduct.productData.StyleId === '999900';

        this.selectedColorId = '';

        // Detect product variant from url
        this.route.queryParams.subscribe(params => {
            preselectedSizeSku = params['size'];
            if (!!preselectedSizeSku) {
                this.setSizeFromQueryParam(preselectedSizeSku);
            }
            this.checkPreselectedSize(preselectedSizeSku);
        });

        this.productImageAltText = imageAltTextFormat(this.productData.BrandName,
            this.productData.Category,
            this.productData.Model,
            this.activeProduct.activeProductVariant.ColorName);
        this.isShowProduct = this.productData.hasOwnProperty('IsShowProduct');
        this.bgColor = this.siteContextService.getContext().bgColor;
    }

    // Generate Schemas
    generateSchemas() {
        const productLdName = [];

        if (this.productData.Model) {
            productLdName.push(this.productData.Model);
        }

        if (this.productData.Name) {
            productLdName.push(this.productData.Name);
        }

        if (this.productData.productColor) {
            productLdName.push(this.productData.productColor);
        }

        this.productLd = {
            '@context': 'http://schema.org',
            '@type': 'Product',
            productID:
                this.productData.StyleId +
                '-' +
                this.productData.ActiveColorGroupId,
            name: productLdName.join(' | '),
            url: this.pageData.pageData.CanonicalUrl,
            brand: this.productData.BrandName,
            category: this.productData.Category,
            description: this.productData.Description,
            image: this.activeProduct.activeProductVariant
                ? this.activeProduct.activeProductVariant.Images[0].Path
                : '',
            offers: {
                '@type': 'Offer',
                availability: this.activeProduct.activeProductVariant.isSoldOut
                    ? 'Sold out'
                    : ' In stock',
                price: this.activeProduct.activeProductVariant.SalePrice
                    ? this.activeProduct.activeProductVariant.SalePrice + '.00'
                    : this.activeProduct.activeProductVariant.Price + '.00',
                priceCurrency: this.productData.Currency,
            },
        };

        this.brandLd = {
            '@context': 'http://schema.org',
            '@type': 'Brand',
            name: this.productData.BrandName,
        };
    }

    // Sets the size in the size picker
    private setSizeFromQueryParam(sku: string) {
        const currentColorGroup = this.activeProduct.productData.ColorGroups.filter(group => group.ColorId === this.activeProduct.activeProductColorId).pop();
        const size = currentColorGroup.Skus.filter(skuItem => skuItem.Id === sku).pop();
        this.productService.setProductSizeVariant(size as ProductSizeVariant);
    }

    private raptorProductTracking() {
        const user = this.userService.currentUser.getValue();
        const productName =
            this.productData.hasOwnProperty('Name') &&
            this.productData.Name !== ''
                ? this.productData.Name
                : [
                      this.productData.Fit,
                      this.productData.Model,
                      this.productData.Category,
                  ].join(' ');

        this.raptorService.trackProductEvent(
            RaptorEventType.Visit,
            this.productData.StyleId,
            productName,
            ''.concat(
                this.productData.CategoryId,
                '#',
                this.productData.Category
            ),
            this.activeProduct.activeProductVariant.SalePrice
                ? String(this.activeProduct.activeProductVariant.SalePrice)
                : String(this.activeProduct.activeProductVariant.Price),
            this.productData.Currency,
            this.activeProduct.activeProductVariant.ColorId,
            this.productData.BrandId,
            ''.concat(
                this.productData.StyleId,
                '_',
                this.activeProduct.activeProductVariant.ColorId
            ),
            user
        );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        if (isPlatformBrowser(this.platformId)) {
            if ((window as any).zE) {
                (window as any).zE(() => {
                    (window as any).zE.show();
                });
            }
        }
    }

    checkPreselectedSize(sku) {
        const colorGroup = this.selectedColorGroup;

        if (colorGroup && sku) {
            const size = colorGroup.Skus.find(item => {
                return item.Id === sku;
            });

            if (size) {
                this.productService.setProductSizeVariant(size);
            }
        }
    }

    // Add product to basket (uses the active product from product service)
    addActiveProductToBasket() {
        const selectedProductvariant = this.productService.selectedSizeVariant;

        if (selectedProductvariant) {
            this.productService.addProductToBasket();
        } else {
            this.openProductSizePicker(true);
        }
    }

    availableStockForSku(skuItem: any) {
        const lineSkuMatch =
            this.basketData && this.basketData.Lines
                ? this.basketData.Lines.find(line => line.SkuId === skuItem.Id)
                : false;

        let stockForSkuModel: StockForSkuModel;

        if (lineSkuMatch) {
            const availableLineSku = lineSkuMatch.AvailableSizes.find(
                lineSku => lineSku.SkuId === skuItem.Id
            );
            const stockForSkuAvailable =
                availableLineSku.StockCount - lineSkuMatch.Amount;
            stockForSkuModel = {
                sku: lineSkuMatch.SkuId,
                stock: stockForSkuAvailable,
            };
        } else {
            stockForSkuModel = {
                sku: skuItem.Id,
                stock: skuItem.StockCount,
            };
        }

        this.stockForSku = stockForSkuModel;
    }

    // Move to productService
    getSelectedColorGroup() {
        const selectedColorGroup = this.productData.ColorGroups.find(group => {
            return this.selectedColorId === group.ColorId;
        });

        if (!selectedColorGroup.hasOwnProperty('SalePrice')) {
            selectedColorGroup['SalePrice'] = 0;
            selectedColorGroup['PercentageSaving'] = 0;
        }

        this.selectedColorGroup = selectedColorGroup;
        this.activeProduct = this.selectedColorGroup;
        this.availableSizes = this.getActiveProductSizeVariants();
    }

    // Reset important product properties
    resetProduct() {
        // Reset selected size, when product changes
        this.productService.setProductSizeVariant(null);
    }

    imageSource(image) {
        const source: LazyLoadSource = {
            srcSet: `${image.Path}&w=900&h=900&bgcolor=${this.bgColor.primary}`,
            sizes: '50vw',
        };

        return [source];
    }

    // Move to productService
    metaTags() {
        return productBulletTags(this.productData, this.selectedColorId);
    }

    changeProductVariant(colorId) {
        this.productService.setActiveProductByColorId(colorId);

        this.scrollToTop();
        this.updateVariantByColorId(colorId);
        this.updateUrl();

        // Product tracking
        this.productUtil.trackProductViewed();
        // this.resetProduct();
    }

    // NEW
    updateVariantByColorId(colorId: string) {
        // Find active product variant based on color id
        this.activeProduct = this.productService.setActiveProductByColorId(
            colorId
        );
    }

    // Call setSelectedProductSizeVariant in productService
    updateSizeByColor() {
        this.getSelectedColorGroup();
        const colorGroup = this.selectedColorGroup;
        const currentSize = this.selectedSize;

        // this.activeProduct = colorGroup;
        this.activeProduct = this.productService.setActiveProductByColorId(
            colorGroup.colorId
        );

        const newSize = colorGroup.Skus.find(sku => {
            return (
                currentSize &&
                sku.SizeLabel === currentSize.SizeLabel &&
                sku.StockCount > 0
            );
        });

        if (newSize) {
            this.selectedSize = newSize;
            this.availableStockForSku(newSize);
        } else {
            this.selectedSize = null;
        }
    }

    updateUrl() {
        const queryParams = {
            id: this.productData.StyleId + '-' + this.selectedColorId,
        };

        this.location.replaceState(
            this.router.serializeUrl(
                this.router.createUrlTree([], { queryParams })
            )
        );
    }

    // Move to productService (needed?), formerly getAvailableSizes()
    private getActiveProductSizeVariants() {
        const colorGroup = this.productData.ColorGroups.find(group => {
            return this.selectedColorId === group.ColorId;
        });

        this.availableSizes = colorGroup.Skus;

        return colorGroup.Skus;
    }

    private checkMobileButtonsWrapperSticky(yScrollPosition: number) {

        const pageScrollHeight = this._document.documentElement.scrollHeight;
        const siteFooterHeight = this._document.getElementById('site-footer').clientHeight;
        const mobileButtonsWrapperOffset = this.mobileButtonsWrapper.nativeElement.offsetTop;
        const mobileButtonsWrapperHeight = this.mobileButtonsWrapper.nativeElement.clientHeight;
        const mobileButtonsStickyOffset = 100 + window.innerHeight;

        if (
            (yScrollPosition + window.innerHeight >= pageScrollHeight - siteFooterHeight)
        ) {
            this.mobileButtonsWrapper.nativeElement.classList.add('cta-sticky-hidden');
        } else {
            this.mobileButtonsWrapper.nativeElement.classList.remove('cta-sticky-hidden');
        }

        // Mobile buttons wrapper logic
        if (
            yScrollPosition + window.innerHeight >= mobileButtonsStickyOffset &&
            !this.mobileCTAWrapperIsFull
        ) {
            this.mobileCTAWrapperIsFull = true;
            this.mobileButtonsWrapper.nativeElement.classList.add('floating');
        } else if (
            yScrollPosition + window.innerHeight <= mobileButtonsStickyOffset &&
            this.mobileCTAWrapperIsFull
        ) {
            this.mobileCTAWrapperIsFull = false;
            this.mobileButtonsWrapper.nativeElement.classList.remove(
                'floating'
            );
        }
    }

    // OPEN PRODUCT IMAGE OVERLAY AND ZOOM IN ON PRODUCT IMAGE
    imageZoom(image) {
        document.body.classList.add('zoomOverlayOpen');

        const zoomOverlay = this.overlay.open(ProductZoomComponent, {
            data: {
                images: this.activeProduct.activeProductVariant.Images,
                clickedImage: image,
            },
            fullHeight: true,
            hasBackdrop: true,
            positionHorizontal: {
                placement: 'center',
            },
            positionVertical: {
                placement: 'center',
            },
        });

        zoomOverlay.afterClose().subscribe(() => {
            document.body.classList.remove('zoomOverlayOpen');
        });
    }

    scrollToTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
    }

    getImageAltTag(index) {
        const model = this.productData.Model ? this.productData.Model : '';
        const name = this.productData.Name ? this.productData.Name : '';
        const category = this.productData.Category
            ? this.productData.Category
            : '';
        const fit = this.productData.Fit ? this.productData.Fit : '';

        const colorGroup = this.selectedColorGroup;

        const colorName = colorGroup ? colorGroup.ColorName : '';

        const tags = [];

        if (model) {
            tags.push(model);
        }
        if (name) {
            tags.push(name);
        }
        if (category) {
            tags.push(category);
        }
        if (fit) {
            tags.push(fit);
        }
        if (colorName) {
            tags.push(colorName);
        }

        tags.push(index + 1);

        return tags.join(' - ');
    }

    goBack() {
        if (this.pageService.navigationLength > 1) {
            this.location.back();
        } else {
            this.router.navigateByUrl('/');
        }
    }

    // XDB Tracking
    private xDbTrackProduct(colorId?: string) {
        if (isPlatformBrowser(this.platformId)) {
            const color = colorId
                ? colorId
                : this.activeProduct.productData.ActiveColorGroupId;
            this.xdbTracking
                .trackProductView(
                    location.pathname + location.search,
                    this.activeProduct.productData.StyleId,
                    color,
                    this.activeProduct.productData.Category
                )
                .subscribe();
        }
    }

    //
    // SIDEBAR METHODS
    //
    colorGroupsInStock() {
        return this.productData.ColorGroups.filter(colorGroup => {
            let hasStock = false;

            colorGroup.Skus.map(sku => {
                if (sku.StockCount > 0) {
                    hasStock = true;
                }
            });

            return hasStock;
        });
    }

    closeAlert = () => {
        this.sizeNotSelectedMessage = false;
    }

    // HELPER METHODS

    // Size picker
    openProductSizePicker(
        addToBasket: boolean = false,
        addToFavorites: boolean = false
    ) {
        const data: ProductSizePickerData = {
            sizes: this.activeProduct.activeProductVariant.Skus || [],
            sizeGuideUrl: this.activeProduct.productSizeGuideUrl,
            addToBasketAfterSelection: addToBasket,
            addToFavoritesAfterSelection: addToFavorites,
        };

        this.paneService.openChild(ProductSizePickerComponent, 'right', data);
        this.paneService.setActiveKey('productSizePicker');
    }

    getColorName(colorId) {
        const colorGroup = this.productData.ColorGroups.find(group => {
            return group.ColorId === colorId;
        });

        if (colorGroup) {
            return colorGroup.FilterColorName;
        }

        return '';
    }

    // Show stores stock status component
    openStockInStore(): void {
        this.productService.openProductInStoreStock();
    }

    // Open size guide
    openSizeguide() {
        this.productService.openActiveProductSizeguide();
    }

}
