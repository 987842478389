<app-rightpane
    (animationStateChanged)="doSomething($event)"
    [startExit]="startExitTrigger"
    *noSSR>

    <div class="rightpane-container">
        <header class="rightpane-container__header">
            <h2 class="rightpane-container__heading">{{pageData?.StockAgentHeadline}}</h2>
        </header>

        <div class="rightpane-container__content" appScrollbarHide>
            <div class="store-stock">
                <form class="form" (ngSubmit)="onSubmit()" novalidate name="form" #form="ngForm">
                    <div class="store-stock__intro-text" [innerHtml]="introText | safeHtml"></div>
                    <div class="store-stock__product">
                        <img [src]="activeColorGroup.Images[0].Path + '&w=40&h=40&bgcolor=eaebe8'"
                             [srcset]="activeColorGroup.Images[0].Path + '&w=40&h=40&bgcolor=eaebe8 1x,' + activeColorGroup.Images[0].Path + '&w=80&h=80&bgcolor=eaebe8 2x'" alt="">
                        <div class="store-stock__product-name">
                            <b>{{data.product.BrandName}}</b><br>
                            {{data.product.Category}} {{activeColorGroup.FilterColorName}}
                        </div>
                    </div>
                    <div class="form__field form__field_small">
                        <div class="input-container input-container_icon input-container_background">
                            <select
                                class="input input_select input_filled"
                                name="size"
                                id="size"
                                [(ngModel)]="model.skuNumber"
                                (change)="changeSize()"
                                #size="ngModel"
                                required
                            >
                                <option [ngValue]="''">{{(translations | async)?.StockAgent?.RightPane?.DropDownSelectSize}}</option>
                                <option *ngFor="let size of activeColorGroup.Skus" [ngValue]="size.Id">{{size.SizeLabel}}</option>
                            </select>
                            <span class="input-container__icon input-container__icon_expand" [innerHTML]="expandIcon | safeHtml"></span>
                        </div>
                    </div>
                    <div class="form__field" *ngIf="!isProductOnSales">
                        <div class="input-container input-container_icon input-container_background">
                            <select
                                class="input input_select input_filled"
                                name="store"
                                id="store"
                                [(ngModel)]="model.store"
                                #store="ngModel"
                                [disabled]="model.skuNumber === ''"
                                required
                            >
                                <option [ngValue]="''">{{(translations | async)?.StockAgent?.RightPane?.SelectStore}}</option>
                                <option *ngFor="let store of stores" [ngValue]="store">{{store.Name}}</option>
                            </select>
                            <span class="input-container__icon input-container__icon_expand" [innerHTML]="expandIcon | safeHtml"></span>
                        </div>
                    </div>
                    <div class="form__field">
                        <label class="label label_animated">
                            <input
                                type="email"
                                class="input input_text input_bold"
                                name="email"
                                [pattern]="regexService.email"
                                [(ngModel)]="model.receiverEmail"
                                #email="ngModel"
                                appResetInput
                                required
                            >
                            <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.EmailInput}} *</span>
                            <span class="input-container__icon input-container__icon-mail validation-icon validation-icon_normal" [innerHTML]="icons.mail | safeHtml"></span>
                            <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        </label>
                        <div class="form__validation" *ngIf="email.invalid && email.touched">
                            <span class="validation" role="alert"
                                    *ngIf="email?.errors?.required">{{(translations | async)?.GeneralInput?.Content?.EmailInputMissing}}</span>
                            <span class="validation" role="alert"
                                    *ngIf="email?.errors?.pattern">{{(translations | async)?.GeneralInput?.Content?.EmailInputInvalid}}</span>
                        </div>
                    </div>

                    <app-alert class="form__response" *ngIf="responseSuccess" [type]="'success'" [closeCallback]="closeSuccessNotification" [text]="(translations | async)?.StockAgent?.RightPane?.SuccessMessage"></app-alert>
                    <app-alert class="form__response" *ngIf="responseFailed" [type]="'error'" [closeCallback]="closeFailedNotification" [text]="(translations | async)?.StockAgent?.RightPane?.ErrorMessage"></app-alert>

                    <div class="form__action">
                        <button
                            class="button button_full button_primary"
                            type="submit"
                            role="button"
                        >
                            {{(translations | async)?.StockAgent?.RightPane?.SentButtonText}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</app-rightpane>

