import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-store-spot',
    templateUrl: './store-spot.component.html',
})
export class StoreSpotComponent {
    static ref = 'Store spot';

    @Input()
    public data;
}
