import {Component} from '@angular/core';
import {ImpactOverlayRef} from '@impactdk/ngx-overlay';
import {HeaderService} from '@core/header.service';
import {IAutoSuggestResultItem} from '@features/search/search.interface';
import {Router} from '@angular/router';
import {SearchService} from '@features/search/search.service';
import {SiteContextService} from '@core/site-context.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { close } from '@shared/svg';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-search',
    template: `
        <div class="search-bar" [class.search-bar_results]="result.length > 0">
            <div class="close-search-btn" (click)="onClose()" [showOnSite]="['axelDesktopOnly']">{{
                (translation.translations$ | async)?.Axel?.Search?.CloseSearchButtonText
            }}</div>
            <div class="close-search-btn" (click)="onClose()" aria-label="Luk søgning"
                 [showOnSite]="['axelMobileOnly']">
                ${close}
            </div>
            <app-search-field
                (searchResult)="onSearchResult($event)"
                [headerSearch]="true"
                (query)="onQueryChanged($event)"
                (keyboardEvent)="onKeyboardChanged($event)"
                (trigger)="onTrigger($event)"
            ></app-search-field>
            <button class="button button_clean" (click)="onClose()" aria-label="Luk søgningen">
                <app-svg src="cross" size="small"></app-svg>
            </button>
        </div>
        <div
            class="show-all-results"
            [showOnSite]="['axel']"
            *ngIf="result.length > 0"
             (click)="onTrigger(-1)"
        >
            {{(isMobile$ | async) ?
                (translation.translations$ | async)?.Axel?.Search?.ShowAllSearchResultsMobile :
                (translation.translations$ | async)?.Axel?.Search.ShowAllSearchResults | stringFormat: [getSumOfItems(result)]
            }}
        </div>
        <app-search-result [result]="result" [query]="query" (trigger)="onTrigger($event)"
                           [keyboardEvent]="keyboardEvent"></app-search-result>
    `,
})
export class SearchComponent {
    constructor(
        public overlayRef: ImpactOverlayRef,
        private headerService: HeaderService,
        private router: Router,
        private searchService: SearchService,
        private siteContextService: SiteContextService,
        private breakpointObserver: BreakpointObserver,
        public translation: TranslationService
    ) {
        this.isMobile$ = this.breakpointObserver.observe(this.mobileMaxWidth).pipe(map((bpState: BreakpointState) => bpState.matches));
    }

    isOpen = false;
    result: IAutoSuggestResultItem[] = [];
    query: string;
    isMobile$: Observable<boolean>;
    mobileMaxWidth = '(max-width: 1220px)';

    keyboardEvent: KeyboardEvent;

    onSearchResult(result: IAutoSuggestResultItem[]) {
        this.result = result;
    }

    onQueryChanged(query: string) {
        this.query = query;
    }

    onKeyboardChanged(event: KeyboardEvent) {
        this.keyboardEvent = event;
    }

    onClose() {
        this.headerService.headerSolidState = false;
        this.overlayRef.close();
    }

    onTrigger(searchStringIndex: number): void {
        if (this.query?.length > 1) {
            const pickedSearchString: string =
                searchStringIndex === -1
                    ? this.query
                    : this.result[searchStringIndex].label;


            this.searchService.persistedSearchResults = this.result;

            this.overlayRef.close();

            this.router.navigate(
                [this.siteContextService.context.searchSettings.Url],
                {
                    queryParams: { search: pickedSearchString },
                }
            );
            this.onClose();
        }
    }

    getSumOfItems(result: IAutoSuggestResultItem[]): number {
        return result.reduce((a, b) => a + b.count, 0);
    }
}
