import {
    ImpactCoreStoresStoreServiceModel as StoreDto,
    ImpactCoreStoresPeriod,
} from '@shared/swagger/swagger.interface';

export class Store {
    Longitude?: number;
    Latitude?: number;
    Body?: string;
    Heading?: string;
    Openinghours?: string;
    StoreImportEasyposId?: string;
    StoreImportDescription?: string;
    PresentationimageUrl?: string;
    Phone?: string;
    City?: string;
    Zip?: string;
    Floor?: string;
    Streetnumber?: string;
    Address?: string;
    Name?: string;
    LinkToContentPage?: string;
    OpeningPeriods?: ImpactCoreStoresPeriod[];
    StoreSpecialOpeningHours: any[];

    constructor(storeDto: StoreDto) {
        this.Longitude = +storeDto.Longitude;
        this.Latitude = +storeDto.Latitude;
        this.Body = storeDto.Body;
        this.Heading = storeDto.Heading;
        this.Openinghours = storeDto.Openinghours;
        this.StoreImportEasyposId = storeDto.StoreImportEasyposId;
        this.StoreImportDescription = storeDto.StoreImportDescription;
        this.PresentationimageUrl = storeDto.PresentationimageUrl;
        this.Phone = storeDto.Phone;
        this.City = storeDto.City;
        this.Zip = storeDto.Zip;
        this.Floor = storeDto.Floor;
        this.Streetnumber = storeDto.Streetnumber;
        this.Address = storeDto.Address;
        this.Name = storeDto.Name;
        this.LinkToContentPage = storeDto.LinkToContentPage;
        this.OpeningPeriods = storeDto.OpeningPeriods;
        this.StoreSpecialOpeningHours = storeDto.StoreSpecialOpeningHours;
    }
}
