<div class="basket-products">
    <ul class="basket-products__list">
        <li class="basket-products__item" *ngFor="let basketLine of basketLines; trackBy: trackByFn">
            <app-basket-product (updateBasket)="onUpdateBasket($event)" (updateGiftWrap)="onUpdateGiftWrap($event)" [wrappingComment]="wrappingComment"
                [basketLine]="basketLine"></app-basket-product>
        </li>
        <li *ngIf="!basketLines || basketLines?.length === 0">
            {{(translationService.translations$ | async)?.Checkout?.Basket?.NoProducts}}
        </li>
    </ul>
</div>