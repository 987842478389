import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RightpaneModule } from '@features/rightpane/rightpane.module';
import { SearchService } from '@features/search/search.service';
import { SharedModule } from '@shared/shared.module';

import { SearchFieldComponent } from './search-field/search-field.component';
import { SearchComponent } from './search.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SearchBtnComponent } from './search-btn/search-btn.component';

@NgModule({
    imports: [CommonModule, SharedModule, RightpaneModule],
    declarations: [SearchComponent, SearchFieldComponent, SearchResultComponent, SearchBtnComponent],
    exports: [SearchFieldComponent, SearchBtnComponent],
    entryComponents: [SearchComponent],
    providers: [SearchService],
})
export class SearchModule {}
