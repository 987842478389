import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { close } from '@shared/svg';
import { BasketService } from '@core/basket.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImpactCoreBasketsBasketLineServiceModel as basketLineModel } from '@shared/swagger/swagger.interface';

@Component({
    selector: 'app-notification',
    template: `
        <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(message)"></span>
        <button type="button" (click)="onCloseNotification()">
            <app-svg size="small" [src]="icon.close"></app-svg>
        </button>
    `,
})
export class NotificationComponent {
    icon = {
        close,
    };
    @HostBinding('class.notification') get containerClass() { return true; }
    @HostBinding('class.notification_error') get classError() { return this.type === 'error'; }
    @HostBinding('class.notification-size_sm') get sizeSmall() { return this.size === 'small'; }
    @HostBinding('class.notification-size_md') get sizeMedium() { return this.size === 'medium'; }
    @HostBinding('class.notification-size_lg') get sizeLarge() { return this.size === 'large'; }

    @Input() message: string;
    @Input() type: 'error' | 'success';
    @Input() size: 'small' | 'medium' | 'large';

    @Output() closeNotification: EventEmitter<any> = new EventEmitter();

    constructor(
        private basketService: BasketService,
        public sanitizer: DomSanitizer,
    ) {
    }

    onCloseNotification() {
        this.closeNotification.emit();
    }
}
