import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollbarHideDirective } from './scrollbar-hide/scrollbar-hide.directive';
import { ScrollbarService } from '@shared/scrollbar/scrollbar.service';

@NgModule({
    imports: [CommonModule],
    providers: [ScrollbarService],
    declarations: [ScrollbarHideDirective],
    exports: [ScrollbarHideDirective],
})
export class ScrollbarModule {}
