import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    NgZone,
    OnDestroy,
    PLATFORM_ID,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IMPACT_OVERLAY_DATA, ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { close } from '@shared/svg';
import { getNearestNumberIndex } from '@shared/utility';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-product-zoom',
    templateUrl: './product-zoom.component.html',
})
export class ProductZoomComponent implements AfterViewInit, OnDestroy {
    constructor(
        public overlayRef: ImpactOverlayRef,
        @Inject(IMPACT_OVERLAY_DATA) public data: any,
        @Inject(PLATFORM_ID) private platformId: object,
        private router: Router,
        private ngZone: NgZone,
        private changeDetect: ChangeDetectorRef
    ) {}

    @ViewChildren('productImage') productImages;
    @ViewChild('imageTrack') imageTrack: ElementRef<HTMLDivElement>;
    last_known_scroll_position;
    ticking = false;
    closeIcon = close;

    private destroy = new Subject();

    currentThumbnail = 0;

    ngAfterViewInit() {
        const selectedImageIndex = this.data.images.indexOf(
            this.data.clickedImage
        );

        const currentImage = this.productImages.find((ele, index) => {
            return index === selectedImageIndex;
        });

        this.initScrollListen();

        setTimeout(() => {
            this.imageTrack.nativeElement.scrollTop =
                currentImage.nativeElement.offsetTop;
        }, 100);

        this.router.events
            .pipe(takeUntil(this.destroy))
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(data => {
                this.overlayRef.close();
            });
    }

    initScrollListen() {
        if (isPlatformBrowser(this.platformId)) {
            this.ngZone.runOutsideAngular(() => {
                this.stickyListenerBound = this.stickyListener.bind(this);
                this.imageTrack.nativeElement.addEventListener(
                    'scroll',
                    this.stickyListenerBound
                );
            });
        }
    }

    stickyListenerBound;
    stickyListener() {
        if (!this.ticking && isPlatformBrowser(this.platformId)) {
            window.requestAnimationFrame(() => {
                this.last_known_scroll_position = this.imageTrack.nativeElement.scrollTop;
                this.setThumbnail();
                this.ticking = false;
            });

            this.ticking = true;
        }
    }

    ngOnDestroy() {
        if (isPlatformBrowser(this.platformId)) {
            this.imageTrack.nativeElement.removeEventListener(
                'scroll',
                this.stickyListenerBound
            );
        }

        this.destroy.next();
        this.destroy.complete();
    }

    closeOverlay() {
        this.overlayRef.close();
    }

    imageHeight(image) {
        const height = image.Height / image.Width * 100;

        return height;
    }

    scrollToImage(index) {
        const clickedImage = this.productImages.find((ele, i) => {
            return index === i;
        });

        const scrollPos = clickedImage.nativeElement.offsetTop;

        if (!this.imageTrack.nativeElement.scroll) {
            this.imageTrack.nativeElement.scrollTop = scrollPos;
        } else {
            this.imageTrack.nativeElement.scroll({
                top: scrollPos,
                behavior: 'smooth',
            });
        }
    }

    setThumbnail() {
        const centerViewport = this.last_known_scroll_position + window.innerHeight / 5;
        const yPositions = this.productImages.map(ele => {
            return (ele.nativeElement.offsetTop + ele.nativeElement.offsetHeight / 2);
        });

        const newThumbnail = getNearestNumberIndex(yPositions, centerViewport);

        if (newThumbnail !== this.currentThumbnail) {
            this.currentThumbnail = newThumbnail;
            this.changeDetect.detectChanges();
        }
    }
}
