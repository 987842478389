import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImpactCoreModelsProductsDtoSkuDto} from '@shared/swagger/swagger.interface';
import {TranslationService} from '@core/translation.service';
import {
    expand,
} from '@shared/svg';

@Component({
    selector: 'app-product-size-picker',
    template: `
        <div class="input-container input-container_icon input-container_background">
            <select (change)="onChange()" [(ngModel)]="size" class="input input_select input_filled input_round">
                <option value="">{{(translate.translations$ | async)?.Minibasket?.UpSellingProducts?.SelectSize}}</option>
                <option *ngFor="let sku of skusWithStock" name="sku" [value]="sku.Id">{{sku.SizeLabel}}</option>
            </select>
            <div class="input-container__icon input-container__icon_expand" [innerHTML]="expandIcon | safeHtml"></div>
        </div>
    `
})
export class ProductSizePickerComponent implements OnInit {
    constructor(
        public translate: TranslationService,
    ) {
    }

    @Input() skus: ImpactCoreModelsProductsDtoSkuDto[];
    @Input() selectedSku: string;
    @Output() callback = new EventEmitter();

    size: string;
    expandIcon = expand;
    skusWithStock: ImpactCoreModelsProductsDtoSkuDto[];

    ngOnInit(): void {
        this.size = this.selectedSku ?? '';
        this.skusWithStock = this.skus.filter(sku => sku.hasOwnProperty('StockCount') && sku.StockCount > 0);
    }

    onChange() {
        this.callback.emit(this.size);
    }
}
