import {Pipe, PipeTransform} from '@angular/core';
import {ImpactCoreModelsProductsDtoSkuDto as Sku} from "@shared/swagger/swagger.interface";

@Pipe({
    name: 'skuInStock'
})
export class SkuInStockPipe implements PipeTransform {

    transform(sku: Sku[]): Sku[] {
        return sku.filter(item => item.StockCount > 0);
    }

}
