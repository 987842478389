import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    ViewChild,
    Renderer2,
    OnDestroy, OnChanges, SimpleChanges,
} from '@angular/core';
import { ListViewProductModel } from '@shared/swagger/solrsearcher.interface';
import { ScrollBackService } from '@core/scrollBack.service';
import { SiteContextService } from '@core/site-context.service';
import { ISiteContextBgColor } from '@core/site-context.interface';
import { animate, style } from '@angular/animations';
import { TrackingService } from '@core/tracking/tracking.service';
import { TranslationService } from '@core/translation.service';
import { take } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {ISplashVisibility} from '@shared/product-splash/product-splash.component';
import { imageAltTextFormat } from '@shared/utility';

@Component({
    selector: 'app-product-item',
    templateUrl: './product-item.component.html',
})
export class ProductItemComponent implements OnInit, OnDestroy {
    @HostBinding('class') elmClass: string;
    @ViewChild('slideImagesContainer') slideImagesContainer: ElementRef;
    @ViewChild('secondaryImage') secondaryImage: ElementRef;

    @Input() product: ListViewProductModel;

    @Input() sizeLabels: any;

    @Input() index: number;

    @Input() listName: string;

    @Input() splashVisibility: ISplashVisibility;

    @Input() isExposedProduct = false;

    @Input() exposedImage: string[];

    @Input() exposedBgColorImage: string;

    @Input() initialLoad: boolean;

    @Input() rowTemplate: number;

    @Input() rowNumber: number;

    bgColor: ISiteContextBgColor;

    imageBgColor: string;

    translations: any;

    isGiftcard: boolean;

    imageSize = {
        width: 700,
        height: 700,
    };
    exposedImageRatio = 1.286208026208026;

    imageLoaded = false;

    private imageHovered = false;

    private slideCounter;

    animateInAnimation = [
        style({
            opacity: 0,
            transform: 'translateY(25%)',
        }),
        animate(
            '500ms cubic-bezier(0.25, 0, 0, 1)',
            style({
                opacity: 1,
                transform: 'translateY(0)',
            })
        ),
    ];

    constructor(
        private scrollBackService: ScrollBackService,
        private siteContextService: SiteContextService,
        private tracking: TrackingService,
        private translationService: TranslationService,
        private breakPoint: BreakpointObserver,
    ) {}

    ngOnInit() {
        this.isGiftcard = this.product.BrandId === 'GK';

        this.breakPoint
            .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
            .subscribe(result => {
                let imageSquareValue;
                if (
                    result.breakpoints[Breakpoints.HandsetLandscape] ||
                    result.breakpoints[Breakpoints.HandsetPortrait]
                ) {
                    imageSquareValue = 300;
                }
                if (
                    result.breakpoints[Breakpoints.TabletLandscape] ||
                    result.breakpoints[Breakpoints.TabletPortrait]
                ) {
                    imageSquareValue = 400;
                }
                if (
                    result.breakpoints[Breakpoints.WebLandscape] ||
                    result.breakpoints[Breakpoints.WebPortrait]
                ) {
                    imageSquareValue = 370;
                }
                this.imageSize = {
                    width: this.isExposedProduct
                        ? imageSquareValue * 2
                        : imageSquareValue,
                    height: this.isExposedProduct
                        ? Math.ceil(
                              imageSquareValue * 2 * this.exposedImageRatio
                          )
                        : imageSquareValue,
                };
            });

        this.elmClass = this.initialLoad ? 'initial' : '';

        this.bgColor = this.siteContextService.getContext().bgColor;

        this.imageBgColor =
            this.isExposedProduct &&
            this.exposedBgColorImage &&
            this.exposedBgColorImage !== ''
                ? this.exposedBgColorImage
                : this.bgColor.productListItem;

        this.translationService.translations$
            .pipe(take(1))
            .subscribe((translations: any) => {
                this.translations = translations;
            });
    }

    slideImages() {
        this.breakPoint
            .observe([Breakpoints.Large, Breakpoints.Web])
            .subscribe(breakpoint => {
                if (
                    breakpoint.matches &&
                    this.product.FlipImagesOnProductList
                ) {
                    this.slideCounter = setTimeout(() => {
                        if (this.isExposedProduct) {
                            if (
                                !this.imageLoaded &&
                                this.exposedImage.length > 1
                            ) {
                                this.loadFlipImage();
                            }
                        } else {
                            if (
                                !this.imageLoaded &&
                                this.product.ImageUrl.length > 1
                            ) {
                                this.loadFlipImage();
                            }
                        }
                    }, 200);
                }
            });
    }

    private loadFlipImage(): void {
        fetch(
            `${this.product.ImageUrl[1]}&w=${this.imageSize.width}&h=${this.imageSize.height}&bgcolor=${this.imageBgColor}`,
            { method: 'GET' }
        )
            .then(() => {
                this.secondaryImage.nativeElement.src = `${this.product.ImageUrl[1]}&w=${this.imageSize.width}&h=${this.imageSize.height}&bgcolor=${this.imageBgColor}`;
                this.imageHovered = !this.imageHovered;
                setTimeout(() => (this.imageLoaded = true), 0);
            })
            .catch(() =>
                console.error(
                    `Image failed to load! ${this.product.ImageUrl[1]}&w=${this.imageSize.width}&h=${this.imageSize.height}&bgcolor=${this.imageBgColor}`
                )
            );
    }

    slideImagesOff(): void {
        clearTimeout(this.slideCounter);
    }

    setYPosition() {
        this.scrollBackService.setYPosition();
    }

    getImageAltTag() {
        const brand = this.product.Brand ?? '';
        const category = this.product.CategoryDisplayName ?? '';
        const model = this.product.Model ?? '';
        const colorName = this.product.ColorName ?? '';

        return imageAltTextFormat(brand, category, model, colorName);
    }

    getProductTags() {
        const tags = [];

        if (this.product.FilterFit) {
            tags.push(this.product.FilterFit);
        }

        if (this.product.ProductName) {
            tags.push(this.product.ProductName);
        } else {
            tags.push(this.product.CategoryDisplayName);
        }

        if (this.product.ColorName) {
            tags.push(this.product.ColorName);
        }

        return tags.join(' | ');
    }

    // NIS : Dirty hack to fix height on exposed products
    calcBottomPadding(): string {
        const businessDimension = this.siteContextService.getContext()
            .businessDimension;

        if (businessDimension === 'Kaufmann') {
            return 'calc(100% + 104px)';
        }

        if (businessDimension === 'Axel') {
            return 'calc(100% + 102px)';
        }

        if (businessDimension === 'Quint') {
            return 'calc(100% + 113px)';
        }
    }

    sentGTMData() {
        if (this.isExposedProduct) {
            this.tracking.sendExposedProductClick(
                this.product,
                this.rowNumber,
                'Highlighted Product'
            );
        } else {
            this.tracking.sendProductClick(
                this.product,
                this.index,
                this.listName
            );
        }
    }

    ngOnDestroy(): void {
        this.breakPoint.ngOnDestroy();
    }
}
