import {Injectable} from '@angular/core';
import {ITracedock} from '@core/tracking/tracedock.interface';

@Injectable({
    providedIn: 'root'
})
export class TracedockService implements ITracedock {

    private tracedockPromise: Promise<any> | null;

    constructor() {
        this.tracedockPromise = null;
    }

    identify(userId: string): void {
        this.getTracedock()
            .then(td => {
                td.identify(userId);
            });
    }

    private getTracedock(): Promise<ITracedock> {
        if (this.tracedockPromise) {
            return (this.tracedockPromise);
        }

        if (window.td) {
            return (this.tracedockPromise = Promise.resolve(window.td));
        }

        if (window.document.readyState === 'complete') {
            return (this.tracedockPromise = Promise.resolve(new NoTraceDock()));
        }

        this.tracedockPromise = new Promise<ITracedock>((resolve) => {
            window.addEventListener('load', function handleWindowLoad() {
                resolve(window.td.identify ? window.td : new NoTraceDock());
            });
        });

        return (this.tracedockPromise);
    }
}

// tslint:disable-next-line:max-classes-per-file
class NoTraceDock implements ITracedock {
    constructor() {
        console.warn('TraceDock API is not available');
    }

    identify(userId: string): void {
        console.warn('TraceDock API is not available and the identify function is not working.');
    }

}
