import { Component, Input, OnInit } from '@angular/core';
import { MainMenuItem, MenuItem } from '../mobile-menu/menu.interface';
import { TrackingService } from '@core/tracking/tracking.service';

/**
 * Renders a mega menu
 */
@Component({
    selector: 'mega-menu-axel',
    templateUrl: './mega-menu-axel.component.html',
})
export class MegaMenuAxelComponent implements OnInit {
    /**
     * Menu items to show within the mega menu
     */
    @Input() item: MenuItem;
    @Input() listItem: any;

    selectedMenuItem: MainMenuItem;
    showMainMenuNavigation: boolean;

    constructor(private tracking: TrackingService) {
        setTimeout(() => {
            this.selectedMenuItem = this.item.MainMenuItems[0];
        }, 2000);
    }

    ngOnInit() {
        this.selectedMenuItem = this.item.MainMenuItems[0];
        this.showMainMenuNavigation = this.selectedMenuItem && !this.selectedMenuItem.HideTitle && this.selectedMenuItem.NavigationTitle !== '';
    }

    handleClick(link) {
        this.listItem.classList.add('hideMegaMenu');

        setTimeout(() => {
            this.listItem.classList.remove('hideMegaMenu');
        }, 900);

        this.tracking.sendNavigationEvent('Main menu', link);
    }


    getFilename(path) {
        const filePathShards = path.split('/');

        return filePathShards[filePathShards.length - 1];
    }

    getColumnSpanValue(index: number, numOfSpots: number): string {
        if (numOfSpots > 1 && index === 0 && this.item.MegaMenuSpots[index].Size === 'Normal') {
            return '3 / span 2';
        }
        if (numOfSpots > 1 && index === 1 && this.item.MegaMenuSpots[index].Size === 'Normal') {
            return '5 / span 2';
        }
        if (numOfSpots === 1 && this.item.MegaMenuSpots[index].Size === 'Normal') {
            return '5 / span 2';
        }
        if (numOfSpots === 1 && this.item.MegaMenuSpots[index].Size === 'Double') {
            return '3 / span 4';
        }

        return '';
    }
}
