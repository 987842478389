import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-categorylist-sublayout',
    templateUrl: './category-list-sublayout.component.html',
})
export class CategoryListSublayoutComponent {
    @Input() public data;

    static ref = 'CategoryListSublayout';
}
