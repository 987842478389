<h3 class="store-info__sub-header">{{translations?.OpeningHours}}</h3>
<div class="store-info__opening-hours-lines"
    *ngFor="let day of openingPeriods | firstDayOfWeek | specialOpeningHours : specialOpenings ; even as isEven"
     [ngClass]="{'store-info__opening-hours-lines_uneven': isEven, 'store-info__opening-hours-lines_toDay': toDay === day.open.day}"
>
    <div class="store-info__opening-hours__days">{{dayNames[day.open.day]}}</div>
    <div class="store-info__opening-hours__opening-hours" *ngIf="!day.HasSpecialOpeningHour">
        <ng-container *ngIf="day.open.time !== ''">{{day.open.time | timeSeperator}} - {{day.close.time | timeSeperator}}</ng-container>
        <ng-container *ngIf="day.open.time === ''">{{translations?.StoreClosed}}</ng-container>
    </div>
    <div class="store-info__opening-hours__opening-hours" *ngIf="day.HasSpecialOpeningHour">
        {{day.SpecialOpeningHour}}
    </div>
</div>
