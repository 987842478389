import { Directive, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[appPreventPaste]',
})
export class PreventPasteDirective {
    @HostListener('paste', ['$event'])
    onPaste(event: KeyboardEvent) {
        event.preventDefault();
    }
}
