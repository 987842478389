<app-rightpane (animationStateChanged)="doSomething($event)" [startExit]="startExitTrigger" *noSSR>
    <app-minibasket-upselling *ngIf="!isMobile && showUpsellingProducts" [productImageBgColor]="imageBgColor"></app-minibasket-upselling>
    <div class="rightpane-container">
        <header class="rightpane-container__header">
            <h2 class="rightpane-container__heading">{{siteContextService.context?.checkoutSettings?.Minibaskettext}}</h2>
        </header>
        <section class="rightpane-container__top-text" [ngClass]="freeFreightAvailable ? 'text_emphasized' : null">{{freeFreightMessage | stringFormat: (amountBeforefreeFreightValue | kfmCurrency: currency)}}</section>

        <div class="rightpane-container__content" appScrollbarHide>
            <div class="minibasket">
                <div class="minibasket__products">
                    <app-minibasket-products
                        (updateBasket)="onUpdateBasket($event)"
                        [basketLines]="(basketService.basket$ | async)?.Lines"
                        [productImageBgColor]="imageBgColor"
                    ></app-minibasket-products>
                </div>
            </div>
        </div>
        <ng-container *ngIf="(basketService.basket$ | async)?.Lines?.length > 0">
            <div class="rightpane-container__footer rightpane-container__footer_shadow">
                <div class="rightpane-container__footer-description">
                    <header class="minibasket-overview__header">
                        {{(translationService.translations$ | async)?.Minibasket?.Overview?.YourOrder}}
                    </header>
                    <div class="minibasket-overview-total">
                        <div class="minibasket-overview-info">
                            <span class="minibasket-overview-info__label minibasket-overview-info__label_large">
                                {{(translationService.translations$ | async)?.Minibasket?.Overview?.PriceTotal}} ({{(basketService.basket$ | async)?.TotalItemCount}} {{(translationService.translations$ | async)?.Minibasket?.Overview?.TotalProducts}})
                            </span>
                            <span class="minibasket-overview-info__price minibasket-overview-info__price_large">
                                {{(basketService.basket$ | async)?.TotalPrice | kfmCurrency: (basketService.basket$ | async)?.Currency}}
                            </span>
                        </div>
                        <div class="minibasket-overview-info">
                            <span class="minibasket-overview-info__label">{{(translationService.translations$ | async)?.Minibasket?.Overview?.Delivery}}</span>
                            <span class="minibasket-overview-info__price">{{(basketService.basket$ | async)?.DeliveryPrice | kfmCurrency: (basketService.basket$ | async)?.Currency}}</span>
                        </div>
                    </div>
                </div>
                <a [IRouterLink]="siteContextService.context?.checkoutSettings?.GoToBasketLink" class="button button_primary button_full button_icon">
                    <app-svg class="" src="arrow-right" [inline]="true"></app-svg>
                    {{siteContextService.context?.checkoutSettings?.GoToBasketLinkText}}
                </a>
            </div>
        </ng-container>
        <ng-container *ngIf="(basketService.basket$ | async)?.Lines?.length === 0">
            <div class="rightpane-container__footer rightpane-container__footer_shadow">
                <a [IRouterLink]="siteContextService.context?.checkoutSettings?.MinibasketEmptyButton1Link"
                   [ngClass]="{
                    'button_outline': siteContextService?.context?.checkoutSettings?.MinibasketEmptyButton1StyleAsSecondary,
                    'button_primary': !siteContextService?.context?.checkoutSettings?.MinibasketEmptyButton1StyleAsSecondary
                   }"
                   class="button button_full button_icon"
                >
                    <app-svg class="" src="arrow-right" [inline]="true"></app-svg>
                    {{siteContextService?.context?.checkoutSettings?.MinibasketEmptyButton1Text}}
                </a>
                <a [IRouterLink]="siteContextService.context?.checkoutSettings?.MinibasketEmptyButton2Link"
                   [ngClass]="{
                    'button_outline': siteContextService?.context?.checkoutSettings?.MinibasketEmptyButton2StyleAsSecondary,
                    'button_primary': !siteContextService?.context?.checkoutSettings?.MinibasketEmptyButton2StyleAsSecondary
                   }"
                   class="button button_full button_icon"
                >
                    <app-svg class="" src="arrow-right" [inline]="true"></app-svg>
                    {{siteContextService?.context?.checkoutSettings?.MinibasketEmptyButton2Text}}
                </a>
            </div>
        </ng-container>
    </div>

</app-rightpane>
