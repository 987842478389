import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ElementRef,
    EventEmitter, Output
} from '@angular/core';
import {favoriteFill, favoritOpt} from '@shared/svg';
import {FavoriteService} from '@core/favorite.service';
import {IFavoriteProductStore} from '@core/favorite.interface';
import {Subject} from 'rxjs';
import {last, takeUntil, tap} from 'rxjs/operators';

@Component({
    selector: 'app-favorite-picker',
    template: `
        <button type="button"
                class="button button_clean"
                (click)="handleFavoriteClick($event)"
                [class.active]="isFavorite"
                [class.inactive]="!pristineFavorite && !isFavorite"
        >
            <app-svg [src]="heart" [inline] [size]="'unset'"></app-svg>
        </button>
    `
})
export class FavoritePickerComponent implements OnInit, OnDestroy, OnChanges {
    constructor(
        private favoriteService: FavoriteService,
    ) {
    }

    private destroy = new Subject();

    heart = favoriteFill;
    @Input() styleId: string;
    @Input() colorId: string;
    @Output() favoriteClick = new EventEmitter();
    favorites: IFavoriteProductStore[];
    isFavorite: boolean;
    pristineFavorite = true;

    ngOnInit(): void {
        this.favoriteService.favoriteProductStoreList$
            .pipe(
                tap((list) => {
                    this.isFavorite = !!list && !!list.find((product: IFavoriteProductStore) => this.styleId === product.styleId && this.colorId === product.colorId);
                }),
                takeUntil(this.destroy),
            )
            .subscribe(favoriteList => {
                this.favorites = favoriteList;
                this.favoriteClick.emit();
            });
    }

    handleFavoriteClick(event: Event): void {
        event.stopPropagation();
        event.preventDefault();

        if (this.pristineFavorite) {
            this.pristineFavorite = !this.pristineFavorite;
        }

        if (this.isFavorite) {
            this.favoriteService.removeFavorite(this.styleId, this.colorId);
            this.favoriteClick.emit();
        } else {
            const favorite = this.favorites !== undefined ? [...this.favorites, {
                styleId: this.styleId,
                colorId: this.colorId
            }] : [{
                styleId: this.styleId,
                colorId: this.colorId
            }];
            this.favoriteService.favoriteProductStoreList$.next(favorite);
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('colorId')) {
            this.isFavorite = !!this.favoriteService.favoriteProductStoreList$.getValue()
                .filter(favorite => this.styleId === favorite.styleId && favorite.colorId === changes.colorId.currentValue).length;
            this.colorId = changes['colorId'].currentValue;
        }
    }
}
