import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'safeStyle' })
export class SafeStylePipe implements PipeTransform {
    /**
     * Injects our dependencies
     * @param {DomSanitizer} sanitized
     */
    constructor(private sanitized: DomSanitizer) {}

    /**
     * Returns the bypassed raw value
     * @param value
     * @returns {SafeHtml}
     */
    transform(value) {
        return this.sanitized.bypassSecurityTrustStyle(value);
    }
}
