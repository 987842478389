import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { CookieService } from 'ngx-cookie';
import { SiteContextService } from '@core/site-context.service';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import {LoggingService} from '@core/logging.service';

@Injectable()
export class RaptorService {
    RaptorContentId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private siteContextService: SiteContextService,
        @Inject(PLATFORM_ID) private platformId,
        private logging: LoggingService
    ) {}

    getRaptorCookieValue(): string {
        const raptorCustomerId =
            environment.raptorCustomerId[
                this.siteContextService.getContext().businessDimension
            ];
        return raptorCustomerId
            ? this.cookieService.get(raptorCustomerId + 'rsa')
            : null;
    }

    getRaptorRecommendations(data: any) {
        if (isPlatformBrowser(this.platformId)) {
            data['CookieId'] = this.getRaptorCookieValue();
        }
        return this.http
            .post(
                `${
                    environment.raptorRoot
                }/api/raptorrecommendation/RaptorRecommendation`,
                data
            )
            .pipe(
                catchError((err, caught) => {
                    console.warn(`Couldn't find any recommendations`);
                    this.logging.exception(`Error happened in /api/raptorrecommendation/RaptorRecommendation. Error was: ${err}. Caught: ${caught}`);

                    return of({
                        Products: [],
                    });
                })
            );
    }
}
