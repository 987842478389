export class ObserverServiceConfig {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number | number[];
}

export type CallbackType = (inViewport: boolean) => void;

export interface WatchedItem {
    element: Element;
    callback: CallbackType;
}

export interface ExtendedIntersectionObserverEntry
    extends IntersectionObserverEntry {
    isIntersecting: boolean;
}
