import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {TranslationService} from '@core/translation.service';
import {goArrow} from '@shared/svg';

@Component({
    selector: 'app-sublayout-large-small',
    templateUrl: './sublayout-large-small.component.html',
})
export class SublayoutLargeSmall implements OnInit {
    constructor(
        private elm: ElementRef,
        public translation: TranslationService
        ) {
    }
    static ref = 'SublayoutLargeSmall';

    @Input() public data;

    public templateName: string;
    public mobileLayout: string;
    public collapseContentToggle: boolean;
    public nonCollapsedContentHeight: string;
    public icon = {
        goArrow
    };

    ngOnInit() {
        for (let i = 0; i < this.data.Column1Spots.length; i++) {
            if (this.data.Column1Spots[i].TemplateName) {
                this.templateName = this.data.Column1Spots[i].TemplateName;
                this.mobileLayout = this.data.Column1Spots[i].MobileLayout;
            }
        }
    }

    toggleCollapseContent() {
        this.nonCollapsedContentHeight = this.elm.nativeElement.firstElementChild.firstElementChild.firstElementChild.clientHeight;
        this.collapseContentToggle = !this.collapseContentToggle;
    }
}
