import { CommonModule } from '@angular/common';
import {
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf,
} from '@angular/core';

import { LazyPictureComponent } from './lazy-picture/lazy-picture.component';
import { LazyloadDirective } from './lazyload.directive';
import { ObserverServiceConfig } from './observer.interface';
import { ObserverService } from './observer.service';

@NgModule({
    imports: [CommonModule],
    declarations: [LazyloadDirective, LazyPictureComponent],
    exports: [LazyloadDirective, LazyPictureComponent],
})
export class NgxLazyloadModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: NgxLazyloadModule
    ) {
        if (parentModule) {
            throw new Error(
                'NgxLazyloadModule is already loaded. Import it in the AppModule only'
            );
        }
    }

    /**
     * Adds the config options defined the the app module if any
     */
    public static forRoot(config?: ObserverServiceConfig): ModuleWithProviders {
        return {
            ngModule: NgxLazyloadModule,
            providers: [
                {
                    provide: ObserverServiceConfig,
                    useValue: config,
                },
            ],
        };
    }

    /**
     * Adds the config options defined the the app module if any
     */
    public static forFeature(
        config?: ObserverServiceConfig
    ): ModuleWithProviders {
        return {
            ngModule: NgxLazyloadModule,
            providers: [
                {
                    provide: ObserverServiceConfig,
                    useValue: config,
                },
                ObserverService,
            ],
        };
    }
}
