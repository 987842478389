import { Component, Input } from '@angular/core';

@Component({
    selector: 'product-page',
    template: `
        <app-breadcrumb [floated]="breadcrumbFloat" [productPage]="true"></app-breadcrumb>
        <spots [spots]="data"></spots>
        <app-footer *ngIf="showFooter"></app-footer>
    `,
})
export class ProductPageComponent  {
    /**
     * Page content
     */
    @Input() public data;

    /**
     * Reference to resolve the correct component
     * @type {string}
     */
    static ref = 'Product page';

    @Input() public showFooter = true;

    @Input() public breadcrumbFloat = true;
}
