import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'stringFormatUrlInOverlay',
})
export class StringFormatUrlInOverlayPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(
        value: string,
        args?: IStringFormatUrlArg | IStringFormatUrlArg[]
    ): any {
        if (!value) {
            return '';
        }

        value =
            args instanceof Array
                ? this.formatUrl(value, args)
                : this.formatUrl(value, [args]);

        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
    private formatUrl(value, args): string {
        args.forEach((item: IStringFormatUrlArg, index) => {
            value = value.replace(
                `{url${index}}`,
                `<a href="${item.url}">${item.linkText}</a>`
            );
        });
        return value;
    }
}

export interface IStringFormatUrlArg {
    url: string;
    linkText: string;
}
