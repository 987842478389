<form class="form" novalidate name="form" [ngClass]="{'form_submitted': form.submitted}" (ngSubmit)="onSignup(form)" #form="ngForm">

    <div class="columns is-mobile is-multiline is-gapless-vertical">

        <div class="column is-12">
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="email" class="input input_text input_bold" name="email" [pattern]="regexService.email" appResetInput [observe]="true"
                            required [(ngModel)]="user.Email" #email="ngModel" appLowerCaseInput>
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{translations?.GeneralInput?.Content?.EmailInput}}</span>
                    </div>
                </label>
                <div class="form__validation" *ngIf="(email.invalid && email.touched) || form.submitted">
                    <span class="validation" *ngIf="email.errors?.required">{{translations?.GeneralInput?.Content?.EmailInputMissing}}</span>
                    <span class="validation" *ngIf="email.errors?.pattern">{{translations?.GeneralInput?.Content?.EmailInputInvalid}}</span>
                </div>
            </div>
        </div>

        <div class="column is-12">
            <div class="form__field form__field_last">
                <div class="checkbox checkbox_no-overflow signup__newsletter-checkbox">
                    <input type="checkbox" id="newsletterEmail" name="isNewsletterSubscriber" [(ngModel)]="user.IsNewsletterSubscriber" #isNewsletterSubscriber="ngModel">
                    <label class="checkbox__label" for="newsletterEmail">
                        <span [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="checkbox__name">{{translations?.GeneralInput?.Content?.ReceiveNewslettersByEmail}}</span>
                    </label>
                </div>

                <div class="checkbox checkbox_no-overflow">
                    <input type="checkbox" id="userAccept" name="userAccept" [(ngModel)]="userAcceptance" #userAccept="ngModel">
                    <label class="checkbox__label" for="userAccept">
                        <span [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="checkbox__name">
                            {{translations?.Authentication?.CreateUser?.TermsAndConditionsAccept}}
                            <br/>
                            <a (click)="openTerms($event)">{{translations?.Authentication?.CreateUser?.TermsAndConditionsRead}}</a>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="form__action">
        <app-alert class="form__response" *ngIf="response.text" [closeCallback]="closeAlert" [type]="response.type" [text]="response.text"></app-alert>

        <button type="submit" class="button button_primary button_full button_text-center">
            {{translations?.Authentication?.CreateUser?.CreateUserButton}}
            <spinner name="signupSpinner" [size]="20" background="true" inverted="true"></spinner>
        </button>
    </div>
</form>
