import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PaneService} from '@core/pane.service';
import {SiteContextService} from '@core/site-context.service';
import {TrackingService} from '@core/tracking/tracking.service';
import {TranslationService} from '@core/translation.service';
import {SearchService} from '@features/search/search.service';
import {search} from '@shared/svg';
import {getStringParameters} from '@shared/utility';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {IAutoSuggestResultItem} from '@features/search/search.interface';

@Component({
    selector: 'app-search-field',
    templateUrl: './search-field.component.html',
})
export class SearchFieldComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private searchService: SearchService,
        private siteContextService: SiteContextService,
        private paneService: PaneService,
        private tracking: TrackingService,
        public translationService: TranslationService
    ) {
        this.businessDimension = this.siteContextService.getContext().businessDimension.toLowerCase();
    }

    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    /**
     * Detach dropdown usable for search filter page
     */
    @Input() detachable = false;

    /**
     * Initial search string to be used with search query param
     */
    @Input() searchString = '';

    @Input() headerSearch = false;

    @Output() searchResult = new EventEmitter<IAutoSuggestResultItem[]>();
    @Output() query = new EventEmitter<string>();
    @Output() keyboardEvent = new EventEmitter<KeyboardEvent>();
    @Output() trigger = new EventEmitter();

    businessDimension: string;
    /**
     * Search index for arrow usage
     */
    searchStringIndex = -1;

    /**
     * Create initial search array
     */
    searchResults: any[] = [];

    /**
     * Bind input keypress to debounce service call
     */
    searchKeyTrigger = new Subject();

    /**
     * Toggle search results
     */
    searchResultsShown: boolean;

    /**
     * Subscribe to search service call and cancel if needed
     */
    private searchSubscription: Subscription;

    /**
     * Destroy any subscriptions with component
     */
    private destroy: Subject<any> = new Subject();

    /**
     * Prep icons for search
     */
    icons = {
        search,
    };

    ngOnInit() {
        this.searchKeyTrigger
            .pipe(takeUntil(this.destroy), debounceTime(400))
            .subscribe(() => {
                this.query.emit(this.searchString);
                this.getSearchResults();
            });

        this.searchResultsShown = this.detachable ? false : true;

        // Set focus for non detachable search field
        if (!this.detachable) {
            setTimeout(() => this.searchInput.nativeElement.focus());
        }
    }

    /**
     * Toggle visual search results based on detachable input
     */
    searchToggle(event?: Event): void {
        if (this.detachable) {
            if (event && event.type === 'blur') {
                setTimeout(() => {
                    this.searchResultsShown = false;
                }, 200);
            } else {
                if (this.searchService.persistedSearchResults.length > 0) {
                    this.searchResults = this.searchService.persistedSearchResults;
                }

                if (this.searchString && this.searchResults.length === 0) {
                    this.searchSubscription = this.searchService
                        .search(this.searchString)
                        .subscribe(data => {
                            this.searchResults = data;
                        });
                }

                this.searchResultsShown = !this.searchResultsShown;
            }
        }
    }
    searchTrigger() {
        this.trigger.emit();
    }

    private getSearchResults(): void {
        const searchValue = this.searchString.trim();
        if (searchValue.length > 1) {
            // Cancel request if pending
            if (this.searchSubscription) {
                this.searchSubscription.unsubscribe();
            }

            this.searchSubscription = this.searchService
                .search(searchValue)
                .subscribe((data: IAutoSuggestResultItem[]) => {
                    // Fix url for routerLink directive
                    // Seperate params from url and serve them in their own properties
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].url && data[i].url.indexOf('?') > -1) {
                            data[i].urlParams = getStringParameters(data[i].url);
                            data[i].url = data[i].url.split('?')[0];
                        }
                    }

                    this.searchResults = data;
                    this.searchResult.emit(data);

                    let resultAmount = 0;

                    data.map(result => {
                        resultAmount = resultAmount + result.count;
                    });

                    this.tracking.sendSearchResults(
                        searchValue,
                        resultAmount,
                        true
                    );
                });
        } else {
            this.searchResults = [];
            this.searchResult.emit([]);
        }
    }

    /**
     * Trigger action based on keyboard events
     */
    searchKeyEvents(event: KeyboardEvent): void {
        this.keyboardEvent.emit(event);
        this.query.emit(this.searchString);
        switch (event.keyCode) {
            case 13:
                this.trigger.emit();
                return;
            case 27:
                this.reset();
                return;
            default:
                break;
        }
    }

    private reset(): void {
        this.searchString = '';
        this.searchResults = [];
        this.searchResult.emit([]);
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();

        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
    }
}
