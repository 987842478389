<spinner name="basketSpinner" [size]="40" background="true"></spinner>

<div class="basket" #wrapper>
    <div class="basket__section">
        <div class="columns">
            <div class="column is-gapless-vertical is-12-tablet is-6-desktop is-offset-1-desktop">
                <h2 class="basket__heading">
                    {{(translationService.translations$ | async)?.Checkout?.Basket?.BasketHeading}}
                    <ng-container *ngIf="(basketService.basket$ | async)?.Lines?.length > 0">
                        ({{(basketService.basket$ | async)?.TotalItemCount}}
                        <ng-container *ngIf="(basketService.basket$ | async)?.Lines?.length <= 1">{{(translationService.translations$
                            | async)?.Checkout?.Basket?.TotalProduct}}</ng-container>
                        <ng-container *ngIf="(basketService.basket$ | async)?.Lines?.length > 1">{{(translationService.translations$
                            | async)?.Checkout?.Basket?.TotalProducts}}</ng-container>)
                    </ng-container>
                </h2>

                <div class="basket__products">
                    <app-basket-products (updateBasket)="onUpdateBasket($event)"
                        (updateGiftWrap)="onUpdateGiftWrap($event)"
                        [wrappingComment]="(basketService.basket$ | async)?.WrappingComment"
                        [basketLines]="(basketService.basket$ | async)?.Lines"></app-basket-products>
                </div>
            </div>
        </div>
    </div>

    <div class="raptor__section" *ngIf="!isMobileTablet && data.Row1.length">
        <div class="sublayout"
            [ngClass]="{'sublayout_background': data.BackgroundColor, 'sublayout_shadow': data.HasShadow}"
            [style.backgroundColor]="data.BackgroundColor">
            <div class="columns">
                <div class="column is-gapless-vertical is-12-tablet is-6-desktop is-offset-1-desktop">
                    <div *ngFor="let spot of data.Row1"
                         generic-spot-basket
                         [spotType]="spot.TemplateName"
                         [spotData]="spot"
                         [backgroundColor]="data.BackgroundColor"
                         [templateType]="data.TemplateName"
                    ></div>
                </div>
            </div>
        </div>
    </div>

    <div class="checkout__section">
        <div class="columns">
            <div class="column is-12 is-4-desktop is-offset-8-desktop">
                <div class="checkout__sidebar" #sidebar>
                    <div class="basket__overview" *ngIf="(basketService.basket$ | async)?.Lines?.length > 0">
                        <app-basket-overview [basket]="basketService.basket$ | async"></app-basket-overview>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="raptor__section {{ mobileLayout }}" *ngIf="isMobileTablet && data.Row1.length">
        <div class="sublayout"
            [ngClass]="{'sublayout_background': data.BackgroundColor, 'sublayout_shadow': data.HasShadow}"
            [style.backgroundColor]="data.BackgroundColor">
            <div class="columns">
                <div class="column is-gapless-vertical">
                    <div *ngFor="let spot of data.Row1"
                         generic-spot-basket
                         [spotType]="spot.TemplateName"
                         [spotData]="spot"
                         [backgroundColor]="data.BackgroundColor"
                         [templateType]="data.TemplateName"
                    >

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="basket__section basket__delivery">
        <div class="columns">
            <div class="column is-gapless-vertical is-12-tablet is-6-desktop is-offset-1-desktop">
                <h2 class="basket__heading basket__heading_smaller">
                    {{(translationService.translations$ | async)?.Checkout?.Basket?.DeliveryMethodsHeading}}</h2>
                <p class="basket__body"
                    *ngIf="(translationService.translations$ | async)?.Checkout?.Basket?.DeliveryMethodsText !== '#EMPTY#'">
                    {{(translationService.translations$ | async)?.Checkout?.Basket?.DeliveryMethodsText}}
                </p>
                <div class="basket-icons">
                    <ul class="basket-icons__list">
                        <li class="basket-icons__item"
                            *ngFor="let icon of (pageService.page | async)?.pageData?.DeliveryIcons">
                            <ng-container *ngIf="displayIcon(icon); else image"><span
                                    [innerHTML]="icon | safeHtml"></span></ng-container>
                            <ng-template #image><img [src]="icon" /></ng-template>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>

    <div class="basket__section basket__payment">
        <div class="columns">
            <div class="column is-gapless-vertical is-12-tablet is-6-desktop is-offset-1-desktop">
                <h2 class="basket__heading basket__heading_smaller">
                    {{(translationService.translations$ | async)?.Checkout?.Basket?.PaymentMethodsHeading}}</h2>
                <p class="basket__body"
                    *ngIf="(translationService.translations$ | async)?.Checkout?.Basket?.PaymentMethodsText !== '#EMPTY#'">
                    {{(translationService.translations$ | async)?.Checkout?.Basket?.PaymentMethodsText}}
                </p>
                <div class="basket-icons">
                    <ul class="basket-icons__list">
                        <li class="basket-icons__item"
                            *ngFor="let icon of (pageService.page | async)?.pageData?.PaymentIcons">
                            <ng-container *ngIf="displayIcon(icon); else image"><span
                                    [innerHTML]="icon | safeHtml"></span></ng-container>
                            <ng-template #image><img [src]="icon" /></ng-template>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
