import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PaneService } from '@core/pane.service';
import { ImpactCoreModelsDtoNavigationsMainMenuItemDto } from '@shared/swagger/swagger.interface';
import {getVwoId} from '@shared/utility';

const ANIMATION_TIMINGS = '300ms cubic-bezier(0.25, 0, 0, 1)';

type IconLocation = "left" | "right";

@Component({
    selector: 'app-mobile-navigation-accordion',
    template: `
        <div class="mobile_menu-accordion"
            [ngClass]="{
                'accordion_active': getAccordionState() === 'expanded'
            }">
            <button type="button" class="accordion__header" (click)="toggle()"
                [ngClass]=" {
                    'accordion__header__icon_left': iconLocation == 'left',
                    'accordion__header__icon_right': iconLocation == 'right',
                    'accordion_hide-title': hideTitle
                }">

                <h4 class="accordion__header__title" [attr.id]="vwoId(title)">{{title}}</h4>
                <div class="accordion__header__icon" [ngClass]="actAsLink ? 'act-as-link' : ''" [innerHtml]="icon | safeHtml"></div>

            </button>

            <div class="accordion__content" [@bodyExpansion]="getAccordionState()">
                <ng-content></ng-content>
                <div style="margin-bottom: 10px"></div>
            </div>
        </div>

    `,
    animations: [
        trigger('bodyExpansion', [
            state('collapsed', style({ height: '0px', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate(ANIMATION_TIMINGS)),
        ]),
    ],
})
export class MobileNavigationAccordionComponent implements OnInit {
    @Output() onToggle = new EventEmitter();
    @Input() title: string;
    @Input() id: string | number;
    @Input() activeId: string | number;
    @Input() icon: string
    @Input() iconLocation : IconLocation;
    @Input() hideTitle : any;
    @Input() actAsLink: false;
    @Input() link;

    vwoId = getVwoId;

    getAccordionState(): string {
        return (this.hideTitle || this.activeId === this.id) ? 'expanded' : 'collapsed';
    }

    constructor(private router: Router,
                private paneService: PaneService) {}

    ngOnInit() {
    }

    toggle() {

        if (this.actAsLink) {
            this.router.navigateByUrl(this.link);
            this.paneService.closeAll();
            return
        }
        this.onToggle.emit(this.id);
    }
}
