import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogOutletComponent } from '@features/dialog/dialog-outlet/dialog-outlet.component';
import { DialogPageComponent } from './dialog-page/dialog-page.component';
import { DialogPageContentComponent } from './dialog-page-content/dialog-page-content.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        DialogOutletComponent,
        DialogPageComponent,
        DialogPageContentComponent,
    ],
    exports: [DialogOutletComponent],
    entryComponents: [DialogPageComponent],
})
export class DialogModule {}
