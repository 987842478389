import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Helper directive to be used with WYSIWYG content that can contain links, both insite and offsite.
 *
 * It will evaluate if the link is on the same domain, not blank and not download etc, and then either use the router
 * or let the browser do its thing.
 */
@Directive({
    selector: '[intercept-links]',
})
export class InterceptLinksDirective {
    /**
     * Listener for all click events within the host
     * @param $event
     */
    @HostListener('click', ['$event'])
    public onClick($event) {
        // Find the linkElement that was clicked
        let linkEle = $event.target;

        // look up recursively until you find a linkElement
        // break when reaching container
        if (linkEle.tagName !== 'A') {
            while (linkEle && linkEle !== null && linkEle.tagName !== 'A') {
                if (linkEle === this.elementRef.nativeElement) {
                    return;
                }
                linkEle = linkEle.parentElement;
            }
        }

        if (typeof linkEle === 'undefined' || linkEle === null) {
            return;
        }

        if (linkEle.href.includes('#')) {
            const urlFragment = linkEle.href.match(/\#\w+/g);
            const fragment = urlFragment ? urlFragment.join('').replace('#', '') : null;
            const anchorElm = document.getElementById(fragment);
            anchorElm?.scrollIntoView({block: 'start', behavior: 'smooth'});
            return false;
        }

        // Create a Url based on the linkElements href
        const url = new URL(linkEle.href);

        // Eject if the linkElement has a download-attribute
        if (linkEle.getAttribute('download') !== null) {
            return;
        }

        // Eject if link has a target
        if (linkEle.target === '_blank') {
            return;
        }

        // Eject if link points to another domain
        if (url.host !== location.host) {
            return;
        }

        // If you are still here, the router will take over and do the navitaion
        this.router.navigateByUrl(url.pathname + url.search, {
            preserveQueryParams: true,
        });
        return false;
    }

    /**
     * Injects our dependencies
     * @param {Router} router
     * @param {ElementRef} elementRef
     */
    constructor(private router: Router, private elementRef: ElementRef) {}
}
