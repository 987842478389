import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    Renderer2,
} from '@angular/core';
import { comment } from '@shared/svg';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ScrollBackService } from '@core/scrollBack.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-trust-pilot',
    templateUrl: './trust-pilot.component.html',
})
export class TrustPilotComponent implements OnDestroy, AfterViewInit {
    commentIcon = comment;
    isSliderOpen = false;
    loadAPI: Promise<any>;
    @Input() data: any;
    script: any;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private render: Renderer2,
        @Inject(DOCUMENT) private _document,
        private scrollBack: ScrollBackService
    ) {}

    ngAfterViewInit() {
        if (
            isPlatformBrowser(this.platformId) &&
            this.data.TrustPilotBusinessId
        ) {
            this.loadAPI = new Promise(resolve => {
                this.loadScript();
                resolve(true);
            });
        }
    }

    public loadScript() {
        let isFound = false;
        const scripts = this._document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; ++i) {
            if (
                scripts[i].getAttribute('src') != null &&
                scripts[i].getAttribute('src').includes('tp.widget.bootstrap')
            ) {
                isFound = true;
            }
        }

        if (!isFound) {
            const dynamicScripts = [
                'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
            ];

            for (let i = 0; i < dynamicScripts.length; i++) {
                this.script = this.render.createElement('script');
                this.script.src = dynamicScripts[i];
                this.script.type = 'text/javascript';
                this.script.async = false;
                this.script.charset = 'utf-8';
                this.render.appendChild(this._document.head, this.script);
            }
        }

        if ((window as any).Trustpilot) {
            this.scrollBack.animationDone.pipe(first()).subscribe(() => {
                setTimeout(() => {
                    (window as any).Trustpilot.Modules.WidgetManagement.findAndApplyWidgets();
                }, 0);
            });
        }
    }

    ngOnDestroy() {
        if (isPlatformBrowser(this.platformId)) {
            if ((window as any).Trustpilot) {
                (window as any).Trustpilot.Modules.WidgetManagement.widgets = [];
            }
        }
    }

    onToggleReviewSlider() {
        this.isSliderOpen = !this.isSliderOpen;
    }
}
