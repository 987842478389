import {Pipe, PipeTransform} from '@angular/core';
import {isArray} from 'rxjs/internal-compatibility';
import {ImpactCoreModelsDtoMetaMenuMetaMenuItemDto as IMetaMenuItem} from '@shared/swagger/swagger.interface';

@Pipe({
    name: 'metaMenuItemsPipe'
})
export class MetaMenuItemsPipePipe implements PipeTransform {

    transform(value: IMetaMenuItem[], isLoggedIn: boolean): IMetaMenuItem[] {
        const items: IMetaMenuItem[] = value?.filter(item => {
            if (isLoggedIn) {
                if (!item.HideWhenLoggedIn && !item.ShowWhenLoggedIn) {
                    return item;
                } else if (item.ShowWhenLoggedIn) {
                    return item;
                }
            } else {
                if (!item.HideWhenLoggedIn && !item.ShowWhenLoggedIn) {
                    return item;
                } else if (item.HideWhenLoggedIn) {
                    return item;
                }
            }
        });
        return items;
    }
}
