import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-article-text-spot',
    templateUrl: './article-text-spot.component.html',

    host: {
        class: 'column is-12-mobile is-12-tablet is-8-desktop article__gap',
    },
})
export class ArticleTextSpotComponent {
    static ref = 'Article Text Spot';

    @Input()
    public data;
}
