import { IFilterConfig } from '../models/filter.model';
import { environment } from '../../../../environments/environment';

export const FilterConfigs: IFilterConfig[] = [
    {
        key: 'product',
        url: environment.solrSearchRoot + '/api/filter/ProductFilter',
        pageSize: 48,
    },
];
