<div class="minibasket-products__inner">
    <div class="minibasket-product">
        <div class="minibasket-product__image minibasket-product__image_large" [style.background-color]="'#' + productImageBgColor">
            <a [href]="activeColorGroup?.ColorGroupPath" intercept-links>
                <img [src]="activeColorGroup?.Images[0].Path + '&w=109&h=164&bgcolor=' + productImageBgColor" [srcset]="activeColorGroup?.Images[0].Path + '&w=109&h=164&bgcolor=' + productImageBgColor + ' 1x,' + activeColorGroup?.Images[0].Path + '&w=218&h=328&bgcolor=' + productImageBgColor + ' 2x'"
                     alt="{{product?.BrandName}}" />
            </a>
        </div>
        <div class="minibasket-product__details">
            <div class="minibasket-product__top">
                <div class="minibasket-product__image minibasket-product__image_small" [style.background-color]="'#' + productImageBgColor">
                    <a [href]="activeColorGroup?.ColorGroupPath" intercept-links>
                        <img [src]="activeColorGroup?.Images[0].Path + '&w=109&h=164&bgcolor=' + productImageBgColor" [srcset]="activeColorGroup?.Images[0].Path + '&w=109&h=164&bgcolor=' + productImageBgColor + ' 1x,' + activeColorGroup?.Images[0].Path + '&w=218&h=328&bgcolor=' + productImageBgColor + ' 2x'"
                             alt="{{product?.BrandName}}" />
                    </a>
                </div>

                <div class="minibasket-product__name">
                    <header class="minibasket-product__header">
                        <a [href]="activeColorGroup?.ColorGroupPath" intercept-links class="minibasket-product__link">{{product?.BrandName}}</a>
                    </header>
                    <div class="minibasket-product__info">
                        <div class="minibasket-product__type">
                            {{getTags()}}
                        </div>
                    </div>
                    <div class="minibasket-product__price">
                        <div class="basket-product-price">
                            <ng-container *ngIf="!activeColorGroup?.hasOwnProperty('SalePrice')">
                                <div class="basket-product-price__price">{{activeColorGroup?.Price | kfmCurrency : product?.Currency}}</div>
                            </ng-container>
                            <ng-container *ngIf="activeColorGroup?.hasOwnProperty('SalePrice')">
                                <div class="basket-product-price__price basket-product-price__price_before">{{activeColorGroup?.Price | kfmCurrency : product.Currency}}</div>
                                <div class="basket-product-price__price basket-product-price__price_discount">{{activeColorGroup?.SalePrice | kfmCurrency : product.Currency}}</div>
                            </ng-container>
                        </div>
                        <button class="button button_clean button_as-text-link" (click)="onDelete()">{{(translationService.translations$ | async)?.Wishlist?.RightPane?.WishlistRemoveProductButtonText}}</button>
                    </div>
                </div>
            </div>
            <div class="minibasket-product__bottom">
                <div class="minibasket-product__size-amount">
                    <div class="minibasket-product__size">
                        <app-product-size-picker [ngClass]="{'invalid': showSizeMeassage}" [skus]="activeColorGroup.Skus" (callback)="onChangeSize($event)" [selectedSku]="selectedSize"></app-product-size-picker>
                    </div>
                    <div>
                        <app-add-to-basket-standalone
                            [selectedSku]="selectedSize"
                            [product]="activeColorGroup"
                            [arrowDirection]="'left'"
                        ></app-add-to-basket-standalone>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
