import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    PLATFORM_ID,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { AgmInfoWindow, AgmMap, LatLngBounds } from '@agm/core';
import { Store } from '@features/find-store/models/store.model';
import { Router } from '@angular/router';
import { PaneService } from '@core/pane.service';

declare var google: any;

@Component({
    selector: 'app-store-map',
    templateUrl: './store-map.component.html',
})
export class StoreMapComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() stores: Store[];
    @Input() isSearched: boolean;
    @Input() isPane: boolean; // Used for different styling on the pane map
    @Input() mapHeight: number;
    @Input() singleStoreToShow: Store;
    @Output() getStoresFromGeoLocation = new EventEmitter<any>();
    @Output() onStoreSelected = new EventEmitter<any>();
    @ViewChild(AgmMap) map: any;
    @ViewChildren(AgmInfoWindow) snazzyWindowChildren: QueryList<AgmInfoWindow>;

    // Default map location. Shows all of Denmark (Including Bornholm)
    private defaultLat = 56.000611;
    private defaultLng = 12.28473;
    private defaultZoomedOut = 6;
    private defaultZoomedIn = 15;

    // Map bound variables
    public storesToDisplay: Store[] = [];
    public storeMap: any;
    public lat: number = this.defaultLat;
    public lng: number = this.defaultLng;
    public zoom: number = this.defaultZoomedOut;
    public bounds: LatLngBounds;
    // Map styling
    public mapStyling = [
        {
            featureType: 'administrative',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'landscape',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'landscape.man_made',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'landscape.natural',
            stylers: [
                {
                    color: '#EAEBE8',
                },
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'landscape.natural.terrain',
            stylers: [
                {
                    color: '#ffff00',
                },
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#D2E0B8',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.business',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.government',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.medical',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.place_of_worship',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.school',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.sports_complex',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#989C90',
                },
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'transit',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            stylers: [
                {
                    color: '#88A0A4',
                },
                {
                    visibility: 'simplified',
                },
            ],
        },
    ];

    private geoLocationPosition: any;
    private mapIsReady = false;
    private isInit = true;

    constructor(
        private cdr: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: object,
        private router: Router,
        private paneService: PaneService
    ) {}

    ngOnInit() {
        if (this.singleStoreToShow) {
            this.storesToDisplay.push(this.singleStoreToShow);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.map.triggerResize(true);

            // Display stores
            if (!this.singleStoreToShow) {
                this.storesToDisplay = this.stores;
            }
            this.cdr.detectChanges();
        }, 500);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.mapIsReady) {
            if (this.isSearched) {
                this.storesToDisplay = this.stores;

                setTimeout(() => {
                    this.displayStores(this.storesToDisplay);
                }, 0);
            } else if (!this.singleStoreToShow) {
                this.storesToDisplay = this.stores;
            } else {
                this.storesToDisplay = this.stores;

                setTimeout(() => {
                    this.displayStores(this.storesToDisplay);
                }, 0);
            }
        }
    }

    /**
     * Used to ask user permission to get their location.
     *  The location can be used for an automated search.
     *
     *  - Currently not used
     */

    private getUserLocation(): void {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                position => {
                    this.geoLocationPosition = position;
                    this.onGeoLocationFound();
                },
                error => {
                    switch (error.code) {
                        case 1:
                            console.log('Permission Denied');
                            break;
                        case 2:
                            console.log('Position Unavailable');
                            break;
                        case 3:
                            console.log('Timeout');
                            break;
                    }
                }
            );
        }
    }

    /**
     * Activates when the user's geo location has been found
     *
     *  - Currently not used
     */

    private onGeoLocationFound() {
        this.getStoresFromGeoLocation.emit(this.geoLocationPosition);
    }

    /**
     * Activates when map is ready
     * @param map
     */

    public storeMapReady(map) {
        this.storeMap = map;
        this.mapIsReady = true;
        this.displayStores(this.storesToDisplay);
    }

    /**
     * Opens the info window on a marker
     * @param marker_index
     */

    private openInfoWindow(store: Store) {
        const infoWindow = this.snazzyWindowChildren.find((window, i) => {
            return (
                window.latitude === store.Latitude &&
                window.longitude === store.Longitude
            );
        });
        this.closeInfoWindows();
        if (infoWindow != null) {
            infoWindow.open();
        }
    }

    /**
     * Closes all info windows
     */

    public closeInfoWindows() {
        this.snazzyWindowChildren.forEach(item => item.close());
    }

    /**
     * Sets the bounds of the map to show a list of stores
     * @param {Store[]} stores
     */

    private displayStores(stores: Store[]): void {
        this.closeInfoWindows();
        this.cdr.detectChanges(); // Force update after deletion
        if (stores.length !== 0) {
            if (stores.length === 1) {
                if (stores[0].LinkToContentPage) {
                    this.bounds = undefined;
                    if (this.isInit) {
                        setTimeout(() => {
                            this.zoomIn(
                                stores[0].Latitude,
                                stores[0].Longitude
                            );
                            this.openInfoWindow(stores[0]);
                            this.isInit = false;
                        }, 1000);
                    } else {
                        this.zoomIn(stores[0].Latitude, stores[0].Longitude);
                        this.openInfoWindow(stores[0]);
                    }
                }
            } else {
                if (this.isSearched) {
                    this.bounds = this.findStoresBounds(stores);
                    this.storeMap.fitBounds(this.bounds);
                } else {
                    this.zoomOut();
                }
            }
        }
        this.cdr.detectChanges();
    }

    /**
     * Calculates map bounds required to show the inputted list of shops
     * @param {Store[]} stores
     * @returns {LatLngBounds}
     */

    public findStoresBounds(stores: Store[]): LatLngBounds {
        const bounds: LatLngBounds = new google.maps.LatLngBounds();
        for (const store of stores) {
            bounds.extend(
                new google.maps.LatLng(store.Latitude, store.Longitude)
            );
        }
        return bounds;
    }

    /**
     * Zooms the map.
     *  Used to show a single store
     *
     * @param {number} lat
     * @param {number} lng
     */

    zoomIn(lat: number, lng: number): void {
        this.lat = lat;
        this.lng = lng;
        this.zoom = this.defaultZoomedIn;
    }

    /**
     * Zooms out the map
     */

    zoomOut(): void {
        this.lat = this.defaultLat;
        this.lng = this.defaultLng;
        this.zoom = this.defaultZoomedOut;
    }

    /**
     *
     * Opens the store page
     * @param {Store} store
     * @memberof StoreMapComponent
     */

    public storeSelected(store: Store) {
        this.onStoreSelected.emit(store);
    }
}
