import { Component, OnInit, Input } from '@angular/core';
import { ImpactCoreBasketsBasketServiceModel as basketModel } from '@shared/swagger/swagger.interface';
import { TranslationService } from '@core/translation.service';
import { SiteContextService } from '@core/site-context.service';
import { goArrow } from '@shared/svg';

@Component({
    selector: 'app-basket-overview',
    templateUrl: './basket-overview.component.html',
})
export class BasketOverviewComponent {
    /**
     * Basket input from service
     */
    @Input() public basket: basketModel;

    public icons = {
        goArrow,
    };

    constructor(
        public translationService: TranslationService,
        public siteContextService: SiteContextService
    ) {}
}
