import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'xlReformat',
})
export class XlReformatPipe implements PipeTransform {
    XRegexp = new RegExp(/^X+/i);

    transform(facetName: string): string {
        if (facetName.includes('XXXL')) {
            const Xs = facetName.match(this.XRegexp);
            const lastOfFacetName = facetName.substring(
                Xs[0].length,
                facetName.length
            );
            return `${Xs[0].length}X${lastOfFacetName}`;
        }
        return facetName;
    }
}
