import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SiteContextService } from '@core/site-context.service';
import { Wish } from '@features/wishlist/wish.model';
import {
    ImpactWebsiteCodeWebApiControllersEmailControllerSendWishListArgs as SendWishListEmailRequestModel,
} from '@shared/swagger/swagger.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class WishlistService {
    private wishlist: Wish[] = [];
    public $wishlist = new BehaviorSubject<Wish[]>(this.wishlist);

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private http: HttpClient,
        private siteContextService: SiteContextService
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.loadData();
        }
    }

    public getWishlist(): Observable<Wish[]> {
        return this.$wishlist.asObservable();
    }

    public addWish(wish: Wish): void {

        if (!this.contains(wish.StyleId, wish.ColorId, wish.Size)) {
            this.$wishlist.next(this.$wishlist.getValue().concat([wish]));
            this.saveData();
        }
    }

    public deleteWish(wish: Wish): void {
        const array = this.$wishlist.getValue();
        const list = array.filter(item => {
            return !(
                item.StyleId === wish.StyleId &&
                item.ColorId === wish.ColorId &&
                item.Size === wish.Size
            );
        });
        this.$wishlist.next(list);
        this.saveData();
    }

    public deleteWishWithProperties(
        styleId: string,
        colorId: string,
        size: string
    ): void {
        const array = this.$wishlist.getValue();
        const list = array.filter(item => {
            return !(
                item.StyleId === styleId &&
                item.ColorId === colorId &&
                item.Size === size
            );
        });
        this.$wishlist.next(list);
        this.saveData();
    }

    public clearWishlist(): void {
        this.wishlist = [];
    }

    public sendWishlist(body: SendWishListEmailRequestModel): Observable<any> {
        return this.http.post(
            `${this.siteContextService.getRootUrl()}/webapi/Email/SendWishListEmail`,
            body
        );
    }

    public contains(styleId: string, colorId: string, size: string): boolean {
        if (!this.wishlist) { return false; }
        const list = this.$wishlist.getValue();
        const wish = list.find(wish => {
            return (
                colorId === wish.ColorId &&
                styleId === wish.StyleId &&
                size === wish.Size
            );
        });
        return !!wish;
    }

    private saveData() {
        localStorage.setItem(
            'user_wishlist',
            JSON.stringify(this.$wishlist.getValue())
        );
    }

    private loadData() {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('user_wishlist')) {
                const loaded = JSON.parse(
                    localStorage.getItem('user_wishlist')
                );
                const newWishlist = [];
                for (let i = 0; i < loaded.length; i++) {
                    let wish = new Wish('', '', loaded[i]);
                    newWishlist.push(wish);
                }
                this.$wishlist.next(newWishlist); // Load data into Behaviour subject
            } else this.clearWishlist();
        }
    }
}
