<div class="checkbox">
    <input type="checkbox"
           #input
           [id]="forValue(inputId, option.key)"
           [checked]="option.isActive"
           [disabled]="!option.count"
           (change)="onItemChange(option, input.checked)"
    >
    <label class="checkbox__label"
           [ngClass]="{'dot-color': hasHexColor}"
           [for]="forValue(inputId, option.key)"
    >
        <span *ngIf='!hasHexColor' [innerHTML]="icons.checkmark | safeHtml"></span>
        <span *ngIf='hasHexColor'
              [style.--dot-color]="dotSolidColor"
              [style.--dot-border-color]="dotBorderColor"
              [style.--dot-gradient-color]="dotGradientColor"
              [ngClass]="{
                'dot-color-item_gradient': !!dotGradientColor
              }"
              class="dot-color-item"
        ></span>
        <span class="checkbox__name">{{option.name | xlReformat}}</span>
        <span class="checkbox__count">({{option.count}})</span>
    </label>
</div>
