import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { LeftpaneModule } from '@features/leftpane/leftpane.module';
import { RightpaneModule } from '@features/rightpane/rightpane.module';
import { SearchModule } from '@features/search/search.module';

import { ProductFilterComponent } from './product-filter.component';

import { ProductFilterMobileComponent } from '@features/product-filter/product-filter-mobile/product-filter-mobile.component';

import { ProductFilterFacetsComponent } from './product-filter-facets/product-filter-facets.component';
import { ProductFilterFacetListComponent } from './product-filter-facet-list/product-filter-facet-list.component';
import { ProductFilterFacetItemComponent } from './product-filter-facet-item/product-filter-facet-item.component';
import { ProductFilterSliderComponent } from './product-filter-slider/product-filter-slider.component';

import { ProductFilterSmartFilterComponent } from './product-filter-smart/product-filter-smart.component';
import { ProductFilterSmartFilterFacetComponent } from './product-filter-smart-facet/product-filter-smart-facet.component';

import { ProductFilterShowMoreComponent } from './product-filter-show-more/product-filter-show-more.component';
import { ProductFilterShowMoreDialogComponent } from './product-filter-show-more-dialog/product-filter-show-more-dialog.component';

import { VisualFacetsPipe } from '@features/product-filter/pipes/visual-facets.pipe';
import { PopularFacetsPipe } from '@features/product-filter/pipes/popular-facets.pipe';
import { XlReformatPipe } from './pipes/xl-reformat.pipe';
import { MobileFacetLeftPaneComponent } from './product-filter-mobile/mobile-facet-left-pane.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        LeftpaneModule,
        RightpaneModule,
        SearchModule,
        NgxSliderModule,
    ],
    declarations: [
        ProductFilterComponent,
        ProductFilterFacetsComponent,
        ProductFilterFacetItemComponent,
        ProductFilterShowMoreComponent,
        ProductFilterFacetListComponent,
        ProductFilterMobileComponent,
        ProductFilterShowMoreDialogComponent,
        ProductFilterSmartFilterComponent,
        ProductFilterSmartFilterFacetComponent,
        ProductFilterSliderComponent,
        PopularFacetsPipe,
        VisualFacetsPipe,
        XlReformatPipe,
        MobileFacetLeftPaneComponent,
    ],
    exports: [ProductFilterComponent, VisualFacetsPipe, XlReformatPipe],
    entryComponents: [
        ProductFilterMobileComponent,
        ProductFilterShowMoreDialogComponent,
    ],
})
export class ProductFilterModule {}
