<div class="filter__facet-group">
    <div>
        <h2 class="filter__facet-group-headline">{{ facet.name }}</h2>
        <div class="filter__facet-item-container" *ngFor="let item of facet.children">
            <label for="{{facet.EscapedKey}}{{item.name}}">
                <input id="{{facet.EscapedKey}}{{item.name}}"
                       #input
                       class="filter__facet-checkbox"
                       type="checkbox"
                       [checked]="item.isActive"
                       [disabled]="!item.count"
                       (change)="onItemChange(item, input.checked)" />
                <div class="filter__facet-item" [ngClass]="{ 'filter__facet-item_selected' : item.isActive }" >
                    <span class="filter__checkbox-name">{{item.name}}</span>
                    <span class="filter__checkbox-count">({{item.count || 0}})</span>
                </div>
            </label>
        </div>
    </div>
</div>
