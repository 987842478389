import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { SiteContextService } from '@core/site-context.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { RaptorEventType, RaptorTrackingService } from '@core/tracking/raptor-tracking.service';
import { TranslationService } from '@core/translation.service';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { imageAltTextFormat } from '@shared/utility';
import { ISplashVisibility } from '@shared/product-splash/product-splash.component';
import { IRaptorSpotVisibilitySettings } from '@features/spots/product-slider/product-slider.component';

/**
 * Simple component that just renders a product in the [product slider]{@link ProductSliderComponent}
 */
@Component({
    selector: 'product-slider-product',
    templateUrl: './product-slider-product.component.html',
})
export class ProductSliderProductComponent implements OnInit {
    constructor(
        private siteContextService: SiteContextService,
        private tracking: TrackingService,
        private raptorTracking: RaptorTrackingService,
        private translationService: TranslationService,
    ) {
    }

    @Input() public slide: any;
    @Input() public listName: string;
    @Input() public index: number;
    @Input() public raptorFeedType: string;
    @Input() public backgroundColor: string;
    @Input() public imageBgColor: string;
    @Input() public showPrice: string;
    @Input() public visibilitySettings: IRaptorSpotVisibilitySettings;
    @Output() changeDetected = new EventEmitter();

    tags: string[] = [];
    imgBgColor: string;
    translations: any;
    imageUrl: string;
    styleAndColor: string[];
    imageAltText: string;
    splashVisibility: ISplashVisibility = {
        hideExtra: false,
        hideOutlet: false,
        hideSales: false,
        hideNewArrivals: false,
    };
    isGiftcard: boolean;
    sizeLabels: any;

    @HostBinding('style.backgroundColor') cardBackgroundColor: string;

    ngOnInit() {
        this.splashVisibility = {
            ...this.splashVisibility, ...{
                hideExtra: this.visibilitySettings.hideExtraSplash,
                hideOutlet: this.visibilitySettings.hideOutletSplash,
                hideSales: this.visibilitySettings.hideSalesSplash,
                hideNewArrivals: this.visibilitySettings.hideNewArrivalsSplash,
            },
        };
        this.isGiftcard = this.slide.BrandId === 'GK';
        this.imageAltText = imageAltTextFormat(this.slide.BrandName, this.slide.CategoryName, this.slide.Model, this.slide.Color);

        this.styleAndColor = this.slide.ColorGroupId.split('-');
        if (this.slide.FilterFit) {
            this.tags.push(this.slide.FilterFit);
        }

        if (this.slide.ProductName) {
            this.tags.push(this.slide.ProductName);
        } else {
            this.tags.push(this.slide.CategoryName);
        }

        if (this.slide.Color) {
            this.tags.push(this.slide.Color);
        }

        // Replace empty string by null-value
        this.imageBgColor = this.imageBgColor !== '' ? this.imageBgColor : null;
        this.backgroundColor = this.backgroundColor !== '' ? this.backgroundColor : null;

        if (this.backgroundColor) {
            this.cardBackgroundColor = `#${this.backgroundColor}`;
        }

        this.imgBgColor = this.brandBgColor();

        this.translationService.translations$
            .pipe(take(1))
            .subscribe((translations: any) => {
                this.translations = translations;
                this.sizeLabels = translations.ProductList.Block;
            });
        this.imageUrl = environment.dev && environment.local && !this.slide.ImageUrl.includes('http') ? `${environment.cdnAbsolutePath}${this.slide.ImageUrl}?i=${this.slide.ImageUrl}&w=400&h=484&bgcolor=${this.imgBgColor}`
            : `${this.slide.ImageUrl}&w=400&h=484&bgcolor=${this.imgBgColor}`;
    }

    brandBgColor() {
        const context = this.siteContextService.getContext();
        const bgColor = context.bgColor;
        return this.imageBgColor ?? (this.backgroundColor ?? bgColor.productImageBgColor);
    }

    getImageAltTag(slide) {
        const tags = [];

        if (slide.ProductName) {
            tags.push(slide.ProductName);
        }
        if (slide.BrandName) {
            tags.push(slide.BrandName);
        }
        if (slide.CategoryName) {
            tags.push(slide.CategoryName);
        }
        if (slide.Color) {
            tags.push(slide.Color);
        }

        return tags.join(' - ');
    }

    onClick(event) {
        event.preventDefault();
        event.stopPropagation();
        this.trackProductImpression();
        this.raptorItemClick();
    }

    disableDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    trackProductImpression() {
        this.tracking.sendProductClick(
            {
                ColorGroupId: this.slide.ColorGroupId,
                Name: this.slide.ProductName,
                Price: this.slide.IsOnSale ? this.slide.Price : this.slide.OriginalPrice,
                Currency: this.slide.Currency,
                Brand: this.slide.BrandName,
                CategoryDisplayName: this.slide.CategoryName,
                ColorName: this.slide.Color,
            },
            this.index,
            'RAP - ' + this.raptorFeedType,
        );
    }

    raptorItemClick() {
        this.raptorTracking.trackClickEvent(
            this.raptorFeedType,
            RaptorEventType.ItemClick,
            this.slide.ColorGroupId.slice(
                0,
                this.slide.ColorGroupId.indexOf('-'),
            ),
            this.slide.ColorGroupId.replace('-', '_'),
        );
    }

    onFavoriteClick() {
        this.changeDetected.emit();
    }
}
