import {
    whBleachingNonChlorine,
    whBleachingNotAllowed,
    whDrycleaningF2,
    whDrycleaningNotAllowed,
    whDrycleaningP,
    whDrycleaningW,
    whDryingFlatDry,
    whDryingLineDry,
    whDryingTumble,
    whDryingTumbleHighHeat,
    whDryingTumbleLowHeat,
    whDryingTumbleMediumHeat,
    whDryingTumbleNotAllowed,
    whIron,
    whIronHigh,
    whIronLow,
    whIronMedium,
    whIronNotAllowed,
    whWashing,
    whWashing30deg,
    whWashing30degExtraCare,
    whWashing30degPermanentPress,
    whWashing40deg,
    whWashing40degExtraCare,
    whWashing40degPermanentPress,
    whWashing60deg,
    whWashing60degExtraCare,
    whWashing60degPermanentPress,
    whWashingHand,
    whWashingNotAllowed,
    whWringingNotAllowed,
    whWashing90degNew,
    whWashing90deg,
} from '@shared/svg';

export const washIcons = {
    Haandvask: whWashingHand,
    'Ikke rens': whDrycleaningNotAllowed,
    'Ikke stryges': whIronNotAllowed,
    'Ikke toerretumbling': whDryingTumbleNotAllowed,
    Maskingvask: whWashing,
    Maskinvask: whWashing,
    'Mild maskinvask 30': whWashing30degExtraCare,
    'Oxygen blegning': whBleachingNonChlorine,
    Rens: whDrycleaningP,
    'Special rens': whDrycleaningF2,
    Stryges: whIron,
    Toerretumbling: whDryingTumble,
    'ikke bleges': whBleachingNotAllowed,
    'ikke vask': whWashingNotAllowed,
    'kold maskinvask': whWashing30deg,
    'kold maskinvask 30': whWashing30degPermanentPress,
    liggetoerre: whDryingFlatDry,
    'middel maskinvask': whWashing40deg,
    'middel maskinvask 40': whWashing40degPermanentPress,
    'middel maskinvask hoej': whWashing40degExtraCare,
    'stryges hoej': whIronHigh,
    'stryges lav': whIronLow,
    'stryges middel': whIronMedium,
    'tumbling hoej': whDryingTumbleHighHeat,
    'tumbling lav': whDryingTumbleLowHeat,
    'tumbling middel': whDryingTumbleMediumHeat,
    vaadrens: whDrycleaningW,
    'varm maskinvask': whWashing60deg,
    'varm maskinvask 60': whWashing60degPermanentPress,
    'varm maskinvask hoej': whWashing60degExtraCare,
    Haengetoerre: whDryingLineDry,
    'maa ikke vrides': whWringingNotAllowed,
    'varm maskinvask 90': whWashing90degNew,
    'varm maskinvask 95': whWashing90deg,
};
