<div class="newsletter-signup" [ngClass]="{'in-footer-context': inputIsBoxed}">
    <h2 class="newsletter-signup__heading">{{data.Title}}</h2>
    <p class="newsletter-signup__sub-heading">{{data.Subtitle}}</p>

    <form class="form newsletter-signup__form"
        novalidate
        name="form"
        [ngClass]="{'form_submitted': form.submitted}"
        (ngSubmit)="onSubmit(form)"
        #form="ngForm">

        <div class="form__field">
            <label class="label" [ngClass]="{
                'label_animated': !inputIsBoxed}">
                <div class="input-container input-container_icon">
                    <input type="email"
                        class="input input_text newsletter-signup__input"
                        [ngClass]="{'input_boxed': inputIsBoxed}"
                        name="email"
                        appResetInput
                        required
                        [pattern]="'^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'"
                        [(ngModel)]="user.Email"
                        #email="ngModel"
                        [placeholder]="inputIsBoxed ? (translationService.translations$ | async).GeneralInput?.Content?.EmailInput : ''"
                        appLowerCaseInput>
                    <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                    <span class="label__text" [ngClass]="{'sr-only': inputIsBoxed}">
                        {{(translationService.translations$ | async).GeneralInput?.Content?.EmailInput}}
                    </span>
                    <button type="submit" class="newsletter-signup__button" *ngIf="inputIsBoxed">
                        <span class="newsletter-signup__button-icon" [innerHTML]="icons.goArrow | safeHtml"></span>
                        <spinner [name]="acceptanceId" [size]="20" background="true" inverted="true"></spinner>
                    </button>
                </div>
            </label>
            <div class="form__validation" *ngIf="(email.invalid && email.touched) || form.submitted">
                <span class="validation" *ngIf="email.errors?.required">{{translations.GeneralInput?.Content?.EmailInputMissing}}</span>
                <span class="validation" *ngIf="email.errors?.pattern">{{translations.GeneralInput?.Content?.EmailInputInvalid}}</span>
            </div>
        </div>

        <div class="form__action newsletter-signup__action">
            <div class="newsletter-signup__checkbox checkbox checkbox_no-overflow">
                <input type="checkbox" [id]="acceptanceId" [name]="acceptanceId" [(ngModel)]="userAcceptance" #newsletterSignupUserAccept="ngModel" class="newsletter-signup__input">
                <label class="checkbox__label" [for]="acceptanceId">
                    <span [innerHTML]="icons.checkmark | safeHtml"></span>
                    <span class="checkbox__name">
                        {{(translationService.translations$ | async).NewsletterSignup?.Spot?.TermsAndConditionsAccept}}
                        <a *ngIf="data?.TermsLink" (click)="openTerms($event)">
                            {{(translationService.translations$ | async).NewsletterSignup?.Spot?.TermsAndConditionsRead}}
                        </a>
                    </span>
                </label>
            </div>

            <button type="submit" class="button button_primary button_small" *ngIf="!inputIsBoxed">
                {{(translationService.translations$ | async).NewsletterSignup?.Spot?.SignupButton}}
                <spinner [name]="acceptanceId" [size]="20" background="true" inverted="true"></spinner>
            </button>
        </div>

        <app-alert class="form__action" *ngIf="response.text" [closeCallback]="closeAlert" [type]="response.type" [text]="response.text"></app-alert>
    </form>
</div>
