import { Component, Inject, OnInit } from '@angular/core';
import { OrderViewModel } from '@shared/swagger/easypos.interface';
import { IMPACT_OVERLAY_DATA, ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { cross, store } from '@shared/svg';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-receipt',
    templateUrl: './receipt.component.html',
})
export class ReceiptComponent implements OnInit {
    public date: Date;
    public calculatedHeight: number;

    public icons = {
        cross,
        store,
    };

    constructor(
        public overlayRef: ImpactOverlayRef,
        public translationService: TranslationService,
        @Inject(IMPACT_OVERLAY_DATA) public data: OrderViewModel
    ) {}

    ngOnInit() {
        this.convertViewModel();
    }

    public onExit() {
        this.overlayRef.close();
    }

    private convertViewModel() {
        if (this.data.Completed) {
            this.date = new Date(this.data.Completed);
        }
        if (this.data.InvoiceAddress.Phone) {
            this.data.InvoiceAddress.Phone = ReceiptComponent.convertPhoneNumber(
                this.data.InvoiceAddress.Phone
            );
        }
        if (this.data.DeliveryAddress.Phone) {
            this.data.DeliveryAddress.Phone = ReceiptComponent.convertPhoneNumber(
                this.data.DeliveryAddress.Phone
            );
        }
        if (this.data.OrderLines) {
            this.calculatedHeight =
                90 * this.data.OrderLines.length + 50 + 30 + 335;
        }
    }

    public isGiftCard(): boolean {
        if (!this.data.GiftCards) return false;
        return this.data.GiftCards.length !== 0;
    }

    /**
     * Converts phone number from format:
     *      XXXXXXXX
     * to format:
     *      XX XX XX XX
     */

    static convertPhoneNumber(phone: string) {
        phone =
            phone.substring(0, 2) +
            ' ' +
            phone.substring(2, 4) +
            ' ' +
            phone.substring(4, 6) +
            ' ' +
            phone.substring(6, 8);
        return phone;
    }
}
