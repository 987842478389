import { Component, Input } from '@angular/core';
import {
    RaptorEventType,
    RaptorTrackingService,
} from '@core/tracking/raptor-tracking.service';
import { UserService } from '@core/user.service';
import { getFilename } from '@shared/utility';

@Component({
    selector: 'app-article-reference-spot',
    templateUrl: './article-reference-spot.component.html',
    host: { class: 'column' },
})
export class ArticleReferenceSpotComponent {
    static ref = 'Article Reference Spot';

    @Input() public data;
    imagePathName = getFilename;

    constructor(
        private raptorTracking: RaptorTrackingService,
        private userService: UserService
    ) {}

    raptorItemClick() {
        if (this.data.RaptorContentId) {
            const user = this.userService.currentUser.getValue();
            this.raptorTracking.trackContentEvent(
                RaptorEventType.ItemClick,
                this.data.RaptorContentId,
                this.data.RaptorContentTags,
                user
            );
        }
    }
}
