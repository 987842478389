<app-rightpane (animationStateChanged)="doSomething($event)" [startExit]="startExitTrigger" *noSSR>

    <div class="rightpane-container">
        <header class="rightpane-container__header">
            <h2 class="rightpane-container__heading">{{(translations | async)?.Authentication?.Login?.LoginOverlayTitle}}</h2>
        </header>
        <div class="rightpane-container__content" appScrollbarHide>
            <app-login-spot-component (paneClose)="onPaneClose()"></app-login-spot-component>
        </div>
    </div>

</app-rightpane>
