import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { SpinnerComponent } from '@shared/spinner/spinner.component';

/** Based on:
 *  https://github.com/Chevtek/angular-spinners **/

@NgModule({
    imports: [CommonModule],
    providers: [SpinnerService],
    declarations: [SpinnerComponent],
    exports: [SpinnerComponent],
})
export class SpinnerModule {}
