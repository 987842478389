import { animate, style, transition, trigger } from '@angular/animations';
import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IAutoSuggestResultItem} from '@features/search/search.interface';
import {Router} from '@angular/router';
import {SearchService} from '@features/search/search.service';
import {SiteContextService} from '@core/site-context.service';
import {PaneService} from '@core/pane.service';
import {TrackingService} from '@core/tracking/tracking.service';
import {TranslationService} from '@core/translation.service';

@Component({
    selector: 'app-search-result',
    template: `
        <div class="search-result columns" *ngIf="result.length">
            <section class="search-result__column column is-4-tablet">
                <header>
                    <h3 class="search-result__headline">{{(translationService.translations$ | async)?.Search?.ResultOverlay?.SearchResultsHeading}}</h3>
                    <ul class="search-results__list">
                        <li class="search-results__item"
                            *ngFor="let result of result; let i = index; trackBy: trackByFn"
                            @enterRemoveAnimation
                            [ngSwitch]="result.url !== null">

                            <!-- If result item has an url to page -->
                            <a *ngSwitchCase="true"
                               [IRouterLink]="result.url"
                               [queryParams]="result.urlParams"
                               [closeDialog]="true"
                               class="search-results__link"
                               [ngClass]="{ 'search-results__link_active': searchStringIndex === i }">
                                <div [innerHTML]="result.label | highlight: query | safeHtml"></div>
                                <div class="search-results__count">({{result.count}})</div>
                            </a>

                            <!-- Else, we trigger the search page -->
                            <button type="button"
                                    *ngSwitchCase="false"
                                    (click)="searchTrigger(i)"
                                    class="search-results__link"
                                    [ngClass]="{ 'search-results__link_active': searchStringIndex === i }">
                                <div [innerHTML]="result.label | highlight: query | safeHtml"></div>
                                <div class="search-results__count">({{result.count}})</div>
                            </button>
                        </li>
                    </ul>
                </header>
            </section>
            <section class="search-result__column column"></section>
        </div>
    `,
    animations: [
        trigger('enterRemoveAnimation', [
            transition(':enter', [
                style({
                    opacity: '0',
                    transform: 'translateX(10%)',
                }),
                animate(
                    '300ms cubic-bezier(0.35, 0, 0.25, 1)',
                    style({
                        opacity: '1',
                        transform: 'translateX(0)',
                    })
                ),
            ]),
            transition(':leave', [
                style({
                    opacity: '1',
                    transform: 'translateX(0)',
                }),
                animate(
                    '300ms cubic-bezier(0.35, 0, 0.25, 1)',
                    style({
                        opacity: '0',
                        transform: 'translateX(10%)',
                    })
                ),
            ]),
        ]),
    ],
})
export class SearchResultComponent implements OnInit, OnChanges {

    constructor(
        public translationService: TranslationService
    ) {
    }
    /**
     * Detach dropdown usable for search filter page
     */
    @Input() detachable = false;

    @Input() result: IAutoSuggestResultItem[];
    @Input() query: string;
    @Input() keyboardEvent: KeyboardEvent;

    @Output() trigger = new EventEmitter<number>();

    /**
     * Search index for arrow usage
     */
    searchStringIndex = -1;

    ngOnInit(): void {
    }

    /**
     * Trigger action based on keyboard events
     */
    searchKeyEvents(key: number): void {
        switch (key) {
            case 13:
                this.trigger.emit(this.searchStringIndex);
                return;
            case 38:
                this.up();
                return;
            case 40:
                this.down();
                return;
            default:
                break;
        }

        // Reset if search term index is set (above -1)
        if (this.searchStringIndex > -1) {
            this.searchStringIndex = -1;
        }
    }

    /**
     * Trigger search by mouse click and go to search page with params
     */
    searchTrigger(index?: number): void {
        if (index !== undefined) {
            this.searchStringIndex = index;
        }

        this.trigger.emit(this.searchStringIndex);
    }

    private up(): void {
        // Prevent up-arrow to go to start of input. This has to be set together with keydown
        event.preventDefault();

        if (this.searchStringIndex > -1) {
            this.searchStringIndex--;
        }
    }

    private down(): void {
        if (this.searchStringIndex < this.result.length - 1) {
            this.searchStringIndex++;
        }
    }

    trackByFn(index, item) {
        return index;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('keyboardEvent') && changes.keyboardEvent.currentValue) {
            this.searchKeyEvents(changes.keyboardEvent.currentValue.keyCode);
        }
    }
}
