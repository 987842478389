<div class="customer-profile">
    <form class="form" novalidate name="form" #form="ngForm">
        <div class="customer__section customer__section_no-margin">
            <h2 class="customer__heading customer__heading_icon">
                {{translations?.Profile?.Content?.InformationSelectionTitle}}
                <span class="customer__icon customer__icon-user" [innerHTML]="icons.user | safeHtml"></span>
            </h2>

            <div class="columns is-mobile is-multiline is-gapless-vertical">
                <div class="column is-6">
                    <div class="form__field">
                        <label class="label label_animated">
                            <input type="text" appResetInput [observe]="true" class="input input_text input_bold" name="firstName" (change)="onChange()" [(ngModel)]="user.FirstName"
                                #firstName="ngModel">
                            <span class="label__text">{{translations?.GeneralInput?.Content?.FirstNameInput}}</span>
                        </label>
                    </div>
                </div>
                <div class="column is-6">
                    <div class="form__field">
                        <label class="label label_animated">
                            <input type="text" appResetInput [observe]="true" class="input input_text input_bold" name="lastName" (change)="onChange()" [(ngModel)]="user.LastName"
                                #lastName="ngModel">
                            <span class="label__text">{{translations?.GeneralInput?.Content?.SurnameInput}}</span>
                        </label>
                    </div>
                </div>
                <div class="column is-8">
                    <div class="form__field">
                        <label class="label label_animated">
                            <input type="email" appResetInput [observe]="true" class="input input_text input_bold" name="email" (change)="email.valid && onChange({ NewEmail: email.value })"
                                [pattern]="regexService.email" required [ngModel]="user.Email" appLowerCaseInput
                                #email="ngModel">
                            <span class="label__text">{{translations?.GeneralInput?.Content?.EmailInput}}</span>
                        </label>
                        <div class="form__validation" *ngIf="email.invalid && email.touched">
                            <span class="validation" *ngIf="email.errors?.required">{{translations?.GeneralInput?.Content?.EmailInputMissing}}</span>
                            <span class="validation" *ngIf="email.errors?.pattern">{{translations?.GeneralInput?.Content?.EmailInputInvalid}}</span>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="form__field">
                        <label class="label label_animated">
                            <input type="text" appResetInput [observe]="true" class="input input_text input_bold" name="phoneNumber" (change)="onChange()" [(ngModel)]="user.PhoneNumber"
                                #phoneNumber="ngModel">
                            <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                            <span class="label__text">{{translations?.GeneralInput?.Content?.PhoneInput}}</span>
                        </label>
                    </div>
                </div>
                <div class="column is-6">
                    <div class="form__field form__field_last">
                        <label class="label label_animated">
                            <div class="input-container input-container_icon">
                                <select class="input input_select input_bold" appResetInput [observe]="true" name="gender" (change)="onChange()" [(ngModel)]="user.Gender" #gender="ngModel">
                                    <option [ngValue]="" selected></option>
                                    <option [ngValue]="'Male'">{{translations?.GeneralInput?.Content?.GenderInputOptionMale}}</option>
                                    <option [ngValue]="'Female'">{{translations?.GeneralInput?.Content?.GenderInputOptionFemale}}</option>
                                </select>
                                <span class="input-container__icon input-container__icon-expand" [innerHTML]="icons.expand | safeHtml"></span>
                                <span class="label__text">{{translations?.GeneralInput?.Content?.GenderInput}}</span>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="column is-6">
                    <div class="form__field form__field_last">
                        <label class="label label_animated">
                            <div class="input-container input-container_icon">
                                <input type="text" appResetInput [observe]="true" class="input input_text input_bold" matInput [matDatepicker]="myDatepicker" (click)="myDatepicker.open()" readonly name="birthday" (dateChange)="onChange()"
                                    [(ngModel)]="user.Birthday" #birthday="ngModel">
                                <mat-datepicker #myDatepicker></mat-datepicker>
                                <span class="input-container__icon input-container__icon-expand" [innerHTML]="icons.expand | safeHtml"></span>
                                <span class="label__text">{{translations?.GeneralInput?.Content?.BirthdayInput}}</span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="customer__section customer__section_colored">
            <h2 class="customer__heading customer__heading_icon">
                {{translations?.Profile?.Content?.AddressSelectionTitle}}
                <span class="customer__icon customer__icon-home" [innerHTML]="icons.home | safeHtml"></span>
            </h2>

            <div class="columns is-mobile is-multiline is-gapless-vertical">
                <div class="column is-6">
                    <div class="form__field">
                        <label class="label label_animated">
                            <input type="text" appResetInput [observe]="true" class="input input_text input_bold" name="address" (change)="onChange()" [(ngModel)]="user.Address"
                                #address="ngModel">
                            <span class="label__text">{{translations?.GeneralInput?.Content?.StreetNameInput}}</span>
                        </label>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="form__field">
                        <label class="label label_animated">
                            <input type="text" appResetInput [observe]="true" class="input input_text input_bold" name="houseNumber" (change)="onChange()" [(ngModel)]="user.HouseNumber"
                                #houseNumber="ngModel">
                            <span class="label__text">{{translations?.GeneralInput?.Content?.StreetNumberInput}}</span>
                        </label>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="form__field">
                        <label class="label label_animated">
                            <input type="text" appResetInput [observe]="true" class="input input_text input_bold" name="floor" (change)="onChange()" [(ngModel)]="user.Floor"
                                #floor="ngModel">
                            <span class="label__text">{{translations?.GeneralInput?.Content?.FloorInput}}</span>
                        </label>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="form__field">
                        <label class="label label_animated">
                            <input type="text" appResetInput [observe]="true" class="input input_text input_bold" name="zipCode" (change)="onChange()" (keyup)="setCity(zipCode.value)"
                                [(ngModel)]="user.ZipCode" #zipCode="ngModel">
                            <span class="label__text">{{translations?.GeneralInput?.Content?.ZipcodeInput}}</span>
                        </label>
                    </div>
                </div>
                <div class="column is-9">
                    <div class="form__field">
                        <label class="label label_animated">
                            <input type="text" appResetInput [observe]="true" class="input input_text input_bold" name="city" (change)="onChange()" [(ngModel)]="user.City"
                                #city="ngModel">
                            <span class="label__text">{{translations?.GeneralInput?.Content?.CityInput}}</span>
                        </label>
                    </div>
                </div>
                <div class="column is-12">
                    <div class="form__field form__field_last">
                        <label class="label label_animated">
                            <div class="input-container input-container_icon">
                                <select class="input input_select input_bold" appResetInput [observe]="true" name="country" (change)="onChange()" [(ngModel)]="user.Country"
                                    #country="ngModel">
                                    <option [ngValue]="" selected></option>
                                    <option *ngFor="let country of countries.Countries" [ngValue]="country">{{country}}</option>
                                </select>
                                <span class="input-container__icon input-container__icon-expand" [innerHTML]="icons.expand | safeHtml"></span>
                                <span class="label__text">{{translations?.GeneralInput?.Content?.CountryInput}}</span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="customer__section">
            <h2 class="customer__heading customer__heading_icon">
                {{translations?.Profile?.Content?.MyShopSelectionTitle}}
                <span class="customer__icon customer__icon-store" [innerHTML]="icons.store | safeHtml"></span>
            </h2>

            <div class="form__field form__field_last">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <select class="input input_select input_bold" appResetInput [observe]="true" name="store" (change)="onChange()" [(ngModel)]="user.Store" #store="ngModel">
                            <option [ngValue]="" selected></option>
                            <option *ngFor="let store of stores" [ngValue]="store.StoreImportEasyposId">{{store.StoreImportDescription}}</option>
                        </select>
                        <span class="input-container__icon input-container__icon-expand" [innerHTML]="icons.expand | safeHtml"></span>
                        <span class="label__text">{{translations?.GeneralInput?.Content?.FavoriteStoreInput}}</span>
                    </div>
                </label>
            </div>
        </div>

        <div class="customer__section customer__section_colored">
            <h2 class="customer__heading customer__heading_icon">
                {{translations?.Profile?.Content?.NewsletterSelectionTitle}}
                <span class="customer__icon customer__icon-mail" [innerHTML]="icons.mail | safeHtml"></span>
            </h2>

            <div class="checkbox checkbox_no-overflow customer-profile__newsletter-checkbox">
                <input type="checkbox" id="newsletterEmail" name="isNewsletterSubscriber" (change)="onChange()" [(ngModel)]="user.IsNewsletterSubscriber"
                    #isNewsletterSubscriber="ngModel">
                <label class="checkbox__label" for="newsletterEmail">
                    <span [innerHTML]="icons.checkmark | safeHtml"></span>
                    <span class="checkbox__name">{{translations?.GeneralInput?.Content?.ReceiveNewslettersByEmail}}</span>
                </label>
            </div>
            <div class="checkbox checkbox_no-overflow">
                <input type="checkbox" id="newsletterSMS" name="isSms" (change)="onChange()" [(ngModel)]="user.IsSms" #isSms="ngModel">
                <label class="checkbox__label" for="newsletterSMS">
                    <span [innerHTML]="icons.checkmark | safeHtml"></span>
                    <span class="checkbox__name">{{translations?.GeneralInput?.Content?.ReceiveNewsLettersBySMS}}</span>
                </label>
            </div>
        </div>

        <div class="form__action">
            <app-alert class="form__response" *ngIf="response.text" [closeCallback]="closeAlert" [type]="response.type" [text]="response.text"></app-alert>
        </div>
    </form>
</div>
