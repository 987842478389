import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { SiteContextService } from '@core/site-context.service';
import { BehaviorSubject } from 'rxjs';

const stateKey = makeStateKey<any>('GetTranslations');

/**
 * Translation service
 */
@Injectable()
export class TranslationService {
    /**
     * Subject holder
     */
    private _subject = new BehaviorSubject<any>({});

    /**
     * Translations as an observable
     */
    public translations$ = this._subject.asObservable();
    private language: string;

    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService,
        private statetransferService: TransferState
    ) {
        this.language = this.siteContextService.getContext().language;
    }

    /**
     * Loads the translations
     */
    loadTranslations(): void {
        const loadedTranslations = this.statetransferService.get(
            stateKey,
            null as any
        );

        if (!loadedTranslations) {
            this.http
                .get(
                    this.siteContextService.getRootUrl() +
                        `/webapi/Translations/GetTranslations?language=${this.language}`
                )
                .subscribe(translations => {
                    this.statetransferService.set(
                        stateKey,
                        translations as any
                    );
                    this._subject.next(translations);
                });
        } else {
            this._subject.next(loadedTranslations);
        }
    }

    // Replace tokens in translations strings with specified keys
    // Usage example:
    // utilityService.replaceTokensByKeys('You requested {ProductRequested} {ProductName}, but there's only {ProductStock} in stock.', {ProductName: 'Skofus Boots', ProductRequested: '3', ProductStock: '2'});
    public replaceTranslationToken(translationString: string, tokenName: string, replaceToken: any): string { // eslint-disable-line
        if (translationString) {
            return translationString.replace(`{${tokenName}}`, replaceToken);
        }
    }
}
