import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ArticleModule} from '@features/article/article.module';
import {AuthenticationModule} from '@features/authentication/authentication.module';
import {BasketModule} from '@features/basket/basket.module';
import {BrandModule} from '@features/brand/brand.module';
import {CheckoutReceiptModule} from '@features/checkout-receipt/checkout-receipt.module';
import {CustomerModule} from '@features/customer/customer.module';
import {FindStoreModule} from '@features/find-store/find-store.module';
import {NewsletterModule} from '@features/newsletter/newsletter.module';
import {PlyrModule} from '@features/plyr-video/plyr.module';
import {ProductFilterModule} from '@features/product-filter';
import {ProductListModule} from '@features/product-list';
import {ProductPageModule} from '@features/product-page/product-page.module';
import {RightpaneModule} from '@features/rightpane/rightpane.module';
import {ShopByImageModule} from '@features/shop-by-image/shop-by-image.module';
import {SignupModule} from '@features/signup';
import {
    ProductSliderProductComponent,
} from '@features/spots/product-slider/product-slider-product/product-slider-product.component';
import {spots} from '@features/spots/spots';
import {TrustPilotModule} from '@features/trust-pilot/trust-pilot.module';
import {NgxLazyloadModule} from '@features/ngx-lazyload/ngx-lazyload.module';
import {SharedModule} from '@shared/shared.module';

import {GenericSpotDirective} from './generic-spot.directive';
import {SpotsComponent} from './spots.component';
import {
    FavoriteBrandSpotBrandComponent
} from '@features/spots/favorite-brand-spot/favorite-brand-spot-brand/favorite-brand-spot-brand.component';
import {AxelBrandTileAnimModule} from '@features/axel-brand-tile-anim/axel-brand-tile-anim.module';
import {FavoritePickerModule} from '@features/favorite-picker/favorite-picker.module';


@NgModule({
    declarations: [
        GenericSpotDirective,
        SpotsComponent,
        ProductSliderProductComponent,
        FavoriteBrandSpotBrandComponent,
        ...spots,
    ],
    entryComponents: [SpotsComponent, ...spots],
    imports: [
        SharedModule,
        RouterModule,
        CustomerModule,
        ArticleModule,
        BrandModule,
        NgxLazyloadModule.forFeature({
            threshold: 0
        }), // Makes sure product and brands carousel shows glimpse of next image from beginning
        ProductPageModule,
        FindStoreModule,
        SignupModule,
        TrustPilotModule,
        CheckoutReceiptModule,
        ProductFilterModule,
        ProductListModule,
        BasketModule,
        RightpaneModule,
        ShopByImageModule,
        PlyrModule,
        AuthenticationModule,
        NewsletterModule,
        AxelBrandTileAnimModule,
        FavoritePickerModule
    ],
    exports: [SpotsComponent],
})
export class SpotsModule {
}
