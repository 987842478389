<section class='spot-article-image' appShopByImageTitleHoverPos>
    <figure class='spot-article-image__figure' (inView)='onInView($event)'
            [ngClass]="{'shop-by-marker_show': inView}"
            id='{{data.Id}}'>
        <div class='spot-article-image__image-wrapper'>
            <LazyPicture class='spot-article-image__image'
                         [LazyLoadSrc]="data.Image + '?w=' + 1680 / imageScaleDivider"
                         [LazyLoadSources]='imageSources'
                         [LazyImageWidth]='data.ImageWidth'
                         [LazyImageHeight]='data.ImageHeight'
                         [LazyLoadAlt]='data.ImageAltText ? data.ImageAltText : imagePathName(data.Image)'>
            </LazyPicture>
            <button *ngFor='let marker of data.Markers' appShopByImageHoverClass type='button' class='shop-by-button'
                    [ngStyle]="{'left':marker.XPos + '%', 'top': marker.YPos + '%'}"
                    (click)='showProductInRightPane($event, marker)'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
                    <path
                        d='M25.83 10.67a15.5 15.5 0 1 1-15.5 15.5 15.51 15.51 0 0 1 15.5-15.5m0-2a17.5 17.5 0 1 0 17.5 17.5 17.5 17.5 0 0 0-17.5-17.5z'
                        class='shop-by-marker-circle'
                        [ngStyle]="{'fill': '#' + marker.MarkerColor, 'stroke': '#' + marker.MarkerColor}" />
                    <path d='M25.83 34.17v-16M17.83 26.17h16' class='shop-by-marker-cross'
                          [ngStyle]="{'fill': '#' + marker.MarkerColor, 'stroke': '#' + marker.MarkerColor}" />
                </svg>
                <div class='shop-by-button__hover-title is-hidden-touch'>{{marker.Brand}} {{marker.Category}}</div>
            </button>
        </div>
        <div class='columns'>
            <div class='column is-10-desktop is-offset-2-desktop'>
                <figcaption class='spot-article-image__caption'>{{data.ImageText}}</figcaption>
            </div>
        </div>
    </figure>
</section>
