<div class="container"
    [ngClass]="{
        'container_to-edges': data.NoSidePadding
    }"
>
    <div class="sublayout"
         [ngClass]="{
            'sublayout_background': data.BackgroundColor,
            'sublayout_shadow': data.HasShadow
            }"
        [style.backgroundColor]="data.BackgroundColor">
        <article class="columns is-multiline">
            <div class="column is-12-tablet is-4-desktop">

                <div class="columns is-equal-height">
                    <div *ngFor="let spot of data.Column1Spots" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName" [backgroundColor]="data.BackgroundColor"></div>
                </div>

            </div>
            <div class="column is-6-tablet is-4-desktop">

                <div class="columns is-equal-height">
                    <div *ngFor="let spot of data.Column2Spots" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName" [backgroundColor]="data.BackgroundColor"></div>
                </div>

            </div>
            <div class="column is-6-tablet is-4-desktop">

                <div class="columns is-equal-height">
                    <div *ngFor="let spot of data.Column3Spots" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName" [backgroundColor]="data.BackgroundColor"></div>
                </div>

            </div>
        </article>
    </div>
</div>
