import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewChild
} from '@angular/core';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {PageService} from '@core/page.service';
import {UserService} from '@core/user.service';
import {BreakpointObserver, BreakpointState, MediaMatcher} from '@angular/cdk/layout';
import * as BezierEasing from 'bezier-easing';
import {arrowRight} from '@shared/svg';
import {Observable, Subject} from 'rxjs';
import {first, shareReplay, take, takeUntil, tap} from 'rxjs/operators';
import {throttle} from 'lodash-es';
import {isPlatformBrowser} from '@angular/common';
import {RaptorEventType, RaptorTrackingService} from '@core/tracking/raptor-tracking.service';
import {getFilename} from '@shared/utility';
import {ImpactCoreModelsDtoArticleSpotsRaptorArticleReferenceSpotDto} from '@shared/swagger/swagger.interface';
import {ArticleService} from '@features/article/article.service';
import { SiteContextService } from '@core/site-context.service';

@Component({
    selector: 'app-article-reference-list-carousel-spot',
    templateUrl: './article-reference-list-carousel-spot.component.html',
    animations: [
        trigger('prevClick', [
            transition(':increment', [
                style({ transform: 'translate(0, -50%)' }),
                animate(
                    '400ms cubic-bezier(0.25, 0, 0.25, 1)',
                    keyframes([
                        style({ transform: 'translate(0, -50%)' }),
                        style({
                            transform: 'translate(-5px, -50%)',
                        }),
                        style({ transform: 'translate(0, -50%)' }),
                    ])
                ),
            ]),
        ]),
        trigger('nextClick', [
            transition(':increment', [
                style({ transform: 'translate(0, -50%)' }),
                animate(
                    '400ms cubic-bezier(0.25, 0, 0.25, 1)',
                    keyframes([
                        style({ transform: 'translate(0, -50%)' }),
                        style({
                            transform: 'translate(5px, -50%)',
                        }),
                        style({ transform: 'translate(0, -50%)' }),
                    ])
                ),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleReferenceListCarouselSpotComponent implements OnInit, OnDestroy {

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private pageService: PageService,
        private userService: UserService,
        private ngZone: NgZone,
        private breakpointObserver: BreakpointObserver,
        private mediaMatcher: MediaMatcher,
        private changeDetectorRef: ChangeDetectorRef,
        private pageDataService: PageService,
        private articleService: ArticleService,
        private raptorTracking: RaptorTrackingService,
        private siteContextService: SiteContextService,
    ) {
        this.articleUniverseTitle = this.siteContextService.getContext().generalSiteSettings.ArticleUniverseTitle;
        this.articleReferenceSpotLinkToArticleText = this.siteContextService.getContext().generalSiteSettings.ArticleReferenceSpotLinkToArticleText;
    }

    static ref = '';

    @HostBinding('class.column') hostClass = true;
    @Input() data: any;

    articleUniverseTitle: string;
    articleReferenceSpotLinkToArticleText: string;

    arrowRight = arrowRight;
    itemWidthInPx: number;
    id = 'raptorSlider' + Math.floor(Math.random() * 1000);
    articles: any[] = [];
    columnClass: string;
    imageSizeDivider = 1;
    imagePathName = getFilename;
    imageWidthSm = 480;
    imageWidthMd = 600;
    imageWidthLg = 768;
    imageWidthXl = 450;

    /**
     *
     * Element reference to our template
     */
    @ViewChild('slider') sliderElement: ElementRef;
    @ViewChild('sliderList') sliderListElement: ElementRef;
    @ViewChild('sliderItem') sliderItemElement: ElementRef;

    /**
     * Used to hold our Swiper instance for control purposes
     */
    sliderManager: HammerManager;
    sliderEnd = true;
    sliderStart = true;
    reducedMotion = false;

    public slider = {
        animating: false,
        interacting: false,
        index: 0,
        slides: 0,
        x: {
            current: 0,
            start: 0,
            min: 0,
            max: 0,
            deltaX: 0,
            weightedVelocity: 0,
            weightedDeltaX: 0,
            animationFrame: 0,
        },
        release: {
            target: 0,
            amplitude: 0,
            timestamp: 0,
            timeConstant: 250,
            animationFrame: 0,
        },
        goto: {
            start: 0,
            target: 0,
            amplitude: 0,
            timestamp: 0,
            timeConstant: 150,
            timeMin: 400,
            duration: 0,
            ease: BezierEasing(0.4, 0, 0.1, 1),
            animationFrame: 0,
        },
        layout: {
            sliderSize: 600,
            itemSize: 300,
            slidesPerView: 4,
            breakpoints: {},
        },
    };

    itemWidth = {
        width: -1,
    };

    userId: string;
    settings: any;

    public layoutOne = true;

    layoutSetting: any;

    /**
     * Used to trigger our slider button animations
     * @type {number}
     */
    prevClick = 0;

    /**
     * Used to trigger our slider button animations
     * @type {number}
     */
    nextClick = 0;

    /**
     * SVG for pagination arrows
     * @type {string}
     */
    paginationArrow = arrowRight;


    slides$: Observable<ImpactCoreModelsDtoArticleSpotsRaptorArticleReferenceSpotDto[]>;

    destroy = new Subject();


    isMobile: boolean;

    // Bind resize callback + throttle
    onResizeBound = throttle(this.onResize.bind(this), 100, true);
    ngOnInit(): void {
        this.layoutOne = true;

        // Disable or speed up slider animations for people how prefer reduced motion
        if (
            this.mediaMatcher.matchMedia('(prefers-reduced-motion: reduce)')
                .matches
        ) {
            this.reducedMotion = true;
            this.slider.release.timeConstant =
                this.slider.release.timeConstant * 0.1;
            this.slider.goto.timeConstant = 0;
            this.slider.goto.timeMin = 0;
        }

        this.breakpointObserver
            .observe(['(max-width: 440px)'])
            .pipe(takeUntil(this.destroy))
            .subscribe((state: BreakpointState) => {
                this.isMobile = state.matches;
            });

        this.pageService.page.pipe(first()).subscribe(page => {
            let raptorSettings = {};

            this.userService.currentUser$
                .pipe(takeUntil(this.destroy))
                .subscribe(user => {
                    this.userId = user.UserName;
                });

            if (this.userId) {
                raptorSettings = {
                    UserNameIncludingDomain: this.userId,
                };
                this.getRaptorItems(raptorSettings);
            } else {
                this.getRaptorItems();
            }
        });
    }
    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
        if (this.sliderManager) {
            this.sliderManager.off('init');
            this.sliderManager.off('progress');
            this.sliderManager.destroy();
            this.sliderManager = null;
        }
        this.breakpointObserver.ngOnDestroy();

        if (this.sliderManager) {
            this.sliderManager.destroy();
        }
        if (isPlatformBrowser(this.platformId)) {
            window.removeEventListener('resize', this.onResizeBound);
        }
    }

    private getRaptorItems(raptorSettings: {} = {}) {

        this.pageDataService.page.pipe(take(1)).subscribe(_data => {

            this.slides$ = this.articleService
                .getArticlesBySpotId(this.data.Id, undefined, undefined, undefined)
                .pipe(
                    takeUntil(this.destroy),
                    tap(articles => {
                        this.slider.slides = articles.length;
                        setTimeout(() => {
                            this.initSlider();
                        }, 0);
                    }),
                    shareReplay()
                );
        });
    }

    /**
     * Triggers our button animations
     */
    nextClickTrigger() {
        if (!this.slider.interacting) {
            this.nextClick = this.nextClick + 1;
            this.sliderGoto(
                this.slider.index + this.slider.layout.slidesPerView
            );
        }
    }

    /**
     * Triggers our button animations
     */
    prevClickTrigger() {
        if (!this.slider.interacting) {
            this.prevClick = this.prevClick + 1;
            this.sliderGoto(
                this.slider.index - this.slider.layout.slidesPerView
            );
        }
    }

    initSlider() {
        if (isPlatformBrowser(this.platformId) && this.sliderElement) {
            this.layoutSetting = {
                slidesPerView: 1,
            };
            this.settings = {
                slidesPerView: this.isMobile ? 1 : 4,
                breakpoints: {
                    '(min-width: 441px)': {
                        slidesPerView: 4,
                    },
                    '(max-width: 440px)': {
                        slidesPerView: 1,
                    },
                },
            };


            // Watch all breakpoints in settings for changes to update slidesPerView
            const breakpointsToObserve = Object.keys(this.settings.breakpoints);

            this.breakpointObserver
                .observe(breakpointsToObserve)
                .pipe(takeUntil(this.destroy))
                .subscribe((state: BreakpointState) => {

                    let matchedBreakpoint = this.settings.breakpoints[
                        '(min-width: 1651px)'
                        ];
                    if (state.matches) {
                        // Sort matched breakpoints and get largest match
                        const matchedKey = Object.keys(state.breakpoints)
                            .filter(key => state.breakpoints[key])
                            .shift();
                        matchedBreakpoint = this.settings.breakpoints[
                            matchedKey
                            ];
                    }
                    this.layoutSetting.slidesPerView = matchedBreakpoint.slidesPerView;

                    const totalWidth = this.isMobile ? 92 : this.slider.slides <= 4 ? 100 : 97;
                    this.itemWidth.width = 1 / this.layoutSetting.slidesPerView * totalWidth;
                    this.changeDetectorRef.detectChanges();
                });

            this.setupSlider();
        }
    }

    setupSlider() {
        // Run all hammer.js + animations outside zone for maximum performance
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener('resize', this.onResizeBound, {
                passive: true,
            });
            // Setup hammer.js slider manager + listeners
            this.sliderManager = new Hammer.Manager(
                this.sliderElement.nativeElement,
                {
                    recognizers: [[Hammer.Pan]],
                }
            );
            this.sliderManager.add(
                new Hammer.Pan({ threshold: 0, pointers: 0 })
            );
            this.sliderManager.get('pan').set({
                direction: Hammer.DIRECTION_HORIZONTAL,
            });
            // this.sliderManager.on('pan', this.onPan.bind(this));
            this.sliderManager.on('panstart', this.onPanStart.bind(this));
            this.sliderManager.on('panend pancancel', this.onPanEnd.bind(this));
            this.sliderManager.on('panmove', this.onPanMove.bind(this));

            setTimeout(this.onResize.bind(this));
        });
    }

    onResize() {
        this.itemWidthInPx = this.sliderItemElement.nativeElement.getBoundingClientRect().width;
        this.ngZone.runOutsideAngular(() => {
            if (this.slider.interacting) {
                this.slider.interacting = false;
                // Reanable pointer events when done panning to allow click interactions again
                this.sliderElement.nativeElement.parentNode.classList.remove(
                    'interacting'
                );
            }

            // Recalculate slider container size
            this.slider.layout.sliderSize = this.sliderListElement.nativeElement.getBoundingClientRect().width;

            // Recalculate slider item size
            this.slider.layout.itemSize = this.sliderItemElement.nativeElement.getBoundingClientRect().width;

            // Set min and max x scroll values
            this.slider.x.min = 0;
            this.slider.x.max =
                this.sliderListElement.nativeElement.scrollWidth -
                this.slider.layout.sliderSize;

            // Calculate slides per view when it isn't a fixed number
            let perView = this.layoutSetting.slidesPerView;
            if (typeof perView !== 'number') {
                perView =
                    this.slider.layout.sliderSize / this.slider.layout.itemSize;
            }
            this.slider.layout.slidesPerView = perView;

            // Update slider x position to match existing index
            this.setSliderX(-this.slider.index * this.slider.layout.itemSize);
            this.renderSliderX();

            // Update index for good measure
            this.trackSlideIndex();
        });
    }

    onPanStart(event: HammerInput) {
        event.preventDefault();
        // Save current pan interaction start position
        this.slider.x.start = this.slider.x.current;
        this.slider.x.deltaX = 0;
        this.slider.interacting = true;
        this.sliderElement.nativeElement.parentNode.classList.add(
            'interacting'
        );
    }

    onPanEnd(event: HammerInput) {
        event.preventDefault();
        if (this.slider.interacting) {
            // event.preventDefault();
            this.slider.interacting = false;
            // Reanable pointer events when done panning to allow click interactions again
            this.sliderElement.nativeElement.parentNode.classList.remove(
                'interacting'
            );
        }
        // Trigger snap / smoothing on pan release
        this.onRelease(event);
    }

    onPanMove(event: HammerInput) {
        event.preventDefault();
        // Update weighted average of velocities while panning
        // 80% is from latest pan event
        // 20% is average of all previous pan events
        this.slider.x.weightedVelocity =
            0.8 * event.velocityX + 0.2 * event['overallVelocityX'];

        const thisDeltaX = event.deltaX - this.slider.x.deltaX;
        this.slider.x.weightedDeltaX =
            0.8 * thisDeltaX + 0.2 * this.slider.x.weightedDeltaX;

        // Update x position
        this.setSliderX(this.slider.x.start + event.deltaX);
        this.renderSliderX();
    }

    onRelease(event: HammerInput) {
        this.slider.release.timestamp = Date.now();
        // Round up or down depending on delta direction
        let rounding = event.deltaX < 0 ? 'floor' : 'ceil';

        // OR: if delta or velocity too small = simply round to nearest
        if (
            Math.abs(event.deltaX) < 15 ||
            Math.abs(this.slider.x.weightedVelocity) < 0.05
        ) {
            rounding = 'round';
        }

        // Get release target x based on velocity
        // + round to nearest slider item (clamped within range)
        const amplitude =
            this.slider.x.weightedVelocity * this.slider.release.timeConstant;
        const naturalTarget = this.slider.x.current + amplitude;
        const roundedTarget =
            Math[rounding](naturalTarget / this.slider.layout.itemSize) *
            this.slider.layout.itemSize;
        this.slider.release.target = this.clamp(
            roundedTarget,
            -this.slider.x.max,
            this.slider.x.min
        );
        // Update amplitude based on rounded target
        this.slider.release.amplitude =
            this.slider.release.target - this.slider.x.current;

        // Use release target x to update indexes
        this.trackSlideIndex(this.slider.release.target);

        // Start animating
        if (this.slider.release.animationFrame) {
            window.cancelAnimationFrame(this.slider.release.animationFrame);
        }
        this.slider.release.animationFrame = requestAnimationFrame(
            this.releaseToTarget.bind(this)
        );
    }

    releaseToTarget() {
        // interactions, goto animations or destroy cancel release animation
        if (
            !this.slider.interacting &&
            !this.slider.animating &&
            !this.changeDetectorRef['destroyed']
        ) {
            const elapsed = Date.now() - this.slider.release.timestamp;
            // Approach target x via exponential function
            const delta =
                this.slider.release.amplitude *
                Math.exp(-elapsed / this.slider.release.timeConstant);
            if (Math.abs(delta) > 0.1) {
                this.setSliderX(this.slider.release.target - delta, false);
                this.renderSliderX(true);
                if (this.slider.release.animationFrame) {
                    window.cancelAnimationFrame(
                        this.slider.release.animationFrame
                    );
                }
                this.slider.release.animationFrame = requestAnimationFrame(
                    this.releaseToTarget.bind(this)
                );

                // When release animation is over halfway done, trigger recalc of index
                // Not done immediately to avoid visible jank
            } else {
                this.setSliderX(this.slider.release.target, false);
                this.renderSliderX(true);
            }
        }
    }

    sliderGoto(index) {
        this.ngZone.runOutsideAngular(() => {
            this.slider.animating = true;
            this.slider.goto.timestamp = Date.now();

            // Difference in indexes
            const diff = index - this.slider.index;
            const targetIndex = this.clamp(
                index,
                0,
                this.slider.slides - this.slider.layout.slidesPerView
            );
            // Duration based on index diff
            this.slider.goto.duration = Math.max(
                this.slider.goto.timeMin,
                Math.round(Math.abs(diff) * this.slider.goto.timeConstant)
            );

            // Set start x, target x and amplitude (difference)
            this.slider.goto.start = this.slider.x.current;
            this.slider.goto.target =
                -targetIndex * this.slider.layout.itemSize;
            this.slider.goto.amplitude =
                this.slider.goto.target - this.slider.goto.start;

            // Use target x to update slider indexes
            this.trackSlideIndex(this.slider.goto.target);

            if (this.slider.goto.animationFrame) {
                window.cancelAnimationFrame(this.slider.release.animationFrame);
            }
            this.slider.goto.animationFrame = requestAnimationFrame(
                this.animateToTarget.bind(this)
            );
        });
    }

    animateToTarget() {
        // interactions or destroy cancel goto animation
        if (!this.slider.interacting && !this.changeDetectorRef['destroyed']) {
            const elapsed = Date.now() - this.slider.goto.timestamp;
            if (elapsed < this.slider.goto.duration) {
                // Use ease with fixed duration to animate to target x
                const delta =
                    this.slider.goto.amplitude *
                    this.slider.goto.ease(elapsed / this.slider.goto.duration);
                this.setSliderX(this.slider.goto.start + delta, false);
                this.renderSliderX(true);
                requestAnimationFrame(this.animateToTarget.bind(this));
            } else {
                this.setSliderX(this.slider.goto.target, false);
                this.renderSliderX(true);
                this.slider.animating = false;
            }
        }
    }

    trackSlideIndex(xPosition = this.slider.x.current) {
        // Only run if not destroyed
        if (!this.changeDetectorRef['destroyed']) {
            // Get index of first visible slide based on position x
            this.slider.index = Math.round(
                -xPosition / this.slider.layout.itemSize
            );
            // Whether or not to show next/prev buttons
            this.sliderStart = this.slider.index === 0;
            this.sliderEnd =
                this.slider.index + this.slider.layout.slidesPerView >=
                this.slider.slides;
            this.changeDetectorRef.detectChanges();
        }
    }

    setSliderX(targetX, restrict = true, restrictOverflow = 30) {
        let displayX = targetX;

        // Restriction used in panmove when dragging beyond ends of slide list
        // restrictOverflow = pixels of allowed transformation beyond min and max
        if (restrict) {
            // If dragging beyond list end
            if (targetX > this.slider.x.min) {
                const overflowX = targetX - this.slider.x.min;
                displayX =
                    this.slider.x.min +
                    restrictOverflow *
                    this.clamp(
                        1 - Math.exp(-overflowX / restrictOverflow / 2)
                    );
                // If dragging beyond list start
            } else if (targetX < -this.slider.x.max) {
                const overflowX = Math.abs(targetX) - this.slider.x.max;
                displayX =
                    -this.slider.x.max -
                    restrictOverflow *
                    this.clamp(
                        1 - Math.exp(-overflowX / restrictOverflow / 2)
                    );
            }
        }

        this.slider.x.current = displayX;
    }

    renderSliderX(immediate = false) {
        if (this.sliderListElement) {
            if (immediate) {
                this.updateStyles();
            } else {
                if (this.slider.x.animationFrame) {
                    window.cancelAnimationFrame(this.slider.x.animationFrame);
                }
                this.slider.x.animationFrame = window.requestAnimationFrame(
                    this.updateStyles.bind(this)
                );
            }
        }
    }

    updateStyles() {
        // Use percentages for transforms (less janky while resizing)
        this.sliderListElement.nativeElement.style.transform = `translate3d(${Math.round(
            this.slider.x.current / this.slider.layout.sliderSize * 100 * 100
        ) / 100}%, 0, 0)`;
    }

    // Cap a given value within min and max
    clamp(value, min = 0, max = 1) {
        return Math.max(min, Math.min(max, value));
    }
    raptorItemClick(article: any) {
        if (article && article.RaptorContentId) {
            const user = this.userService.currentUser.getValue();
            this.raptorTracking.trackContentEvent(
                RaptorEventType.ItemClick,
                article.RaptorContentId,
                article.RaptorContentTags,
                user
            );
        }
    }

    private setColumnClass(numberOfArticles: number): void {
        if (numberOfArticles <= 1) {
            this.imageWidthLg = 1024;
            this.imageWidthXl = 1680;
            this.columnClass = 'is-12-tablet is-12-desktop';
        } else if (numberOfArticles === 2) {
            this.imageWidthLg = 1024 / 2;
            this.imageWidthXl = 1680 / 2;
            this.columnClass = 'is-6-tablet is-6-desktop';
        } else if (numberOfArticles === 4) {
            this.imageWidthLg = 1024 / 2;
            this.imageWidthXl = 1680 / 4;
            this.columnClass = 'is-6-tablet is-3-desktop';
        } else {
            this.imageWidthLg = 1026 / 3;
            this.imageWidthXl = 1680 / 3;
            this.columnClass = 'is-4-tablet is-4-desktop';
        }
    }
}
