import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { MetaMenuComponent } from './meta-menu.component';
import { MinibasketModule } from '@features/minibasket/minibasket.module';
import { SearchModule } from '@features/search/search.module';
import { FavoritesModule } from '@features/wishlist/wishlist.module';
import { MetaMenuItemsPipePipe } from './meta-menu-items-pipe.pipe';

@NgModule({
    declarations: [MetaMenuComponent, MetaMenuItemsPipePipe],
    imports: [SharedModule, MinibasketModule, SearchModule, FavoritesModule],
    exports: [MetaMenuComponent],
    entryComponents: [],
})
export class MetaMenuModule {}
