import { of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SiteContextService } from '@core/site-context.service';
import { makeStateKey, TransferState } from '@angular/platform-browser';

const footerStateKey = makeStateKey<object>('/webapi/footer');
const navigationStateKey = makeStateKey<object>('/webapi/navigation');

/**
 * Service for fetching navigation settings such as footer and header content
 */
@Injectable()
export class NavigationService {
    /**
     * Holder for the main navigation data
     */
    mainNav;

    /**
     * Holder for the footer settings
     */
    footerSettings;

    contextLanguage: string;
    businessDimension: string;

    /**
     * Injects dependencies
     * @param http
     * @param siteContextService
     * @param statetransferService
     */
    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService,
        private statetransferService: TransferState
    ) {
        this.contextLanguage = this.siteContextService.getContext().language;
        this.businessDimension = this.siteContextService.getContext().businessDimension;
    }

    /**
     * Fetches the main nav on the first call, then after that simply returns the data as its unlikely to have changed
     * on the next calls.
     */
    getMainNav() {
        const mainNavigation = this.statetransferService.get(
            navigationStateKey,
            null as any
        );

        if (!mainNavigation) {
            if (!this.mainNav) {
                return this.http
                    .get(
                        this.siteContextService.getRootUrl() +
                            `/webapi/navigation/GetNavigations?businessDimension=${this.businessDimension}&languageCode=${this.contextLanguage}`
                    )
                    .pipe(
                        map(res => {
                            this.mainNav = res;
                            this.statetransferService.set(
                                navigationStateKey,
                                res
                            );
                            return res;
                        })
                    );
            }

            return observableOf(this.mainNav);
        }

        return observableOf(mainNavigation);
    }

    /**
     * Fetches the footer settings on the first call, then after that simply returns the data as
     * its unlikely to have changed on the next calls.
     */
    getFooterSettings() {
        const footerSettings = this.statetransferService.get(
            footerStateKey,
            null as any
        );

        if (!footerSettings) {
            if (!this.footerSettings) {
                return this.http
                    .get(
                        this.siteContextService.getRootUrl() + `/webapi/footer/GetFooter?businessDimension=${this.businessDimension}&languageCode=${this.contextLanguage}`
                    )
                    .pipe(
                        map(res => {
                            this.footerSettings = res;
                            this.statetransferService.set(footerStateKey, res);
                            return res;
                        })
                    );
            }

            return observableOf(this.footerSettings);
        }

        return observableOf(footerSettings);
    }
}
