import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    GetOrderArgs,
    OrderViewModel,
} from '@shared/swagger/easypos.interface';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ReceiptService {
    private activeReceipt = new BehaviorSubject<OrderViewModel>(null);

    public $activeReceipt = this.activeReceipt.asObservable();

    constructor(private http: HttpClient) {}

    public RetreiveReceiptById(id: string) {
        const request: GetOrderArgs = {
            OrderId: id,
        };
        return this.http.post<OrderViewModel>(
            `${environment.easyposRoot}/api/Order/RetriveEasyposOrderById`,
            request
        );
    }
}
