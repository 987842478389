<ng-container *ngIf="!isSignupSplash">
    <div class="columns is-multiline is-vertical-center">
        <div class="column is-12-tablet is-6-desktop">
            <div class="signup">
                <div class="signup__content rte" *ngIf="currentStep === 1" [innerHTML]="pageContent?.pageData?.Step1Text"></div>
                <div class="signup__content rte" *ngIf="currentStep === 2" [innerHTML]="pageContent?.pageData?.Step2Text"></div>

                <div class="signup__steps" *ngIf="currentStep < 3">
                    <div class="steps">
                        <div class="steps__step" [ngClass]="currentStep === 1 && 'steps__step_active'">
                            <span>{{(translations | async)?.Authentication?.CreateUser?.CreateUserStep1}}</span>
                        </div>
                        <div class="steps__step" [ngClass]="currentStep === 2 && 'steps__step_active'">
                            <span>{{(translations | async)?.Authentication?.CreateUser?.FinishUserStep2}}</span>
                        </div>
                    </div>
                </div>

                <div class="signup__content rte" *ngIf="currentStep === 3" [innerHTML]="pageContent?.pageData?.Step3Text"></div>

                <app-signup-step-1 *ngIf="currentStep === 1" [onSubmit]="signup" [response]="response" [termsLink]="createUserTermsLink"></app-signup-step-1>
                <app-signup-step-2 *ngIf="currentStep === 2" [onSubmit]="complete" [response]="response"></app-signup-step-2>
            </div>
        </div>
        <div class="column is-12-tablet is-6-desktop">
            <div class="signup-image">
                <div class="lazy-load" [style.paddingBottom.%]="(pageContent?.pageData?.ImageHeight / pageContent?.pageData?.ImageWidth) * 100">
                    <img
                        LazyLoad
                        [LazyLoadSrc]="pageContent?.pageData?.SignupImageUrl + '?w=700'"
                        [LazyLoadSrcSet]="pageContent?.pageData?.SignupImageUrl + '?w=700 1x,' + pageContent?.pageData?.SignupImageUrl + '?w=1400 2x'"
                        alt="{{pageContent?.pageData?.ImageAltText ? pageContent?.pageData?.ImageAltText : getFilename(pageContent?.pageData?.SignupImageUrl + '?w=700')}}" />
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isSignupSplash">
    <div class="columns is-multiline is-vertical-center">
        <div class="column is-12-tablet is-6-desktop">
            <div class="signup">
                <div class="signup__content rte" *ngIf="currentStep === 1" [innerHTML]="pageContent?.pageData?.Step1SplashText"></div>
                <div class="signup__content rte" *ngIf="currentStep === 2" [innerHTML]="pageContent?.pageData?.Step2Text"></div>

                <div class="signup__steps">
                    <div class="steps">
                            <ng-container *ngIf="!isSignupSplash && currentStep < 3">
                                <div class="steps__step" [ngClass]="currentStep === 1 && 'steps__step_active'">
                                    <span>{{(translations | async)?.Authentication?.CreateUser?.UpdateUserStep1}}</span>
                                </div>
                                <div class="steps__step" [ngClass]="currentStep === 2 && 'steps__step_active'">
                                    <span>{{(translations | async)?.Authentication?.CreateUser?.FinishUserStep2}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="isSignupSplash && currentStep < 3">
                                <div class="steps__step" [ngClass]="currentStep === 2 && 'steps__step_active'">
                                    <span>{{(translations | async)?.Authentication?.CreateUser?.FinishUserStep2}}</span>
                                </div>
                            </ng-container>
                    </div>
                </div>

                <div class="signup__content rte" *ngIf="currentStep === 3" [innerHTML]="pageContent?.pageData?.Step3Text"></div>

                <app-signup-step-1 *ngIf="currentStep === 1" [onSubmit]="update" [currentUser]="user" [response]="response" [termsLink]="createUserTermsLink"></app-signup-step-1>
                <app-signup-step-2 *ngIf="currentStep === 2" [onSubmit]="complete" [response]="response" [isSignupSplash]="isSignupSplash"></app-signup-step-2>
            </div>
        </div>
        <div class="column is-12-tablet is-6-desktop">
            <div class="signup-image">
                <div class="lazy-load" [style.paddingBottom.%]="(pageContent?.pageData?.ImageHeight / pageContent?.pageData?.ImageWidth) * 100">
                    <img
                        LazyLoad
                        [LazyLoadSrc]="pageContent?.pageData?.SignupImageUrl + '?w=700'"
                        [LazyLoadSrcSet]="pageContent?.pageData?.SignupImageUrl + '?w=700 1x,' + pageContent?.pageData?.SignupImageUrl + '?w=1400 2x'"
                        alt="{{pageContent?.pageData?.ImageAltText}}" />
                </div>
            </div>
        </div>
    </div>
</ng-container>
