import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { linkArrow } from '@shared/svg';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-product-filter-show-more',
    template: `
        <button class="button button_primary button_small button_icon">
            {{translations?.Filter?.Content?.ShowMoreFacets}}
            <span class="button__icon button__icon-arrow-right" [innerHTML]="icons.linkArrow | safeHtml"></span>
        </button>
    `
})
export class ProductFilterShowMoreComponent implements OnInit, OnDestroy {
    public translations: any;
    icons = {
        linkArrow,
    };

    constructor(private translationService: TranslationService) {}
    private unSubscribe = new Subject();

    ngOnInit() {
        this.translationService.translations$
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(translations => {
                this.translations = translations;
            });
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
