<div class="language-picker">
    <div class="language-picker__icon"
         [innerHTML]="globe | safeHtml">
    </div>
    <div *ngIf="useCustomLanguagePicker" (click)="openCustomLanguagePicker()" class="language-picker__select">
        {{ selectedCountry.Currency }}
    </div>

    <select *ngIf="!useCustomLanguagePicker" name="languages" class="language-picker__select"
        [(ngModel)]="selectedCountry"
        (ngModelChange)="countrySelect(selectedCountry)">

        <option *ngFor="let country of countrySelectorData"
            [ngValue]="country">
            {{country.CountryName}}
        </option>
    </select>

</div>
