<div class="basket-product">
    <button class="basket-product__remove" (click)="removeBasketLine()" [innerHTML]="icons.cross | safeHtml"></button>

    <div class="basket-product__image basket-product__image_large">
        <div class="lazy-load" [style.paddingBottom.%]="(400 / 400) * 100">
            <a [IRouterLink]="basketLine.SkuUrl.split('?')[0]" [queryParams]="{id: basketLine.StyleId + '-' + basketLine.ColorId, size: selectedSize.SkuId}">
                <img [srcset]="basketLine.ImagePath + '&w=220&h=220&bgcolor=eaebe8 1x,' + basketLine.ImagePath + '&w=220&h=220&bgcolor=eaebe8 2x'" [src]="basketLine.ImagePath + '&w=220&h=220&bgcolor=eaebe8'"
                    alt="{{basketLine.Name}}" />
            </a>
        </div>
    </div>

    <div class="basket-product__details">
        <div class="basket-product__top-container">
            <div class="basket-product__top">
                <div class="basket-product__image basket-product__image_small">
                    <div class="lazy-load" [style.paddingBottom.%]="(400 / 400) * 100">
                        <a [IRouterLink]="basketLine.SkuUrl.split('?')[0]" [queryParams]="{id: basketLine.StyleId + '-' + basketLine.ColorId, size: selectedSize.SkuId}">
                            <img [srcset]="basketLine.ImagePath + '&w=220&h=220&bgcolor=eaebe8 1x,' + basketLine.ImagePath + '&w=220&h=220&bgcolor=eaebe8 2x'" [src]="basketLine.ImagePath + '&w=220&h=220&bgcolor=eaebe8'"
                                alt="{{basketLine.Name}}" />
                        </a>
                    </div>
                </div>

                <div class="basket-product__name-container">
                    <div class="basket-product__name">
                        <header class="basket-product__header">
                            <a [IRouterLink]="basketLine.SkuUrl.split('?')[0]" [queryParams]="{id: basketLine.StyleId + '-' + basketLine.ColorId, size: selectedSize.SkuId}"
                                class="basket-product__link">{{basketLine.Brand}}</a>
                        </header>
                        <div class="basket-product__info">
                            <div class="basket-product__type">
                                {{getProductTags()}}
                                <br />{{basketLine.StyleId}}
                            </div>
                        </div>
                    </div>
                    <div class="basket-product__gift-wrap basket-product__gift-wrap_small">
                        <div class="checkbox checkbox_no-overflow">
                            <input type="checkbox" [id]="'basket-gift-wrap-' + basketLine.SkuId" [checked]="hasGiftWrappingChecked()" (change)="toggleGiftwrapping()">
                            <label class="checkbox__label" [for]="'basket-gift-wrap-' + basketLine.SkuId">
                                <span [innerHTML]="icons.checkmark | safeHtml"></span>
                                <span class="checkbox__name">
                                    {{(translationService.translations$ | async)?.Minibasket?.Product?.GiftWrap}}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="basket-product__bottom">
                <div class="basket-product__size-amount">
                    <div class="basket-product__size">
                        <div class="input-container input-container_icon input-container_background">
                            <select class="input input_select input_filled basket-product__select" name="size" [ngModel]="selectedSize" (change)="changeSize(size.value)"
                                #size="ngModel">
                                <option *ngFor="let size of basketLine.AvailableSizes" [ngValue]="size">{{size.Size | xlReformat}}</option>
                            </select>
                            <span class="input-container__icon input-container__icon_expand" [innerHTML]="icons.expand | safeHtml"></span>
                        </div>
                    </div>
                    <div class="basket-product__amount">
                        <div class="input-container input-container_icon input-container_background">
                            <select class="input input_select input_filled basket-product__select" name="amount" [ngModel]="selectedAmount" (change)="changeAmount(amount.value)"
                                #amount="ngModel">
                                <option *ngFor="let amount of stockCount; let i = index" [ngValue]="i + 1">{{i + 1}}</option>
                            </select>
                            <span class="input-container__icon input-container__icon_expand" [innerHTML]="icons.expand | safeHtml"></span>
                        </div>
                    </div>
                    <app-notification class="basket-product__gift-notification" [message]="giftCardLimitNotificationMessage" (closeNotification)="onCloseNotification($event)" size="small" type="error" *ngIf="showGiftCardLimitNotification"></app-notification>
                </div>
                <div class="basket-product__stock-price">
                    <div class="basket-product__stock">
                        <div class="stock-display" [ngClass]="{
                                'stock-display_high': isStockCountHigh(selectedSize.StockCount),
                                'stock-display_low': !isStockCountHigh(selectedSize.StockCount)
                            }">
                            <span *ngIf="isStockCountHigh(selectedSize.StockCount)">{{(translationService.translations$ |
                                async)?.Minibasket?.Product?.StockHigh}}
                            </span>
                            <span *ngIf="!isStockCountHigh(selectedSize.StockCount)">{{(translationService.translations$
                                | async)?.Minibasket?.Product?.StockLow}}</span>
                        </div>
                    </div>
                    <div class="basket-product__price">
                        <div class="basket-product-price">
                            <div class="basket-product-price__label">
                                {{(translationService.translations$ | async)?.Minibasket?.Product?.PriceTotal}}
                            </div>
                            <div>
                                <ng-container *ngIf="!basketLine.TotalLineBeforePrice">
                                    <div class="basket-product-price__price">{{basketLine.TotalLinePrice | kfmCurrency: basketLine.Currency}}</div>
                                </ng-container>
                                <ng-container *ngIf="basketLine.TotalLineBeforePrice">
                                    <div class="basket-product-price__price basket-product-price__price_before">{{basketLine.TotalLineBeforePrice
                                        | kfmCurrency: basketLine.Currency}}</div>
                                    <div class="basket-product-price__price basket-product-price__price_discount">{{basketLine.TotalLinePrice
                                        | kfmCurrency: basketLine.Currency}}</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="basket-product__stock-gift-wrap-price">
            <div class="basket-product__stock">
                <div class="stock-display" [ngClass]="{
                                            'stock-display_high': isStockCountHigh(selectedSize.StockCount),
                                            'stock-display_low': !isStockCountHigh(selectedSize.StockCount)
                                        }">
                    <span *ngIf="isStockCountHigh(selectedSize.StockCount)">{{(translationService.translations$ | async)?.Minibasket?.Product?.StockHigh}}
                    </span>
                    <span *ngIf="!isStockCountHigh(selectedSize.StockCount)">{{(translationService.translations$ | async)?.Minibasket?.Product?.StockLow}}</span>
                </div>
            </div>
            <div class="basket-product__gift-wrap">
                <div class="checkbox checkbox_no-overflow">
                    <input type="checkbox" [id]="'basket-gift-wrap-' + basketLine.SkuId" [checked]="hasGiftWrappingChecked()" (change)="toggleGiftwrapping()">
                    <label class="checkbox__label" [for]="'basket-gift-wrap-' + basketLine.SkuId">
                        <span [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="checkbox__name">
                            {{(translationService.translations$ | async)?.Minibasket?.Product?.GiftWrap}}
                        </span>
                    </label>
                </div>
            </div>
            <div class="basket-product__price">
                <div class="basket-product-price">
                    <div class="basket-product-price__label">
                        {{(translationService.translations$ | async)?.Minibasket?.Product?.PriceTotal}}
                    </div>
                    <div>
                        <ng-container *ngIf="!basketLine.TotalLineBeforePrice">
                            <div class="basket-product-price__price">{{basketLine.TotalLinePrice | kfmCurrency: basketLine.Currency}}</div>
                        </ng-container>
                        <ng-container *ngIf="basketLine.TotalLineBeforePrice">
                            <div class="basket-product-price__price basket-product-price__price_before">{{basketLine.TotalLineBeforePrice
                                | kfmCurrency: basketLine.Currency}}</div>
                            <div class="basket-product-price__price basket-product-price__price_discount">{{basketLine.TotalLinePrice
                                | kfmCurrency: basketLine.Currency}}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
