<div class="minibasket-product" [ngClass]="{ 'removing-product': removingProduct }">
    <div class="minibasket-product__image minibasket-product__image_large" [style.background-color]="'#' + productImageBgColor">
        <div class="lazy-load" [style.paddingBottom.%]="(180 / 125) * 100">
            <a [IRouterLink]="basketLine.SkuUrl.split('?')[0]" [queryParams]="{id: basketLine.StyleId + '-' + basketLine.ColorId, size: selectedSize.SkuId}">
                <img [src]="basketLine.ImagePath + '&w=109&h=164&bgcolor=' + productImageBgColor" [srcset]="basketLine.ImagePath + '&w=109&h=164&bgcolor=' + productImageBgColor + ' 1x,' + basketLine.ImagePath + '&w=218&h=328&bgcolor=' + productImageBgColor + ' 2x'"
                    alt="{{basketLine.Name}}" />
            </a>
        </div>
    </div>
    <div class="minibasket-product__details">
        <div class="minibasket-product__top">
            <div class="minibasket-product__image minibasket-product__image_small" [style.background-color]="'#' + productImageBgColor">
                <div class="lazy-load" [style.paddingBottom.%]="(180 / 125) * 100">
                    <a [IRouterLink]="basketLine.SkuUrl.split('?')[0]" [queryParams]="{id: basketLine.StyleId + '-' + basketLine.ColorId, size: selectedSize.SkuId}">
                        <img [src]="basketLine.ImagePath + '&w=109&h=164&bgcolor=' + productImageBgColor" [srcset]="basketLine.ImagePath + '&w=109&h=164&bgcolor=' + productImageBgColor + ' 1x,' + basketLine.ImagePath + '&w=218&h=328&bgcolor=' + productImageBgColor + ' 2x'"
                            alt="{{basketLine.Name}}" />
                    </a>
                </div>
            </div>

            <div class="minibasket-product__name">
                <header class="minibasket-product__header">
                    <a [IRouterLink]="basketLine.SkuUrl.split('?')[0]" [queryParams]="{id: basketLine.StyleId + '-' + basketLine.ColorId, size: selectedSize.SkuId}"
                        class="minibasket-product__link">{{basketLine.Brand}}</a>
                </header>
                <div class="minibasket-product__info">
                    <div class="minibasket-product__type">
                        {{getProductTags()}}
                        <br />{{basketLine.StyleId}}
                    </div>
                </div>
                <div class="minibasket-product__price">
                    <div class="basket-product-price">
                        <ng-container *ngIf="!basketLine.TotalLineBeforePrice">
                            <div class="basket-product-price__price">{{+basketLine.TotalLinePrice | kfmCurrency: basketLine.Currency}}</div>
                        </ng-container>
                        <ng-container *ngIf="basketLine.TotalLineBeforePrice">
                            <div class="basket-product-price__price basket-product-price__price_discount">{{+basketLine.TotalLinePrice
                                | kfmCurrency: basketLine.Currency}}</div>
                            <div class="basket-product-price__price basket-product-price__price_before">{{+basketLine.TotalLineBeforePrice
                                | kfmCurrency: basketLine.Currency}}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="minibasket-product__bottom">
            <div class="minibasket-product__size-amount">
                <div class="minibasket-product__size">
                    <div class="input-container input-container_icon input-container_background">
                        <select class="input input_select input_filled input_round minibasket-product__select" name="size" [ngModel]="selectedSize" (change)="changeSize(size.value)"
                            #size="ngModel">
                            <option *ngFor="let size of basketLine.AvailableSizes " [ngValue]="size">{{size.Size | xlReformat}}</option>
                        </select>
                        <span class="input-container__icon input-container__icon_expand" [innerHTML]="expandIcon | safeHtml"></span>
                    </div>
                    <div class="minibasket-product__center-item">
                        <div class="minibasket-product__stock">
                            <div class="stock-display" [ngClass]="{
                                'stock-display_high': isStockCountHigh(selectedSize.StockCount),
                                'stock-display_low': !isStockCountHigh(selectedSize.StockCount)
                            }">
                        <span *ngIf="isStockCountHigh(selectedSize.StockCount)">{{(translationService.translations$ | async)?.Minibasket?.Product?.StockHigh}}
                        </span>
                                <span *ngIf="!isStockCountHigh(selectedSize.StockCount)">{{(translationService.translations$ | async)?.Minibasket?.Product?.StockLow}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="minibasket-product__amount">
                    <div class="input-container input-container_icon input-container_background">
                        <select class="input input_select input_filled input_round minibasket-product__select" name="amount" [ngModel]="selectedAmount" (change)="changeAmount(amount.value)"
                            #amount="ngModel">
                            <option *ngFor="let amount of stockCount; let i = index" [ngValue]="i + 1">{{i + 1}}</option>
                        </select>
                        <span class="input-container__icon input-container__icon_expand" [innerHTML]="expandIcon | safeHtml"></span>
                    </div>
                    <div class="minibasket-product__center-item">
                        <button class="minibasket-product__remove" (click)="removeBasketLine()">{{(translationService.translations$ | async)?.Wishlist.RightPane.WishlistRemoveProductButtonText}}</button>
                    </div>
                </div>
            </div>
            <app-notification [message]="giftCardLimitNotificationMessage" (closeNotification)="onCloseNotification($event)" size="small" type="error" *ngIf="showGiftCardLimitNotification"></app-notification>
        </div>
    </div>
</div>
