import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UspComponent } from './usp.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [UspComponent,],
    exports: [UspComponent],
})
export class UspModule {}
