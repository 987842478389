import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PageService {
    private _page: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public navigationLength = 0;

    constructor() {}

    get page() {
        return this._page.asObservable();
    }

    set page(value) {
        this.navigationLength = this.navigationLength + 1;
        this._page.next(value);
    }

    setPage(value) {
        this.navigationLength = this.navigationLength + 1;
        this._page.next(value);
    }
}
