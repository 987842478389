<app-rightpane (animationStateChanged)="doSomething($event)" [startExit]="startExitTrigger" *noSSR>

    <div class="rightpane-container">
        <header class="rightpane-container__header">
            <h2 class="rightpane-container__heading">{{currentBusinessDimension !== 'axel' ? (translations | async)?.Wishlist?.RightPane?.WishlistHeading : (translations | async)?.Axel?.WishlistPane?.WishlistHeading}}</h2>
        </header>
        <section class="rightpane-container__top-text"
                 [showOnSite]="['kaufmann', 'quint']">{{(translations | async)?.Wishlist?.RightPane?.WishlistContentText}}</section>

        <div class="rightpane-container__content rightpane-container__content_grow" appScrollbarHide #productListContainer>
            <app-wishlist-item *ngFor="let product of products"
                               [product]="product"
                               [selectedSize]="getSelectedSizeFromFavorites(product.StyleId, product.ActiveColorGroupId)"
                               [showSizeMeassage]="showSizeMeassage(product.StyleId, product.ActiveColorGroupId)"
                               (itemDeletion)="onDelete($event)"
                               (changeSize)="onChangeSize($event)"
            ></app-wishlist-item>
        </div>

        <div class="rightpane-container__footer rightpane-container__footer_shadow">
            <div class="rightpane-container__footer-top-text"><span
                [showOnSite]="['kaufmann', 'quint']">{{(translations | async)?.Wishlist?.RightPane?.WishlistSendContentText}}</span>
            </div>
            <div class="wishlist__formula-container">
                <div *ngIf="isFormulaOpen" [@slideUpDown] class="wishlist__front">
                    <div class="wishlist__center wishlist__background">
                        <span [innerHtml]="expand | safeHtml" class="input-container__icon-expand wishlist__collapse"
                              (click)="toggleFormula()"></span>
                    </div>
                    <app-wishlist-formula [wishlist]="favorites"></app-wishlist-formula>
                </div>
                <button
                    class="button button_primary button_full button_icon"
                    *ngIf="products?.length >= 1 && validToSendFavorites"
                    (click)="toggleFormula()"
                >
                    <app-svg class="" src="arrow-right" [inline]="true"></app-svg>
                    {{currentBusinessDimension !== 'axel' ? (translations | async)?.Wishlist?.RightPane?.WishlistButtonText : (translations | async)?.Axel?.WishlistPane?.SubmitWishlist}}
                </button>
                <button type="button"
                        class="button button_primary button_full button_icon"
                        (click)="showValidationMessage()"
                        *ngIf="!validToSendFavorites">
                    <ng-container *ngIf="!submitTouched">
                        <app-svg class="" src="arrow-right" [inline]="true"></app-svg>
                        {{currentBusinessDimension !== 'axel' ? (translations | async)?.Wishlist?.RightPane?.WishlistButtonText : (translations | async)?.Axel?.WishlistPane?.SubmitWishlist}}
                    </ng-container>
                    <ng-container *ngIf="submitTouched">
                        <app-svg class="" src="arrow-right" [inline]="true"></app-svg>
                        {{(translations | async)?.Wishlist?.Validation?.SubmitButtonValidationText1}}
                        <div class="button__message">{{(translations | async)?.Wishlist?.Validation?.SubmitButtonValidationText2}}</div>
                    </ng-container>
                </button>
            </div>
        </div>
    </div>

</app-rightpane>
