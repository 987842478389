import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';


// Directive for form elements to add focus to an element on page load

// Usage on single element:
// <a [autoFocus]="true"></a>

// Usage in for loop. Apply boolean variable to indicate which item to apply autofocus on
// <li *ngFor="let item of items; let isFirst = first">
//     <a [autoFocus]="isFirst"></a>
// </li>
@Directive({
    selector: '[autoFocus]'
})
export class AutofocusDirective implements AfterViewInit {

    // Used for when looping through lists, to identify the element
    @Input('autoFocus') setFocus: boolean = false;

    public constructor(private elementRef: ElementRef) {}

    public ngAfterViewInit(): void {
        if (this.setFocus) {
            setTimeout(() => {
                this.elementRef.nativeElement.focus();
            }, 0);
        }
    }
}
