import {
    Component,
    OnInit,
    PLATFORM_ID,
    Inject,
    EventEmitter,
} from '@angular/core';
import { SiteContextService } from '@core/site-context.service';
import { Subject } from 'rxjs';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-language-picker-pane',
    templateUrl: './language-picker-pane.component.html',
})
export class LanguagePickerPaneComponent implements OnInit {
    constructor(
        public translationService: TranslationService,
        public siteContextService: SiteContextService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    ngOnInit() {}

    // Trigger animation
    animationStateChanged = new EventEmitter<AnimationEvent>();
    startExitTrigger = new Subject();

    doSomething(event) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }
}
