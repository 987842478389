import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';

@Injectable()
export class ScrollBackService {
    public shouldScrollBack = false;
    public lastYPosition = 0;
    public animationDone = new Subject();
    public animationStart = new Subject();

    constructor(@Inject(PLATFORM_ID) private platformId: object) {}

    setYPosition() {
        if (isPlatformBrowser(this.platformId)) {
            this.lastYPosition = window.scrollY;
        }
    }

    setScrollBack(status) {
        this.shouldScrollBack = status;
    }

    scrollBack() {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll({
                left: 0,
                top: this.lastYPosition,
            });
        }
    }

    triggerAnimationDone() {
        this.animationDone.next();
    }

    triggerAnimationStart() {
        this.animationStart.next();
    }
}
