import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
})
export class ProductDetailComponent {
    static ref = 'Product spot';

    @Input()
    public data;
}
