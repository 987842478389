import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ScrollbarService {
    constructor(@Inject(PLATFORM_ID) private platformId: object) {}

    getScrollbarWidth(): number {
        if (isPlatformBrowser(this.platformId)) {
            const scrollDiv: HTMLElement = document.createElement('div');
            scrollDiv.style.width = '100px';
            scrollDiv.style.height = '100px';
            scrollDiv.style.overflow = 'scroll';
            scrollDiv.style.position = 'absolute';
            scrollDiv.style.top = '-9999px';

            document.body.appendChild(scrollDiv);

            const scrollbarWidth: number =
                scrollDiv.offsetWidth - scrollDiv.clientWidth;

            document.body.removeChild(scrollDiv);

            return scrollbarWidth;
        } else {
            return 0;
        }
    }
}
