import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { TrackingService } from '@core/tracking/tracking.service';

@Directive({
    selector: '[appTracking]',
})
export class TrackingDirective {
    constructor(
        private elementRef: ElementRef,
        private tracking: TrackingService
    ) {}

    /**
     * Listener for all click events within the host
     * @param $event
     */
    @HostListener('click', ['$event'])
    public onClick($event) {
        // Find the linkElement that was clicked
        let linkEle = $event.target;

        // look up recursively until you find a linkElement
        // break when reaching container
        if (linkEle.tagName !== 'A') {
            while (linkEle && linkEle !== null && linkEle.tagName !== 'A') {
                if (linkEle === this.elementRef.nativeElement) {
                    return;
                }
                linkEle = linkEle.parentElement;
            }
        }

        if (typeof linkEle === 'undefined' || linkEle === null) {
            return;
        }

        if (!linkEle.href) {
            return;
        }

        if (linkEle.href === 'javascript:void(0)') {
            return;
        }

        // Create a Url based on the linkElements href
        const url = new URL(linkEle.href);

        // Eject if the linkElement has a download-attribute
        if (linkEle.getAttribute('download') !== null) {
            this.tracking.sendDownloadLinkClick(linkEle);
            return;
        }

        // Eject if link has a target
        if (linkEle.target === '_blank') {
            this.tracking.sendOutgoingLinkClick(linkEle);
            return;
        }

        // If the link is a mailto link
        if (linkEle.href.includes('mailto:')) {
            this.tracking.sendEmailLinkClick();
            return;
        }

        // If the link is a tel link
        if (linkEle.href.includes('tel:')) {
            this.tracking.sendPhoneLinkClick();
            return;
        }

        // Eject if link points to another domain
        if (url.host !== location.host) {
            this.tracking.sendOutgoingLinkClick(linkEle);
            return;
        }

        // If you are still here, simply return.
        // TODO: Track inbound link and location
        return;
    }
}
