<footer class="footer" id="site-footer">
    <div class="container">
        <div class="footer__top">
            <ng-container *ngTemplateOutlet="branding"></ng-container>
        </div>

        <ng-container [ngSwitch]="siteContextName">

            <!-- AXEL -->
            <ng-container *ngSwitchCase="siteContextNames.AXEL">
                <div class="footer__main">
                    <!-- 1st column -->
                    <div class="footer__column footer__column--usp footer__divider" *ngIf="(footerSettings)?.USPColumn">
                        <ng-container *ngFor="let item of (footerSettings)?.USPColumn">
                            <ng-container *ngTemplateOutlet="footerSection; context: {
                                title: item.Title,
                                items: item.USPItems,
                                type: 'usp-section'
                            }"></ng-container>
                        </ng-container>
                    </div>

                    <!-- 2nd column -->
                    <div class="footer__column" *ngIf="(footerSettings)?.InfoColumn[0]">
                        <ng-container *ngTemplateOutlet="footerSection; context: {
                            title: (footerSettings)?.InfoColumn[0].Title,
                            items: (footerSettings)?.InfoColumn[0].InfoItems,
                            type: 'info-section'
                        }"></ng-container>
                        <ng-container *ngTemplateOutlet="footerSection; context: {
                            title: (footerSettings)?.InfoColumn[1].Title,
                            items: (footerSettings)?.InfoColumn[1].InfoItems,
                            type: 'info-section'
                        }"></ng-container>
                    </div>

                    <!-- 3rd column -->
                    <div class="footer__column" *ngIf="(footerSettings)?.InfoColumn[1]">
                        <ng-container *ngTemplateOutlet="footerSection; context: {
                            title: (footerSettings)?.InfoColumn[2].Title,
                            items: (footerSettings)?.InfoColumn[2].InfoItems,
                            type: 'info-section'
                        }"></ng-container>
                    </div>

                    <!-- 4th column -->
                    <div class="footer__column footer__divider" *ngIf="(footerSettings)?.InfoColumn[3]">
                        <ng-container *ngTemplateOutlet="footerSection; context: {
                            title: (footerSettings)?.InfoColumn[3].Title,
                            items: (footerSettings)?.InfoColumn[3].InfoItems,
                            type: 'info-section'
                        }"></ng-container>
                    </div>

                    <div class="footer__social-links footer__social-links--mobile">
                        <a class="footer__social-link"
                           *ngFor="let socialItem of (footerSettings)?.SocialColumn.SocialItems"
                           [href]="socialItem.ImageLink"
                           rel="noopener"
                           target="_blank"
                           [title]="socialItem.Title"
                           (click)="trackSocialIcon(socialItem.Title)">
                            {{socialItem?.Title}}
                        </a>
                    </div>

                    <!-- 5th column -->
                    <div class="footer__column" *ngIf="!!(footerSettings)?.Newsletter?.ShowNewsletter">
                        <div class="footer__section">
                            <app-newsletter-signup [inputIsBoxed]="true" [data]="{
                                Title: (footerSettings)?.Newsletter?.Title,
                                Subtitle: (footerSettings)?.Newsletter?.SubTitle,
                                SignupSource: (footerSettings)?.Newsletter?.SignupSource,
                                TermsLink: (footerSettings)?.Newsletter?.TermsLink
                            }"></app-newsletter-signup>
                        </div>
                    </div>
                </div>

                <div class="footer__main-bottom">
                    <div class="footer__social-links">
                        <a class="footer__social-link"
                           *ngFor="let socialItem of (footerSettings)?.SocialColumn.SocialItems"
                           [href]="socialItem.ImageLink"
                           rel="noopener"
                           target="_blank"
                           [title]="socialItem.Title"
                           (click)="trackSocialIcon(socialItem.Title)">
                            {{socialItem?.Title}}
                        </a>
                    </div>
                </div>

                <div class="footer__bottom" [ngSwitch]="siteContextName">
                    <div class="footer__bottom-content">
                        <ng-container *ngTemplateOutlet="branding"></ng-container>

                        <ul class="footer__bottom-list footer__policies-list">
                            <ng-container *ngIf="(footerSettings)?.TermsPoliciesLinks">
                                <li *ngFor="let item of (footerSettings)?.TermsPoliciesLinks">
                                    <a [href]="item.Link">{{item.Name}}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>

                    <div class="footer__bottom-content">
                        <ng-container *ngTemplateOutlet="trustpilot"></ng-container>
                        <ul class="footer__bottom-list footer__icons-list">
                            <ng-container *ngFor="let icon of (footerSettings)?.RightLogos">
                                <li *ngIf="icon?.Image" class="footer__bottom-list-item">
                                    <app-svg [src]="icon?.Image" class="footer__payment-icon" [size]="'unset'"></app-svg>
                                </li>
                            </ng-container>
                        </ul>
                        <ul class="footer__bottom-list footer__icons-list">
                            <ng-container
                                *ngFor="let paymentOption of (footerSettings)?.PaymentOptionsColumn?.PaymentOptionItems">
                                <li *ngIf="paymentOption?.Image" class="footer__bottom-list-item">
                                    <app-svg [src]="paymentOption?.Image" class="footer__payment-icon" [size]="'unset'"></app-svg>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </ng-container>

            <!-- DEFAULT -->
            <ng-container *ngSwitchDefault>
                <div class="footer__main">
                    <!-- 1st column -->
                    <div class="footer__column footer__column--usp footer__divider" *ngIf="(footerSettings)?.USPColumn">
                        <ng-container *ngFor="let item of (footerSettings)?.USPColumn">
                            <ng-container *ngTemplateOutlet="footerSection; context: {
                                title: item.Title,
                                items: item.USPItems,
                                type: 'usp-section'
                            }"></ng-container>
                        </ng-container>
                    </div>

                    <!-- 2nd column -->
                    <div class="footer__column" *ngIf="(footerSettings)?.InfoColumn[0]">
                        <ng-container *ngTemplateOutlet="footerSection; context: {
                            title: (footerSettings)?.InfoColumn[0].Title,
                            items: (footerSettings)?.InfoColumn[0].InfoItems,
                            type: 'info-section'
                        }"></ng-container>
                    </div>

                    <!-- 3rd column -->
                    <div class="footer__column" *ngIf="(footerSettings)?.InfoColumn[1]">
                        <ng-container *ngTemplateOutlet="footerSection; context: {
                            title: (footerSettings)?.InfoColumn[1].Title,
                            items: (footerSettings)?.InfoColumn[1].InfoItems,
                            type: 'info-section'
                        }"></ng-container>
                        <ng-container *ngTemplateOutlet="footerSection; context: {
                            title: (footerSettings)?.InfoColumn[2].Title,
                            items: (footerSettings)?.InfoColumn[2].InfoItems,
                            type: 'info-section'
                        }"></ng-container>
                    </div>

                    <!-- 4th column -->
                    <div class="footer__column footer__divider" *ngIf="(footerSettings)?.InfoColumn[3]">
                        <ng-container *ngTemplateOutlet="footerSection; context: {
                            title: (footerSettings)?.InfoColumn[3].Title,
                            items: (footerSettings)?.InfoColumn[3].InfoItems,
                            type: 'info-section'
                        }"></ng-container>
                    </div>

                    <div class="footer__social-links footer__social-links--mobile">
                        <a class="footer__social-link"
                           *ngFor="let socialItem of (footerSettings)?.SocialColumn.SocialItems"
                           [href]="socialItem.ImageLink"
                           rel="noopener"
                           target="_blank"
                           [title]="socialItem.Title"
                           (click)="trackSocialIcon(socialItem.Title)"
                           [ngSwitch]="socialItem?.Image !== undefined && socialItem?.Image !== ''">
                            <app-svg *ngSwitchCase="true" [src]="socialItem?.Image" [inline]="true" [size]="'unset'"></app-svg>
                            <span *ngSwitchCase="false">{{socialItem?.Title}}</span>
                        </a>
                    </div>

                    <!-- 5th column -->
                    <div class="footer__column" *ngIf="!!(footerSettings)?.Newsletter?.ShowNewsletter">
                        <div class="footer__section">
                            <app-newsletter-signup [inputIsBoxed]="true" [data]="{
                                Title: (footerSettings)?.Newsletter?.Title,
                                Subtitle: (footerSettings)?.Newsletter?.SubTitle,
                                SignupSource: (footerSettings)?.Newsletter?.SignupSource,
                                TermsLink: (footerSettings)?.Newsletter?.TermsLink
                            }"></app-newsletter-signup>
                        </div>
                    </div>
                </div>

                <div class="footer__main-bottom">
                    <div class="footer__social-links">
                        <a class="footer__social-link"
                           *ngFor="let socialItem of (footerSettings)?.SocialColumn.SocialItems"
                           [href]="socialItem.ImageLink" rel="noopener" target="_blank" [title]="socialItem.Title"
                           (click)="trackSocialIcon(socialItem.Title)">
                            {{socialItem.Title}}
                        </a>
                    </div>
                </div>

                <!-- BOTTOM -->
                <div class="footer__bottom">
                    <div class="footer__bottom-content">
                        <ng-container *ngTemplateOutlet="branding"></ng-container>

                        <ul class="footer__bottom-list footer__policies-list">
                            <ng-container *ngIf="(footerSettings)?.TermsPoliciesLinks">
                                <li *ngFor="let item of (footerSettings)?.TermsPoliciesLinks">
                                    <a [href]="item.Link">{{item.Name}}</a>
                                </li>
                            </ng-container>
                            <li class="footer__language-picker-wrapper">
                                <span class="footer__language-picker-icon" [innerHTML]="icons.globe | safeHtml"></span>
                                <select name="languages" class="footer__language-picker"
                                        (change)="countrySelect($event.target.value)">
                                    <option *ngFor="let country of countrySelectorData" [selected]="country.IsCurrent">
                                        {{country.CountryName}}
                                    </option>
                                </select>
                            </li>
                        </ul>
                    </div>

                    <div class="footer__bottom-content">
                        <ng-container *ngTemplateOutlet="trustpilot"></ng-container>
                        <ul class="footer__bottom-list footer__icons-list">
                            <ng-container *ngFor="let icon of (footerSettings)?.RightLogos">
                                <li *ngIf="icon?.Image" class="footer__bottom-list-item">
                                    <app-svg [src]="icon?.Image" class="footer__payment-icon" [size]="'unset'"></app-svg>
                                </li>
                            </ng-container>
                        </ul>
                        <ul class="footer__bottom-list footer__icons-list">
                            <ng-container
                                *ngFor="let paymentOption of (footerSettings)?.PaymentOptionsColumn?.PaymentOptionItems">
                                <li *ngIf="paymentOption?.Image" class="footer__bottom-list-item">
                                    <app-svg [src]="paymentOption?.Image" class="footer__payment-icon" [size]="'unset'"></app-svg>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</footer>
<app-json-ld [json]="organisationLd"></app-json-ld>

<!--
    TEMPLATES
-->

<!-- Branding -->
<ng-template #branding>
    <div class="footer__branding">
        <app-svg [src]="brandLogo?.image" [inline]="true" class="footer__brand-logo" [size]="'unset'"></app-svg>
        <span *ngIf="(footerSettings)?.AdditionalData?.CopyrightText">
            {{(footerSettings)?.AdditionalData?.CopyrightText}}
        </span>
    </div>
</ng-template>

<!-- Trustpilot widget -->
<ng-template #trustpilot>
    <div class="trustpilot-widget" [showOnSite]="['kaufmann']">
        <a href="https://dk.trustpilot.com/review/www.kaufmann.dk" target="_blank"><img src="/assets/trustpilotlogo-white.png" width="100%" /></a>
    </div>
    <div class="trustpilot-widget" [showOnSite]="['quint']">
        <a href="https://dk.trustpilot.com/review/www.quint.dk" target="_blank"><img src="/assets/trustpilotlogo-white.png" width="100%" /></a>
    </div>
    <div class="trustpilot-widget" [showOnSite]="['axel']">
        <a href="https://dk.trustpilot.com/review/www.axel.dk" target="_blank"><img src="/assets/trustpilot-black.png" width="100%" /></a>
    </div>
    <!--<div class="trustpilot-widget"
         data-locale="da-DK"
         data-template-id="5419b732fbfb950b10de65e5"
         [attr.data-businessunit-id]="trustpilotId"
         data-style-height="70px"
         data-style-width="100%"
         [attr.data-theme]="trustpilotTheme"
    ></div>-->
</ng-template>

<!-- Policies list -->
<ng-template #footerPoliciesList>
    <li *ngFor="let item of (footerSettings)?.TermsPoliciesLinks">
        <a [href]="item.Link">{{item.Name}}</a>
    </li>
</ng-template>

<!-- Footer section -->
<ng-template #footerSection let-title="title" let-items="items" let-type="type">
    <ng-container [ngSwitch]="type">
        <!-- Info Columns -->
        <ng-container *ngSwitchCase="'info-section'">
            <footer-section [data]="{
                Title: title,
                InfoItems: items,
                type: true
            }"></footer-section>
        </ng-container>

        <!-- USP Columns -->
        <ng-container *ngSwitchCase="'usp-section'">
            <section class="footer__section">
                <h3 class="footer__section-title">{{title}}</h3>
                <ul class="footer__section-list" intercept-links>
                    <li *ngFor="let item of items" class="footer__section-list-item">
                        <ng-container [ngSwitch]="item?.TemplateName">
                            <a class="footer__section-list-link"
                               *ngSwitchCase="'USP Column Item'"
                               [target]="item?.LinkTarget ? item?.LinkTarget : '_self'"
                               [href]="item?.Link"
                               (click)="trackNavigationClick(item?.Link)">
                                {{item?.LinkText}}
                            </a>
                            <div *ngSwitchCase="'USP Column Rich Text Item'"
                                 class="footer__section-list-text"
                                 [innerHTML]="item?.Text | safeHtml">
                            </div>
                        </ng-container>
                    </li>
                </ul>
            </section>
        </ng-container>
    </ng-container>
</ng-template>


