<div class="rightpane-container">
    <header class="rightpane-container__header">
        <h2 class="rightpane-container__heading">{{(translationService.translations$ | async)?.Minibasket?.UpSellingProducts?.OthersAlsoBought}}</h2>
    </header>
    <div class="rightpane-container__content">
        <div class="minibasket">
            <div class="minibasket__products">
                <ul class="minibasket-products__list">
                    <li
                        class="minibasket-products__item"
                        *ngFor="let prod of products"
                    >
                        <div class="minibasket-products__inner">
                            <app-minibasket-upselling-product
                                (updateBasket)="onUpdateBasket($event)"
                                [product]="prod"
                                [translate]="translationService.translations$ | async"
                                [productImageBgColor]="productImageBgColor"
                            ></app-minibasket-upselling-product>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="rightpane-container__footer-zero rightpane-container__footer_shadow">
        <div class="rightpane-container__footer-icon" [innerHTML]="icons.mouseWheel | safeHtml"></div>
    </div>
</div>
