<div class="pre-header" [ngStyle]="preHeaderStyles()">
    <div class="container">
        <div class="pre-header_content">
            <div class="pre-header_mobile-content" [innerHtml]="siteContext.headerUsp?.ContentMobile | safeHtml"></div>
            <div class="pre-header_desktop-content" [innerHtml]="siteContext.headerUsp?.ContentDesktop |safeHtml"></div>
            <nav class="header__meta-nav" aria-label="Funktionsmenu" [showOnSite]="['axelDesktopOnly']">
                <app-language-picker></app-language-picker>
                <meta-menu [metaMenu]="metaMenu"></meta-menu>
            </nav>
        </div>
    </div>
</div>
<div class="container container_to-edges">
    <header class="header">
        <!-- Header scetion for the checkout page -->
        <div class="header__row container" [class.checkout]="pageData?.TemplateName === 'CheckoutPage'">
            <div class="header__burger-menu" [innerHtml]="icons.burger | safeHtml" (click)="openMobileMenu()"
                 *ngIf="pageData?.TemplateName !== 'CheckoutPage'"></div>
            <a [routerLink]="logo.url" class="header__logo" [innerHtml]="logo.image | safeHtml"></a>
            <nav class="header__meta-nav" aria-label="Funktionsmenu"
                 [showOnSite]="['kaufmann','quint','axelMobileOnly']" *ngIf="pageData?.TemplateName !== 'CheckoutPage'">
                <meta-menu [metaMenu]="metaMenu"></meta-menu>
            </nav>
            <ng-container *ngIf="pageData?.TemplateName === 'CheckoutPage'">
                <nav class="header__nav">
                    <ul class="header__nav-list">
                        <li class="header__nav-item">
                            <a [IRouterLink]="siteContext.checkoutSettings?.GoToBasketLink" [closeDialog]="true"
                               class="header__nav-link">{{siteContext.checkoutSettings?.GoBackToBasketLinkText}}</a>
                        </li>
                    </ul>
                </nav>
                <nav class="header__checkout-nav header__checkout-nav_mobile">
                    <a [IRouterLink]="siteContext.checkoutSettings?.GoToBasketLink" [closeDialog]="true"
                       class="header__checkout-nav-link">
                        {{siteContext.checkoutSettings?.GoBackToBasketLinkText.split(" ")[0]}}
                    </a>
                </nav>
                <nav class="header__checkout-nav header__checkout-nav_desktop">
                    <a [IRouterLink]="siteContext.checkoutSettings?.GoToBasketLink" [closeDialog]="true"
                       class="header__checkout-nav-link">{{siteContext.checkoutSettings?.GoBackToBasketLinkText}}
                    </a>
                </nav>
            </ng-container>
        </div>
        <!-- Header section for the main pages -->
        <div class="header__row" *ngIf="pageData?.TemplateName !== 'CheckoutPage'">
            <nav class="header__nav container" aria-label="Hovedmenu">
                <ul class="header__nav-list">
                    <li class="header__nav-item" [ngClass]="menuItem?.IsSeparator ? 'header__nav-item_separator' : ''"
                        *ngFor="let menuItem of menuItems" #listItem (mouseleave)="handleMouseLeave(listItem)" (mouseenter)="handleMouseEnter(listItem)">
                        <ng-container *ngIf="!menuItem.NavigationLink.includes('http')">
                            <a [IRouterLink]="menuItem.NavigationLink" [closeDialog]="true"
                               (click)="handleClick(menuItem.NavigationLink, listItem)"
                               [attr.vwo-id]="vwoId(menuItem.NavigationTitle)"
                               class="header__nav-link"
                               [ngClass]="{'header__nav-link_highlighted': menuItem.IsHighlighted}"
                               [ngStyle]="{'color': menuItem.IsHighlighted ? navHighlightItem : ''}">{{menuItem.NavigationTitle}}</a>
                        </ng-container>
                        <ng-container *ngIf="menuItem.NavigationLink.includes('http')">
                            <a [href]="menuItem.NavigationLink" (click)="handleClick(menuItem.NavigationLink, listItem)"
                               target="_blank" [attr.vwo-id]="vwoId(menuItem.NavigationTitle)"
                               class="header__nav-link"
                               [ngClass]="{'header__nav-link_highlighted': menuItem.IsHighlighted}"
                               [ngStyle]="{'color': menuItem.IsHighlighted ? navHighlightItem : ''}">{{menuItem.NavigationTitle}}</a>
                        </ng-container>
                        <mega-menu [showOnSite]="['kaufmann','quint']" [item]="menuItem"
                                   *ngIf="menuItem.MainMenuItems && menuItem.MainMenuItems.length > 0"
                                   [listItem]="listItem"></mega-menu>
                        <mega-menu-axel [showOnSite]="['axel']" [item]="menuItem"
                                        *ngIf="menuItem.MainMenuItems && menuItem.MainMenuItems.length > 0"
                                        [listItem]="listItem"></mega-menu-axel>
                    </li>
                </ul>
                <ul class="header__nav-list header__nav-list_secondary">
                    <li [showOnSite]="['kaufmann','quint']" class="header__nav-item"
                        [ngClass]="menuItem?.IsSeparator ? 'header__nav-item_separator' : ''"
                        *ngFor="let menuItem of secondaryMenuItems" #listItemSecondary
                        (mouseleave)="handleMouseLeave(listItemSecondary)" (mouseenter)="handleMouseEnter(listItemSecondary)">
                        <ng-container *ngIf="!menuItem.NavigationLink.includes('http')">
                            <a [IRouterLink]="menuItem.NavigationLink" [closeDialog]="true"
                               (click)="handleClick(menuItem.NavigationLink, listItemSecondary)"
                               [attr.vwo-id]="vwoId(menuItem.NavigationTitle)"
                               class="header__nav-link"
                               [ngClass]="{'header__nav-link_highlighted': menuItem.IsHighlighted}"
                               [ngStyle]="{'color': menuItem.IsHighlighted ? navHighlightItem : ''}">{{menuItem.NavigationTitle}}</a>
                        </ng-container>
                        <ng-container *ngIf="menuItem.NavigationLink.includes('http')">
                            <a [href]="menuItem.NavigationLink"
                               (click)="handleClick(menuItem.NavigationLink, listItemSecondary)" target="_blank"
                               [attr.vwo-id]="vwoId(menuItem.NavigationTitle)"
                               class="header__nav-link"
                               [ngClass]="{'header__nav-link_highlighted': menuItem.IsHighlighted}"
                               [ngStyle]="{'color': menuItem.IsHighlighted ? navHighlightItem : ''}">{{menuItem.NavigationTitle}}</a>
                        </ng-container>
                        <mega-menu [item]="menuItem"
                                   *ngIf="menuItem.MainMenuItems && menuItem.MainMenuItems.length > 0"
                                   [listItem]="listItemSecondary"></mega-menu>
                    </li>
                    <li class="header__nav-item">
                        <app-search-btn class="header__nav-link search-btn"
                                        [showOnSite]="['axelDesktopOnly']"></app-search-btn>
                    </li>
                </ul>


            </nav>
        </div>
    </header>
</div>
