import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-product-sublayout',
    templateUrl: './product-sublayout.component.html',
})
export class ProductSublayoutComponent {
    @Input()
    public data;

    static ref = 'ProductSublayout';
}
