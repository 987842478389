<a class='product-slider-product__details product-card__link' [routerLink]='slide.ProductUrl' [queryParams]='{id: slide.ColorGroupId}'
   (click)='onClick($event)' (dragstart)='disableDefault($event)'>
    <div class='product-slider-product__picture' [ngStyle]="{'background-color': '#' + imgBgColor}">
        <app-product-splash
            [productPriceGroup]='slide.PriceGroup'
            [percentageSaving]='slide.PercentageSavings'
            [discountDescription]='slide.DiscountDescription'
            [isNew]='slide.IsNew'
            [splashVisibility]='splashVisibility'
        ></app-product-splash>
        <app-favorite-picker
            class='inside-white-circle'
            [colorId]='styleAndColor[1]'
            [styleId]='styleAndColor[0]'
            (favoriteClick)='onFavoriteClick()'
        ></app-favorite-picker>
        <div class='product-slider-product__picture-wrap'>
            <img
                #image
                loading='lazy'
                class='product-slider-product__image'
                [src]='this.imageUrl'
                [srcset]="this.imageUrl + ' 1x, ' + this.imageUrl + ' 2x'"
                [alt]='imageAltText'
                width='400'
                height='484'
                draggable='false'
            />
        </div>
    </div>
    <div class='product-slider-product__body'>
        <h4 class='product-slider-product__title'>{{slide.BrandName}}</h4>
        <div class='product-slider-product__tags'><span>{{ tags.join(' | ')}}</span></div>
    </div>
    <div class='product-slider-product__price product-card__prices-splash' *ngIf='showPrice'>
        <div class='product-card__price'
             *ngIf='slide.Price && !slide.IsOnSale'>{{slide.OriginalPrice | kfmCurrency: slide.Currency }}</div>
        <div class='product-card__price product-card__price_retail'
             *ngIf='slide.Price && slide.IsOnSale'>{{slide.OriginalPrice | kfmCurrency: slide.Currency }}</div>
        <div class='product-card__price product-card__price_discount'
             *ngIf='slide.IsOnSale'>{{slide.Price | kfmCurrency: slide.Currency}}</div>
    </div>
    <ng-container *ngIf='!isGiftcard'>
        <app-color-group-variations *ngIf='!visibilitySettings.hideAvailableColors' [colorGroupList]='slide.ColorHexCodes'></app-color-group-variations>
        <div class='product-card__sizes'
             *ngIf='!visibilitySettings.hideAvailableSizes'
             [innerHTML]='slide.ActiveSizesDisplayNames | showSizes: sizeLabels?.SizeLabel:sizeLabels?.SizesLabel'>
        </div>
    </ng-container>
</a>
