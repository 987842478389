import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringFormat',
})
export class StringFormatPipe implements PipeTransform {
    transform(value: string, args?: string | number | any[]): any {
        if (!value) {
            return '';
        }

        if (args) {
            if (typeof args === 'object') {
                args.forEach((argValue, index) => {
                    value = value.replace('{' + index + '}', argValue);
                });
            }
            if (typeof args === 'string' || typeof args === 'number') {
                value = value.replace('{0}', args.toString());
            }
        } else {
            value = value.replace('{0}', '');
        }
        return value;
    }
}
