import { Component, Input } from '@angular/core';

@Component({
    selector: 'signup-page',
    template: `
        <app-breadcrumb [floated]="breadcrumbFloat"></app-breadcrumb>
        <spots [spots]="data"></spots>
        <app-footer *ngIf="showFooter"></app-footer>
    `,
})
export class SignupPageComponent {
    constructor() {}

    static ref = 'SignupPage';

    @Input() public data;
    @Input() public showFooter = true;
    @Input() public breadcrumbFloat = false;
}
