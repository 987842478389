import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TrustpilotService {

    constructor() {
    }

    trustpilotLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
}
