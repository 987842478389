import { animate, group, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { TrackingService } from '@core/tracking/tracking.service';

import { InfoColumn } from '../interfaces/info-column.interface';

/**
 * Renders a column in the footer and adds
 * accordion functionality on mobile
 */
@Component({
    selector: 'footer-column',
    templateUrl: './footer-column.component.html',
    animations: [
        trigger('slideInOut', [
            state(
                'open',
                style({
                    overflow: 'hidden',
                    height: '*',
                })
            ),
            state(
                'closed',
                style({
                    overflow: 'hidden',
                    height: '0px',
                })
            ),
            transition(
                'open <=> closed',
                animate('600ms cubic-bezier(0.35, 0, 0.25, 1)')
            ),
            transition('closed => open', [
                group([
                    query('li', style({ transform: 'translateX(-100%)' })),
                    animate('600ms cubic-bezier(0.35, 0, 0.25, 1)'),
                    query(
                        'li',
                        stagger('100ms', [
                            animate(
                                '800ms cubic-bezier(0.35, 0, 0.25, 1)',
                                style({ transform: 'translateX(0)' })
                            ),
                        ])
                    ),
                ]),
            ]),
        ]),
    ],
})
export class FooterColumnComponent {
    /**
     * Constructs and injects our services
     * @param {object} platformId
     */
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private tracking: TrackingService
    ) {}

    /**
     * The content of the column
     */
    @Input() column: InfoColumn;

    /**
     * Defines the current state of the accordion
     * @type {string}
     */
    open = 'closed';

    /**
     * Toggle the accordion on mobile
     */
    toggleColumn() {
        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth < 800) {
                this.open = this.open === 'open' ? 'closed' : 'open';
            }
        }
    }

    trackNavigationClick(link) {
        this.tracking.sendNavigationEvent('Footer menu', link);
    }
}
