import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterService } from '@features/filter/services/filter.service';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [CommonModule, SharedModule],
    providers: [FilterService],
})
export class FilterModule {}
