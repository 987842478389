<section class="spot-reference" *ngIf="data.Link">
    <a [routerLink]="data.Link" target="{{data.LinkTarget}}" class="spot-reference__link" (click)="raptorItemClick()">
        <!--<picture *ngIf="data.Image">
            <source media="(max-width: 480px)" srcset="{{data.Image}}?w=480">
            <source media="(min-width: 481px) and (max-width: 768px)" srcset="{{data.Image}}?w=768">
            <source media="(min-width: 769px)" srcset="{{data.Image}}?w=1680">
            <img alt="{{data.ImageAltText ? data.ImageAltText : getFilename(data.Image)}}" src="{{data.Image}}?w=1680">
        </picture>-->
        <LazyPicture *ngIf="data.Image"
                    [LazyLoadSrc]="data.Image + '?w=320'"
                    [LazyLoadSources]="[
                            {
                                media: '(max-width: 480px)',
                                srcSet: this.data.Image + '?w=480'
                            },
                            {
                                media: '(min-width: 481px) and (max-width: 1023px)',
                                srcSet: this.data.Image + '?w=768'
                            },
                            {
                                media: '(min-width: 1024px)',
                                srcSet: this.data.Image + '?w=' + 400
                            }
                        ]"
                     [LazyLoadAlt]="data.ImageAltText ? data.ImageAltText : imagePathName(data.Image)"
                     [LazyImageWidth]="data.ImageWidth"
                     [LazyImageHeight]="data.ImageHeight"
        ></LazyPicture>
        <div class="spot-reference__body">
            <h3 class="spot-reference__heading">{{data.Heading}}</h3>
            <div class="spot-reference__action">
                <span class="spot-reference__text">{{data.Teaser}}</span>
            </div>
        </div>
    </a>
</section>

<section class="spot-reference" *ngIf="!data.Link">
    <LazyPicture *ngIf="data.Image"
                 [LazyLoadSrc]="data.Image + '?w=320'"
                 [LazyLoadSources]="[
                            {
                                media: '(max-width: 480px)',
                                srcSet: this.data.Image + '?w=480'
                            },
                            {
                                media: '(min-width: 481px) and (max-width: 1023px)',
                                srcSet: this.data.Image + '?w=768'
                            },
                            {
                                media: '(min-width: 1024px)',
                                srcSet: this.data.Image + '?w=' + 400
                            }
                        ]"
                 [LazyImageHeight]="data.ImageHeight"
                 [LazyLoadAlt]="data.ImageAltText ? data.ImageAltText : imagePathName(data.Image)"
    ></LazyPicture>
    <div class="spot-reference__body">
        <h3 class="spot-reference__heading">{{data.Heading}}</h3>
    </div>
</section>
