import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OverlayService } from '@impactdk/ngx-overlay';
import { ProductFilterShowMoreDialogComponent } from '@features/product-filter/product-filter-show-more-dialog/product-filter-show-more-dialog.component';
import { MultiCheckBoxFacet, PaginationFacet } from '@features/filter/models/facet.model';
import { Subject } from 'rxjs';
import { FilterService } from '@features/filter/services/filter.service';
import { IProduct } from '@features/product-filter/models/product.model';
import { ProductFilterMobileComponent } from '../product-filter-mobile/product-filter-mobile.component';

@Component({
    selector: 'app-product-filter-smart',
    templateUrl: './product-filter-smart.component.html',
})
export class ProductFilterSmartFilterComponent implements OnChanges {
    @Input() facets: MultiCheckBoxFacet[];
    @Input() displayOrder: string[];
    @Input() paginationFacet: PaginationFacet;
    public activeFacet$ = new Subject<MultiCheckBoxFacet>();

    constructor(private overlay: OverlayService, private filterService: FilterService<IProduct>) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.facets.currentValue || changes.displayOrder.currentValue) {
            this.updateShouldShow();
        }
    }

    updateShouldShow() {
        if (!this.displayOrder || !this.facets) {
            return;
        }
        const smartFacets = this.displayOrder.map(key => {
            return this.facets.find(facet => facet.key === key);
        });
        const activeFacet = this.firstVisibleFacet(smartFacets);
        this.activeFacet$.next(activeFacet);
    }

    firstVisibleFacet(facets: MultiCheckBoxFacet[]) {
        return facets.find(facet => {
            return facet && !facet.isActive && facet.popularChildren.filter(child => child.count > 0).length > 0;
        });
    }


    selectFacet(facet: MultiCheckBoxFacet) {

        this.filterService.updateFilter([
            facet, {
                ...this.paginationFacet,
                pageIndex: 0,
            }
        ], true);
    }

    showMore(facet: MultiCheckBoxFacet) {
        this.overlay.open(ProductFilterShowMoreDialogComponent, {
            data: facet,
            backdropClass: 'cdk-overlay-white-backdrop',
        });
    }

    showMoreMobile(facet: MultiCheckBoxFacet) {
        this.overlay.open(ProductFilterMobileComponent, {
            data: facet.key,
            positionHorizontal: {
                placement: 'right',
            },
            fullHeight: true,
        });
    }
}
