import { Component, HostBinding, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';

import { map, takeUntil } from 'rxjs/operators';
import { BrandService } from '@features/brand/brand.service';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-favorite-brand-text-spot',
    templateUrl: './favorite-brand-text-spot.component.html',
})
export class FavoriteBrandTextSpotComponent implements OnInit, OnDestroy {

    /**
     * Injects our dependencies
     * @param platformId
     * @param brandService
     */
    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private brandService: BrandService,
    ) {
    }

    static ref = 'Favorite Brand Text Spot';

    @HostBinding('class.column') columnClass = true;
    @Input() public data: any;
    @Input() public backgroundColor: string;

    brands: FavoriteBrand[];
    destroy = new Subject();
    useRichTextLinks: boolean;

    /**
     * Checks if we are on the browser, and then instantiate our Swiper slider
     */
    ngOnInit() {
        this.useRichTextLinks = !!this.data.BrandLinks;
        if (!this.useRichTextLinks) {
            this.brandService
                .getFavoriteBrands(this.data.MaxNumberOfBrands, this.data.Type)
                .pipe(
                    takeUntil(this.destroy),
                    map(response => response.Brands),
                )
                .subscribe(brands => this.brands = brands);
        }
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

}

export interface FavoriteBrand {
    BrandImage: string;
    BrandName: string;
    BrandPageUrl: string;
}
