import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MetaDefinition } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class LinkTagService {

    constructor(
        @Inject(DOCUMENT) private document: Document,
    ) {
    }

    setLinkTags(tags: LinkTag[]): void {
        const linkTags = this.document.head.querySelectorAll('link[rel=canonical], link[rel=next], link[rel=prev]');
        if (linkTags.length) {
            linkTags.forEach(tag => tag.remove());
        }
        tags.forEach((tag) => this.createLinkElement(tag));
    }
    private createLinkElement(tag: LinkTag): void {
        const element = this.document.createElement('link');
        element.setAttribute('rel', tag.rel);
        element.setAttribute('href', tag.href);
        this.document.head.appendChild(element);
    }
}

export declare type LinkTag = {
    rel?: string;
    href?: string;
    type?: string;
    media?: string;
    as?: string;
    crossorigin?: string;
    title?: string;
    blocking?: string;
} & {
    [prop: string]: string;
};
