import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-filter-sublayout',
    templateUrl: './filter-sublayout.component.html',
})
export class FilterSublayoutComponent {
    @Input()
    public data;

    static ref = 'FilterSublayout';
}
