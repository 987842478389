import {Component, HostBinding, Input, OnInit} from '@angular/core';
interface ITooltipPosition {
    top: string;
    left?: string;
    right?: string;
}
@Component({
    selector: 'app-tooltip',
    template: `
        <span class="tooltip is-hidden-touch" [matTooltip]="helpText" [matTooltipPosition]="'after'">i</span>
        <span class="tooltip is-hidden-desktop" (click)="toggleTooltip($event)">i</span>
        <span class="tooltip-message is-hidden-desktop"
              [ngStyle]="toolTipPosition"
              [ngClass]="[float ? 'float' : '']"
              *ngIf="showTooltip"
        >{{helpText}}</span>
    `,
})
export class TooltipComponent implements OnInit {
    @Input() helpText: string;
    @Input() float = false;

    showTooltip = false;
    toolTipPosition: ITooltipPosition = {
        top: '0px'
    };

    constructor() {}

    ngOnInit() {}

    toggleTooltip(event: MouseEvent) {
        if (this.float) {
            this.toolTipPosition['top'] = (event.target as HTMLElement).offsetTop + 'px';
            if (event.clientX > (screen.availWidth / 2)) {
                this.toolTipPosition['right'] = '54px';
            } else {
                this.toolTipPosition['left'] = event.pageX + 'px';
            }
        }
        this.showTooltip = !this.showTooltip;
    }
}
