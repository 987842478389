<article>
    <header class="favorite-brand-text__headline">{{ data.Title }}</header>
    <nav class="favorite-brand-text__body column is-7-tablet">
        <menu aria-label="Menu med udvalgte brands" *ngIf="!useRichTextLinks">
            <li *ngFor="let brand of brands">
                <a [routerLink]="brand.BrandPageUrl">{{brand.BrandName}}</a>
            </li>
        </menu>
        <div intercept-links [innerHTML]="data.BrandLinks" *ngIf="useRichTextLinks"></div>
    </nav>
</article>
