<div class="filter__facets is-hidden-mobile" *ngIf="facet" [@openState]="open ? 'true' : 'false'">
    <div class="filter__facets__content">
        <div class="container container_padded" *ngIf="open" [@.disabled]="disableAnimation">
            <div class="filter-facets">
                <div class="filter-facets__content" *ngFor="let facetItem of [facet]; trackBy: trackByFn"
                     [@checkboxAnimation]="facet.name">
                    <div class="is-hidden-mobile">
                        <div class="filter-facets__group filter-facets__group_rest">
                            <app-product-filter-facet-list (onChange)="onItemChange($event)"
                                (onSliderChange)="onSliderChange($event)"
                                [facet]="facetItem"
                                class="filter-facets__list filter-facets__list_animated columns is-multiline">

                            </app-product-filter-facet-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
