<!-- TrustBox widget - Mini -->
<div class="_container" *ngIf="data?.TrustPilotBusinessId">
    <div class="trustpilot-widget" data-locale="da-DK" data-template-id="53aa8807dec7e10d38f59f32" [attr.data-businessunit-id]="data.TrustPilotBusinessId" data-style-height="128px" data-style-width="100%" data-theme="light">
        <a href="https://dk.trustpilot.com/review/www.kaufmann.dk" target="_blank">Trustpilot</a>
    </div>
    <!-- End TrustBox widget -->
    <div class="is-hidden-mobile">
        <div class="trustpilot__latest-reviews">
            <div (click)="onToggleReviewSlider()" class="trustpilot__more-reviews">
                <div class="trustpilot__dot" [ngClass]="{'active': isSliderOpen}">
                    <span [innerHTML]="commentIcon | safeHtml"></span>
                </div>
                <p class="trustpilot__noselect">Se seneste anmeldelser</p>
            </div>
        </div>

        <div [hidden]="!isSliderOpen">
            <div class="trustpilot__arrow-up"></div>
            <!-- Slider -->
            <div class="trustpilot__slider">
                <!-- TrustBox widget - Slider -->
                <div LazyLoad class="trustpilot-widget" data-locale="da-DK" data-template-id="54ad5defc6454f065c28af8b" [attr.data-businessunit-id]="data.TrustPilotBusinessId" data-style-height="240px" data-style-width="100%" data-theme="'dark'" data-stars="1,2,3,4,5">
                    <a href="https://dk.trustpilot.com/review/www.kaufmann.dk" target="_blank">Trustpilot</a>
                </div>
                <!-- End TrustBox widget -->
            </div>
        </div>
    </div>
</div>

