import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiteContextService } from '@core/site-context.service';
import { IFilter } from '@features/filter/models/filter.model';
import { ExposedProductRow } from '@features/product-list/exposed-product-row';
import {
    ExposedProduct,
    ListViewProductModel,
} from '@shared/swagger/solrsearcher.interface';
import {
    ImpactCoreModelsProductsDtoColorGroupDto as ColorGroupModel,
    ImpactWebsiteCodeWebApiControllersProductControllerDeliveryTimeResponse as DeliveryTimeResponseModel
} from '@shared/swagger/swagger.interface';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class ProductUtilityService {
    businessDimension: string;
    language: string;

    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService
    ) {
        this.businessDimension = this.siteContextService.getContext().businessDimension;
        this.language = this.siteContextService.getContext().language;
    }

    getDeliveryTime(): Observable<DeliveryTimeResponseModel> {
        const apiUrl = `${this.siteContextService.getRootUrl()}/webapi/Product/GetDeliveryTime?businessDimension=${this.businessDimension}&languageCode=${this.language}`;
        return this.http.get<DeliveryTimeResponseModel>(apiUrl);
    }
    getActiveColorGroup(
        colorGroups: ColorGroupModel[],
        selectedColorId: string
    ): ColorGroupModel {
        return colorGroups.find(
            colorGroup => colorGroup.ColorId === selectedColorId
        );
    }

    trackProductViewed(): void {
        const requestUrl =
            this.siteContextService.getRootUrl() + environment.proxyPageApiRoot;

        this.http
            .post(`${requestUrl}/webapi/product/trackproductviewed`, {})
            .subscribe(() => {}, err => {});
    }

    mapExposedProduct(filterResult: IFilter<any>): ExposedProductRow[] {
        let productCountDown = 0;
        let templateRowNum = 1;
        let numberOfProductsForRow: number;
        let templateNumber: number;
        let exposedProduct: ExposedProduct;
        const exposedProducts = [];
        const productsForRow = [];
        const filterCountLength = filterResult.entities.length;

        if (
            (!filterResult.exposedProducts && !filterResult.initialLoad) ||
            !filterResult.exposedProducts
        ) {
            return [];
        }

        while (productCountDown !== filterCountLength) {
            exposedProduct = this.getExposedProduct(
                filterResult,
                templateRowNum
            );

            templateNumber = exposedProduct
                ? +exposedProduct.TemplateNumber
                : 4;

            numberOfProductsForRow = this.numberOfProductsForRow(
                templateNumber
            );

            if (numberOfProductsForRow > filterCountLength - productCountDown) {
                numberOfProductsForRow = filterCountLength - productCountDown;
            }

            exposedProducts.push(
                this.mapExposedProductRow(
                    this.productForRow(
                        numberOfProductsForRow,
                        productCountDown,
                        filterResult.entities
                    ),
                    exposedProduct,
                    exposedProduct ? exposedProduct.ProductImageUrl : '',
                    templateNumber
                )
            );

            productCountDown = productCountDown + numberOfProductsForRow;
            templateRowNum += 1;
        }
        return exposedProducts;
    }

    private getExposedProduct(
        filterResult: IFilter<any>,
        templateRowNum: number
    ) {
        if (!filterResult.exposedProducts) {
            return {};
        }
        return filterResult.exposedProducts.find((item, i) => {
            return (
                +filterResult.exposedProducts[i].RowNumber === templateRowNum
            );
        });
    }

    private mapExposedProductRow(
        products: ListViewProductModel[],
        exposedProduct: ExposedProduct,
        exposedProductImageUrl: string,
        templateNumber: number
    ): ExposedProductRow {
        return {
            products,
            exposedProduct,
            exposedProductImageUrl,
            templateName: 'exposedProductTemplate' + templateNumber,
        };
    }

    private productForRow(
        productsForRow: number,
        productCountDown: number,
        products: ListViewProductModel[]
    ): ListViewProductModel[] {
        return products.slice(
            productCountDown,
            productCountDown + productsForRow
        );
    }

    private numberOfProductsForRow(templateTypeNum: number): number {
        return templateTypeNum === 4 ? 8 : 4;
    }
}
