<div class="wishlist__formula">
    <form name="form" class="form" #form="ngForm" [ngClass]="{'form_submitted': form.submitted}" novalidate (ngSubmit)="onSubmit(form)">
        <div class="form__field wishlist__form-field">
            <label class="label label_animated">
                <div class="input-container input-container_icon">
                    <input name="name" appResetInput required class="input input_text input_invers input_bold" [(ngModel)]="values.name"
                        type="text" #name="ngModel">
                    <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.NameInput}}</span>
                    <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid wishlist__white"
                        [innerHTML]="checkmarkIcon | safeHtml"></span>
                </div>
            </label>
            <div class="form__validation" *ngIf="(name.invalid && name.touched) || form.submitted">
                <span class="validation" *ngIf="name.errors?.required">{{(translations | async)?.GeneralInput?.Content?.NameInputMissing}}</span>
            </div>
        </div>

        <div class="form__field wishlist__form-field">
            <label class="label label_animated">
                <div class="input-container input-container_icon">
                    <input name="email" appResetInput required appLowerCaseInput [pattern]="regexService.email" class="input input_text input_invers input_bold"
                        [(ngModel)]="values.email" type="email" #email="ngModel">
                    <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.EmailInput}}</span>
                    <span class="input-container__icon input-container__icon-mail validation-icon validation-icon_normal wishlist__white" [innerHTML]="mailIcon | safeHtml"></span>
                    <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid wishlist__white"
                        [innerHTML]="checkmarkIcon | safeHtml"></span>
                </div>
            </label>
            <div class="form__validation" *ngIf="(email.invalid && email.touched) || form.submitted">
                <span class="validation" *ngIf="email.errors?.required">{{(translations | async)?.GeneralInput?.Content?.EmailInputMissing}}</span>
                <span class="validation" *ngIf="email.errors?.pattern">{{(translations | async)?.GeneralInput?.Content?.EmailInputInvalid}}</span>
            </div>
        </div>

        <div class="form__field wishlist__form-field">
            <label class="label label_animated">
                <div class="input-container input-container_icon">
                    <input name="receiver_email" appResetInput required appLowerCaseInput [pattern]="regexService.email" class="input input_text input_invers input_bold"
                        [(ngModel)]="values.receiver_email" type="email" #receiver_email="ngModel">
                        <span class="label__text">{{(translations | async)?.GeneralInput?.Content?.ReceiverEmailInput}}</span>
                    <span class="input-container__icon input-container__icon-mail validation-icon validation-icon_normal wishlist__white" [innerHTML]="mailIcon | safeHtml"></span>
                    <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid wishlist__white"
                        [innerHTML]="checkmarkIcon | safeHtml"></span>
                </div>
            </label>
            <div class="form__validation" *ngIf="(receiver_email.invalid && receiver_email.touched) || form.submitted">
                <span class="validation" *ngIf="receiver_email.errors?.required">{{(translations | async)?.GeneralInput?.Content?.ReceiverEmailInputMissing}}</span>
                <span class="validation" *ngIf="receiver_email.errors?.pattern">{{(translations | async)?.GeneralInput?.Content?.ReceiverEmailInputInvalid}}</span>
            </div>
        </div>

        <div class="wishlist__form-submit">
            <!-- Flex container -->
            <div>
                <ngx-recaptcha2 #captchaElem [theme]="'Light'" [siteKey]="'6LcUdRsUAAAAAEV3rIDVXdUwOuluke14mnQ4pWNf'" (expire)="captchaExpire()" (success)="captchaResolved($event)">
                </ngx-recaptcha2>
            </div>
            <div class="wishlist__form-submit__flex-fill">
            </div>
        </div>
        <button type="submit" class="button button_icon button_white button_full wishlist__button_sent" [disabled]="!captchaValid && form.invalid">
            <app-svg [src]="'arrow-right-black'"></app-svg>
            {{(translations | async)?.Wishlist?.RightPane?.WishlistButtonText}}
            <spinner name="sendEmailSpinner" [size]="20" background="true" inverted="true"></spinner>
        </button>
        <app-alert *ngIf="emailSendSuccess" [type]="'success'" [text]="(translations | async)?.Wishlist?.Error?.EmailSentSuccess" [closeCallback]="closeAlertSuccess"></app-alert>
        <app-alert *ngIf="emailSendFailed" [type]="'error'" [text]="(translations | async)?.Wishlist?.Error?.EmailSentFailed" [closeCallback]="closeAlertFailed"></app-alert>
    </form>
</div>

