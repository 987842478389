import { BreakpointObserver, BreakpointState, MediaMatcher } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    Input,
    NgZone, OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID, SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ProductService } from '@core/product.service';
import { OverlayService } from '@impactdk/ngx-overlay';
import { arrowRight90, doubleArrow, plus, productSliderArrowRight, zoomIn } from '@shared/svg';
import { KEYCODES } from '@shared/utility';
import * as BezierEasing from 'bezier-easing';
import { throttle } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProductZoomComponent } from '../product-zoom/product-zoom.component';
import { SiteContextService } from '@core/site-context.service';
import {ImpactCoreModelsDtoGeneralSiteSettingsGeneralSiteSettingsDto} from '@shared/swagger/swagger.interface';
import {PriceGroup} from '@features/product-page/product-price/price-group.interface';

export enum DeviceTypes {
    MOBILE = 'mobile',
    TABLET = 'tablet',
    DESKTOP = 'desktop',
}
interface ISplashSettings {
    SplashDiscountBackgroundColor: string;
    SplashDiscountTextColor: string;
    SplashSaleBackgroundColor: string;
    SplashSaleTextColor: string;
    SplashSaleTextLabel: string;
    SplashExtraBackgroundColor: string;
    SplashExtraTextColor: string;
    SplashExtraTextLabel: string;
    SplashOutletBackgroundColor: string;
    SplashOutletTextColor: string;
    SplashOutletTextLabel: string;
}
/**
 * Renders a simple product slider
 */
@Component({
    selector: 'product-details-image-slider',
    templateUrl: './product-details-image-slider.component.html',
})
export class ProductDetailsImageSliderComponent implements OnInit, OnDestroy, OnChanges {

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private overlay: OverlayService,
        private productService: ProductService,
        private ngZone: NgZone,
        private breakpointObserver: BreakpointObserver,
        private mediaMatcher: MediaMatcher,
        private changeDetectorRef: ChangeDetectorRef,
        private siteContextService: SiteContextService,
    ) {
        this.contextGeneralSettings = this.siteContextService.getContext().generalSiteSettings;
        this.splash.SplashDiscountBackgroundColor = !!this.contextGeneralSettings.SplashDiscountBackgroundColor ?
            `#${this.contextGeneralSettings.SplashDiscountBackgroundColor}` : '#9f9f9f';
        this.splash.SplashDiscountTextColor = !!this.contextGeneralSettings.SplashDiscountTextColor ?
            `#${this.contextGeneralSettings.SplashDiscountTextColor}` : '#fff';
        this.splash.SplashSaleBackgroundColor = !!this.contextGeneralSettings.SplashSaleBackgroundColor ?
            `#${this.contextGeneralSettings.SplashSaleBackgroundColor}` : '#fff700';
        this.splash.SplashSaleTextColor = !!this.contextGeneralSettings.SplashSaleTextColor ?
            `#${this.contextGeneralSettings.SplashSaleTextColor}` : '#17191c';
        this.splash.SplashExtraBackgroundColor = !!this.contextGeneralSettings.SplashExtraBackgroundColor ?
            `#${this.contextGeneralSettings.SplashExtraBackgroundColor}` : '#17191c';
        this.splash.SplashExtraTextColor = !!this.contextGeneralSettings.SplashExtraTextColor ?
            `#${this.contextGeneralSettings.SplashExtraTextColor}` : '#fff';
        this.splash.SplashOutletBackgroundColor = !!this.contextGeneralSettings.SplashOutletBackgroundColor ?
            `#${this.contextGeneralSettings.SplashOutletBackgroundColor}` : '#861910';
        this.splash.SplashOutletTextColor = !!this.contextGeneralSettings.SplashOutletTextColor ?
            `#${this.contextGeneralSettings.SplashOutletTextColor}` : '#fff';
        this.splash.SplashSaleTextLabel = this.contextGeneralSettings.SplashSaleTextLabel;
        this.splash.SplashOutletTextLabel = this.contextGeneralSettings.SplashOutletTextLabel;
        this.splash.SplashExtraTextLabel = this.contextGeneralSettings.SplashExtraTextLabel;
    }

    static ref = 'ProductImageSlider';
    private contextGeneralSettings: ImpactCoreModelsDtoGeneralSiteSettingsGeneralSiteSettingsDto;
    splash: ISplashSettings = {
        SplashExtraTextLabel: '', SplashOutletTextLabel: '', SplashSaleTextLabel: '',
        SplashDiscountBackgroundColor: '',
        SplashDiscountTextColor: '',
        SplashExtraBackgroundColor: '',
        SplashExtraTextColor: '',
        SplashOutletBackgroundColor: '',
        SplashOutletTextColor: '',
        SplashSaleBackgroundColor: '',
        SplashSaleTextColor: ''
    };

    priceGroup = PriceGroup;

    @Input() public productImages: any[] = [];
    @Input() public activeImageIndex: number;
    @Input() public productImageDescription: string;
    @Input() public product: any;
    @Input() public hideCallback: () => {};

    totalThumbsWidth: number;
    scrollLeft: number;
    totalScrollWidth: number;

    /**
     * Element reference to our template
     */
    @ViewChild('slider', { static: true }) sliderElement: ElementRef;
    @ViewChild('sliderList', { static: true }) sliderListElement: ElementRef;
    @ViewChild('sliderItem') sliderItemElement: ElementRef;
    @ViewChild('thumbnailsList', { static: true }) thumbnailsList: ElementRef<HTMLElement>;

    id = 'product-details-image-slider' + Math.floor(Math.random() * 1000);
    tags: string[] = [];
    // imgBgColor: string;

    sliderImageBgColor: string;
    thumbnailImageBgColor: string;

    icons = {
        arrowRight90,
        productSliderArrowRight,
        plus,
        doubleArrow,
        zoomIn
    };

    /**
     * Used to hold our Swiper instance for control purposes
     */
    sliderManager: HammerManager;
    sliderEnd = true;
    sliderStart = true;

    reducedMotion = false;
    desktopDevice = false;

    // Thumbnails list
    thumbContainerHeight: number;
    thumbContainerWidth: number;
    totalThumbsHeight: number;
    ThumbnailsListscrollTop = 0;
    showThumbNavDown = false;
    showThumbNavUp = false;
    thumbsScrolldistance: number;

    public slider = {
        animating: false,
        interacting: false,
        index: 0,
        slides: 0,
        x: {
            current: 0,
            start: 0,
            min: 0,
            max: 0,
            deltaX: 0,
            weightedVelocity: 0,
            weightedDeltaX: 0,
            animationFrame: 0,
        },
        release: {
            target: 0,
            amplitude: 0,
            timestamp: 0,
            timeConstant: 250,
            animationFrame: 0,
        },
        goto: {
            start: 0,
            target: 0,
            amplitude: 0,
            timestamp: 0,
            timeConstant: 500,
            timeMin: 250,
            duration: 0,
            ease: BezierEasing(0.4, 0, 0.1, 1),
            animationFrame: 0,
        },
        layout: {
            sliderSize: 600,
            itemSize: 600,
            slidesPerView: 1
        },
    };

    activeSlide = 0;

    settings: any;

    sliderInitialized = false;

    preventClick = false;

    deviceType: string;

    private unsubscribe: Subject<void> = new Subject();

    breakpoints = [
        '(min-width: 1024px)', // Typical desktop size
    ];

    // Bind resize callback + throttle
    onResizeBound = throttle(this.onResize.bind(this), 100, true);

    @HostListener('document:keydown', ['$event'])
    keyEvent($event: KeyboardEvent): void {
        // eslint-disable-line
        if ($event.keyCode === KEYCODES.LEFT_ARROW) {
            this.prevClickTrigger();
        } else if ($event.keyCode === KEYCODES.RIGHT_ARROW) {
            this.nextClickTrigger();
        }
    }

    /**
     * Checks if we are on the browser, and then instantiate our Swiper slider
     */
    ngOnInit() {
        // Observe breakpoints
        this.breakpointObserver
            .observe(this.breakpoints)
            .subscribe((state: BreakpointState) => {
                this.desktopDevice = state.matches;

                if (!this.changeDetectorRef['destroyed']) {
                    this.changeDetectorRef.detectChanges();
                }
            });

        this.productService.currentlySelectedProduct$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.sliderGoto(0, true);
                this.initSlider();
            });

        // Disable or speed up slider animations for people how prefer reduced motion
        if (this.mediaMatcher.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            this.reducedMotion = true;
            this.slider.release.timeConstant = this.slider.release.timeConstant * 0.1;
            this.slider.goto.timeConstant = 0;
            this.slider.goto.timeMin = 0;
        }

        // Get image background colors
        this.getBrandColors();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.productImages) {
            // Set amount of slides for calculations when a new variant is selected
            this.slider.slides = this.productImages.length;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        if (this.sliderManager) {
            this.sliderManager.off('init');
            this.sliderManager.off('progress');
            this.sliderManager.destroy();
            this.sliderManager = null;
        }
        this.breakpointObserver.ngOnDestroy();

        if (this.sliderManager) {
            this.sliderManager.destroy();
        }
        if (isPlatformBrowser(this.platformId)) {
            window.removeEventListener('resize', this.onResizeBound);
        }
    }

    // When first image loads, we can initialize slider
    sliderImageLoaded() {
        if (this.sliderInitialized) {
            return;
        }

        // Defer from initiliizing slider more than once
        this.sliderInitialized = true;

        setTimeout(() => {
            this.initSlider();
        }, 0);
    }

    // Handle click events contra other mouse/touch events,
    // so we are sure to not navigate away when user interacts with the slider
    handleClick($event: Event, image: any): boolean | void {
        // this.mouseIsDown = false;
        if (this.preventClick) {
            $event.stopPropagation();
            $event.preventDefault();
            return false;
        } else {
            this.imageZoom();
            return true;
        }
    }

    disableDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    // Open overlay with product images
    imageZoom() {
        if (!this.preventClick) {
            document.body.classList.add('zoomOverlayOpen');

            const zoomOverlay = this.overlay.open(ProductZoomComponent, {
                data: {
                    images: this.productImages,
                    clickedImage: this.productImages[this.slider.index],
                },
                fullHeight: true,
                hasBackdrop: true,
                positionHorizontal: {
                    placement: 'center',
                },
                positionVertical: {
                    placement: 'center',
                },
            });

            zoomOverlay.afterClose().subscribe(() => {
                document.body.classList.remove('zoomOverlayOpen');
            });
        }
    }

    getBrandColors() {
        const colors = this.productService.getBrandColors();
        this.sliderImageBgColor = colors.sliderImageBgColor;
        this.thumbnailImageBgColor = colors.thumbnailImageBgColor;
    }

    initSlider() {
        if (isPlatformBrowser(this.platformId) && this.sliderElement) {
            // Set amount of slides for calculations
            this.slider.slides = this.productImages.length;

            this.settings = {
                slidesPerView: 1,
            };

            this.setupSlider();
        }
    }

    setupSlider() {
        // Run all hammer.js + animations outside zone for maximum performance
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener('resize', this.onResizeBound, {
                passive: true,
            });

            // Setup hammer.js slider manager + listeners
            this.sliderManager = new Hammer.Manager(
                this.sliderElement.nativeElement,
                {
                    recognizers: [[Hammer.Pan]],
                }
            );

            this.sliderManager.add(
                new Hammer.Pan({ threshold: 0, pointers: 0 })
            );

            this.sliderManager.get('pan').set({
                direction: Hammer.DIRECTION_HORIZONTAL,
            });

            this.sliderManager.on('panstart', this.onPanStart.bind(this));
            this.sliderManager.on('panend pancancel', this.onPanEnd.bind(this));
            this.sliderManager.on('panmove', this.onPanMove.bind(this));

            setTimeout(this.onResize.bind(this));
        });
    }

    onResize() {
        this.ngZone.runOutsideAngular(() => {

            const currentSlide = this.slider.index;

            setTimeout(() => {
                if (this.slider.interacting) {
                    this.slider.interacting = false;
                    // Reanable pointer events when done panning to allow click interactions again
                    this.sliderElement.nativeElement.parentNode.classList.remove(
                        'interacting'
                    );
                }

                // Updte thumbnails list variables
                if (this.desktopDevice) {
                    this.thumbContainerHeight = this.thumbnailsList.nativeElement.clientHeight;
                    this.thumbContainerWidth = this.thumbnailsList.nativeElement.clientWidth;
                    this.totalThumbsHeight = this.thumbnailsList.nativeElement.scrollHeight;
                    this.totalThumbsWidth = this.thumbnailsList.nativeElement.scrollWidth;
                }

                this.calculateSliderDimensions();
                this.sliderGoto(currentSlide, true); // Make sure the slide is set to the same when resized

                // Set min and max x scroll values
                this.slider.x.min = 0;
                this.slider.x.max =
                    this.sliderListElement.nativeElement.scrollWidth -
                    this.slider.layout.sliderSize;

                // Calculate slides per view when it isn't a fixed number
                let perView = this.settings.slidesPerView;
                if (typeof perView !== 'number') {
                    perView =
                        this.slider.layout.sliderSize / this.slider.layout.itemSize;
                }
                this.slider.layout.slidesPerView = perView;

                if (!this.changeDetectorRef['destroyed']) {
                    this.changeDetectorRef.detectChanges();
                }
            }, 0);
        });

        if (this.desktopDevice) {
            setTimeout(() => {
                this.initThumbnails();
            }, 0);
        }
    }

    // Initialize thumbnails functionality
    initThumbnails() {
        this.updateThumbNailStuff();
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    updateThumbNailStuff() {
        this.thumbContainerWidth = this.thumbnailsList.nativeElement.clientWidth;
        this.totalThumbsWidth = this.thumbnailsList.nativeElement.scrollWidth;
        this.scrollLeft = this.thumbnailsList.nativeElement.scrollLeft;
        this.totalScrollWidth = this.totalThumbsWidth - this.thumbContainerWidth;

        this.showThumbNavDown = this.scrollLeft < this.totalScrollWidth - 20;
        this.showThumbNavUp = this.scrollLeft > 20;

        this.changeDetectorRef.detectChanges();

    }

    // Thumbnails list
    thumbnailsScroll($event: any) {
        window.requestAnimationFrame(() => {
            // Update scroll distance in list
            // this.ThumbnailsListscrollTop = $event.target.scrollTop;

            // setTimeout(() => {
            //     // Update scroll distance in list
            //     this.ThumbnailsListscrollTop = $event.target.scrollTop;
            // }, 750);

            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    // Used when product slider position changes
    scrollThumbsToIndex(index: number) {
        // Get the height of the
        const thumbwidth: number =
            this.totalThumbsWidth / this.productImages.length;
        const scrollDistance: number =
        thumbwidth * index -
            (this.thumbContainerWidth / 2 - thumbwidth / 2);

        // Scroll distance
        this.scrollThumbnailsList(scrollDistance);
    }

    // Global scroll function for thumbnails list
    scrollThumbnailsList(scrollDistance: number) {
        this.ngZone.runOutsideAngular(() => {
            // Scroll distance
            this.thumbnailsList.nativeElement.scrollTo({
                top: 0,
                left: scrollDistance,
                behavior: 'smooth',
            });
        });
        setTimeout(() => {
            this.updateThumbNailStuff();
        }, 400);
    }

    thumbsDown() {
        this.updateThumbNailStuff();
        if (this.scrollLeft + this.totalThumbsWidth < this.totalScrollWidth) {
            this.thumbsScrolldistance = this.scrollLeft + this.totalThumbsWidth; // Scroll down one length of container
        } else {
            this.thumbsScrolldistance = this.totalScrollWidth;  // Scroll to bottom
        }
        this.scrollThumbnailsList(this.thumbsScrolldistance);
    }

    thumbsUp() {
        if (this.scrollLeft < this.totalThumbsWidth) {
            this.thumbsScrolldistance = 0; // Scroll to top
        } else {
            this.thumbsScrolldistance = this.scrollLeft - this.totalThumbsWidth; // Scroll up one length of container
        }
        this.scrollThumbnailsList(this.thumbsScrolldistance);
    }

    // Go to slide, when thumbnails are clicked
    gotoSlide(index: number) {
        this.activeSlide = index;
        this.sliderGoto(this.activeSlide);
    }

    //
    // SLIDER HELPER METHODS

    calculateSliderDimensions() {
        // Recalculate slider container size
        this.slider.layout.sliderSize = this.sliderListElement.nativeElement.getBoundingClientRect().width;
        // Recalculate slider item size
        this.slider.layout.itemSize = this.sliderItemElement.nativeElement.getBoundingClientRect().width;
        this.trackSlideIndex();
    }

    /**
     * Triggers our button animations
     */
    nextClickTrigger() {
        if (!this.slider.interacting) {
            this.sliderGoto(this.slider.index + this.slider.layout.slidesPerView);
        }
    }

    /**
     * Triggers our button animations
     */
    prevClickTrigger() {
        if (!this.slider.interacting) {
            this.sliderGoto(this.slider.index - this.slider.layout.slidesPerView);
        }
    }

    onPanStart(event: HammerInput) {
        event.preventDefault();
        this.preventClick = true;

        // Save current pan interaction start position
        this.slider.x.start = this.slider.x.current;
        this.slider.x.deltaX = 0;
        this.slider.interacting = true;
        this.sliderElement.nativeElement.parentNode.classList.add(
            'interacting'
        );
    }

    onPanEnd(event: HammerInput) {
        event.preventDefault();

        if (this.slider.interacting) {
            // event.preventDefault();
            this.slider.interacting = false;
            // Reanable pointer events when done panning to allow click interactions again
            this.sliderElement.nativeElement.parentNode.classList.remove(
                'interacting'
            );
        }
        // Trigger snap / smoothing on pan release
        this.onRelease(event);
    }

    onPanMove(event: HammerInput) {
        event.preventDefault();
        this.preventClick = true;

        // Update weighted average of velocities while panning
        // 80% is from latest pan event
        // 20% is average of all previous pan events
        this.slider.x.weightedVelocity =
            0.8 * event.velocityX + 0.2 * event['overallVelocityX'];

        const thisDeltaX = event.deltaX - this.slider.x.deltaX;
        this.slider.x.weightedDeltaX;
        0.8 * thisDeltaX + 0.2 * this.slider.x.weightedDeltaX;

        // Update x position
        this.setSliderX(this.slider.x.start + event.deltaX);
        this.renderSliderX();
    }

    onRelease(event: HammerInput) {
        this.slider.release.timestamp = Date.now();
        // Round up or down depending on delta direction
        let rounding = event.deltaX < 0 ? 'floor' : 'ceil';

        // OR: if delta or velocity too small = simply round to nearest
        if (
            Math.abs(event.deltaX) < 15 ||
            Math.abs(this.slider.x.weightedVelocity) < 0.05
        ) {
            rounding = 'round';
        }

        // Get release target x based on velocity
        // + round to nearest slider item (clamped within range)
        const amplitude =
            this.slider.x.weightedVelocity * this.slider.release.timeConstant;
        const naturalTarget = this.slider.x.current + amplitude;
        const roundedTarget =
            Math[rounding](naturalTarget / this.slider.layout.itemSize) *
            this.slider.layout.itemSize;
        this.slider.release.target = this.clamp(
            roundedTarget,
            -this.slider.x.max,
            this.slider.x.min
        );
        // Update amplitude based on rounded target
        this.slider.release.amplitude =
            this.slider.release.target - this.slider.x.current;

        // Use release target x to update indexes
        this.trackSlideIndex(this.slider.release.target);

        // Start animating
        if (this.slider.release.animationFrame) {
            window.cancelAnimationFrame(this.slider.release.animationFrame);
            this.preventClick = true;
        }
        this.slider.release.animationFrame = requestAnimationFrame(
            this.releaseToTarget.bind(this)
        );
    }

    releaseToTarget() {
        // interactions, goto animations or destroy cancel release animation
        if (
            !this.slider.interacting &&
            !this.slider.animating &&
            !this.changeDetectorRef['destroyed']
        ) {
            const elapsed = Date.now() - this.slider.release.timestamp;
            // Approach target x via exponential function
            const delta =
                this.slider.release.amplitude *
                Math.exp(-elapsed / this.slider.release.timeConstant);

            if (Math.abs(delta) > 0.1) {
                this.setSliderX(this.slider.release.target - delta, false);
                this.renderSliderX(true);
                if (this.slider.release.animationFrame) {
                    window.cancelAnimationFrame(
                        this.slider.release.animationFrame
                    );
                }
                this.slider.release.animationFrame = requestAnimationFrame(
                    this.releaseToTarget.bind(this)
                );

                // When release animation is over halfway done, trigger recalc of index
                // Not done immediately to avoid visible jank
            } else {
                this.setSliderX(this.slider.release.target, false);
                this.renderSliderX(true);
            }
        } else if (
            this.slider.interacting ||
            (this.slider.animating && !this.changeDetectorRef['destroyed'])
        ) {
            // If user grabs slider while sliding, animate it into position without breaking the panning functionliaty
            this.slider.goto.animationFrame = requestAnimationFrame(
                this.animateToTarget.bind(this)
            );
        }

        this.preventClick = false;
    }

    sliderGoto(index: number, immediately: boolean = false) {
        this.ngZone.runOutsideAngular(() => {
            this.slider.animating = true;
            this.slider.goto.timestamp = Date.now();

            // Difference in indexes
            const diff = index - this.slider.index;
            const targetIndex = this.clamp(
                index,
                0,
                this.slider.slides - this.slider.layout.slidesPerView
            );

            // Slide duration calculation
            // Check if custom time duration has been passed to method.
            // If yes, we use that duration, if not we calculate the duration
            // this.slider.goto.duration = 0;
            if (immediately) {
                this.slider.goto.duration = 0;
            } else {
                this.slider.goto.duration = Math.max(
                    this.slider.goto.timeMin,
                    Math.round(Math.abs(diff) * this.slider.goto.timeConstant)
                );
            }

            // Set start x, target x and amplitude (difference)
            this.slider.goto.start = this.slider.x.current;
            this.slider.goto.target =
                -targetIndex * this.slider.layout.itemSize;
            this.slider.goto.amplitude =
                this.slider.goto.target - this.slider.goto.start;

            // Use target x to update slider indexes
            this.trackSlideIndex(this.slider.goto.target);

            if (this.slider.goto.animationFrame) {
                window.cancelAnimationFrame(this.slider.release.animationFrame);
            }
            this.slider.goto.animationFrame = requestAnimationFrame(
                this.animateToTarget.bind(this)
            );
        });
    }

    animateToTarget() {
        // interactions or destroy cancel goto animation
        if (!this.slider.interacting && !this.changeDetectorRef['destroyed']) {
            const elapsed = Date.now() - this.slider.goto.timestamp;
            if (elapsed < this.slider.goto.duration) {
                // Use ease with fixed duration to animate to target x
                const delta =
                    this.slider.goto.amplitude *
                    this.slider.goto.ease(elapsed / this.slider.goto.duration);
                this.setSliderX(this.slider.goto.start + delta, false);
                this.renderSliderX(true);
                requestAnimationFrame(this.animateToTarget.bind(this));
            } else {
                this.setSliderX(this.slider.goto.target, false);
                this.renderSliderX(true);
                this.slider.animating = false;
            }
        }
    }

    trackSlideIndex(xPosition = this.slider.x.current) {
        // Only run if not destroyed
        if (!this.changeDetectorRef['destroyed']) {
            // Get index of first visible slide based on position x
            this.slider.index = Math.round(
                -xPosition / this.slider.layout.itemSize
            );

            // Set active slide
            this.activeSlide = this.slider.index;

            this.scrollThumbsToIndex(this.activeSlide);

            // Whether or not to show next/prev buttons
            this.sliderStart = this.slider.index === 0;
            this.sliderEnd =
                this.slider.index + this.slider.layout.slidesPerView >=
                this.slider.slides;

            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    setSliderX(targetX, restrict = true, restrictOverflow = 30) {
        let displayX = targetX;

        // Restriction used in panmove when dragging beyond ends of slide list
        // restrictOverflow = pixels of allowed transformation beyond min and max
        if (restrict) {
            // If dragging beyond list end
            if (targetX > this.slider.x.min) {
                const overflowX = targetX - this.slider.x.min;
                displayX =
                    this.slider.x.min +
                    restrictOverflow *
                        this.clamp(
                            1 - Math.exp(-overflowX / restrictOverflow / 2)
                        );
                // If dragging beyond list start
            } else if (targetX < -this.slider.x.max) {
                const overflowX = Math.abs(targetX) - this.slider.x.max;
                displayX =
                    -this.slider.x.max -
                    restrictOverflow *
                        this.clamp(
                            1 - Math.exp(-overflowX / restrictOverflow / 2)
                        );
            }
        }

        this.slider.x.current = displayX;
    }

    renderSliderX(immediate = false) {
        if (this.sliderListElement) {
            if (immediate) {
                this.updateStyles();
            } else {
                if (this.slider.x.animationFrame) {
                    window.cancelAnimationFrame(this.slider.x.animationFrame);
                }
                this.slider.x.animationFrame = window.requestAnimationFrame(
                    this.updateStyles.bind(this)
                );
            }
        }
    }

    updateStyles() {
        // Use percentages for transforms (less janky while resizing)
        this.sliderListElement.nativeElement.style.transform = `translate3d(${Math.round(
            this.slider.x.current / this.slider.layout.sliderSize * 100 * 100
        ) / 100}%, 0, 0)`;
    }

    // Cap a given value within min and max
    clamp(value, min = 0, max = 1) {
        return Math.max(min, Math.min(max, value));
    }
}
