import { checkmark } from '@shared/svg';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiCheckBoxFacetChild } from '@features/filter/models/facet.model';
import { MultiCheckBoxSelection } from '../models/selection.model';

@Component({
    selector: 'app-product-filter-facet-item',
    templateUrl: './product-filter-facet-item.component.html',
})
export class ProductFilterFacetItemComponent implements OnInit {
    @Input() option: MultiCheckBoxFacetChild;
    @Output() onChange = new EventEmitter<MultiCheckBoxSelection>();
    inputId = '';

    constructor() {}

    hasHexColor = false;
    dotSolidColor: string | null;
    dotBorderColor: string | null;
    dotGradientColor: string | null;
    icons = {
        checkmark,
    };

    ngOnInit() {
        this.inputId = (Math.floor(Math.random() * 10000) + 1).toString();
        this.hasHexColor = !!this.option.hexColor && !!this.option.hexColor.length;
        this.dotBorderColor = this.option.hexColor?.length === 1 ? this.option.hexColor[0].includes('#ffffff') ? '#333' : this.option.hexColor[0] : '#333';
        this.dotSolidColor = this.option?.hexColor?.length === 1 ? this.option.hexColor[0] : null;
        this.dotGradientColor = this.option?.hexColor?.length > 1 ? this.generateGradientString() : null;
    }

    onItemChange(option: MultiCheckBoxFacetChild, input: boolean) {
        this.onChange.emit({
            option,
            isChecked: input,
        });
    }
    forValue(arg1, arg2): string {
        return arg1 + arg2.replace(/\s/g, '+');
    }

    private generateGradientString() {
        return this.option.hexColor.join(', ');
    }

}
