import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightpaneComponent } from './rightpane/rightpane.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [RightpaneComponent],
    exports: [RightpaneComponent],
    entryComponents: [RightpaneComponent],
})
export class RightpaneModule {}
