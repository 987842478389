import { Pipe, PipeTransform } from '@angular/core';
import {
    ImpactCoreStoresPeriod,
    ImpactCoreStoresStoreSpecialOpeningHour,
} from '@shared/swagger/swagger.interface';

@Pipe({
    name: 'specialOpeningHours',
})
export class SpecialOpeningHoursPipe implements PipeTransform {
    transform(
        days: ImpactCoreStoresPeriod[],
        specialOpenings: ImpactCoreStoresStoreSpecialOpeningHour[]
    ): any[] {

        /* Map special opening hours to days */
        if (
            days &&
            specialOpenings
        ) {
            const daysWithSpecialOpenings = [];

            days.map((day, idx) => {
                daysWithSpecialOpenings.push({
                    ...day,
                    ...specialOpenings.filter(
                        specialDay => specialDay.Day === day.open.day
                    )[0],
                });
            });

            return daysWithSpecialOpenings;
        }

        /* If only special opening hours are available */
        if (days === undefined && specialOpenings !== undefined) {
            const newDays = new Array(7);
            const daysToOutput = [];

            for (let x = 0, arrLen = newDays.length; x < arrLen; x++) {
                const dayIndex = x >= 6 ? 0 : x + 1; // sets the right start day "Monday" index 1, and "Sunday" index 0.
                newDays[x] = {
                    open: {
                        time: '',
                        day: dayIndex
                    },
                    close: {
                        time: '',
                        day: dayIndex
                    }
                };
            }

            newDays.map((day, index) => {
                daysToOutput.push({
                    ...day,
                    ...specialOpenings.filter(sday => sday.Day === day.open.day)[0]
                });
            });
            return daysToOutput;
        }
        return days;
    }
}
