<div class="usp__container">
    <ul
        class="usp__element">
        <li
            *ngFor="let usp of (page | async)?.pageData?.USPList"
         >
        {{usp.Heading}} {{usp.Text}}
        </li>
    </ul>
</div>
