import { Component, Input } from '@angular/core';

/**
 * Template for a subpage
 */
@Component({
    selector: 'filter-page',
    template: `
        <app-breadcrumb [floated]="breadcrumbFloat"></app-breadcrumb>
        <spots [spots]="data"></spots>
        <app-footer *ngIf="showFooter"></app-footer>
    `,
})
export class FilterPageComponent {
    /**
     * Page content
     */
    @Input() public data;

    @Input() public showFooter = true;

    @Input() public breadcrumbFloat = true;

    /**
     * Reference to resolve the correct component
     * @type {string}
     */
    static ref = 'Filter page';
}
