import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProductListComponent } from './product-list.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { SharedModule } from '@shared/shared.module';
import { PaginationComponent } from './pagination/pagination.component';
import { PaginationFactPipe } from './pipes/pagination-fact.pipe';
import { NgxAnimateInModule } from '@impactdk/ngx-animate-in';
import { ExposedProductsTemplateDirective } from './exposed-products/exposed-products-template.directive';
import { RowTemplate1Component } from './exposed-products/product-row-templates/row-template1.component';
import { RowTemplate2Component } from './exposed-products/product-row-templates/row-template2.component';
import { RowTemplate3Component } from './exposed-products/product-row-templates/row-template3.component';
import { RowTemplate4Component } from './exposed-products/product-row-templates/row-template4.component';
import { NgxLazyloadModule } from '@features/ngx-lazyload/ngx-lazyload.module';
import {FavoritePickerModule} from '@features/favorite-picker/favorite-picker.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        NgxAnimateInModule,
        NgxLazyloadModule,
        FavoritePickerModule,
    ],
    entryComponents: [
        RowTemplate1Component,
        RowTemplate2Component,
        RowTemplate3Component,
        RowTemplate4Component,
    ],
    declarations: [
        ProductListComponent,
        ProductItemComponent,
        PaginationComponent,
        PaginationFactPipe,
        ExposedProductsTemplateDirective,
        RowTemplate1Component,
        RowTemplate2Component,
        RowTemplate3Component,
        RowTemplate4Component,
    ],
    providers: [PaginationFactPipe],
    exports: [ProductListComponent],
})
export class ProductListModule { }
