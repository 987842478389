import { Component, Input } from '@angular/core';

/**
 * Simple component to inject all of our spot components with the generic-spot directive for resolving it.
 */
@Component({
    selector: 'spots',
    template: `
        <article class="spots-article">
		    <div *ngFor="let spot of spots"
                generic-spot
                [spotType]="spot.TemplateName"
                [spotData]="spot"></div>
        </article>
	`,
})
export class SpotsComponent {
    /**
     * A list of spots
     */
    @Input() public spots;
}
