import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {plus} from '@shared/svg';

const ANIMATION_TIMINGS = '300ms cubic-bezier(0.25, 0, 0, 1)';

@Component({
    selector: 'app-accordion',
    template: `
        <div class="accordion"
             [ngClass]="{
                'accordion_active': getAccordionState() === 'expanded'
            }">
            <button type="button"
                    class="accordion__header"
                    (click)="toggle()"
                    [attr.aria-expanded]="getAccordionState() === 'expanded'"
                    [attr.aria-controls]="id"
                    [id]="id + 'button'"
            >
                <h4 class="accordion__header__title">{{title}}</h4>
                <div class="accordion__header__icon" role="presentation" aria- [innerHtml]="plusIcon | safeHtml"></div>
            </button>
            <div class="accordion__content"
                 [id]="id"
                 role="region"
                 [attr.aria-labelledby]="id + 'button'"
                 [@bodyExpansion]="getAccordionState()"
            >
                <ng-content></ng-content>
            </div>
        </div>

    `,
    animations: [
        trigger('bodyExpansion', [
            state('collapsed', style({height: '0px', visibility: 'hidden'})),
            state('expanded', style({height: '*', visibility: 'visible'})),
            transition('expanded <=> collapsed', animate(ANIMATION_TIMINGS)),
        ]),
    ],
})
export class AccordionComponent implements OnInit {
    @Output() onToggle = new EventEmitter();
    @Input() title: string;
    @Input() id: string | number;
    @Input() activeId: string | number;

    plusIcon = plus;

    getAccordionState(): string {
        return this.activeId === this.id ? 'expanded' : 'collapsed';
    }

    constructor() {
    }

    ngOnInit() {
    }

    toggle() {
        this.onToggle.emit(this.id);
    }
}
