import { NgModule, Optional, SkipSelf } from '@angular/core';

import { TranslationService } from '@core/translation.service';
import { SeoService } from '@core/seo';
import { NavigationService } from '@core/navigation.service';
import { SiteContextService } from '@core/site-context.service';
import { RaptorService } from '@core/raptor.service';
import { PageService } from '@core/page.service';
import { StoreService } from './store.service';
import { AuthenticationService } from '@core/authentication.service';
import { ZipcodeService } from './zipcode.service';
import { UserService } from './user.service';
import { BasketService } from './basket.service';
import { ProductUtilityService } from '@core/productUtility.service';
import { PaneService } from '@core/pane.service';
import { OrderService } from '@core/order.service';
import { PaymentService } from '@core/payment.service';
import { RegexService } from '@core/regex.service';
import { StickyService } from '@core/sticky.service';
import { InfoService } from '@core/info.service';
import { ScrollBackService } from '@core/scrollBack.service';
import { LayoutModule } from '@angular/cdk/layout';
import { TrackingService } from '@core/tracking/tracking.service';
import { HttpErrorInterceptor } from '@core/http-error.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UpsellingService } from '@core/upselling.service';
import { PersonalizeContentService } from '@core/personalize-content.service';
import {ResponsiveService} from '@core/responsive.service';
import {FavoriteService} from '@core/favorite.service';

@NgModule({
    declarations: [],
    imports: [LayoutModule],
    exports: [],
    providers: [
        TranslationService,
        SeoService,
        NavigationService,
        SiteContextService,
        RaptorService,
        PageService,
        StoreService,
        AuthenticationService,
        ZipcodeService,
        UserService,
        BasketService,
        ProductUtilityService,
        PaneService,
        OrderService,
        RegexService,
        PaymentService,
        StickyService,
        InfoService,
        ScrollBackService,
        TrackingService,
        UpsellingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        PersonalizeContentService,
        ResponsiveService,
        FavoriteService,
    ],
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only'
            );
        }
    }
}
