import { Pipe, PipeTransform } from '@angular/core';
import { VisualFacet, Facet } from '@features/filter/models/facet.model';

@Pipe({
    name: 'visualFacets',
})
export class VisualFacetsPipe implements PipeTransform {
    transform(value: Facet[]): VisualFacet[] {
        const returnValue = value.filter(
            (f): f is VisualFacet =>
                f.kind !== 'pagination' &&
                f.kind !== 'sort' &&
                f.kind !== 'search'
        );
        return returnValue;
    }
}
