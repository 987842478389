import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageService } from '@core/page.service';
import { ScrollBackService } from '@core/scrollBack.service';
import { StickyService } from '@core/sticky.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { TranslationService } from '@core/translation.service';
import { Facet, MultiCheckBoxFacet, PaginationFacet, SortFacet, VisualFacet } from '@features/filter/models/facet.model';
import { FilterService, IProductGridState } from '@features/filter/services/filter.service';
import { IProduct } from '@features/product-filter/models/product.model';
import {
    ProductFilterMobileComponent,
} from '@features/product-filter/product-filter-mobile/product-filter-mobile.component';
import { OverlayService } from '@impactdk/ngx-overlay';
import { slideInOutAnimation } from '@shared/animations/slide-in-out.animation';
import { cross, expand, filter2, productGridLarge, productGridSmall } from '@shared/svg';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { ActiveVisualFacet } from './models/selection.model';

@Component({
    selector: 'app-product-filter',
    templateUrl: './product-filter.component.html',
    animations: [
        slideInOutAnimation,
        trigger('activeFilters', [
            transition(':enter', [
                style({
                    transform: 'scale(0)',
                }),
                animate(
                    '400ms cubic-bezier(0.25, 0, 0, 1)',
                    style({
                        transform: 'scale(1)',
                    })
                ),
            ]),
            transition(':leave', [
                animate(
                    '400ms cubic-bezier(0.25, 0, 0, 1)',
                    style({
                        transform: 'scale(0)',
                    })
                ),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ProductFilterComponent implements OnInit, OnDestroy {
    @ViewChild('sticky', { static: true }) stickyFilters: ElementRef<
        HTMLDivElement
    >;
    @ViewChild('filterContainer', { static: true }) filterContainer: ElementRef<
        HTMLDivElement
    >;
    @ViewChild('filterWrapper', { static: true }) filterWrapper: ElementRef<
        HTMLDivElement
    >;

    public facets: Facet[];
    public visualFacets: VisualFacet[] = [];
    public sortFacet: SortFacet;
    public paginationFacet: PaginationFacet;
    public activeFacets: ActiveVisualFacet[] = [];
    public productNames: string[];
    public smartFilterDisplayOrder: string[] = [];
    public drawerOpen = false;
    public productsFound = 0;
    public searchString: string;

    public productGridSmallIcon: string = productGridSmall;
    public productGridLargeIcon: string = productGridLarge;
    public expand: string = expand;
    public selectedgrid: IProductGridState;

    public currentFacet: MultiCheckBoxFacet | any = {};

    public translations: any;
    private unSubscribe = new Subject();
    pageData: any = {};
    private stickyActive = false;

    isDefaultSorting = true;

    private stickyBarPosition = 400;

    lastKnownHeaderPos;

    removeTicking = false;
    addTicking = false;

    appendedPadding = 0;

    icons = {
        cross,
        filter2,
    };

    constructor(
        private filterService: FilterService<IProduct>,
        private changeDetection: ChangeDetectorRef,
        private route: ActivatedRoute,
        private overlay: OverlayService,
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private _document,
        private translationService: TranslationService,
        private pageService: PageService,
        private stickyService: StickyService,
        private scrollService: ScrollBackService,
        private tracking: TrackingService
    ) {
        this.searchString = this.route.snapshot.queryParams.search;
    }

    public activeCollection: boolean[] = [];

    public toggleFacets(index: number): void {
        this.activeCollection[index] = !this.activeCollection[index];
    }

    public toggleDrawer(facet) {
        if (this.drawerOpen === false) {
            this.drawerOpen = true;
            this.currentFacet = facet;
        } else {
            if (!!this.currentFacet && this.currentFacet.name === facet.name) {
                this.drawerOpen = false;
                this.currentFacet = {};
            } else {
                this.currentFacet = facet;
            }
        }
    }

    ngOnInit() {
        this.pageService.page.pipe(take(1)).subscribe(data => {
            this.pageData = data.pageData;
        });

        this.route.queryParams
            .pipe(
                takeUntil(this.unSubscribe),
                map((params, index) => ({ params, index }))
            )
            .subscribe(({ params, index }) => {
                // index 0 === initial filter load
                if (index > 0 && params.hasOwnProperty('SortBy')) {
                    this.filterService.paramsUpdated(params);
                } else {
                    const preSelected = params['SortBy'];
                    const defaultParams = this.pageData.DefaultSortBy
                        ? {
                              SortBy: this.pageData.DefaultSortBy,
                          }
                        : {};

                    if (preSelected) {
                        this.isDefaultSorting = false;
                        defaultParams.SortBy = preSelected;
                    }

                    this.searchString = params['search'];
                    this.filterService.initFilter(params, defaultParams);

                }
            });

        // this.initScrollListen();
        this.stickyService.stickyEvent
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(event => {
                if (isPlatformBrowser(this.platformId)) {
                    this.sticky(
                        event.yPos,
                        event.direction,
                        event.headerBottom
                    );
                }
            });

        this.scrollService.animationDone
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(() => {
                setTimeout(() => {
                    if (isPlatformBrowser(this.platformId)) {
                        this.stickyBarPosition = this.stickyFilters.nativeElement.getBoundingClientRect().top;
                    }
                }, 100);
            });

        this.translationService.translations$
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(translations => {
                this.translations = translations;
            });

        this.filterService
            .getProductGridSize()
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(data => (this.selectedgrid = data));

        this.filterService
            .getFilter()
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(data => {
                this.facets = data.facets;
                this.visualFacets = data.facets.filter(
                    (f): f is VisualFacet =>
                        f.kind === 'Multicheck' || f.kind === 'Slider'
                );

                if (this.visualFacets.length) {
                    const activeFacets = this.visualFacets.filter(facet => {
                        return facet.kind === 'Slider' ? facet.isActive : true;
                    });

                    if (activeFacets && activeFacets.length) {
                        this.activeFacets = activeFacets
                            .map((facet: VisualFacet) => {
                                switch (facet.kind) {
                                    case 'Slider': {
                                        return [
                                            {
                                                facet,
                                                child: {
                                                    name: `${facet.name}: ${facet.currentMin}-${facet.currentMax}`,
                                                },
                                            },
                                        ];
                                    }
                                    default: {
                                        return facet.children
                                            .filter(
                                                facetItem => facetItem.isActive
                                            )
                                            .map(
                                                (child): ActiveVisualFacet => {
                                                    return {
                                                        facet,
                                                        child,
                                                    };
                                                }
                                            );
                                    }
                                }
                            })
                            .reduce((previousValue, currentValue) => {
                                return [...previousValue, ...currentValue];
                            });
                    } else {
                        this.activeFacets = [];
                    }
                } else {
                    this.activeFacets = [];
                }

                if (this.currentFacet.key) {
                    const currentFacet = this.facets.find((facet: any) => {
                        return facet.key === this.currentFacet.key;
                    });

                    this.currentFacet = currentFacet;
                }

                const sortFacet: SortFacet = this.facets.find(
                    (facet: Facet): facet is SortFacet => {
                        return facet.kind === 'sort';
                    }
                );

                const paginationFacet = this.facets.find(
                    (facet): facet is PaginationFacet => {
                        return facet.kind === 'pagination';
                    }
                );

                if (sortFacet) {
                    this.sortFacet = sortFacet;
                }
                if (paginationFacet) {
                    this.paginationFacet = paginationFacet;
                }

                this.smartFilterDisplayOrder = data.smartFilterDisplayOrder
                    ? data.smartFilterDisplayOrder
                    : [];

                this.productNames = data.entities.map(p => p.Name);

                this.productsFound = data.totalEntityCount;
            });
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
        this.removeSticky();
    }

    sticky(
        yPosition: number,
        direction: string = 'down',
        headerHeight: number
    ) {
        if (yPosition <= 10) {
            this.removeSticky();
            return;
        }

        if (!this.stickyActive) {
            const documentBody = this._document.body.getBoundingClientRect()
                .top;

            this.stickyBarPosition =
                this.stickyFilters.nativeElement.getBoundingClientRect().top -
                documentBody;
        }

        if (yPosition + headerHeight > this.stickyBarPosition) {
            if (direction === 'down') {
                this.drawerOpen = false;
            }

            if (
                (!this.stickyActive ||
                    this.lastKnownHeaderPos !== headerHeight) &&
                !this.addTicking
            ) {
                this.makeSticky(headerHeight);
            }

            this.setBodyPadding(true);
        } else {
            const top = this._document.documentElement.style.top;

            if (top.charAt(0) === '-') {
                const topAmount = parseInt(
                    top.replace('-', '').replace('px', ''),
                    10
                );

                if (topAmount + headerHeight > this.stickyBarPosition) {
                    if (direction === 'down') {
                        this.drawerOpen = false;
                    }

                    if (
                        (!this.stickyActive ||
                            this.lastKnownHeaderPos !== headerHeight) &&
                        !this.addTicking
                    ) {
                        this.makeSticky(headerHeight);
                    }
                } else {
                    if (this.stickyActive && !this.removeTicking) {
                        this.removeSticky();
                    }
                }
            } else {
                if (this.stickyActive && !this.removeTicking) {
                    this.removeSticky();
                }
            }
        }
    }

    makeSticky(headerHeight) {
        this.addTicking = true;
        this.stickyFilters.nativeElement.style.position = 'fixed';
        this.stickyFilters.nativeElement.style.top = headerHeight + 'px';
        this.stickyFilters.nativeElement.style.zIndex = '102';
        this.stickyFilters.nativeElement.classList.add('is-sticky');
        this.filterWrapper.nativeElement.classList.add('is-sticky');

        this.stickyActive = true;
        this.lastKnownHeaderPos = headerHeight;
        this.addTicking = false;
    }

    removeSticky() {
        this.removeTicking = true;
        this.stickyFilters.nativeElement.style.position = 'relative';
        this.stickyFilters.nativeElement.style.top = 0 + 'px';
        this.stickyFilters.nativeElement.style.zIndex = '102';
        this.stickyFilters.nativeElement.classList.remove('is-sticky');
        this.filterWrapper.nativeElement.classList.remove('is-sticky');

        this.stickyService.removeBodyPadding('filter');

        this.stickyActive = false;

        this.removeTicking = false;
    }

    getStickyStyle(ele, style) {
        let strValue = '';

        if (
            this._document.defaultView &&
            this._document.defaultView.getComputedStyle
        ) {
            strValue = this._document.defaultView
                .getComputedStyle(ele, '')
                .getPropertyValue(style);
        } else if (ele.currentStyle) {
            style = style.replace(/\-(\w)/g, function(strMatch, p1) {
                return p1.toUpperCase();
            });
            strValue = ele.currentStyle[style];
        }

        return strValue;
    }

    setBodyPadding(addPadding: boolean) {
        if (addPadding) {
            const eleHeight = this.stickyFilters.nativeElement.clientHeight;

            this.stickyService.addBodyPadding('filter', eleHeight);
        } else {
            this.stickyService.removeBodyPadding('filter');
        }
    }

    removeFacet(facet, item) {
        switch (facet.kind) {
            case 'Slider': {
                this.filterService.updateFilter({ ...facet, isActive: false });
                break;
            }

            default: {
                const newChildren = facet.children.map(child => {
                    const newIsActive =
                        child.key === item.key ? false : child.isActive;

                    return { ...child, isActive: newIsActive };
                });

                this.filterService.updateFilter({
                    ...facet,
                    children: newChildren,
                });
            }
        }

        this.scrollToTop();
    }

    resetAllFilters() {
        this.filterService.resetFilter(this.facets);
        this.scrollToTop();
    }

    trackByFn(index, item) {
        return item.child ? item.child.name : null; // or item.id
    }

    openMobileFilter(facetKey = '') {
        this.overlay.open(ProductFilterMobileComponent, {
            data: facetKey,
            positionHorizontal: {
                placement: 'right',
            },
            fullHeight: true,
        });
    }

    changeGridLayout(state: string) {
        this.tracking.sendChangeProductGridLayout(state);
        const gridState: IProductGridState = {
            gridState: state,
        };
        this.selectedgrid = gridState;
        this.filterService.setProductGridSize(gridState);
    }

    sortChange(event) {
        this.isDefaultSorting = false;
        this.tracking.sendChangeProductSorting(event.target.value);
        const paginationFacet = this.facets.find(facet => {
            return facet.kind === 'pagination';
        });

        const newChildren = this.sortFacet.children.map(child => {
            const newIsActive = child.key === event.target.value;
            return { ...child, isActive: newIsActive };
        });

        this.filterService.updateFilter([
            { ...this.sortFacet, children: newChildren },
            {
                ...paginationFacet,
                pageIndex: 0,
            },
        ]);

        this.scrollToTop();
    }

    pixelScroll() {
        setTimeout(() => {
            let scrollBy = 0;
            if (this.stickyActive) {
                scrollBy =
                    this.filterWrapper.nativeElement.offsetTop -
                    this.stickyService.getBodyPadding('filter');
                window.scrollTo({
                    top: scrollBy > 0 ? scrollBy : 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }, 500);
    }

    private scrollToTop(): void {
        this.pixelScroll();
    }
}
