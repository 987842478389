import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
} from '@angular/core';
import { expand, search } from '@shared/svg';
import { StoreService } from '@core/store.service';
import { ImpactWebsiteCodeWebApiControllersNearestStoreRequest as IStoreRequest } from '@shared/swagger/swagger.interface';
import { Store } from '@features/find-store/models/store.model';
import { TranslationService } from '@core/translation.service';
import { isPlatformBrowser } from '@angular/common';
import { Subject, SubscriptionLike as ISubscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { PaneService } from '@core/pane.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-find-store-content',
    templateUrl: './find-store.component.html',
})
export class FindStoreComponent implements OnInit, OnDestroy {
    // Parsed down to store map component
    @Input() mapHeight: number;
    @Input() isPane: boolean;
    @Input() storeToShow: Store;
    // Output
    @Output() selectedStoreObject = new EventEmitter<Store>();

    // Variables parsed to child map component
    public storesToMap: Store[];
    public singleStoreToShow: Store;
    public isSearched = false;

    // Component variables
    public selectedStore: string;
    public zip;
    public icons = { expand, search };
    public translations: any;
    public isBrowser = false;

    public allStores: Store[];
    private latestZipSearch: Store[];
    private unSubscribe = new Subject();
    private subscription_allstores: ISubscription;
    private subscription_nearest_stores: ISubscription;

    constructor(
        public storeService: StoreService,
        private translationService: TranslationService,
        @Inject(PLATFORM_ID) private platformId: object,
        private cdRef: ChangeDetectorRef,
        private paneService: PaneService,
        private router: Router
    ) {}

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.isBrowser = true;
        }
        this.translationService.translations$
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(translations => {
                this.translations = translations;
            });
        if (this.storeToShow) {
            this.selectedStore = this.storeToShow.StoreImportDescription;
            this.singleStoreToShow = this.storeToShow;
        } else {
            this.selectedStore = this.translations.Store.StoreFinderPane.ChooseStore;
        }

        // Subscribes on nearest stores service
        this.storesToMap = [];

        this.storeService
            .getAllStores()
            .pipe(takeUntil(this.unSubscribe), take(1))
            .subscribe(data => {
                this.allStores = [];
                for (let i = 0; i < data.length; i++) {
                    this.allStores.push(new Store(data[i]));
                }
                this.storesToMap = this.allStores;
                this.cdRef.detectChanges();
            });
    }

    /**
     *
     * ngOnDestroy to unsubscribe on component subscriptions
     * @memberof FindStoreComponent
     */
    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }

    /**
     * Activates when child component finds the user's geo location
     * Finds stores based on the user's geo location
     * @param position
     */

    onGeoLocation(position: any) {
        if (!this.isSearched) {
            this.findNearestStore(
                position.coords.latitude + ', ' + position.coords.longitude
            );
        }
    }

    /**
     * Calls backend to locate nearest stores
     * @param {string} address
     */

    findNearestStore(address: string): void {
        const store: IStoreRequest = {
            Country: 'Denmark',
            ZipCode: address,
            Count: 3,
        }; // TODO: Dynamically fetch country
        this.storeService
            .findNearestStore(store)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(data => {
                if (this.isSearched) {
                    this.latestZipSearch = [];
                    for (let i = 0; i < data.length; i++) {
                        this.latestZipSearch.push(new Store(data[i]));
                    }
                    this.storesToMap = this.latestZipSearch;
                    this.cdRef.detectChanges();
                }
            });
    }

    /**
     * Activates when user selects a store from list
     * @param {string} item
     */

    onSelectItem(item: string): void {
        const stores = this.allStores.filter(
            store => store.StoreImportDescription === item
        );

        this.selectedStoreObject.emit(stores[0]);
        this.storesToMap = stores;
        this.isSearched = false;
    }

    /**
     *
     * onListClick activates when a list item is clicked on
     * @param {Store} store
     * @memberof FindStoreComponent
     */

    onListClick(store: Store) {
        const stores: Store[] = [];
        stores.push(store);
        this.selectedStoreObject.emit(stores[0]);
        this.storesToMap = stores;
        this.isSearched = false;
    }

    /**
     * Activates when user submits a zip number
     */

    onSubmitZip(): void {
        this.findNearestStore(this.zip);
        this.isSearched = true;
        this.selectedStore = this.translations.Store.StoreFinderPane.ChooseStore;
    }

    onStoreSelected(store) {
        if (this.isPane) {
            this.router.navigate(
                [
                    {
                        outlets: {
                            dialog: store.LinkToContentPage,
                        },
                    },
                ],
                { queryParamsHandling: 'preserve' }
            );
            this.paneService.closeAll();
        } else {
            this.selectedStoreObject.emit(store);
        }
    }
}
