import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {
    ProductDetailsImageSliderComponent,
} from '@features/product-page/product-details-image-slider/product-details-image-slider.component';
import {StockInStoreService} from '@features/product-page/stock-in-store/stock-in-store.service';
import {WatchProductService} from '@features/product-page/watch-product/watch-product.service';
import {RightpaneModule} from '@features/rightpane/rightpane.module';
import {UspModule} from '@features/usp/usp.module';
import {NgxLazyloadModule} from '@features/ngx-lazyload/ngx-lazyload.module';
import {KfmCurrencyPipe} from '@shared/pipes/currency/kfm-currency.pipe';
import {SharedModule} from '@shared/shared.module';

import {ProductAddToBasketComponent} from './product-add-to-basket-button/product-add-to-basket-button.component';
import {ProductFavoriteButtonComponent} from './product-favorite-button/product-favorite-button.component';
import {ProductPriceComponent} from './product-price/product-price.component';
import {ProductSizePickerButtonComponent} from './product-size-picker/product-size-picker-button.component';
import {ProductSizePickerComponent} from './product-size-picker/product-size-picker.component';
import {ProductSpotComponent} from './product-spot/product-spot.component';
import {InfoDescriptionComponent} from './product-spot/info-description/info.description.component';
import {ProductStoreStockButtonComponent} from './product-store-stock-button/product-store-stock-button.component';
import {ProductVariantsListComponent} from './product-variants-list/product-variants-list.component';
import {ProductZoomComponent} from './product-zoom/product-zoom.component';
import {StockInStoreComponent} from './stock-in-store/stock-in-store.component';
import {WatchProductComponent} from './watch-product/watch-product.component';
import {InsertLinkPipe} from './product-spot/insert-link.pipe';
import {BrandInfoComponent} from './product-spot/brand-info/brand.info.component';
import {FavoritePickerModule} from '@features/favorite-picker/favorite-picker.module';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxLazyloadModule,
        RightpaneModule,
        RouterModule,
        UspModule,
        FavoritePickerModule,
    ],
    declarations: [
        ProductSizePickerButtonComponent,
        ProductAddToBasketComponent,
        ProductFavoriteButtonComponent,
        ProductSpotComponent,
        InfoDescriptionComponent,
        BrandInfoComponent,
        ProductPriceComponent,
        ProductSizePickerComponent,
        ProductStoreStockButtonComponent,
        ProductDetailsImageSliderComponent,
        StockInStoreComponent,
        WatchProductComponent,
        ProductVariantsListComponent,
        ProductZoomComponent,
        InsertLinkPipe,
    ],
    exports: [ProductSpotComponent, ProductSizePickerComponent],
    entryComponents: [
        ProductSizePickerButtonComponent,
        ProductAddToBasketComponent,
        ProductFavoriteButtonComponent,
        ProductSpotComponent,
        ProductPriceComponent,
        StockInStoreComponent,
        WatchProductComponent,
        ProductVariantsListComponent,
        ProductSizePickerComponent,
        ProductStoreStockButtonComponent,
        ProductZoomComponent,
    ],
    providers: [StockInStoreService, WatchProductService, KfmCurrencyPipe],
})
export class ProductPageModule {}
