import {
    Component,
    Inject,
    InjectDecorator,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    SimpleChanges
} from '@angular/core';
import {PageService} from '@core/page.service';
import {arrowRight, arrowRight90} from '@shared/svg';
import {take, takeUntil} from 'rxjs/operators';
import {ResponsiveService} from '@core/responsive.service';
import {Subject} from 'rxjs';
import {TranslationService} from '@core/translation.service';
import {isPlatformBrowser} from '@angular/common';
import {BreadCrumbService} from '@shared/breadcrumb/bread-crumb.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * Prep breadcrumb for icons
     *
     * @memberof BreadcrumbComponent
     */
    icons = {
        arrowRight,
        arrowRight90,
    };

    pageData: any = {};
    breadcrumbLd: any = {};
    isMobile = false;

    @Input() floated = false;
    @Input() productPage = false;
    private destroy: Subject<any> = new Subject();

    constructor(
        private pageService: PageService,
        private responsiveService: ResponsiveService,
        public transitions: TranslationService,
        @Inject(PLATFORM_ID) private platformId: InjectDecorator,
        public breadcrumbService: BreadCrumbService,
        ) {}

    ngOnInit(): void {
        this.responsiveService.mediaMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe(breakpoint => {
                this.isMobile = breakpoint.matches;
            });

        this.breadcrumbLd = {
            '@context': 'http://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [],
        };

        // Gets the page data
        this.pageService.page.pipe(take(1)).subscribe(data => {
            if (data.hasOwnProperty('pageData')) {
                this.pageData = data.pageData;
                const items = this.breadcrumbLdItemElements(
                    data.pageData.BreadCrumb
                );
                this.breadcrumbLd.itemListElement = items;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isPlatformBrowser(this.platformId)) {
            if (this.breadcrumbService.init$.getValue()) {
                this.breadcrumbService.init$.next(false);
            } else {
                this.breadcrumbService.breadcrumbHideBackButton$.next(false);
            }
        }
    }

    breadcrumbLdItemElements(items: any[]): any[] {
        const outputItems = [];
        items.forEach((item, index) => {
            outputItems.push({
                '@type': 'ListItem',
                position: index + 1,
                item: {
                    '@id': item.Url,
                    name: item.Title,
                },
            });
        });
        return outputItems;
    }
    goBack(): void {
        history.back();
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }
}
