import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiptComponent } from './receipt/receipt.component';
import { ReceiptService } from '@core/receipt.service';
import { SharedModule } from '@shared/shared.module';
import { NgxLazyloadModule } from '@features/ngx-lazyload/ngx-lazyload.module';

@NgModule({
    imports: [CommonModule, SharedModule, NgxLazyloadModule],
    declarations: [ReceiptComponent],
    exports: [ReceiptComponent],
    entryComponents: [ReceiptComponent],
    providers: [ReceiptService],
})
export class ReceiptModule {}
