<div class='columns' *ngIf='data.Heading && articles.length > 0'>
    <div class='column is-5-desktop'>
        <div class='article-reference-list__sup-heading'>{{data.SupHeading}}</div>
        <div class='spot-text__heading' [innerHTML]='data.Heading | safeHtml'></div>
    </div>
    <div class='column article-reference-list__link-container'>
        <app-svg [src]='arrowRight' [size]="'normal'"></app-svg>
        <a [routerLink]='data.LinkToAllArticles'>{{data.SeeAllArticlesLinkText}}</a>
    </div>
</div>

<div class='columns'>
    <div class='column' *ngFor='let article of articles; let i = index;' [ngClass]='columnClass'>
        <section class='spot-reference' *ngIf='article.Link'>
            <a [routerLink]='article.Link' target='{{article.LinkTarget}}' class='spot-reference__link'
               (click)='raptorItemClick(article)'>
                <div class='spot-reference__image-wrapper'>
                    <LazyPicture [LazyLoadSrc]="article.Image + '?w=' + imageWidthXl"
                                 [LazyLoadAlt]='article.ImageAltText ? article.ImageAltText : imagePathName(article.Image)'
                                 [LazyLoadSources]="[
            {media: '(max-width: 480px)', srcSet: article.Image + '?w=' + imageWidthSm },
            {media: '(min-width: 481px) and (max-width: 768px)', srcSet: article.Image + '?w=' + imageWidthMd },
            {media: '(min-width: 769px) and (max-width: 1024px)', srcSet: article.Image + '?w=' + imageWidthLg },
            {media: '(min-width: 1025px)', srcSet: article.Image + '?w=' + imageWidthXl }
            ]"
                    [LazyImageWidth]="article.ImageWidth"
                    [LazyImageHeight]="article.ImageHeight"
                    ></LazyPicture>
                </div>
                <div class='spot-reference__body'>
                    <div class='spot-reference__sup-heading'>
                        {{articleUniverseTitle}}<span
                        class='spot-reference__sup-heading_light'>{{!!article.RaptorContentTagNames?.length ? article.RaptorContentTagNames[0] : ''}}</span>
                    </div>

                    <app-spot-heading-seo
                        [headingClass]="'spot-reference__heading'"
                        [hTag]='data.HeadingSeoImportance'
                        [headingText]='article.Heading'
                        *ngIf='article.Heading'
                    ></app-spot-heading-seo>
                    <div class='spot-reference__action'>
                        <app-svg [src]="'arrow-right-black'" [size]="'normal'"></app-svg>
                        <span class='spot-reference__text'>{{articleReferenceSpotLinkToArticleText}}</span>
                    </div>
                </div>
            </a>
        </section>
    </div>
</div>
