import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, ObservableInput, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LoggingService} from '@core/logging.service';

@Injectable()
export class ZipcodeService {
    constructor(
        private httpClient: HttpClient,
        private logging: LoggingService,
    ) {}

    /**
     * Get city from zipcode
     *
     * @param {number} zipcode
     * @returns {Observable<any>}
     * @memberof ZipcodeService
     */
    public getCity(zipcode: string): Observable<any> {
        return this.httpClient
            .get(`https://dawa.aws.dk/postnumre/${zipcode}`)
            .pipe(
                catchError((err): ObservableInput<any> => {
                    this.logging.exception(`Error happened in https://dawa.aws.dk/postnumre/${zipcode}. Error was: ${err}`);
                    return throwError(err);
                })
            );
    }
}
