import { Pipe, PipeTransform } from '@angular/core';
import { Facet, MultiCheckBoxFacet } from '@features/filter/models/facet.model';

@Pipe({
    name: 'popularFacets',
})
export class PopularFacetsPipe implements PipeTransform {
    transform(value: Facet[]): MultiCheckBoxFacet[] {
        const returnValue = value.filter<MultiCheckBoxFacet>(
            (f): f is MultiCheckBoxFacet =>
                f.kind === 'Multicheck' && !!f.popularKey && f.popularChildren.length > 0
        );
        return returnValue;
    }
}
