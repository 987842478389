import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImpactWebsiteCodeWebApiControllersPersonalizeControllerPersonalizeRequest as IPersonalizeRequest } from '@shared/swagger/swagger.interface';
import { Observable } from 'rxjs';
import { SiteContextService } from '@core/site-context.service';
import { UserService } from '@core/user.service';

@Injectable({
    providedIn: 'root',
})
export class PersonalizeContentService {
    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService,
        private userService: UserService
    ) {}

    private env: string = this.siteContextService.getRootUrl();

    userContent(args: IPersonalizeRequest): Observable<any> {
        return this.http.post(
            `${this.env}/webapi/personalize/personalizecontent`,
            args
        );
    }
}
