<div class="container mega-menu">
    <div class="mega-menu__columns">
        <div class="mega-menu__column" *ngFor="let menuItem of item.MainMenuItems; index as i;">
            <ng-container *ngIf="menuItem.NavigationLink !== ''">
                <a class="mega-menu__column-title" *ngIf="!menuItem.HideTitle" [IRouterLink]="menuItem.NavigationLink" [closeDialog]="true"
               (click)="handleClick(menuItem.NavigationLink)">{{menuItem.NavigationTitle}}</a>
            </ng-container>
            <ng-container *ngIf="menuItem.NavigationLink === ''">
                <span class="mega-menu__column-title" *ngIf="!menuItem.HideTitle">{{menuItem.NavigationTitle}}</span>
            </ng-container>
            <ul class="mega-menu__sub-items" [appMegaMenuClasses]="menuItem" [idx]="i">
                <li *ngFor="let subItem of menuItem.Subitems">
                    <a
                        [IRouterLink]="subItem.NavigationLink"
                        [closeDialog]="true"
                        class="mega-menu__link"
                        (click)="handleClick(subItem.NavigationLink)">{{subItem.NavigationTitle}}</a>
                </li>
            </ul>
        </div>
        <div class="mega-menu__column" [ngStyle]="{'grid-column': getColumnSpanValue(i, n)}" *ngFor="let spotItem of item.MegaMenuSpots; index as i; count as n;">
            <app-mega-menu-spot [listItem]="listItem" [data]="spotItem"></app-mega-menu-spot>
        </div>
        <a [IRouterLink]="item.NavigationLink" (click)="handleClick()" *ngIf="item.SeeAllLinkText" class="button button_as-text-link-arrow button_as-text-link-arrow-black">{{item.SeeAllLinkText}}</a>
    </div>
</div>
