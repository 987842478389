import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {RAVEN} from 'app/tokens';
import {RavenStatic} from 'raven-js';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        // @Inject(RAVEN) private raven: RavenStatic
    ) {
    }

    exception(message: string, extraObject: {} = {}): void {
        console.log(message, extraObject);
        /*this.raven.captureException(message, {
            extra: {
                extra: extraObject
            }
        });*/
    }
}
