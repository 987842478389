import { animate, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { SiteContextService } from '@core/site-context.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { arrowDownAxel, downArrow } from '@shared/svg';

import { IFooterSection } from './footer-section.model';
import {BreakpointObserver} from '@angular/cdk/layout';
import { ResponsiveService } from '@core/responsive.service';

/**
 * Renders a column in the footer and adds
 * accordion functionality on mobile
 */
@Component({
    selector: 'footer-section',
    // templateUrl: './footer-section.component.html',
    template: `
        <section class="footer__section footer__section--accordion" [ngClass]="{'footer__section--open': open === 'open'}">
            <h3 class="footer__section-title"
                (click)="toggleColumn()" [ngSwitch]="siteContextName === 'Axel'">
                {{ data.Title }}
                <span class="footer__section-title-icon" *ngSwitchCase="true" [innerHTML]="arrowDownAxel | safeHtml"></span>
                <span class="footer__section-title-icon" *ngSwitchCase="false" [innerHTML]="downArrow | safeHtml"></span>
            </h3>

            <ul class="footer__section-list" [@slideInOut]="open" intercept-links>
                <li *ngFor="let link of data.InfoItems" class="footer__section-list-item">
                    <a class="footer__section-list-link"
                        [target]="link.LinkTarget ? link.LinkTarget : '_self'"
                        [href]="link.Link"
                        (click)="trackNavigationClick(link.Link)">
                        {{link.LinkText}}
                    </a>
                </li>
            </ul>
        </section>
    `,
    animations: [
        trigger('slideInOut', [
            state(
                'open',
                style({
                    overflow: 'hidden',
                    height: '*',
                    opacity: '1',
                })
            ),
            state(
                'closed',
                style({
                    opacity: '0',
                    overflow: 'hidden',
                    height: '0px',
                })
            ),
            transition(
                'open <=> closed',
                animate('150ms cubic-bezier(0.35, 0, 0.25, 1)')
            )
        ]),
    ],
})
export class FooterSectionComponent implements OnInit {
    /**
     * Constructs and injects our services
     * @param {object} platformId
     * @param tracking
     * @param siteContextService
     * @param responsiveService
     */
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private tracking: TrackingService,
        private siteContextService: SiteContextService,
        private responsiveService: ResponsiveService,
    ) {
    }

    /**
     * The content of the column
     */
    @Input() data: IFooterSection;
    @Input() accordion = true;

    siteContextName: string;

    /**
     * Defines the current state of the accordion
     * @type {string}
     */
    open = 'open';
    isMobile: boolean;
    downArrow = downArrow;
    arrowDownAxel = arrowDownAxel;

    ngOnInit(): void {
        this.siteContextName = this.siteContextService.getContext().businessDimension;
        this.responsiveService.mediaMobile().subscribe(observe => {
            this.open = observe.matches ? 'closed' : 'open';
        });
    }

    /**
     * Toggle the accordion on mobile
     */
    toggleColumn() {
        if (!this.accordion) {
            return;
        }

        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth < 800) {
                this.open = this.open === 'open' ? 'closed' : 'open';
            }
        }
    }

    trackNavigationClick(link) {
        this.tracking.sendNavigationEvent('Footer menu', link);
    }
}
