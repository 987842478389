<div class="minibasket-product">
    <div class="minibasket-product__image minibasket-product__image_large" [style.background-color]="'#' + productImageBgColor">
        <div class="lazy-load" [style.paddingBottom.%]="(180 / 125) * 100">
            <a
                [IRouterLink]="activeColorGroup.ColorGroupPath.split('?')[0]"
                [queryParams]="{id: product.StyleId + '-' + activeColorGroup.ColorId}">
                <img
                    LazyLoad
                    [LazyLoadSrc]="image + '&w=109&h=164&bgcolor=' + productImageBgColor"
                    [LazyLoadSrcSet]="image + '&w=109&h=164&bgcolor=' + productImageBgColor + ' 1x,' + image + '&w=218&h=328&bgcolor=' + productImageBgColor + ' 2x'"
                    alt="{{product.Name}}" />
            </a>
        </div>
    </div>
    <div class="minibasket-product__details">
        <div class="minibasket-product__top">
            <div class="minibasket-product__image minibasket-product__image_small">
                <div class="lazy-load" [style.paddingBottom.%]="(180 / 125) * 100">
                    <a
                        [IRouterLink]="activeColorGroup.ColorGroupPath.split('?')[0]"
                        [queryParams]="{id: product.StyleId + '-' + activeColorGroup.ColorId}">
                        <img
                            LazyLoad
                            [LazyLoadSrc]="image + '&w=109&h=164&bgcolor=' + productImageBgColor"
                            [LazyLoadSrcSet]="image + '&w=109&h=164&bgcolor=' + productImageBgColor + ' 1x,' + image + '&w=218&h=328&bgcolor=' + productImageBgColor + ' 2x'"
                            alt="{{product.Name}}" />
                    </a>
                </div>
            </div>

            <div class="minibasket-product__name">
                <header class="minibasket-product__header">
                    <a
                        [IRouterLink]="activeColorGroup.ColorGroupPath.split('?')[0]"
                        [queryParams]="{id: product.StyleId + '-' + activeColorGroup.ColorId}"
                        class="minibasket-product__link">
                        {{product.BrandName}}
                    </a>
                </header>
                <div class="minibasket-product__info">
                    <div class="minibasket-product__type">
                        {{getProductTags()}}
                        <br />{{product.StyleId}}
                    </div>

                </div>
                <div class="basket-product-price">
                    <ng-container *ngIf="!activeColorGroup.SalePrice">
                        <div class="basket-product-price__price">{{activeColorGroup.Price | kfmCurrency: product.Currency}}</div>
                    </ng-container>
                    <ng-container *ngIf="activeColorGroup.SalePrice">
                        <div class="basket-product-price__price basket-product-price__price_discount">{{activeColorGroup.SalePrice
                            | kfmCurrency: product.Currency}}</div>
                        <div class="basket-product-price__price basket-product-price__price_before">{{activeColorGroup.Price
                            | kfmCurrency: product.Currency}}</div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="minibasket-product__bottom">
            <div class="minibasket-product__size-add">
                <div class="minibasket-product__size">
                    <div class="input-container input-container_icon input-container_background">
                        <select class="input input_select input_filled minibasket-product__select" [class.input_round]="businessDimension !== 'axel'"
                                name="size"
                                [(ngModel)]="selectedSize"
                                #size="ngModel">
                            <option value="">{{translate?.Minibasket?.UpSellingProducts?.SelectSize}}</option>
                            <option *ngFor="let size of activeColorGroup.Skus | skuInStock" [ngValue]="size">{{size.SizeLabel}}</option>
                        </select>
                        <span class="input-container__icon input-container__icon_expand" [innerHTML]="icons.expand | safeHtml"></span>
                    </div>
                </div>
                <div class="minibasket-product__add-to-cart">
                    <div class="input-container input-container_icon input-container_background">
                        <button
                            class="button button_primary button_full add-to-basket-button"
                            type="button"
                            (click)="addToBasket()"
                            [disabled]="selectedSize === ''"
                            [attr.aria-label]="translate?.ProductDetails?.SideBarBasketSection?.AddToCartButtonText"
                        >
                            <app-svg src="basket" [inline]="true"></app-svg>
                            <spinner [name]="basketSpinnerName" [size]="20" background="true" inverted="true"></spinner>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
