<div class="find-store__body">
    <p>{{translations?.Store?.StoreFinderPane?.FindStoreDescription}}</p>
</div>
<div class="find-store__flex-container">
    <div class="find-store__left-button">

        <div class="input-container input-container_icon input-container_background">
            <select type="submit" class="input_filled input input_select" [(ngModel)]="selectedStore" #item (change)="onSelectItem(item.value)">
                <option>{{translations?.Store?.StoreFinderPane?.ChooseStore}}</option>
                <option *ngFor="let store of allStores">{{ store.StoreImportDescription }}</option>
            </select>
            <span class="input-container__icon input-container__icon_expand" [innerHTML]="icons.expand | safeHtml"></span>
        </div>
    </div>
    <div class="find-store__right-input">
        <label class="label label_animated">
            <div class="input-container input-container_icon">
                <form (submit)="onSubmitZip()">
                    <input type="text" appResetInput class="input input_text find-store__right-input__input" name="zip-code" [(ngModel)]="zip">
                    <span class="input-container__icon input-container__icon-mail" [innerHTML]="icons.search | safeHtml"></span>
                    <span class="label__text">{{translations?.Store?.StoreFinderPane?.ZipCode}}</span>
                </form>
            </div>
        </label>
    </div>
</div>

<div *ngIf="isBrowser">
    <app-store-map [stores]="storesToMap" [isSearched]="isSearched" [mapHeight]="mapHeight" [isPane]="isPane" [singleStoreToShow]="singleStoreToShow"
        (getStoresFromGeoLocation)="onGeoLocation($event)" (onStoreSelected)="onStoreSelected($event)"></app-store-map>
</div>

<div *ngIf="isSearched">
    <app-find-store-list [stores]="storesToMap" [isPane]="isPane" (listClick)="onListClick($event)">
    </app-find-store-list>
</div>