import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-sublayout33',
    templateUrl: './sublayout33.component.html',
})
export class Sublayout33Component {
    static ref = 'Sublayout33';

    @Input() data: any;

    @HostBinding('class.is-hidden-mobile') get isHiddenMobile() {
        return !this.data.ShowOnMobile && this.data.ShowOnTabletAndDesktop;
    }
    @HostBinding('class.is-hidden-tablet') get isHiddenTabletDesktop() {
        return !this.data.ShowOnTabletAndDesktop && this.data.ShowOnMobile;
    }

}
