<div class="minibasket-voucher">
    <ng-container *ngIf="!basket?.VoucherApplied">
        <label class="label label_animated">
            <div class="input-container input-container_icon">
                <input type="text" appResetInput class="input input_text input_bold" name="code" (change)="onVoucher($event)" [(ngModel)]="voucher.code"
                    #code="ngModel">
                <span class="input-container__icon input-container__icon-password" [innerHTML]="icons.password | safeHtml"></span>
                <span class="label__text">{{(translationService.translations$ | async)?.Minibasket?.Voucher?.EnterVoucher}}</span>
            </div>
        </label>

        <app-alert class="minibasket-voucher__response" *ngIf="response.text" [closeCallback]="closeAlert" [type]="response.type"
            [text]="response.text"></app-alert>
    </ng-container>
    <div class="minibasket-voucher__item" *ngIf="basket?.VoucherApplied" @slideInOutAnimation>
        <div class="minibasket-voucher__label">{{basket?.VoucherDescription}}</div>
        <div class="minibasket-voucher__price">{{basket?.VoucherBalance | kfmCurrency: basket?.Currency}}</div>
        <button class="minibasket-voucher__remove" (click)="removeVoucher()" [innerHTML]="icons.cross | safeHtml"></button>
    </div>
</div>