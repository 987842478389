import { finalize, takeUntil } from 'rxjs/operators';
import { AlertResponse, AlertType } from '@shared/alert/alert.interface';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ImpactWebsiteCodeWebApiControllersB2BUserControllerChangePasswordRequest as changePasswordRequest } from '@shared/swagger/swagger.interface';
import { Subject } from 'rxjs';
import { UserService } from '@core/user.service';
import { checkmark, password } from '@shared/svg';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-customer-change-password',
    templateUrl: './customer-change-password.component.html',
})
export class CustomerChangePasswordComponent implements OnInit, OnDestroy {
    constructor(
        private userService: UserService,
        private spinnerService: SpinnerService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.userService.currentUser$
            .pipe(takeUntil(this.destroy))
            .subscribe(data => (this.user.Email = data.Email));

        this.translationService.translations$
            .pipe(takeUntil(this.destroy))
            .subscribe(data => (this.translations = data));
    }

    private destroy = new Subject();

    translations: any;
    submitted = false;
    response: AlertResponse = {};
    user: customChangePasswordRequest = {};
    icons = {
        password,
        checkmark,
    };

    onChangePassword(form: NgForm) {
        this.response = {};

        if (form.valid && !this.submitted) {
            this.spinnerService.show('changePasswordSpinner');
            this.submitted = true;

            this.userService
                .changePassword(this.user)
                .pipe(
                    finalize(() => {
                        this.spinnerService.hide('changePasswordSpinner');
                        this.submitted = false;
                    })
                )
                .subscribe(
                    data => {
                        // Reset form
                        this.user.Password = '';
                        this.user.RepeatPassword = '';
                        form.onReset();

                        this.response = {
                            type: AlertType.success,
                            text: this.translations.Profile.Content
                                .YourProfileWasUpdated,
                        };
                    },
                    ({ error }) =>
                        (this.response = {
                            type: AlertType.error,
                            text: error.Message,
                        })
                );
        }
    }

    closeAlert = () => {
        this.response = {};
    };

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }
}

interface customChangePasswordRequest extends changePasswordRequest {
    RepeatPassword?: string;
}
