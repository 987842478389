import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { SiteContextService } from '@core/site-context.service';

@Injectable({
    providedIn: 'root',
})
export class XdbTrackingService {
    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService
    ) {}

    initializeTracking(): void {
        const requestUrl =
            this.siteContextService.getRootUrl() + environment.proxyPageApiRoot;
        this.http
            .post(`${requestUrl}/webapi/TrackingData/InitializeTracking`, {})
            .subscribe();
    }

    trackPageView(itemId: string): Observable<any> {
        const requestUrl =
            this.siteContextService.getRootUrl() + environment.proxyPageApiRoot;
        return this.http
            .post(
                `${requestUrl}/webapi/TrackingData/TrackPageView`,
                new TrackPageViewRequestModel(itemId)
            )
            .pipe(
                catchError((err, caught) => {
                    console.error(
                        'XDB Tracking Page View - missing page item id',
                        err
                    );
                    return of({});
                })
            );
    }

    trackIdentifyUser(itemId: string): void {
        const requestUrl =
            this.siteContextService.getRootUrl() + environment.proxyPageApiRoot;
        this.http
            .post(
                `${requestUrl}/webapi/TrackingData/TrackIdentifyUser`,
                new TrackPageViewRequestModel(itemId)
            )
            .subscribe();
    }

    trackProductView(
        url: string,
        styleId: string,
        colorId: string,
        category: string
    ): Observable<any> {
        const requestUrl =
            this.siteContextService.getRootUrl() + environment.proxyPageApiRoot;
        return this.http
            .post(
                `${requestUrl}/webapi/TrackingData/TrackProductView`,
                new TrackProductViewRequestModel(
                    url,
                    styleId,
                    colorId,
                    category
                )
            )
            .pipe(
                catchError((err, caught) => {
                    console.error(
                        'XDB Tracking Product View - missing data',
                        err
                    );
                    return of({});
                })
            );
    }

    trackGoToPayment(): void {
        const requestUrl =
            this.siteContextService.getRootUrl() + environment.proxyPageApiRoot;
        this.http
            .post(`${requestUrl}/webapi/TrackingData/TrackGoalGoToPurchase`, {})
            .subscribe();
    }

    trackGoalPurchase(orderId: string): void {
        const requestUrl =
            this.siteContextService.getRootUrl() + environment.proxyPageApiRoot;
        this.http
            .post(
                `${requestUrl}/webapi/TrackingData/TrackGoalPurchase?orderId=${orderId}`,
                {}
            )
            .subscribe();
    }
}

class TrackPageViewRequestModel {
    ItemId: string;
    constructor(ItemId: string) {
        this.ItemId = ItemId;
    }
}

class TrackProductViewRequestModel {
    Url: string;
    StyleId: string;
    ColorId: string;
    Category: string;

    constructor(
        Url: string,
        StyleId: string,
        ColorId: string,
        Category: string
    ) {
        this.Url = Url;
        this.StyleId = StyleId;
        this.ColorId = ColorId;
        this.Category = Category;
    }
}
