<!-- PRODUCT DETAILS & INFORMATION -->
<app-accordion
    [id]="'productDetailsInformation'"
    [title]="translations?.ProductDetails?.ProductInfoSectionInformation?.ProductInfoTitle"
    [activeId]="currentTab"
    (onToggle)="accordionChange($event)">

    <ul class="product-spot__details__info__description-list">
        <li *ngIf="activeProduct?.productData?.Model">
            {{translations?.ProductDetails?.ProductInfoSectionInformation?.ModelLabel}}: {{activeProduct?.productData.Model}}
        </li>
        <li *ngIf="activeProduct?.productData?.Fit">
            {{translations?.ProductDetails?.ProductInfoSectionInformation?.FitLabel}}: {{activeProduct?.productData?.Fit}}
        </li>
        <li>
            {{translations?.ProductDetails?.ProductInfoSectionInformation?.ColorLabel}}: {{activeProduct.activeProductVariant.ColorName}}
        </li>
        <li>
            {{translations?.ProductDetails?.ProductInfoSectionInformation?.ProductNumberLabel}}: {{activeProduct?.productData?.StyleId}}
        </li>
    </ul>
    <ul intercept-links class="info-description-list" *ngIf="!!activeProduct?.productInformation?.productInfoList.length">
        <li *ngFor="let information of activeProduct?.productInformation?.productInfoList | insertLink: activeProduct.productInformation.ProductInfoLinks" [innerHTML]="information"></li>
    </ul>
    <p class="info-description-text" *ngIf="activeProduct?.productData?.Description" [innerHTML]="activeProduct?.productData.Description | transformToLineBreaks: true | insertLink: activeProduct.productInformation.ProductInfoLinks"></p>
</app-accordion>

<!-- SIZE & FIT -->
<app-accordion
    [id]="'sizefit'"
    [title]="translations?.ProductDetails?.ProductSizeFit?.SizeFitTitle"
    [activeId]="currentTab"
    (onToggle)="accordionChange($event)"
    *ngIf="!!activeProduct?.productData?.SizeFit?.length">
    <p class="product-spot__details__info__description-list" [innerHTML]="activeProduct?.productData?.SizeFit | transformToLineBreaks: true | insertLink: activeProduct.productInformation.ProductInfoLinks"></p>
</app-accordion>

<!-- PRODUCT MATERIALS, WASHING & CARE INSTRUCTIONS -->
<app-accordion
    [id]="'materialswashingInstructions'"
    [title]="translations?.ProductDetails?.ProductInfoSectionWashCare?.WashCareTitle"
    [activeId]="currentTab"
    (onToggle)="accordionChange($event)"
    *ngIf="activeProduct?.productInformation?.productCareLabels.length > 0 || activeProduct?.productData?.MaterialPercentages.length > 0">
    <ul class="info-description-list" *ngIf="!!activeProduct?.productData?.MaterialPercentages.length">
        <li *ngFor="let productMaterial of activeProduct?.productData?.MaterialPercentages">
            {{productMaterial}}
        </li>
    </ul>
    <ul class="product-spot__details__info__description-care info-description-list" *ngIf="!!activeProduct?.productInformation?.productCareLabels.length">
        <li [attr.icon-label]="label.Description"
            *ngFor="let label of activeProduct?.productInformation?.productCareLabels">
            <div class="product-spot__details__washing-icon" [innerHtml]="treatmentIcons[label.Id] | safeHtml" aria-hidden="true"></div>
            <div class="product-spot__details__washing-icon-description">{{label.Description}}</div>
        </li>
    </ul>
</app-accordion>
