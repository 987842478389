import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';

import {
    CallbackType,
    ExtendedIntersectionObserverEntry,
    ObserverServiceConfig,
    WatchedItem,
} from './observer.interface';

@Injectable()
export class ObserverService {
    options: ObserverServiceConfig = {
        rootMargin: '0px',
        threshold: 0.1,
    };

    supported = false;

    watching: WatchedItem[] = [];

    observer: IntersectionObserver | null;

    isBot = false;

    /**
     * Assigns the config applied by the user
     * in the forRoot function on the module
     */
    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Optional() config?: ObserverServiceConfig,
        @Optional() @Inject('isBot') isBot?: boolean
    ) {
        this.isBot = !!isBot;

        if (isPlatformBrowser(this.platformId)) {
            this.supported =
                typeof window !== 'undefined' &&
                'IntersectionObserver' in window &&
                'IntersectionObserverEntry' in window;
            if (config) {
                this.options = {...this.options, ...config};
            }

            this.observer = this.supported
                ? new IntersectionObserver(
                      this.handleEvent.bind(this),
                      this.options
                  )
                : null;
        }
    }

    /**
     * Handles events made by the Observer
     */
    handleEvent(entries: ExtendedIntersectionObserverEntry[]): void {
        entries.forEach((entry: ExtendedIntersectionObserverEntry) => {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
                const target = this.removeTarget(entry.target);

                if (target) {
                    target.callback(true);
                }
            }
        });
    }

    /**
     * Adds the element to the array of objects to observe
     * and runs the callback when it intersects the viewport
     * based on the configs.
     */
    addTarget(el: Element, callback: CallbackType): void {
        this.observer.observe(el);

        this.watching.push({
            element: el,
            callback,
        });
    }

    removeTarget(el: Element): WatchedItem {
        const targetIndex = this.watching.findIndex(element => {
            return element.element.isEqualNode(el);
        });

        if (targetIndex < 0) {
            return;
        }

        const target = this.watching[targetIndex];

        if (el && this.supported) {
            this.observer.unobserve(el);
        }

        this.watching.splice(targetIndex, 1);
        return target;
    }
}
