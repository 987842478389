import { Observable, of as observableOf, of } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { environment } from 'environments/environment';

import { catchError, tap } from 'rxjs/operators';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { PaneService } from '@core/pane.service';
import { SiteContextService } from '@core/site-context.service';
import { PageService } from '@core/page.service';
import { XdbTrackingService } from '@core/tracking/xdb-tracking.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

/**
 * Page resolver, in charge of fetching our page data when routing on the site.
 */
@Injectable()
export class PageResolve implements Resolve<any> {
    /**
     *
     * @param {HttpClient} http
     * @param {TransferState} statetransferService
     * @param {Router} router
     * @param {PaneService} paneService
     * @param {SiteContextService} siteContextService
     * @param {PageService} pageService
     * @param {XdbTrackingService} xdbTracking
     * @param {object} platformId
     * @param response
     */
    constructor(
        private http: HttpClient,
        private statetransferService: TransferState,
        private router: Router,
        private paneService: PaneService,
        private siteContextService: SiteContextService,
        private pageService: PageService,
        private xdbTracking: XdbTrackingService,
        @Inject(PLATFORM_ID) private platformId: object,
        @Optional()
        @Inject('RESPONSE')
        private response: any
    ) {}

    /**
     * Resolves the page URL
     * @param {string} url
     * @param queryParams
     * @returns {any}
     */
    public resolveUrl(url: string, queryParams) {
        const stateKey = makeStateKey<object>(url);

        if (
            isPlatformBrowser(this.platformId) &&
            this.statetransferService.hasKey(stateKey)
        ) {
            const state = this.statetransferService.get(stateKey, null);
            this.statetransferService.remove(stateKey);
            return observableOf(state);
        }

        const urlTree = this.router.createUrlTree(
            [{ outlets: { primary: url, dialog: null } }],
            {
                queryParams: {
                    ...queryParams,
                    getasjson: 1,
                },
            }
        );

        const requestUrl =
            this.siteContextService.getRootUrl() +
            environment.proxyPageApiRoot +
            urlTree.toString().replace('//', '/');

        return this.http.get<any>(requestUrl).pipe(
            tap(val => {
                if (isPlatformServer(this.platformId)) {
                    this.statetransferService.set(stateKey, val);
                }

                this.paneService.closeAll();
            }),
            catchError(err => {
                // We only handle the 404 error here, rest is handled in our interceptor
                if (isPlatformServer(this.platformId)) {
                    this.response.status(404);
                }

                return of(err.error);
                // throw err;
            })
        );
    }

    /**
     * Resolves the content of the page
     * @param {ActivatedRouteSnapshot} route
     * @returns {Observable<object> & Observable<any>}
     */
    public resolve(route: ActivatedRouteSnapshot) {
        return this.resolveUrlSegment(route.url, route.queryParams);
    }

    /**
     * Resolves the URL segments
     * @param urlseg
     * @param queryParams
     * @returns {Observable<object> | Observable<any>}
     */
    private resolveUrlSegment(urlseg, queryParams) {
        const url = this.sanitizeUrlSegment(urlseg);
        return this.resolveUrl(url, queryParams);
    }

    /**
     * Sanitizes the URL segments
     * @param url
     * @returns {string}
     */
    private sanitizeUrlSegment(url) {
        return (
            '/' +
            url.reduce((acc, segment) => {
                return acc + segment.path + '/';
            }, '')
        );
    }
}
