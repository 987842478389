import { Pipe, PipeTransform } from '@angular/core';
import { ImpactCoreModelsProductsDtoSkuDto } from '@shared/swagger/swagger.interface';

@Pipe({
    name: 'xLargeReformat',
})
export class XLargeReformatPipe implements PipeTransform {
    XRegexp = new RegExp(/^X+/i);

    transform(
        sizes: ImpactCoreModelsProductsDtoSkuDto[]
    ): ImpactCoreModelsProductsDtoSkuDto[] {
        return sizes.map(size => {
            if (size.SizeLabel.includes('XXXL')) {
                const Xs = size.SizeLabel.match(this.XRegexp);
                const lastOfSizeLabel = size.SizeLabel.substring(
                    Xs[0].length,
                    size.SizeLabel.length
                );
                return {
                    ...size,
                    SizeLabel: `${Xs[0].length}X${lastOfSizeLabel}`,
                };
            }
            return size;
        });
    }
}
