import { Directive, ElementRef, HostListener, Input } from '@angular/core';

// Directive to scroll sideways with mousewheel

// Usage on single element:
// <div [horizontalMouseScroll]></div>

@Directive({
    selector: '[horizontalMouseScroll]'
})
export class HorizontalMouseScrollDirective {

    // Used for when looping through lists, to identify the element
    @Input('horizontalMouseScroll') horizontalMouseScroll;

    @HostListener('mousewheel', ['$event']) onMousewheel($event: any): void { // eslint-disable-line
        this.scrollSideways($event.wheelDelta);
    }

    constructor(private elementRef: ElementRef) {}

    private scrollSideways(distance: number) {
        this.elementRef.nativeElement.scrollLeft -= distance;
    }
}
