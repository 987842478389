import {Inject, Injectable, OnInit, PLATFORM_ID} from '@angular/core';
import {IFavoriteProductStore} from '@core/favorite.interface';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {SiteContextService} from '@core/site-context.service';
import {ProductVariant} from '@features/product-filter/models/product.model';
import {HttpClient} from '@angular/common/http';
import {
    ImpactCoreModelsProductsDtoStyleDto,
    ImpactWebsiteCodeWebApiControllersEmailControllerSendWishListArgs as SendWishListEmailRequestModel
} from '@shared/swagger/swagger.interface';
import {of} from 'rxjs/internal/observable/of';

@Injectable({
    providedIn: 'root'
})
export class FavoriteService {
    static favoriteStorageKey = '__favorite';
    static productStyleEndpoint = '/webapi/Product/Style';

    public favoriteProductStoreList$: BehaviorSubject<IFavoriteProductStore[]> = new BehaviorSubject<IFavoriteProductStore[]>([]);

    private businessDimension: string;
    private language: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private siteContextService: SiteContextService,
        private httpClient: HttpClient,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            const localStorageFavoriteProducts = localStorage.getItem(this.favoriteStorageKey());
            if (!!localStorageFavoriteProducts) {
                this.favoriteProductStoreList$.next([...JSON.parse(localStorageFavoriteProducts)]);
            }
            this.favoriteProductStoreList$.subscribe(favoriteList => localStorage.setItem(this.favoriteStorageKey(), JSON.stringify(favoriteList)))
        }
        this.businessDimension = this.siteContextService.getContext().businessDimension;
        this.language = this.siteContextService.getContext().language;
    }

    getFavoriteProductsData(): Observable<ImpactCoreModelsProductsDtoStyleDto[]> {
        const productObservable: Array<Observable<ImpactCoreModelsProductsDtoStyleDto>> = [];
        this.favoriteProductStoreList$.getValue().forEach((favProd: IFavoriteProductStore) => {
            productObservable.push(this.httpClient
                .get<ImpactCoreModelsProductsDtoStyleDto>(
                    `${this.siteContextService.getRootUrl()}${FavoriteService.productStyleEndpoint}?businessDimension=${this.businessDimension}&languageCode=${this.language}&styleId=${favProd.styleId}&colorId=${favProd.colorId}`))
        });

        if (!!productObservable?.length) {
            return forkJoin([...productObservable]);
        }
        return of([]);
    }

    removeFavorite(styleId: string, colorId: string): void {
        this.favoriteProductStoreList$.next(this.favoriteProductStoreList$.getValue().filter(f => {
            if (f.styleId === styleId) {
                return f.colorId !== colorId;
            }
            return true;
        }));
    }

    updateFavorite(styleId: string, colorId: string, sku: string) {
        const favorites = this.favoriteProductStoreList$.getValue();
        this.favoriteProductStoreList$.next(favorites.map(fav => {
            if (fav.styleId === styleId && fav.colorId === colorId) {
                return {...fav, sku};
            }
            return fav;
        }));
    }

    public sendFavorites(body: SendWishListEmailRequestModel): Observable<any> {
        return this.httpClient.post(
            `${this.siteContextService.getRootUrl()}/webapi/Email/SendWishListEmail`,
            body
        );
    }

    private favoriteStorageKey(): string {
        return `${FavoriteService.favoriteStorageKey}-${this.siteContextService.getContext().businessDimension}-${this.siteContextService.getContext().language}`;
    }

}
