import { Component, Input, OnInit } from '@angular/core';
import { SiteContextService } from '@core/site-context.service';
import { TranslationService } from '@core/translation.service';
import { arrowRight } from '@shared/svg';
import { take } from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import { Favoritebrand } from '../favorite-brand-spot.component';

/**
 * Simple component that just renders a product in the [product slider]{@link ProductSliderComponent}
 */
@Component({
    selector: 'favorite-brand-spot-brand',
    templateUrl: './favorite-brand-spot-brand.component.html',
})
export class FavoriteBrandSpotBrandComponent implements OnInit {
    constructor(
        private siteContextService: SiteContextService,
        private translationService: TranslationService
    ) {}

    @Input() public slide: Favoritebrand;
    @Input() public index: number;
    @Input() public raptorFeedType: string;
    @Input() public backgroundColor: string;
    @Input() public imageBgColor: string;

    tags: string[] = [];
    imgBgColor: string;
    translations: any;
    imageUrl: string;

    arrowRight = arrowRight;

    ngOnInit() {

        this.tags.push(this.slide.BrandName);

        // if (this.slide.Color) {
        //     this.tags.push(this.slide.Color);
        // }

        this.imgBgColor = this.brandBgColor();

        this.translationService.translations$
            .pipe(take(1))
            .subscribe((translations: any) => {
                this.translations = translations;
            });
        this.imageUrl = environment.dev && environment.local ? `${environment.cdnAbsolutePath}${this.slide.BrandImage}?i=${this.slide.BrandImage}&w=400&h=400&bgcolor=${this.imgBgColor}`
            : `${this.slide.BrandImage}&w=400&h=400&bgcolor=${this.imgBgColor}`;
    }

    brandBgColor() {
        const context = this.siteContextService.getContext();
        const bgColor = context.bgColor;

        return context.businessDimension === 'Kaufmann'
            ? this.imageBgColor
                ? this.imageBgColor
                : this.backgroundColor
                ? this.backgroundColor
                : bgColor.secondary
            : bgColor.productListItem;
    }

    getImageAltTag(slide) {
        const tags = [];

        if (slide.ProductName) {
            tags.push(slide.ProductName);
        }
        if (slide.BrandName) {
            tags.push(slide.BrandName);
        }
        if (slide.CategoryName) {
            tags.push(slide.CategoryName);
        }
        if (slide.Color) {
            tags.push(slide.Color);
        }

        return tags.join(' - ');
    }

    onClick(event) {
        event.preventDefault();
        event.stopPropagation();
        // this.trackProductImpression();
        // this.raptorItemClick();
    }

    disableDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

}
