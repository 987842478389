export const dns: IDnsLinks[] = [
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://www.kaufmannstatic.com/',
    },
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://www.googletagmanager.com/',
    },
    // Raptor
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://az19942.vo.msecnd.net/',
    },
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://widget.trustpilot.com/',
    },
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://connect.facebook.net/',
    },
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://www.google-analytics.com/',
    },
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://www.googleadservices.com/',
    },
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://googleads.g.doubleclick.net/',
    },
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://static.zdassets.com/',
    },
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://display.ipaper.io/',
    },
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://use.typekit.net/',
    },
    {
        rel: 'preconnect|dns-prefetch',
        href: 'https://policy.app.cookieinformation.com/'
    }
];

export interface IDnsLinks {
    rel: RelType;
    href: string;
    as?: RelAs;
}
export type RelType = 'preload' | 'preconnect' | 'dns-prefetch' | 'preconnect|dns-prefetch';

export type RelAs =
    | 'audio'
    | 'document'
    | 'embed'
    | 'fetch'
    | 'font'
    | 'image'
    | 'object'
    | 'script'
    | 'style'
    | 'track'
    | 'video'
    | 'worker';
