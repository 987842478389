<div class="customer-change-password">
    <form class="form" novalidate name="form" [ngClass]="{'form_submitted': form.submitted}" (ngSubmit)="onChangePassword(form)"
        #form="ngForm">
        <div class="customer__section">
            <h2 class="customer__heading customer__heading_icon">
                {{translations?.Profile?.Content?.PasswordSelectionTitle}}
                <span class="customer__icon customer__icon-password" [innerHTML]="icons.password | safeHtml"></span>
            </h2>

            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="password" class="input input_text input_bold" name="password" appResetInput required minlength="6" [(ngModel)]="user.Password"
                            #password="ngModel">
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{translations?.GeneralInput?.Content?.PasswordInput}}</span>
                    </div>
                </label>
                <div class="form__validation" *ngIf="(password.invalid && password.touched) || form.submitted">
                    <span class="validation" *ngIf="password.errors?.required">{{translations?.GeneralInput?.Content?.PasswordInputMissing}}</span>
                    <span class="validation" *ngIf="password.errors?.minlength">{{translations?.GeneralInput?.Content?.PasswordInputLength}}</span>
                </div>
            </div>
            <div class="form__field">
                <label class="label label_animated">
                    <div class="input-container input-container_icon">
                        <input type="password" class="input input_text input_bold" name="repeatPassword" appResetInput required minlength="6" [pattern]="user.Password"
                            [(ngModel)]="user.RepeatPassword" #repeatPassword="ngModel">
                        <span class="input-container__icon input-container__icon_checkmark validation-icon validation-icon_valid" [innerHTML]="icons.checkmark | safeHtml"></span>
                        <span class="label__text">{{translations?.GeneralInput?.Content?.RepeatPasswordInput}}</span>
                    </div>
                </label>
                <div class="form__validation" *ngIf="(repeatPassword.invalid && repeatPassword.touched) || form.submitted">
                    <span class="validation" *ngIf="repeatPassword.errors?.required">{{translations?.GeneralInput?.Content?.RepeatPasswordInputMissing}}</span>
                    <span class="validation" *ngIf="repeatPassword.errors?.minlength">{{translations?.GeneralInput?.Content?.RepeatPasswordInputLength}}</span>
                    <span class="validation" *ngIf="repeatPassword.errors?.pattern">{{translations?.GeneralInput?.Content?.RepeatPasswordIsNotTheSame}}</span>
                </div>
            </div>
            <div class="form__action">
                <app-alert class="form__response" *ngIf="response.text" [closeCallback]="closeAlert" [type]="response.type" [text]="response.text"></app-alert>

                <button type="submit" class="button button_outline button_full button_text-center">
                    {{translations?.Profile?.Content?.ChangePasswordButton}}
                    <spinner name="changePasswordSpinner" [size]="20" background="true"></spinner>
                </button>
            </div>
        </div>
    </form>
</div>