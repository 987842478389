import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import { MainMenuItem } from '@features/mobile-menu/menu.interface';

@Directive({
    selector: '[appMegaMenuAxelClasses]'
})
export class MegaMenuAxelClassesDirective implements OnInit {
    @Input('appMegaMenuAxelClasses') subMenuItem: MainMenuItem;
    @Input() idx: number;

    constructor(private elmRef: ElementRef) {}

    ngOnInit(): void {

        if (!this.subMenuItem.Subitems) {
            return;
        }

        let columns;
        let rows = 11;
        columns = 3;

        const countSubItems = this.subMenuItem.Subitems.length;
        if (countSubItems > 33) {
            rows = Math.ceil((this.subMenuItem.Subitems.length / 3));
        } 

        if (columns > 1) {
            this.elmRef.nativeElement.parentNode.setAttribute('style', `grid-column: ${this.idx + 1} / span ${columns}`);
            this.elmRef.nativeElement.setAttribute('style', `
                grid-template-rows: repeat(${rows}, 1fr);
                column-gap: 24px;
                grid-template-columns: repeat(${columns}, 1fr);
            `);
        } else {
            this.elmRef.nativeElement.setAttribute('style', 'display: block;');
        }
    }

}
