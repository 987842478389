import {
    ComponentFactoryResolver,
    Directive,
    Input,
    OnChanges,
    ViewContainerRef,
} from '@angular/core';
import { spots } from '@features/spots/spots';

/**
 * Directive to render a given spot based on its type and content.
 */
@Directive({
    selector: '[generic-spot]',
})
export class GenericSpotDirective implements OnChanges {
    /**
     * Spot type used to resolve the correct component
     */
    @Input() public spotType: string;

    /**
     * The data to inject into our spot component
     */
    @Input() public spotData;

    @Input() public templateType;

    @Input() public backgroundColor;

    @Input() public hideCallback;

    /**
     * Injects our dependencies
     * @param {ViewContainerRef} viewContainer
     * @param {ComponentFactoryResolver} cfResolver
     */
    constructor(
        private viewContainer: ViewContainerRef,
        private cfResolver: ComponentFactoryResolver
    ) {}

    /**
     * Detects changes and renders the correct spot based on the type
     * @param change
     */

    ngOnChanges(change) {
        if (change) {
            // Start out by clearing the view container
            this.viewContainer.clear();

            // Find the ComponentClass of the desired spotComponent (based on spotType)
            const componentClass = spots.find(
                component => component.ref === this.spotType
            );

            if (!componentClass) {
                console.warn(
                    `Couldn't find a matching component for this spot ${componentClass}`
                );
                return;
            }

            if (typeof componentClass !== 'undefined') {
                // Resolve the ComponentFactory
                const spotComponentFactory = this.cfResolver.resolveComponentFactory(
                    componentClass
                );

                // Create the component, attach it to the viewContainer and bind the data
                const spotComponent = this.viewContainer.createComponent(
                    spotComponentFactory
                );
                spotComponent.instance['data'] = this.spotData;
                spotComponent.instance['templateType'] = this.templateType;

                spotComponent.instance['hideCallback'] = this.hideCallback
                    ? this.hideCallback
                    : () => {};

                if (this.backgroundColor) {
                    spotComponent.instance[
                        'backgroundColor'
                    ] = this.backgroundColor.replace('#', '');
                }
            }
        }
    }
}
