import { isPlatformBrowser } from '@angular/common';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { SiteContextService } from '@core/site-context.service';
import { Observable } from 'rxjs';

@Injectable()
export class SettingsHeaderInterceptor implements HttpInterceptor {
    constructor(
        private siteContext: SiteContextService,
        @Inject(PLATFORM_ID) private platformId: object,
        private injector: Injector
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.indexOf('dawa.aws.dk') > -1) {
            return next.handle(req);
        }

        let clientToken: string = '';

        if (isPlatformBrowser(this.platformId)) {
            clientToken = localStorage.getItem('clientToken') || '';
        }

        const context = this.siteContext.getContext();

        let settingsHeader;

        if (isPlatformBrowser(this.platformId)) {
            settingsHeader = req.clone({
                headers: req.headers
                    .set('BusinessDimension', context.businessDimension)
                    .set('Language', context.language)
                    .set('Access-Control-Allow-Origin', '*')
                    .set('ClientToken', clientToken),
            });
        } else {
            const cookies: string = this.injector.get('ForwardedCookie');

            settingsHeader = req.clone({
                headers: req.headers
                    .set('BusinessDimension', context.businessDimension)
                    .set('Language', context.language)
                    .set('Access-Control-Allow-Origin', '*')
                    .set('ClientToken', clientToken)
                    .set('Cookie', cookies),
            });
        }

        return next.handle(settingsHeader);
    }
}
