import { Directive, HostListener } from '@angular/core';
import { InfoService } from '@core/info.service';

@Directive({
    selector: '[open-link-in-overlay]',
})
export class OpenLinkInOverlayDirective {
    @HostListener('click', ['$event'])
    public onClick($event) {
        const linkElm = $event.target;
        if (linkElm.tagName === 'A') {
            this.infoService.openInfo(decodeURI(linkElm.pathname));
            return false;
        }
    }

    constructor(private infoService: InfoService) {}
}
