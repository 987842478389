import { InjectionToken } from '@angular/core';
import { RavenStatic } from 'raven-js';

/**
 *
 * @type {InjectionToken<any>}
 */
export const HOST: InjectionToken<any> = new InjectionToken('HOST');
export const BUILDNUMBER = new InjectionToken<string>('BUILDNUMBER');

// RavenJS
export const RAVEN = new InjectionToken<RavenStatic>('RAVEN');
export const CLOUDFLAREURL = new InjectionToken<string>('CLOUDFLAREURL');
