import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-article-reference-list-type-switch',
    template: `
        <ng-container *ngIf="data.ShowAsCarousel">
            <app-article-reference-list-carousel-spot [data]="data"></app-article-reference-list-carousel-spot>
        </ng-container>
        <ng-container *ngIf="!data.ShowAsCarousel">
            <app-article-reference-list-spot [data]="data"></app-article-reference-list-spot>
        </ng-container>
    `
})
export class ArticleReferenceListTypeSwitchComponent {
    constructor() {}

    static ref = 'Article Reference List Spot';

    @Input() data: any;

}
