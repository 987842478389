import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { LazyLoadSource } from '../lazyload.interface';
import { ObserverService } from '../observer.service';

@Component({
    selector: 'LazyPicture',
    template: `
        <picture #picture [ngClass]="{ 'lazy-loaded': loaded }">
            <source
                *ngFor="let source of LazyLoadSources"
                [media]="source.media ? source.media : ''"
                [srcset]="source.srcSet && loaded ? source.srcSet : ''"
                [sizes]="source.sizes ? source.sizes : ''"
            />
            <img [src]="initSourceImage" [alt]="LazyLoadAlt || ''" [width]="LazyImageWidth" [height]="LazyImageHeight" />
        </picture>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyPictureComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() LazyLoadSrc: string | SafeResourceUrl;
    @Input() LazyLoadAlt: string;
    @Input() LazyImageWidth: string;
    @Input() LazyImageHeight: string;
    @Input() LazyLoadSources: LazyLoadSource[];

    @ViewChild('picture') pictureEle: ElementRef;

    loaded = false;
    initSourceImage: SafeResourceUrl;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private _observer: ObserverService,
        private sanitizer: DomSanitizer,
        private cd: ChangeDetectorRef
    ) {}

    /**
     * Initiates the temporary image source
     */
    ngOnInit(): void {
        if (this._observer.isBot) {
            this.addSources(true);
        } else if (this.LazyImageWidth && this.LazyImageHeight) {
            this.initSourceImage = this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="${
                    this.LazyImageWidth
                }" height="${this.LazyImageHeight}"><rect width="${
                    this.LazyImageWidth
                }" height="${
                    this.LazyImageHeight
                }" fill="transparent"></rect></svg>`
            );
        } else {
            this.initSourceImage = this.sanitizer.bypassSecurityTrustResourceUrl(
                'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><rect width="10" height="10" fill="transparent"/></svg>'
            );
        }
    }

    /**
     * Initiates the observer watcher if Observers is supported
     * if not we simply just load the sources.
     */
    ngAfterViewInit(): void {
        if (this._observer.isBot) {
            return;
        }

        if (this._observer.supported) {
            this._observer.addTarget(
                this.pictureEle.nativeElement,
                this.addSources.bind(this)
            );
        } else if (isPlatformBrowser(this.platformId)) {
            this.addSources(true);
        }
    }

    /**
     * Destroy eventual IntersectionObserver subscriptions
     */
    ngOnDestroy() {
        this._observer.removeTarget(this.pictureEle.nativeElement);
        this.pictureEle = undefined;
    }

    /**
     * Toggles the loaded status if we are in the viewport
     */
    addSources(inViewport: boolean) {
        if (inViewport) {
            this.loaded = true;
            this.initSourceImage = this.LazyLoadSrc;
            this.cd.markForCheck();
        }
    }
}
