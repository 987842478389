import { Component, Input, OnInit } from "@angular/core";
import { washIcons } from "@shared/washicons";


@Component({
    selector: 'app-brand-info',
    templateUrl: './brand.info.component.html',
})
export class BrandInfoComponent {

    @Input()
    activeProduct;

    constructor() {}
    
}