import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupComponent } from './signup.component';
import { SignupStep1Component } from './signup-step-1/signup-step-1.component';
import { SignupStep2Component } from './signup-step-2/signup-step-2.component';
import { SharedModule } from '@shared/shared.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SignupTermsComponent } from './signup-terms/signup-terms.component';
import { NgxLazyloadModule } from '@features/ngx-lazyload/ngx-lazyload.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxLazyloadModule,
    ],
    declarations: [
        SignupComponent,
        SignupStep1Component,
        SignupStep2Component,
        SignupTermsComponent,
    ],
    exports: [SignupComponent],
    entryComponents: [SignupTermsComponent],
})
export class SignupModule {}
