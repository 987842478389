import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RegexService {
    constructor() {}

    /**
     * Email regex, matching common emails
     */
    public email: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    /**
     * Phone regex, matching simple digits
     */
    public phone: RegExp = /^\d+$/;

    /**
     * Zip code regex, matching numbers and a range
     */
    public zipCode: RegExp = /^[0-9]{1,6}$/;

    /**
     * Address code regex, maching string and a number
     */
    public address: RegExp = /.+(?=\w)(?=\d)/;
}
