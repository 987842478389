import {Directive, Input, OnInit, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appLinkTarget]'
})
export class LinkTargetDirective implements OnInit {
    @Input('appLinkTarget') linkTarget: string;

    constructor(
        private viewConRef: ViewContainerRef
    ) {
    }

    ngOnInit(): void {
        this.viewConRef.element.nativeElement.target = this.linkTarget.indexOf('http') >= 0 ? '_blank' : '_self';
    }

}
