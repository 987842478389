import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { checkmark, cross, store } from '@shared/svg';
import localeDa from '@angular/common/locales/da';
import { DatePipe, registerLocaleData } from '@angular/common';
import { SiteContextService } from '@core/site-context.service';
import { BasketService } from '@core/basket.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { XdbTrackingService } from '@core/tracking/xdb-tracking.service';
import { RaptorTrackingService } from '@core/tracking/raptor-tracking.service';
import { UserService } from '@core/user.service';

registerLocaleData(localeDa, 'da-Dk');

@Component({
    selector: 'app-checkout-receipt',
    templateUrl: './checkout-receipt.component.html',
})
export class CheckoutReceiptComponent implements OnInit {
    @Input('receiptData') data: any;

    icons = {
        cross,
        store,
        checkmark,
    };

    orderCreatedDate: string;
    showPaymentFee: boolean;

    private giftWrappingSkus: string[] = [];
    private contextLanguage: string = '';
    private VWOActive: boolean;

    constructor(
        public translationService: TranslationService,
        private siteContextService: SiteContextService,
        private datePipe: DatePipe,
        private basketService: BasketService,
        private tracking: TrackingService,
        private xdbTracking: XdbTrackingService,
        private raptorTracking: RaptorTrackingService,
        private userService: UserService,
    ) {
        // Clear basket and checkout data
        this.basketService.destroy();

        // Fetch basket to clear the old one
        this.basketService.fetchBasket();
    }

    ngOnInit() {
        /* Call to set user cookie */
        this.userService.getUserName(this.data.Email).subscribe();

        const hasGiftWrapProp = this.data.hasOwnProperty('WrappingComment');

        if (hasGiftWrapProp) {
            this.giftWrappingSkus = JSON.parse(this.data.WrappingComment);
        }

        this.contextLanguage = this.siteContextService.getContext().language;

        this.showPaymentFee = this.data.hasOwnProperty('ShowPaymentFee');

        this.data.orderCreatedDate = this.datePipe.transform(
            this.data.Created,
            this.setDateFormat()
        );

        this.mapGiftWrappingToOrderLines();

        if (!this.data.HasBeenTracked) {
            if (localStorage.getItem('orderId') !== this.data.OrderNumber) {
                localStorage.setItem('orderId', this.data.OrderNumber);

                this.tracking.sendTransaction(this.data);

                this.raptorTracking.trackBuyEvent(
                    this.data,
                    this.userService.currentUser.getValue()
                );
            }
        }

    }

    private setDateFormat(): string {
        if (this.contextLanguage !== 'en') {
            return 'dd-MM-yyyy';
        } else {
            return 'MM-dd-yyyy';
        }
    }

    private mapGiftWrappingToOrderLines() {
        this.giftWrappingSkus.map(wrapSku =>
            this.data.OrderLines.map(line => {
                if (wrapSku === line.SkuId) {
                    line['Wrapping'] = true;
                }
            })
        );
    }
}
