import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { InfoService } from '@core/info.service';
import { PaneService } from '@core/pane.service';
import { ProductService } from '@core/product.service';
import { TranslationService } from '@core/translation.service';
import { ProductSizePickerData, ProductSizeVariant } from '@features/product-filter/models/product.model';
import { IMPACT_OVERLAY_DATA, ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { mail } from '@shared/svg';
import { isStockCountHigh, lowStockCount } from '@shared/utility';
import { Observable, Subject } from 'rxjs';

export interface ProductSizePickerDataOLD {
    sizes: Size[];
    currentSize: Size;
    sizeGuideUrl: string;
    onSizeSelection(size?: Size): void;
    onProductWatch(size?: Size): void;
}
export interface Size {
    Id: string;
    StockCount?: number | null;
    SizeLabel: string;
    SizeSortIndex: number;
    SizeSortGroup: number;
}
@Component({
    selector: 'app-product-size-picker',
    template: `
        <app-rightpane
            (animationStateChanged)="animationStateChanged.emit($event)"
            [startExit]="startExit$"
            *noSSR>
            <div class="rightpane-container size-picker" appScrollbarHide>
                <header class="rightpane-container__header">
                    <h2 class="rightpane-container__heading">
                        {{ (translations$ | async)?.ProductDetails?.SideBarSizePicker ?.SelectSizeLabel }}
                    </h2>
                </header>

                <div class="product-size-picker__wrapper">

                    <!-- Product sizes list -->
                    <ul class="product-size-picker__list">
                        <li class="product-size-picker__item"
                            *ngFor="let size of this.data.sizes | xLargeReformat"
                            [ngSwitch]="!size.StockCount || size.StockCount <= 0"
                            [ngClass]="{
                                selected: size.Id === currentSize?.Id
                            }">

                            <!-- Out of stock -->
                            <button type="button" *ngSwitchCase="true"
                                class="product-size-picker__list-button product-size-out-of-stock"
                                (click)="watchSize(size)">
                                <div class="product-size-out-of-stock__text">{{size.SizeLabel}}</div>
                                <div class="product-size-picker__subscribe-product">
                                    <div class="product-size-picker__subscribe-product__icon" [innerHtml]="mail | safeHtml"></div>
                                    {{ (translations$ | async)?.ProductDetails?.SideBarSideBarActionSection?.KeepAnEyeOnTheItemButtonText }}
                                </div>
                            </button>

                            <!-- Has stock -->
                            <button type="button"
                                class="product-size-picker__list-button"
                                (click)="!!size.StockCount && select(size)"
                                *ngSwitchCase="false">
                                <div>{{ size.SizeLabel }}</div>

                                <!-- Has few in stock -->
                                <div class="product-size-picker__low-stock-message" *ngIf="size.StockCount <= lowStockCount">
                                    {{ (translations$ | async)?.ProductDetails?.SideBarSizePicker?.FewInStockLabel }}
                                </div>
                            </button>
                        </li>
                    </ul>
                    <div class="rightpane-container__footer" *ngIf="shouldShowSizeGuide">
                        <button type="button"
                            class="product-size-picker__size-guide"
                            (click)="showSizeGuideInfo()">
                            {{ (translations$ | async)?.ProductDetails?.SideBarSizePicker?.ShowSizeGuideLinkText }}
                        </button>
                    </div>
                </div>
            </div>
        </app-rightpane>
    `,
})
export class ProductSizePickerComponent implements OnInit {
    // Stuff for animations / ImpactOverlayRef
    animationStateChanged = new EventEmitter<AnimationEvent>();
    startExit$ = new Subject();

    mail = mail;

    translations$: Observable<any> = this.translationService.translations$;
    shouldShowSizeGuide: boolean;
    isStockCountHigh = isStockCountHigh;
    currentSize: ProductSizeVariant;

    lowStockCount = lowStockCount;

    constructor(
        private productService: ProductService,
        private paneService: PaneService,
        public overlayRef: ImpactOverlayRef,
        public translationService: TranslationService,
        private infoService: InfoService,
        private spinnerService: SpinnerService,
        @Inject(IMPACT_OVERLAY_DATA) public data: ProductSizePickerData
    ) {}

    ngOnInit() {
        this.shouldShowSizeGuide = Boolean(this.data.sizeGuideUrl);
        this.currentSize = this.productService.selectedSizeVariant;
    }

    showSizeGuideInfo() {
        this.productService.openActiveProductSizeguide();
    }

    // When user selects product
    select(selected: ProductSizeVariant) {
        this.currentSize = selected;
        this.productService.setProductSizeVariant(selected);

        // Add product to basket
        if (this.data.addToBasketAfterSelection) {
            this.spinnerService.show('addToBasketSpinner');
            this.productService.addProductToBasket();
        }

        // Add produc tto wishlist
        if (this.data.addToFavoritesAfterSelection) {
            this.productService.addProductToWishlist();
        }

        this.paneService.closeAll();
    }

    // Watch product size
    watchSize(productSize: ProductSizeVariant) {
        this.productService.openWatchProduct(productSize);
    }

    isSelected(size: ProductSizeVariant): boolean {
        return !!size;
    }

    // Allow sitebar to animate on closing
    startExitAnimation() {
        this.startExit$.next();
        this.startExit$.complete();
    }
}
