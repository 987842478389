import { finalize } from 'rxjs/operators';
import { TranslationService } from '@core/translation.service';
import { AuthenticationService } from '@core/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/user.service';
import { goArrow } from '@shared/svg';
import { SpinnerService } from '@shared/spinner/spinner.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private router: Router,
        private spinnerService: SpinnerService,
        private translationService: TranslationService,
    ) {}

    translations: any;
    submitted = false;
    icons = {
        goArrow,
    };

    ngOnInit() {
        this.translations = this.translationService.translations$;
    }

    onLogout() {
        if (!this.submitted) {
            this.spinnerService.show('logoutSpinner');
            this.submitted = true;

            this.authenticationService
                .logout()
                .pipe(
                    finalize(() => {
                        this.spinnerService.hide('logoutSpinner');
                        this.submitted = false;
                    })
                )
                .subscribe(data => {
                    this.router.navigate(
                        [data.RedirectUrl, { outlets: { dialog: null } }],
                        { queryParamsHandling: 'preserve' }
                    );
                });
        }
    }
}
