import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IFavoriteBrands } from '@core/personalization-bar.service';

@Pipe({
    name: 'brandUrlFormat',
})
export class BrandUrlFormatPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: any, args?: IFavoriteBrands[]): SafeHtml {
        if (!args) {
            return '';
        }
        args.forEach((brand, index) => {
            value = value.replace(
                `{Brand${index + 1}}`,
                `<a href="${brand.Url}">${brand.Name}</a>`
            );
        });
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
