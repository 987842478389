<div appTracking *ngIf="appReady" [style.opacity]="showApp ? 1 : 0">
    <div class="page">
        <app-header></app-header>
        <main class="page-content" id="mainRouter"
            [@routerTransition]="getState(o)"
            (@routerTransition.start)="animationStart($event)"
            (@routerTransition.done)="animationDone($event)"
            aria-live="assertive">
            <router-outlet #o="outlet"></router-outlet>
        </main>
    </div>
    <router-outlet name="dialog"></router-outlet>
    <router-outlet name="info"></router-outlet>
    <app-scroll-to-top [showOnSite]="['kaufmannDesktopOnly', 'axelDesktopOnly', 'quintDesktopOnly']"></app-scroll-to-top>
</div>
