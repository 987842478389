import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from '@features/authentication/login/login.component';
import { ResetPasswordComponent } from '@features/authentication/reset-password/reset-password.component';
import { RightpaneModule } from '@features/rightpane/rightpane.module';
import { LoginSpotComponent } from './login-spot/login-spot.component';

@NgModule({
    imports: [CommonModule, RightpaneModule, SharedModule, RouterModule],
    declarations: [LoginComponent, ResetPasswordComponent, LogoutComponent, LoginSpotComponent],
    exports: [LogoutComponent, LoginComponent, LoginSpotComponent],
    entryComponents: [LoginComponent, ResetPasswordComponent],
})
export class AuthenticationModule {}
