import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {BasketService} from '@core/basket.service';
import {
    ImpactCoreBasketsBasketLineServiceModel,
    ImpactCoreModelsProductsDtoColorGroupDto
} from '@shared/swagger/swagger.interface';
import {arrowRight, basket} from '@shared/svg';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

export type ArrowDirection = 'top' | 'left' | 'bottom' | 'right';

@Component({
    selector: 'app-add-to-basket-standalone',
    template: `
        <button
            class="button button_primary button_icon button_icon-center"
            (click)="onAddToBasket()"
            [ngClass]="{
                'basket_active': !showSelectSizeArrow,
                'no-size-selected': showSelectSizeArrow,
                'arrow-left': arrowDirection === 'left'
            }"
        >
            <app-svg [src]="basketIcon" [size]="'normal'"></app-svg>
            <app-svg [src]="arrowRightIcon" [size]="'normal'"></app-svg>
            <div class="amount" [ngClass]="{'amount_show': !!skuAmount, 'amount_count': amountAnimOut}">
                <div class="amount_number">{{skuAmount}}</div>
            </div>
        </button>
    `
})
export class AddToBasketStandaloneComponent implements OnChanges, OnDestroy {

    constructor(
        private basketService: BasketService,
    ) {
        this.basketService.basket$
            .pipe(takeUntil(this.destroy))
            .subscribe(({Lines}) => {
                this.basketLines = Lines;
                this.updateSkuAmount();
            });
    }

    @Input() selectedSku: string;
    @Input() product: ImpactCoreModelsProductsDtoColorGroupDto;
    @Input() amount?: number;
    @Input() arrowDirection?: ArrowDirection = 'right';

    basketIcon = basket;
    arrowRightIcon = arrowRight;
    showSelectSizeArrow = false;
    skuAmount: number;
    basketLines: ImpactCoreBasketsBasketLineServiceModel[];
    destroy = new Subject();
    amountAnimOut = false;
    amountAnimIn = false;

    onAddToBasket() {
        if (!this.selectedSku) {
            this.showSelectSizeArrow = true;
            this.skuAmount = 0;
        } else {
            this.amountAnimOut = true;
            this.basketService.add(this.selectedSku, this.product, this.amount)
                .subscribe(() => {
                    setTimeout(() => {
                        this.amountAnimOut = false;
                    }, 400);
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('selectedSku') && !!changes.selectedSku.currentValue) {
            this.showSelectSizeArrow = false;
            this.updateSkuAmount();
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    private updateSkuAmount() {
        this.skuAmount = this.basketLines.filter(line => line.SkuId === this.selectedSku)?.shift()?.Amount ?? 0;
        if (this.skuAmount) {
            this.amountAnimIn = true;
        }
    }
}
