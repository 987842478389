import { Component, Input } from '@angular/core';
import { MenuItem } from '../mobile-menu/menu.interface';
import { TrackingService } from '@core/tracking/tracking.service';

/**
 * Renders a mega menu
 */
@Component({
    selector: 'mega-menu',
    templateUrl: './mega-menu.component.html',
})
export class MegaMenuComponent {
    /**
     * Menu items to show within the mega menu
     */
    @Input() item: MenuItem;
    @Input() listItem: any;

    constructor(private tracking: TrackingService) {}

    handleClick(link) {
        this.listItem.classList.add('hideMegaMenu');

        setTimeout(() => {
            this.listItem.classList.remove('hideMegaMenu');
        }, 900);

        this.tracking.sendNavigationEvent('Main menu', link);
    }


    getFilename(path) {
        const filePathShards = path.split('/');

        return filePathShards[filePathShards.length - 1];
    }

    getColumnSpanValue(index: number, numOfSpots: number): string {
        if (numOfSpots > 1 && index === 0 && this.item.MegaMenuSpots[index].Size === 'Normal') {
            return '3 / span 2';
        }
        if (numOfSpots > 1 && index === 1 && this.item.MegaMenuSpots[index].Size === 'Normal') {
            return '5 / span 2';
        }
        if (numOfSpots === 1 && this.item.MegaMenuSpots[index].Size === 'Normal') {
            return '5 / span 2';
        }
        if (numOfSpots === 1 && this.item.MegaMenuSpots[index].Size === 'Double') {
            return '3 / span 4';
        }

        return '';
    }
}
