import { Component, Input } from '@angular/core';

/**
 * Component in charge of rendering our frontpage content
 */
@Component({
    selector: 'home-page',
    template: `
        <spots [spots]="data"></spots>
        <app-footer *ngIf="showFooter"></app-footer>
    `,
})
export class HomePageComponent {
    /**
     * Template reference used for rendering the correct component
     * @type {string}
     */
    static ref = 'HomePage';

    @Input() public showFooter = true;

    /**
     * Page content
     */
    @Input() public data;
}
