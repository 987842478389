import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, ObservableInput, throwError, timer } from 'rxjs';
import { catchError, finalize, first, map } from 'rxjs/operators';
import * as moment from 'moment';
// import {Moment} from 'moment';
import * as natsort from 'natsort';
import { SiteContextService } from '@core/site-context.service';
import { ImpactWebsiteCodeWebApiControllersListPersonalizationBoxArgs } from '@shared/swagger/swagger.interface';
import { UserService } from '@core/user.service';
import { OrderViewModel } from '@shared/swagger/easypos.interface';
import { environment } from '../../environments/environment';
import { removeIndexFromArray } from '@shared/utility';
import { LoggingService } from '@core/logging.service';

@Injectable({
    providedIn: 'root',
})
export class PersonalizationBarService {
    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService,
        private userService: UserService,
        private logging: LoggingService
    ) {}

    set localStorageCloseTimestamp(value) {
        const bars = JSON.parse(
            localStorage.getItem(this.PERSONALIZATION_STORAGE_KEY)
        );
        if (!bars) {
            localStorage.setItem(
                this.PERSONALIZATION_STORAGE_KEY,
                JSON.stringify([value])
            );
        } else {
            localStorage.setItem(
                this.PERSONALIZATION_STORAGE_KEY,
                JSON.stringify(this.updateObjectInArray(value, bars, value.id))
            );
        }
    }

    get localStorageCloseTimestamp() {
        return JSON.parse(
            localStorage.getItem(this.PERSONALIZATION_STORAGE_KEY)
        );
    }
    PERSONALIZATION_STORAGE_KEY =
        'personalization-bar_' +
        this.siteContextService.getContext().businessDimension +
        '_' +
        this.siteContextService.getContext().language;
    private emptyBar: IPersonalizationBarItem = new PersonalizationBarItem();

    barTypes = personalizationBarType;
    personalizationBarIds =
        environment.personalization[
            this.siteContextService.getContext().businessDimension
        ];

    personalizationBar$: BehaviorSubject<
        IPersonalizationBarItem
    > = new BehaviorSubject(this.emptyBar);

    freeFreightMessage$: BehaviorSubject<IPersonalizationBarItem> = new BehaviorSubject<IPersonalizationBarItem>(this.emptyBar);

    private personalizationBars: IPersonalizationBarItem[] = [];

    private static profilePageUrl(url: string): string {
        return url.replace('/', '');
    }

    getHighestPriorityBar() {
        const sorter = natsort.default({ desc: false });

        const visibleBars = this.personalizationBars.filter(
            bar => bar.isVisible
        );

        visibleBars.sort((a, b) => {
            return sorter(a.priority, b.priority);
        });

        this.personalizationBar$.next(visibleBars[0]);
    }

    getPersonalizationBars(barType: personalizationBarType): void {
        const userName = this.userService.currentUser.getValue().UserName;
        const payload = new PersonalizationBarRequestModel(
            this.siteContextService.getContext().language,
            barType,
            userName
        );

        if (payload.BasketId || userName) {
            this.http
                .post<IPersonalizationBarItem[]>(
                    `${this.siteContextService.getRootUrl()}/webapi/PersonalizationBox/List`,
                    payload
                )
                .pipe(
                    map(bar => {
                        const newBars = [];

                        bar.forEach(b => {
                            newBars.push(this.mapRawBarData(b));
                        });
                        return newBars;
                    }),
                    catchError(
                        (err): ObservableInput<any> => {
                            this.logging.exception(
                                `Error happened in /webapi/PersonalizationBox/List. Error was: ${err}`,
                                payload
                            );
                            return throwError(err);
                        }
                    )
                )
                .subscribe(bars => {
                    const updatedBars: IPersonalizationBarItem[] = [];
                    bars.forEach(bar => {
                        updatedBars.push(this.updateBar(bar));
                    });

                    const freeFreightBar = updatedBars.filter(val => val.barType === personalizationBarType.basket);
                    if (freeFreightBar.length) {
                        this.freeFreightMessage$.next(freeFreightBar[0]);
                    }

                    this.updateBars(updatedBars);
                    this.getHighestPriorityBar();
                });
        }
    }

    private updateBar(
        newBar: IPersonalizationBarItem
    ): IPersonalizationBarItem {
        const barToUpdate = this.personalizationBars.find(
            exBar => exBar.id === newBar.id
        );
        if (
            barToUpdate &&
            barToUpdate.id !== this.personalizationBarIds.freeFreight
        ) {
            newBar.isVisible = barToUpdate.isVisible;
        }
        return Object.assign(new PersonalizationBarItem(), barToUpdate, newBar);
    }

    private updateBars(updatedBars: IPersonalizationBarItem[]): void {
        if (this.personalizationBars.length) {
            updatedBars.forEach(bar => {
                const barIndex = this.personalizationBars.findIndex(
                    exBar => exBar.id === bar.id
                );
                if (barIndex >= 0) {
                    this.personalizationBars[barIndex] = Object.assign(
                        new PersonalizationBarItem(),
                        this.personalizationBars[barIndex],
                        bar
                    );
                } else {
                    this.personalizationBars.push(bar);
                }
            });
        } else {
            this.personalizationBars = [...updatedBars];
        }
    }

    private mapRawBarData(rawBarData: any): PersonalizationBarItem {
        // Free freight bar
        if (rawBarData.ItemId === this.personalizationBarIds.freeFreight) {
            return new PersonalizationBarItem(
                rawBarData.IsVisible,
                personalizationBarType.basket,
                {
                    text: rawBarData.Text,
                    textGoToCheckout: rawBarData.TextGoToCheckout,
                    freeShippingAvailable: rawBarData.FreeShippingAvailable,
                    freeDeliveryDifferenceAmount:
                        rawBarData.FreeDeliveryDifference,
                    checkoutUrl: this.siteContextService.getContext()
                        .checkoutSettings.GoToBasketLink,
                },
                {
                    textColor: rawBarData.TextColor
                        ? '#' + rawBarData.TextColor
                        : 'transparent',
                    backgroundColor: rawBarData.BackgroundColor
                        ? '#' + rawBarData.BackgroundColor
                        : 'transparent',
                    borderColor: rawBarData.BorderColor
                        ? '#' + rawBarData.BorderColor
                        : 'transparent',
                },
                rawBarData.Priority,
                rawBarData.ItemId,
                rawBarData.Currency,
                this
            );
        }

        // Favorite brands bar
        if (rawBarData.ItemId === this.personalizationBarIds.favoriteBrands) {
            let firstName = '';
            if (rawBarData.UserFirstName) {
                firstName = rawBarData.UserFirstName;
            } else {
                firstName = this.userService.currentUser.getValue().FirstName
                    ? this.userService.currentUser.getValue().FirstName
                    : '';
            }
            return new PersonalizationBarItem(
                true,
                personalizationBarType.profile,
                {
                    text: rawBarData.Text,
                    brands: rawBarData.Brands,
                    userFirstName: firstName,
                    userLastName: rawBarData.UserLastName,
                    userIsAuthenticated: rawBarData.UserIsAuthenticated,
                },
                {
                    textColor: rawBarData.TextColor
                        ? '#' + rawBarData.TextColor
                        : 'transparent',
                    backgroundColor: rawBarData.BackgroundColor
                        ? '#' + rawBarData.BackgroundColor
                        : 'transparent',
                    borderColor: rawBarData.BorderColor
                        ? '#' + rawBarData.BorderColor
                        : 'transparent',
                },
                rawBarData.Priority,
                rawBarData.ItemId,
                null,
                this
            );
        }

        // Offline orders
        if (rawBarData.ItemId === this.personalizationBarIds.offlineOrders) {
            return new PersonalizationBarItem(
                true,
                personalizationBarType.profile,
                {
                    text: rawBarData.Text,
                    orders: rawBarData.Orders,
                    storeName: rawBarData.Orders.length
                        ? rawBarData.Orders[0].StoreName
                        : '',
                    buttonText: rawBarData.ButtonText,
                    buttonLink: PersonalizationBarService.profilePageUrl(
                        rawBarData.ButtonLink
                    ),
                },
                {
                    textColor: rawBarData.TextColor
                        ? '#' + rawBarData.TextColor
                        : 'transparent',
                    backgroundColor: rawBarData.BackgroundColor
                        ? '#' + rawBarData.BackgroundColor
                        : 'transparent',
                    borderColor: rawBarData.BorderColor
                        ? '#' + rawBarData.BorderColor
                        : 'transparent',
                },
                rawBarData.Priority,
                rawBarData.ItemId,
                null,
                this
            );
        }
    }

    removeExpiredBarFromlocalStorage(id: string) {
        const bars: any[] = JSON.parse(
            localStorage.getItem(this.PERSONALIZATION_STORAGE_KEY)
        );
        if (bars) {
            const barIndex = bars.findIndex(bar => bar.id === id);
            if (barIndex >= 0) {
                if (bars.length === 1) {
                    localStorage.removeItem(this.PERSONALIZATION_STORAGE_KEY);
                } else {
                    localStorage.setItem(
                        this.PERSONALIZATION_STORAGE_KEY,
                        JSON.stringify(removeIndexFromArray(bars, barIndex))
                    );
                }
            }
        }
    }

    private updateObjectInArray(
        source: any,
        dest: any[],
        identifier: any
    ): any[] {
        const index = dest.findIndex(val => val.id === identifier);
        if (index >= 0) {
            dest[index] = source;
            return dest;
        } else {
            return [...dest, source];
        }
    }
}

class PersonalizationBarItem implements IPersonalizationBarItem {
    barContent:
        | IPersonalizationBarFreeFreight
        | IPersonalizationBarFavoriteBrands
        | IPersonalizationBarOfflineOrders;
    readonly barStyling: IPersonalizationBarStyling;
    readonly barType: personalizationBarType;
    disabledTimestamp: moment.Moment;
    isAnimated: boolean;
    isVisible: boolean;
    readonly priority: number;
    readonly id: string;
    readonly currency: string;
    PersonalizationService: any;

    constructor(
        isVisible: boolean = false,
        barType?: personalizationBarType,
        barData?:
            | IPersonalizationBarFreeFreight
            | IPersonalizationBarFavoriteBrands
            | IPersonalizationBarOfflineOrders,
        barStyling?: IPersonalizationBarStyling,
        priority?: number,
        id?: string,
        currency?: string,
        perzaService?: any
    ) {
        this.isVisible = isVisible;
        this.barType = barType;
        this.barContent = barData;
        this.barStyling = barStyling;
        this.priority = priority;
        this.id = id;
        this.currency = currency;
        this.PersonalizationService = perzaService;
    }

    close(): void {
        const time = timer(300);
        const closeTimestamp = moment().add(12, 'h');
        this.isAnimated = false;
        time.pipe(
            first(),
            finalize(() => {
                this.PersonalizationService.personalizationBar$.next(this);
            })
        ).subscribe(() => {
            this.disabledTimestamp = closeTimestamp;
            this.PersonalizationService.localStorageCloseTimestamp = {
                id: this.id,
                timestamp: closeTimestamp,
            };
            this.isVisible = false;
        });
    }
}

class PersonalizationBarRequestModel
    implements ImpactWebsiteCodeWebApiControllersListPersonalizationBoxArgs {
    BasketId: string;
    Event: personalizationBarType.basket | personalizationBarType.profile | 99;
    UserId?: string;
    private language: string;

    constructor(lang: string, event: personalizationBarType, userId?: string) {
        this.language = lang;
        this.BasketId = this.getBasketId();
        this.Event = event;
        this.UserId = userId ? userId : null;
    }

    private getBasketId(): string {
        const localStorageName =
            this.language === 'da' ? 'basketId' : 'basketId_' + this.language;
        return localStorage.getItem(localStorageName);
    }
}

interface IPersonalizationBarStyling {
    borderColor: string;
    backgroundColor: string;
    textColor: string;
}

export interface IPersonalizationBarItem {
    isVisible: boolean;
    isAnimated: boolean;
    readonly barType: personalizationBarType;
    barContent?:
        | IPersonalizationBarFreeFreight
        | IPersonalizationBarFavoriteBrands
        | IPersonalizationBarOfflineOrders;
    readonly barStyling: IPersonalizationBarStyling;
    readonly priority: number;
    readonly id: string;
    disabledTimestamp?: moment.Moment;
    readonly currency?: string;
    PersonalizationService?: any;
    close(): void;
}

export interface IPersonalizationBarFreeFreight {
    text: string;
    textGoToCheckout: string;
    freeShippingAvailable: boolean;
    freeDeliveryDifferenceAmount: number;
    checkoutUrl: string;
}

export interface IFavoriteBrands {
    Id: string;
    Name: string;
    Url: string;
    ImageUrl: string;
}

interface IPersonalizationBarFavoriteBrands {
    text: string;
    brands: IFavoriteBrands[];
    userFirstName: string;
    userLastName: string;
    userIsAuthenticated: boolean;
}
interface IPersonalizationBarOfflineOrders {
    text: string;
    orders: OrderViewModel[];
    storeName: string;
    buttonText: string;
    buttonLink: string;
}
export enum personalizationBarType {
    basket = 0,
    profile = 1,
}
