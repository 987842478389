import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-customer-sublayout',
    templateUrl: './customer-sublayout.component.html',
})
export class CustomerSublayoutComponent {
    constructor() {}

    static ref = 'CustomerSublayout';

    @Input()
    public data;
}
