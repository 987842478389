import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { MegaMenuComponent } from './mega-menu.component';
import { RouterModule } from '@angular/router';
import { NgxLazyloadModule } from '@features/ngx-lazyload/ngx-lazyload.module';
import { MegaMenuSpotComponent } from './mega-menu-spot.component';
import { MegaMenuClassesDirective } from './mega-menu-classes.directive';

@NgModule({
    declarations: [MegaMenuComponent, MegaMenuSpotComponent, MegaMenuClassesDirective],
    imports: [SharedModule, RouterModule, NgxLazyloadModule],
    exports: [MegaMenuComponent],
})
export class MegaMenuModule {}
