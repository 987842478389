import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { MegaMenuAxelComponent } from './mega-menu-axel.component';
import { RouterModule } from '@angular/router';
import { NgxLazyloadModule } from '@features/ngx-lazyload/ngx-lazyload.module';
import { MegaMenuAxelSpotComponent } from './mega-menu-axel-spot.component';
import { MegaMenuAxelClassesDirective } from './mega-menu-axel-classes.directive';

@NgModule({
    declarations: [MegaMenuAxelComponent, MegaMenuAxelSpotComponent, MegaMenuAxelClassesDirective],
    imports: [SharedModule, RouterModule, NgxLazyloadModule],
    exports: [MegaMenuAxelComponent],
})
export class MegaMenuAxelModule {}
