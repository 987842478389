import { Injectable } from '@angular/core';
import {ImpactOverlayConfig, ImpactOverlayRef, OverlayService} from '@impactdk/ngx-overlay';
import {BehaviorSubject, Observable} from 'rxjs';
import { take } from 'rxjs/operators';
import {findLast} from '@angular/compiler/src/directive_resolver';

@Injectable()
export class PaneService {
    constructor(
        private overlayService: OverlayService) {
        this.refs = [];
    }

    private activeKey: BehaviorSubject<string> = new BehaviorSubject(null);

    /**
     * Get the active pane key
     *
     * @type {Observable<string>}
     */
    activeKey$: Observable<string> = this.activeKey.asObservable();

    /**
     * Get current pane overlay refs
     *
     * @type {ImpactOverlayRef[]}
     */
    refs: ImpactOverlayRef[];

    /**
     * Keep track of which panels are open
     */
    paneRefs: BehaviorSubject<ImpactOverlayRef[]> = new BehaviorSubject<ImpactOverlayRef[]>([]);

    /**
     * Open component inside left/right pane
     *
     * @param {*} component
     * @param {('left' | 'right' | 'top')} placement
     * @param {string} key
     * @param {string} paneClass
     * @param verticalPlacement
     * @param backdropClass
     */

    open(component: any, placement: 'left' | 'right' | 'center', key: string, paneClass?: string, verticalPlacement?: 'top' | 'bottom' | 'center' | null, hasBackdrop: boolean = false, backdropClass?: string): void {

        if (this.refs.length > 0) {
            this.refs.forEach(child => child.close());
            this.refs = [];
        }

        if (this.paneRefs.value.length > 0) {
            this.paneRefs.value.forEach(child => {
                if (child.componentInstance) {
                    child.close();
                }
            });
            this.paneRefs.next([]);
        }

        if (this.activeKey.value === key) {
            this.activeKey.next(null);
            return;
        }

        this.activeKey.next(key);

        let overlayConfig: ImpactOverlayConfig<any> = {
            positionHorizontal: {
                placement,
            },
            fullHeight: true,
            blockScroll: true,
            panelClass: paneClass ? `overlay-pane-${paneClass}` : 'overlay-pane',
            hasBackdrop,
        };

        if (verticalPlacement) {
            overlayConfig = {
                ...overlayConfig,
                positionVertical: {
                    placement: verticalPlacement
                },
                fullHeight: false,
                blockScroll: false,
                hasBackdrop,

            };
        }
        if (backdropClass) {
            overlayConfig = {...overlayConfig, backdropClass};
        }

        const pane = this.overlayService.open(component, overlayConfig);

        pane
            .beforeClose()
            .pipe(take(1))
            .subscribe(() => {
                if (this.activeKey.value === key) {
                    this.activeKey.next(null);
                }

                this.refs.splice(this.refs.indexOf(pane), 1);
            });

        this.paneRefs.next([].concat(this.paneRefs.value, [pane]));
        this.refs.push(pane);
    }

    /**
     * Open child component inside left/right pane
     *
     * @param {*} component
     * @param {('left' | 'right')} placement
     */
    openChild(component: any, placement: 'left' | 'right', data?: any) {
        const pane = this.overlayService.open(component, {
            data,
            positionHorizontal: {
                placement,
            },
            fullHeight: true,
            blockScroll: true,
            panelClass: 'overlay-pane',
        });

        pane
            .beforeClose()
            .pipe(take(1))
            .subscribe(() => {
                this.refs.splice(this.refs.indexOf(pane), 1);
            });

        this.refs.push(pane);
    }

    /**
     * Close all panes what so ever
     */
    closeAll(): void {
        if (this.refs.length > 0) {
            this.refs.forEach(child => child.close());
            this.refs = [];
            this.activeKey.next(null);
        }
    }

    /**
     * Set active key manually
     *
     * @param {string} key
     */
    setActiveKey(key: string) {
        this.activeKey.next(key);
    }


}
