import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { CheckoutReceiptComponent } from '@features/checkout-receipt/checkout-receipt.component';
import { NgxLazyloadModule } from '@features/ngx-lazyload/ngx-lazyload.module';
import { ProductFilterModule } from '@features/product-filter';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxLazyloadModule,
        ProductFilterModule,
    ],
    declarations: [CheckoutReceiptComponent],
    providers: [DatePipe],
    exports: [CheckoutReceiptComponent],
})
export class CheckoutReceiptModule {}
