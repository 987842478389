<article
    class="spot-reference spot-video"
    (inView)="onInView($event)"
    [ngClass]="{'is-hidden-mobile': data.HideOnMobile, 'is-hidden-tablet': data.HideOnDesktopTablet, 'spot-reference_no-text': !noText }"
    *ngIf="!hideFromSearchEngines || inView"
>
    <div class="spot-reference__image-wrapper">
        <div *ngIf="data.MP4">
            <div
                plyr
                style="width:auto;max-width: 100%"
                plyrTitle="MP4"
                class="simple-controls"
                [ngClass]="{'no-controls': data.HidePlayerControl, 'muted': data.Mute, 'cursor': moodVideo && data.Link }"
                [plyrOptions]="videoConfig"
                [plyrPoster]="data.PosterImage"
                [plyrSources]="videoSourcesMP4"
                (plyrPlay)="played($event)"
                (plyrEnded)="playerEnded($event)"
                (plyrSeeking)="seeking($event)"
                (plyrTimeUpdate)="timeUpdate($event)"
                (click)="videoLink()"
            ></div>
        </div>

        <div *ngIf="data.Youtube">
            <div class="poster-image">
                <div
                    *ngIf="showPosterImage"
                    class="poster-image__image"
                    [style.backgroundImage]="'url(' + data?.PosterImage + ')' | safeStyle"
                ></div>
                <div
                    plyr
                    style="width:auto;max-width: 100%"
                    class="simple-controls"
                    plyrTitle="Youtube"
                    [ngClass]="{'no-controls': data.HidePlayerControl || showPosterImage, 'muted': data.Mute, 'cursor': moodVideo && data.Link }"
                    [plyrOptions]="videoConfig"
                    [plyrSources]="videoSourcesYoutube"
                    (plyrPlay)="played($event)"
                    (plyrEnded)="playerEnded($event)"
                    (plyrSeeking)="seeking($event)"
                    (plyrTimeUpdate)="timeUpdate($event)"
                    (click)="videoLink()"
                ></div>
            </div>
        </div>

        <div *ngIf="data.Vimeo">
            <div class="poster-image">
                <div
                    *ngIf="showPosterImage"
                    class="poster-image__image"
                    [style.backgroundImage]="'url(' + data.PosterImage +')' | safeStyle"
                ></div>
                <div
                    plyr
                    style="width:auto;max-width: 100%"
                    class="simple-controls"
                    plyrTitle="Vimeo"
                    [ngClass]="{'no-controls': data.HidePlayerControl || showPosterImage, 'muted': data.Mute, 'cursor': moodVideo && data.Link }"
                    [plyrOptions]="videoConfig"
                    [plyrSources]="videoSourcesVimeo"
                    (plyrPlay)="played($event)"
                    (plyrEnded)="playerEnded($event)"
                    (plyrSeeking)="seeking($event)"
                    (plyrTimeUpdate)="timeUpdate($event)"
                    (click)="videoLink()"
                ></div>
            </div>
        </div>
    </div>
    <a *ngIf="data.Link" [href]="data.Link" [target]="data.LinkTarget ? data.LinkTarget : '_self'" [rel]="data.LinkTarget ? 'noopener' : ''" class="spot-video__link" (click)="trackClick()">
        <div class="spot-reference__body" *ngIf="data.Headling || data.SubHeadingText">
            <app-svg [src]="rightArrow" [size]="'large'"></app-svg>
            <app-spot-heading-seo [headingClass]="'spot-reference__heading'" [hTag]="data.HeadingSeoImportance"
                                [headingText]="data.Heading" *ngIf="data.Heading"></app-spot-heading-seo>
            <div class="spot-reference__action" *ngIf="data.SubHeadingText">
                <app-svg [src]="rightArrow" [size]="'normal'"></app-svg>
                <span class="spot-reference__text">{{data.SubHeadingText}}</span>
            </div>
        </div>
    </a>
    <ng-container *ngIf="!data.Link">
        <div class="spot-reference__body" *ngIf="data.Headling || data.SubHeadingText">
            <app-spot-heading-seo [headingClass]="'spot-reference__heading'" [hTag]="data.HeadingSeoImportance" [headingText]="data.Heading" *ngIf="data.Heading"></app-spot-heading-seo>
            <div class="spot-reference__action" *ngIf="data.SubHeadingText">
                <span class="spot-reference__text">{{data.SubHeadingText}}</span>
            </div>
        </div>
    </ng-container>
</article>
