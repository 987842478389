import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appShopByImageTitleHoverPos]',
})
export class ShopByImageTitleHoverPosDirective implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit(): void {
        const elm = this.el.nativeElement as HTMLElement;
        elm.addEventListener(
            'mouseenter',
            (e: MouseEvent) => {
                const titleElms = (this.el
                    .nativeElement as HTMLElement).querySelectorAll(
                    '.shop-by-button__hover-title'
                );

                for (let i = 0, itemLen = titleElms.length; i < itemLen; i++) {
                    titleElms[i].classList.add(
                        this.positionHoverTitle(titleElms[i])
                    );
                    titleElms[i].parentElement.classList.add(`delay${i + 1}`);
                }
            },
            { passive: true, once: true }
        );
    }
    positionHoverTitle(element: Element): string {
        const imageHeight = element.parentElement.parentElement.clientHeight;
        const imageWidth = element.parentElement.parentElement.clientWidth;
        const topLeft =
            element.parentElement.offsetLeft < imageWidth / 2 &&
            element.parentElement.offsetTop < imageHeight / 2;
        const bottomLeft =
            element.parentElement.offsetLeft < imageWidth / 2 &&
            element.parentElement.offsetTop > imageHeight / 2;
        const topRight =
            element.parentElement.offsetLeft > imageWidth / 2 &&
            element.parentElement.offsetTop < imageHeight / 2;
        const bottomRight =
            element.parentElement.offsetLeft > imageWidth / 2 &&
            element.parentElement.offsetTop > imageHeight / 2;
        if (topLeft) {
            return 'top-left';
        }
        if (bottomLeft) {
            return 'bottom-left';
        }
        if (topRight) {
            return 'top-right';
        }
        if (bottomRight) {
            return 'bottom-right';
        }
    }
}
