<app-rightpane
    (animationStateChanged)="doSomething($event)"
    [startExit]="startExitTrigger"
    *noSSR>

    <div class="rightpane-container">
        <header class="rightpane-container__header">
            <h2 class="rightpane-container__heading">{{(translationService.translations$ | async)?.Axel?.LanguagePane?.LanguagePickerHeading}}</h2>
        </header>

        <div class="rightpane-container__content" appScrollbarHide>
            <div class="language-picker-pane">
                <div class="language-picker-pane__choose-country-title">{{ (translationService.translations$ | async)?.LanguagePicker?.Content?.ChooseLanguage }}</div>
                <ul class="language-picker-pane__list">
                    <li
                        class="language-picker-pane__item"
                        *ngFor="let country of siteContextService.getContext().countrySelectorList">
                        <a
                            [href]="!country.IsCurrent ? country.LinkToHomePage : '#'"
                            class="language-picker-pane__link" [class.active]="country.IsCurrent">
                            {{country.CountryName}} ({{country.Currency}})
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</app-rightpane>
