import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
    selector: 'spinner',
    template: `
        <div class="sp-container"
            [ngClass]="{
                'sp-container_white': inverted,
                'sp-container_black': !inverted,
                'sp-container_background': background
            }"
            *ngIf="show">
            <div class="sp sp-circle" [style.width]="size + 'px'" [style.height]="size + 'px'"></div>
        </div>
    `,
})
export class SpinnerComponent implements OnInit, OnDestroy {
    constructor(private spinnerService: SpinnerService) {}

    @Input() name: string;
    @Input() group: string;
    @Input() size: string;
    @Input() inverted: boolean = false;
    @Input() background: boolean = false;

    private isShowing = false;

    @Input()
    get show(): boolean {
        return this.isShowing;
    }

    @Output() showChange = new EventEmitter();

    set show(val: boolean) {
        this.isShowing = val;
        this.showChange.emit(this.isShowing);
    }

    ngOnInit(): void {
        if (!this.name)
            throw new Error("Spinner must have a 'name' attribute.");

        this.spinnerService._register(this);
    }

    ngOnDestroy(): void {
        this.spinnerService._unregister(this);
    }
}
