import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RightpaneModule } from '@features/rightpane/rightpane.module';
import { ShopByImageHoverClassDirective } from '@features/shop-by-image/shop-by-image-hover-class.directive';
import { SharedModule } from '@shared/shared.module';
import { ShopByImageTitleHoverPosDirective } from '@features/shop-by-image/shop-by-image-title-hover-pos.directive';
import { ShopByImageComponent } from '@features/shop-by-image/shop-by-image.component';

@NgModule({
    imports: [CommonModule, RightpaneModule, SharedModule],
    declarations: [
        ShopByImageComponent,
        ShopByImageTitleHoverPosDirective,
        ShopByImageHoverClassDirective,
    ],
    entryComponents: [ShopByImageComponent],
    exports: [
        ShopByImageComponent,
        ShopByImageTitleHoverPosDirective,
        ShopByImageHoverClassDirective,
    ],
})
export class ShopByImageModule {}
