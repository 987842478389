<div class="columns">
    <div class="column is-5-desktop">
        <div class="article-reference-list__sup-heading">{{data.SupHeading}}</div>
        <div class="spot-text__heading" [innerHTML]="data.Heading | safeHtml"></div>
    </div>
    <div class="column article-reference-list__link-container">
        <app-svg [src]="arrowRight" [size]="'normal'"></app-svg>
        <a [routerLink]="data.LinkToAllArticles">{{data.SeeAllArticlesLinkText}}</a>
    </div>
</div>

<div class="slider">
    <div class="slider__content">
        <!-- Slider main container -->
        <div id="slider" class="swiper-container" #slider [id]="id">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper" #sliderList>
                <!-- Slides -->
                <div class="swiper-slide" #sliderItem *ngFor="let article of (slides$ | async); let i = index;" [style.width]="itemWidth.width + '%'">
                    <section class="spot-reference column" *ngIf="article.Link">
                        <a [routerLink]="article.Link" target="{{article.LinkTarget}}" class="spot-reference__link"
                           (click)="raptorItemClick(article)">
                            <div class="spot-reference__image-wrapper">
                                <img [src]="article.Image + '?w=' + imageWidthMd"
                                     loading="lazy"
                                     [alt]="article.ImageAltText ? article.ImageAltText : imagePathName(article.Image)"
                                     [srcset]="article.Image + '?w=' + imageWidthSm + ' 480w,' + article.Image + '?w=' + imageWidthMd + ' 768w, ' + article.Image + '?w=' + imageWidthLg + ' 1024w, ' + article.Image + '?w=' + imageWidthXl + ' 1440w'"
                                     [width]="article.ImageWidth"
                                     [height]="article.ImageHeight"
                                />
                            </div>
                            <div class="spot-reference__body">
                                <div class="spot-reference__sup-heading">
                                    {{articleUniverseTitle}}
                                    <span class="spot-reference__sup-heading_light">{{!!article.RaptorContentTagNames ? article.RaptorContentTagNames[0] : ''}}</span>
                                </div>

                                <app-spot-heading-seo
                                    [headingClass]="'spot-reference__heading'"
                                    [hTag]="data.HeadingSeoImportance"
                                    [headingText]="article.Heading"
                                    *ngIf="article.Heading"
                                ></app-spot-heading-seo>
                                <div class="spot-reference__action">
                                    <app-svg [src]="'arrow-right-black'" [size]="'normal'"></app-svg>
                                    <span class="spot-reference__text">{{articleReferenceSpotLinkToArticleText}}</span>
                                </div>
                            </div>
                        </a>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="slider__footer">
    <a [routerLink]="data.LinkToAllArticles" class="button button_outline button_full button_text-center">{{data.MobileSeeAllArticlesLinkText}}</a>
</div>
