import {Component, Input, OnInit} from '@angular/core';
import {MegaMenuContentSpot} from '@features/mobile-menu/menu.interface';

@Component({
    selector: 'app-mega-menu-spot',
    template: `
        <section class="mega-menu-spot" [ngClass]="{'mega-menu-spot_double': data.Size === 'Double'}" [ngStyle]="{'background-image': 'url(' + data.BackgroundImageUrl + ')', 'color': '#' + data.TextColor}">
            <div class="mega-menu-spot__headline">{{data.Headline}}</div>
            <div class="mega-menu-spot__content" intercept-links [innerHTML]="data.Content | safeHtml"></div>
        </section>
    `,
})
export class MegaMenuAxelSpotComponent {

    @Input() data: MegaMenuContentSpot;

}
