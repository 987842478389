<div class="search-field" [ngClass]="{ 'search-field_detached': detachable }" aria-label="Søgefunktionalitet til at søge efter katagorier og farver">
    <div class="search-field__container">
        <button type="button" class="search-field__button" (click)="searchTrigger()" aria-label="Udfør søgning">
            <app-svg src="search2" size="normal"></app-svg>
        </button>
        <input type="text" class="search-field__input input input_text"
               placeholder="{{this.businessDimension !== 'axel' ? (translationService.translations$ | async)?.GeneralInput?.Content?.SearchInputPlaceholder : (translationService.translations$ | async)?.Axel?.Search?.SearchPlaceHolderText}}"
               aria-label="Skriv hvad du vil søge efter"
               (keydown)="searchKeyEvents($event)"
               (input)="searchKeyTrigger.next()"
               (focus)="searchToggle($event)"
               (blur)="searchToggle($event)"
               [(ngModel)]="searchString"
               #searchInput
        />
        <button class="button button_clear_search" (click)="reset()" aria-label="Nulstil indtastning"
            [showOnSite]="['axelDesktopOnly']" *ngIf="searchString && searchString.length > 0">
            <app-svg src="cross" size="small"></app-svg>
        </button>
    </div>

</div>
