import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    PLATFORM_ID,
} from '@angular/core';
import { TrackingService } from '@core/tracking/tracking.service';
import { isIe, sublayoutImageScaleDivider, getFilename } from '@shared/utility';
import { PaneService } from '@core/pane.service';
import { ShopByImageComponent } from '@features/shop-by-image/shop-by-image.component';
import {
    BreakpointObserver,
    Breakpoints,
    BreakpointState,
} from '@angular/cdk/layout';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { UserService } from '@core/user.service';
import { PersonalizeContentService } from '@core/personalize-content.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { arrowRight } from '@shared/svg';

@Component({
    selector: 'app-hero-spot',
    templateUrl: './hero-spot.component.html',
    host: {
        '[class]':
            '"column " + (data.HideOnMobile ? "is-hidden-mobile " : "") + (data.HideOnDesktopTablet ? "is-hidden-tablet " : "")',
    },
})
export class HeroSpotComponent implements OnInit, OnDestroy {
    static ref = 'Hero Spot';

    @Input() public data;
    @Input() public templateType;

    arrowRightIcon = arrowRight;

    noText: boolean;
    imageSizeDivider = 1;
    imagePathName = getFilename;

    sitecoreId: string;

    hideFromSearchEngines = false;
    inView = false;
    isTabletMobile: boolean;
    displaySpotContent = false;
    destroy = new Subject();
    textColor = 'fff';
    private heroText1: string;
    private heroText2: string;
    private heroText3: string;

    constructor(
        private tracking: TrackingService,
        @Optional()
        @Inject('isBot')
        private isBot: boolean,
        private paneService: PaneService,
        private breakpointObserver: BreakpointObserver,
        @Inject(PLATFORM_ID) private platformId,
        private userService: UserService,
        private personalizeContent: PersonalizeContentService
    ) {

    }

    onInView(bool: boolean) {
        if (isPlatformBrowser(this.platformId)) {
            this.inView = isIe() || this.isTabletMobile ? true : bool;

            const element = document.getElementById(this.data.Id);
            const viewed = element.getAttribute('viewed') !== null;

        }
    }

    showProductInRightPane(ev: Event, marker: any) {
        const sitecoreId = this.data.Id;

        this.tracking.clickShopByImage(marker, 'Image', this.data.Id);

        ev.stopPropagation();
        ev.preventDefault();
        this.paneService.openChild(ShopByImageComponent, 'right', {
            marker,
            sitecoreId,
        });
        return false;
    }

    trackClick() {
        this.tracking.sendPromotionClick(this.data);
    }

    ngOnInit(): void {
        this.heroText1 = !!this.data.RichText1 ? this.data.RichText1 : this.data.Text1;
        this.heroText2 = !!this.data.RichText2 ? this.data.RichText2 : this.data.Text2;
        this.heroText3 = !!this.data.RichText3 ? this.data.RichText3 : this.data.Text3;

        if (this.data.TextColor && this.data.TextColor !== '') {
            this.setStrokeColorInSvg(this.data.TextColor);
            this.textColor = this.data.TextColor;
        }

        if (
            !this.isBot &&
            isPlatformBrowser(this.platformId) &&
            this.data.IsPersonalized
        ) {

            if (this.userService.userEmailLocalstorage) {
                this.userService.currentUser
                    .pipe(takeUntil(this.destroy))
                    .subscribe(user => {
                        if (!!user.Segment) {
                            this.fetchPersonalizedSpotContent(
                                this.userService.userSegmentLocalstorage
                                    ? this.userService.userSegmentLocalstorage
                                    : user.Segment
                            );
                        }
                    });
            } else {
                this.displaySpotContent = true;
            }
        } else if (isPlatformBrowser(this.platformId)) {
            this.displaySpotContent = true;
        }

        if (isPlatformServer(this.platformId)) {
            if (!this.data.IsPersonalized) {
                this.displaySpotContent = true;
            }
        }

        this.breakpointObserver
            .observe(['(max-width: 768px)'])
            .subscribe((state: BreakpointState) => {
                this.isTabletMobile = state.matches;
            });

        if (this.isBot) {
            this.hideFromSearchEngines = this.data.HideFromSearchEngines;
        }
        this.imageSizeDivider = sublayoutImageScaleDivider(this.templateType);
    }

    ngOnDestroy() {
        this.breakpointObserver.ngOnDestroy();
        this.destroy.next();
        this.destroy.complete();
    }

    private fetchPersonalizedSpotContent(segment: string) {
        const isUserSegmentInSpotSegments =
            this.data.Segments.length > 0 &&
            this.data.Segments.filter(
                seg => seg.toLowerCase() === segment.toLowerCase()
            ).length > 0;

        if (isUserSegmentInSpotSegments) {
            this.personalizeContent
                .userContent({
                    Id: this.data.Id,
                    TemplateName: this.data.TemplateName,
                    Segment: segment,
                })
                .pipe(takeUntil(this.destroy))
                .subscribe(content => {
                    this.data = { ...content };
                    setTimeout(() => {
                        this.displaySpotContent = true;
                    }, 1000);
                });
        } else {
            this.displaySpotContent = true;
        }
    }

    setStrokeColorInSvg(textColor: string): void {
        this.arrowRightIcon = this.arrowRightIcon.replace('stroke="#fff"', `stroke="#${textColor}"`);
    }
}
