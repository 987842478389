import { Pipe, PipeTransform } from '@angular/core';
import { ImpactCoreStoresPeriod } from '@shared/swagger/swagger.interface';

@Pipe({
    name: 'firstDayOfWeek',
})
export class FirstDayOfWeekPipe implements PipeTransform {
    transform(days: ImpactCoreStoresPeriod[], args?: any): any {
        if (days) {
            const sundaysIndex = days.findIndex(day => day.open.day === 0);

            if (days[0].open.day === 0) {
                const daysArr = days.slice(1, days.length);
                return [].concat(...daysArr, days[0]);
            } else {
                if (sundaysIndex < 0) {
                    days.push({
                        close: {
                            day: 0,
                            time: '',
                        },
                        open: {
                            day: 0,
                            time: '',
                        },
                    });
                }
            }

            return days;
        }
    }
}
