import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    ImpactCoreModelsProductsDtoStoreStockDto as StoreStockModel,
    ImpactWebsiteCodeWebApiControllersSkuStoreStockRequest as SkuStoreStockRequest,
} from '@shared/swagger/swagger.interface';
import { environment } from '../../../../environments/environment';
import { tap } from 'rxjs/operators';
import { SiteContextService } from '@core/site-context.service';

@Injectable()
export class StockInStoreService {
    private env: string = this.siteContextService.getRootUrl();

    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService
    ) {}

    getStockInStores(sku: string): Observable<StoreStockModel> {
        const payload = <SkuStoreStockRequest>{
            SkuId: sku,
        };
        return this.http.post(`${this.env}/webapi/Product/StoreStock`, payload);
    }
}
