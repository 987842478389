import { PageService } from '@core/page.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ImpactOverlayRef, OverlayService } from '@impactdk/ngx-overlay';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogPageComponent } from '@features/dialog/dialog-page/dialog-page.component';

@Component({
    selector: 'app-dialog-outlet',
    template: ' ',
})
export class DialogOutletComponent implements OnInit, OnDestroy {
    overlayRef: ImpactOverlayRef;
    constructor(
        private overlay: OverlayService,
        private route: ActivatedRoute,
        private router: Router,
        private pageService: PageService,
        @Inject(DOCUMENT) private _document
    ) {}

    ngOnInit() {
        const data = this.route.snapshot.data;
        setTimeout(() => {
            this.pageService.page = data.content;
            this._document.body.classList.add('dialog-open');
            this.overlayRef = this.overlay.open(DialogPageComponent, {
                fullHeight: true,
                hasBackdrop: false,
                data,
            });
        }, 0);
    }

    ngOnDestroy() {
        if (this.overlayRef) {
            this.overlayRef.close();
        }

        this.pageService.setPage({});
    }
}
