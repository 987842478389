import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication.service';
import { PageService } from '@core/page.service';
import { SiteContextService } from '@core/site-context.service';
import {
    GaEvent,
    IExposedProductImpressions,
    PageViewEvent,
} from '@core/tracking/tracking.events';
import {COOKIE_KEY, UserService} from '@core/user.service';
import { ExposedProductRow } from '@features/product-list/exposed-product-row';
import {TracedockService} from '@core/tracking/tracedock.service';
import {CookieService} from 'ngx-cookie';
import {user} from '@shared/svg';

@Injectable()
export class TrackingService {
    private initialPageView = true;
    private currentSpots = [];
    public currentProductList = '';
    private dataLayerOriginalLocation: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private authService: AuthenticationService,
        private breakpointObserver: BreakpointObserver,
        private contextService: SiteContextService,
        @Inject(PLATFORM_ID) private platformId: object,
        private router: Router,
        private pageService: PageService,
        private tracedockService: TracedockService,
        private cookieService: CookieService,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            const userId = this.cookieService.get(COOKIE_KEY);

            if (typeof (window as any).dataLayer === 'undefined') {
                (window as any).dataLayer = (window as any).dataLayer || [];
            }

            if (userId) {
                this.tracedockService.identify(userId);
            }
        }

        this.pageService.page.subscribe(page => {
            if (
                page.pageData &&
                page.pageData.TemplateName !== 'Product page'
            ) {
                this.currentProductList = '';
            }
        });
    }

    sendCheckoutOption(option, stepIndex) {
        if (isPlatformBrowser(this.platformId)) {
            const event = {
                event: 'checkoutOption',
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            step: stepIndex,
                            option,
                        },
                    },
                },
            };

            (window as any).dataLayer.push(event);
        }
    }

    sendTransaction(order) {
        if (isPlatformBrowser(this.platformId)) {
            const event = {
                event: 'transaction',
                ecommerce: {
                    currencyCode: order.Currency,
                    purchase: {
                        actionField: {
                            id: order.OrderNumber,
                            affiliation: 'Online Store',
                            revenue: Number.isInteger(order.TotalPrice)
                                ? order.TotalPrice.toString()
                                : order.TotalPrice,
                            tax: order.TotalVatAmount
                                ? Number.isInteger(order.TotalVatAmount)
                                    ? order.TotalVatAmount.toString()
                                    : order.TotalVatAmount
                                : '0',
                            shipping: order.DeliveryFee
                                ? Number.isInteger(order.DeliveryFee)
                                    ? order.DeliveryFee.toString()
                                    : order.DeliveryFee
                                : '',
                            coupon: order.VoucherCode ? order.VoucherCode : '',
                        },
                        products: order.OrderLines.map(line => {
                            return {
                                name: line.ProductName ? line.ProductName : '',
                                id: line.StyleId + '-' + line.ColorId,
                                brand: line.BrandText ? line.BrandText : '',
                                price: line.UnitPrice,
                                category: line.CategoryText
                                    ? line.CategoryText
                                    : '',
                                variant: line.Color,
                                quantity: line.Count,
                            };
                        }),
                    },
                },
            };

            (window as any).dataLayer.push(event);
        }
    }

    /**
     * Send when a item has its giftwrapping status changed
     * @param status
     */
    sendGiftWrapping(status) {
        if (isPlatformBrowser(this.platformId)) {
            const event = this.GaEvent('Ecommerce', 'Giftwrapping', status);

            (window as any).dataLayer.push(event);
        }
    }

    /**
     * Used to send an event with the basket content after each
     * step of the checkout
     * @param stepIndex
     * @param basket
     */
    sendCheckoutStep(stepIndex, basket, sendOptionsEvent = true) {
        if (isPlatformBrowser(this.platformId)) {
            let sendEvent = true;
            const index = stepIndex + 1;
            const actionField = { step: index };

            switch (stepIndex) {
                case 2:
                /*if (sendOptionsEvent) {
                        console.log(basket);
                        this.sendCheckoutOption(basket.CarrierId, stepIndex);
                    }
                    break;*/

                case 3:
                    if (sendOptionsEvent) {
                        this.sendCheckoutOption(basket.CarrierId, stepIndex);
                    }
                    break;

                case 4:
                    if (sendOptionsEvent) {
                        this.sendCheckoutOption(basket.PaymentId, stepIndex);
                    }
                    sendEvent = false;
                    break;

                default:
                    break;
            }

            const event = {
                event: 'checkout',
                ecommerce: {
                    currencyCode: basket.Currency,
                    checkout: {
                        actionField,
                        products: basket.Lines
                            ? basket.Lines.map(line => {
                                  return {
                                      name: line.Name,
                                      id: `${line.StyleId}-${line.ColorId}`,
                                      price: line.UnitPrice,
                                      brand: line.Brand,
                                      category: line.CategoryName,
                                      variant: line.Color,
                                      quantity: line.Amount,
                                  };
                              })
                            : [],
                    },
                },
            };

            if (sendEvent) {
                (window as any).dataLayer.push(event);
            }
        }
    }

    /**
     * Used when one or more products are added the the basket
     * @param product
     * @param amount
     */
    sendAddToBasket(product, amount) {
        if (isPlatformBrowser(this.platformId)) {
            let activeColorGroupTracking;

            if (product.ColorGroups && product.ActiveColorGroupId) {
                activeColorGroupTracking = product.ColorGroups.find(group => {
                    return product.ActiveColorGroupId === group.ColorId;
                }) ?? product.ColorGroups[0];

            } else if (product.ColorGroups && !product.ActiveColorGroupId) {
                activeColorGroupTracking = product.ColorGroups.find(group => {
                    return group.Skus.find(sku => {
                        return product.SkuId === sku.Id;
                    });
                });
            } else {
                activeColorGroupTracking = product;
            }

            const event = {
                event: 'addToCart',
                ecommerce: {
                    currencyCode: product.Currency,
                    add: {
                        products: [
                            {
                                name: product.Name,
                                id: `${product.StyleId}-${
                                    product.ActiveColorGroupId
                                        ? product.ActiveColorGroupId
                                        : product.ColorId
                                        ? product.ColorId
                                        : activeColorGroupTracking.ColorId
                                }`,
                                price: product.UnitPrice
                                    ? product.UnitPrice
                                    : !!activeColorGroupTracking.SalePrice && activeColorGroupTracking.SalePrice > 0
                                        ? activeColorGroupTracking.SalePrice : activeColorGroupTracking.Price,
                                brand: product.Brand
                                    ? product.Brand
                                    : product.BrandName,
                                category: product.CategoryName
                                    ? product.CategoryName
                                    : product.Category,
                                variant: product.Color
                                    ? product.Color
                                    : activeColorGroupTracking.FilterColorName,
                                quantity: amount,
                            },
                        ],
                    },
                },
            };

            (window as any).dataLayer.push(event);
        }
    }

    /**
     * Used when one or more products are removed from the basket
     * @param product
     * @param amount
     */
    sendRemoveFromBasket(product, amount) {
        if (isPlatformBrowser(this.platformId)) {
            let activeColorGroup;
            if (product.ColorGroups) {
                activeColorGroup = product.ColorGroups.find(group => {
                    return product.ColorId === group.ColorId;
                });
            }

            const event = {
                event: 'removeFromCart',
                ecommerce: {
                    currencyCode: product.Currency,
                    remove: {
                        products: [
                            {
                                name: product.Name,
                                id: `${product.StyleId}-${product.ColorId}`,
                                price: product.UnitPrice
                                    ? product.UnitPrice
                                    : activeColorGroup.Price,
                                brand: product.Brand
                                    ? product.Brand
                                    : product.BrandName,
                                category: product.CategoryName
                                    ? product.CategoryName
                                    : product.Category,
                                variant: product.Color
                                    ? product.Color
                                    : activeColorGroup.FilterColorName,
                                quantity: amount,
                            },
                        ],
                    },
                },
            };

            (window as any).dataLayer.push(event);
        }
    }

    /**
     * Send an event to GTM that the page has changed
     * @param pageData
     */
    public sendPageView(pageData, basketId: string) {
        if (isPlatformBrowser(this.platformId)) {
            if (this.initialPageView) {
                this.dataLayerOriginalLocation = `${location.protocol}//${location.hostname}${location.pathname}${location.search}`;
            }

            const pageviewEvent = this.PageViewEvent(pageData, basketId);

            (window as any).dataLayer.push({
                event: 'genericVariables',
                pagePath: location.pathname,
                originalLocation: this.dataLayerOriginalLocation
            });

            (window as any).dataLayer.push({
                event: 'virtualPageView',

                ...pageviewEvent,
            });
        }
    }

    /**
     * Helper function to push new datalayer events
     * @param {GaEvent} event
     */
    public sendGaEvent(event: GaEvent) {
        if (isPlatformBrowser(this.platformId)) {
            (window as any).dataLayer.push(event);
        }
    }

    /**
     * Pushes the array of spots on the page to GTM
     * @param spots
     */
    public sendPromotionImpressions(spots) {
        const totalSpots = this.collectSpots(spots);
        if (totalSpots.length) {
            const trackedSpots = totalSpots.filter(spot => {
                const trackedSpotTypes = [
                    'Reference Spot',
                    'Trust Pilot spot',
                    'Newsletter Signup Spot',
                ];

                return trackedSpotTypes.indexOf(spot.TemplateName) !== -1;
            });

            this.currentSpots = trackedSpots;

            if (trackedSpots.length) {
                if (isPlatformBrowser(this.platformId)) {
                    (window as any).dataLayer.push({
                        event: 'promotionImpressions',
                        ecommerce: {
                            promoView: {
                                promotions: trackedSpots.map((spot, index) => {
                                    return {
                                        id: spot.Id,
                                        name: this.getSpotName(spot),
                                        creative: spot.TemplateName,
                                        position: index,
                                    };
                                }),
                            },
                        },
                    });
                }
            }
        }
    }

    /**
     * Event to send when a promotion spot is clicked
     * @param spot
     */
    public sendPromotionClick(spot) {
        (window as any).dataLayer.push({
            event: 'promotionClick',
            ecommerce: {
                promoClick: {
                    promotions: [
                        {
                            id: spot.Id,
                            name: this.getSpotName(spot),
                            creative: spot.TemplateName,
                            position: this.getSpotIndex(spot.Id),
                        },
                    ],
                },
            },
        });
    }

    /**
     * Event to send whenever a product is clicked in a list
     * @param product
     * @param index
     * @param listName
     */
    public sendProductClick(product, index, listName) {
        if (isPlatformBrowser(this.platformId)) {
            const event = {
                event: 'productClick',
                ecommerce: {
                    currencyCode: product.Currency,
                    click: {
                        actionField: {
                            list: listName,
                        },
                        products: [
                            {
                                id: product.ColorGroupId,
                                name: product.Name,
                                price: product.Price,
                                brand: product.Brand,
                                category: product.CategoryDisplayName,
                                variant: product.ColorName,
                                list: listName,
                                position: index,
                            },
                        ],
                    },
                },
            };

            this.currentProductList = listName;
            (window as any).dataLayer.push(event);
        }
    }

    /**
     * Event to send when a product page is visited
     * @param product
     */
    public sendProductPageView(product) {
        if (isPlatformBrowser(this.platformId)) {
            const activeColorGroup = product.ColorGroups.find(group => {
                return group.ColorId === product.ActiveColorGroupId;
            });
            const event = {
                event: 'productView',
                ecommerce: {
                    currencyCode: product.Currency,
                    detail: {
                        actionField: {
                            list: this.currentProductList,
                        },
                        products: [
                            {
                                id: `${product.StyleId}-${product.ActiveColorGroupId}`,
                                name: product.Name
                                    ? product.Name
                                    : this.fallBackProductName(
                                          product.Fit,
                                          product.Model,
                                          product.Category,
                                          activeColorGroup.ColorName
                                      ),
                                price: Number.isInteger(activeColorGroup.Price)
                                    ? activeColorGroup.Price.toString()
                                    : activeColorGroup.Price,
                                brand: product.BrandName,
                                category: product.Category,
                                variant: activeColorGroup.ColorName,
                            },
                        ],
                    },
                },
            };

            (window as any).dataLayer.push(event);
        }
    }

    /**
     * Event to send when a list of products is showed
     * @param products
     * @param listName
     */
    public sendProductsImpressions(products, listName, priceVisibility) {
        if (products.length && isPlatformBrowser(this.platformId)) {
            const event = {
                event: 'productImpressions',
                priceVisible: priceVisibility,
                ecommerce: {
                    currencyCode: products[0].Currency,
                    impressions: products.map((product, index) => {
                        return {
                            id: product.ColorGroupId,
                            name: product.Name
                                ? product.Name
                                : this.fallBackProductName(
                                      product.Fit,
                                      product.Model,
                                      product.CategoryDisplayName,
                                      product.ColorName
                                  ),
                            price: product.Price,
                            brand: product.Brand,
                            category: product.CategoryDisplayName,
                            variant: product.ColorName,
                            list: listName,
                            position: index,
                        };
                    }),
                },
            };

            (window as any).dataLayer.push(event);
        }
    }

    /**
     * Event to send whenever a product is clicked in a shop-by-image
     * @param product
     * @param listName
     * @param spotId
     */
    public clickShopByImage(product, listName, spotId) {
        if (isPlatformBrowser(this.platformId)) {
            const event = {
                event: 'gaEvent_productClick',
                eventCategory: 'Shop The Look',
                eventAction: product.Name,
                eventLabel: listName,
                eventSpot: spotId,
                ecommerce: {
                    currencyCode: product.CurrencyCode,
                    click: {
                        actionField: {
                            list: 'Shop The Look',
                            action: 'click',
                        },
                        products: [
                            {
                                id: product.ColorGroupId,
                                name: product.Name,
                                price: product.Price,
                                brand: product.Brand,
                                category: product.Category,
                                variant: product.Color,
                                list: 'Shop The Look',
                                position: 0,
                            },
                        ],
                    },
                },
            };
            (window as any).dataLayer.push(event);
        }
    }

    /**
     * Event to send whenever a shop-by-image is viewed
     * @param products
     */
    public sendShopByImage(products) {
        if (products.length && isPlatformBrowser(this.platformId)) {
            const event = {
                event: 'productImpressions',
                ecommerce: {
                    currencyCode: products[0].CurrencyCode,
                    impressions: products.map((product, index) => {
                        return {
                            id: product.ColorGroupId,
                            name: product.Name
                                ? product.Name
                                : this.fallBackProductName(
                                      product.Fit,
                                      product.Model,
                                      product.Category,
                                      product.Color
                                  ),
                            price: product.Price,
                            brand: product.Brand,
                            category: product.Category,
                            variant: product.Color,
                            list: 'Shop The Look',
                            position: index,
                        };
                    }),
                },
            };

            (window as any).dataLayer.push(event);
        }
    }

    sendVideoDuration(action: string, label: string) {
        const event: GaEvent = {
            event: 'gaEvent',
            eventCategory: 'Video',
            eventAction: action,
            eventLabel: label,
        };
        this.sendGaEvent(event);
    }

    /**
     * Event to send when a meta menu item is clicked
     * @param tab
     */
    sendMetaMenuMenuClick(tab) {
        const event = this.GaEvent('Meta menu', 'Icon click', tab);
        this.sendGaEvent(event);
    }

    /**
     * Event to send when a navigation item is clicked
     * @param location
     * @param destination
     */
    sendNavigationEvent(location, destination) {
        const event = this.GaEvent(location, this.getCurrentUrl(), destination);
        this.sendGaEvent(event);
    }

    /**
     * Event to send when a social icon is clicked
     * @param network
     */
    public sendSocialClick(network) {
        const event = this.GaEvent('Social', network, this.getCurrentUrl());
        this.sendGaEvent(event);
    }

    /**
     * Event to send when a facet checkbox is checked
     * @param facetName
     * @param optionName
     */
    public sendFacetCheck(facetName, optionName) {
        const event = this.GaEvent('Filter use', facetName, optionName);
        this.sendGaEvent(event);
    }

    /**
     * Event to send when a facet slider is changed
     * @param facetName
     * @param value
     */
    public sendSliderChange(facetName, value) {
        const event = this.GaEvent('Filter use', facetName, value);
        this.sendGaEvent(event);
    }

    /**
     * Event to send when the product grid layout is changed
     * @param size
     */
    public sendChangeProductGridLayout(size) {
        const event = this.GaEvent('Sorting use', 'Column', size);
        this.sendGaEvent(event);
    }

    /**
     * Event to send when the product sorting is changed
     * @param sorting
     */
    public sendChangeProductSorting(sorting) {
        const event = this.GaEvent('Sorting use', 'Choose sorting', sorting);
        this.sendGaEvent(event);
    }

    /**
     * Event to send when a tel: link is clicked
     */
    public sendPhoneLinkClick() {
        const event = this.GaEvent('Contact', 'Phone', this.getCurrentUrl());
        this.sendGaEvent(event);
    }

    /**
     * Event to send when a mailto: link is clicked
     */
    public sendEmailLinkClick() {
        const event = this.GaEvent('Contact', 'Email', this.getCurrentUrl());
        this.sendGaEvent(event);
    }

    /**
     * Event to send when a file is downloaded
     * @param linkEle
     */
    public sendDownloadLinkClick(linkEle) {
        const event = this.GaEvent(
            'Download',
            linkEle.href,
            this.getCurrentUrl()
        );

        this.sendGaEvent(event);
    }

    /**
     * Event to send when an outgoing link is clicked
     * @param linkEle
     */
    public sendOutgoingLinkClick(linkEle) {
        const event = this.GaEvent(
            'Outgoing links',
            linkEle.href,
            this.getCurrentUrl()
        );

        this.sendGaEvent(event);
    }

    /**
     * Event to send up when signed up to the newsletter
     * @param {string} location
     */
    public sendNewsletterSignup(location: string) {
        const event = this.GaEvent('Newsletter', 'Sign up', location);
        this.sendGaEvent(event);
    }

    /**
     * Event to send when a search is complete
     * @param query
     * @param amount
     * @param {boolean} quicksearch
     */
    public sendSearchResults(query, amount, quicksearch: boolean = false) {
        const category = quicksearch ? 'Instant search' : 'Search';
        const event = this.GaEvent(category, query, amount);
        this.sendGaEvent(event);
    }

    /**
     * Event to send when a item is added to the favorite list
     * @param productName
     */
    public sendAddToFavorite(productName) {
        const event = this.GaEvent(
            'E-commerce',
            'Add to favorite',
            productName
        );

        this.sendGaEvent(event);
    }

    /**
     * Event to send when a item is removed from the favorite list
     * @param productName
     */
    public sendRemoveFromFavorite(productName) {
        const event = this.GaEvent(
            'E-commerce',
            'Remove from favorite',
            productName
        );

        this.sendGaEvent(event);
    }

    /**
     * Event to send when the favorite list is send
     * @param amountOfProducts
     */
    public sendFavoriteListSend(amountOfProducts) {
        const event = this.GaEvent(
            'E-commerce',
            'Favorite list sent',
            amountOfProducts
        );

        this.sendGaEvent(event);
    }

    /**
     * Event to send when the find product in store button is clicked
     */
    public sendFindProductInStore() {
        const event = this.GaEvent(
            'E-commerce',
            'Find product in store',
            'Button clicked'
        );

        this.sendGaEvent(event);
    }

    /**
     * Event to send when the keep an eye on the item button is clicked
     * @param productName
     */
    public sendKeepAnEyeOpen(productName) {
        const event = this.GaEvent(
            'Keep an eye on the item',
            'Button clicked',
            productName
        );

        this.sendGaEvent(event);
    }

    /**
     * Event to send when the keep an eye on the item form is send
     * @param productName
     * @param storeName
     */
    public sendKeepAnEyeOpenSend(productName, storeName) {
        const event = this.GaEvent(
            'Keep an eye on the item',
            storeName,
            productName
        );

        this.sendGaEvent(event);
    }

    public sendAddUpsaleProductToBasket(sku: string) {
        const event = this.GaEvent(
            'E-commerce',
            'Added upsale product to basket',
            sku
        );

        this.sendGaEvent(event);
    }
    /**
     * Event is sent the first time an exposed product impression is in view
     * @param data
     */
    public sendExposedProductImpressions(data: ExposedProductRow) {
        const event: IExposedProductImpressions = {
            event: 'gaEvent_productImpressions',
            eventCategory: 'Highlighted Product',
            eventAction: 'View',
            eventLabel: data.exposedProduct.Product.Name
                ? data.exposedProduct.Product.Name
                : this.fallBackProductName(
                      data.exposedProduct.Product.FilterFit,
                      data.exposedProduct.Product.ProductName,
                      data.exposedProduct.Product.CategoryDisplayName,
                      data.exposedProduct.Product.ColorName
                  ),
            ecommerce: {
                currencyCode: data.exposedProduct.Product.Currency,
                impressions: [
                    {
                        id: data.exposedProduct.Product.ColorGroupId,
                        name: data.exposedProduct.Product.Name
                            ? data.exposedProduct.Product.Name
                            : this.fallBackProductName(
                                  data.exposedProduct.Product.FilterFit,
                                  data.exposedProduct.Product.ProductName,
                                  data.exposedProduct.Product
                                      .CategoryDisplayName,
                                  data.exposedProduct.Product.ColorName
                              ),
                        price: +data.exposedProduct.Product.Price,
                        brand: data.exposedProduct.Product.Brand,
                        category:
                            data.exposedProduct.Product.CategoryDisplayName,
                        variant: data.exposedProduct.Product.ColorName,
                        list: 'Highlighted Product',
                        position: +data.exposedProduct.RowNumber,
                    },
                ],
            },
        };
        this.sendGaEvent(event);
    }

    /**
     * Event to send whenever an exposed product is clicked in a list
     * @param product
     * @param index
     * @param listName
     */
    public sendExposedProductClick(product, index, listName) {
        if (isPlatformBrowser(this.platformId)) {
            const event = {
                event: 'gaEvent_productClick',
                eventCategory: 'Highlighted Product',
                eventAction: 'Click',
                eventLabel: product.Name
                    ? product.Name
                    : this.fallBackProductName(
                          product.FilterFit,
                          product.ProductName,
                          product.CategoryDisplayName,
                          product.ColorName
                      ),
                ecommerce: {
                    currencyCode: product.Currency,
                    click: {
                        actionField: {
                            list: 'Highlighted Product',
                            action: 'click',
                        },
                        products: [
                            {
                                id: product.ColorGroupId,
                                name: product.Name,
                                price: product.Price,
                                brand: product.Brand,
                                category: product.CategoryDisplayName,
                                variant: product.ColorName,
                                list: 'Highlighted Product',
                                position: index,
                            },
                        ],
                    },
                },
            };

            this.currentProductList = listName;

            (window as any).dataLayer.push(event);
        }
    }

    /**
     * Helper function to traverse all layers of spots on a page
     * @param spots
     * @returns {(any)[] | any[]}
     */
    private collectSpots(spots) {
        let levelSpots = [];

        spots.map(spot => {
            if (Array.isArray(spot.Column1Spots)) {
                levelSpots = [...levelSpots, ...spot.Column1Spots];
            }

            if (Array.isArray(spot.Column2Spots)) {
                levelSpots = [...levelSpots, ...spot.Column2Spots];
            }

            if (Array.isArray(spot.Column3Spots)) {
                levelSpots = [...levelSpots, ...spot.Column3Spots];
            }

            if (Array.isArray(spot.Column4Spots)) {
                levelSpots = [...levelSpots, ...spot.Column4Spots];
            }
        });

        let nextLevelSpots;

        if (levelSpots.length) {
            nextLevelSpots = this.collectSpots(levelSpots);
        }

        return Array.isArray(nextLevelSpots)
            ? [...spots, ...levelSpots, ...nextLevelSpots]
            : levelSpots;
    }

    /**
     * Helper to get spot name
     * @param spot
     * @returns {any}
     */
    private getSpotName(spot) {
        switch (spot.TemplateName) {
            case 'Reference Spot':
                return spot.Heading;

            case 'Trust Pilot spot':
                return 'Trustpilot';

            case 'Newsletter Signup Spot':
                return spot.Title;

            default:
                return 'Something else';
        }
    }

    /**
     * Helper to find the index of a spot in a page
     * @param spotId
     * @returns {number}
     */
    private getSpotIndex(spotId) {
        return this.currentSpots.findIndex(spot => {
            return spot.Id === spotId;
        });
    }

    /**
     * Helper to get the current URL
     * @returns {string}
     */
    private getCurrentUrl() {
        if (isPlatformBrowser(this.platformId)) {
            return window.location.href;
        }

        return '/';
    }

    /**
     * Event send when a page is viewed
     * @param pageData
     * @returns {PageViewEvent}
     * @constructor
     */
    private PageViewEvent(pageData, basketId): PageViewEvent {
        const url = window.location.href;
        const user = this.userService.currentUser.getValue();
        const auth = this.authService.isAuthorized.getValue();
        const context = this.contextService.getContext();
        const title = this.getPageTitle(pageData);

        const pageViewEvent: PageViewEvent = {
            pageTitle: title,
            pageType: pageData.TemplateName,
            pageUrl: url,
            pagePath: document.location.pathname + document.location.search,
            pageLoadType: this.initialPageView
                ? 'full-page-load'
                : 'spa-page-load',
            userId: user.UserName ?? '',
            loginStatus: auth ? 'loggedin' : 'loggedout',
            deviceType: this.getDeviceType(),
            countrySite: context.countryCode,
            anonymousID: basketId,
        };

        if (this.initialPageView) {
            this.initialPageView = false;
        }

        return pageViewEvent;
    }

    public fallBackProductName(
        fit = '',
        model = '',
        category = '',
        color = ''
    ) {
        const tags = [];

        if (fit) {
            tags.push(fit);
        }

        if (model) {
            tags.push(model);
        }

        if (category) {
            tags.push(category);
        }

        if (color) {
            tags.push(color);
        }

        return tags.join(' | ');
    }

    /**
     * Helper for creating GaEvent objects
     * @param {string} category
     * @param {string} action
     * @param {string} label
     * @returns {{event: string; eventCategory: string; eventAction: string; eventLabel: string}}
     * @constructor
     */
    private GaEvent(category: string, action: string, label: string) {
        return {
            event: 'gaEvent',
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
        };
    }

    private getPageTitle(pageData) {
        if (
            pageData.ProductData &&
            pageData.ProductData.PageTitle &&
            !pageData.Title
        ) {
            return pageData.ProductData.PageTitle;
        } else {
            return pageData.Title;
        }
    }

    /**
     * Helper to get the currenct device type
     * @returns {string}
     */
    private getDeviceType() {
        const isMobile = this.breakpointObserver.isMatched(
            '(max-width: 768px)'
        );

        const isTablet = this.breakpointObserver.isMatched(
            '(max-width: 1023px)'
        );

        return isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
    }
}
