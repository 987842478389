import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightpaneModule } from '@features/rightpane/rightpane.module';
import { StoreMapComponent } from './store-map/store-map.component';

import { SharedModule } from '@shared/shared.module';
import { FindStorePaneComponent } from './find-store-pane/find-store-pane.component';
import { FindStorePageComponent } from './find-store-page/find-store-page.component';
import { FindStoreComponent } from '@features/find-store/find-store-content/find-store.component';
import { StoreInfoComponent } from '@features/find-store/store-info/store-info.component';
import { OpeningHoursComponent } from './store-info/opening-hours/opening-hours.component';
// Google-Maps dependencies
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { FindStoreListComponent } from './find-store-list/find-store-list.component';
import { FindStoreListItemComponent } from './find-store-list/find-store-list-item/find-store-list-item.component';
import { SpecialOpeningHoursPipe } from './special-opening-hours.pipe';

@NgModule({
    imports: [
        CommonModule,
        RightpaneModule,
        SharedModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCEMisr63guJAwd7mYpRF1Ol0xc0ctzoMM',
            libraries: ['places'],
            apiVersion: '3.31', // ctrl + scroll zoom: https://github.com/SebastianM/angular-google-maps/issues/1411
        }),
        AgmJsMarkerClustererModule,
    ],
    entryComponents: [FindStorePaneComponent],
    declarations: [
        FindStoreComponent,
        FindStorePaneComponent,
        FindStorePageComponent,
        StoreInfoComponent,
        StoreMapComponent,
        OpeningHoursComponent,
        FindStoreListComponent,
        FindStoreListItemComponent,
        SpecialOpeningHoursPipe,
    ],
    exports: [FindStorePageComponent],
})
export class FindStoreModule {}
