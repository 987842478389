import {
    Component,
    EventEmitter,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output, SimpleChanges,
} from '@angular/core';
import { cross, expand, chevronDown } from '@shared/svg';
import {
    ImpactCoreBasketsBasketLineServiceModel as basketLineModel,
    ImpactCoreModelsProductsAvailableSize as availableSizeModel,
} from '@shared/swagger/swagger.interface';
import { TranslationService } from '@core/translation.service';
import { isStockCountHigh } from '@shared/utility';
import { SiteContextService } from '@core/site-context.service';
import { BasketService } from '@core/basket.service';
import { Subject } from 'rxjs';
import { first, last, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-minibasket-product',
    templateUrl: './minibasket-product.component.html',
})
export class MinibasketProductComponent implements OnChanges, OnInit, OnDestroy {

    constructor(
        public translationService: TranslationService,
        private contextService: SiteContextService,
        private basketService: BasketService,
    ) {
        this.businessDimension = this.contextService.getContext().businessDimension;
        this.expandIcon = this.businessDimension.toLowerCase() === 'axel' ? chevronDown : expand;
    }

    removingProduct = false;
    businessDimension: string;
    expandIcon: string;

    destroy = new Subject();

    /**
     * Get product from parent component
     */
    @Input()
    basketLine: basketLineModel;

    @Input() productImageBgColor: string;


    /**
     * Update basket event which parent should handle
     */
    @Output()
    updateBasket: EventEmitter<basketLineModel> = new EventEmitter();

    /**
     * Selected size for initial dropdown view
     */
    selectedSize: availableSizeModel;

    /**
     * Selected amount for initial dropdown view
     */
    selectedAmount: number;

    /**
     * Stock count dummy array for options to loop over
     */
    stockCount: number[];

    /**
     * Is stock high or low
     */
    isStockCountHigh = isStockCountHigh;

    /**
     * Prep icons for minibasket
     */
    icons = {
        expand,
        cross,
    };

    /**
     * Hard cap stock count for products
     */
    private maxStockCount = 10;

    giftCardLimitNotificationMessage: string;
    showGiftCardLimitNotification: boolean;

    ngOnInit() {
        this.showGiftCardLimitNotification = false;
        this.basketService.basketUpdateGiftCardLimitMessage$
            .pipe(
                takeUntil(this.destroy)
            )
            .subscribe(data => {
                this.showGiftCardLimitNotification = false;
                if (data.LineId === this.basketLine.Id && !data.Success) {
                    this.giftCardLimitNotificationMessage = data.Message;
                    this.showGiftCardLimitNotification = true;
                }
            }
        );
    }

    ngOnChanges() {
        this.selectedSize = this.setSelectedSize(this.basketLine.SkuId);
        this.selectedAmount = this.basketLine.Amount;
    }

    /**
     * Change size for current sku
     */
    changeSize(selectedSize: availableSizeModel): void {
        const basketLine: basketLineModel = {
            Id: this.basketLine.Id,
            SkuId: selectedSize.SkuId,
            Amount: this.selectedAmount,
        };

        this.updateBasket.emit(basketLine);
    }

    /**
     * Change amount for current sku
     */
    changeAmount(selectedAmount: number): void {
        const basketLine: basketLineModel = {
            Id: this.basketLine.Id,
            SkuId: this.basketLine.SkuId,
            Amount: selectedAmount,
        };

        this.updateBasket.emit(basketLine);
    }

    /**
     * Remove basket line
     */
    removeBasketLine(): void {
        const basketLine: basketLineModel = {
            Id: this.basketLine.Id,
            SkuId: this.basketLine.SkuId,
            Amount: 0,
        };

        // Visibly show that
        this.removingProduct = true;

        this.updateBasket.emit(basketLine);
    }

    /**
     * Set selected size object for initial load
     */
    private setSelectedSize(skuId: string): any {
        const selectedSize: any = this.basketLine.AvailableSizes.find(
            size => size.SkuId === skuId,
        );

        this.stockCount = new Array(
            selectedSize.StockCount > this.maxStockCount
                ? this.maxStockCount
                : selectedSize.StockCount,
        );

        return selectedSize;
    }

    getProductTags(): string {
        const tags = [];

        if (this.basketLine.Fit) {
            tags.push(this.basketLine.Fit);
        }

        if (this.basketLine.Name) {
            tags.push(this.basketLine.Name);
        } else {
            tags.push(this.basketLine.CategoryName);
        }

        if (this.basketLine.Color) {
            tags.push(this.basketLine.Color);
        }

        return tags.join(' | ');
    }
    ngOnDestroy() {
        this.showGiftCardLimitNotification = false;
        this.basketService.basketUpdateGiftCardLimitMessage$.next({...this.basketService.basketUpdateGiftCardLimitMessage$.getValue(), Success: true});
        this.destroy.next();
        this.destroy.complete();
    }

    onCloseNotification(event: any) {
        this.showGiftCardLimitNotification = false;
    }
}
