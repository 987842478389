import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { MetaMenuModule } from '../meta-menu/meta-menu.module';
import { NewsletterSignupComponent } from './newsletter-signup.component';

@NgModule({
    declarations: [NewsletterSignupComponent],
    imports: [SharedModule, MetaMenuModule],
    exports: [NewsletterSignupComponent],
    entryComponents: [NewsletterSignupComponent],
})
export class NewsletterModule {}
