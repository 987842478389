import {
    Component,
    EventEmitter,
    Input,
    OnChanges, OnDestroy, OnInit,
    Output,
} from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { cross, expand, checkmark } from '@shared/svg';
import {
    ImpactCoreBasketsBasketLineServiceModel as basketLineModel,
    ImpactCoreModelsProductsAvailableSize as availableSizeModel,
} from '@shared/swagger/swagger.interface';
import { isStockCountHigh } from '@shared/utility';
import { TrackingService } from '@core/tracking/tracking.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BasketService } from '@core/basket.service';

@Component({
    selector: 'app-basket-product',
    templateUrl: './basket-product.component.html',
})
export class BasketProductComponent implements OnChanges, OnInit, OnDestroy {
    destroy = new Subject();

    /**
     * Get product from parent component
     */
    @Input() basketLine: basketLineModel;

    /**
     * Get wrapping comment
     */
    @Input() wrappingComment: string;

    /**
     * Update basket event which parent should handle
     */
    @Output() updateBasket: EventEmitter<basketLineModel> = new EventEmitter();

    /**
     * Update basket event which parent should handle
     */
    @Output() updateGiftWrap: EventEmitter<string> = new EventEmitter();

    /**
     * Selected size for initial dropdown view
     */
    selectedSize: availableSizeModel;

    /**
     * Selected amount for initial dropdown view
     */
    selectedAmount: number;

    /**
     * Stock count dummy array for options to loop over
     */
    stockCount: number[];

    /**
     * Is stock high or low
     */
    isStockCountHigh: Function = isStockCountHigh;

    /**
     * Prep icons for minibasket
     */
    icons = {
        expand,
        cross,
        checkmark,
    };

    /*
    * Gift card limit notification
    */
    giftCardLimitNotificationMessage: string;
    showGiftCardLimitNotification: boolean;

    /**
     * Hard cap stock count for products
     */
    private maxStockCount: number = 10;

    constructor(
        private tracking: TrackingService,
        public translationService: TranslationService,
        private basketService: BasketService,
    ) {}

    ngOnInit() {
        this.basketService.basketUpdateGiftCardLimitMessage$
            .pipe(takeUntil(this.destroy))
            .subscribe(data => {
                    this.showGiftCardLimitNotification = false;
                    if (data.LineId === this.basketLine.Id && !data.Success) {
                        this.giftCardLimitNotificationMessage = data.Message;
                        this.showGiftCardLimitNotification = true;
                    }
                }
            );
    }

    ngOnDestroy() {
        this.showGiftCardLimitNotification = false;
        this.basketService.basketUpdateGiftCardLimitMessage$.next({...this.basketService.basketUpdateGiftCardLimitMessage$.getValue(), Success: true});
        this.destroy.next();
        this.destroy.complete();
    }

    ngOnChanges() {
        this.selectedSize = this.setSelectedSize(this.basketLine.SkuId);
        this.selectedAmount = this.basketLine.Amount;
    }

    /**
     * Change size for current sku
     */
    changeSize(selectedSize: availableSizeModel): void {
        const basketLine: basketLineModel = {
            Id: this.basketLine.Id,
            SkuId: selectedSize.SkuId,
            Amount: this.selectedAmount,
        };

        this.updateBasket.emit(basketLine);
    }

    /**
     * Change amount for current sku
     */
    changeAmount(selectedAmount: number): void {
        this.showGiftCardLimitNotification = false;
        const basketLine: basketLineModel = {
            Id: this.basketLine.Id,
            SkuId: this.basketLine.SkuId,
            Amount: selectedAmount,
        };

        this.updateBasket.emit(basketLine);
    }

    /**
     * Remove basket line
     */
    removeBasketLine(): void {
        const basketLine: basketLineModel = {
            Id: this.basketLine.Id,
            SkuId: this.basketLine.SkuId,
            Amount: 0,
        };

        this.updateBasket.emit(basketLine);
    }

    /*
     * Update giftwrapping
     */
    toggleGiftwrapping() {
        this.tracking.sendGiftWrapping(!this.hasGiftWrappingChecked());
        this.updateGiftWrap.emit(this.basketLine.SkuId);
    }

    hasGiftWrappingChecked() {
        return this.basketLine.WithWrapping;
    }

    /**
     * Set selected size object for initial load
     */
    private setSelectedSize(skuId: string): any {
        this.showGiftCardLimitNotification = false;
        const selectedSize: any = this.basketLine.AvailableSizes.find(
            size => size.SkuId === skuId
        );

        this.stockCount = new Array(
            selectedSize.StockCount > this.maxStockCount
                ? this.maxStockCount
                : selectedSize.StockCount
        );

        return selectedSize;
    }

    getProductTags(): string {
        const tags = [];

        if (this.basketLine.Fit) {
            tags.push(this.basketLine.Fit);
        }

        if (this.basketLine.Name) {
            tags.push(this.basketLine.Name);
        } else {
            tags.push(this.basketLine.CategoryName);
        }

        if (this.basketLine.Color) {
            tags.push(this.basketLine.Color);
        }

        return tags.join(' | ');
    }

    onCloseNotification(event: any) {
        this.showGiftCardLimitNotification = false;
    }

}
