/**
 * The file contents for the production environment will overwrite the dev variables during build.
 * `ng build --env=prod` then this file will be used instead.
 */
export const environment = {
    production: true,
    dev: false,
    local: false,
    localSSR: false,
    proxyPageApiRoot: '',
    easyposRoot: 'https://easypos.kaufmann.dk',
    raptorRoot: 'https://raptor.kaufmann.dk',
    solrSearchRoot: 'https://solrsearcher.kaufmann.dk',
    stockAgentRoot: 'https://stockagent.kaufmann.dk',
    nnMarkedsDataRoot: 'https://nnmarkedsdata.kaufmann.dk',
    cdnAbsolutePath: 'https://admin.kaufmannstatic.com/Images/',
    raptorCustomerId: {
        Kaufmann: '2835',
        Quint: '3933',
        Axel: '3932',
    },
    personalization: {
        Kaufmann: {
            freeFreight: '88d5ae62-4743-4f74-b7ed-de6bfad7e80d',
            favoriteBrands: '60045969-b4c6-44c4-a683-2dee7f52d827',
            offlineOrders: 'd80a8f9c-9e01-4511-bf86-ffb49a42c85f',
        },
        Quint: {
            freeFreight: '605c27cf-7423-4eb0-aaef-e5b752ea0428',
            favoriteBrands: 'c9fb22d3-4614-48d2-96d6-cac4238270b9',
            offlineOrders: 'aef1493a-c9ed-49b0-82a3-8b2c4eefd3b2',
        },
        Axel: {
            freeFreight: 'aab7141a-42c9-4f1e-a5a3-104c290ddf79',
            favoriteBrands: '20c927e6-80b3-4d4a-9e5f-91a122e40f5a',
            offlineOrders: '234c798b-f4eb-4ca0-84f9-ffff1357391c',
        },
    },
    socialLogin: {
        google: {
            Kaufmann:
                '442478687371-f3l8grequ95sn4rq9vb84s99ln4hdu0n.apps.googleusercontent.com',
            Quint:
                '943418895231-cv1lool51p0vahgaovq7auf15r00t065.apps.googleusercontent.com',
            Axel:
                '289364320770-nqu6b0oe7l4hd983fh0a9l78rbv711ts.apps.googleusercontent.com',
        },
        facebook: {
            Kaufmann: '454464525745626',
            Quint: '1076647886145579',
            Axel: '289021122576749',
        },
    },
};
