import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const slideInOutAnimation = [
    trigger('slideInOut', [
        state(
            'true',
            style({
                overflow: 'hidden',
                height: '*',
            })
        ),
        state(
            'false',
            style({
                overflow: 'hidden',
                height: '0px',
            })
        ),
        transition('0 <=> 1', animate('500ms cubic-bezier(0.25, 0, 0, 1)')),
    ]),
];
