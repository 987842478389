export class Wish {
    StyleId: string;
    ProductInfo: string;
    Name: string;
    Model: string;
    MaterialPercentages: string[];
    Fit: string;
    Description: string;
    Currency: string;
    Price: number;
    ColorName: string;
    ColorId: string;
    Image: WishlistImage;
    Size: any;
    Category: string;
    BrandName: string;
    BrandImage: string;
    ProductPath: string;
    SkuId: string;

    /**
     *  Constructor use:
     *      Use productData & selectedSize to parse from DTO object
     *      If parsing from JSON in localstorage use the json parameter
     *          - Leave productData & selectedSize empty (with an empty string '') for that purpose
     *
     * @param productData
     * @param {string} selectedSize
     * @param json
     */

    constructor(productData: any, selectedSize: any, json?: any) {
        if (!json) {
            this.StyleId = productData.StyleId;
            this.ProductInfo = productData.ProductInfo;
            this.Name = productData.Name;
            this.Model = productData.Model;
            this.MaterialPercentages = productData.MaterialPercentages;
            this.Fit = productData.Fit;
            this.Description = productData.Description;
            this.Currency = productData.Currency;
            this.Category = productData.Category;
            this.BrandName = productData.BrandName;
            this.BrandImage = productData.BrandImage;
            this.ColorId = productData.ActiveColorGroupId;
            this.Size = selectedSize.SizeLabel;
            this.ProductPath = this.getProductPath(productData, selectedSize);

            const colorGroup = productData.ColorGroups.find(group => {
                return productData.ActiveColorGroupId === group.ColorId;
            });
            this.Price = colorGroup.Price;
            this.ColorName = colorGroup.ColorName;
            this.Image = colorGroup.Images[0];
            this.SkuId = selectedSize.Id;
        } else {
            this.StyleId = json.StyleId;
            this.ProductInfo = json.ProductInfo;
            this.Name = json.Name;
            this.Model = json.Model;
            this.MaterialPercentages = json.MaterialPercentages;
            this.Fit = json.Fit;
            this.Description = json.Description;
            this.Currency = json.Currency;
            this.Category = json.Category;
            this.BrandName = json.BrandName;
            this.BrandImage = json.BrandImage;
            this.ColorId = json.ColorId;
            this.Price = json.Price;
            this.ColorName = json.ColorName;
            this.Image = json.Image;
            this.ProductPath = json.ProductPath;
            this.Size = json.Size;
            this.SkuId = json.SkuId;
        }
    }
    private getProductPath(productData: any, selectedSize: any): string {
        const selectedColorGroup = productData.ColorGroups.find(
            item => item.ColorId === productData.ActiveColorGroupId
        );
        return selectedColorGroup.ColorGroupPath + '&size=' + selectedSize.Id;
    }
}
export class WishlistImage {
    public Width: number;
    public Height: number;
    public Path: string;
}
