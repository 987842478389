import { Component } from '@angular/core';
import { PaneService } from '@core/pane.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchComponent } from '../search.component';
import {TranslationService} from '@core/translation.service';


@Component({
    selector: 'app-search-btn',
    templateUrl: './search-btn.component.html',
})
export class SearchBtnComponent {

    angularKey = "search-axel-desktop"
    isSearchFieldOpen$: Observable<boolean>;

    constructor(
        private paneService: PaneService,
        public translation: TranslationService,
    ) {
        this.isSearchFieldOpen$ = this.paneService.activeKey$.pipe(map(activeKey => activeKey == this.angularKey));
    }

    openSearchPane() {
        this.paneService.open(SearchComponent, 'center', this.angularKey , this.angularKey, 'center', true);
    }
}
