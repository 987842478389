<article intercept-links appShopByImageTitleHoverPos (inView)="onInView($event)" class="spot-hero"
         [ngClass]="{
         'is-hidden-mobile': data.HideOnMobile,
         'is-hidden-tablet': data.HideOnDesktopTablet,
         'spot-hero_no-text': !noText,
         'shop-by-marker_show': inView,
         'hide-spot-content':!displaySpotContent
         }"
         *ngIf="!hideFromSearchEngines"
         id="{{data.Id}}">
    <a [href]="data.Link" [target]="data.LinkTarget ? data.LinkTarget : '_self'" [rel]="data.LinkTarget ? 'noopener' : ''" class="spot-hero__link" (click)="trackClick()">
        <div class="spot-hero__image-wrapper">
            <LazyPicture
                [LazyLoadSrc]="data.Image + '?w=' + (1680 / imageSizeDivider)"
                [LazyLoadSources]="[
                {media: '(max-width: 480px)', srcSet: data.Image + '?w=' + 480},
                {media: '(min-width: 481px) and (max-width: 768px)', srcSet: data.Image + '?w=' + 768},
                {media: '(min-width: 769px) and (max-width: 1024px)', srcSet: data.Image + '?w=' + 1024  / imageSizeDivider},
                {media: '(min-width: 1025px)', srcSet: data.Image + '?w=' + 1680  / imageSizeDivider}
                ]"
                [LazyImageWidth]="data.ImageWidth"
                [LazyImageHeight]="data.ImageHeight"
                [LazyLoadAlt]="data.ImageAltText ? data.ImageAltText : imagePathName(data.Image)"
            ></LazyPicture>
            <button *ngFor="let marker of data.Markers" appShopByImageHoverClass type="button" class="shop-by-button" [ngStyle]="{'left':marker.XPos + '%', 'top': marker.YPos + '%'}" (click)="showProductInRightPane($event, marker)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <path d="M25.83 10.67a15.5 15.5 0 1 1-15.5 15.5 15.51 15.51 0 0 1 15.5-15.5m0-2a17.5 17.5 0 1 0 17.5 17.5 17.5 17.5 0 0 0-17.5-17.5z" class="shop-by-marker-circle" [ngStyle]="{'fill': '#' + marker.MarkerColor, 'stroke': '#' + marker.MarkerColor}"/>
                    <path d="M25.83 34.17v-16M17.83 26.17h16" class="shop-by-marker-cross" [ngStyle]="{'fill': '#' + marker.MarkerColor, 'stroke': '#' + marker.MarkerColor}"/>
                </svg>
                <div class="shop-by-button__hover-title is-hidden-touch">{{marker.Brand}} {{marker.Category}}</div>
            </button>
        </div>
        <div class="heading-seo"  class="spot-hero__body dynamical-side-padding" [style.color]="'#' + textColor">

            <div class="text-container">
                <div class="new-styles" [innerHTML]='heroText1 | safeHtml'></div>
                <div class="headline" [innerHtml]="heroText2 | safeHtml"></div>
                <div class="content" [innerHtml]="heroText3 | safeHtml" ></div>
                <div class="call-to-action" *ngIf="data.LinkText">

                    <div class="arrow-right-div" [innerHtml]="arrowRightIcon | safeHtml"></div>
                    <div> {{ data.LinkText }}  </div>

                </div>
            </div>

        </div>

        <div class="arrow-down-div" [innerHtml]="arrowRightIcon | safeHtml"></div>

    </a>
</article>
