import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftpaneComponent } from './leftpane/leftpane.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [LeftpaneComponent],
    exports: [LeftpaneComponent],
    entryComponents: [LeftpaneComponent],
})
export class LeftpaneModule {}
