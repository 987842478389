import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { PageService } from '@core/page.service';
import { ResponsiveService } from '@core/responsive.service';
import { ScrollBackService } from '@core/scrollBack.service';
import { throttle } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import {HeaderService} from '@core/header.service';

interface StickyEvent {
    yPos?: number;
    direction?: 'down' | 'up';
    headerBottom?: number;
}

@Injectable()
export class StickyService {

    get stickyEvent() {
        return this.stickyEvent$.asObservable();
    }

    constructor(
        @Inject(DOCUMENT) private document: any,
        @Inject(PLATFORM_ID) private platformId: object,
        private scrollBackService: ScrollBackService,
        private ngZone: NgZone,
        private pageService: PageService,
        private responsiveService: ResponsiveService,
        private headerService: HeaderService,
    ) {
        this.pageService.page.subscribe(data => this.pageData = data);
        this.headerService.headerSolid$.subscribe(state => this.isHeaderSolid = state);
    }
    header: HTMLElement;
    private additionalPadding = {};
    private ticking = false;
    private pageData: any;
    lastKnownScrollPos = 0;
    bodyPadding = 0;

    stickyEvent$ = new BehaviorSubject<StickyEvent>({
        yPos: 0,
    });

    scrollListenerBound;
    isHeaderSolid: boolean;

    initScrollListen() {
        this.header = this.document.querySelector('app-header');

        this.setBodyPadding();

        this.scrollListenerBound = this.scrollListener.bind(this);

        if (isPlatformBrowser(this.platformId)) {
            this.ngZone.runOutsideAngular(() => {
                window.addEventListener(
                    'scroll',
                    throttle(this.scrollListenerBound, 100),
                    { passive: true }
                );
                window.addEventListener(
                    'resize',
                    throttle(this.scrollListenerBound, 100),
                    { passive: true }
                );
            });
        }

        this.scrollBackService.animationDone.subscribe(() => {
            this.scrollListenerBound();
        });

        this.scrollBackService.animationStart.subscribe(() => {
            this.scrollListenerBound();
        });
    }

    getHeaderheight() {
        if (this.header) {
            return this.header.clientHeight;
        } else {
            return this.document.querySelector('app-header').clientHeight;
        }
    }

    scrollListener(event) {
        if (isPlatformBrowser(this.platformId)) {
            if (this.header === null || this.header === undefined) {
                this.header = this.document.querySelector('app-header');
            }

            window.requestAnimationFrame(() => {
                this.setBodyPadding();
                this.sendStickyEvent();
                if (window.scrollY > 0) {
                    this.document.body.classList.add('sticky');
                } else {
                    this.document.body.classList.remove('sticky');
                }
            });
        }
    }

    setBodyPadding() {
        let bodyPadding;
        let additionalPadding = 0;

        const isDesktopAndFrontpage = this.responsiveService.mediaDesktopAndLarger() && this.pageData.pageData?.TemplateName.toLowerCase() === 'homepage';
        const headerHeight = isDesktopAndFrontpage && !this.isHeaderSolid ? 0 : this.getHeaderheight();

        Object.keys(this.additionalPadding).map(key => {
            additionalPadding = additionalPadding + this.additionalPadding[key];
        });

        bodyPadding = headerHeight + additionalPadding;

        if (this.bodyPadding !== bodyPadding) {
            this.document.body.style.paddingTop = bodyPadding + 'px';
            this.bodyPadding = bodyPadding;
        }
    }

    addBodyPadding(key, amount) {
        if (this.additionalPadding[key] !== amount) {
            const newPadding = { ...this.additionalPadding };

            newPadding[key] = amount;

            this.additionalPadding = newPadding;

            this.setBodyPadding();
        }
    }

    removeBodyPadding(key) {
        const newPadding = { ...this.additionalPadding };

        delete newPadding[key];

        this.additionalPadding = newPadding;

        this.setBodyPadding();
    }

    getBodyPadding(key) {
        return this.additionalPadding[key] ? this.additionalPadding[key] : 0;
    }

    sendStickyEvent() {
        if (isPlatformBrowser(this.platformId)) {
            const headerHeight = this.header.clientHeight;
            const currentScrollPos = window.pageYOffset;

            this.stickyEvent$.next({
                yPos: currentScrollPos,
                direction:
                    this.lastKnownScrollPos < currentScrollPos ? 'down' : 'up',
                headerBottom: headerHeight,
            });

            this.lastKnownScrollPos = currentScrollPos;
        }
    }
}
