import { Subject } from 'rxjs';
import {Component, Input, OnInit, Injectable, OnDestroy} from '@angular/core';
import { ImpactCoreUsersModelsCreateUserRequest as CreateUserRequest } from '@shared/swagger/swagger.interface';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { SiteContextService } from '@core/site-context.service';
import { NgForm } from '@angular/forms';
import { AlertResponse, AlertType } from '@shared/alert/alert.interface';
import { OverlayService } from '@impactdk/ngx-overlay';
import { SignupTermsComponent } from '@features/signup/signup-terms/signup-terms.component';
import { checkmark, expand } from '@shared/svg';
import { TranslationService } from '@core/translation.service';
import { takeUntil } from 'rxjs/operators';
import { RegexService } from '@core/regex.service';
import { InfoService } from '@core/info.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/authentication.service';
import { UserService } from '@core/user.service';

// Set date format for picker
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }
}

@Component({
    selector: 'app-signup-step-1',
    templateUrl: './signup-step-1.component.html',

    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_LOCALE, useValue: 'en' },
    ],
})
export class SignupStep1Component implements OnInit, OnDestroy {
    constructor(
        private siteContextService: SiteContextService,
        private dateAdapter: DateAdapter<NativeDateAdapter>,
        private translationService: TranslationService,
        public regexService: RegexService,
        private infoService: InfoService,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
        private userService: UserService
    ) {}

    @Input()
    onSubmit: (user: CreateUserRequest) => void;

    @Input()
    response: AlertResponse = {};

    @Input()
    termsLink: string;

    @Input()
    currentUser: CreateUserRequest;

    private destroy = new Subject();

    translations: any;
    user: CreateUserRequest = {
        IsNewsletterSubscriber: true,
    };

    userAcceptance = false;
    icons = {
        expand,
        checkmark,
    };
    form: NgForm;

    ngOnInit() {
        // Set date locale based on service response
        const dateLanguage = this.siteContextService.getContext();
        this.dateAdapter.setLocale(dateLanguage.language);

        this.translationService.translations$
            .pipe(takeUntil(this.destroy))
            .subscribe(data => (this.translations = data));

        this.userService.currentUser$.subscribe(userData => {
            if (userData) {
                this.user.Email = userData.Email;
                this.userAcceptance = true;
            }
        });
    }

    closeAlert = () => {
        this.response = {};
    }

    onSignup(form: NgForm) {
        this.response = {};

        if (form.valid) {
            if (this.userAcceptance) {
                this.onSubmit(this.user);
            } else {
                this.response = {
                    type: AlertType.error,
                    text: this.translations.Authentication.CreateUser
                        .TermsAndConditionsInputMissing,
                };
            }
        }
    }

    openTerms(event: Event) {
        event.preventDefault();
        this.infoService.openInfo(this.termsLink);
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }
}
