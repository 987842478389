<div class="brandlist">
    <div class="brandlist__subtitle">
        <div class="container container_padded">
            <h1 class="headline brandlist__heading">
                {{(translation.translations$ | async)?.CategoryList?.List?.Title}}
            </h1>
        </div>
    </div>
    <ul class="brandlist__group" *ngFor="let categoryList of data.CategoryList">
        <li class="brandlist__alphabet"><span>{{categoryList.Alphabet}}</span></li>
        <li class="brandlist__name" *ngFor="let category of categoryList.Categories">
            <a [routerLink]="category.CategoryPageUrl">{{category.CategoryName}} <span class="brandlist__name__arrow" [innerHtml]="arrow | safeHtml"></span></a>
        </li>
    </ul>
</div>