import {ArticleHeaderSpotComponent} from '@features/spots/article/article-header-spot/article-header-spot.component';
import {ArticleImageSpotComponent} from '@features/spots/article/article-image-spot/article-image-spot.component';
import {
    ArticleReferenceSpotComponent
} from '@features/spots/article/article-reference-spot/article-reference-spot.component';
import {ArticleTextSpotComponent} from '@features/spots/article/article-text-spot/article-text-spot.component';
import {BrandlistComponent} from '@features/spots/brandlist-spot/brandlist.component';
import {CategorylistComponent} from '@features/spots/category-list-spot/categorylist.component';
import {FavoriteBrandSpotComponent} from '@features/spots/favorite-brand-spot/favorite-brand-spot.component';
import {NewsletterSignupSpotComponent} from '@features/spots/newsletter-signup-spot/newsletter-signup-spot.component';
import {OrderReceiptSpotComponent} from '@features/spots/order-receipt-spot/order-receipt-spot.component';
import {ProductDetailComponent} from '@features/spots/product-detail/product-detail.component';
import {ProductFilterSpotComponent} from '@features/spots/product-filter-spot/product-filter-spot.component';
import {ProductSliderComponent} from '@features/spots/product-slider/product-slider.component';
import {ReferenceSpotComponent} from '@features/spots/reference-spot/reference-spot.component';
import {HeroSpotComponent} from '@features/spots/hero-spot/hero-spot.component';
import {StoreSpotComponent} from '@features/spots/store-spot/store-spot.component';
import {ArticleLayout100Component} from '@features/spots/sublayout/article-layout100/article-layout100.component';
import {ArticleLayout50Component} from '@features/spots/sublayout/article-layout50/article-layout50.component';
import {ArticleSublayoutComponent} from '@features/spots/sublayout/article-sublayout/article-sublayout.component';
import {BasketSublayoutComponent} from '@features/spots/sublayout/basket-sublayout/basket-sublayout.component';
import {BrandlistSublayoutComponent} from '@features/spots/sublayout/brandlist-sublayout/brandlist-sublayout.component';
import {
    CategoryListSublayoutComponent
} from '@features/spots/sublayout/categorylist-sublayout/category-list-sublayout.component';
import {CustomerSublayoutComponent} from '@features/spots/sublayout/customer-sublayout/customer-sublayout.component';
import {FilterSublayoutComponent} from '@features/spots/sublayout/filter-sublayout/filter-sublayout.component';
import {
    GiftcardbalanceSublayoutComponent
} from '@features/spots/sublayout/giftcardbalance-sublayout/giftcardbalance-sublayout.component';
import {OrderReceiptComponent} from '@features/spots/sublayout/order-receipt/order-receipt/order-receipt.component';
import {ProductSublayoutComponent} from '@features/spots/sublayout/product-sublayout/product-sublayout.component';
import {SignupSublayoutComponent} from '@features/spots/sublayout/signup-sublayout/signup-sublayout.component';
import {StoreSublayoutComponent} from '@features/spots/sublayout/store-sublayout/store-sublayout.component';
import {Sublayout100Component} from '@features/spots/sublayout/sublayout100/sublayout100.component';
import {Sublayout25Component} from '@features/spots/sublayout/sublayout25/sublayout25.component';
import {Sublayout33Component} from '@features/spots/sublayout/sublayout33/sublayout33.component';
import {Sublayout50Component} from '@features/spots/sublayout/sublayout50/sublayout50.component';
import {SublayoutLargeSmall} from '@features/spots/sublayout/sublayout-large-small/sublayout-large-small.component';
import {TextSpotComponent} from '@features/spots/text-spot/text-spot/text-spot.component';
import {TrustPilotSpotComponent} from '@features/spots/trust-pilot-spot/trust-pilot-spot.component';
import {UserOptOutComponent} from '@features/spots/user-opt-out/user-opt-out.component';
import {VideoSpotComponent} from '@features/spots/video-spot/video-spot.component';
import {LoginSpotComponent} from '@features/spots/login-spot/login-spot.component';
import {CheckoutSublayoutComponent} from '@features/spots/sublayout/checkout-sublayout/checkout-sublayout.component';
import {
    Sublayout50LargeSmallComponent
} from '@features/spots/sublayout/sublayout50LargeSmall/sublayout50LargeSmall.component';
import {
    SublayoutSearchResultZeroComponent
} from '@features/spots/sublayout/sublayout-search-result-zero/sublayoutSearchResultZero.component';
import {Sublayout204040Component} from '@features/spots/sublayout/sublayout20-40-40/sublayout20-40-40.component';
import {
    FavoriteBrandTextSpotComponent
} from '@features/spots/favorite-brand-text-spot/favorite-brand-text-spot.component';
import {
    ArticleReferenceListTypeSwitchComponent
} from '@features/spots/article/article-reference-list-spot/article-reference-list-type-switch.component';
import {
    ArticleReferenceListSpotComponent
} from '@features/spots/article/article-reference-list-spot/article-reference-list-spot.component';
import {
    ArticleReferenceListCarouselSpotComponent
} from '@features/spots/article/article-reference-list-carousel-spot/article-reference-list-carousel-spot.component';
import { Made2MeasureComponent } from '@features/spots/made-2-measure/made-2-measure.component';

export const spots = [
    Sublayout100Component,
    Sublayout25Component,
    Sublayout33Component,
    Sublayout50Component,
    Sublayout50LargeSmallComponent,
    Sublayout204040Component,
    SublayoutLargeSmall,
    ReferenceSpotComponent,
    HeroSpotComponent,
    ArticleSublayoutComponent,
    ArticleHeaderSpotComponent,
    ArticleImageSpotComponent,
    ArticleTextSpotComponent,
    ProductSliderComponent,
    ArticleReferenceSpotComponent,
    ProductFilterSpotComponent,
    CustomerSublayoutComponent,
    FilterSublayoutComponent,
    TextSpotComponent,
    SignupSublayoutComponent,
    StoreSublayoutComponent,
    ProductDetailComponent,
    ProductSublayoutComponent,
    StoreSpotComponent,
    CheckoutSublayoutComponent,
    TrustPilotSpotComponent,
    OrderReceiptComponent,
    OrderReceiptSpotComponent,
    BrandlistSublayoutComponent,
    NewsletterSignupSpotComponent,
    BrandlistComponent,
    CategorylistComponent,
    CategoryListSublayoutComponent,
    BasketSublayoutComponent,
    ArticleLayout50Component,
    ArticleLayout100Component,
    GiftcardbalanceSublayoutComponent,
    UserOptOutComponent,
    FavoriteBrandSpotComponent,
    VideoSpotComponent,
    LoginSpotComponent,
    SublayoutSearchResultZeroComponent,
    FavoriteBrandTextSpotComponent,
    ArticleReferenceListTypeSwitchComponent,
    ArticleReferenceListSpotComponent,
    ArticleReferenceListCarouselSpotComponent,
    Made2MeasureComponent,
];
