import { DomSanitizer, Meta, MetaDefinition, SafeUrl, Title } from '@angular/platform-browser';
import { Inject, Injectable, PLATFORM_ID, Sanitizer, SecurityContext } from '@angular/core';
import { SeoOptions } from './SeoOptions.interface';
import { SiteContextService } from '@core/site-context.service';
import { ISiteContext } from '@core/site-context.interface';
import { LinkTag, LinkTagService } from '@core/link-tag.service';

/**
 * Adds the correct meta and title tags from the page content
 */
@Injectable()
export class SeoService {
    siteContext: ISiteContext;
    siteName: string;

    /**
     * Injects dependencies
     * @param {Title} title
     * @param {Meta} meta
     * @param platformId
     * @param siteContextService
     */
    constructor(
        private title: Title,
        private meta: Meta,
        @Inject(PLATFORM_ID) private platformId: object,
        private siteContextService: SiteContextService,
        private linkTagService: LinkTagService,
    ) {
        this.siteContext = siteContextService.getContext();
        switch (this.siteContext.businessDimension.toLowerCase()) {
            case 'kaufmann':
                this.siteName = 'KAUFMANN';
                break;
            case 'quint':
                this.siteName = 'qUINT';
                break;
            case 'axel':
                this.siteName = 'AXEL';
                break;
        }
    }

    /**
     * Sets the meta tags and the title of the page
     * @param {SeoOptions} options
     */
    setTags(options: any) {
        const tags = [];

        // Site name
        tags.push({
            property: 'og:site_name',
            content: this.siteName
        });
        // Title
        if (options.Title) {
            const title = `${options.Title}`;
            this.title.setTitle(title);

            tags.push({
                itemprop: 'name',
                content: title,
            });

            tags.push({
                name: 'twitter:title',
                content: title,
            });

            tags.push({
                property: 'og:title',
                content: title,
            });
        }

        if (
            options.ProductData &&
            options.ProductData.PageTitle &&
            !options.Title
        ) {
            const title = options.ProductData.PageTitle;

            this.title.setTitle(title);

            tags.push({
                itemprop: 'name',
                content: title,
            });

            tags.push({
                name: 'twitter:title',
                content: title,
            });

            tags.push({
                property: 'og:title',
                content: title,
            });
        }

        /*if (isPlatformBrowser(this.platformId)) {
            return;
        }*/

        if (options.NoIndex || options.NoFollow) {
            tags.push({
                name: 'robots',
                content: this.noIndexNoFollow(
                    options.NoIndex,
                    options.NoFollow
                ),
            });
        }

        // Description
        if (options.Description) {
            tags.push({
                name: 'description',
                content: options.Description,
            });

            tags.push({
                itemprop: 'description',
                content: options.Description,
            });

            tags.push({
                name: 'twitter:description',
                content: options.Description,
            });

            tags.push({
                property: 'og:description',
                content: options.Description,
            });
        }

        if (
            !options.Description &&
            options.ProductData &&
            options.ProductData.Description
        ) {
            tags.push({
                name: 'description',
                content: options.ProductData.Description,
            });

            tags.push({
                itemprop: 'description',
                content: options.ProductData.Description,
            });

            tags.push({
                name: 'twitter:description',
                content: options.ProductData.Description,
            });

            tags.push({
                property: 'og:description',
                content: options.ProductData.Description,
            });
        }

        // Image (And twitter card)
        if (options.FeaturedImage) {
            tags.push({
                name: 'twitter:image',
                content: options.FeaturedImage + '&w=1000&h=500',
            });

            tags.push({
                itemprop: 'image',
                content: options.FeaturedImage + '&w=800',
            });

            tags.push({
                property: 'og:image',
                content: options.FeaturedImage + '&w=800',
            });

            tags.push({
                name: 'twitter:card',
                content: 'summary_large_image',
            });
        }

        // Image (And twitter card)
        if (
            options.ProductData &&
            options.ProductData.FeaturedProductImage &&
            !options.FeaturedImage
        ) {
            tags.push({
                name: 'twitter:image',
                content: options.ProductData.FeaturedProductImage + '&w=1000&h=500',
            });

            tags.push({
                itemprop: 'image',
                content: options.ProductData.FeaturedProductImage + '&w=800',
            });

            tags.push({
                property: 'og:image',
                content: options.ProductData.FeaturedProductImage + '&w=800',
            });

            tags.push({
                name: 'twitter:card',
                content: 'summary_large_image',
            });
        }

        if (
            !options.FeaturedImage &&
            ((options.ProductData && !options.ProductData.FeaturedImage) ||
                !options.ProductData)
        ) {
            tags.push({
                name: 'twitter:card',
                content: 'summary',
            });
        }

        // Url
        if (options.CanonicalUrl) {
            tags.push({
                property: 'og:url',
                content: options.CanonicalUrl,
            });
        }

        // Type
        if (options.CanonicalUrl.match(/\w+\/the-journal\/\w+/ig)) {
            tags.push({
                property: 'og:type',
                content: 'article',
            });
        } else if (options.TemplateName === 'Product page') {
            tags.push({
                property: 'og:type',
                content: 'product',
            });
        } else {
            tags.push({
                property: 'og:type',
                content: 'website',
            });
        }

        /**
         * Product information
         */
        if (options.ProductData) {
            // Brand
            if (options.ProductData.Brand) {
                tags.push({
                    property: 'og:product:brand',
                    content: options.ProductData.Brand,
                });
            }

            // Category
            if (options.ProductData.Category) {
                tags.push({
                    property: 'og:product:category',
                    content: options.ProductData.Category,
                });
            }

            // Color
            if (options.ProductData.Color) {
                tags.push({
                    property: 'og:product:color',
                    content: options.ProductData.Color,
                });
            }

            // Size
            if (options.ProductData.Size) {
                tags.push({
                    property: 'og:product:size',
                    content: options.ProductData.Size,
                });
            }

            // Prices
            if (
                options.ProductData.OriginalPrice &&
                options.ProductData.Currency
            ) {
                tags.push({
                    property: 'og:product:original_price:amount',
                    content: options.ProductData.OriginalPrice,
                });

                tags.push({
                    property: 'og:product:original_price:currency',
                    content: options.ProductData.Currency,
                });
            }

            if (
                options.ProductData.CurrentPrice &&
                options.ProductData.Currency
            ) {
                tags.push({
                    property: 'og:product:price:amount',
                    content: options.ProductData.CurrentPrice,
                });

                tags.push({
                    property: 'og:product:price:currency',
                    content: options.ProductData.Currency,
                });
            }

            if (
                options.ProductData.Currency &&
                options.ProductData.OriginalPrice &&
                options.ProductData.CurrentPrice &&
                options.ProductData.OriginalPrice !==
                    options.ProductData.CurrentPrice
            ) {
                tags.push({
                    property: 'og:product:sale_price:amount',
                    content: options.ProductData.CurrentPrice,
                });

                tags.push({
                    property: 'og:product:sale_price:currency',
                    content: options.ProductData.Currency,
                });
            }
        }

        this.meta.addTags(tags);
    }
    setCanonicalsForPagination(pageData: any, pageIndex: number, lastPageIndex: number): void {
        const tags: LinkTag[] = [];
        if (pageIndex === 0) {
            tags.push({
                rel: 'canonical',
                href: pageData.CanonicalUrl
            });
            tags.push({
                rel: 'next',
                href: `${pageData.CanonicalUrl}?pageIndex=${pageIndex + 1}`
            });
        }
        if (pageIndex > 0 && pageIndex < lastPageIndex) {
            tags.push({
                rel: 'canonical',
                href: `${pageData.CanonicalUrl}?pageIndex=${pageIndex}`
            });
            tags.push({
                rel: 'next',
                href: `${pageData.CanonicalUrl}?pageIndex=${pageIndex + 1}`
            });
            tags.push({
                rel: 'prev',
                href: pageIndex - 1 === 0 ? pageData.CanonicalUrl : `${pageData.CanonicalUrl}?pageIndex=${pageIndex - 1}`
            });
        }
        if (pageIndex === lastPageIndex) {
            tags.push({
                rel: 'canonical',
                href: `${pageData.CanonicalUrl}?pageIndex=${lastPageIndex}`
            });
            if (lastPageIndex - 1 === 0) {
                tags.push({
                    rel: 'prev',
                    href: `${pageData.CanonicalUrl}`
                });
            } else {
                tags.push({
                    rel: 'prev',
                    href: `${pageData.CanonicalUrl}?pageIndex=${pageIndex - 1}`
                });
            }
        }
        this.linkTagService.setLinkTags(tags);
    }

    private noIndexNoFollow(noIndex: boolean, noFollow: boolean): string {
        const noIndexStr = 'noindex';
        const noFollowStr = 'nofollow';
        let outputStr = '';

        if (noIndex) {
            outputStr += noIndexStr;
        }

        if (noFollow) {
            if (noIndex) {
                outputStr += ',' + noFollowStr;
            } else {
                outputStr += noFollowStr;
            }
        }
        return outputStr;
    }
}
