import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { PaneService } from '@core/pane.service';
import { PersonalizationBarService } from '@core/personalization-bar.service';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
    constructor(
        public overlayRef: ImpactOverlayRef,
        private router: Router,
        private paneService: PaneService,
        private translationService: TranslationService,
    ) {}

    private destroy = new Subject();
    translations: any;

    // Trigger animation
    animationStateChanged = new EventEmitter<AnimationEvent>();
    startExitTrigger = new Subject();

    ngOnInit() {
        this.router.events
            .pipe(takeUntil(this.destroy))
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(data => {
                this.overlayRef.close();
            });
        this.translations = this.translationService.translations$;
    }

    onPaneClose() {
        this.overlayRef.close();
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    doSomething(event) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }
}
