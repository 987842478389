import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IMPACT_OVERLAY_DATA, ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { Router } from '@angular/router';

import { close } from '@shared/svg';
import { infoPageAnimations } from '@features/info/info-page.animations';
import { StickyService } from '@core/sticky.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-info-page',
    animations: infoPageAnimations,
    template: `
        <section class="info-page" [@slideContent]="animationState" (@slideContent.start)="onAnimationStart($event)"
                 (@slideContent.done)="onAnimationDone($event)" #infopage>
            <button type="button" class="info-page_close" (click)="closeDialog()">${close}</button>
            <div class="info-page_content">
                <app-info-page-content *ngIf="dataHolder" [data]="dataHolder"></app-info-page-content>
            </div>
        </section>`,
})
export class InfoPageComponent implements OnInit, OnDestroy {
    animationState: 'void' | 'enter' | 'leave' = 'enter';
    animationStateChanged = new EventEmitter<AnimationEvent>();

    dataHolder: any;
    @ViewChild('infopage', { static: true }) infoElement;
    private destroy;

    constructor(
        @Inject(IMPACT_OVERLAY_DATA) public data: any,
        public overlayRef: ImpactOverlayRef,
        private router: Router,
        @Inject(DOCUMENT) private _document,
        private stickyService: StickyService
    ) {}

    ngOnInit() {
        this.dataHolder = this.data;

        this.destroy = new Subject();
        this.stickyService.stickyEvent
            .pipe(takeUntil(this.destroy))
            .subscribe(event => {
                this.setPosition(event.headerBottom);
            });
    }

    onAnimationStart(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    onAnimationDone(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.animationState = 'leave';
    }

    closeDialog() {
        this.overlayRef.close();
        this.router.navigate([{ outlets: { info: null } }], {
            queryParamsHandling: 'preserve',
        });
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    setPosition(headerBottom) {
        const headerHeight = headerBottom + 40;
        let paneMargin = headerHeight + 'px';

        this.infoElement.nativeElement.style.marginTop = paneMargin;
        this.infoElement.nativeElement.style.height = `calc(100% - ${paneMargin}`;
    }
}
