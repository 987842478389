<article intercept-links appShopByImageTitleHoverPos (inView)="onInView($event)" class="spot-reference"
         [ngClass]="{
         'is-hidden-mobile': data.HideOnMobile,
         'is-hidden-tablet': data.HideOnDesktopTablet,
         'spot-reference_no-text': !noText,
         'shop-by-marker_show': inView,
         'hide-spot-content':!displaySpotContent
         }"
         *ngIf="data.Link && !hideFromSearchEngines"
         id="{{data.Id}}">
    <a [href]="data.Link" [target]="data.LinkTarget ? data.LinkTarget : '_self'" [rel]="data.LinkTarget ? 'noopener' : ''" class="spot-reference__link" (click)="trackClick()">
        <div class="spot-reference__image-wrapper">
            <img
                [src]="data.Image + '?w=' + (1680 / imageSizeDivider)"
                [srcset]="data.Image + '?w=' + 480 + ' 480w, ' + data.Image + '?w=' + 768 + ' 768w, ' + data.Image + '?w=' + 1024  / imageSizeDivider + ' 1024w, ' + data.Image + '?w=' + 1680  / imageSizeDivider + ' 1680w'"
                sizes="(max-width: 480px) 480px, (min-width: 481px) and (max-width: 768px) 768px, (min-width: 769px) and (max-width: 1024px) 1024px, (min-width: 1025px) 1680px"
                [alt]="data.ImageAltText ? data.ImageAltText : imagePathName(data.Image)"
                [width]="data.ImageWidth"
                [height]="data.ImageHeight"
                loading="lazy"
            />
            <!--<LazyPicture
                [LazyLoadSrc]="data.Image + '?w=' + (1680 / imageSizeDivider)"
                [LazyLoadSources]="[
                {media: '(max-width: 480px)', srcSet: data.Image + '?w=' + 480},
                {media: '(min-width: 481px) and (max-width: 768px)', srcSet: data.Image + '?w=' + 768},
                {media: '(min-width: 769px) and (max-width: 1024px)', srcSet: data.Image + '?w=' + 1024  / imageSizeDivider},
                {media: '(min-width: 1025px)', srcSet: data.Image + '?w=' + 1680  / imageSizeDivider}
                ]"
                [LazyImageWidth]="data.ImageWidth"
                [LazyImageHeight]="data.ImageHeight"
                [LazyLoadAlt]="data.ImageAltText ? data.ImageAltText : imagePathName(data.Image)"
            ></LazyPicture>-->
            <button *ngFor="let marker of data.Markers" appShopByImageHoverClass type="button" class="shop-by-button" [ngStyle]="{'left':marker.XPos + '%', 'top': marker.YPos + '%'}" (click)="showProductInRightPane($event, marker)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <path d="M25.83 10.67a15.5 15.5 0 1 1-15.5 15.5 15.51 15.51 0 0 1 15.5-15.5m0-2a17.5 17.5 0 1 0 17.5 17.5 17.5 17.5 0 0 0-17.5-17.5z" class="shop-by-marker-circle" [ngStyle]="{'fill': '#' + marker.MarkerColor, 'stroke': '#' + marker.MarkerColor}"/>
                    <path d="M25.83 34.17v-16M17.83 26.17h16" class="shop-by-marker-cross" [ngStyle]="{'fill': '#' + marker.MarkerColor, 'stroke': '#' + marker.MarkerColor}"/>
                </svg>
                <div class="shop-by-button__hover-title is-hidden-touch">{{marker.Brand}} {{marker.Category}}</div>
            </button>
        </div>
        <div
            class="spot-reference__body"
            *ngIf="noText"
            #bodyContainer
        >
            <app-svg [src]="rightArrow" [size]="'large'"></app-svg>
            <app-spot-heading-seo
                [headingClass]="'spot-reference__heading'"
                [hTag]="data.HeadingSeoImportance"
                [headingText]="data.Heading"
                *ngIf="data.Heading"
            ></app-spot-heading-seo>
            <div class="spot-reference__action" *ngIf="data.LinkText">
                <app-svg [src]="rightArrow" [size]="'normal'"></app-svg>
                <span class="spot-reference__text">{{data.LinkText}}</span>
            </div>
        </div>
    </a>
</article>
<article class="spot-reference" appShopByImageTitleHoverPos (inView)="onInView($event)"
         [ngClass]="{'hide-spot-content':!displaySpotContent, 'is-hidden-mobile': data.HideOnMobile, 'is-hidden-tablet': data.HideOnDesktopTablet, 'spot-reference_no-text': !noText, 'shop-by-marker_show': inView}"
         *ngIf="!data.Link && !hideFromSearchEngines"
         id="{{data.Id}}">
    <div class="spot-reference__image-wrapper">
        <!--<LazyPicture
            [LazyLoadSrc]="data.Image + '?w=' + (1680 / imageSizeDivider)"
            [LazyLoadSources]="[
                {media: '(max-width: 480px)', srcSet: data.Image + '?w=' + 480},
                {media: '(min-width: 481px) and (max-width: 768px)', srcSet: data.Image + '?w=' + 768},
                {media: '(min-width: 769px) and (max-width: 1024px)', srcSet: data.Image + '?w=' + 1024  / imageSizeDivider},
                {media: '(min-width: 1025px)', srcSet: data.Image + '?w=' + 1680  / imageSizeDivider}
                ]"
            [LazyImageWidth]="data.ImageWidth"
            [LazyImageHeight]="data.ImageHeight"
            [LazyLoadAlt]="data.ImageAltText ? data.ImageAltText : imagePathName(data.Image)"
        ></LazyPicture>-->
        <img
            [src]="data.Image + '?w=' + (1680 / imageSizeDivider)"
            [srcset]="data.Image + '?w=' + 480 + ' 480w, ' + data.Image + '?w=' + 768 + ' 768w, ' + data.Image + '?w=' + 1024  / imageSizeDivider + ' 1024w, ' + data.Image + '?w=' + 1680  / imageSizeDivider + ' 1680w'"
            sizes="(max-width: 480px) 480px, (min-width: 481px) and (max-width: 768px) 768px, (min-width: 769px) and (max-width: 1024px) 1024px, (min-width: 1025px) 1680px"
            [alt]="data.ImageAltText ? data.ImageAltText : imagePathName(data.Image)"
            [width]="data.ImageWidth"
            [height]="data.ImageHeight"
            loading="lazy"
        />
        <button *ngFor="let marker of data.Markers" appShopByImageHoverClass type="button" class="shop-by-button" [ngStyle]="{'left':marker.YPos + '%', 'top': marker.XPos + '%'}" (click)="showProductInRightPane($event, marker)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <path d="M25.83 10.67a15.5 15.5 0 1 1-15.5 15.5 15.51 15.51 0 0 1 15.5-15.5m0-2a17.5 17.5 0 1 0 17.5 17.5 17.5 17.5 0 0 0-17.5-17.5z" class="shop-by-marker-circle" [ngStyle]="{'fill': '#' + marker.MarkerColor, 'stroke': '#' + marker.MarkerColor}"/>
                <path d="M25.83 34.17v-16M17.83 26.17h16" class="shop-by-marker-cross" [ngStyle]="{'fill': '#' + marker.MarkerColor, 'stroke': '#' + marker.MarkerColor}"/>
            </svg>
            <div class="shop-by-button__hover-title is-hidden-touch">{{marker.Brand}} {{marker.Category}}</div>
        </button>
    </div>
    <div class="spot-reference__body" *ngIf="data.LinkText">
        <app-spot-heading-seo
            [headingClass]="'spot-reference__heading'"
            [hTag]="data.HeadingSeoImportance"
            [headingText]="data.Heading"
            *ngIf="data.Heading"
        ></app-spot-heading-seo>
    </div>
</article>
