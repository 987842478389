import { Component, ElementRef, HostBinding, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ArticleService } from '@features/article/article.service';
import { environment } from '../../../../../environments/environment';
import { SiteContextService } from '@core/site-context.service';
import { CookieService } from 'ngx-cookie';
import { PageService } from '@core/page.service';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { RaptorService } from '@core/raptor.service';
import {
    RaptorEventType,
    RaptorTrackingService,
} from '@core/tracking/raptor-tracking.service';
import { UserService } from '@core/user.service';
import { getContainerElement, getFilename } from '@shared/utility';
import { isPlatformBrowser } from '@angular/common';
import {arrowRight} from '@shared/svg';

@Component({
    selector: 'app-article-reference-list-spot',
    templateUrl: './article-reference-list-spot.component.html',
})
export class ArticleReferenceListSpotComponent implements OnInit {
    constructor(
        private articleService: ArticleService,
        private pageDataService: PageService,
        private siteContextService: SiteContextService,
        private cookieService: CookieService,
        private route: ActivatedRoute,
        private raptorService: RaptorService,
        private userService: UserService,
        private raptorTracking: RaptorTrackingService,
        @Inject(PLATFORM_ID) private platformId,
        private element: ElementRef
    ) {
        this.articleUniverseTitle = this.siteContextService.getContext().generalSiteSettings.ArticleUniverseTitle;
        this.articleReferenceSpotLinkToArticleText = this.siteContextService.getContext().generalSiteSettings.ArticleReferenceSpotLinkToArticleText;
    }

    static ref = '';

    @HostBinding('class.column') hostColumnClass = true;
    // @HostBinding('class.article') hostArticleClass = true;
    @Input() public data;

    articles: any[] = [];
    columnClass: string;
    pageData: any;
    imagePathName = getFilename;
    imageWidthSm = 480;
    imageWidthMd = 768;
    imageWidthLg = 1024;
    imageWidthXl = 1680;
    arrowRight = arrowRight;

    articleUniverseTitle: string;
    articleReferenceSpotLinkToArticleText: string;

    ngOnInit() {
        this.pageDataService.page.pipe(take(1)).subscribe(data => {
            this.pageData = data.pageData;

            let raptorCookieValue = '';
            if (isPlatformBrowser(this.platformId)) {
                raptorCookieValue = this.raptorService.getRaptorCookieValue();
            }
            const productId = this.route.snapshot.queryParams['id'];
            const pageId = this.pageData.Id;
            this.articleService
                .getArticlesBySpotId(
                    this.data.Id,
                    raptorCookieValue,
                    productId,
                    pageId
                )
                .subscribe(articles => {
                    this.setColumnClass(articles.length);
                    this.articles = [].concat(articles);

                    if (articles.length === 0) {
                        const containerElm = getContainerElement((this.element.nativeElement as HTMLElement), 'container');
                        containerElm.classList.add('container_no-elements');
                    }
                });
        });
    }

    raptorItemClick(article: any) {
        if (article && article.RaptorContentId) {
            const user = this.userService.currentUser.getValue();
            this.raptorTracking.trackContentEvent(
                RaptorEventType.ItemClick,
                article.RaptorContentId,
                article.RaptorContentTags,
                user
            );
        }
    }

    private setColumnClass(numberOfArticles: number): void {
        if (numberOfArticles <= 1) {
            this.imageWidthLg = 1024;
            this.imageWidthXl = 1680;
            this.columnClass = 'is-12-tablet is-12-desktop';
        } else if (numberOfArticles === 2) {
            this.imageWidthLg = 1024 / 2;
            this.imageWidthXl = 1680 / 2;
            this.columnClass = 'is-6-tablet is-6-desktop';
        } else if (numberOfArticles === 4) {
            this.imageWidthLg = 1024 / 2;
            this.imageWidthXl = 1680 / 4;
            this.columnClass = 'is-6-tablet is-3-desktop';
        } else {
            this.imageWidthLg = 1026 / 3;
            this.imageWidthXl = 1680 / 3;
            this.columnClass = 'is-4-tablet is-4-desktop';
        }
    }
}
