import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-axel-brand-tile-anim',
    template: `
        <div class="anim-wrapper">
            <app-svg [size]="'unset'" [src]="'../../assets/anim/axel/cyphers/1'"></app-svg>
            <app-svg [size]="'unset'" [src]="'../../assets/anim/axel/cyphers/2'"></app-svg>
            <app-svg [size]="'unset'" [src]="'../../assets/anim/axel/cyphers/3'"></app-svg>
            <app-svg [size]="'unset'" [src]="'../../assets/anim/axel/cyphers/4'"></app-svg>
            <app-svg [size]="'unset'" [src]="'../../assets/anim/axel/cyphers/5'"></app-svg>
            <app-svg [size]="'unset'" [src]="'../../assets/anim/axel/cyphers/6'"></app-svg>
            <app-svg [size]="'unset'" [src]="'../../assets/anim/axel/cyphers/7'"></app-svg>
        </div>
    `
})
export class AxelBrandTileAnimComponent {

    constructor() {
    }

}
