import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-sublayout25',
    templateUrl: './sublayout25.component.html',
})
export class Sublayout25Component {
    static ref = 'Sublayout25';

    @Input() public data;

    @HostBinding('class.is-hidden-mobile') get isHiddenMobile() {
        return !this.data.ShowOnMobile && this.data.ShowOnTabletAndDesktop;
    }

    @HostBinding('class.is-hidden-tablet') get isHiddenTabletDesktop() {
        return !this.data.ShowOnTabletAndDesktop && this.data.ShowOnMobile;
    }
}
