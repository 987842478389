import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-login-spot',
    templateUrl: './login-spot.component.html',
    styles: []
})
export class LoginSpotComponent implements OnInit {
    static ref = 'Login spot';
    @Input() public data;

    constructor() {
    }

    ngOnInit(): void {
    }

}
