import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-basket-sublayout',
    templateUrl: './basket-sublayout.component.html',
})
export class BasketSublayoutComponent {
    static ref = 'BasketSublayout';

    @Input() public data;
}
