import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Inject, InjectionToken,
    Input,
    OnInit,
    Optional,
    PLATFORM_ID, TemplateRef, ViewContainerRef,
} from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { goArrow } from '@shared/svg';
import { isPlatformServer } from '@angular/common';

@Component({
    selector: 'app-sublayout20-40-40',
    templateUrl: './sublayout20-40-40.component.html',
})
export class Sublayout204040Component implements OnInit, AfterViewInit {
    constructor(
        private elm: ElementRef,
        public translation: TranslationService,
        @Optional()
        @Inject('isBot')
        private isBot: boolean,
    ) {}

    static ref = 'Sublayout20_40_40';

    @Input() public data;

    @HostBinding('class.is-hidden-mobile') get isHiddenMobile() {
        return !this.data.ShowOnMobile && this.data.ShowOnTabletAndDesktop;
    }
    @HostBinding('class.is-hidden-tablet') get isHiddenTabletDesktop() {
        return !this.data.ShowOnTabletAndDesktop && this.data.ShowOnMobile;
    }

    public templateName: string;
    public mobileLayout: string;
    public collapseContentToggle: boolean;
    public nonCollapsedContentHeight: string;
    public icon = {
        goArrow
    };

    ngOnInit() {

        for (let i = 0; i < this.data.Column1Spots.length; i++) {
            if (this.data.Column1Spots[i].TemplateName) {
                this.templateName = this.data.Column1Spots[i].TemplateName;
                this.mobileLayout = this.data.Column1Spots[i].MobileLayout;
            }
        }
    }

    toggleCollapseContent() {
        this.nonCollapsedContentHeight = this.elm.nativeElement.firstElementChild.firstElementChild.firstElementChild.clientHeight;
        this.collapseContentToggle = !this.collapseContentToggle;
    }

    ngAfterViewInit(): void {
        if (!!this.data.BackgroundColor && this.data.CollapseContent) {
            this.elm.nativeElement.querySelector('.collapse-content').style.setProperty('--bg-color', this.data.BackgroundColor);
        }
    }
}
