import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UpsellingService} from '@core/upselling.service';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {
    ImpactCoreModelsProductsDtoColorGroupDto as ColorGroup,
    ImpactCoreModelsProductsDtoSkuDto as ProductSku,
    ImpactCoreModelsProductsDtoStyleDto as ProductStyle
} from '@shared/swagger/swagger.interface';
import {mouseWheel} from '@shared/svg';
import {TranslationService} from '@core/translation.service';
import {BasketService} from '@core/basket.service';
import {TrackingService} from '@core/tracking/tracking.service';

@Component({
    selector: 'app-minibasket-upselling',
    templateUrl: './minibasket-upselling.component.html',
    styles: []
})
export class MinibasketUpsellingComponent implements OnInit, OnDestroy {
    constructor(
        private upsellingService: UpsellingService,
        private basketService: BasketService,
        public translationService: TranslationService,
        private trackingService: TrackingService
    ) {
    }
    @Input() productImageBgColor: string;

    private destroy: Subject<any> = new Subject<any>();

    products: ProductStyle[];
    icons = {
        mouseWheel
    };

    ngOnInit(): void {
        this.upsellingService.getUpsellingProducts()
            .pipe(
                takeUntil(this.destroy)
            )
            .subscribe(products => {
                this.products = products;
            });
    }

    onUpdateBasket(product) {
        this.basketService.add(product.selectedSize.Id, product.product, 1)
            .subscribe(() => this.trackingService.sendAddUpsaleProductToBasket(product.selectedSize.Id));
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

}
