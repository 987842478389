import { Component, Input } from '@angular/core';
import {
    RaptorEventType,
    RaptorTrackingService,
} from '@core/tracking/raptor-tracking.service';
import { UserService } from '@core/user.service';
import { RaptorService } from '@core/raptor.service';

@Component({
    selector: 'app-article-sublayout',
    templateUrl: './article-sublayout.component.html',
})
export class ArticleSublayoutComponent {
    static ref = 'ArticleSublayout';

    @Input() public data;

    constructor(
        private raptorTracking: RaptorTrackingService,
        private userService: UserService,
        private raptorService: RaptorService
    ) {}

    ngOnInit() {
        if (this.data && this.data.RaptorContentId) {
            this.raptorService.RaptorContentId$.next(this.data.RaptorContentId);
            const user = this.userService.currentUser.getValue();
            this.raptorTracking.trackContentEvent(
                RaptorEventType.Visit,
                this.data.RaptorContentId,
                this.data.RaptorContentTags,
                user
            );
        }
    }
}
