<div class="container {{ templateName }} {{ mobileLayout }}" [ngClass]="{'container_to-edges': data.NoSidePadding}" *ngIf="show">
    <div class="sublayout"
        [ngClass]="{'sublayout_background': data.BackgroundColor, 'sublayout_shadow': data.HasShadow}"
        [style.backgroundColor]="data.BackgroundColor">
        <div class="columns">
            <div class="column">

                <article class="columns is-multiline">
                    <div *ngFor="let spot of data.Column1Spots" generic-spot [spotType]="spot.TemplateName" [spotData]="spot" [templateType]="data.TemplateName" [backgroundColor]="data.BackgroundColor" [hideCallback]="boundHide"></div>
                </article>

            </div>
        </div>
    </div>
</div>
