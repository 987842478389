import {Pipe, PipeTransform, Sanitizer} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
    name: 'insertLink',
})
export class InsertLinkPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(infoItems: string[] | string, urls: string[]): unknown {
        if (typeof infoItems === 'string' && infoItems.includes('##')) {
            return this.replaceWithLink(infoItems, urls);
        } if (Array.isArray(infoItems)) {
            return (infoItems as string[]).map((item: string) => {
                if (item.includes('##')) {
                    return this.replaceWithLink(item, urls);
                }
                return item;
            });
        } else {
            return infoItems;
        }
    }
    replaceWithLink(item: string, urls: string[]): SafeHtml {
        let str = item;
        const links = item
            .replace(/\r\n/g, '')
            .match(new RegExp(/##\d+##/g))
            .map(style => style.replace(new RegExp(/##/g), ''));
        links.forEach((link: string) => {
            const urlIndex = urls.findIndex(url => url.includes(link));
            const linkTag = `<a href="${urls[urlIndex]}" role="navigation">${link}</a>`;

            str = str.replace(`##${link}##`, linkTag);

        });
        return this.sanitizer.bypassSecurityTrustHtml(str);
    }
}
