import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@features/find-store/models/store.model';

@Component({
    selector: 'app-store-info',
    templateUrl: './store-info.component.html',
})
export class StoreInfoComponent implements OnInit {
    @Input() selectedStore: Store;

    constructor() {}

    ngOnInit() {}
}
