import { isPlatformServer, Location } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ISiteContext } from '@core/site-context.interface';
import { environment } from 'environments/environment';

export enum BusinessDimensions {
    KAUFMANN = 'Kaufmann',
    AXEL = 'Axel',
    QUINT = 'Quint',
}

const CONTEXT_KEY = makeStateKey('context');

@Injectable()
export class SiteContextService {
    /**
     * Business Dimension ID
     * Can be: `kaufmann` | `quint` | `qnts` | `axel`
     */
    businessDimension: string;

    host: string;
    forwardOrigin: string;
    origin: string;
    localHost: string;
    language: string;

    appReady = false;

    /**
     * Hold the context object
     */
    context: ISiteContext;
    rootUrl: string;
    proxyBusinessDimension: string = '';

    constructor(
        private injector: Injector,
        @Inject(PLATFORM_ID) private platformId: object,
        private location: Location,
        private state: TransferState
    ) {
        if (isPlatformServer(this.platformId)) {
            this.host = this.injector.get('ForwardedHost');
            this.forwardOrigin = this.injector.get('ForwardedOrigin');
            this.origin = this.injector.get('ForwardedOrigin');
            this.localHost = this.injector.get('LocalHost');
            this.language = this.injector.get('language');
            this.businessDimension = this.injector.get('businessDimension');
        } else {
            this.host = document.location.hostname;
            this.origin = document.location.origin;
        }

        const hostname = this.host ? this.host : 'kaufmann.dk';
        const path = isPlatformServer(this.platformId)
            ? this.location.path()
            : document.location.pathname;
        let context = this.state.get(CONTEXT_KEY, null as any);

        if (!context) {
            context = this.contextResolver(hostname, path);
            this.setContext(context);
        } else {
            this.setContext(context);
            this.businessDimension = context.businessDimension;
        }

        this.setRootUrl(this.origin);
    }

    setContext(context: ISiteContext) {
        if (isPlatformServer(this.platformId)) {
            this.state.set(CONTEXT_KEY, context as any);
        }

        this.context = context;
    }

    getContext(): ISiteContext {
        return this.context;
    }

    setRootUrl(url: string) {
        this.rootUrl = url;
    }

    getRootUrl() {
        if (environment.dev) {
            this.proxyBusinessDimension =
                '/' +
                (this.businessDimension
                    ? this.businessDimension.toLowerCase()
                    : this.getContext()?.businessDimension?.toLowerCase());
        }

        if (environment.local && environment.localSSR) {
            return this.rootUrl;
        }
        return environment.local && !environment.localSSR
            ? this.rootUrl + this.proxyBusinessDimension
            : this.rootUrl;
    }

    /**
     * Resolves which business dimension and language by looking at the hostname and path.
     * @param {string} hostname
     * @param {string} path
     * @returns {ISiteContext}
     */
    contextResolver(hostname: string, path: string): ISiteContext {
        const domain: string = hostname.replace(/www.|-store|-shop/gi, '');

        const businessDimension: string = domain.substring(
            0,
            domain.indexOf('.')
        );

        const matchedLang: string[] | null = path.match(
            /^\/de\/|\/de$|\/en\/|\/en$|\/se\/|\/se$/i
        );

        const language: string = matchedLang
            ? matchedLang[0].replace(/\//g, '')
            : 'da';

        const contextObj: ISiteContext = {
            businessDimension: this.businessDimension
                ? this.businessDimension
                : this.capitalizeFirstLetter(businessDimension),
            language: this.language ? this.language : language,
            cookieBox: { Content: '' },
            countrySelectorList: [],
        };

        return contextObj;
    }

    /**
     *
     * @param {string} string
     * @returns {string}
     */
    capitalizeFirstLetter(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
