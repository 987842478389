import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

/**
 * Helper pipe to bypass security for innerHtml, used with wysiwyg, and inline SVG mainly.
 *
 * ## **Use with caution**
 * It may opens up for cross site scripting exploits.
 */
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  /**
   * Injects our dependencies
   * @param {DomSanitizer} sanitized
   */
  constructor(private sanitized: DomSanitizer) {}

  /**
   * Returns the bypassed raw value
   * @param value
   * @returns {SafeHtml}
   */
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
