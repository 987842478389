import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageService } from '@core/page.service';
import { PaneService } from '@core/pane.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'customer-page',
    template: `
        <ng-container *noSSR>
            <div class="headline">
                <div class="headline__header">
                    <div class="container container_padded">
                        <div class="headline__container">
                            <app-logout></app-logout>
                            <h1 class="headline__heading">{{pageContent?.pageData?.Headline}}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <spots [spots]="data"></spots>
        </ng-container>
    `,
})
export class CustomerPageComponent implements OnInit, OnDestroy {
    static ref = 'CustomerPage';

    @Input() public data;
    @Input() public showFooter = true;

    pageContent: any = {};

    constructor(
        private pageService: PageService,
        private paneService: PaneService
    ) {}

    ngOnInit() {
        this.pageService.page.pipe(take(1)).subscribe(data => {
            this.pageContent = data;
        });

        this.paneService.setActiveKey('login');
    }

    public ngOnDestroy(): void {
        this.paneService.setActiveKey(null);
    }
}
