import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// tslint:disable-next-line:no-duplicate-imports
import { throwError } from 'rxjs';
import { RAVEN } from '../tokens';
import { BasketService } from '@core/basket.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse, caught) => {
                if (err.error instanceof Error) {
                    console.warn('An error occurred:', err.error.message);
                } else {
                    console.warn(
                        `Backend returned code ${err.status}, body was:`,
                        request.url,
                        JSON.stringify(err.error)
                    );
                    if (err.status === 500) {
                        const extra: any = {};
                        if (isPlatformBrowser(this.platform_id)) {
                            const raptorId = (window as any).raptor
                                ? (window as any).raptor.cookieId
                                : '';

                            extra.RavenId = raptorId;
                            extra.platform = 'browser';
                        } else {
                            extra.platform = 'server';
                        }

                        /*if (!request.url.includes('/webapi/User/GetUserName')) {
                            this.Raven.captureException(err, { extra });
                        }*/
                    }
                }

                if (
                    err.status === 404 &&
                    err.url.indexOf('getasjson=1') !== -1
                ) {
                    return throwError(err);
                }

                if (err.status === 400 || err.status === 409) {
                    return throwError(err);
                }

                return EMPTY;
            })
        );
    }

    constructor(
        // @Inject(RAVEN) private Raven,
        @Inject(PLATFORM_ID) private platform_id
    ) {}
}
