import { AuthenticationModule } from '@features/authentication/authentication.module';
import { NgModule } from '@angular/core';

import { PageComponent } from './page.component';

import { PageResolve } from './page.resolve';

import { SpotsModule } from '../spots/spots.module';

import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { pageComponents } from '@features/pages/pages';
import { FooterModule } from '@features/footer/footer.module';
import { FindStoreModule } from '@features/find-store/find-store.module';
import { ProductPageModule } from '@features/product-page/product-page.module';
import { DialogPageResolve } from '@features/pages/dialog.resolve';
import { XdbTrackingService } from '@core/tracking/xdb-tracking.service';
import { PersonalizationModule } from '@features/personalization/personalization.module';

export { PageComponent } from './page.component';

@NgModule({
    declarations: [PageComponent, ...pageComponents],
    imports: [
        SharedModule,
        SpotsModule,
        RouterModule,
        FooterModule,
        AuthenticationModule,
        FindStoreModule,
        ProductPageModule,
        PersonalizationModule,
    ],
    providers: [PageResolve, DialogPageResolve, XdbTrackingService],
    entryComponents: [pageComponents],
    exports: [PageComponent],
})
export class PagesModule {}
