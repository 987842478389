import {Injectable, OnDestroy} from '@angular/core';
import {MediaMatcher, BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResponsiveService implements OnDestroy {

    destroy = new Subject();

    constructor(
        private mediaMatcher: MediaMatcher,
        private breakpointObserver: BreakpointObserver,
    ) {
    }
    mediaDesktopAndLarger(): boolean {
        return this.mediaMatcher.matchMedia('(min-width: 1221px)').matches;
    }
    mediaMobile(): Observable<BreakpointState> {
        return this.breakpointObserver.observe('(max-width: 768px)').pipe(takeUntil(this.destroy));
    }
    mediaSmallMobile(): Observable<BreakpointState> {
        return this.breakpointObserver.observe('(max-width: 440px)').pipe(takeUntil(this.destroy));
    }
    ngOnDestroy() {
        this.destroy.complete();
        this.destroy.unsubscribe();
    }
}
