import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-article-layout50',
    templateUrl: './article-layout50.component.html',
})
export class ArticleLayout50Component {
    static ref = 'ArticleLayout50';

    @Input()
    public data;
}
