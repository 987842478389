<app-rightpane (animationStateChanged)='animationStateChange($event)' [startExit]='startExitTrigger' *noSSR>
    <div class='rightpane__content'>
        <div class='filter-pane'>
            <div class='filter-pane__facets'>
                <ul class='filter-pane__list'>
                    <ng-container *ngFor='let facet of facets; trackBy: trackByFacet; let i = index;'>

                        <li class='filter-pane__item filter-pane__item_border'
                            *ngIf="facet.kind === 'Multicheck' || facet.kind === 'Slider'">
                            <button
                                type='button'
                                class='filter-pane__toggler'
                                (click)='toggleFacets(i)'
                                *ngIf="facet.kind === 'Slider'"
                            >
                                <ng-container *ngTemplateOutlet='buttonContent'></ng-container>
                            </button>
                            <button
                                type='button'
                                class='filter-pane__toggler'
                                (click)='openFacetsPane(facet.key)'
                                *ngIf="facet.kind !== 'Slider'"
                            >
                                <ng-container *ngTemplateOutlet='buttonContent'></ng-container>
                            </button>
                            <ng-template #buttonContent>
                                <span class='filter-pane__content'>
                                    <span class='filter-pane__heading'>{{translations?.Filter?.Facets[facet.name]}}</span>
                                    <span class='filter-pane__active-facets'>
                                        <ng-container *ngFor='let activeFacet of activeFacets'>
                                            <span class='filter-pane__active-facet'
                                                *ngIf='activeFacet.facet.key === facet.key'>{{activeFacet.child.name}}</span>
                                        </ng-container>
                                    </span>
                                </span>
                                <svg #plus class='filter-pane__icon svg-plus' viewBox='0 0 14 9' aria-hidden='true'
                                     [ngClass]="{
                                        'filter-pane__expand_active': activeCollection[i],
                                        'filter-pane__expand': facet.kind === 'Slider'
                                     }">
                                    <path d='M1 .57l6 7.5 6-7.5' stroke='#979797' fill='none' fill-rule='evenodd' />
                                </svg>
                            </ng-template>

                            <app-mobile-facet-left-pane [id]="facet.key" *ngIf="facet.kind !== 'Slider'">
                                <div class="filter-pane__facet-search" *ngIf="facet.key === 'brand'">
                                    <app-svg class="facet-search__icon" [src]='icons.search' [size]="'unset'"></app-svg>
                                    <button (click)="clearFilterFacetValue()" class="facet-search__icon facet-search__icon_pos-right"><app-svg [src]='icons.cross' [size]="'unset'"></app-svg></button>
                                    <input
                                        type='text'
                                        class="facet-search__input"
                                        [placeholder]="translations?.Filter?.MobileFilterPane?.FilterFacetsPlaceholderText | stringFormat: facet.name.toLowerCase()"
                                        (keyup)="filterFacets($event)"
                                        #filterFacetInput
                                    />
                                </div>
                                <ul class='filter-pane__list'
                                    *ngIf="facet.kind === 'Multicheck'">
                                    <li class='filter-pane__item' [ngClass]="{'filter-pane__item_sub': option.isSubFacet}" *ngFor='let option of facet.children | filterFacets: [filterFacetValue, facet.key]'>
                                        <div class='checkbox'>
                                            <input type='checkbox' #input
                                                   [id]='option.id'
                                                   [checked]='option.isActive'
                                                   [disabled]='!option.count'
                                                   (change)='onItemChange(facet, option, input.checked)' />
                                            <label class='checkbox__label'
                                                   [ngClass]="{'dot-color': Array.isArray(option.hexColor) && option.hexColor.length}"
                                                   [for]='option.id'>
                                                <ng-container *ngIf='!option.hexColor'>
                                                    <svg #checkmark class='svg-checkmark' viewBox='0 0 10.5 8.6'>
                                                        <path fill='#3D3D3D' d='M4.1 8.6L0 4.4l.7-.7 3.4 3.4L9.7 0l.8.6z' />
                                                    </svg>
                                                </ng-container>
                                                <span *ngIf='!!option.hexColor'
                                                      [style.--dot-color]="option.hexColor.length === 1 ? option.hexColor[0] : '#333'"
                                                      [style.--dot-border-color]="option.hexColor.length === 1 ? option.hexColor[0].includes('#ffffff') ? '#333' : option.hexColor[0] : '#333'"
                                                      [style.--dot-gradient-color]="option.hexColor.length > 1 ? option.hexColor.join(', ') : null"
                                                      [ngClass]="{
                                                        'dot-color-item_checked': option.isActive,
                                                        'dot-color-item_white': option.hexColor[0].includes('#ffffff'),
                                                        'dot-color-item_gradient': option.hexColor?.length > 1
                                                      }"
                                                      class='dot-color-item'
                                                ></span>
                                                <span class='checkbox__name'
                                                      *ngIf="facet.key === 'PriceGroup'; else normalName">{{translations?.Filter.PriceGroup[option.key]}}</span>
                                                <ng-template #normalName>
                                                    <span class='checkbox__name'>{{option.name | xlReformat}}</span>
                                                </ng-template>
                                                <span class='checkbox__count'>({{option.count}})</span>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </app-mobile-facet-left-pane>
                            <div
                                class='filter-pane__list filter-pane__list_spacing' [ngClass]="{'ngx-slider_hidden': !activeCollection[i]}" [@slideInOut]='activeCollection[i] || false'
                                 *ngIf="facet.kind === 'Slider'">
                                <ngx-slider
                                    #slider
                                    [(value)]="minValue"
                                    [(highValue)]="maxValue"
                                    [options]="options"
                                    (userChange)="onSliderChange(facet, $event)"
                                ></ngx-slider>
                                <!--<app-product-filter-slider
                                    [facet]='facet'
                                    (onChange)='sliderChange(facet, $event)'>
                                </app-product-filter-slider>-->
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
    <div class='rightpane-container__footer filter-pane__footer'>
        <button
            class='button button_faded-inverted'
            (click)='resetAllFilters()'
        >{{translations?.Filter?.Content?.RemoveAllFilters}}</button>
        <button
            class='button button_primary'
            (click)='closeFilterPane()'
        >{{translations?.Filter?.MobileFilterPane?.CloseAndSeeProducts | stringFormat: productsFound}}</button>
    </div>

</app-rightpane>
