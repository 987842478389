import { Component, Input } from '@angular/core';

@Component({
    selector: 'basket-page',
    template: `
        <spots [spots]="data"></spots>
        <app-footer *ngIf="showFooter"></app-footer>
    `,
})
export class BasketPageComponent {
    constructor() {}

    static ref = 'BasketPage';

    @Input() public data;

    @Input() public showFooter = true;
}
