import { takeUntil } from 'rxjs/operators';
import { Component } from '@angular/core';
import { CustomerService } from '@features/customer/customer.service';
import { TranslationService } from '@core/translation.service';
import { UserService } from '@core/user.service';
import { Subject } from 'rxjs';
import { OverlayService } from '@impactdk/ngx-overlay';
import { ReceiptComponent } from '@features/receipt/receipt/receipt.component';
import { ReceiptService } from '@core/receipt.service';

@Component({
    selector: 'app-customer-receipts',
    templateUrl: './customer-receipts.component.html',
})
export class CustomerReceiptsComponent {
    constructor(
        private userService: UserService,
        private overlay: OverlayService,
        private receiptService: ReceiptService,
        public customerService: CustomerService,
        public translationService: TranslationService
    ) {
        this.userService.currentUser$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data.UserName) {
                    this.customerService.fetchReceipts({
                        UserName: data.UserName,
                    });

                    this.unsubscribe.next();
                    this.unsubscribe.complete();
                }
            });
    }

    private unsubscribe = new Subject();

    public onShowReceipt(id) {
        this.receiptService.RetreiveReceiptById(id).subscribe(data => {
            this.overlay.open(ReceiptComponent, { data: data });
        });
    }
}
