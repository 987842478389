import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
    personalizationBarType,
    PersonalizationBarService,
} from '@core/personalization-bar.service';
import { SiteContextService } from '@core/site-context.service';
import { TrackingService } from '@core/tracking/tracking.service';
import {
    NnMarkedsDataViewModel,
    RequestModel,
} from '@shared/swagger/nnmarkedsdata.interface';
import {
    FindNearestParcelShopsArgs as parcelShopsArgs,
    SearchParcelShop as searchParcelShop,
} from '@shared/swagger/solrsearcher.interface';
import {
    ImpactCoreAddressB2CAddress as invoiceAddress,
    ImpactCoreBasketsBasketLineServiceModel,
    ImpactCoreBasketsBasketServiceModel as basketModel,
    ImpactCoreBasketsBasketServiceModel as IBasketModel,
    ImpactWebsiteCodeWebApiControllersAddGiftCardArgs as IAddGiftCardModel,
    ImpactWebsiteCodeWebApiControllersDeliveryTypeServiceModel as deliveryTypeModel,
    ImpactWebsiteCodeWebApiControllersGetBasketArgs as IGoToPaymentRequestModel,
    ImpactWebsiteCodeWebApiControllersGetInvoiceCountriesResponse as invoiceCountriesResponse,
    ImpactWebsiteCodeWebApiControllersGoToPaymentResponse,
    ImpactWebsiteCodeWebApiControllersRemoveGiftCardArgs as IRemoveGiftCardModel,
    ImpactWebsiteCodeWebApiControllersSetCommentArgs as ICommentRequestModel,
    ImpactWebsiteCodeWebApiControllersSetDeliveryAddressArgs as deliveryAddressRequest,
    ImpactWebsiteCodeWebApiControllersSetPaymentTypeRequest as ISetPaymentTypeRequestModel,
} from '@shared/swagger/swagger.interface';
import { environment } from 'environments/environment';
import { Observable, ObservableInput, ReplaySubject, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { catchError, publishLast, refCount, tap } from 'rxjs/operators';
import { LoggingService } from '@core/logging.service';
import { ProfitMetricsService } from '@core/tracking/profit-metrics.service';

@Injectable()
export class BasketService {
    constructor(
        private httpClient: HttpClient,
        private siteContextService: SiteContextService,
        @Inject(PLATFORM_ID) private platformId: object,
        private tracking: TrackingService,
        private personalizationService: PersonalizationBarService,
        private logging: LoggingService,
        private profitMetricsService: ProfitMetricsService,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.fetchBasket();
        }
    }

    private env: string = this.siteContextService.getRootUrl();

    private basket: BehaviorSubject<IBasketModel> = new BehaviorSubject({});
    private basketUpdating: BehaviorSubject<boolean> = new BehaviorSubject(
        false,
    );

    /**
     * Get current basket state as obserable
     */
    basket$: Observable<IBasketModel> = this.basket.asObservable();
    basketUpdating$: Observable<boolean> = this.basketUpdating.asObservable();

    /**
     * Observable that holds state for showing gift card limit message
     */
    giftCardLimitMessage$: BehaviorSubject<BasketGiftCardLimitResponse> = new BehaviorSubject({});
    basketUpdateGiftCardLimitMessage$: BehaviorSubject<BasketUpdateGiftCardLimitResponse> = new BehaviorSubject({});

    // Used for raptor recommentations (get products related to the products in the basket)
    public basketColorGroupIds: BehaviorSubject<string[]> = new BehaviorSubject<
        string[]
    >([]);

    public getCurrentBasket() {
        return this.basket.getValue();
    }

    fetchBasket(): void {
        this.httpClient
            .post<IBasketModel>(`${this.env}/webapi/Basket/RetrieveBasket`, {
                BasketId: this.getBasketId(),
                UserId: localStorage.getItem('uid') || '',
            })
            .subscribe(data => {
                this.basketColorGroupIds.next(
                    this.getBasketColorGroupIds(data.Lines),
                );
                this.basket.next(data);
                this.setBasketId(data.Id);
                this.personalizationService.getPersonalizationBars(
                    personalizationBarType.basket,
                );
            });
    }

    /**
     * Update basket to alter current basket state
     * Use this method to remove from basket aswell with amount = 0
     */
    update(Id: string, SkuId: string, Amount: number = 0): void {
        const basket = this.basket.getValue();
        const currentLine = basket.Lines.find(line => {
            return line.Id === Id;
        });
        let addNewSize = false;

        if (currentLine.SkuId === SkuId) {
            // If we didn't change size

            if (currentLine.Amount < Amount) {
                // We added products from the line
                const changeAmount = Amount - currentLine.Amount;
                this.tracking.sendAddToBasket(currentLine, changeAmount);
            } else if (currentLine.Amount > Amount) {
                // We removed products from the line
                const changeAmount = currentLine.Amount - Amount;
                this.tracking.sendRemoveFromBasket(currentLine, changeAmount);
            }
        } else {
            // If we changed the size
            // We remove the old size completely
            const removeAmount = currentLine.Amount;
            this.tracking.sendRemoveFromBasket(currentLine, removeAmount);

            // We add the new size once the server replies
            // in case there is fewer than the current size amount in stock.
            addNewSize = true;
        }

        this.httpClient
            .post<IBasketModel>(`${this.env}/webapi/Basket/Update`, {
                BasketId: this.getBasketId(),
                LineId: Id,
                SkuId,
                Amount,
            })
            .subscribe(data => {
                if (data.hasOwnProperty('Success') && data.hasOwnProperty('Message')) {
                    this.basketUpdateGiftCardLimitMessage$.next({ ...(data as BasketGiftCardLimitResponse), LineId: Id });
                } else {
                    const basketData: basketModel = data;
                    if (addNewSize) {
                        const newLine = basketData.Lines.find(line => {
                            return line.Id === Id;
                        });

                        this.tracking.sendAddToBasket(
                            { ...newLine },
                            newLine.Amount,
                        );
                    }
                    this.basketColorGroupIds.next(
                        this.getBasketColorGroupIds(basketData.Lines),
                    );
                    this.basket.next(basketData);
                    this.personalizationService.getPersonalizationBars(
                        personalizationBarType.basket,
                    );
                }
            });
    }

    // Get style and color group ids from basket lines
    getBasketColorGroupIds(
        basketLines: ImpactCoreBasketsBasketLineServiceModel[],
    ): string[] {
        const styleColorIds = [];
        basketLines.forEach(line => {
            styleColorIds.push(line.StyleId + '_' + line.ColorId);
        });
        return styleColorIds;
    }

    /**
     * Add item to basket
     */
    add(SkuId: string, Product: any, Amount: number = 1): Observable<void> {
        // Next that basket is updating
        this.basketUpdating.next(true);

        this.tracking.sendAddToBasket({ ...Product, SkuId }, Amount);
        this.profitMetricsService.trackAddToCart({ ...Product, SkuId }, Amount);

        return new Observable(observer => {
            this.httpClient
                .post<IBasketModel>(`${this.env}/webapi/Basket/Add`, {
                    BasketId: this.getBasketId(),
                    SkuId,
                    Amount,
                })
                .subscribe(data => {
                    if (data.hasOwnProperty('Success') && data.hasOwnProperty('Message')) {
                        this.basketUpdating.next(false);
                        this.giftCardLimitMessage$.next(data as BasketGiftCardLimitResponse);
                        observer.error();
                        observer.complete();
                    } else {
                        // Only set basket if id is not set in local storage
                        if (this.getBasketId() === '') {
                            this.setBasketId(data.Id);
                        }

                        this.personalizationService.getPersonalizationBars(
                            personalizationBarType.basket,
                        );

                        this.basketColorGroupIds.next(
                            this.getBasketColorGroupIds(data.Lines),
                        );
                        this.basket.next(data);
                        this.basketUpdating.next(false);

                        observer.next();
                        observer.complete();
                    }
                });
        });
    }

    /**
     * Destroy basket and remove basket id
     */
    destroy(): void {
        this.removeBasketId();
        this.basket.next(null);
    }

    /**
     * Add voucher to basket
     */
    addVoucher(VoucherCode: string): Observable<IBasketModel> {
        return this.httpClient
            .post<IBasketModel>(`${this.env}/webapi/Basket/AddVoucher`, {
                BasketId: this.getBasketId(),
                VoucherCode,
            })
            .pipe(tap(data => this.basket.next(data)));
    }

    /**
     * Remove voucher from basket
     */
    removeVoucher(): void {
        this.httpClient
            .post<IBasketModel>(`${this.env}/webapi/Basket/RemoveVoucher`, {
                BasketId: this.getBasketId(),
            })
            .subscribe(data => this.basket.next(data));
    }

    setInvoiceAddress(invoiceAddress: invoiceAddress): Observable<basketModel> {
        return this.httpClient
            .post<basketModel>(`${this.env}/webapi/Basket/SetInvoiceAddress`, {
                BasketId: this.getBasketId(),
                BasketAddress: invoiceAddress,
                DeliveryToInvoiceAddress: this.basket.getValue()
                    .DeliveryToInvoiceAddress,
            })
            .pipe(tap(data => this.basket.next(data)));
    }

    setDeliveryAddress(
        deliveryAddress: deliveryAddressRequest,
    ): Observable<basketModel> {
        return this.httpClient
            .post<basketModel>(`${this.env}/webapi/Basket/SetDeliveryAddress`, {
                BasketId: this.getBasketId(),
                BasketAddress: deliveryAddress.BasketAddress,
                CarrierId: deliveryAddress.CarrierId,
                DeliveryId: deliveryAddress.DeliveryId,
                DeliveryViewKey: deliveryAddress.DeliveryViewKey,
                DeliveryToInvoiceAddress:
                deliveryAddress.DeliveryToInvoiceAddress,
                ParcelShopId: deliveryAddress.ParcelShopId,
            })
            .pipe(
                tap(data => this.basket.next(data)),
                catchError(
                    (err): ObservableInput<any> => {
                        this.logging.exception(
                            `Error happened in /webapi/Basket/SetDeliveryAddress. Error was: ${err}`,
                            deliveryAddress,
                        );
                        return throwError(err);
                    },
                ),
            );
    }

    private countries: Observable<invoiceCountriesResponse> = null;

    retrieveInvoiceCountries(
        fetchNew: boolean = false,
    ): Observable<invoiceCountriesResponse> {
        if (fetchNew || this.countries === null) {
            this.countries = this.httpClient
                .post<invoiceCountriesResponse>(
                    `${this.env}/webapi/Basket/RetrieveInvoiceCountries`,
                    null,
                )
                .pipe(publishLast(), refCount());
        }

        return this.countries;
    }

    retrieveUserFromMarkedsData(
        user: RequestModel,
    ): Observable<NnMarkedsDataViewModel> {
        return this.httpClient.post<NnMarkedsDataViewModel>(
            `${environment.nnMarkedsDataRoot}/api/nnmarkedsdata`,
            user,
        );
    }

    getDeliveryTypes(): Observable<deliveryTypeModel[]> {
        return this.httpClient
            .get<deliveryTypeModel[]>(
                `${this.env}/webapi/DeliveryType/GetDeliveryTypes`,
                {
                    params: {
                        basketId: this.getBasketId(),
                    },
                },
            )
            .pipe(
                catchError(
                    (err): ObservableInput<any> => {
                        this.logging.exception(
                            `Error happened in /webapi/DeliveryType/GetDeliveryTypes. Error was: ${err}`,
                        );
                        return throwError(err);
                    },
                ),
            );
    }

    findNearestParcelShops(
        address: parcelShopsArgs,
    ): Observable<searchParcelShop[]> {
        return this.httpClient
            .post<searchParcelShop[]>(
                `${environment.solrSearchRoot}/api/parcelshop/FindNearestParcelShops`,
                address,
            )
            .pipe(
                catchError(
                    (err): ObservableInput<any> => {
                        this.logging.exception(
                            `Error happened in /api/parcelshop/FindNearestParcelShops. Error was: ${err}`,
                            address,
                        );
                        return throwError(err);
                    },
                ),
            );
    }

    updateGiftWrapping(sku: string) {
        const basket = this.getCurrentBasket();

        const currentLine = basket.Lines.find(line => {
            return line.SkuId === sku;
        });

        const withWrapping = !currentLine.WithWrapping;

        this.httpClient
            .post(`${this.env}/webapi/Basket/SetWithWrapping`, {
                BasketId: this.getBasketId(),
                SkuId: sku,
                WithWrapping: withWrapping,
            })
            .subscribe(newState => {
                this.basket.next(newState);
            });
    }

    addGiftCard(giftCardRequestModel: IAddGiftCardModel) {
        const addingGiftCardState = new ReplaySubject<any>(1);
        this.httpClient
            .post(`${this.env}/webapi/Basket/AddGiftCard`, giftCardRequestModel)
            .subscribe(
                basket => this.basket.next(basket),
                err => {
                    addingGiftCardState.next(err);
                },
            );

        return addingGiftCardState.asObservable();
    }

    removeGiftCard(body: IRemoveGiftCardModel) {
        return this.httpClient
            .post(`${this.env}/webapi/Basket/RemoveGiftCard`, body)
            .subscribe(basket => this.basket.next(basket));
    }

    setPaymentType(body: ISetPaymentTypeRequestModel) {
        return this.httpClient
            .post(`${this.env}/webapi/Basket/SetPaymentType`, body)
            .subscribe(basket => this.basket.next(basket));
    }

    setComment(commentRequestModel: ICommentRequestModel) {
        return this.httpClient.post(
            `${this.env}/webapi/Basket/SetComment`,
            commentRequestModel,
        );
    }

    goToPayment(
        body: IGoToPaymentRequestModel,
    ): Observable<ImpactWebsiteCodeWebApiControllersGoToPaymentResponse> {
        return this.httpClient.post(
            `${this.env}/webapi/Basket/GoToPayment`,
            body,
        );
    }

    private setBasketId(basketId: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(this.basketIdLocalstorageName(), basketId);
        }
    }

    private getBasketId() {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem(this.basketIdLocalstorageName()) || '';
        } else {
            return '';
        }
    }

    private removeBasketId(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('checkoutSteps');
            localStorage.removeItem(this.basketIdLocalstorageName());
            this.basket.next({});
        }
    }

    basketIdLocalstorageName(): string {
        const lang = this.siteContextService.getContext().language;
        return lang === 'da' ? 'basketId' : 'basketId_' + lang;
    }
}

interface BasketGiftCardLimitResponse {
    Success?: boolean;
    Message?: string;
}
interface BasketUpdateGiftCardLimitResponse extends BasketGiftCardLimitResponse {
    LineId?: string;
}
