import { Component, ViewChild, ViewContainerRef, Compiler, Injector, NgModuleFactory, Input } from '@angular/core';

@Component({
    selector: 'app-signup-sublayout',
    templateUrl: './signup-sublayout.component.html',
})
export class SignupSublayoutComponent {
    // @ViewChild('signupAnchor', { read: ViewContainerRef }) signupAnchor: ViewContainerRef;
    static ref = 'SignupSublayout';
    @Input()
    public data;

    constructor(
        // private compiler: Compiler,
        // private injector: Injector
    ) {
        // this.loadSignup();
    }

    // async loadSignup() {
    //     const { SignupComponent, SignupModule } = await import('@features/signup');
    //     const moduleFactory = SignupModule instanceof NgModuleFactory ? SignupModule
    //         : (await this.compiler.compileModuleAsync(SignupModule));
    //     const moduleRef = moduleFactory.create(this.injector);
    //     const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(SignupComponent);
    //     this.signupAnchor.clear();
    //     this.signupAnchor.createComponent(componentFactory);
    // }
}
