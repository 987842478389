import {BehaviorSubject, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import {tap} from 'rxjs/operators';
import { SiteContextService } from '@core/site-context.service';

@Injectable()
export class SearchService {
    private env: string = environment.solrSearchRoot;
    private businessDimension: string;
    private language: string;

    persistedSearchResults: any[] = [];
    searchResult$ = new BehaviorSubject([]);

    constructor(
        private httpClient: HttpClient,
        private siteContextService: SiteContextService
    ) {
        this.businessDimension = this.siteContextService.getContext().businessDimension;
        this.language = this.siteContextService.getContext().language;
    }

    search(searchString: string): Observable<any> {
        return this.httpClient.get<any>(
            `${this.env}/api/AutoSuggest/Suggestions`,
            {
                params: {
                    searchString,
                    businessDimension: this.businessDimension,
                    languageCode: this.language,
                },
            }
        ).pipe(
            tap(data => this.searchResult$.next(data))
        );
    }
}
