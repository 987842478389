import { Component, Input, ViewChild } from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { checkmark } from '@shared/svg';
import { NgForm } from '@angular/forms';
import { GiftCardBalanceService } from '@core/gift-card-balance.service';
import { SpinnerService } from '@shared/spinner/spinner.service';

@Component({
    selector: 'app-giftcardbalance-sublayout',
    templateUrl: './giftcardbalance-sublayout.component.html',
})
export class GiftcardbalanceSublayoutComponent {
    constructor(
        public translations: TranslationService,
        private giftCardBalanceService: GiftCardBalanceService,
        private spinnerService: SpinnerService
    ) { }
    static ref = 'GiftcardSaldoSublayout';
    @Input()
    public data;

    @ViewChild('form', { static: true }) form: NgForm;

    icons = {
        checkmark: checkmark,
    };

    model = {
        GiftcardNumber: '',
        GiftcardPassword: '',
    };

    cardBalance: number;
    currency: string;
    showBalanceCard = false;
    cardNotRecognized = false;

    getSaldo() {
        this.cardNotRecognized = false;
        this.showBalanceCard = false;

        this.spinnerService.show('checkGiftcardBalance');

        if (this.form.valid) {
            this.giftCardBalanceService
                .checkGiftCardBalance(this.model)
                .subscribe(data => {
                    this.spinnerService.hide('checkGiftcardBalance');
                    if (data === null) {
                        this.cardNotRecognized = true;
                        this.showBalanceCard = true;
                    } else {
                        this.cardBalance = data.Saldo;
                        this.currency = data.Currency;
                        this.showBalanceCard = true;
                    }
                });
        }
    }
}
