import { NgModule } from '@angular/core';
import { BrandService } from '@features/brand/brand.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [BrandService],
})
export class BrandModule {}
