<div class="minibasket-products">
    <ul
        class="minibasket-products__list"
        [@loadAnimation]="state">
        <li
            class="minibasket-products__item"
            *ngFor="let basketLine of basketLines; trackBy: trackByFn"
            @removeAnimation>
                <div class="minibasket-products__inner">
                    <app-minibasket-product
                        (updateBasket)="onUpdateBasket($event)"
                        [basketLine]="basketLine"
                        [productImageBgColor]="productImageBgColor"
                    ></app-minibasket-product>
                </div>
        </li>
        <li *ngIf="!basketLines || basketLines?.length === 0" class="minibasket-products__item_empty">
            <div class="minibasket-products__inner minibasket-products__inner_empty">
                <app-svg src="basket" [showOnSite]="['kaufmann', 'quint']"></app-svg>
                <p>{{(translationService.translations$ | async)?.Minibasket?.Products?.NoProducts}}</p>
            </div>
        </li>
    </ul>
</div>
