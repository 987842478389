<div class="customer-receipts">
    <h2 class="customer__heading">{{(translationService.translations$ | async).Profile?.Content?.ReceiptSelectionTitle}}</h2>

    <div class="customer-receipts__table">
        <spinner name="receiptsSpinner" [size]="20" background="true"></spinner>

        <div class="customer-receipts__no-found" *ngIf="(customerService.receipts$ | async)?.length === 0">{{(translationService.translations$ | async).Receipt?.Content?.NoReceiptsFound}}</div>

        <table class="customer-receipts-table" *ngIf="(customerService.receipts$ | async)?.length > 0">
            <tr>
                <th>{{(translationService.translations$ | async).Receipt?.Content?.PurchaseDateTableLabel}}</th>
                <th>{{(translationService.translations$ | async).Receipt?.Content?.OrderTableLabel}}</th>
                <th>{{(translationService.translations$ | async).Receipt?.Content?.ShopTableLabel}}</th>
                <th>{{(translationService.translations$ | async).Receipt?.Content?.StatusTableLabel}}</th>
                <th>{{(translationService.translations$ | async).Receipt?.Content?.SendDateTableLabel}}</th>
                <th>{{(translationService.translations$ | async).Receipt?.Content?.TrackAndTraceTableLabel}}</th>
                <th>{{(translationService.translations$ | async).Receipt?.Content?.ReceiptTableLabel}}</th>
            </tr>
            <tr *ngFor="let receipt of (customerService.receipts$ | async)">
                <td [attr.data-label]="(translationService.translations$ | async).Receipt?.Content?.PurchaseDateTableLabel">{{receipt.Created}}</td>
                <td [attr.data-label]="(translationService.translations$ | async).Receipt?.Content?.OrderTableLabel">{{receipt.OrderNumber}}</td>
                <td [attr.data-label]="(translationService.translations$ | async).Receipt?.Content?.ShopTableLabel">{{(translationService.translations$ | async).Receipt?.Content[receipt.Type]}}</td>
                <td [attr.data-label]="(translationService.translations$ | async).Receipt?.Content?.StatusTableLabel">{{(translationService.translations$ | async).Receipt?.Content[receipt.Status]}}</td>
                <td [attr.data-label]="(translationService.translations$ | async).Receipt?.Content?.SendDateTableLabel">{{receipt.Completed}}</td>
                <td [attr.data-label]="(translationService.translations$ | async).Receipt?.Content?.TrackAndTraceTableLabel"><a href="{{receipt.TrackAndTrace}}" target="_blank" *ngIf="receipt.TrackAndTrace">{{(translationService.translations$ | async).Receipt?.Content?.TrackAndTraceLink}}</a></td>
                <td [attr.data-label]="(translationService.translations$ | async).Receipt?.Content?.ReceiptTableLabel"><a (click)="onShowReceipt(receipt.OrderNumber)">{{(translationService.translations$ | async).Receipt?.Content?.ShowReceiptLink}}</a></td>
            </tr>
        </table>
    </div>
</div>
