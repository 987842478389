import {Injectable} from '@angular/core';
import {
    FormattedProductData,
    ProductInformation,
    ProductSizeVariant,
    ProductVariant,
} from '@features/product-filter/models/product.model';
import {StockInStoreComponent} from '@features/product-page/stock-in-store/stock-in-store.component';
import {StockInStoreService} from '@features/product-page/stock-in-store/stock-in-store.service';
import {WatchProductComponent} from '@features/product-page/watch-product/watch-product.component';
import {Wish} from '@features/wishlist/wish.model';
import {
    ImpactCoreModelsProductsDtoSkuDto,
    ImpactCoreModelsProductsDtoStyleDto
} from '@shared/swagger/swagger.interface';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {BasketService} from './basket.service';
import {InfoService} from './info.service';
import {PaneService} from './pane.service';
import {BusinessDimensions, SiteContextService} from './site-context.service';
import {TrackingService} from './tracking/tracking.service';
import {WishlistService} from './wishlist.service';

//
// PRODUCT SERVICE
// The heart and brain of product related data.
// Stores product data, accessible for all components, and provides global methods for working with product data
//

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    // Product page CMS page data, to make it accessible to all product components
    public activeProductPageData: any;
    public activeProductSizeGuideUrl = '';
    // Active product
    public activeProduct: FormattedProductData;
    // Currently selected size variant, of the activeProduct
    public selectedSizeVariant: ProductSizeVariant;
    private currentlySelectedProduct: Subject<any> = new Subject(); // eslint-disable-line
    public currentlySelectedProduct$: Observable<any> = this.currentlySelectedProduct.asObservable();
    private currentlySelectedProductSizeVariant: BehaviorSubject<ProductSizeVariant> = new BehaviorSubject(null); // eslint-disable-line
    public currentlySelectedProductSizeVariant$: Observable<any> = this.currentlySelectedProductSizeVariant.asObservable();
    private productAddedToBasket: Subject<boolean> = new Subject(); // eslint-disable-line
    public productAddedToBasket$: Observable<boolean> = this.productAddedToBasket.asObservable();
    private showProductSizePicker: Subject<any> = new Subject(); // eslint-disable-line
    public showProductSizePicker$: Observable<any> = this.showProductSizePicker.asObservable();
    private productSizeStockStatus: BehaviorSubject<any> = new BehaviorSubject(null); // eslint-disable-line
    public productSizeStockStatus$: Observable<any> = this.productSizeStockStatus.asObservable();

    constructor(
        private basketService: BasketService,
        private siteContextService: SiteContextService,
        private stockService: StockInStoreService,
        private paneService: PaneService,
        private infoService: InfoService,
        private tracking: TrackingService,
        private wishlistService: WishlistService
    ) {
        this.currentlySelectedProductSizeVariant.subscribe(sizeVariant => {
            this.selectedSizeVariant = sizeVariant;
        });
    }

    //
    // PUBLIC METHODS
    //

    // Store the product page data
    public setActiveProductPageData(pageData: any): void {
        this.activeProductPageData = pageData;
        this.activeProductSizeGuideUrl = pageData.pageData.SizeGuidePageUrl;
    }

    // Sets the currently active product
    // This is the product used in the product-spot component
    public setActiveProduct(
        product: ImpactCoreModelsProductsDtoStyleDto
    ): FormattedProductData {
        if (!product) {
            return;
        }

        const productVariant = this.findActiveProductVariant(product);

        // Construct the product object
        const productData: FormattedProductData = {
            activeProductColorId: productVariant.ColorId,
            activeProductVariant: productVariant,
            productData: product, // Original product data
            productInformation: this.getProductInformation(product),
            productSizeGuideUrl: this.activeProductSizeGuideUrl,
        };

        // Set active product data, to make accessible for other components
        this.activeProduct = productData;

        this.setSize(productData.activeProductVariant.Skus);

        this.currentlySelectedProduct.next(this.activeProduct);

        // Return the active product data
        return this.activeProduct;
    }

    // Set active product data
    // Update activeProductData with currently selected productVariant (activeProductVariant), and activeProductId
    public setActiveProductByColorId(colorId: string): FormattedProductData {
        // Get active variant by colorId
        const variant = this.activeProduct.productData.ColorGroups.find(
            group => {
                return colorId === group.ColorId;
            }
        ) as ProductVariant;

        // Update activeProductData properties
        this.activeProduct.activeProductColorId = colorId;
        this.activeProduct.productData.ActiveColorGroupId = colorId;

        variant.isSoldOut = this.isVariantSoldOut(variant.Skus);
        this.activeProduct.activeProductVariant = variant;

        // Reset product size variant
        this.currentlySelectedProduct.next(this.activeProduct);
        this.setSize(variant.Skus);
        this.productSizeStockStatus.next(null);

        // Return the active product data
        return this.activeProduct;
    }

    // Set currently selected product size variant, from size picker
    // Is the product variant that is added to basket
    // RENAME : setSelectedProductSizeVariant
    public setProductSizeVariant(variant: ProductSizeVariant) {
        this.currentlySelectedProductSizeVariant.next(variant);

        // Get product size store stock
        this.getProductSizeStoreStock();
    }

    // Add active product to basket
    public addProductToBasket() {
        if (
            this.activeProduct.productData &&
            this.activeProduct.activeProductVariant
        ) {
            this.basketService
                .add(
                    this.selectedSizeVariant.Id,
                    this.activeProduct.productData
                )
                .subscribe(() => {
                    this.productAddedToBasket.next(true);
                });
        }
    }

    // Size picker, for active product
    public openActiveProductSizeguide() {
        if (this.activeProduct.productSizeGuideUrl.length > 0) {
            this.infoService.openInfo(this.activeProduct.productSizeGuideUrl);
        }
    }

    // Open product watch
    public openWatchProduct(productSize?: any) {
        this.tracking.sendKeepAnEyeOpen(this.activeProduct.productData.Name);

        this.paneService.openChild(WatchProductComponent, 'right', {
            product: this.activeProduct.productData,
            selectedColorId: this.activeProduct.activeProductColorId,
            selectedSize: productSize ? productSize : this.selectedSizeVariant,
        });
    }

    // Open product watch
    public openProductInStoreStock() {
        this.tracking.sendKeepAnEyeOpen(this.activeProduct.productData.Name);

        this.paneService.openChild(StockInStoreComponent, 'right', {
            product: this.activeProduct.productData,
            selectedColorId: this.activeProduct.activeProductColorId,
            selectedSize: this.selectedSizeVariant,
        });
    }

    //
    // HELPER METHODS
    //

    // Wish list, check if selected product is on wishlist
    isProductOnWisthList(): boolean {
        if (!this.selectedSizeVariant) {
            return false;
        }

        const existsOnWishlist = this.wishlistService.contains(
            this.activeProduct.productData.StyleId,
            this.activeProduct.productData.ActiveColorGroupId,
            this.selectedSizeVariant.SizeLabel
        );

        return existsOnWishlist;
    }

    // WISHLIST: Add active product variant
    public addProductToWishlist(): void {
        if (!this.selectedSizeVariant) {
            // Next the next step in the flow
            this.showProductSizePicker.next({
                addToBasket: false,
                addToFavorites: true,
            });

            return;
        }

        const colorName = this.activeProduct.activeProductVariant.ColorName;

        const productName = this.activeProduct.productData.Name
            ? this.activeProduct.productData.Name
            : this.tracking.fallBackProductName(
                this.activeProduct.productData.Fit,
                this.activeProduct.productData.Model,
                this.activeProduct.productData.Category,
                colorName
            );

        this.tracking.sendAddToFavorite(productName);
        this.wishlistService.addWish(
            new Wish(this.activeProduct.productData, this.selectedSizeVariant)
        );
    }

    // WISHLIST: Remove active product variant
    public removeProductFromWishList() {
        if (!this.selectedSizeVariant) {
            return;
        }

        this.tracking.sendRemoveFromFavorite(
            this.activeProduct.productData.Name
        );
        this.wishlistService.deleteWishWithProperties(
            this.activeProduct.productData.StyleId,
            this.activeProduct.productData.ActiveColorGroupId,
            this.selectedSizeVariant.SizeLabel
        );
    }

    // Check if variant is sold out
    public isVariantSoldOut(variantSizes: any): boolean {
        return (
            variantSizes.findIndex(sku => sku.hasOwnProperty('StockCount')) < 0
        );
    }

    // Get product stock in store, based on product size
    getProductSizeStoreStock(): void {
        if (!this.selectedSizeVariant) {
            return;
        }

        this.stockService
            .getStockInStores(this.selectedSizeVariant.Id)
            .subscribe((storeStock: any) => {
                this.productSizeStockStatus.next(storeStock);
            });
    }

    getBrandColors() {
        const context = this.siteContextService.getContext();

        const brandColors = {
            sliderImageBgColor: '',
            thumbnailImageBgColor: '',
            variantImageBgColor: '',
        };

        switch (context.businessDimension) {
            case BusinessDimensions.AXEL:
                brandColors.sliderImageBgColor = context.bgColor.secondary;
                brandColors.thumbnailImageBgColor = context.bgColor.secondary;
                brandColors.variantImageBgColor = context.bgColor.primary;
                break;
            case BusinessDimensions.KAUFMANN:
                brandColors.sliderImageBgColor = context.bgColor.primary;
                brandColors.thumbnailImageBgColor = context.bgColor.primary;
                brandColors.variantImageBgColor = context.bgColor.secondary;
                break;
            case BusinessDimensions.QUINT:
                brandColors.sliderImageBgColor = context.bgColor.secondary;
                brandColors.thumbnailImageBgColor = context.bgColor.secondary;
                brandColors.variantImageBgColor = context.bgColor.primary;
                break;
            default:
                console.warn(
                    'Get brand colors: Product does not match any business dimension'
                );
                break;
        }

        return brandColors;
    }

    // Find the correct product variant, in the colorGroups array, based on activeColorGroupId
    private findActiveProductVariant(
        productData: ImpactCoreModelsProductsDtoStyleDto
    ): ProductVariant {
        // Generate variant product object
        const variant = productData.ColorGroups.find(group => {
            return productData.ActiveColorGroupId === group.ColorId;
        }) as ProductVariant;

        // Add missing price properties
        if (!variant.hasOwnProperty('SalePrice')) {
            variant['SalePrice'] = 0;
            variant['PercentageSaving'] = '';
        }

        variant.isSoldOut = this.isVariantSoldOut(variant.Skus); // Active product variant (ColorGroup)

        return variant;
    }

    // Format product information object
    private getProductInformation(
        productData: ImpactCoreModelsProductsDtoStyleDto
    ): ProductInformation {
        // tslint:disable-next-line:no-object-literal-type-assertion
        const information = {} as ProductInformation;

        information.productInfoList = productData.ProductInfo
            ? productData.ProductInfo.split('|')
            : [];
        information.productCareLabels = productData.CareLabels;
        information.ProductInfoLinks = productData.ProductInfoLinks;
        return information;
    }

    // Reset product size variant or set it if its a "one size" product with no other sizes
    private setSize(skus: ImpactCoreModelsProductsDtoSkuDto[]): void {
        if (skus.length > 1) {
            this.currentlySelectedProductSizeVariant.next(null);
        } else {
            this.currentlySelectedProductSizeVariant.next(skus[0] as ProductSizeVariant);
        }
    }

}
