import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { PersonalizationBarComponent } from './personalization-bar/personalization-bar.component';
import { UspBarBasketComponent } from './usp-bar-basket/usp-bar-basket.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, SharedModule, RouterModule],
    declarations: [PersonalizationBarComponent, UspBarBasketComponent],
    exports: [PersonalizationBarComponent, UspBarBasketComponent],
})
export class PersonalizationModule {}
