import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paginationFact',
})
export class PaginationFactPipe implements PipeTransform {
    transform(facets: any): any {
        const returnValue = facets.filter(f => f.kind === 'pagination');
        return returnValue[0];
    }
}
