import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    ImpactCoreStoresStoreServiceModel as storeModel,
    ImpactWebsiteCodeWebApiControllersNearestStoreRequest as storeRequest,
} from '@shared/swagger/swagger.interface';
import { publishLast, refCount } from 'rxjs/operators';
import { SiteContextService } from '@core/site-context.service';

@Injectable()
export class StoreService {
    private businessDimension: string;
    private language: string;

    constructor(
        private httpClient: HttpClient,
        private siteContextService: SiteContextService
    ) {
        this.businessDimension = this.siteContextService.getContext().businessDimension;
        this.language = this.siteContextService.getContext().language;
    }

    /**
     * Cache all stores
     */
    private stores: Observable<storeModel[]> = null;

    /**
     * Get all stores
     */
    getAllStores(fetchNew: boolean = false): Observable<storeModel[]> {
        if (fetchNew || this.stores === null) {
            this.stores = this.httpClient
                .get<storeModel[]>(
                    `${this.siteContextService.getRootUrl()}/webapi/Store/GetAllStoresIncludeWebshop?businessDimension=${this.businessDimension}&languageCode=${this.language}`
                )
                .pipe(publishLast(), refCount());
        }

        return this.stores;
    }

    /**
     * Get nearest stores
     */
    findNearestStore(storeRequest: storeRequest) {
        return this.httpClient.post<storeModel[]>(
            `${this.siteContextService.getRootUrl()}/webapi/Store/GetNearestStores`,
            storeRequest
        );
    }
}
