import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
} from '@angular/core';
import { Store } from '@features/find-store/models/store.model';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from '@core/translation.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-find-store-list',
    templateUrl: './find-store-list.component.html',
})
export class FindStoreListComponent implements OnInit, OnDestroy {
    @Input() stores: Store[];
    @Input() isPane: boolean;
    @Output() listClick = new EventEmitter<Store>();
    public translations: any;
    private unSubscribe = new Subject();

    constructor(private translationService: TranslationService) {}

    ngOnInit() {
        this.translationService.translations$
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(translations => {
                this.translations = translations;
            });
    }

    onListClick(store: Store) {
        this.listClick.emit(store);
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
