import { Component, Input } from '@angular/core';
import { PageService } from '@core/page.service';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'store-page',
    template: `
        <div class="headline">
            <div class="headline__header">
                <div class="container container_padded">
                    <div class="headline__container">
                        <h1 class="headline__heading">{{(translationService.translations$ | async)?.Store?.StoreFinderPane?.FindStore}}</h1>
                    </div>
                </div>
            </div>
        </div>

        <spots [spots]="data"></spots>
    `,
})
export class StorePageComponent {
    constructor(
        public pageService: PageService,
        public translationService: TranslationService
    ) {}

    static ref = 'StorePage';

    @Input() public data;
    @Input() public showFooter = true;
}
