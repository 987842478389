import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-newsletter-signup-spot',
    template: `
        <div class="newsletter-signup-spot">
            <app-newsletter-signup [data]="data"></app-newsletter-signup>
        </div>
    `,
    host: { class: 'column' },
})
export class NewsletterSignupSpotComponent {
    static ref = 'Newsletter Signup Spot';

    @Input() data: any;
}
