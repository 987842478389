import { NgModule } from '@angular/core';

import { MobileMenuComponent } from './mobile-menu.component';
import { SharedModule } from '@shared/shared.module';
import { MetaMenuModule } from '../meta-menu/meta-menu.module';
import { RightpaneModule } from '@features/rightpane/rightpane.module';
import { LeftpaneModule } from '@features/leftpane/leftpane.module';
import { MobileNavigationAccordionComponent } from './mobile-navigation-accordion/mobile.navigation.accordion.component';
import { LanguagePickerModule } from '@features/language-picker/language-picker.module';

@NgModule({
    declarations: [MobileMenuComponent, MobileNavigationAccordionComponent],
    imports: [SharedModule, MetaMenuModule, RightpaneModule, LeftpaneModule, LanguagePickerModule],
    exports: [MobileMenuComponent],
    entryComponents: [MobileMenuComponent],
})
export class MobileMenuModule {}
