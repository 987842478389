import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { InfoService } from '@core/info.service';
import { RegexService } from '@core/regex.service';
import { SiteContextService } from '@core/site-context.service';
import { RaptorTrackingService } from '@core/tracking/raptor-tracking.service';
import { TrackingService } from '@core/tracking/tracking.service';
import { TranslationService } from '@core/translation.service';
import { UserService } from '@core/user.service';
import { AlertResponse, AlertType } from '@shared/alert/alert.interface';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { checkmark, goArrow } from '@shared/svg';
import { ImpactCoreUsersModelsCreateUserRequest as createUserRequest } from '@shared/swagger/swagger.interface';
import { finalize, take } from 'rxjs/operators';

export interface INewsletterSignupData {
    Title?: string;
    Subtitle?: string;
    SignupSource: string;
    TermsLink: any;
}

@Component({
    selector: 'app-newsletter-signup',
    templateUrl: './newsletter-signup.component.html',
})
export class NewsletterSignupComponent implements OnInit {
    @Input() data: INewsletterSignupData;
    @Input() inputIsBoxed?: boolean = false;

    icons = {
        checkmark,
        goArrow
    };

    user: createUserRequest = {
        Email: '',
        IsNewsletterSubscriber: true,
    };

    response: AlertResponse = {};
    userAcceptance = false;
    submitted = false;
    translations: any = {};
    acceptanceId: string;

    constructor(
        public translationService: TranslationService,
        public regexService: RegexService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private infoService: InfoService,
        private siteContextService: SiteContextService,
        private router: Router,
        private tracking: TrackingService,
        private raptorTrackingService: RaptorTrackingService
    ) {
        this.acceptanceId = `${Math.random() * 16}-newsletterSignupUserAccept`;
    }

    ngOnInit() {
        this.translationService.translations$
            .pipe(take(1))
            .subscribe((translations: any) => {
                this.translations = translations;
            });
    }

    closeAlert = () => {
        this.response = {};
    }

    openTerms(event: Event): void {
        if (!this.data.TermsLink) {
            return;
        }

        event.preventDefault();
        this.infoService.openInfo(this.data.TermsLink);
    }

    onSubmit(form: NgForm): void {
        this.response = {};

        if (form.valid) {
            if (this.userAcceptance && !this.submitted) {
                this.tracking.sendNewsletterSignup('Newsletter Signup Spot');
                this.raptorTrackingService.trackAddUser(this.user.Email);
                this.spinnerService.show(this.acceptanceId);
                this.submitted = true;

                this.user.SignupSource = this.data.SignupSource;

                this.userService
                    .subscribeToEmailNewsletter(this.user)
                    .pipe(
                        finalize(() => {
                            this.spinnerService.hide(
                                this.acceptanceId
                            );
                            this.submitted = false;
                        })
                    )
                    .subscribe(
                        data => {
                            this.userAcceptance = false;
                            form.onReset();

                            if (data.Redirect) {
                                this.router.navigate(
                                    [
                                        this.siteContextService.getContext()
                                            .createUserUrl,
                                    ],
                                    {
                                        queryParams: { splash: 'true' },
                                    }
                                );
                            }

                            this.response = {
                                type: AlertType.success,
                                text: this.translations.NewsletterSignup.Spot
                                    .SignupSuccess,
                            };
                        },
                        ({ error }) =>
                            (this.response = {
                                type: AlertType.error,
                                text: error.ErrorMessage,
                            })
                    );
            } else {
                this.response = {
                    type: AlertType.error,
                    text: this.translations.NewsletterSignup.Spot
                        .TermsAndConditionsInputMissing,
                };
            }
        } else {
            Object.keys(form.controls).forEach(field => {
                form.controls[field].markAsTouched({ onlySelf: true });
            });
        }
    }
}
