import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AlertResponse, AlertType } from '@shared/alert/alert.interface';
import { TranslationService } from '@core/translation.service';
import { SiteContextService } from '@core/site-context.service';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { AuthenticationService } from '@core/authentication.service';
import {
    ImpactCoreUsersModelsCreateUserRequest,
    ImpactWebsiteCodeWebApiControllersLoginArguments as loginArguments,
} from '@shared/swagger/swagger.interface';
import { checkmark, goArrow, mail, password } from '@shared/svg';
import { Router } from '@angular/router';
import { RegexService } from '@core/regex.service';
import { ResetPasswordComponent } from '@features/authentication/reset-password/reset-password.component';
import { PaneService } from '@core/pane.service';
import { Subject } from 'rxjs';
import { IPostAuthenticationInfo } from '@features/authentication/login/login.interface';
import { InfoService } from '@core/info.service';
import { UserService } from '@core/user.service';

@Component({
    selector: 'app-login-spot-component',
    templateUrl: './login-spot.component.html',
    styles: [],
})
export class LoginSpotComponent implements OnInit, OnDestroy {
    createUserUrl = '';
    translations: any;
    response: AlertResponse = {};
    submitted = false;

    user: loginArguments = {
        Email: '',
        Password: '',
        RememberMe: false,
    };

    icons = {
        mail,
        password,
        goArrow,
        checkmark,
    };

    termsLink: string;
    businessDimension: string;
    topContent: string;
    bottomContent: string;

    postAuthenticationInfo: IPostAuthenticationInfo = {};

    @Output() paneClose: EventEmitter<any> = new EventEmitter<any>();

    userAcceptNewsletter = true;

    destroy = new Subject();

    constructor(
        private translationService: TranslationService,
        private siteContextService: SiteContextService,
        private spinnerService: SpinnerService,
        private authenticationService: AuthenticationService,
        private router: Router,
        public regexService: RegexService,
        private paneService: PaneService,
        private infoService: InfoService,
        private userService: UserService,
        private elementRef: ElementRef
    ) {}

    ngOnInit(): void {
        this.translations = this.translationService.translations$;

        this.createUserUrl = this.siteContextService.getContext().createUserUrl;
        this.termsLink = this.siteContextService.getContext().createUserTermsUrl;
        this.businessDimension = this.siteContextService.getContext().businessDimension;
        this.topContent = this.siteContextService.getContext().loginUrl.TopContent;
        this.bottomContent = this.siteContextService.getContext().loginUrl.BottomContent;
    }

    onLogin = (form: NgForm) => {
        this.response = {};

        if (form.valid && !this.submitted) {
            this.spinnerService.show('loginSpinner');
            this.submitted = true;

            this.authenticationService
                .login(this.user)
                .pipe(
                    finalize(() => {
                        this.spinnerService.hide('loginSpinner');
                        this.submitted = false;
                    })
                )
                .subscribe(
                    data => {
                        // this.overlayRef.close();
                        this.paneClose.emit(true);

                        this.router.navigate(
                            [
                                {
                                    outlets: {
                                        dialog: data.RedirectUrl.replace(
                                            '/',
                                            ''
                                        ),
                                    },
                                },
                            ],
                            { queryParamsHandling: 'preserve' }
                        );
                    },
                    ({ error }) =>
                        (this.response = {
                            type: AlertType.error,
                            text: error.ErrorMessage,
                        })
                );
        }
    }

    closeAlert = () => {
        this.response = {};
    }

    openResetPassword() {
        this.paneService.openChild(ResetPasswordComponent, 'right');
    }

    openTerms(event: Event) {
        event.preventDefault();
        this.infoService.openInfo(this.termsLink, 'social-login-term-wrapper');
    }

    createUser(): void {
        const response: ImpactCoreUsersModelsCreateUserRequest = {};
        response.Email = this.postAuthenticationInfo.email;
        response.SignupSource = 'Social login';

        if (this.userAcceptNewsletter) {
            response.IsNewsletterSubscriber = true;
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }
}
