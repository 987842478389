import { Directive, HostBinding, OnInit } from '@angular/core';
import { ScrollbarService } from '@shared/scrollbar/scrollbar.service';

@Directive({
    selector: '[appScrollbarHide]',
})
export class ScrollbarHideDirective implements OnInit {
    constructor(private scrollbarService: ScrollbarService) {
        this.marginRight = this.scrollbarService.getScrollbarWidth() * -1;
    }

    @HostBinding('style.marginRight.px') marginRight: number;

    ngOnInit() {
        this.marginRight = this.scrollbarService.getScrollbarWidth() * -1;
    }
}
