<app-rightpane (animationStateChanged)="doSomething($event)" [startExit]="startExitTrigger" *noSSR>

    <div class="rightpane-container">
        <header class="rightpane-container__header">
            <h2 class="rightpane-container__heading">{{(translations.translations$ | async)?.ShopByImage?.RightPane?.Headline}}</h2>
        </header>

        <div class="rightpane-container__content" appScrollbarHide>
            <div class="shop-by-right">
                <a class="shop-by-right__image-link" [href]="data.marker.Url" intercept-links [ngStyle]=" {'background-color': '#'+imageBgColor} ">
                    <img [src]="mainImage + '&w=405&h=405&bgcolor=' + imageBgColor" [srcset]="mainImage + '&w=405&h=405&bgcolor=' + imageBgColor + ' 1x,' + mainImage + '&w=810&h=810&bgcolor=' + imageBgColor + ' 2x'" alt="">
                </a>
                <div class="shop-by-right__title">{{data.marker.Brand}}</div>
                <div class="shop-by-right__meta">{{productMeta}}</div>
                <div class="shop-by-right__available-sizes"
                    [innerHTML]="data.marker.AvailableSizes | showSizes: (translations.translations$ | async)?.ProductList?.Block?.SizeLabel : (translations.translations$ | async)?.ProductList?.Block?.SizesLabel">
                </div>
                <div class="shop-by-right__price-container">
                    <div class="product-spot__sidebar__prices">
                        <div class="product-spot__sidebar__current-price" *ngIf="!(data.marker.SalePrice > 1)">
                            {{data.marker.Price | kfmCurrency : currency}}
                        </div>
                        <div
                            class="product-spot__sidebar__current-price product-spot__sidebar__current-price_stroked"
                            *ngIf="data.marker.SalePrice > 1">
                            <span>{{translations?.ProductDetails?.SideBarPriceSection?.RetailLabel}}</span>
                            {{data.marker.Price | kfmCurrency : currency}}
                        </div>
                        <div class="product-spot__sidebar__discount-price" *ngIf="data.marker.SalePrice > 1">
                            <span>{{translations?.ProductDetails?.SideBarPriceSection?.DiscountLabel}}</span> {{data.marker.SalePrice | kfmCurrency : currency}}
                        </div>
                        <div class="product-spot__sidebar__discount-price" *ngIf="data.marker.DiscountDescription">
                            <span>{{data.marker.DiscountDescription}}</span>
                        </div>
                    </div>
                    <div class="product-spot__sidebar__discount-badge" *ngIf="data.marker.SalePrice > 1">-{{data.marker.PercentageSaving}}%</div>
                </div>

            </div>
        </div>
    </div>
    <div class="shop-by-right__CTA rightpane-container__footer_shadow">
        <a class="button button_primary button_full button_icon" [href]="data.marker.Url" intercept-links (click)="trackShopByImageDetailClick(data.marker)">
            <app-svg class="" src="arrow-right" [inline]="true"></app-svg> Flere detaljer
        </a>
    </div>
</app-rightpane>
