import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-brandlist-sublayout',
    templateUrl: './brandlist-sublayout.component.html',
})
export class BrandlistSublayoutComponent {
    @Input() public data;

    static ref = 'BrandListSublayout';
}
