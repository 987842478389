import {ChangeDetectorRef, Component, HostBinding, Input, OnInit, OnDestroy} from '@angular/core';
import { IProduct } from '@features/product-filter/models/product.model';
import { FilterService } from '@features/filter/services/filter.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-sublayout-search-result-zero',
    templateUrl: './sublayoutSearchResultZero.component.html',
})
export class SublayoutSearchResultZeroComponent implements OnInit, OnDestroy {
    static ref = 'FilterZeroResultsSublayout';
    show = true;

    zeroResults = false;
    private destroy = new Subject();

    @Input() public data;
    @HostBinding('style.display') get showZeroResults() {
        return this.zeroResults ? 'block' : 'none';
    }
    constructor(
        private changeRef: ChangeDetectorRef,
        private filterService: FilterService<IProduct>,
        ) {
        this.filterService
            .getFilter()
            .pipe(takeUntil(this.destroy))
            .subscribe(data => {
                this.zeroResults = data.totalEntityCount === 0;
            });
    }
    public templateName: string;
    public mobileLayout: string;

    ngOnInit() {
        this.boundHide = this.hide.bind(this);

        for (let i = 0; i < this.data.SearchResultSpots.length; i++) {
            if (this.data.Column1Spots[i].TemplateName) {
                this.templateName = this.data.SearchResultSpots[i].TemplateName;
                this.mobileLayout = this.data.SearchResultSpots[i].MobileLayout;
            }
        }
    }

    boundHide;
    hide() {
        this.show = false;
        this.changeRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }
}
