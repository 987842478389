import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { ProductService } from '@core/product.service';
import { TranslationService } from '@core/translation.service';
import { store } from '@shared/svg';
import { ImpactCoreModelsProductsDtoStoreStockDto } from '@shared/swagger/swagger.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-product-store-stock-button',
    template: `
        <button type="button" class="button button_outline button_icon product-spot__store-stock-button" (click)="openProductSizeStock()">
            <div *ngIf="noneSelected">
                {{translations?.ProductDetails?.SideBarSideBarActionSection?.FindProductInStoreButtonText}}
            </div>
            <div *ngIf="!noneSelected && productSizeInStock === 0">
                {{translations?.ProductDetails?.ProductInfoSectionInformation?.SoldOutInStoresLabel}}
            </div>
            <div *ngIf="!noneSelected && productSizeInStock === 1" [innerHTML]="inStockSingleStore | safeHtml">
                {{inStockSingleStore}}
            </div>
            <div *ngIf="!noneSelected && productSizeInStock > 1" [innerHTML]="inStockMultipleStore | safeHtml">
                {{inStockMultipleStore}}
            </div>
        </button>
        <!--<div class="button button_outline button_icon product-spot__store-stock-button" *ngIf="onSale">
            <div *ngIf="noneSelected">
                {{translations?.ProductDetails?.SideBarSideBarActionSection?.OnlyInStockOnline}}
            </div>
            <div *ngIf="!noneSelected && productSizeInStock > 0">
                {{translations?.ProductDetails?.SideBarSideBarActionSection?.OnlyInStockOnline}}
            </div>
            <div *ngIf="!noneSelected && productSizeInStock === 0">
                {{translations?.ProductDetails?.ProductInfoSectionInformation?.SoldOutInStoresLabel}}
            </div>
        </div>-->
    `
})
export class ProductStoreStockButtonComponent implements OnDestroy {
    @Input() productSizeStock: number;
    @Input() onSale = false;

    public translations: any;
    public locationIcon = store;
    public noneSelected = true;
    public hasProductDiscount = false;
    public productSizeInStock = 0;

    public inStockSingleStore: string;
    public inStockMultipleStore: string;

    private unsubscribe: Subject<void> = new Subject();


    constructor(
        private translationService: TranslationService,
        private productService: ProductService
    ) {
        // Get translations
        this.translationService.translations$.subscribe(translations => {
            this.translations = translations;
        });

        // Listen for when product variant changes
        this.productService.currentlySelectedProduct$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((prodData) => {
                this.hasProductDiscount = prodData.activeProductVariant.hasOwnProperty('SalePrice') && !!prodData.activeProductVariant.SalePrice;
                this.noneSelected = true;
            });

        // Listen for when product variant is added to basket
        this.productService.productAddedToBasket$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.noneSelected = true;
            });

        // Listen for store stock status
        this.productService.productSizeStockStatus$.subscribe((stores: ImpactCoreModelsProductsDtoStoreStockDto[]) => {
            if (stores) {
                this.productSizeInStock = stores.length;

                this.inStockSingleStore = this.translationService.replaceTranslationToken(
                    this.translations.ProductDetails.ProductInfoSectionInformation.SingleOnStockInXStoreLabel,
                    'AmountInStockInStore',
                    stores.length
                );
                this.inStockMultipleStore = this.translationService.replaceTranslationToken(
                    this.translations.ProductDetails.ProductInfoSectionInformation.OnStockInXStoreLabel,
                    'AmountInStockInStore',
                    stores.length
                );

                this.noneSelected = false;
            } else {
                this.noneSelected = true;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public openProductSizeStock(): void {
        this.productService.openProductInStoreStock();
    }
}
