import {
    animate,
    group,
    query,
    stagger,
    style,
    transition,
} from '@angular/animations';

/**
 * Menu items animation for flying in and out
 */
export const flyOutFlyInAnimation = [
    transition(':increment', [
        // this hides everything right away
        query(
            ':enter',
            style({ position: 'absolute', left: '0%', opacity: 0 }),
            {
                optional: true,
            }
        ),
        query(':leave', style({ transform: 'translateX(0)' }), {
            optional: true,
        }),

        // starts to animate things with a stagger in between
        query(
            ':leave',
            animate(
                '400ms cubic-bezier(0.25, 0, 0, 1)',
                style({ transform: 'translateX(-50%)', opacity: 0 })
            ),
            { optional: true }
        ),
        group([
            query(
                ':enter',
                style({
                    position: 'relative',
                    left: 'auto',
                    opacity: 0,
                    transform: 'translateX(50%)',
                }),
                { optional: true }
            ),

            // starts to animate things with a stagger in between
            query(
                ':enter',
                animate(
                    '400ms cubic-bezier(0.25, 0, 0, 1)',
                    style({ opacity: 1, transform: 'translateX(0)' })
                ),
                { optional: true }
            ),
        ]),
    ]),

    transition(':decrement', [
        // this hides everything right away
        query(
            ':enter',
            style({ position: 'absolute', left: '0%', opacity: 0 }),
            {
                optional: true,
            }
        ),
        query(':leave', style({ transform: 'translateX(0)' }), {
            optional: true,
        }),

        // starts to animate things with a stagger in between
        query(
            ':leave',
            animate(
                '400ms cubic-bezier(0.25, 0, 0, 1)',
                style({ transform: 'translateX(50%)', opacity: 0 })
            ),
            { optional: true }
        ),
        group([
            query(
                ':enter',
                style({
                    position: 'relative',
                    left: 'auto',
                    opacity: 0,
                    transform: 'translateX(-50%)',
                }),
                { optional: true }
            ),

            // starts to animate things with a stagger in between
            query(
                ':enter',
                animate(
                    '400ms cubic-bezier(0.25, 0, 0, 1)',
                    style({ opacity: 1, transform: 'translateX(0)' })
                ),
                { optional: true }
            ),
        ]),
    ]),
];

/**
 * The animation for our back button
 */
export const backAnimation = [
    transition(':enter', [
        style({ transform: 'translateX(-200px)', opacity: 0 }),
        group(
            [
                animate(
                    '400ms cubic-bezier(0.25, 0, 0, 1)',
                    style({
                        transform: 'translateX(0)',
                        opacity: 1,
                    })
                ),
            ],
            { delay: '100ms' }
        ),
    ]),
    transition(':leave', [
        group([
            animate(
                '400ms cubic-bezier(0.25, 0, 0, 1)',
                style({
                    transform: 'translateX(-300px)',
                    opacity: 0,
                })
            ),
        ]),
    ]),
];
