import { finalize, takeUntil } from 'rxjs/operators';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { AlertResponse, AlertType } from '@shared/alert/alert.interface';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/authentication.service';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { ImpactWebsiteCodeWebApiControllersResetArguments as resetArguments } from '@shared/swagger/swagger.interface';
import { checkmark, mail } from '@shared/svg';
import { TranslationService } from '@core/translation.service';
import { RegexService } from '@core/regex.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
    constructor(
        private authenticationService: AuthenticationService,
        private spinnerService: SpinnerService,
        private translationService: TranslationService,
        public regexService: RegexService
    ) {}

    ngOnInit() {
        this.translationService.translations$
            .pipe(takeUntil(this.destroy))
            .subscribe(data => (this.translations = data));
    }

    private destroy = new Subject();

    translations: any;
    submitted = false;
    response: AlertResponse = {};
    user: resetArguments = {
        Email: '',
    };
    icons = {
        mail,
        checkmark,
    };

    onResetPassword(form: NgForm) {
        this.response = {};

        if (form.valid && !this.submitted) {
            this.spinnerService.show('resetPasswordSpinner');
            this.submitted = true;

            this.authenticationService
                .resetPassword(this.user)
                .pipe(
                    finalize(() => {
                        this.spinnerService.hide('resetPasswordSpinner');
                        this.submitted = false;
                    })
                )
                .subscribe(
                    data => {
                        // Reset form
                        this.user.Email = '';
                        form.onReset();

                        this.response = {
                            type: AlertType.success,
                            text: this.translations.Authentication.ResetPassword
                                .ResetPasswordChanged,
                        };
                    },
                    ({ error }) =>
                        (this.response = {
                            type: AlertType.error,
                            text: error.ErrorMessage,
                        })
                );
        }
    }

    closeAlert = () => {
        this.response = {};
    };

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    // Trigger animation
    animationStateChanged = new EventEmitter<AnimationEvent>();
    startExitTrigger = new Subject();

    doSomething(event) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.startExitTrigger.next();
        this.startExitTrigger.complete();
    }
}
