import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-order-receipt',
    templateUrl: './order-receipt.component.html',
})
export class OrderReceiptComponent {
    @Input()
    public data;

    static ref = 'OrderReceiptSublayout';
}
