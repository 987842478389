import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { PaneService } from '@core/pane.service';
import { ShopByImageComponent } from '@features/shop-by-image/shop-by-image.component';
import { LazyLoadSource } from '@features/ngx-lazyload/lazyload.interface';
import { getFilename, isIe, sublayoutImageScaleDivider } from '@shared/utility';
import { TrackingService } from '@core/tracking/tracking.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-article-image-spot',
    templateUrl: './article-image-spot.component.html',
    host: {
        '[class]':
            '"column article__gap" + " " + (data.Size !== null ? data.Size : "is-12")',
    },
})
export class ArticleImageSpotComponent implements OnInit {
    static ref = 'Article Image Spot';
    @Input() public data;
    @Input() private templateType;

    imageScaleDivider = 1;

    imagePathName = getFilename;
    imageSources: LazyLoadSource[];
    inView = false;
    isTabletMobile: boolean;

    constructor(
        private paneService: PaneService,
        private breakpointObserver: BreakpointObserver,
        private tracking: TrackingService,
        @Inject(PLATFORM_ID) private platformId
    ) {}

    onInView(bool: boolean) {
        if (isPlatformBrowser(this.platformId)) {
            this.inView = isIe() || this.isTabletMobile ? true : bool;

            let element = document.getElementById(this.data.Id);
            let viewed = element.getAttribute('viewed') !== null;

            if (this.inView && this.data.Markers.length > 0 && !viewed) {
                element.setAttribute('viewed', 'true');
                this.tracking.sendShopByImage(this.data.Markers);
            }
        }
    }

    showProductInRightPane(ev: Event, marker: any) {
        let sitecoreId = this.data.Id;

        this.tracking.clickShopByImage(marker, 'Image', sitecoreId);

        ev.stopPropagation();
        ev.preventDefault();
        this.paneService.openChild(ShopByImageComponent, 'right', {
            marker,
            sitecoreId,
        });
        return false;
    }

    ngOnInit() {
        this.breakpointObserver
            .observe(['(max-width: 768px)'])
            .subscribe((state: BreakpointState) => {
                this.isTabletMobile = state.matches;
            });

        const divider = sublayoutImageScaleDivider(this.templateType);

        this.imageScaleDivider = divider;

        this.imageSources = [
            {
                media: '(max-width: 480px)',
                srcSet: this.data.Image + '?w=480',
            },
            {
                media: '(min-width: 481px) and (max-width: 768px)',
                srcSet: this.data.Image + '?w=768',
            },
            {
                media: '(min-width: 769px) and (max-width: 1024px)',
                srcSet: this.data.Image + '?w=' + 1024,
            },
            {
                media: '(min-width: 1025px)',
                srcSet: this.data.Image + '?w=' + 1680 / divider,
            },
        ];
    }
}
