<div class="checkout">
    <div class="checkout-step checkout-step_active">
        <div class="checkout-step__inset">
            <div class="columns is-gapless-vertical">
                <div class="column is-12-tablet is-7-desktop is-offset-1-desktop">
                    <div class="checkout-step__inner">
                        <h2 class="checkout-step__heading">
                            {{(translationService.translations$ | async)?.Receipt?.Page?.Headline}}
                        </h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="checkout-step__inset checkout-step__inset_top">
            <div class="columns is-gapless-vertical is-multiline">
                <div class="column is-6-tablet is-6-desktop is-offset-1-desktop">

                    <div class="receipt-container__order-details">
                        <div class="receipt-container__order-details__details-properties">
                            <span>{{(translationService.translations$ | async)?.Receipt?.Page?.OrderNumberLabel}}</span>
                            <span>{{(translationService.translations$ | async)?.Receipt?.Page?.DateLabel}}</span>
                            <span *ngIf="data?.PaymentType">{{(translationService.translations$ | async)?.Receipt?.Page?.PaymentMethodLabel}}</span>
                            <span *ngIf="data.GiftCards.length">{{(translationService.translations$ | async)?.Receipt?.Page?.GiftCardLabel}}</span>
                        </div>
                        <div class="receipt-container__order-details__details-values">
                            <span>{{ data?.OrderNumber }}</span>
                            <span>{{ data.orderCreatedDate}}</span>
                            <span *ngIf="data?.PaymentType">{{ data?.PaymentType }}</span>
                            <div *ngIf="data.GiftCards.length">
                                <span *ngFor="let giftcard of data.GiftCards">{{ giftcard.GiftCardCode }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="columns is-gapless-vertical">
                        <div class="column is-6">
                            <div class="receipt-container__contact-information">
                                <span class="receipt-container__contact-information__header">{{(translationService.translations$ | async)?.Receipt?.Page?.InvoiceTitle}}</span>
                                <div class="receipt-container__contact-information__content">
                                    <span>{{ data?.InvoiceAddress?.Company }}</span>
                                    <span>{{ data?.InvoiceAddress?.Firstname}} {{ data?.InvoiceAddress?.Lastname}}</span>
                                    <span>{{ data?.InvoiceAddress?.Line1 }}</span>
                                    <span>{{ data?.InvoiceAddress?.Zipcode }} {{ data?.InvoiceAddress?.City }}</span>
                                    <span>{{ data?.InvoiceAddress?.Country}}</span>
                                    <span>{{ data?.InvoiceAddress?.Email }}</span>
                                    <span>{{ data?.InvoiceAddress?.Phone }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="column is-6">
                            <div class="receipt-container__contact-information">
                                <span class="receipt-container__contact-information__header">{{(translationService.translations$ | async)?.Receipt?.Page?.DeliveryTitle}} - {{ data?.DeliveryMethodText
                                    }}
                                </span>
                                <div class="receipt-container__contact-information__content">
                                    <span>{{ data?.DeliveryAddress?.Company }}</span>
                                    <span>{{ data?.DeliveryAddress?.Firstname}} {{ data?.DeliveryAddress?.Lastname}}</span>
                                    <span>{{ data?.DeliveryAddress?.Line1 }} {{ data?.DeliveryAddress?.Line2 }} {{ data?.DeliveryAddress?.Line3
                                        }}
                                    </span>
                                    <span>{{ data?.DeliveryAddress?.Zipcode }} {{ data?.DeliveryAddress?.City }}</span>
                                    <span>{{ data?.DeliveryAddress?.Country}}</span>
                                    <span>{{ data?.DeliveryAddress?.Phone }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="receipt-container__contact-information" *ngIf="data?.Comment?.length > 0">
                        <span class="receipt-container__contact-information__header">{{(translationService.translations$ | async)?.Receipt?.Page?.CommentTitle}}</span>
                        <div class="receipt-container__contact-information__content receipt-container__contact-information__content_comment">
                            <span>{{ data?.Comment }}</span>
                        </div>
                    </div>

                </div>
                <div class="column is-6-tablet is-4-desktop is-offset-1-desktop">

                    <div class="receipt-container__sales-slip-container">
                        <div class="receipt-container__sales-slip">
                            <span class="receipt-container__sales-slip__header">{{(translationService.translations$ | async)?.Receipt?.RightPane?.Headline}}</span>

                            <div class="receipt-item-list">
                                <div class="receipt-item-list__item" *ngFor="let order of data.OrderLines">
                                    <div class="receipt-item-list__item__picture">
                                        <img
                                            LazyLoad
                                            [LazyLoadSrc]="order.ImageUrl + '&w=50&h=50'"
                                            [LazyLoadSrcSet]="order.ImageUrl + '&w=50&h=50 1x,' + order.ImageUrl + '&w=100&h=100 2x'"
                                            alt="" />
                                    </div>
                                    <div class="receipt-item-list__item__info">
                                        <span>{{ order.BrandText }}</span>
                                        <span>{{ order.CategoryText }}, {{ order.Color }}</span>
                                        <span>{{(translationService.translations$ | async)?.Receipt?.Page?.SizeLabel}} {{ order.Size | xlReformat }}
                                        </span>
                                        <div class="checkbox checkbox_no-overflow" *ngIf="order?.Wrapping">
                                            <input type="checkbox" checked>
                                            <label class="checkbox__label">
                                                <span [innerHTML]="icons.checkmark | safeHtml"></span>
                                                <span class="checkbox__name">{{(translationService.translations$ | async)?.Checkout?.RightPane?.GiftWrappingLabel}}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="receipt-item-list__item__price">
                                        <br>
                                        <ng-container *ngIf="order.Count === 1; else plural">
                                            <span>{{ order.Count }} {{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryProductLabel}}</span>
                                        </ng-container>
                                        <ng-template #plural>
                                            <span>{{ order.Count }} {{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryProductsLabel}}</span>
                                        </ng-template>
                                        <span *ngIf="order?.TotalLineBeforePrice" [ngClass]="{'receipt-item-list__item__price_before-price':order?.TotalLineBeforePrice}">{{ order?.TotalLineBeforePrice | kfmCurrency: order.Currency }}</span>
                                        <span [ngClass]="{'receipt-item-list__item__price_discount':order?.TotalLineBeforePrice}">{{ order.TotalLinePrice | kfmCurrency: order.Currency }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receipt-container__sales-slip__footer">
                                <div class="receipt-container__sales-slip__footer__price-details-container">
                                    <div class="receipt-container__sales-slip__footer__price-details">
                                        <div class="receipt-container__sales-slip__footer__price-details__category" *ngIf="data.OrderLines?.length === 1; else orderlinesPlural">
                                            {{ data.OrderLines?.length }} {{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryProductLabel}}
                                        </div>
                                        <ng-template #orderlinesPlural>
                                            <div class="receipt-container__sales-slip__footer__price-details__category">{{ data.OrderLines?.length }} {{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryProductsLabel}}</div>
                                        </ng-template>
                                        <div class="receipt-container__sales-slip__footer__price-details__price">{{data.LinesTotalPrice | kfmCurrency: data.Currency}}</div>
                                    </div>
                                    <div class="receipt-container__sales-slip__footer__price-details" *ngIf="data?.LinesTotalBeforePrice">
                                        <div class="receipt-container__sales-slip__footer__price-details__category">{{(translationService.translations$ | async)?.Receipt?.Page?.RetailPriceLabel}}</div>
                                        <div class="receipt-container__sales-slip__footer__price-details__price">{{ data.LinesTotalBeforePrice | kfmCurrency: data.Currency }}</div>
                                    </div>
                                    <div class="receipt-container__sales-slip__footer__price-details" *ngIf="data?.LinesTotalRebate">
                                        <div class="receipt-container__sales-slip__footer__price-details__category receipt-container__sales-slip__footer__price-details_discount">{{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryYouDiscountLabel}}</div>
                                        <div class="receipt-container__sales-slip__footer__price-details__price receipt-container__sales-slip__footer__price-details_discount">{{ data?.LinesTotalRebate | kfmCurrency: data.Currency: true }}</div>
                                    </div>

                                    <div class="receipt-container__sales-slip__footer__price-details" *ngIf="data?.GiftCardAmount">
                                        <div class="receipt-container__sales-slip__footer__price-details__category receipt-container__sales-slip__footer__price-details_discount">{{(translationService.translations$ | async)?.Receipt?.RightPane?.GiftCardLabel}}</div>
                                        <div class="receipt-container__sales-slip__footer__price-details__price receipt-container__sales-slip__footer__price-details_discount">{{ data?.GiftCardAmount | kfmCurrency: data.Currency: true }}</div>
                                    </div>

                                    <div class="receipt-container__sales-slip__footer__price-details" *ngIf="data?.VoucherBalance">
                                        <div class="receipt-container__sales-slip__footer__price-details__category receipt-container__sales-slip__footer__price-details_discount">{{(translationService.translations$ | async)?.Minibasket?.Overview?.Voucher}}: {{data?.VoucherCode}}</div>
                                        <div class="receipt-container__sales-slip__footer__price-details__price receipt-container__sales-slip__footer__price-details_discount">{{ data?.VoucherBalance | kfmCurrency: data.Currency: true }}</div>
                                    </div>

                                    <div class="receipt-container__sales-slip__footer__price-details__breaker"></div>

                                    <div class="receipt-container__sales-slip__footer__price-details">
                                        <div class="receipt-container__sales-slip__footer__price-details__category">{{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryDeliveryLabel}}</div>
                                        <div class="receipt-container__sales-slip__footer__price-details__price">
                                            <span *ngIf="data?.DeliveryFee; else deliveryFree">
                                                {{ data.DeliveryFee | kfmCurrency : data.Currency }}</span>
                                                    <ng-template #deliveryFree>{{0 | kfmCurrency: data.Currency}}</ng-template>
                                        </div>
                                    </div>
                                    <div class="receipt-container__sales-slip__footer__price-details" *ngIf="showPaymentFee">
                                        <div class="receipt-container__sales-slip__footer__price-details__category">{{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryPaymentFeeLabel}}</div>
                                        <div class="receipt-container__sales-slip__footer__price-details__price">{{ data.PaymentTypeFee | kfmCurrency: data.Currency}}</div>
                                    </div>

                                    <div class="receipt-container__sales-slip__footer__price-details__breaker receipt-container__sales-slip__footer__price-details__breaker_large"></div>

                                    <div class="receipt-container__sales-slip__footer__price-details">
                                        <div class="receipt-container__sales-slip__footer__price-details__category receipt-container__sales-slip__footer__price-details_total">{{(translationService.translations$ | async)?.Receipt?.RightPane?.SummaryTotalSumLabel}}</div>
                                        <div class="receipt-container__sales-slip__footer__price-details__price receipt-container__sales-slip__footer__price-details_total">{{ data.TotalPrice | kfmCurrency: data.Currency }}</div>
                                    </div>
                                    <div class="receipt-container__sales-slip__footer__price-details__breaker"></div>
                                    <div class="receipt-container__sales-slip__footer__price-details">
                                        <div class="receipt-container__sales-slip__footer__price-details__category">{{(translationService.translations$ | async)?.Receipt?.RightPane?.TotalVatAmountLabel}}</div>
                                        <div class="receipt-container__sales-slip__footer__price-details__price">{{ data.TotalVatAmount | kfmCurrency: data.Currency}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
