<div class="product-slider" *ngIf="(slides$ | async) as slides" [ngClass]="templateType">

  <div class="product-slider__title-div">
      <div [showOnSite]="['kaufmann', 'quint']" class="heading">{{data.HeadingText}}</div>
      <a [showOnSite]="['axel']" [IRouterLink]="data.LinkUrl"><h2 class="axel-see-all-button heading"> {{data.HeadingText}} </h2></a>
      <div class="secondary-heading">{{data.SecondHeadingText}}</div>
  </div>


  <div class="product-slider__content">
    <!-- Slider main container -->
    <div class="swiper-container" #slider [id]="id" [ngClass]="{'with-price': data.ShowPrice, 'oneOneThird': layoutOneOneThird, 'twoOneThird': layoutTwoOneThird}">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper" [class.needs-margin-desktop]="slides?.length <= 3" #sliderList>
        <!-- Slides -->
          <div class="swiper-slide" #sliderItem *ngFor="let slide of slides; let i = index;" [ngStyle]="itemWidth">
            <product-slider-product
                (changeDetected)="onChangeDetected()"
                [raptorFeedType]="raptorFeedType"
                [visibilitySettings]="visibilitySettings"
                [showPrice]="data.ShowPrice"
                [slide]="slide"
                [listName]="data.HeadingText"
                [index]="i"
                [backgroundColor]="backgroundColor"
                [imageBgColor]="data.ImageBackgroundColor"
            ></product-slider-product>
          </div>
      </div>
    </div>
    <!-- If we need navigation buttons -->
    <div class="slider-button-prev" [ngClass]="{'slider-button-prev--hide': sliderStart}" [id]="'prev_' + id" (click)="prevClickTrigger()" [@prevClick]="prevClick" tabindex="0" role="button" aria-label="Previous" [attr.aria-disabled]="sliderEnd ? true : false">
      <span [showOnSite]="['kaufmann', 'quint']" [innerHtml]="paginationArrow | safeHtml"></span>
      <span [showOnSite]="['axel']" [innerHtml]="'\<'"></span>
    </div>
    <div class="slider-button-next" [ngClass]="{'slider-button-next--hide': sliderEnd}" [id]="'next_' + id" (click)="nextClickTrigger()" [@nextClick]="nextClick" tabindex="0" role="button" aria-label="Next" [attr.aria-disabled]="sliderStart ? true : false">
      <span [showOnSite]="['kaufmann', 'quint']" [innerHtml]="paginationArrow | safeHtml"></span>
      <span [showOnSite]="['axel']" [innerHtml]="'>'"></span>
    </div>
  </div>

  <div [showOnSite]="['kaufmann', 'quint']" *ngIf="data.LinkUrl && data.LinkText && data.LinkText != ''" class="product-slider__see-all-div" >
    <button type="button" class="smart-filter__facets__button"><span class="smart-filter__facets__button-name" [IRouterLink]="data.LinkUrl">{{ data.LinkText }}</span> </button>
  </div>

</div>
