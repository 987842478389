import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslationService } from '@core/translation.service';
import { checkmark, password } from '@shared/svg';
import { UserService } from '@core/user.service';
import { NgForm } from '@angular/forms';
import { RegexService } from '@core/regex.service';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { first } from 'rxjs/operators';
import { AlertType } from '@shared/alert/alert.interface';

@Component({
    selector: 'app-user-opt-out',
    templateUrl: './user-opt-out.component.html',
})
export class UserOptOutComponent implements OnInit {
    static ref = 'User opt out spot';

    optOutEmail: UserOptOutEmail;
    optOutEmailResponseMessage: string;
    optOutEmailResponseMessageShow: boolean = false;
    optOutSms: UserOptOutSms;
    optOutSmsResponseMessage: string;
    optOutSmsResponseMessageShow: boolean = false;

    icons = {
        checkmark,
    };

    alertType = AlertType;

    private transl: any;

    @Input() public data: any;
    @Input() public templateType;
    @ViewChild('optOutEmailForm', { static: true }) optOutEmailForm: NgForm;
    @ViewChild('optOutSmsForm', { static: true }) optOutSmsForm: NgForm;

    constructor(
        public translations: TranslationService,
        private userService: UserService,
        public regexService: RegexService,
        private spinnerService: SpinnerService
    ) {}

    ngOnInit() {
        this.optOutEmail = {
            Email: '',
        };

        this.optOutSms = {
            PhoneNumber: '',
        };

        this.translations.translations$
            .pipe(first())
            .subscribe(res => (this.transl = res));
    }

    optOutEmailSubmit() {
        if (this.optOutEmailForm.valid) {
            this.spinnerService.show('unsubscribeEmailSpinner');
            this.userService
                .unsubscribeEmail(this.optOutEmail)
                .subscribe(() => {
                    this.optOutEmailResponseMessage = this.transl.UserService.Unsubscribes.ResponseMessageEmail;
                    this.optOutEmailResponseMessageShow = true;
                    this.spinnerService.hide('unsubscribeEmailSpinner');
                });
        }
    }

    optOutSmsSubmit() {
        if (this.optOutSmsForm.valid) {
            this.spinnerService.show('unsubscribeSmsSpinner');
            this.userService.unsubscribeSms(this.optOutSms).subscribe(() => {
                this.optOutSmsResponseMessage = this.transl.UserService.Unsubscribes.ResponseMessageSms;
                this.optOutSmsResponseMessageShow = true;
                this.spinnerService.hide('unsubscribeSmsSpinner');
            });
        }
    }

    closeEmailSuccessNotification = () => {
        this.optOutEmailResponseMessageShow = false;
    };

    closeSmsSuccessNotification = () => {
        this.optOutSmsResponseMessageShow = false;
    };
}
declare interface UserOptOutEmail {
    Email: string;
}
declare interface UserOptOutSms {
    PhoneNumber: string;
}
