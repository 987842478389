import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { SiteContextService } from '@core/site-context.service';
import { StickyService } from '@core/sticky.service';
import { arrowUp } from '@shared/svg';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-scroll-to-top',
    templateUrl: './scroll-to-top.component.html',
})
export class ScrollToTopComponent implements OnInit, OnDestroy {
    showScrollToTop = false;
    animating = false;
    backgroundColor: string;

    icon = arrowUp;

    destroy;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private stickyService: StickyService,
        private changeRef: ChangeDetectorRef,
        private siteContextService: SiteContextService
    ) {
        this.backgroundColor = '#' + this.siteContextService.getContext()?.generalSiteSettings?.ToTopButtonColor ?? '#213A57';
    }

    ngOnInit() {
        this.destroy = new Subject();

        this.stickyService.stickyEvent
            .pipe(takeUntil(this.destroy))
            .subscribe(event => {
                this.sticky(event.yPos);
            });
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    sticky(scrollPos) {
        const show = scrollPos > 600;
        if (this.showScrollToTop !== show) {
            this.showScrollToTop = show;
            this.changeRef.detectChanges();
        }
    }

    scrollToTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
    }
}
