import { Component, Input, OnInit } from '@angular/core';
import {washIcons} from '@shared/washicons';


@Component({
    selector: 'app-info-description',
    templateUrl: './info.description.component.html',
})
export class InfoDescriptionComponent implements OnInit {

    treatmentIcons = washIcons;

    @Input()
    translations;

    @Input()
    activeProduct;

    @Input() openFirstTab: boolean;

    currentTab: string | number;

    // Keep track of active accordions
    accordionChange(tabId: string | number) {
        this.currentTab = tabId === this.currentTab ? '' : tabId;
    }

    ngOnInit(): void {
        this.currentTab = this.openFirstTab ? 'productDetailsInformation' : -1;
    }
}
