import {AfterViewInit, Component, HostBinding, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {basket, burger, close, favoriteFill, heart, linkArrow, search, store, user} from '@shared/svg';
import {DOCUMENT} from '@angular/common';
import {MenuItem} from '../mobile-menu/menu.interface';
import {NavigationService} from '@core/navigation.service';
import {OverlayService} from '@impactdk/ngx-overlay';
import {MobileMenuComponent} from '@features/mobile-menu/mobile-menu.component';
import {PaneService} from '@core/pane.service';
import {PageService} from '@core/page.service';
import {StickyService} from '@core/sticky.service';
import {SiteContextService} from '@core/site-context.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {TrackingService} from '@core/tracking/tracking.service';
import {HeaderService} from '@core/header.service';
import {ImpactCoreModelsDtoMetaMenuMetaMenuItemDto as MetaMenuItem} from '@shared/swagger/swagger.interface';
import { getVwoId } from '@shared/utility';
/**
 * Renders our header and makes sure to sticky it when scrolling down
 *
 * @TODO: Make it only stick when scrolling up on mobile in landscape
 * @TODO: Set up tests for component
 */
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, AfterViewInit {
    /**
     * Content of our header
     */
    menuItems: MenuItem[];
    secondaryMenuItems: MenuItem[];

    /**
     * Helper for checking if we scrolled enough to hide
     */
    last_known_scroll_position;

    /**
     * Helper for debouncing with animation frame
     * @type {boolean}
     */
    ticking = false;

    /**
     * Temporary icons for the meta menu
     *
     * @TODO: These should come from a service
     */
    icons = {
        store,
        favoriteFill,
        user,
        arrow: linkArrow,
        basket,
        search,
        burger,
        close,
    };

    /**
     * Page logo
     */
    logo: { image: string; url: string };

    /**
     * Temporary content for our mega menu
     * @TODO: These should come from a service
     */
    metaMenu: MetaMenuItem[];

    /**
     * PageData containing meta page data. The model differs depending on the page type.
     * @type {{}}
     */
    pageData: any = {};
    siteContext: any;

    showDesktopStyles = true;

    isHeaderSolid: boolean;

    navHighlightItem: string;

    megaMenuTimer: any;
    isInteractingWithMegaMenu = false;
    isInteractingWithMegaMenuTimer: any;

    vwoId = getVwoId;
    /**
     * Injects our dependencies
     * @param {object} platformId
     * @param {NavigationService} navService
     * @param overlay
     * @param paneService
     * @param _document
     * @param pageService
     * @param stickyService
     * @param siteContextService
     * @param breakpointObserver
     * @param tracking
     * @param headerService
     */
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private navService: NavigationService,
        private overlay: OverlayService,
        private paneService: PaneService,
        @Inject(DOCUMENT) private _document,
        private pageService: PageService,
        private stickyService: StickyService,
        private siteContextService: SiteContextService,
        private breakpointObserver: BreakpointObserver,
        private tracking: TrackingService,
        private headerService: HeaderService,
    ) {
        // Gets the page data
        this.pageService.page.subscribe(data => {
            this.pageData = data.pageData;
            this.siteContext = this.siteContextService.getContext();
        });
    }
    @HostBinding('class.frontpage') get isFrontpage() {
        return this.pageData ? this.pageData.TemplateName.toLowerCase() === 'homepage' : false;
    }
    @HostBinding('class.header_solid') get isSolid() {
        return this.isHeaderSolid;
    }

    /**
     * Fetches our main navigation and sets up our scroll listener if in browser
     */
    ngOnInit() {
        this.logo = {
            image: this.siteContextService.getContext().logoImageUrl,
            url: this.siteContextService.getContext().logoImageRedirectUrl,
        };

        this.navHighlightItem = this.siteContextService.getContext().generalSiteSettings.NavigationHighlightColor ? `#${this.siteContextService.getContext().generalSiteSettings.NavigationHighlightColor}` : '';
        this.navService.getMainNav().subscribe(menu => {
            this.menuItems = menu.MainMenuViewModel.MainMenu;
            this.secondaryMenuItems = menu.SecondaryMainMenuViewModel.MainMenu;
            this.metaMenu = menu.MetaMenuViewModel.MetaMenu.filter(item => item.AngularKey !== 'languagePicker');
        });

        const layoutChanges = this.breakpointObserver.observe(
            '(min-width: 1221px)'
        );

        layoutChanges.subscribe((res: any) => {
            this.showDesktopStyles = res.matches;
        });

        this.headerService.headerSolid$.subscribe(state => this.isHeaderSolid = state);
    }

    ngAfterViewInit() {
        this.stickyService.initScrollListen();
    }


    preHeaderStyles() {
        return this.showDesktopStyles
            ? {
                  'background-color': `#${
                      this.siteContext.headerUsp &&
                      this.siteContext.headerUsp.BackgroundColorDesktop
                          ? this.siteContext.headerUsp.BackgroundColorDesktop
                          : '000000'
                  }`,
                  color: `#${
                      this.siteContext.headerUsp &&
                      this.siteContext.headerUsp.TextColorDesktop
                          ? this.siteContext.headerUsp.TextColorDesktop
                          : 'ffffff'
                  }`,
              }
            : {
                  'background-color': `#${
                      this.siteContext.headerUsp &&
                      this.siteContext.headerUsp.BackgroundColorMobile
                          ? this.siteContext.headerUsp.BackgroundColorMobile
                          : '000000'
                  }`,
                  color: `#${
                      this.siteContext.headerUsp &&
                      this.siteContext.headerUsp.TextColorMobile
                          ? this.siteContext.headerUsp.TextColorMobile
                          : 'ffffff'
                  }`,
              };
    }

    /**
     * Makes the menu sticky if needed be
     */
    sticky() {
        if (this.last_known_scroll_position > 34) {
            this._document.body.classList.add('header_sticky');
        } else {
            const top = this._document.documentElement.style.top;

            if (top.charAt(0) === '-') {
                const topAmount = parseInt(
                    top.replace('-', '').replace('px', '')
                , 0);

                if (topAmount > 30) {
                    this._document.body.classList.add('header_sticky');
                } else {
                    this._document.body.classList.remove('header_sticky');
                }
            } else {
                this._document.body.classList.remove('header_sticky');
            }
        }
    }

    /**
     * Opens our mobile menu
     */
    openMobileMenu() {
        this.paneService.open(MobileMenuComponent, 'left', 'mobile-menu');
    }

    handleClick(link, listItem) {
        listItem.classList.add('hideMegaMenu');

        setTimeout(() => {
            listItem.classList.remove('hideMegaMenu');
        }, 3000);

        this.tracking.sendNavigationEvent('Main menu', link);
    }

    handleMouseLeave(listItem) {
        // Clear timer so that mega menu do not open
        clearTimeout(this.megaMenuTimer);

        listItem.classList.remove('hideMegaMenu');
        listItem.classList.remove('openMegaMenu');

        // If a user stops interacting with the main menu items the boolean is set to its default value.
        this.isInteractingWithMegaMenuTimer = setTimeout(() => {
            this.isInteractingWithMegaMenu = false;
        }, 200);
    }
    handleMouseEnter(listItem) {
        clearTimeout(this.isInteractingWithMegaMenuTimer);

        // If a user is already interacting with the main menu items the mega menu is opened strait away.
        // Otherwise, it is delayed by 300 ms.
        if (this.isInteractingWithMegaMenu) {
            listItem.classList.add('openMegaMenu');
        } else {
            this.megaMenuTimer = setTimeout(() => {
                this.isInteractingWithMegaMenu = true;
                listItem.classList.add('openMegaMenu');
            }, 300);
        }
    }
}
