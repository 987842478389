<section class="filter-facets__all-dialog">
    <div class="filter-facets__all-dialog__close" [innerHtml]="closeSvg | safeHtml" (click)="close()"></div>
    <h2 class="filter-facets__all-dialog__heading"
        [innerHTML]="translations?.BrandOverview?.Dialog?.Heading | stringFormat: facetName">
    </h2>

    <div class="filter-facets__all-dialog__list" [horizontalMouseScroll]>
        <app-product-filter-facet-item
            class="filter-facets__item filter-facets__all-dialog__item"
            [class.filter-facets__item_sub]="option.isSubFacet"
            *ngFor="let option of itemsToShow();" [option]="option" (onChange)="onItemChange($event)">
        </app-product-filter-facet-item>
    </div>

</section>
