import { Component, Input, OnInit } from '@angular/core';
import { arrowRight } from '@shared/svg';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-categorylist',
    templateUrl: './categorylist.component.html',
})
export class CategorylistComponent implements OnInit {
    arrow = arrowRight;

    @Input() data: any;

    static ref: string = 'Category list spot';

    constructor(public translation: TranslationService) {}

    ngOnInit() {}
}
