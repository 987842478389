import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { TrackingService } from '@core/tracking/tracking.service';
import { TranslationService } from '@core/translation.service';
import { cross, expand } from '@shared/svg';
import {SiteContextService} from '@core/site-context.service';
import {
    ImpactCoreModelsProductsDtoColorGroupDto,
    ImpactCoreModelsProductsDtoStyleDto
} from '@shared/swagger/swagger.interface';

export interface IChangeSizeProps {
    sku: string;
    style: string;
    color: string;
}

@Component({
    selector: 'app-wishlist-item',
    templateUrl: './wishlist-item.component.html',
})
export class WishlistItemComponent implements OnInit {
    @Input() product: ImpactCoreModelsProductsDtoStyleDto;
    @Input() selectedSize: string;
    @Input() showSizeMeassage: boolean;
    @Output() itemDeletion: EventEmitter<ImpactCoreModelsProductsDtoStyleDto> = new EventEmitter<ImpactCoreModelsProductsDtoStyleDto>();
    @Output() changeSize: EventEmitter<IChangeSizeProps> = new EventEmitter();

    icons = {
        expand,
        cross,
    };

    productImageBgColor = this.siteContextService.getContext().bgColor.productImageBgColor;

    activeColorGroup: ImpactCoreModelsProductsDtoColorGroupDto;

    constructor(
        private tracking: TrackingService,
        public translationService: TranslationService,
        private siteContextService: SiteContextService,
    ) {}

    ngOnInit(): void {
        const matchingColorGroup = this.product.ColorGroups.filter(colorGroup => this.product.ActiveColorGroupId.includes(colorGroup.ColorId));
        this.activeColorGroup = matchingColorGroup.length ? matchingColorGroup[0] : null;
    }

    onDelete() {
        this.tracking.sendRemoveFromFavorite(this.product.Name);
        this.itemDeletion.emit(this.product);
    }

    onChangeSize(sku: string) {
        this.changeSize.emit({sku, style: this.product.StyleId, color: this.activeColorGroup.ColorId});
    }

    getTags() {
        const tags = [];

        if (this.product.Fit) {
            tags.push(this.product.Fit);
        }

        if (this.product.Name) {
            tags.push(this.product.Name);
        } else {
            if (this.product.Category) {
                tags.push(this.product.Category);
            }
        }

        if (this.activeColorGroup?.ColorName) {
            tags.push(
                this.activeColorGroup.ColorName.charAt(0).toUpperCase() +
                    this.activeColorGroup.ColorName.substr(1).toLowerCase()
            );
        }

        return tags.join(' | ');
    }

}
