import {
    Component,
    OnInit,
    PLATFORM_ID,
    Inject,
    Input,
} from '@angular/core';
import { SiteContextService } from '@core/site-context.service';
import { isPlatformBrowser } from '@angular/common';
import { TranslationService } from '@core/translation.service';
import { ICountrySelectorList } from '@core/site-context.interface';
import { globe } from '@shared/svg';
import { PaneService } from '@core/pane.service';
import { LanguagePickerPaneComponent } from './language-picker-pane/language-picker-pane.component';

@Component({
    selector: 'app-language-picker',
    templateUrl: './language-picker.component.html',
})
export class LanguagePickerComponent implements OnInit {

    countrySelectorData: ICountrySelectorList[];
    globe = globe;
    selectedCountry: ICountrySelectorList;

    @Input()
    useCustomLanguagePicker = true;

    constructor(
        public translationService: TranslationService,
        public siteContextService: SiteContextService,
        public paneService: PaneService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    ngOnInit() {
        this.countrySelectorData = this.siteContextService.getContext().countrySelectorList;
        this.selectedCountry = this.countrySelectorData.find(countrySelectorData => countrySelectorData.IsCurrent);
    }

    countrySelect(selectedCountry: ICountrySelectorList): void {
        if (isPlatformBrowser(this.platformId)) {
            window.location.href = selectedCountry.LinkToHomePage;
        }
    }

    openCustomLanguagePicker() {
        this.paneService.open(LanguagePickerPaneComponent, 'right', 'languagePicker', 'language-picker', null, true);
    }
}
