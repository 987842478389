import { Pipe, PipeTransform } from '@angular/core';
import { MultiCheckBoxFacetChild } from '@features/filter/models/facet.model';

@Pipe({
    name: 'filterFacets',
})
export class FilterFacetsPipe implements PipeTransform {

    transform(value: MultiCheckBoxFacetChild[], args: any[]) {
        if (args[1]?.toLowerCase() !== 'brand') {
            return value;
        }
        return value.filter(facet => facet.name.toLowerCase().includes(args[0]?.toLowerCase()));
    }

}
