import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import { MainMenuItem } from '@features/mobile-menu/menu.interface';

@Directive({
    selector: '[appMegaMenuClasses]'
})
export class MegaMenuClassesDirective implements OnInit {
    @Input('appMegaMenuClasses') subMenuItem: MainMenuItem;
    @Input() idx: number;

    constructor(private elmRef: ElementRef) {}

    ngOnInit(): void {
        const numOfColumns = +(this.subMenuItem.Subitems.length / 11).toExponential(1);
        const columns = Number.isInteger(numOfColumns) ? numOfColumns : Math.ceil(numOfColumns);

        if (columns > 1) {
            this.elmRef.nativeElement.parentNode.setAttribute('style', `grid-column: ${this.idx + 1} / span ${columns}`);
            this.elmRef.nativeElement.setAttribute('style', `
                grid-template-rows: repeat(11, auto);
                column-gap: 24px;
                grid-template-columns: repeat(${columns}, 1fr);
            `);
        } else {
            this.elmRef.nativeElement.setAttribute('style', 'display: block;');
        }
    }

}
