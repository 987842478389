import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    host: { class: 'column' },
})
export class CustomerComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
    }
}
