import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImpactCoreBasketsBasketLineServiceModel as basketLineModel } from '@shared/swagger/swagger.interface';
import { TranslationService } from '@core/translation.service';

@Component({
    selector: 'app-basket-products',
    templateUrl: './basket-products.component.html',
})
export class BasketProductsComponent {
    /**
     * Get products from parent component
     */
    @Input() basketLines: basketLineModel[];

    /**
     * Get wrapping comment
     */
    @Input() wrappingComment: string;

    /**
     * Update basket event which parent should handle
     */
    @Output() updateBasket: EventEmitter<basketLineModel> = new EventEmitter();

    /**
     * Update basket event which parent should handle
     */
    @Output() updateGiftWrap: EventEmitter<string> = new EventEmitter();

    constructor(public translationService: TranslationService) {}

    /**
     * Emit child event upwards to parent component
     */
    onUpdateBasket(basketLine: basketLineModel): void {
        this.updateBasket.emit(basketLine);
    }

    /**
     * Emit child event upwards to parent component
     */
    onUpdateGiftWrap(skuId: string): void {
        this.updateGiftWrap.emit(skuId);
    }

    trackByFn(index, item) {
        return item.Id;
    }
}
