import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'consoleLog'
})
export class ConsoleLogPipe implements PipeTransform {

    transform(value: any, msg: string, argValue: any = ''): any {
        console.log(`${msg}`, value, argValue);
        return value;
    }

}
