import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@features/find-store/models/store.model';
import { TranslationService } from '@core/translation.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-find-store-page',
    templateUrl: './find-store-page.component.html',
})

/**
 *  Find Store Page Component is the root component in the full-screen 'find-store' view
 */
export class FindStorePageComponent implements OnInit {
    @Input() storeData: any;
    public selectedStore: Store;
    public translations: any;

    constructor(
        private translationService: TranslationService,
        private location: Location,
        private router: Router
    ) {}

    ngOnInit() {
        this.translations = this.translationService.translations$;
        this.selectedStore = new Store(this.storeData);
    }

    onSelectedStore(store: Store) {
        this.selectedStore = store;
        this.updateUrl(store);
    }

    updateUrl(store) {
        if (store.LinkToContentPage) {
            this.location.replaceState(
                this.router.serializeUrl(
                    this.router.createUrlTree(
                        [
                            {
                                outlets: {
                                    dialog: store.LinkToContentPage.replace(
                                        '/',
                                        ''
                                    ),
                                },
                            },
                        ],
                        { queryParamsHandling: 'preserve' }
                    )
                )
            );
        }
    }
}
