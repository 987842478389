<div class="container container_to-edges">
    <div class="sublayout sublayout_background">
        <div class="article">
            <article class="article__content"
                [ngClass]="{
                    'article__content_no-footer':
                        data.ArticleFooterColumn1.length === 0 &&
                        data.ArticleFooterColumn2.length === 0 &&
                        data.ArticleFooterColumn3.length === 0}">
                <div class="columns is-mobile is-centered is-multiline">
                    <div class="column is-10-mobile is-10-tablet is-12-desktop article__header">
                        <div class="columns is-mobile is-centered is-multiline">
                            <div *ngFor="let spot of data.ArticleHeading" generic-spot [spotType]="spot.TemplateName" [spotData]="spot"></div>
                        </div>
                    </div>
                    <div class="column is-10-mobile is-10-tablet is-12-desktop article__main">
                        <div *ngFor="let spot of data.ArticleMain" generic-spot [spotType]="spot.TemplateName" [spotData]="spot"></div>
                    </div>
                </div>
            </article>
            <div class="article__footer"
                *ngIf="
                    data.ArticleFooterColumn1.length > 0 ||
                    data.ArticleFooterColumn2.length > 0 ||
                    data.ArticleFooterColumn3.length > 0">
                <div class="columns is-centered">
                    <div class="column is-10-desktop">
                        <div class="columns is-multiline">
                            <div class="column is-12-tablet is-4-desktop">
                                <div class="columns is-equal-height">
                                    <div *ngFor="let spot of data.ArticleFooterColumn1" generic-spot [spotType]="spot.TemplateName" [spotData]="spot"></div>
                                </div>
                            </div>
                            <div class="column is-6-tablet is-4-desktop">
                                <div class="columns is-equal-height">
                                    <div *ngFor="let spot of data.ArticleFooterColumn2" generic-spot [spotType]="spot.TemplateName" [spotData]="spot"></div>
                                </div>
                            </div>
                            <div class="column is-6-tablet is-4-desktop">
                                <div class="columns is-equal-height">
                                    <div *ngFor="let spot of data.ArticleFooterColumn3" generic-spot [spotType]="spot.TemplateName" [spotData]="spot"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
